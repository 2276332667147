import { Box, Stack, TextFieldProps, Typography, styled } from '@mui/material';
import { useEffect } from 'react';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Thumbnail } from '../../api/DTO/thumbnails.interface';
import { useUploadPublicMediaMutation } from '../../api/mediaApiSlice';
import { ReactComponent as AddIcon } from '../../assets/icon/button-add.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icon/delete.svg';
import AppAddPhotoButton from './form-inputs/AppAddPhotoButton';
import AppInputLayout from './form-layout/AppInputLayout';

interface UploadedThumbnailsImageProps {}

export default function UploadedThumbnailsImage<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	...props
}: UseControllerProps<TFieldValues, TName> &
	TextFieldProps &
	UploadedThumbnailsImageProps) {
	const { t } = useTranslation();
	const { field, fieldState } = useController(props);
	const onChange = field.onChange;
	const errorMessage = fieldState.error?.message;

	const [uploadMedia, { data: uploadMediaResponse, isSuccess }] =
		useUploadPublicMediaMutation();

	useEffect(() => {
		if (isSuccess && uploadMediaResponse?.data[0]) {
			const newFileWithDescription: Thumbnail = {
				id: uploadMediaResponse?.data[0].id,
				url: uploadMediaResponse?.data[0].url,
			};
			onChange({
				id: newFileWithDescription.id,
				url: newFileWithDescription.url,
			});
		}
	}, [isSuccess]);

	async function onUploadFileChange(
		event: React.ChangeEvent<HTMLInputElement>
	) {
		const target = event.target;
		const file = target.files?.[0];

		if (file) {
			uploadMedia(file);
		}
	}

	const handleFileRemove = () => {
		onChange(null);
	};

	return (
		<>
			<AppInputLayout
				label={t('room.ratePlan.Thumbnail')}
				labelAlignItemsFlexStart
				required={props.required}
			>
				<Stack>
					{field.value && (
						<Box position={'relative'} maxWidth={'420px'}>
							<img
								style={{
									width: '100%',
									maxWidth: '400px',
									borderRadius: '10px',
								}}
								src={field.value?.url}
							/>
							<Box
								sx={{
									position: 'absolute',
									top: 0,
									right: '5px',
								}}
							>
								<DeleteIcon
									color='#F6403F'
									cursor={'pointer'}
									onClick={() => handleFileRemove()}
								/>
							</Box>
						</Box>
					)}

					{!field.value && (
						<Box height={'135px'} width={'240px'}>
							<AppAddPhotoButton component='label'>
								<input
									type='file'
									name='doc'
									hidden
									onChange={onUploadFileChange}
									accept='image/jpeg, image/png, image/gif, image/webp'
								/>
								<Typography fontSize={'14px'} fontWeight={'700'}>
									<AddIcon />
									{t('button.AddPhoto')}
								</Typography>
								<Typography fontSize={'10px'}>
									{t('place.SuggestedFormat')}
									<b>{t('place.FileType')}</b>
								</Typography>
								<Typography fontSize={'10px'}>
									{t('place.SuggestedDimension')}
									<b>{t('place.Dimension')}</b>
								</Typography>
							</AppAddPhotoButton>
						</Box>
					)}

					{errorMessage && <Error>{errorMessage ?? '\n'}</Error>}
				</Stack>
			</AppInputLayout>
		</>
	);
}

const Error = styled(Typography)(({ theme }) => ({
	minHeight: '15px',
	paddingTop: '2px',
	fontSize: '10px',
	lineHeight: '10px',
	color: theme.palette.error.main,
	whiteSpace: 'pre-line',
}));

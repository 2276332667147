import {
	Button,
	Divider,
	Grid,
	InputAdornment,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';

import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';

interface ProductMappingFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function ProductMappingForm({
	type,
	setType,
}: ProductMappingFormProps) {
	const { t } = useTranslation();

	const formContent = (
		<Stack>
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form = formContent;

	return <>{form}</>;
}

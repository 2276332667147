import { Dispatch, SetStateAction, useEffect } from 'react';
import { FormCardType } from '../../../../common/components/card/FormCard';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Button,
	Divider,
	Grid,
	InputAdornment,
	Stack,
	Typography,
} from '@mui/material';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import AppFormLabel from '../../../../common/components/form-layout/AppFormLabel';
import ApiRoomMappingSearch from '../components/ApiRoomMappingSearch';
import { ApiSupplierType } from '../../../../api/enum/ApiSupplier.enum';
import { useParams } from 'react-router-dom';
import {
	useGetPropertyQuery,
	useGetRoomQuery,
	useUpdateRoomSupplierMutation,
} from '../../../../api/accommodationApiSlice';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppAlert from '../../../../common/components/AppAlert';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Room } from '../../../../api/DTO/room.interface';

interface RoomSupplierFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function RoomSupplierForm({
	type,
	setType,
}: RoomSupplierFormProps) {
	const { t } = useTranslation();
	const { propertyId, roomId } = useParams();

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');
	const hasDirectContract = getPropertyResponse?.data.supplier.directContract;
	const RakutenPropertyApiManpping =
		getPropertyResponse?.data.supplier.mappings[ApiSupplierType.RAKUTEN];
	const MetiuanPropertyApiManpping =
		getPropertyResponse?.data.supplier.mappings[ApiSupplierType.MEITUAN];

	const formSchema = Yup.object().shape({
		supplier: Yup.object()
			.shape({
				minPrice: hasDirectContract
					? Yup.string().required(t('errorMessage.required'))
					: Yup.string().nullable(),
			})
			.required(),
	});

	const { data: getRoomResponse, isLoading: isGetRoomLoading } =
		useGetRoomQuery(roomId ?? '', {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control, watch, setValue } =
		useFormContext() ??
		useForm<Room>({
			defaultValues: getRoomResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const [updateRoomSupplier, { isSuccess: isUpdateRoomSupplierSuccess }] =
		useUpdateRoomSupplierMutation();

	useEffect(() => {
		if (isUpdateRoomSupplierSuccess) setType(FormCardType.view);
	}, [isUpdateRoomSupplierSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateRoomSupplier({
			roomId: roomId ?? '',
			body: data.supplier,
		});
	};

	const formContent = (
		<Stack>
			{!(RakutenPropertyApiManpping || MetiuanPropertyApiManpping) &&
				!hasDirectContract && (
					<Box marginBottom={'15px'}>
						<AppAlert alertColor='#F6403F'>
							{t('alert.RoomNoSupplier')}
						</AppAlert>
					</Box>
				)}

			{hasDirectContract ? (
				<>
					<AppSubtitleLayout label={t('common.DirectContract')} />

					<AppTextField
						control={control}
						name='supplier.minPrice'
						label={t('room.MinimumSellingRate')}
						infoPopUp={t('hint.MinimumSellingRate')}
						inputType={InputType.amount}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									{getPropertyResponse?.data.supplier.currency}
								</InputAdornment>
							),
						}}
						required
					/>
				</>
			) : (
				<AppInputLayout label={t('common.DirectContract')}>
					<Typography fontSize={'12px'} color='#BCBCBC'>
						/
					</Typography>
				</AppInputLayout>
			)}

			{RakutenPropertyApiManpping || MetiuanPropertyApiManpping ? (
				<>
					<Divider sx={{ marginBottom: '15px' }} />

					<AppSubtitleLayout label={t('common.Mapping')} />

					{RakutenPropertyApiManpping && (
						<>
							<AppFormLabel
								label={t('room.RakutenIDAndName')}
								infoPopUp={' '}
							/>

							<ApiRoomMappingSearch
								apiSupplier={ApiSupplierType.RAKUTEN}
								propertyId={RakutenPropertyApiManpping?.id ?? ''}
								control={control}
								name='supplier.mappings.0'
							/>
						</>
					)}

					{MetiuanPropertyApiManpping && (
						<>
							<Divider sx={{ marginBottom: '15px' }} />

							<AppFormLabel
								label={t('room.MeituanIDAndName')}
								infoPopUp={' '}
							/>

							<ApiRoomMappingSearch
								apiSupplier={ApiSupplierType.MEITUAN}
								propertyId={MetiuanPropertyApiManpping?.id ?? ''}
								control={control}
								name='supplier.mappings.1'
							/>
						</>
					)}
				</>
			) : (
				<>
					<Divider sx={{ marginBottom: '15px' }} />

					<AppInputLayout label={t('common.Mapping')}>
						<Typography fontSize={'12px'} color='#BCBCBC'>
							/
						</Typography>
					</AppInputLayout>
				</>
			)}

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetRoomLoading && form}</>;
}

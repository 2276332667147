import { Switch, SwitchProps, styled } from '@mui/material';

interface OnlineSwitchButtonProps {
	onClickAction: () => void;
}

export default function OnlineSwitchButton({
	checked,
	onClickAction,
}: OnlineSwitchButtonProps & SwitchProps) {
	return (
		<StyledSwitch
			disableRipple
			checked={checked}
			onClick={(event) => {
				event.stopPropagation();
				onClickAction();
			}}
		/>
	);
}

const StyledSwitch = styled(Switch)({
	width: 80,
	height: 35,
	padding: '5px 4px',
	'& .MuiSwitch-switchBase': {
		'&.Mui-checked': {
			transform: 'translateX(46px)',
			'& + .MuiSwitch-track': {
				'&:before, &:after': {
					color: 'white',
					fontWeight: 700,
					fontSize: '12px',
					position: 'absolute',
					top: '8px',
				},
				'&:before': {
					content: '"Online"',
					left: 12,
				},
				'&:after': {
					content: '""',
					right: 12,
				},

				backgroundColor: '#A0DD8D',
				opacity: 1,
			},
		},
	},
	'& .MuiSwitch-track': {
		opacity: 1,
		backgroundColor: '#DA5150',
		borderRadius: 30 / 2,
		'&:before, &:after': {
			color: 'white',
			fontWeight: 700,
			fontSize: '12px',
			position: 'absolute',
			top: '8px',
		},
		'&:before': {
			content: '""',
			left: 12,
		},
		'&:after': {
			content: '"Offline"',
			right: 12,
		},
	},
	'& .MuiSwitch-thumb': {
		color: 'white',
		// boxSizing: 'border-box',
		boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.3)',
		width: 16,
		height: 16,
	},
});

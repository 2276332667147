import { Stack, Typography } from '@mui/material';

interface TextWithUnitProps {
	text?: string;
	unit?: string;
}

export default function TextWithUnit({ text, unit }: TextWithUnitProps) {
	return (
		<Stack direction={'row'}>
			<Typography fontSize='12px' color='#2C2C2C' minWidth={'30px'}>
				{text}
			</Typography>
			<Typography fontSize='12px' color='#2C2C2C' marginLeft={'10px'}>
				{unit}
			</Typography>
		</Stack>
	);
}

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {
	Link,
	matchPath,
	useLocation,
	useParams,
	Outlet,
} from 'react-router-dom';
import PageHeader from '../../common/components/PageHeader';
import { useGetSupplierQuery } from '../../api/supplierApiSlice';
import FullScreenLoader from '../../common/components/FullScreenLoader';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DISPLAY_DATE_TIME_FORMAT } from '../../utils/dateHelper';
import dayjs from 'dayjs';

function useRouteMatch(patterns: readonly string[]) {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}

	return null;
}

function SupplierTabs({ currentTab }: { currentTab?: string }) {
	const { t } = useTranslation();
	const { supplierId } = useParams();

	return (
		<Box
			sx={{
				backgroundColor: '#FFFFFF',
				borderRadius: '5px',
				boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
				padding: '0px 20px',
				marginBottom: '15px',
			}}
		>
			{currentTab && (
				<Tabs value={currentTab}>
					<Tab
						label={t('tabs.Overview')}
						value='/supplier/:supplierId/overview'
						to={`/supplier/${supplierId}/overview`}
						component={Link}
					/>
				</Tabs>
			)}
		</Box>
	);
}

export default function SupplierContainer() {
	const { t } = useTranslation();
	const { supplierId } = useParams();
	const { state } = useLocation();

	const [supplierSummarySearchParams, setSupplierSummarySearchParams] =
		useState<string | undefined>(undefined);

	// You need to provide the routes in descendant order.
	// This means that if you have nested routes like:
	// users, users/new, users/edit.
	// Then the order should be ['users/add', 'users/edit', 'users'].
	const routeMatch = useRouteMatch([
		'/supplier/:supplierId/overview',
		// '/supplier/:supplierId/room/*',
	]);

	const currentTab = routeMatch?.pattern?.path;

	useEffect(() => {
		if (state?.supplierSummarySearchParams) {
			setSupplierSummarySearchParams(state?.supplierSummarySearchParams);
		}
	}, []);

	const backPath = supplierSummarySearchParams
		? `/supplier?${supplierSummarySearchParams}`
		: '/supplier';

	const {
		data: getSupplierResponse,
		isLoading: isGetSupplierLoading,
		isSuccess: isGetSupplierSuccess,
		isError: isGetSupplierError,
	} = useGetSupplierQuery(supplierId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	let pageContent;
	if (isGetSupplierLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetSupplierSuccess) {
		pageContent = (
			<Box sx={{ width: '100%' }}>
				<PageHeader
					showBackButton={currentTab == '/supplier/:supplierId/overview'}
					backPath={backPath}
					title={getSupplierResponse.data.baseInfo.nameEn}
					subtitleJsx={
						<Typography fontSize='12px' color='#808080'>{`${t(
							'common.Lastupdate'
						)}: ${
							getSupplierResponse?.data.baseInfo.updatedAt
								? dayjs(getSupplierResponse?.data.baseInfo.updatedAt).format(
										DISPLAY_DATE_TIME_FORMAT
								  )
								: '-'
						}`}</Typography>
					}
				/>
				<SupplierTabs currentTab={currentTab} />
				<Outlet />
			</Box>
		);
	} else if (isGetSupplierError) {
		pageContent = <div>API Error</div>;
	}
	return <div>{pageContent}</div>;
}

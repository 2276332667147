import Option from '../../common/types/option.type';

export enum Locale {
	CHINESE = 'zh-HK',
	ENGLISH = 'en-US',
	JAPANESE = 'ja-JP',
}

export const translationLocaleOptions: Option[] = [
	{
		id: Locale.ENGLISH,
		name: 'English',
	},
	{
		id: Locale.JAPANESE,
		name: '日文',
	},
];

export const abkLocaleOptions: Option[] = [
	{
		id: Locale.CHINESE,
		name: '繁體中文',
	},
	{
		id: Locale.ENGLISH,
		name: 'English',
	},
	{
		id: Locale.JAPANESE,
		name: '日文',
	},
];

import { Button, Typography } from '@mui/material';
import AppModal from './AppModal';

interface DeleteVideoModalProps {
	id: string;
	handleDeleteClick: (id: string) => void;
}

const DeleteGalleryVideoModal = ({
	id,
	handleDeleteClick,
}: DeleteVideoModalProps) => {
	const deleteUserButtonOnClick = () => {
		handleDeleteClick(id);
	};

	return (
		<AppModal
			title={
				<Typography variant='bold' color='#DA5150'>
					Delete Video
				</Typography>
			}
			content={<>Are you sure you want to delete this video?</>}
			actionButtonEl={
				<Button
					variant='contained'
					color='error'
					onClick={deleteUserButtonOnClick}
				>
					Delete
				</Button>
			}
		/>
	);
};

export default DeleteGalleryVideoModal;

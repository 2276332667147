import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetRfcategoriesQuery } from '../../../../api/accommodationApiSlice';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import RoomFacilityForm from './RoomFacilityForm';
import RoomFacilityView from './RoomFacilityView';

interface RoomFacilityCardProps {
	initialType: FormCardType;
}

export default function RoomFacilityCard({
	initialType,
}: RoomFacilityCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	const { propertyId, rfcategoryId } = useParams();

	const { data: getRfcategoriesResponse } = useGetRfcategoriesQuery(
		propertyId ?? ''
	);
	const title = getRfcategoriesResponse?.data.find(
		(category) => category.id === rfcategoryId ?? ''
	);

	return (
		<FormCard type={type} title={title?.nameEn ?? ''}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <RoomFacilityView setType={setType} />;
					case FormCardType.edit:
						return <RoomFacilityForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

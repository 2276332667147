import { Box, Grid, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import TranslationListCard from './components/TranslationListCard';

export default function TranslationContainer() {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Grid container spacing={'20px'}>
				<Grid item style={{ width: '252px' }}>
					<Stack width={'100%'} spacing={'15px'}>
						<TranslationListCard />
					</Stack>
				</Grid>
				<Grid item xs zeroMinWidth>
					<Outlet />
				</Grid>
			</Grid>
		</Box>
	);
}

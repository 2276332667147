import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { FormCardType } from '../../../../../common/components/card/FormCard';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Stack } from '@mui/material';
import UnderlinedIconButton from '../../../../../common/components/buttons/UnderlinedIconButton';
import { ReactComponent as EditIcon } from '../../../../../assets/icon/edit.svg';
import { RatePlanIdContext } from '../RatePlanDetailPage';
import AppInputLayout from '../../../../../common/components/form-layout/AppInputLayout';
import NonEditableDisplayText from '../../../../../common/components/form-data-display/NonEditableDisplayText';
import {
	useGetPropertyQuery,
	useLazyGetRatePlanQuery,
} from '../../../../../api/accommodationApiSlice';
import AppSubtitleLayout from '../../../../../common/components/form-layout/AppSubtitleLayout';
import {
	RatePlanNameType,
	getRatePlanNameTypeLabel,
} from '../../../../../api/enum/ratePlanNameType.enum';
import {
	getRateModel,
	getRatePlanMealType,
} from '../../../../../utils/accomodationHelper';
import {
	MealType,
	getMealTypeNameByMealType,
} from '../../../../../api/enum/mealType.enum';
import NewDisplayText from '../../../../../common/components/form-data-display/NewDisplayText';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import YesNoDisplay from '../../../../../common/components/form-data-display/YesNoDisplay';
import {
	API_DATE_FORMAT,
	DISPLAY_DATE_FORMAT,
	DISPLAY_DATE_TIME_FORMAT,
} from '../../../../../utils/dateHelper';
import {
	RatePlanChannel,
	getRatePlanChannel,
} from '../../../../../api/enum/ratePlanChannel.enum';
import { PriceMode } from '../../../../../api/enum/priceMode.enum';
import { apiSupplierOptions } from '../../../../../api/enum/ApiSupplier.enum';
import ApiSupplierLabelDisplay from '../../../../../common/components/form-data-display/ApiSupplierLabelDisplay';
import { mealRateCalculationOptions } from '../../../../../api/enum/mealRateCalculationOptions';

export default function RatePlanBaseInfoView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const { propertyId, roomId } = useParams();
	const ratePlanId = useContext(RatePlanIdContext);
	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');
	const [getRatePlan, { data: getRatePlanResponse }] =
		useLazyGetRatePlanQuery();

	useEffect(() => {
		getRatePlan(ratePlanId);
	}, [getRatePlanResponse]);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppInputLayout label={t('room.ratePlan.RatePlanId')}>
					<NonEditableDisplayText text={ratePlanId} />
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('property.SupplierMapping')} />

				<AppInputLayout label={t('room.ratePlan.RatePlanChannel')}>
					<NewDisplayText
						text={getRatePlanChannel(
							getRatePlanResponse?.data.baseInfo.channel
						)}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('room.ratePlan.RateModel')}>
					<NewDisplayText
						text={getRateModel(getRatePlanResponse?.data.baseInfo.rateModel)}
					/>
				</AppInputLayout>

				{getRatePlanResponse?.data.baseInfo.channel ===
					RatePlanChannel.APISUPPLIER && (
					<AppInputLayout label={t('room.ratePlan.ApiSupplier')}>
						<ApiSupplierLabelDisplay
							apiSupplier={getRatePlanResponse?.data.baseInfo.apiSupplier}
							label={
								apiSupplierOptions.find(
									(item) =>
										item.id == getRatePlanResponse?.data.baseInfo.apiSupplier
								)?.name
							}
						/>
					</AppInputLayout>
				)}

				{getPropertyResponse?.data.supplier.priceMode === PriceMode.PER_ROOM &&
					getRatePlanResponse?.data.baseInfo.channel ==
						RatePlanChannel.DIRECT && (
						<AppInputLayout label={t('room.ratePlan.EnableSupplement')}>
							<YesNoDisplay
								value={getRatePlanResponse?.data.baseInfo.enableSupplement}
							/>
						</AppInputLayout>
					)}

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('room.ratePlan.RatePlanName')} />

				<AppInputLayout label={t('room.ratePlan.Type')}>
					<NewDisplayText
						text={getRatePlanNameTypeLabel(
							getRatePlanResponse?.data.baseInfo.nameType
						)}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('common.English')}>
					{getRatePlanResponse?.data.baseInfo.nameType ==
					RatePlanNameType.SYS_GEN ? (
						<NonEditableDisplayText
							text={getRatePlanResponse?.data.baseInfo.nameEn}
						/>
					) : (
						<NewDisplayText text={getRatePlanResponse?.data.baseInfo.nameEn} />
					)}
				</AppInputLayout>

				<AppInputLayout label={t('common.TraditionalChinese')}>
					{getRatePlanResponse?.data.baseInfo.nameType ==
					RatePlanNameType.SYS_GEN ? (
						<NonEditableDisplayText
							text={getRatePlanResponse?.data.baseInfo.name}
						/>
					) : (
						<NewDisplayText text={getRatePlanResponse?.data.baseInfo.name} />
					)}
				</AppInputLayout>

				<AppInputLayout label={t('common.Original')}>
					{getRatePlanResponse?.data.baseInfo.nameType ==
					RatePlanNameType.SYS_GEN ? (
						<NonEditableDisplayText
							text={getRatePlanResponse?.data.baseInfo.originalName}
						/>
					) : (
						<NewDisplayText
							text={getRatePlanResponse?.data.baseInfo.originalName}
						/>
					)}
				</AppInputLayout>

				<AppInputLayout label={t('room.ratePlan.MealType')}>
					<NewDisplayText
						text={getRatePlanMealType(
							getRatePlanResponse?.data.baseInfo.mealType
						)}
					/>
				</AppInputLayout>

				{getRatePlanResponse?.data.baseInfo.mealType !== MealType.NO_MEAL && (
					<AppInputLayout label={t('room.ratePlan.MealRateCalculation')}>
						<NewDisplayText
							text={
								mealRateCalculationOptions.find(
									(i) =>
										i.id ===
										getRatePlanResponse?.data.baseInfo.withMealSupplement
								)?.name ?? ''
							}
						/>
					</AppInputLayout>
				)}

				<AppInputLayout label={t('room.ratePlan.EstimatedPrice')}>
					<YesNoDisplay
						value={getRatePlanResponse?.data.baseInfo.estimatedPrice}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				{getRatePlanResponse?.data.baseInfo.channel ==
					RatePlanChannel.DIRECT && (
					<>
						<AppSubtitleLayout label={t('room.ratePlan.OptionalMeals')} />

						<AppInputLayout label={t('room.ratePlan.EnableOptionalMeals')}>
							<YesNoDisplay
								value={getRatePlanResponse?.data.baseInfo.enableOptionalMeal}
							/>
						</AppInputLayout>

						{getRatePlanResponse?.data.baseInfo.enableOptionalMeal && (
							<AppInputLayout label={t('room.ratePlan.MealList')}>
								<NewDisplayText
									text={getRatePlanResponse?.data.baseInfo.optionalMealTypes
										.map((mealType) => getMealTypeNameByMealType(mealType))
										.join(', ')}
								/>
							</AppInputLayout>
						)}
					</>
				)}

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout label={t('room.ratePlan.BookingPeriod')}>
					<Grid container columnSpacing='20px' padding='0px'>
						<Grid item xs={6}>
							<NewDisplayText
								text={`${t('common.From')}\t${dayjs(
									getRatePlanResponse?.data.baseInfo.bookingPeriod?.start
								).format(DISPLAY_DATE_TIME_FORMAT)}`}
							/>
						</Grid>
						<Grid item xs={6}>
							<NewDisplayText
								text={`${t('common.To')} ${
									getRatePlanResponse?.data.baseInfo.bookingPeriod?.end != null
										? '\t' +
										  dayjs(
												getRatePlanResponse?.data.baseInfo.bookingPeriod?.end
										  ).format(DISPLAY_DATE_TIME_FORMAT)
										: '\t∞'
								} `}
							/>
						</Grid>
					</Grid>
				</AppInputLayout>

				<AppInputLayout label={t('room.ratePlan.CheckInPeriod')}>
					<Grid container columnSpacing='20px' padding='0px'>
						<Grid item xs={6}>
							<NewDisplayText
								text={`${t('common.From')}\t${dayjs(
									getRatePlanResponse?.data.baseInfo.checkInPeriod?.start,
									API_DATE_FORMAT
								).format(DISPLAY_DATE_FORMAT)}`}
							/>
						</Grid>
						<Grid item xs={6}>
							<NewDisplayText
								text={`${t('common.To')} ${
									getRatePlanResponse?.data.baseInfo.checkInPeriod?.end
										? dayjs(
												getRatePlanResponse?.data.baseInfo.checkInPeriod?.end,
												API_DATE_FORMAT
										  ).format(DISPLAY_DATE_FORMAT)
										: '\t∞'
								} `}
							/>
						</Grid>
					</Grid>
				</AppInputLayout>

				<AppInputLayout label={t('room.ratePlan.MinimumStayDays')}>
					<NewDisplayText
						text={getRatePlanResponse?.data.baseInfo.stayDay.min.toString()}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('room.ratePlan.MaximumStayDays')}>
					<NewDisplayText
						text={getRatePlanResponse?.data.baseInfo.stayDay.max.toString()}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('room.ratePlan.CutOffDays')}>
					<NewDisplayText
						text={getRatePlanResponse?.data.baseInfo.cutOffDay.toString()}
					/>
				</AppInputLayout>
			</Stack>
		</>
	);
}

export enum PaymentMethod {
	CREDIT_CARD = 0,
	FPS = 1,
}

export function getPaymentMethods(paymentMethod: PaymentMethod[]): string[] {
	const paymentMethodStrings = [];
	for (let i = 0; i < paymentMethod.length; i++) {
		switch (paymentMethod[i]) {
			case PaymentMethod.CREDIT_CARD:
				paymentMethodStrings.push('Credit Card');
				break;
			case PaymentMethod.FPS:
				paymentMethodStrings.push('FPS/Bank Transfer');
				break;
		}
	}

	return paymentMethodStrings;
}

export function getPaymentMethodLabel(paymentMethod: PaymentMethod): string {
	switch (paymentMethod) {
		case PaymentMethod.CREDIT_CARD:
			return 'Credit Card';
		case PaymentMethod.FPS:
			return 'FPS/Bank Transfer';
		default:
			return '';
	}
}

export const paymentMethodOptions = [
	{
		id: PaymentMethod.CREDIT_CARD,
		name: 'Credit Card',
	},
	{
		id: PaymentMethod.FPS,
		name: 'FPS/Bank Transfer',
	},
];

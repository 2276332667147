import Option from '../../common/types/option.type';

export enum Bed {
	SINGLE_BED = 0,
	SEMI_DOUBLE_BED = 1,
	DOUBLE_BED = 2,
	QUEEN_BED = 3,
	KING_BED = 4,
	BUNK_BED = 5,
	TRUNDLE_BED = 6,
	CAMPING_MATTRESS = 7,
	ROLLAWAY_BED = 8,
	SOFA_BED = 9,
	BABY_COT = 10,
	JAPANESE_TATAMI = 11,
}

export const bedOption: Option[] = [
	{
		id: Bed.SINGLE_BED,
		name: 'Single Bed （ 90 - 100cm wide ）',
		shortName: 'Single Bed',
		remark: '( 90 - 100cm wide )',
	},
	{
		id: Bed.TRUNDLE_BED,
		name: 'Trundle Bed',
		shortName: 'Trundle Bed',
	},
	{
		id: Bed.SEMI_DOUBLE_BED,
		name: 'Semi Double-Bed（ 101 - 120cm wide ）',
		shortName: 'Semi Double-Bed',
		remark: '( 101 - 120cm wide )',
	},
	{
		id: Bed.CAMPING_MATTRESS,
		name: 'Camping Mattress',
		shortName: 'Camping Mattress',
	},
	{
		id: Bed.DOUBLE_BED,
		name: 'Double Bed（ 121 - 140cm wide ）',
		shortName: 'Double Bed',
		remark: '( 121 - 140cm wide )',
	},
	{
		id: Bed.ROLLAWAY_BED,
		name: 'Rollaway Bed',
		shortName: 'Rollaway Bed',
	},
	{
		id: Bed.QUEEN_BED,
		name: 'Queen Bed（ 141 - 170cm wide ）',
		shortName: 'Queen Bed',
		remark: '( 141 - 170cm wide )',
	},
	{
		id: Bed.SOFA_BED,
		name: 'Sofa Bed',
		shortName: 'Sofa Bed',
	},
	{
		id: Bed.KING_BED,
		name: 'King Bed（ over 170cm wide ）',
		shortName: 'King Bed',
		remark: '( over 170cm wide )',
	},
	{
		id: Bed.BABY_COT,
		name: 'Baby Cot ( on request )',
		shortName: 'Baby Cot',
		remark: '( on request )',
	},
	{
		id: Bed.BUNK_BED,
		name: 'Bunk Bed',
		shortName: 'Bunk Bed',
	},
	{
		id: Bed.JAPANESE_TATAMI,
		name: 'Japanese Tatami',
		shortName: 'Japanese Tatami',
	},
];

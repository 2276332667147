import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import AppInputLayout from '../form-layout/AppInputLayout';
import { StyledTextField } from '../styled/StyledTextField';
import { ReactComponent as CalenderIcon } from '../../../assets/icon/calendar.svg';
import { ReactComponent as CalenderErrorIcon } from '../../../assets/icon/calendar-error.svg';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT, INVALID_DATE } from '../../../utils/dateHelper';

interface AppISO8601DatePickerProps {
	label?: string;
	required?: boolean;
	minimumDate?: string;
	placeholder?: string;
	removeErrorPadding?: boolean;
}

export default function AppISO8601DatePicker<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	required,
	minimumDate,
	placeholder,
	removeErrorPadding,
	...props
}: AppISO8601DatePickerProps & UseControllerProps<TFieldValues, TName>) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<AppInputLayout
			label={label}
			required={required}
			errorMessage={errorMessage}
			removeErrorPadding={removeErrorPadding}
		>
			<DatePicker
				{...field}
				inputFormat={DISPLAY_DATE_FORMAT}
				minDate={
					minimumDate === '' || minimumDate === undefined
						? undefined
						: dayjs(minimumDate).format()
				}
				value={
					field.value === '' || field.value === undefined
						? null
						: dayjs(field.value).format()
				}
				onChange={(value) => {
					if (value === null) {
						field.onChange(null);
					} else if (dayjs(value).format() === INVALID_DATE) {
						field.onChange(dayjs(value).format());
					} else {
						field.onChange(dayjs(value).toISOString());
					}
				}}
				components={{
					OpenPickerIcon: fieldState.error ? CalenderErrorIcon : CalenderIcon,
				}}
				renderInput={(params) => {
					const formatPlaceholder = params.inputProps?.placeholder;
					return (
						<StyledTextField
							{...params}
							inputProps={{
								...params.inputProps,
								placeholder: placeholder ? placeholder : formatPlaceholder,
							}}
							sx={{
								'& .MuiInputLabel-outlined': {
									transform: 'translate(13.5px, 8px) scale(1)',
								},
								'& .MuiInputLabel-shrink': {
									transform: 'translate(13.5px, -8px) scale(1) !important',
								},
							}}
							label={required ? `*` : ``}
							error={!!errorMessage}
						/>
					);
				}}
			/>
		</AppInputLayout>
	);
}

import {
	AddTag,
	Section,
	SectionTag,
	ShortNotice,
	Tag,
} from './DTO/page.interface';
import { apiSlice } from './apiSlice';
import { SuccessResponse } from './response/shared/success.response';

const SERVICE_PATH = '/page-service';

export const PageApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// Short Notice

		getShortNotice: builder.query<SuccessResponse<ShortNotice>, void>({
			query: () => ({
				url: `${SERVICE_PATH}/admin/short-notice`,
			}),
			providesTags: ['ShortNotice'],
		}),

		updateShortNotice: builder.mutation<
			SuccessResponse<ShortNotice>,
			ShortNotice
		>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/short-notice`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['ShortNotice'],
		}),

		// Section

		addSection: builder.mutation<SuccessResponse<Section>, Section>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/sections`,
				method: 'POST',
				body,
			}),
		}),

		getSection: builder.query<SuccessResponse<Section[]>, void>({
			query: () => `${SERVICE_PATH}/admin/sections`,
			providesTags: ['Section'],
		}),

		getASection: builder.query<SuccessResponse<Section>, string>({
			query: (sectionId) => ({
				url: `${SERVICE_PATH}/admin/sections/${sectionId}`,
			}),
			providesTags: ['ASection'],
		}),

		updateSection: builder.mutation<
			SuccessResponse<Section>,
			{ sectionId: string; body: Section }
		>({
			query: ({ sectionId, body }) => ({
				url: `${SERVICE_PATH}/admin/sections/${sectionId}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['ASection'],
		}),

		deleteSection: builder.mutation<SuccessResponse<null>, string>({
			query: (sectionId) => ({
				url: `${SERVICE_PATH}/admin/sections/${sectionId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Section'],
		}),

		//Tag

		addPageTag: builder.mutation<SuccessResponse<Tag>, AddTag>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/tags`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['SectionTags'],
		}),

		getPageTags: builder.query<SuccessResponse<Tag[]>, void>({
			query: () => ({
				url: `${SERVICE_PATH}/admin/tags`,
			}),
			providesTags: ['SectionTags'],
		}),

		updateSectionTagPosition: builder.mutation<
			SuccessResponse<SectionTag>,
			{ sectionId: string; body: SectionTag }
		>({
			query: ({ sectionId, body }) => ({
				url: `${SERVICE_PATH}/admin/sections/${sectionId}/tag-positions`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['SectionTagsOrder'],
		}),

		getSectionTagPosition: builder.query<SuccessResponse<SectionTag>, string>({
			query: (sectionId) => ({
				url: `${SERVICE_PATH}/admin/sections/${sectionId}/tag-positions`,
			}),
			providesTags: ['SectionTagsOrder'],
		}),
	}),
});

export const {
	useGetShortNoticeQuery,
	useUpdateShortNoticeMutation,
	useAddSectionMutation,
	useGetSectionQuery,
	useGetASectionQuery,
	useUpdateSectionMutation,
	useDeleteSectionMutation,
	useAddPageTagMutation,
	useGetPageTagsQuery,
	useUpdateSectionTagPositionMutation,
	useGetSectionTagPositionQuery,
} = PageApiSlice;

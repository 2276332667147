import { ButtonBase, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AppFilterSectionLayoutProps {
	label: string;
	clearDisabled: boolean;
	clearOnClick: () => void;
}

export default function AppFilterSectionLayout({
	label,
	clearDisabled,
	clearOnClick,
	children,
}: React.PropsWithChildren<AppFilterSectionLayoutProps>) {
	const { t } = useTranslation();

	return (
		<Stack width={'100%'} padding={'16px'}>
			<Stack
				direction='row'
				justifyContent='space-between'
				paddingBottom={'16px'}
			>
				<Label>{label}</Label>
				<ClearButton disabled={clearDisabled} onClick={clearOnClick}>
					<Typography variant='regular'>{t('common.clear')}</Typography>
				</ClearButton>
			</Stack>
			{children}
		</Stack>
	);
}

const Label = styled(Typography)({
	fontSize: '14px',
	fontWeight: '700',
});

const ClearButton = styled(ButtonBase)(({ theme }) => ({
	fontSize: '0.75rem',
	lineHeight: '0.9375rem',
	height: '100%',
	color: theme.palette.primary.main,
	textDecoration: 'underline',
	'&.Mui-disabled': {
		color: theme.palette.common.silver,
		textDecoration: 'none',
	},
}));

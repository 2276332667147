import { Divider, InputAdornment, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppTextField from '../../../../common/components/form-inputs/AppTextField';
import { ReactComponent as LinkIcon } from '../../../../assets/icon/link.svg';
import { FormCardType } from '../../../../common/components/card/FormCard';
import dayjs from 'dayjs';
import { AbkStatus } from '../../../../api/enum/abkStatus.enum';
import { getRatePlanConfirmType } from '../../../../api/enum/confirmType.enum';
import {
	PaymentMethod,
	paymentMethodOptions,
} from '../../../../api/enum/paymentMethod.enum';
import { getRatePlanPaymentType } from '../../../../api/enum/paymentType.enum';
import AppAlert from '../../../../common/components/AppAlert';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import AppDateTimePicker from '../../../../common/components/form-inputs/AppDateTimePicker';
import AppRadioGroup from '../../../../common/components/form-inputs/AppRadioGroup';
import AppSwitch from '../../../../common/components/form-inputs/AppSwitch';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { useEffect } from 'react';

interface CustomerPaymentInfoFormProps {
	type: FormCardType;
	isAmendment?: boolean;
}

export default function CustomerPaymentInfoForm({
	type,
	isAmendment,
}: CustomerPaymentInfoFormProps) {
	const { t } = useTranslation();

	const { control, watch, setValue, resetField } = useFormContext();
	const watchStatus = watch('baseInfo.status');
	const watchFurtherPayment = watch('customerPayment.furtherPayment');
	const watchPaymentMethod = watch('customerPayment.paymentMethod');
	const watchPayDate = watch('customerPayment.payDate');

	useEffect(() => {
		if (
			watchStatus === AbkStatus.ENQUIRY &&
			(type === FormCardType.edit || type === FormCardType.specialEdit)
		) {
			if (watchPaymentMethod === null || watchPaymentMethod === undefined) {
				resetField('customerPayment.paymentMethod');
			}
			if (watchPayDate === null || watchPayDate === undefined) {
				resetField('customerPayment.payDate');
			}
		}
	}, [watchStatus]);

	const formContent = (
		<Stack>
			<AppSubtitleLayout label={t('abk.PrePayment')} />

			<AppInputLayout label={t('abk.ConfirmType')}>
				<NonEditableDisplayText text={getRatePlanConfirmType(1)} />
			</AppInputLayout>

			<AppInputLayout label={t('abk.PaymentType')}>
				<NonEditableDisplayText text={getRatePlanPaymentType(0)} />
			</AppInputLayout>

			{(watchStatus === AbkStatus.NEW ||
				watchStatus === AbkStatus.CONFIRMED) && (
				<>
					<Divider sx={{ marginBottom: '15px' }} />

					<AppSubtitleLayout label={t('abk.PaymentDetails')} infoPopUp=' ' />

					{isAmendment && (
						<>
							<AppSwitch
								control={control}
								name='customerPayment.furtherPayment'
								label={t('abk.FurtherPayment')}
							/>
							{!watchFurtherPayment && (
								<AppAlert alertColor='#32A5F1'>
									{t('alert.AbkCustomerPaymentNoFurtherPayment')}
								</AppAlert>
							)}
						</>
					)}

					{(type === FormCardType.create ||
						((type === FormCardType.edit ||
							type === FormCardType.specialEdit) &&
							!isAmendment) ||
						((type === FormCardType.edit ||
							type === FormCardType.specialEdit) &&
							isAmendment &&
							watchFurtherPayment)) && (
						<>
							<AppRadioGroup
								control={control}
								name='customerPayment.paymentMethod'
								label={t('abk.PaymentMethod')}
								options={paymentMethodOptions}
							/>

							{watchPaymentMethod == PaymentMethod.CREDIT_CARD && (
								<AppTextField
									control={control}
									name='customerPayment.paymentLink'
									label={t('abk.PaymentLink')}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<LinkIcon />
											</InputAdornment>
										),
									}}
									required
								/>
							)}

							<AppInputLayout
								label={t('abk.CustomerPaymentDeadline')}
								removeErrorPadding
								required
							>
								<AppDateTimePicker
									control={control}
									name={'customerPayment.payDate'}
									minimumDate={dayjs().startOf('day').format()}
									// maximumDate={dayjs(watchStayPeriodStart, API_DATE_FORMAT).format()} // on hold, TBC
								/>
							</AppInputLayout>
							{isAmendment && watchFurtherPayment && (
								<AppAlert alertColor='#32A5F1'>
									{t('alert.AbkCustomerPaymentFurtherPayment')}
								</AppAlert>
							)}
						</>
					)}
				</>
			)}
		</Stack>
	);

	const form = formContent;

	return <>{form}</>;
}

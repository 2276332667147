import { List, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListCard from '../../../../common/components/card/ListCard';
import PropertyList from './PropertyList';
import RoomList from './RoomList';

export default function FacilityListCard() {
	const { t } = useTranslation();

	return (
		<ListCard
			headerTitle={t('facility.Facilities')}
			content={
				<StyledList>
					<PropertyList />
					<RoomList />
				</StyledList>
			}
		/>
	);
}

const StyledList = styled(List)({
	marginTop: '20px',
	padding: 0,
});

import { Button, Typography } from '@mui/material';
import AppModal from './AppModal';
import { useDeleteUserMutation } from '../../../api/userApiSlice';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal, showModal } from './modalSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

interface DeleteUserModalProps {
	userId: string;
	nickname: string;
}

const DeleteUserModal = ({ userId, nickname }: DeleteUserModalProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [deleteUser, { isSuccess }] = useDeleteUserMutation();

	const deleteUserButtonOnClick = () => {
		deleteUser(userId);
	};

	useEffect(() => {
		if (isSuccess) {
			dispatch(hideModal());

			dispatch(
				showModal({
					modalType: 'DELETE_USER_SUCCESS',
					modalProps: {
						nickname,
					},
				})
			);

			navigate('/sys-user');
		}
	}, [isSuccess]);

	return (
		<AppModal
			title={
				<Typography variant='bold' color='#DA5150'>
					Delete {nickname}
				</Typography>
			}
			content={
				<>
					Are you sure to delete the account of{' '}
					<Typography component='span' variant='inherit' color='#DA5150'>
						{nickname}
					</Typography>
					, which the user will no longer exist in the admin portal?
				</>
			}
			actionButtonEl={
				<Button
					variant='contained'
					color='error'
					onClick={deleteUserButtonOnClick}
				>
					Delete
				</Button>
			}
		/>
	);
};

export default DeleteUserModal;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { Provider } from 'react-redux';
import { store } from './store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SW_INIT, SW_UPDATE } from './types';
import { setSWState } from './serviceWorkerSlice';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<Provider store={store}>
		<React.StrictMode>
			<Suspense>
				<RouterProvider router={router} />
			</Suspense>
		</React.StrictMode>
	</Provider>
);

serviceWorkerRegistration.register({
	onSuccess: () => {
		store.dispatch(
			setSWState({ swType: SW_INIT, serviceWorkerRegistration: null })
		);
	},

	onUpdate: (registration) => {
		store.dispatch(
			setSWState({ swType: SW_UPDATE, serviceWorkerRegistration: registration })
		);
	},
});

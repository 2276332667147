import TitleCard from '../../../../common/components/card/TitleCard';
import { useTranslation } from 'react-i18next';
import PropertyFixedForm from './propertyFixedForm';

export default function PropertyFixedCard() {
	const { t } = useTranslation();

	return (
		<TitleCard title={t('gallery.Property')}>
			{(() => {
				return <PropertyFixedForm />;
			})()}
		</TitleCard>
	);
}

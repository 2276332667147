import { Box, Grid, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../common/components/buttons/UnderlinedIconButton';
import { FormCardType } from '../../../common/components/card/FormCard';
import NewDisplayText from '../../../common/components/form-data-display/NewDisplayText';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../common/components/form-layout/AppSubtitleLayout';
import {
	useGetPropertyPerPersonConfigQuery,
	useGetPropertyQuery,
} from '../../../api/accommodationApiSlice';
import { useParams } from 'react-router-dom';
import { PriceMode } from '../../../api/enum/priceMode.enum';
import YesNoDisplay from '../../../common/components/form-data-display/YesNoDisplay';
import {
	ChildPriceType,
	childPriceTypeOptions,
} from '../../../api/enum/childPriceType.enum';
import { RateModel, rateModelOptions } from '../../../api/enum/rateModel.enum';
import { MarkupType } from '../../../api/enum/markupType.enum';

export default function KidsSupplementView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const { propertyId } = useParams();

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');
	const { data: NETTPerPersonConfigResponse } =
		useGetPropertyPerPersonConfigQuery(
			{
				propertyId: propertyId ?? '',
				rateModel: RateModel.AGENCY,
			},
			{
				skip: !getPropertyResponse?.data?.supplier.rateModels?.includes(
					RateModel.AGENCY
				),
			}
		);

	const { data: MERCHANTPerPersonConfigResponse } =
		useGetPropertyPerPersonConfigQuery(
			{
				propertyId: propertyId ?? '',
				rateModel: RateModel.MERCHANT,
			},
			{
				skip: !getPropertyResponse?.data?.supplier.rateModels?.includes(
					RateModel.MERCHANT
				),
			}
		);

	const formContent = (
		<Stack position={'relative'}>
			<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
				<UnderlinedIconButton
					icon={<EditIcon color='#32A5F1' />}
					onClick={() => {
						setType(FormCardType.edit);
					}}
				>
					{t('button.Edit')}
				</UnderlinedIconButton>
			</Box>
			{getPropertyResponse?.data.supplier.priceMode ===
				PriceMode.PER_PERSON && (
				<>
					<Grid container alignItems='stretch' columnSpacing={'20px'}>
						{[
							{
								rateModel: RateModel.AGENCY,
								perPersonConfig: NETTPerPersonConfigResponse?.data,
							},
							{
								rateModel: RateModel.MERCHANT,
								perPersonConfig: MERCHANTPerPersonConfigResponse?.data,
							},
						].map(({ rateModel, perPersonConfig }, index) => (
							<>
								{perPersonConfig && (
									<Grid
										key={index}
										item
										xs={6}
										borderLeft={index > 0 ? '1px solid #E4E8EA' : ''}
									>
										<Typography
											color={'#4EA4D3'}
											fontSize={'16px'}
											fontWeight={'bold'}
											marginBottom={'20px'}
										>
											{rateModelOptions.find((i) => i.id === rateModel)?.name ??
												''}
										</Typography>
										{[
											{
												label: t('common.Infants'),
												isAllowed: getPropertyResponse?.data.policy.allowInfant,
												priceConfig: perPersonConfig.infantPriceConfig,
											},
											{
												label: t('common.Toddlers'),
												isAllowed:
													getPropertyResponse?.data.policy.allowToddler,
												priceConfig: perPersonConfig.toddlerPriceConfig,
											},
											{
												label: t('common.Children'),
												isAllowed:
													getPropertyResponse?.data.policy.allowChildren,
												priceConfig: perPersonConfig.childPriceConfig,
											},
										].map(({ label, isAllowed, priceConfig }) => (
											<>
												{isAllowed && (
													<>
														<AppSubtitleLayout label={label} />

														<AppInputLayout
															label={t('room.ratePlan.PriceType')}
														>
															{priceConfig?.priceType ===
															ChildPriceType.PERCENTAGE ? (
																<NewDisplayText
																	text={`${priceConfig?.amount} %`}
																/>
															) : priceConfig?.priceType ===
															  ChildPriceType.FIXED ? (
																<NewDisplayText
																	text={`${perPersonConfig?.currency} ${priceConfig?.amount}`}
																/>
															) : (
																<NewDisplayText
																	text={
																		childPriceTypeOptions.find(
																			(option) =>
																				option.id == priceConfig?.priceType
																		)?.name ?? ''
																	}
																/>
															)}
														</AppInputLayout>

														{rateModel == RateModel.AGENCY &&
															priceConfig?.priceType == ChildPriceType.FIXED &&
															!perPersonConfig?.sellAtOfficialRate && (
																<AppInputLayout label={t('common.Markup')}>
																	{priceConfig?.markup?.type ===
																		MarkupType.FIXED && (
																		<NewDisplayText
																			text={`${perPersonConfig?.currency} ${priceConfig?.markup?.amount}`}
																		/>
																	)}

																	{priceConfig?.markup?.type ===
																		MarkupType.PERCENTAGE && (
																		<NewDisplayText
																			text={`${priceConfig?.markup?.amount} %`}
																		/>
																	)}
																</AppInputLayout>
															)}
													</>
												)}
											</>
										))}
									</Grid>
								)}
							</>
						))}
					</Grid>
				</>
			)}
		</Stack>
	);
	return <>{formContent}</>;
}

import Option from '../../common/types/option.type';

export const AbkProvidedBySupplierOptions: Option[] = [
	{
		id: true,
		name: 'Yes',
	},
	{
		id: false,
		name: 'No',
	},
];

import { List, styled } from '@mui/material';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetRoomSummaryQuery } from '../../../../api/accommodationApiSlice';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SelectIcon } from '../../../../assets/icon/underlined-button-icon-select.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icon/underlined-button-icon-add.svg';
import ListCard from '../../../../common/components/card/ListCard';
import ListItemLink from '../../../../common/components/list/ListItemLink';
import { useEffect } from 'react';

export default function RoomListCard({ isViewRoom }: { isViewRoom?: boolean }) {
	const { propertyId, roomId } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data: getRoomSummaryResponse } = useGetRoomSummaryQuery({
		propertyId: propertyId ?? '',
	});

	// default view first room of the list
	useEffect(() => {
		if (!roomId && isViewRoom) {
			const firstRoom = getRoomSummaryResponse?.data[0];
			if (firstRoom) {
				navigate(`/property/${propertyId}/room/${firstRoom.id}`);
			}
		}
	}, [roomId, getRoomSummaryResponse]);

	return (
		<ListCard
			headerTitle={t('room.RoomList')}
			headerActionButtonNodes={[
				<UnderlinedIconButton
					key='add-room'
					icon={<AddIcon />}
					onClick={() => {
						navigate(`/property/${propertyId}/room/c`);
					}}
				>
					{t('room.AddRoom')}
				</UnderlinedIconButton>,
				<UnderlinedIconButton
					key='select-room-class'
					icon={<SelectIcon />}
					onClick={() => {
						navigate(`/property/${propertyId}/room/src`);
					}}
				>
					{t('room.RoomRelationship')}
				</UnderlinedIconButton>,
			]}
			content={
				<StyledList>
					{getRoomSummaryResponse?.data?.map((room) => (
						<ListItemLink
							key={room.id}
							room={room}
							selected={room.id == roomId}
							to={`/property/${propertyId}/room/${room.id}`}
						/>
					))}
				</StyledList>
			}
		/>
	);
}

const StyledList = styled(List)({
	marginTop: '20px',
	padding: 0,
});

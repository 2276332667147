import { Button, Typography } from '@mui/material';
import AppModal from './AppModal';
import { useDeleteAbkMutation } from '../../../api/abkApiSlice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal, showModal } from './modalSlice';

interface DeleteAbkEnquiryModalProps {
	id: string;
}

const DeleteAbkEnquiryImageModal = ({ id }: DeleteAbkEnquiryModalProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [deleteAbkMutation, { isSuccess }] = useDeleteAbkMutation();

	const deleteAbkEnquiryButtonOnClick = () => {
		deleteAbkMutation(id);
	};

	useEffect(() => {
		if (isSuccess) {
			dispatch(hideModal());
			navigate('/abks');
		}
	}, [isSuccess]);

	return (
		<AppModal
			title={
				<Typography variant='bold' color='#DA5150'>
					Delete Enquiry {id}
				</Typography>
			}
			content={
				<>
					Are you sure you want to delete
					<Typography variant='bold' color='#DA5150'>
						{' '}
						{id}{' '}
					</Typography>
					, which the enquiry will no longer valid for all parties?
				</>
			}
			actionButtonEl={
				<Button
					variant='contained'
					color='error'
					onClick={deleteAbkEnquiryButtonOnClick}
				>
					Delete
				</Button>
			}
		/>
	);
};

export default DeleteAbkEnquiryImageModal;

import Option from '../types/option.type';

export const currencies: Option[] = [
	{
		id: 'HKD',
		name: 'Hong Kong Dollar',
		iso2: 'HK',
	},
	{
		id: 'USD',
		name: 'United States Dollar',
		iso2: 'US',
	},
	{
		id: 'JPY',
		name: 'Japanese Yen',
		iso2: 'JP',
	},
	{
		id: 'CNY',
		name: 'Chinese Yuan',
		iso2: 'CN',
	},
	{
		id: 'TWD',
		name: 'Taiwan Dollar',
		iso2: 'TW',
	},
];

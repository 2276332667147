import { Button, Typography } from '@mui/material';
import AppModal from './AppModal';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal } from './modalSlice';
import { useUpdateBlogPostStatusMutation } from '../../../api/contentApiSlice';

interface DeactivateBlogModalProps {
	blogId: string;
	title: string;
}

const DeactivateBlogModal = ({ blogId, title }: DeactivateBlogModalProps) => {
	const dispatch = useAppDispatch();
	const [updateBlogPostStatus] = useUpdateBlogPostStatusMutation();

	const DeactivateBlogButtonOnClick = () => {
		updateBlogPostStatus({
			blogId,
			body: {
				status: 1,
			},
		})
			.unwrap()
			.then(() => {
				dispatch(hideModal());
			});
	};

	return (
		<AppModal
			title={
				<>
					Deactivate Blog{' '}
					<Typography variant='bold' color='#4EA4D3'>
						{title}
					</Typography>
				</>
			}
			content={
				<>
					Are you sure to Deactivate Blog the account of{' '}
					<Typography component='span' variant='inherit' color='#4EA4D3'>
						{title}
					</Typography>
				</>
			}
			actionButtonEl={
				<Button variant='contained' onClick={DeactivateBlogButtonOnClick}>
					Deactivate Blog
				</Button>
			}
		/>
	);
};

export default DeactivateBlogModal;

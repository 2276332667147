import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Switch, SwitchProps, styled } from '@mui/material';
import { ReactNode } from 'react';
import AppInputLayout from '../form-layout/AppInputLayout';
interface AppSwitchProps {
	label: string;
	infoPopUp?: ReactNode;
	required?: boolean;
	hidden?: boolean;
	// onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function AppSwitch<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	infoPopUp,
	required,
	...props
}: AppSwitchProps & UseControllerProps<TFieldValues, TName> & SwitchProps) {
	const { field } = useController(props);

	return (
		<AppInputLayout label={label} required={required} infoPopUp={infoPopUp}>
			<StyledSwitch
				disableRipple
				onChange={field.onChange}
				checked={field.value}
			/>
		</AppInputLayout>
	);
}

const StyledSwitch = styled(Switch)({
	width: 32,
	height: 20,
	padding: '5px 2px',
	'& .MuiSwitch-switchBase': {
		padding: 2,

		'&.Mui-checked': {
			transform: 'translateX(12px)',
			'& + .MuiSwitch-track': {
				backgroundColor: '#C2E3F8',
				opacity: 1,
			},
		},
	},

	'& .MuiSwitch-thumb': {
		width: 16,
		height: 16,
		boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.3)',
	},

	'& .MuiSwitch-track': {
		opacity: 1,
		backgroundColor: '#BCBCBC',
	},
});

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Box, InputAdornment, TextFieldProps } from '@mui/material';
import AppInputLayout from '../form-layout/AppInputLayout';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AgeRange } from '../../../api/DTO/property.interface';
import { StyledTextField } from '../styled/StyledTextField';

interface AppAgeRangeTextFieldProps {
	label?: string;
}

export default function AppAgeRangeTextFieldTextField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	required,
	disabled,
	rows,
	...props
}: AppAgeRangeTextFieldProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { t } = useTranslation();
	const { field, fieldState } = useController(props);
	const ageRangeValue = field.value;
	const onChange = field.onChange;
	const errorMessage = fieldState.error?.message;

	const regex = /^[0-9\b]+$/;

	const [min, setMin] = useState<number | undefined>(ageRangeValue?.min);
	const [max, setMax] = useState<number | undefined>(ageRangeValue?.max);

	useEffect(() => {
		setMin(ageRangeValue?.min);
		setMax(ageRangeValue?.max);
	}, [ageRangeValue]);

	useEffect(() => {
		if (min == undefined && max == undefined) {
			onChange(undefined);
		} else {
			const ageRangeObject: AgeRange = {
				min: min,
				max: max,
			};
			onChange(ageRangeObject);
		}
	}, [min, max]);

	return (
		<AppInputLayout
			label={label}
			required={required}
			errorMessage={errorMessage}
		>
			<Box display='grid' gap='10px' gridTemplateColumns='repeat(2, 1fr)'>
				<StyledTextField
					value={min ?? ''}
					onChange={(e) => {
						if (e.target.value === '' || regex.test(e.target.value)) {
							if (e.target.value === '') setMin(undefined);
							else setMin(Number(e.target.value));
						}
					}}
					rows={rows}
					disabled={disabled}
					error={!!errorMessage && min == undefined}
					inputProps={{ maxLength: 3 }}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								{t('common.From')}
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								{t('common.YearsOld')}
							</InputAdornment>
						),
					}}
				/>

				<StyledTextField
					value={max ?? ''}
					onChange={(e) => {
						if (e.target.value === '' || regex.test(e.target.value)) {
							if (e.target.value === '') setMax(undefined);
							else setMax(Number(e.target.value));
						}
					}}
					rows={rows}
					disabled={disabled}
					error={!!errorMessage && max == undefined}
					inputProps={{ maxLength: 3 }}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>{t('common.To')}</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								{t('common.YearsOld')}
							</InputAdornment>
						),
					}}
				/>
			</Box>
		</AppInputLayout>
	);
}

import { Box, CircularProgress, Typography } from '@mui/material';
import { ApiSupplierType } from '../../../api/enum/ApiSupplier.enum';
import { ReactComponent as RakutenIcon } from '../../../assets/icon/Rakuten.svg';
import { ReactComponent as MeituanIcon } from '../../../assets/icon/Meituan.svg';
import { useGetApiPropertyRatePercentageQuery } from '../../../api/apiServiceApiSlice';
import { useTranslation } from 'react-i18next';

interface ApiPropertyLabelDisplayProps {
	apiPropertyId: string;
	apiSupplier: ApiSupplierType;
	label?: string;
}

export default function ApiPropertyLabelDisplay({
	apiPropertyId,
	apiSupplier,
	label,
}: ApiPropertyLabelDisplayProps) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<>
				{apiSupplier === ApiSupplierType.RAKUTEN && (
					<RakutenIcon style={{ minWidth: '20px', marginRight: '10px' }} />
				)}
				{apiSupplier === ApiSupplierType.MEITUAN && (
					<MeituanIcon style={{ minWidth: '20px', marginRight: '10px' }} />
				)}
				<Box>
					<Typography fontSize='12px'>{label}</Typography>
					<ApiPropertyReturnRate
						apiPropertyId={apiPropertyId}
						apiSupplier={apiSupplier}
					/>
				</Box>
			</>
		</Box>
	);
}

export function ApiPropertyReturnRate({
	apiPropertyId,
	apiSupplier,
}: {
	apiPropertyId: string;
	apiSupplier: ApiSupplierType;
}) {
	const { t } = useTranslation();

	const { data: getApiPropertyRatePercentageResponse, isLoading } =
		useGetApiPropertyRatePercentageQuery({
			apiPropertyId: apiPropertyId,
			apiSupplier: apiSupplier,
		});
	return (
		<Typography fontSize='12px' color='#808080'>
			{t('common.ReturnRate')}
			{': '}
			{isLoading ? (
				<CircularProgress size='12px' sx={{ marginLeft: '5px' }} />
			) : (
				<>{`${
					getApiPropertyRatePercentageResponse?.data.find(
						(property) => property.propertyId == apiPropertyId
					)?.returnRate
				}%`}</>
			)}
		</Typography>
	);
}

import { Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { SubmitHandler, DefaultValues, useForm } from 'react-hook-form';
import AppEmailTextField from '../../common/components/form-inputs/AppEmailTextField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthContainer from './components/AuthContainer';
import { useResetPasswordInitMutation } from '../../api/authApiSlice';
import { useEffect } from 'react';

export type FormValues = {
	email: string;
};

export const defaultValues: DefaultValues<FormValues> = {
	email: '',
};

export default function ForgotPasswordPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const formSchema = Yup.object().shape({
		email: Yup.string()
			.required(t('errorMessage.required'))
			.email(t('errorMessage.email')),
	});

	const { handleSubmit, control } = useForm<FormValues>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [resetPasswordInit, { isSuccess: isResetPasswordInitSuccess }] =
		useResetPasswordInitMutation();

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		resetPasswordInit(data.email);
	};

	useEffect(() => {
		if (isResetPasswordInitSuccess) {
			navigate(`/forgot-password/verify`);
		}
	}, [isResetPasswordInitSuccess]);

	return (
		<AuthContainer
			title={t('forgotPassword.ForgotPasswordTitle')}
			message={t('forgotPassword.ForgotPasswordMessage')}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Stack
					width='382px'
					alignItems='center'
					spacing='24px'
					marginTop='40px'
				>
					<AppEmailTextField
						control={control}
						name='email'
						label={t('login.YourEmail')}
						placeholder={t('common.TypeHere')}
					/>
				</Stack>

				<Button
					fullWidth
					type='submit'
					variant='contained'
					color='success'
					sx={{
						marginTop: '40px',
					}}
				>
					{t('button.SendVerificationEmail')}
				</Button>
			</form>

			<Typography
				component={Link}
				to='/login'
				sx={{ textDecoration: 'underline', cursor: 'pointer' }}
				fontSize='14px'
				variant='bold'
				color='#808080'
				marginTop='24px'
			>
				{t('button.BackToSignIn')}
			</Typography>
		</AuthContainer>
	);
}

import {
	Box,
	Divider,
	Grid,
	InputAdornment,
	Stack,
	Typography,
	styled,
} from '@mui/material';

import {
	Controller,
	useFieldArray,
	useFormContext,
	useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';

import { FormCardType } from '../../../../common/components/card/FormCard';

import { ReactComponent as AddIcon } from '../../../../assets/icon/button-add.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icon/copy.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';

import { useContext, useEffect, useState } from 'react';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import AppRadioGroup from '../../../../common/components/form-inputs/AppRadioGroup';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';

import dayjs from 'dayjs';
import { AbkProvidedBySupplierOptions } from '../../../../api/enum/abkProvidedBySupplier.enum';
import { AbkStatus } from '../../../../api/enum/abkStatus.enum';
import {
	RateModel,
	rateModelOptions,
} from '../../../../api/enum/rateModel.enum';
import {
	useGetMultiLangCountriesQuery,
	useLazyGetMultiLangAreaQuery,
	useLazyGetMultiLangMajorAreasQuery,
} from '../../../../api/locationApiSlice';
import AppRichTextEditor from '../../../../common/components/editor/AppRichTextEditor';
import AppAddButton from '../../../../common/components/form-inputs/AppAddButton';
import AppAutocomplete from '../../../../common/components/form-inputs/AppAutocomplete';
import AppCheckbox from '../../../../common/components/form-inputs/AppCheckbox';
import { StyledFormControlLabel } from '../../../../common/components/form-inputs/AppMultipleCheckbox';
import AppSingleCheckbox from '../../../../common/components/form-inputs/AppSingleCheckbox';
import AppSwitch from '../../../../common/components/form-inputs/AppSwitch';
import AppSplitInputLayout from '../../../../common/components/form-layout/AppSplitInputLayout';
import AppSplitSubtitleLabel from '../../../../common/components/form-layout/AppSplitSubtitleLabel';
import AppSplitSubtitleLayout from '../../../../common/components/form-layout/AppSplitSubtitleLayout';
import Option from '../../../../common/types/option.type';
import {
	API_DATE_FORMAT,
	DISPLAY_DATE_FORMAT,
} from '../../../../utils/dateHelper';
import AbkBedConfigsField from '../../components/AbkBedConfigsField';
import AbkGuestInfoCard from '../../components/AbkGuestInfoCard';
import AbkMealItemField from '../../components/AbkMealItemField';
import AbkSupItemField from '../../components/AbkSupItemField ';
import { AbkIdContext } from '../ViewAbkPage';
import { AbkGender } from '../../../../api/enum/abkGender.enum';

interface RateInfoFormProps {
	type: FormCardType;
	cusCurrency?: string;
	supCurrency?: string;
}

export default function RateInfoForm({
	type,
	cusCurrency,
	supCurrency,
}: RateInfoFormProps) {
	const { t } = useTranslation();
	const abkId = useContext(AbkIdContext);

	// load page data
	const { data: getCountriesResponse } = useGetMultiLangCountriesQuery(
		undefined,
		{
			refetchOnMountOrArgChange: true,
		}
	);
	const [getMajorAreas, { data: getMajorAreasResponse }] =
		useLazyGetMultiLangMajorAreasQuery();
	const [getAreas, { data: getAreasResponse }] = useLazyGetMultiLangAreaQuery();

	const [areaOptions, setAreaOptions] = useState<Option[]>([]);

	const {
		control,
		watch,
		getValues,
		setValue,
		unregister,
		formState: { isSubmitted },
		trigger,
	} = useFormContext();

	const watchStayPeriodStart = watch('baseInfo.stayPeriod.start');
	const watchStayPeriodEnd = watch('baseInfo.stayPeriod.end');

	const [isPrefilled, setIsPrefilled] = useState<boolean>(false);

	useEffect(() => {
		setIsPrefilled(false);
		// if (type === FormCardType.edit) {
		// reset(getAbkResponse?.data.rate);
		// }

		setIsPrefilled(true);
	}, []);

	useEffect(() => {
		if (type === FormCardType.edit || type === FormCardType.specialEdit) {
			setValue('rate.sameRateWholePeriod', false);
		}
	}, []);

	useEffect(() => {
		if (type === FormCardType.create) {
			const japanOption = getCountriesResponse?.data?.find(
				(i) => i.iso2 === 'JP'
			);
			if (japanOption) {
				setValue('rate.property.country', japanOption);
			}
		}
	}, [getCountriesResponse]);

	useEffect(() => {
		if (watchStayPeriodStart || watchStayPeriodEnd) {
			roomsFields.map((rooms, index) => {
				unregister(`rate.rooms.${index}.dailyRates`);
			});
		}
	}, [watchStayPeriodStart, watchStayPeriodEnd]);

	// watch

	const watchCountry = watch('rate.property.country');

	useEffect(() => {
		if (watchCountry) getMajorAreas(watchCountry.id);
		if (isPrefilled) setValue('rate.property.majorArea', undefined);
		setAreaOptions([]);
	}, [watchCountry]);

	const watchMajorAreas = watch('rate.property.majorArea');
	useEffect(() => {
		if (watchMajorAreas) getAreas(watchMajorAreas.id);
		if (isPrefilled) setValue('rate.property.area', undefined);
	}, [watchMajorAreas]);

	useEffect(() => {
		setAreaOptions(getAreasResponse?.data ?? []);
	}, [getAreasResponse]);

	const {
		fields: otherServicesFields,
		append: otherServicesAppend,
		remove: otherServicesRemove,
	} = useFieldArray({
		control,
		name: 'rate.otherServices',
	});

	const {
		fields: roomsFields,
		append: roomsAppend,
		remove: roomsRemove,
	} = useFieldArray({
		control,
		name: 'rate.rooms',
	});

	const showRoomsDeleteButton = roomsFields.length > 1;

	const watchRateModel = useWatch({ name: 'rate.rateModel' });
	const watchOtherServices = useWatch({ name: 'rate.otherServices' });
	const watchRooms = useWatch({ name: 'rate.rooms' });

	const watchStatus = useWatch({ name: 'baseInfo.status' });

	useEffect(() => {
		if (isSubmitted) {
			trigger('rate.property.country');
			trigger('rate.property.majorArea');
			trigger('rate.property.area');
			trigger('rate.property.supAddress');
			trigger('rate.property.cusAddress');
		}
	}, [watchStatus]);

	let nightOfStayPeriod;
	const stayPeriodList: string[] = [];
	if (watchStayPeriodStart && watchStayPeriodEnd) {
		nightOfStayPeriod = dayjs(watchStayPeriodEnd).diff(
			watchStayPeriodStart,
			'days'
		);
	}
	if (nightOfStayPeriod) {
		for (let i = 0; i < nightOfStayPeriod; i++) {
			const currentDate = dayjs(watchStayPeriodStart, API_DATE_FORMAT).add(
				i,
				'day'
			);

			stayPeriodList.push(dayjs(currentDate).format(API_DATE_FORMAT));
		}
	}

	const handleCopyButton = (copyFrom: string, copyTo: string) => {
		const fromValue = getValues(copyFrom);
		if (fromValue !== '' && fromValue !== undefined) {
			setValue(copyTo, fromValue, { shouldValidate: true });
		}
	};

	// editor copy feature
	const [supUniquePolicyToBePasted, setSupUniquePolicyToBePasted] = useState<
		string | undefined
	>(undefined);
	const [cusUniquePolicyToBePasted, setCusUniquePolicyToBePasted] = useState<
		string | undefined
	>(undefined);
	const [supCancellationPolicyToBePasted, setSupCancellationPolicyToBePasted] =
		useState<string | undefined>(undefined);
	const [cusCancellationPolicyToBePasted, setCusCancellationPolicyToBePasted] =
		useState<string | undefined>(undefined);
	const [supRatePlanNameToBePasted, setSupRatePlanNameToBePasted] = useState<{
		[key in number]?: string;
	}>({});
	const [cusRatePlanNameToBePasted, setCusRatePlanNameToBePasted] = useState<{
		[key in number]?: string;
	}>({});
	const [supSpecialOfferToBePasted, setSupSpecialOfferToBePasted] = useState<{
		[key in number]?: string;
	}>({});
	const [cusSpecialOfferToBePasted, setCusSpecialOfferToBePasted] = useState<{
		[key in number]?: string;
	}>({});

	const formContent = (
		<Stack>
			<Grid
				container
				alignItems='stretch'
				spacing={'20px'}
				paddingBottom={'15px'}
			>
				<Grid item xs={6} display='flex' flexDirection={'column'}>
					<AppSubtitleLayout label={t('abk.PropertyInfo')} />

					<AppDropDownMenu
						control={control}
						name='rate.property.country'
						label={t('abk.CountryOrRegion')}
						options={getCountriesResponse?.data ?? []}
						passObjectValue={true}
						required={watchStatus === AbkStatus.NEW}
					/>
					<AppAutocomplete
						control={control}
						name='rate.property.majorArea'
						label={t('abk.MajorArea')}
						options={getMajorAreasResponse?.data ?? []}
						required={watchStatus === AbkStatus.NEW}
					/>
					<AppAutocomplete
						control={control}
						name='rate.property.area'
						label={t('abk.Area')}
						options={areaOptions}
						required={watchStatus === AbkStatus.NEW}
					/>
					<AppTextField
						control={control}
						name={'rate.property.phoneNum'}
						label={t('abk.PropertyPhoneNumber')}
						inputType={InputType.number}
					/>
				</Grid>
				<Grid item xs={6} display='flex' flexDirection={'column'}>
					<AppSubtitleLayout label={t('abk.RateDetails')} />

					<AppInputLayout label={t('abk.NumberOfNights')}>
						<NonEditableDisplayText
							text={nightOfStayPeriod?.toString() ?? '-'}
						/>
					</AppInputLayout>

					<AppDropDownMenu
						control={control}
						name={'rate.rateModel'}
						label={t('abk.RateModel')}
						options={rateModelOptions}
						required
					/>
					{watchRateModel === RateModel.MERCHANT && (
						<Box
							sx={{
								paddingLeft: '20px',
								borderLeft: '5px solid #4EA4D3',
							}}
						>
							<AppTextField
								control={control}
								name={'rate.commissionLevel'}
								label={t('abk.CommissionRate')}
								inputType={InputType.number}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>%</InputAdornment>
									),
								}}
								align='end'
								required
							/>

							<AppTextField
								control={control}
								name={'rate.taxRate'}
								label={t('abk.TaxRate')}
								inputType={InputType.number}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>%</InputAdornment>
									),
								}}
								align='end'
								required
							/>

							<AppTextField
								control={control}
								name={'rate.serviceCharge'}
								inputType={InputType.number}
								label={t('abk.ServiceChargeRate')}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>%</InputAdornment>
									),
								}}
								align='end'
								required
							/>
						</Box>
					)}
				</Grid>
			</Grid>

			<Divider sx={{ marginBottom: '15px' }} />

			<Grid
				container
				alignItems='stretch'
				spacing={'20px'}
				paddingBottom={'15px'}
			>
				<Grid item xs={6} display='flex' flexDirection={'column'}>
					<AppSplitInputLayout
						label={' '}
						children1={
							<Typography
								fontSize={12}
								fontWeight={700}
								textAlign={'center'}
								paddingBottom={'5px'}
								color={'#4EA4D3'}
							>
								{t('abk.ForSupplier')}
							</Typography>
						}
						children2={
							<Typography
								fontSize={12}
								fontWeight={700}
								textAlign={'center'}
								paddingBottom={'5px'}
							>
								{t('abk.ForCustomer')}
							</Typography>
						}
					/>
					<AppSplitInputLayout
						label={t('abk.PropertyName')}
						children1={
							<AppTextField
								control={control}
								name={'rate.property.supName'}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<CopyIcon
												color='#4EA4D3'
												onClick={() => {
													handleCopyButton(
														'rate.property.supName',
														'rate.property.cusName'
													);
												}}
											/>
										</InputAdornment>
									),
								}}
							/>
						}
						children2={
							<AppTextField
								control={control}
								name={'rate.property.cusName'}
								// InputProps={{
								// 	endAdornment: (
								// 		<InputAdornment position='end'>
								// 			<CopyIcon
								// 				color='#4EA4D3'
								// 				onClick={() => {
								// 					handleCopyButton(
								// 						'rate.property.cusName',
								// 						'rate.property.supName'
								// 					);
								// 				}}
								// 			/>
								// 		</InputAdornment>
								// 	),
								// }}
							/>
						}
						required
					/>
					<AppSplitInputLayout
						label={t('abk.AddressInDetails')}
						children1={
							<AppTextField
								control={control}
								name={'rate.property.supAddress'}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<CopyIcon
												color='#4EA4D3'
												onClick={() => {
													handleCopyButton(
														'rate.property.supAddress',
														'rate.property.cusAddress'
													);
												}}
											/>
										</InputAdornment>
									),
								}}
							/>
						}
						children2={
							<AppTextField
								control={control}
								name={'rate.property.cusAddress'}
								// InputProps={{
								// 	endAdornment: (
								// 		<InputAdornment position='end'>
								// 			<CopyIcon
								// 				color='#4EA4D3'
								// 				onClick={() => {
								// 					handleCopyButton(
								// 						'rate.property.cusAddress',
								// 						'rate.property.supAddress'
								// 					);
								// 				}}
								// 			/>
								// 		</InputAdornment>
								// 	),
								// }}
							/>
						}
						required={watchStatus === AbkStatus.NEW}
					/>
					<AppSplitInputLayout
						label={t('abk.PropertyUniquePolicy')}
						showQuickActionButton
						labelAlignItemsFlexStart
						children1={
							<Box width={'100%'}>
								<AppRichTextEditor
									control={control}
									name='rate.property.supUniquePolicy'
									showCopyButton
									editorStateToBePasted={supUniquePolicyToBePasted}
									handleCopyButtonClick={() => {
										handleCopyButton(
											'rate.property.supUniquePolicy',
											'rate.property.cusUniquePolicy'
										);
										setCusUniquePolicyToBePasted(
											watch('rate.property.cusUniquePolicy')
										);
									}}
								/>
							</Box>
						}
						children2={
							<Box width={'100%'}>
								<AppRichTextEditor
									control={control}
									name='rate.property.cusUniquePolicy'
									// showCopyButton
									editorStateToBePasted={cusUniquePolicyToBePasted}
									handleCopyButtonClick={() => {
										handleCopyButton(
											'rate.property.cusUniquePolicy',
											'rate.property.supUniquePolicy'
										);
										setSupUniquePolicyToBePasted(
											watch('rate.property.supUniquePolicy')
										);
									}}
								/>
							</Box>
						}
					/>
					<AppSwitch
						control={control}
						name='rate.refundable'
						label={t('abk.Refundable?')}
					/>
					{useWatch({ name: 'rate.refundable' }) && (
						<AppSplitInputLayout
							label={t('abk.CancellationPolicy')}
							showQuickActionButton
							labelAlignItemsFlexStart
							children1={
								<Box width={'100%'}>
									<AppRichTextEditor
										control={control}
										name='rate.supCancellationPolicy'
										showCopyButton
										editorStateToBePasted={supCancellationPolicyToBePasted}
										handleCopyButtonClick={() => {
											handleCopyButton(
												'rate.supCancellationPolicy',
												'rate.cusCancellationPolicy'
											);
											setCusCancellationPolicyToBePasted(
												watch('rate.cusCancellationPolicy')
											);
										}}
									/>
								</Box>
							}
							children2={
								<Box width={'100%'}>
									<AppRichTextEditor
										control={control}
										name='rate.cusCancellationPolicy'
										// showCopyButton
										editorStateToBePasted={cusCancellationPolicyToBePasted}
										handleCopyButtonClick={() => {
											handleCopyButton(
												'rate.cusCancellationPolicy',
												'rate.supCancellationPolicy'
											);
											setSupCancellationPolicyToBePasted(
												watch('rate.supCancellationPolicy')
											);
										}}
									/>
								</Box>
							}
						/>
					)}
				</Grid>
				<Grid item xs={6} display='flex' flexDirection={'column'}>
					{watchRateModel === RateModel.MERCHANT && (
						<>
							<AppSubtitleLayout label={t('abk.RoomRateDetails')} />
							{/* <AppSwitch
								control={control}
								name='rate.sameRateWholePeriod'
								label={t('abk.SameRateWholePeriod')}
								hidden
							/> */}

							<AppSwitch
								control={control}
								name='rate.commissionTaxIncluded'
								label={t('abk.CommissionIncludedTax')}
							/>

							<AppSwitch
								control={control}
								name='rate.commissionServiceChargeIncluded'
								label={t('abk.CommissionIncludedServiceCharge')}
							/>
						</>
					)}
				</Grid>
			</Grid>
			<Divider sx={{ marginBottom: '15px' }} />
			{roomsFields.map((item, index) => {
				return (
					<Box key={item.id}>
						<Grid
							container
							alignItems='stretch'
							spacing={'20px'}
							paddingBottom={'15px'}
						>
							<Grid item xs={6} display='flex' flexDirection={'column'}>
								<AppSubtitleLayout
									label={t('abk.Room') + ` (${index + 1}) ` + t('abk.Info')}
								/>
								<StyledBackgroundCard sx={{ position: 'relative' }}>
									{showRoomsDeleteButton && (
										<Box
											sx={{
												position: 'absolute',
												top: '15px',
												right: '15px',
											}}
										>
											<DeleteIcon
												color='#F6403F'
												cursor={'pointer'}
												onClick={() => roomsRemove(index)}
											/>
										</Box>
									)}
									<AppSplitInputLayout
										subTitle={t('abk.RoomInfo')}
										children1={
											<Typography
												fontSize={12}
												fontWeight={700}
												textAlign={'center'}
												paddingBottom={'5px'}
												color={'#4EA4D3'}
											>
												{t('abk.ForSupplier')}
											</Typography>
										}
										children2={
											<Typography
												fontSize={12}
												fontWeight={700}
												textAlign={'center'}
												paddingBottom={'5px'}
											>
												{t('abk.ForCustomer')}
											</Typography>
										}
									/>

									<AppSplitInputLayout
										label={t('abk.RoomType')}
										required
										children1={
											<AppTextField
												control={control}
												name={`rate.rooms.${index}.supName`}
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<CopyIcon
																color='#4EA4D3'
																onClick={() => {
																	handleCopyButton(
																		`rate.rooms.${index}.supName`,
																		`rate.rooms.${index}.cusName`
																	);
																}}
															/>
														</InputAdornment>
													),
												}}
											/>
										}
										children2={
											<AppTextField
												control={control}
												name={`rate.rooms.${index}.cusName`}
												// InputProps={{
												// 	endAdornment: (
												// 		<InputAdornment position='end'>
												// 			<CopyIcon
												// 				color='#4EA4D3'
												// 				onClick={() => {
												// 					handleCopyButton(
												// 						`rate.rooms.${index}.cusName`,
												// 						`rate.rooms.${index}.supName`
												// 					);
												// 				}}
												// 			/>
												// 		</InputAdornment>
												// 	),
												// }}
											/>
										}
									/>
									<AppSplitInputLayout
										label={t('abk.BreakfastIncluded')}
										children1={
											<AppSingleCheckbox
												control={control}
												name={`rate.rooms.${index}.withBreakfast`}
												label={''}
											/>
										}
										children2={
											<AppSingleCheckbox
												control={control}
												name={`rate.rooms.${index}.withBreakfast`}
												label={''}
												disabled
											/>
										}
									/>
									<AppSplitInputLayout
										label={t('abk.RatePlanName')}
										required
										children1={
											<AppTextField
												control={control}
												name={`rate.rooms.${index}.ratePlanSupName`}
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<CopyIcon
																color='#4EA4D3'
																onClick={() => {
																	handleCopyButton(
																		`rate.rooms.${index}.ratePlanSupName`,
																		`rate.rooms.${index}.ratePlanCusName`
																	);
																}}
															/>
														</InputAdornment>
													),
												}}
											/>
										}
										children2={
											<AppTextField
												control={control}
												name={`rate.rooms.${index}.ratePlanCusName`}
												// InputProps={{
												// 	endAdornment: (
												// 		<InputAdornment position='end'>
												// 			<CopyIcon
												// 				color='#4EA4D3'
												// 				onClick={() => {
												// 					handleCopyButton(
												// 						`rate.rooms.${index}.cusName`,
												// 						`rate.rooms.${index}.supName`
												// 					);
												// 				}}
												// 			/>
												// 		</InputAdornment>
												// 	),
												// }}
											/>
										}
									/>
									<AppSplitInputLayout
										label={t('abk.RatePlanInclusion')}
										labelAlignItemsFlexStart
										children1={
											<Box width={'100%'}>
												<AppRichTextEditor
													control={control}
													name={`rate.rooms.${index}.ratePlanSupInclusion`}
													showCopyButton
													editorStateToBePasted={
														supRatePlanNameToBePasted[index]
													}
													handleCopyButtonClick={() => {
														handleCopyButton(
															`rate.rooms.${index}.ratePlanSupInclusion`,
															`rate.rooms.${index}.ratePlanCusInclusion`
														);
														setCusRatePlanNameToBePasted((prev) => ({
															...prev,
															[index]: watch(
																`rate.rooms.${index}.ratePlanCusInclusion`
															),
														}));
													}}
												/>
											</Box>
										}
										children2={
											<Box width={'100%'}>
												<AppRichTextEditor
													control={control}
													name={`rate.rooms.${index}.ratePlanCusInclusion`}
													// showCopyButton
													editorStateToBePasted={
														cusRatePlanNameToBePasted[index]
													}
													handleCopyButtonClick={() => {
														handleCopyButton(
															`rate.rooms.${index}.ratePlanCusInclusion`,
															`rate.rooms.${index}.ratePlanSupInclusion`
														);
														setSupRatePlanNameToBePasted((prev) => ({
															...prev,
															[index]: watch(
																`rate.rooms.${index}.ratePlanSupInclusion`
															),
														}));
													}}
												/>
											</Box>
										}
									/>
									<AppSplitInputLayout
										label={t('abk.SpecialOffer')}
										labelAlignItemsFlexStart
										children1={
											<Box width={'100%'}>
												<AppRichTextEditor
													control={control}
													name={`rate.rooms.${index}.supSpecialOffer`}
													showCopyButton
													editorStateToBePasted={
														supSpecialOfferToBePasted[index]
													}
													handleCopyButtonClick={() => {
														handleCopyButton(
															`rate.rooms.${index}.supSpecialOffer`,
															`rate.rooms.${index}.cusSpecialOffer`
														);
														setCusSpecialOfferToBePasted((prev) => ({
															...prev,
															[index]: watch(
																`rate.rooms.${index}.cusSpecialOffer`
															),
														}));
													}}
												/>
											</Box>
										}
										children2={
											<Box width={'100%'}>
												<AppRichTextEditor
													control={control}
													name={`rate.rooms.${index}.cusSpecialOffer`}
													// showCopyButton
													editorStateToBePasted={
														cusSpecialOfferToBePasted[index]
													}
													handleCopyButtonClick={() => {
														handleCopyButton(
															`rate.rooms.${index}.cusSpecialOffer`,
															`rate.rooms.${index}.supSpecialOffer`
														);
														setSupSpecialOfferToBePasted((prev) => ({
															...prev,
															[index]: watch(
																`rate.rooms.${index}.supSpecialOffer`
															),
														}));
													}}
												/>
											</Box>
										}
									/>

									<AppInputLayout label={t('abk.GuestInfo')} />
									<Box
										sx={{
											background: 'white',
											borderRadius: '10px',
											marginBottom: '20px',
											padding: '10px',
											width: '100%',
										}}
									>
										<AbkGuestInfoCard
											index={index}
											control={control}
											label={t('abk.NoOfGuests')}
										/>
									</Box>
									<AppInputLayout label={t('abk.BedType')} required />

									<AbkBedConfigsField
										control={control}
										name={`rate.rooms.${index}.beddings`}
									/>

									<AppSplitInputLayout
										label={t('abk.SpecialRequest')}
										children1={
											<AppTextField
												control={control}
												name={`rate.rooms.${index}.supSpecialRequest`}
												removeErrorPadding
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<CopyIcon
																color='#4EA4D3'
																onClick={() => {
																	handleCopyButton(
																		`rate.rooms.${index}.supSpecialRequest`,
																		`rate.rooms.${index}.cusSpecialRequest`
																	);
																}}
															/>
														</InputAdornment>
													),
												}}
											/>
										}
										children2={
											<AppTextField
												control={control}
												name={`rate.rooms.${index}.cusSpecialRequest`}
												removeErrorPadding
												// InputProps={{
												// 	endAdornment: (
												// 		<InputAdornment position='end'>
												// 			<CopyIcon
												// 				color='#4EA4D3'
												// 				onClick={() => {
												// 					handleCopyButton(
												// 						`rate.rooms.${index}.cusSpecialRequest`,
												// 						`rate.rooms.${index}.supSpecialRequest`
												// 					);
												// 				}}
												// 			/>
												// 		</InputAdornment>
												// 	),
												// }}
											/>
										}
									/>
								</StyledBackgroundCard>
							</Grid>
							<Grid item xs={6} display='flex' flexDirection={'column'}>
								<Box paddingX={'10px'}>
									<AppSplitSubtitleLayout
										subTitle={t('abk.Breakdown')}
										children1={
											<AppSplitSubtitleLabel
												label={t('abk.SupplierRate')}
												color={'#4EA4D3'}
											/>
										}
										children2={
											<AppSplitSubtitleLabel
												label={t('abk.CustomerRate')}
												required={watchStatus !== AbkStatus.ENQUIRY}
											/>
										}
									/>
								</Box>
								{stayPeriodList?.map((item: string) => {
									return (
										<StyledBackgroundCard key={item}>
											<AppSplitInputLayout
												subTitle={`${t('abk.RoomRateFor')}\n${dayjs(
													item,
													API_DATE_FORMAT
												).format(DISPLAY_DATE_FORMAT)}`}
												children1={
													<AppTextField
														control={control}
														name={`rate.rooms.${index}.dailyRates.${item}.supRate`}
														inputType={InputType.amount}
														InputProps={{
															startAdornment: (
																<InputAdornment position='start'>
																	{supCurrency}
																</InputAdornment>
															),
															endAdornment: (
																<>
																	<InputAdornment position='end'>
																		{t('abk.PerNight')}
																	</InputAdornment>
																	<InputAdornment position='end'>
																		<CopyIcon
																			color='#4EA4D3'
																			onClick={() => {
																				handleCopyButton(
																					`rate.rooms.${index}.dailyRates.${item}.supRate`,
																					`rate.rooms.${index}.dailyRates.${item}.cusRate`
																				);
																			}}
																		/>
																	</InputAdornment>
																</>
															),
														}}
													/>
												}
												children2={
													<AppTextField
														control={control}
														name={`rate.rooms.${index}.dailyRates.${item}.cusRate`}
														inputType={InputType.amount}
														InputProps={{
															startAdornment: (
																<InputAdornment position='start'>
																	{cusCurrency}
																</InputAdornment>
															),
															endAdornment: (
																<>
																	<InputAdornment position='end'>
																		{t('abk.PerNight')}
																	</InputAdornment>
																	{/* <InputAdornment position='end'>
																		<CopyIcon
																			color='#4EA4D3'
																			onClick={() => {
																				handleCopyButton(
																					`rate.rooms.${index}.dailyRates.${item}.cusRate`,
																					`rate.rooms.${index}.dailyRates.${item}.supRate`
																				);
																			}}
																		/>
																	</InputAdornment> */}
																</>
															),
														}}
													/>
												}
												required
											/>
										</StyledBackgroundCard>
									);
								})}

								<Divider sx={{ marginBottom: '15px' }} />

								<Box display={'flex'} alignItems={'center'}>
									<AppSubtitleLayout label={t('abk.OtherMeal')} />
									{watchRateModel === RateModel.MERCHANT && (
										<Stack direction='row' marginBottom={'15px'}>
											<Controller
												name={`rate.rooms.${index}.otherMealMerchantConfig.commissionApply`}
												control={control}
												render={({ field }) => (
													<StyledFormControlLabel
														key={`rate.rooms.${index}.otherMealMerchantConfig.commissionApply`}
														control={
															<AppCheckbox
																checked={field.value}
																{...field}
																ref={null}
															/>
														}
														label={t('abk.CommissionApply')}
													/>
												)}
											/>
											{watchRooms?.[index]?.otherMealMerchantConfig
												?.commissionApply && (
												<>
													<Controller
														name={`rate.rooms.${index}.otherMealMerchantConfig.commissionTaxIncluded`}
														control={control}
														render={({ field }) => (
															<StyledFormControlLabel
																key={`rate.rooms.${index}.otherMealMerchantConfig.commissionTaxIncluded`}
																control={
																	<AppCheckbox
																		checked={field.value}
																		{...field}
																		ref={null}
																	/>
																}
																label={t('abk.TaxIncluded')}
															/>
														)}
													/>

													<Controller
														name={`rate.rooms.${index}.otherMealMerchantConfig.commissionServiceChargeIncluded`}
														control={control}
														render={({ field }) => (
															<StyledFormControlLabel
																key={`rate.rooms.${index}.otherMealMerchantConfig.commissionServiceChargeIncluded`}
																control={
																	<AppCheckbox
																		checked={field.value}
																		{...field}
																		ref={null}
																	/>
																}
																label={t('abk.ServiceChargeIncluded')}
															/>
														)}
													/>
												</>
											)}
										</Stack>
									)}
								</Box>
								<AbkMealItemField
									index={index}
									control={control}
									handleCopyButton={handleCopyButton}
									cusCurrency={cusCurrency}
									supCurrency={supCurrency}
									status={watchStatus}
								/>
								<Divider sx={{ marginBottom: '15px' }} />

								<Box display={'flex'} alignItems={'center'}>
									<AppSubtitleLayout label={t('abk.OtherSupplement')} />
									{watchRateModel === RateModel.MERCHANT && (
										<Stack direction='row' marginBottom={'15px'}>
											<Controller
												name={`rate.rooms.${index}.otherSupplementMerchantConfig.commissionApply`}
												control={control}
												render={({ field }) => (
													<StyledFormControlLabel
														key={`rate.rooms.${index}.otherSupplementMerchantConfig.commissionApply`}
														control={
															<AppCheckbox
																checked={field.value}
																{...field}
																ref={null}
															/>
														}
														label={t('abk.CommissionApply')}
													/>
												)}
											/>
											{watchRooms?.[index]?.otherSupplementMerchantConfig
												?.commissionApply && (
												<>
													<Controller
														name={`rate.rooms.${index}.otherSupplementMerchantConfig.commissionTaxIncluded`}
														control={control}
														render={({ field }) => (
															<StyledFormControlLabel
																key={`rate.rooms.${index}.otherSupplementMerchantConfig.commissionTaxIncluded`}
																control={
																	<AppCheckbox
																		checked={field.value}
																		{...field}
																		ref={null}
																	/>
																}
																label={t('abk.TaxIncluded')}
															/>
														)}
													/>

													<Controller
														name={`rate.rooms.${index}.otherSupplementMerchantConfig.commissionServiceChargeIncluded`}
														control={control}
														render={({ field }) => (
															<StyledFormControlLabel
																key={`rate.rooms.${index}.otherSupplementMerchantConfig.commissionServiceChargeIncluded`}
																control={
																	<AppCheckbox
																		checked={field.value}
																		{...field}
																		ref={null}
																	/>
																}
																label={t('abk.ServiceChargeIncluded')}
															/>
														)}
													/>
												</>
											)}
										</Stack>
									)}
								</Box>
								<AbkSupItemField
									index={index}
									control={control}
									handleCopyButton={handleCopyButton}
									cusCurrency={cusCurrency}
									supCurrency={supCurrency}
									status={watchStatus}
								/>
							</Grid>
						</Grid>
						<Divider sx={{ marginBottom: '15px' }} />
					</Box>
				);
			})}

			<AppAddButton
				onClick={() =>
					roomsAppend({
						beddings: {},
						supSpecialRequest: undefined,
						cusSpecialRequest: undefined,
						withBreakfast: false,
						supSpecialOffer: undefined,
						cusSpecialOffer: undefined,
						guests: [
							{
								firstName: undefined,
								lastName: undefined,
								adult: true,
								age: undefined,
								gender: AbkGender.MALE,
							},
						],
						otherMealMerchantConfig: {
							commissionApply: false,
							commissionTaxIncluded: false,
							commissionServiceChargeIncluded: false,
						},
						otherSupplementMerchantConfig: {
							commissionApply: false,
							commissionTaxIncluded: false,
							commissionServiceChargeIncluded: false,
						},
					})
				}
			>
				<AddIcon />
				{t('abk.AddRoom')}
			</AppAddButton>
			<Divider sx={{ marginBottom: '15px' }} />

			<AppSubtitleLayout label={t('abk.OtherService')} />

			{otherServicesFields.map((item, index) => {
				return (
					<StyledBackgroundCard key={item.id}>
						<Box sx={{ position: 'relative' }}>
							<Box
								sx={{
									position: 'absolute',
									top: '35px',
									right: '15px',
								}}
							>
								<DeleteIcon
									color='#F6403F'
									cursor={'pointer'}
									onClick={() => otherServicesRemove(index)}
								/>
							</Box>
							<Grid container alignItems='stretch' spacing={'40px'}>
								<Grid item xs={6} display='flex'>
									<AppSubtitleLayout
										label={t('abk.Service') + ` (${index + 1})`}
									/>
								</Grid>
								<Grid item xs={6} display='flex'>
									<Box width={'100%'} display={'flex'} alignItems={'center'}>
										<AppSplitSubtitleLayout
											subTitle={''}
											children1={
												<AppSplitSubtitleLabel
													label={t('abk.SupplierRate')}
													color={'#4EA4D3'}
												/>
											}
											children2={
												<AppSplitSubtitleLabel
													label={t('abk.CustomerRate')}
													required={watchStatus !== AbkStatus.ENQUIRY}
												/>
											}
										/>
									</Box>
								</Grid>
							</Grid>

							<Grid container alignItems='stretch' spacing={'40px'}>
								<Grid item xs={6} display='flex'>
									<AppRadioGroup
										control={control}
										name={`rate.otherServices.${index}.providedBySupplier`}
										label={t('abk.ProvidedBySupplier')}
										options={AbkProvidedBySupplierOptions}
									/>
								</Grid>
								<Grid item xs={6} display='flex'>
									<AppSplitInputLayout
										label={t('abk.Description')}
										children1={
											<AppTextField
												control={control}
												name={`rate.otherServices.${index}.supName`}
												// removeErrorPadding
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<CopyIcon
																color='#4EA4D3'
																onClick={() => {
																	handleCopyButton(
																		`rate.otherServices.${index}.supName`,
																		`rate.otherServices.${index}.cusName`
																	);
																}}
															/>
														</InputAdornment>
													),
												}}
											/>
										}
										children2={
											<AppTextField
												control={control}
												name={`rate.otherServices.${index}.cusName`}
												// removeErrorPadding
												// InputProps={{
												// 	endAdornment: (
												// 		<InputAdornment position='end'>
												// 			<CopyIcon
												// 				color='#4EA4D3'
												// 				onClick={() => {
												// 					handleCopyButton(
												// 						`rate.otherServices.${index}.cusName`,
												// 						`rate.otherServices.${index}.supName`
												// 					);
												// 				}}
												// 			/>
												// 		</InputAdornment>
												// 	),
												// }}
											/>
										}
										required
									/>
								</Grid>
							</Grid>
							<Grid container alignItems='stretch' spacing={'40px'}>
								<Grid item xs={6} display='flex'>
									{watchRateModel === RateModel.MERCHANT && (
										<Stack direction='row'>
											<Controller
												name={`rate.otherServices.${index}.merchantConfig.commissionApply`}
												control={control}
												render={({ field }) => (
													<StyledFormControlLabel
														key={`rate.otherServices.${index}.merchantConfig.commissionApply`}
														control={
															<AppCheckbox
																checked={field.value}
																{...field}
																ref={null}
															/>
														}
														label={t('abk.CommissionApply')}
													/>
												)}
											/>
											{watchOtherServices?.[index]?.merchantConfig
												?.commissionApply && (
												<>
													<Controller
														name={`rate.otherServices.${index}.merchantConfig.commissionTaxIncluded`}
														control={control}
														render={({ field }) => (
															<StyledFormControlLabel
																key={`rate.otherServices.${index}.merchantConfig.commissionTaxIncluded`}
																control={
																	<AppCheckbox
																		checked={field.value}
																		{...field}
																		ref={null}
																	/>
																}
																label={t('abk.TaxIncluded')}
															/>
														)}
													/>

													<Controller
														name={`rate.otherServices.${index}.merchantConfig.commissionServiceChargeIncluded`}
														control={control}
														render={({ field }) => (
															<StyledFormControlLabel
																key={`rate.otherServices.${index}.merchantConfig.commissionServiceChargeIncluded`}
																control={
																	<AppCheckbox
																		checked={field.value}
																		{...field}
																		ref={null}
																	/>
																}
																label={t('abk.ServiceChargeIncluded')}
															/>
														)}
													/>
												</>
											)}
										</Stack>
									)}
								</Grid>
								<Grid item xs={6} display='flex'>
									<AppSplitInputLayout
										label={t('abk.ServiceRate')}
										children1={
											<AppTextField
												control={control}
												name={`rate.otherServices.${index}.supRate`}
												inputType={InputType.amount}
												// removeErrorPadding
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>
															{supCurrency}
														</InputAdornment>
													),
													endAdornment: (
														<InputAdornment position='end'>
															<CopyIcon
																color='#4EA4D3'
																onClick={() => {
																	handleCopyButton(
																		`rate.otherServices.${index}.supRate`,
																		`rate.otherServices.${index}.cusRate`
																	);
																}}
															/>
														</InputAdornment>
													),
												}}
											/>
										}
										children2={
											<AppTextField
												control={control}
												name={`rate.otherServices.${index}.cusRate`}
												inputType={InputType.amount}
												// removeErrorPadding
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>
															{cusCurrency}
														</InputAdornment>
													),
													// endAdornment: (
													// 	<InputAdornment position='end'>
													// 		<CopyIcon
													// 			color='#4EA4D3'
													// 			onClick={() => {
													// 				handleCopyButton(
													// 					`rate.otherServices.${index}.cusRate`,
													// 					`rate.otherServices.${index}.supRate`
													// 				);
													// 			}}
													// 		/>
													// 	</InputAdornment>
													// ),
												}}
											/>
										}
										required
									/>
								</Grid>
							</Grid>
						</Box>
					</StyledBackgroundCard>
				);
			})}

			<AppAddButton
				onClick={() =>
					otherServicesAppend({
						providedBySupplier: true,
						merchantConfig: {
							commissionApply: false,
							commissionTaxIncluded: false,
							commissionServiceChargeIncluded: false,
						},
						supName: undefined,
						cusName: undefined,
						supRate: undefined,
						cusRate: undefined,
					})
				}
			>
				<AddIcon />
				{t('abk.AddOtherService')}
			</AppAddButton>
			<Divider sx={{ marginBottom: '15px' }} />
			<AppSubtitleLayout label={t('abk.Discounts')} />
			<StyledBackgroundCard sx={{ paddingBottom: '0px' }}>
				<Grid container alignItems='stretch' spacing={'40px'}>
					<Grid item xs={6} display='flex' flexDirection={'column'}>
						<AppInputLayout label={t('abk.DiscountCode')}>
							<Box width={'173px'} alignSelf={'end'}>
								<AppTextField
									control={control}
									name={'rate.coupon'}
									placeholder={t('abk.DiscountCode')}
									removeErrorPadding
								/>
							</Box>
						</AppInputLayout>
					</Grid>
					<Grid item xs={6} display='flex' flexDirection={'column'}>
						<AppInputLayout label={t('abk.DiscountAmount')}>
							<Box width={'173px'} alignSelf={'end'}>
								<AppTextField
									control={control}
									name={'rate.couponDiscountAmount'}
									inputType={InputType.amount}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												{cusCurrency}
											</InputAdornment>
										),
									}}
									removeErrorPadding
								/>
							</Box>
						</AppInputLayout>
					</Grid>
				</Grid>
			</StyledBackgroundCard>
			<StyledBackgroundCard sx={{ paddingBottom: '0px' }}>
				<Grid container alignItems='stretch' spacing={'40px'}>
					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						justifyContent={'center'}
					>
						<AppInputLayout
							label={t('abk.DiscountAdjustment')}
							removeErrorPadding
						></AppInputLayout>
					</Grid>
					<Grid item xs={6} display='flex' flexDirection={'column'}>
						<AppInputLayout label={t('abk.DiscountAmount')} removeErrorPadding>
							<Box width={'173px'} alignSelf={'end'}>
								<AppTextField
									control={control}
									name={'rate.discountAdjustment'}
									inputType={InputType.number}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												{cusCurrency}
											</InputAdornment>
										),
									}}
								/>
							</Box>
						</AppInputLayout>
					</Grid>
				</Grid>
			</StyledBackgroundCard>
		</Stack>
	);

	const form = formContent;

	return <>{form}</>;
}

export const StyledBackgroundCard = styled(Box)({
	backgroundColor: '#F2FAFF',
	padding: '20px 10px',
	borderRadius: '10px',
	marginBottom: '20px',
});

import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import { useGetSupplierQuery } from '../../../../api/supplierApiSlice';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';

import EmailDisplayText from '../../../../common/components/form-data-display/EmailDisplaytext';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';

import { departmentOption } from '../../../../api/enum/department.enum';
import { Dispatch, SetStateAction, useContext } from 'react';
import { SupplierIdContext } from '../ViewSupplierPage';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import PhoneNumberDisplay from '../../../../common/components/form-data-display/PhoneNumberDisplay';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';

export default function ContactPointView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const supplierId = useContext(SupplierIdContext);

	const { data: getSupplierResponse } = useGetSupplierQuery(supplierId);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				{getSupplierResponse?.data.contactPoints.map((item, index) => {
					return (
						<Box key={item.id}>
							<AppSubtitleLayout
								label={
									departmentOption.find(
										(i) =>
											i.id ===
											getSupplierResponse?.data.contactPoints[index].department
									)?.name
								}
							/>

							<AppInputLayout label={t('supplier.DefaultEmailContact')}>
								<YesNoDisplay
									value={
										getSupplierResponse?.data.contactPoints[index].selected
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('supplier.ContactName')}>
								<NewDisplayText
									text={getSupplierResponse?.data.contactPoints[index].name}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('supplier.PhoneNumber')}>
								<PhoneNumberDisplay
									iso2={
										getSupplierResponse?.data.contactPoints[index].country
											? getSupplierResponse?.data.contactPoints[index].country
													.iso2
											: ''
									}
									phoneCode={
										getSupplierResponse?.data.contactPoints[index].country
											? getSupplierResponse?.data.contactPoints[index].country
													.callingCode
											: ''
									}
									phoneNum={
										getSupplierResponse?.data.contactPoints[index].phoneNum
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('supplier.Email')}>
								<EmailDisplayText
									email={`${getSupplierResponse?.data.contactPoints[index].email}`}
								/>
							</AppInputLayout>

							{index < getSupplierResponse?.data.contactPoints.length - 1 && (
								<Divider sx={{ marginBottom: '15px' }} />
							)}
						</Box>
					);
				})}
			</Stack>
		</>
	);
}

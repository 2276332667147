import { Autocomplete, styled } from '@mui/material';

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
	display: 'flex',
	fontSize: 12,
	height: '30px',
	'.MuiOutlinedInput-root': {
		padding: '0px !important',
	},

	'.MuiOutlinedInput-root .MuiAutocomplete-input': {
		padding: '9px 10px',
	},

	'label + &': {
		marginTop: '28px',
	},

	'& fieldset': {
		// borderColor: '#BCBCBC !important', // removed this so that Mui-error fieldset wont be overrided
		borderWidth: '1px',
		borderRadius: '5px',
	},

	':hover ': {
		borderWidth: '1px',
		borderColor: '#BCBCBC !important',
	},

	'&.Mui-focused fieldset': {
		borderWidth: '1px !important',
		borderColor: '#4EA4D3 !important',
	},

	'&.Mui-error fieldset': {
		borderWidth: '1px !important',
		borderColor: '#DA5150 !important',
	},
	'&.Mui-disabled fieldset': {
		borderColor: '#F5F5F5 !important',
	},
	'&.Mui-disabled': {
		background: '#F5F5F5',
	},
	'.Mui-disabled': {
		WebkitTextFillColor: '#808080',
	},
}));

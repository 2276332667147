import { Box, Divider, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBkQuery } from '../../../../api/bkApiSlice';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../../utils/dateHelper';
import { BkIdContext } from '../ViewBkPage';

export default function BookInfoView() {
	const { t } = useTranslation();
	const bkId = useContext(BkIdContext);

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getBkResponse } = useGetBkQuery({
		bk_id: bkId ?? '',
	});

	return (
		<>
			<Stack position={'relative'}>
				<AppInputLayout label={t('bk.BookingCreatedDate')}>
					<NonEditableDisplayText
						text={dayjs(getBkResponse?.data.info.createdAt).format(
							DISPLAY_DATE_TIME_FORMAT
						)}
					/>
				</AppInputLayout>
				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('bk.ContactPerson')} />

				<AppInputLayout label={t('bk.ContactName')}>
					<NewDisplayText
						text={
							getBkResponse?.data.info.contactPerson.firstName +
							' ' +
							getBkResponse?.data.info.contactPerson.lastName
						}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('common.Email')}>
					<NewDisplayText text={getBkResponse?.data.info.contactPerson.email} />
				</AppInputLayout>
				<AppInputLayout label={t('common.PhoneNumber')}>
					<NewDisplayText
						text={
							getBkResponse?.data.info.contactPerson.phoneCode &&
							getBkResponse?.data.info.contactPerson.phoneNum
								? `+${getBkResponse?.data.info.contactPerson.phoneCode} ${getBkResponse?.data.info.contactPerson.phoneNum}`
								: '-'
						}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				{getBkResponse?.data.info.guests &&
					getBkResponse?.data.info.guests.length > 0 && (
						<>
							<AppSubtitleLayout label={t('bk.GuestInfo')} />

							{getBkResponse?.data.info.guests.map((guest, index) => (
								<Box key={index}>
									{guest.adult ? (
										<AppInputLayout label={t('bk.GuestNameAdult')}>
											<NewDisplayText
												text={guest.firstName + ' ' + guest.lastName}
											/>
										</AppInputLayout>
									) : (
										<AppInputLayout label={t('bk.GuestNameChild')}>
											<NewDisplayText
												text={
													guest.firstName +
													' ' +
													guest.lastName +
													(guest.age ? ', ' + guest.age + ' years old' : '')
												}
											/>
										</AppInputLayout>
									)}
								</Box>
							))}

							<Divider sx={{ marginBottom: '15px' }} />
						</>
					)}

				<AppInputLayout label={t('bk.SpecialRequest')}>
					<NewDisplayText text={getBkResponse?.data.info.specialRequest} />
				</AppInputLayout>
			</Stack>
		</>
	);
}

import { TextField, styled } from '@mui/material';

export const StyledTextField = styled(TextField, {
	shouldForwardProp: (prop) => prop !== 'align' && prop !== 'large',
})<{ align?: string; large?: boolean }>(({ theme, align, large }) => ({
	display: 'flex',
	background: '#FFFFFF',

	'& .MuiOutlinedInput-root': {
		height: large ? '38px' : 'auto',
		padding: '9px 10px',

		'& fieldset': {
			borderColor: '#BCBCBC',
			borderRadius: '5px',
		},
		'&:hover fieldset': {
			borderWidth: '1px',
			borderColor: '#BCBCBC',
		},
		'&.Mui-focused fieldset': {
			borderWidth: '1px',
			borderColor: theme.palette.primary.main,
		},
		'&.Mui-error fieldset': {
			borderWidth: '1px',
			borderColor: theme.palette.error.main,
		},
		'&.Mui-disabled fieldset': {
			borderColor: '#F5F5F5',
		},
		'&.Mui-disabled': {
			background: '#F5F5F5',
		},
		'.Mui-disabled': {
			WebkitTextFillColor: '#808080',
		},
	},
	'& .MuiInputBase-input': {
		fontSize: 12,
		height: '12px',
		padding: '0px',
		textAlign: align ? align : 'start',

		'&::placeholder': {
			color: '#808080',
			opacity: 1,
		},
	},

	'& .MuiInputAdornment-root': {
		'& .MuiTypography-root': {
			fontSize: 12,
			color: '#808080',
		},
	},
}));

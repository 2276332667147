import { Media } from './DTO/media.interface';
import { apiSlice } from './apiSlice';
import { SuccessResponse } from './response/shared/success.response';
import fileDownload from 'js-file-download';

const SERVICE_PATH = '/media-service';

export const userApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		uploadMedia: builder.mutation<SuccessResponse<Media[]>, File>({
			query: (file) => {
				const bodyFormData = new FormData();
				bodyFormData.append('file', file);
				bodyFormData.append('public', 'false');
				return {
					url: `${SERVICE_PATH}/media/upload`,
					method: 'POST',
					body: bodyFormData,
					formData: true,
				};
			},
		}),
		uploadPublicMedia: builder.mutation<SuccessResponse<Media[]>, File>({
			query: (file) => {
				const bodyFormData = new FormData();
				bodyFormData.append('file', file);
				bodyFormData.append('public', 'true');
				return {
					url: `${SERVICE_PATH}/media/upload`,
					method: 'POST',
					body: bodyFormData,
					formData: true,
				};
			},
		}),
		downloadMedia: builder.mutation<Blob, string[]>({
			query(ids) {
				return {
					url: `${SERVICE_PATH}/media/download`,
					method: 'POST',
					body: {
						id: ids,
					},
					responseHandler: async (response) => {
						const contentDisposition = response.headers.get(
							'Content-Disposition'
						);
						const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

						if (contentDisposition) {
							const matches = fileNameRegex.exec(contentDisposition);
							if (matches != null && matches[1]) {
								const fileName = decodeURIComponent(
									JSON.parse('"' + matches[1].replace(/['"]/g, '') + '"')
								);
								fileDownload(await response.blob(), fileName);
							}
						}
					},
					cache: 'no-cache',
				};
			},
		}),
	}),
});

export const {
	useUploadMediaMutation,
	useUploadPublicMediaMutation,
	useDownloadMediaMutation,
} = userApiSlice;

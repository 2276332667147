import { Box, ButtonBase, Popper, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppStatusToggle from './AppStatusToggle';
import { ReactComponent as InvertedTriangle } from '../../assets/icon/inverted-triangle.svg';
import { AppStatusButtonProps } from './AppStatusButton';

const SmallerAppStatusButton = ({
	initialStatusI18nLabel,
	initialStatusValue,
	enableI18nLabel,
	disableI18nLabel,
	statusOnChange,
}: AppStatusButtonProps) => {
	const { t } = useTranslation();
	const [hovering, setHovering] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const statusButtonOnChange = (
		event: React.MouseEvent<HTMLElement>,
		activated: boolean | null
	) => {
		setAnchorEl(null);
		statusOnChange(event, activated);
	};

	const open = Boolean(anchorEl);

	return (
		<Box>
			<StyledStatusButton
				isActivated={initialStatusValue}
				onClick={handleClick}
				onMouseEnter={() => setHovering(true)}
				onMouseLeave={() => setHovering(false)}
			>
				{hovering ? <></> : <div></div>}
				<Typography
					sx={{ fontSize: '0.75rem' }}
					component={'span'}
					variant='bold'
				>
					{t(initialStatusI18nLabel)}
				</Typography>
				{hovering ? (
					<StyledInvertedTriangle isActivated={initialStatusValue} />
				) : (
					<div></div>
				)}
			</StyledStatusButton>
			<Popper
				sx={{
					'& .MuiPaper-root': {
						marginTop: '0.5rem',
					},
				}}
				open={open}
				anchorEl={anchorEl}
			>
				<AppStatusToggle
					enableI18nLabel={enableI18nLabel}
					disableI18nLabel={disableI18nLabel}
					initialValue={initialStatusValue}
					statusOnChange={statusButtonOnChange}
				/>
			</Popper>
		</Box>
	);
};

const StyledInvertedTriangle = styled(InvertedTriangle, {
	shouldForwardProp: (prop) => prop !== 'isActivated',
})<{
	isActivated: boolean;
}>(({ isActivated }) => ({
	width: '8px',
	height: '4px',
	path: {
		fill: isActivated ? '#4B9831' : '#808080',
	},
}));

const StyledStatusButton = styled(ButtonBase, {
	shouldForwardProp: (prop) => prop !== 'isActivated',
})<{ isActivated: boolean }>(({ isActivated }) => ({
	padding: '0.5rem 0.6rem',
	borderRadius: '5px',
	minWidth: '60px',
	color: isActivated ? '#4B9831' : '#F6403F',
	backgroundColor: isActivated ? '#E3F1DC' : '#FDDFDD',
	justifyContent: 'space-between',
}));

export default SmallerAppStatusButton;

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Box, Stack, TextFieldProps, Typography } from '@mui/material';
import AppInputLayout from '../form-layout/AppInputLayout';
import { useEffect, useState } from 'react';
import { StyledTextField } from '../styled/StyledTextField';
import { StyledMenuItem, StyledSelect } from '../styled/StyledSelect';
import {
	RoomSizeUnit,
	roomSizeUnitOption,
} from '../../../api/enum/roomSizeUnit.enum';
import { RoomSize } from '../../../api/DTO/room.interface';

interface AppRoomSizeInputFieldProps {
	label?: string;
}

export default function AppRoomSizeInputField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	required,
	disabled,
	rows,
	...props
}: AppRoomSizeInputFieldProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const roomSizeValue = field.value;
	const onChange = field.onChange;
	const errorMessage = fieldState.error?.message;

	const regex = /^[0-9\b]+$/;

	const [size, setSize] = useState<number | undefined>(roomSizeValue?.size);
	const [unit, setUnit] = useState<RoomSizeUnit | undefined>(
		roomSizeValue?.unit
	);

	useEffect(() => {
		setSize(roomSizeValue?.size);
		setUnit(roomSizeValue?.unit);
	}, [roomSizeValue]);

	useEffect(() => {
		if (size == undefined && unit == undefined) {
			onChange(undefined);
		} else {
			const roomSizeObject: RoomSize = {
				size: size,
				unit: unit,
			};
			onChange(roomSizeObject);
		}
	}, [size, unit]);

	return (
		<AppInputLayout
			label={label}
			required={required}
			errorMessage={errorMessage}
		>
			<Box display='flex' gap='10px' gridTemplateColumns='repeat(2, 1fr)'>
				<StyledTextField
					sx={{ flexGrow: 1 }}
					value={size ?? ''}
					onChange={(e) => {
						if (e.target.value === '' || regex.test(e.target.value)) {
							if (e.target.value === '') setSize(undefined);
							else setSize(Number(e.target.value));
						}
					}}
					rows={rows}
					disabled={disabled}
					error={!!errorMessage && size == undefined}
				/>

				<StyledSelect
					variant='outlined'
					{...field}
					sx={{
						minWidth: '70px',
						width: '70px',
					}}
					onChange={(e) => setUnit(e.target.value as RoomSizeUnit)}
					value={unit == null ? '' : unit} // to fix `value` prop on `input` should not be null
					disabled={disabled}
					MenuProps={{
						PaperProps: {
							sx: {
								borderRadius: '5px',
								boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
								'& .MuiMenuItem-root': {
									padding: '12px',
								},
								maxHeight: '250px',
							},
						},
					}}
				>
					{roomSizeUnitOption.map((option) => (
						<StyledMenuItem key={option.id} value={option.id}>
							<Stack direction={'row'} alignItems={'center'}>
								<Typography variant='regular' sx={{ fontSize: '0.75rem' }}>
									{option.name}
								</Typography>
							</Stack>
						</StyledMenuItem>
					))}
				</StyledSelect>
			</Box>
		</AppInputLayout>
	);
}

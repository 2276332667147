import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';

import { useContext } from 'react';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';

import { AbkIdContext } from '../ViewAbkPage';
import { useGetAbkQuery } from '../../../../api/abkApiSlice';
import { getRatePlanConfirmType } from '../../../../api/enum/confirmType.enum';
import { getRatePlanPaymentType } from '../../../../api/enum/paymentType.enum';
import AppSpaceSubtitleLayout from '../../../../common/components/form-layout/AppSpaceSubtitleLayout';
import { Link } from 'react-router-dom';
import { AbkVersionContext } from '../../AbkContainer';

export default function CustomerPaymentInfoView() {
	const { t } = useTranslation();
	const abkId = useContext(AbkIdContext);
	const [AbkVersion, setAbkVersion] = useContext(AbkVersionContext);

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getAbkResponse } = useGetAbkQuery({
		abk_id: abkId ?? '',
		version: AbkVersion,
	});

	return (
		<>
			<Stack position={'relative'}>
				<AppSubtitleLayout label={t('abk.PrePayment')} />

				<AppInputLayout label={t('abk.ConfirmType')}>
					<NewDisplayText
						text={getRatePlanConfirmType(
							getAbkResponse?.data.customerPayment.confirmType ?? -1
						)}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('abk.PaymentType')}>
					<NewDisplayText
						text={getRatePlanPaymentType(
							getAbkResponse?.data.customerPayment.paymentType ?? -1
						)}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSpaceSubtitleLayout
					label={t('abk.PaymentDetails')}
					// content={t('abk.GoToTransactions')}
					link={
						<Link
							to={`/abks/${abkId}/transactions`}
							style={{
								textDecorationColor: '#32A5F1',
							}}
						>
							<Typography color='#32A5F1' fontSize='12px'>
								{t('abk.GoToTransactions')}
							</Typography>
						</Link>
					}
				/>
			</Stack>
		</>
	);
}

import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ChevronLeftIcon } from '../../assets/icon/chevron-left.svg';

const ChevronLeftIconWrapper = styled('div')({
	display: 'flex',
	alignItems: 'center',
	marginRight: 20,
	cursor: 'pointer',
});

const SubtitleWrapper = styled('div')({
	marginTop: 5,
});

interface PageHeaderProps {
	title: string;
	subtitleJsx?: JSX.Element;
	showBackButton?: boolean;
	actionJsx?: JSX.Element;
	statusJsx?: JSX.Element;
	backPath?: string;
}

export default function PageHeader(props: PageHeaderProps) {
	const navigate = useNavigate();

	return (
		<Box
			sx={{ display: 'flex' }}
			minHeight='40px'
			alignItems='center'
			alignContent='center'
			paddingRight='128px'
			marginBottom='16px'
		>
			{props.showBackButton && (
				<ChevronLeftIconWrapper
					onClick={() =>
						props.backPath ? navigate(props.backPath) : navigate(-1)
					}
				>
					<ChevronLeftIcon />
				</ChevronLeftIconWrapper>
			)}
			<Box sx={{ flexGrow: 1 }}>
				<Box display={'flex'} gap={'10px'}>
					<Typography variant='bold' fontSize={20} color='text.primary'>
						{props.title}
					</Typography>
					{props.statusJsx && <>{props.statusJsx}</>}
				</Box>
				{props.subtitleJsx && (
					<SubtitleWrapper>{props.subtitleJsx}</SubtitleWrapper>
				)}
			</Box>
			{props.actionJsx && props.actionJsx}
		</Box>
	);
}

import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';

import { useParams } from 'react-router-dom';
import { useGetASectionQuery } from '../../../../api/pageApiSlice';
import FullScreenLoader from '../../../../common/components/FullScreenLoader';
import PageHeader from '../../../../common/components/PageHeader';
import { FormCardType } from '../../../../common/components/card/FormCard';
import SectionCard from './HomeSectionCard';

export default function EditHomeSectionPage() {
	const { t } = useTranslation();
	const { sectionId } = useParams();

	const backPath = '/page/home';

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const {
		data: getASectionResponse,
		isLoading: isGetSectionLoading,
		isSuccess: isGetSectionSuccess,
		isError: isGetSectionError,
	} = useGetASectionQuery(sectionId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	let pageContent;
	if (isGetSectionLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetSectionSuccess) {
		pageContent = (
			<>
				<PageHeader
					showBackButton={true}
					backPath={backPath}
					title={t('page.EditSection')}
				/>
				{ability.can('update', 'Section') && (
					<Stack spacing='16px'>
						<Grid container alignItems='strech' spacing={'10px'}>
							<Grid item xs={12} display='flex'>
								<SectionCard initialType={FormCardType.edit} />
							</Grid>
						</Grid>
					</Stack>
				)}
			</>
		);
	} else if (isGetSectionError) {
		pageContent = <div>API Error</div>;
	}
	return <div>{pageContent}</div>;
}

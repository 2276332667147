interface BkStatusProps {
	name: string;
	color: string;
	backgroundColor: string;
}

export enum BkStatus {
	NEW = 0,
	CONFIRMED = 1,
	CANCELLED = 2,
}

export function getBkStatusDisplay(status: number): BkStatusProps {
	switch (status) {
		case BkStatus.NEW:
			return {
				name: 'New',
				color: '#D0AB2A',
				backgroundColor: '#FFEEB6',
			};
		case BkStatus.CONFIRMED:
			return {
				name: 'Confirmed',
				color: '#4B9831',
				backgroundColor: '#E4F0DB',
			};
		case BkStatus.CANCELLED:
			return {
				name: 'Cancelled',
				color: '#DA5150',
				backgroundColor: '#FFDDDC',
			};
		default:
			return {
				name: '-',
				color: 'black',
				backgroundColor: 'transparent',
			};
	}
}

import { Button, Typography } from '@mui/material';
import AppModal from './AppModal';
import { useUpdateBlogPostStatusMutation } from '../../../api/contentApiSlice';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal } from './modalSlice';

interface ActivateBlogModalProps {
	blogId: string;
	title: string;
}

const ActivateBlogModal = ({ blogId, title }: ActivateBlogModalProps) => {
	const dispatch = useAppDispatch();
	const [updateBlogPostStatus] = useUpdateBlogPostStatusMutation();

	const activateButtonOnClick = () => {
		updateBlogPostStatus({
			blogId,
			body: {
				status: 0,
			},
		})
			.unwrap()
			.then(() => {
				dispatch(hideModal());
			});
	};

	return (
		<AppModal
			title={
				<>
					Activate Blog{' '}
					<Typography variant='bold' color='#4EA4D3'>
						{title}
					</Typography>
				</>
			}
			content={
				<>
					Are you sure to activate the blog of{' '}
					<Typography component='span' variant='inherit' color='#4EA4D3'>
						{title}
					</Typography>
				</>
			}
			actionButtonEl={
				<Button variant='contained' onClick={activateButtonOnClick}>
					Activate Blog
				</Button>
			}
		/>
	);
};

export default ActivateBlogModal;

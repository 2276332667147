export enum PromotionStatus {
	ACTIVE = 0,
	INACTIVE = 1,
}

interface PromotionStatusType {
	isOnline: boolean;
	i18nLabel: string;
}

export function getPromotionStatus(status: number): PromotionStatusType {
	switch (status) {
		case PromotionStatus.ACTIVE:
			return {
				isOnline: true,
				i18nLabel: 'promotion.Online',
			};
		case PromotionStatus.INACTIVE:
			return {
				isOnline: false,
				i18nLabel: 'promotion.Offline',
			};
		default:
			return {
				isOnline: false,
				i18nLabel: 'common.Error',
			};
	}
}

import { Box, styled, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';
import { ReactComponent as CircleInfoIcon } from '../../../assets/icon/circleInfo.svg';

interface AppSubtitleLayoutProps {
	label?: string;
	infoPopUp?: ReactNode;
}

export const Label = styled(Typography)({
	fontSize: '12px',
	fontWeight: 700,
	color: '#2C2C2C',
	whiteSpace: 'pre-line',
});

export default function AppSubtitleLayout({
	label,
	infoPopUp,
	...props
}: React.PropsWithChildren<AppSubtitleLayoutProps>) {
	return (
		<Box sx={{ display: 'flex', gap: '20px' }}>
			{label && (
				<Box>
					<Stack
						width={'160px'}
						direction={'row'}
						alignItems={'center'}
						spacing='5px'
						flexWrap={'wrap'}
						marginBottom='15px'
					>
						<Label>{label}</Label>
						{!!infoPopUp && (
							<Box component={'span'} sx={{ marginLeft: '5px' }}>
								<Tooltip title={infoPopUp} placement='bottom-start'>
									<CircleInfoIcon />
								</Tooltip>
							</Box>
						)}
					</Stack>
				</Box>
			)}
			<Stack sx={{ width: '100%' }}>{props.children}</Stack>
		</Box>
	);
}

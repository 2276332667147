import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import AppInputLayout from '../form-layout/AppInputLayout';
import { StyledTextField } from '../styled/StyledTextField';
import { ReactComponent as CalenderIcon } from '../../../assets/icon/calendar.svg';
import { ReactComponent as CalenderErrorIcon } from '../../../assets/icon/calendar-error.svg';
import dayjs from 'dayjs';
import {
	API_DATE_FORMAT,
	DISPLAY_DATE_FORMAT,
} from '../../../utils/dateHelper';

interface AppDatePickerProps {
	label?: string;
	required?: boolean;
	minimumDate?: string;
	maximumDate?: string;
	placeholder?: string;
	removeErrorPadding?: boolean;
	large?: boolean;
}

export default function AppDatePicker<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	required,
	minimumDate,
	maximumDate,
	placeholder,
	removeErrorPadding,
	large,
	...props
}: AppDatePickerProps & UseControllerProps<TFieldValues, TName>) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<AppInputLayout
			label={label}
			required={required}
			errorMessage={errorMessage}
			removeErrorPadding={removeErrorPadding}
		>
			<DatePicker
				{...field}
				inputFormat={DISPLAY_DATE_FORMAT}
				minDate={
					minimumDate === '' || minimumDate === undefined
						? undefined
						: dayjs(minimumDate, API_DATE_FORMAT).format()
				}
				maxDate={
					maximumDate === '' || maximumDate === undefined
						? undefined
						: dayjs(maximumDate, API_DATE_FORMAT).format()
				}
				value={
					field.value === '' ||
					field.value === undefined ||
					field.value === null
						? null
						: dayjs(field.value, API_DATE_FORMAT).format()
				}
				onChange={(value) => {
					if (value === null) {
						field.onChange(null);
					} else field.onChange(dayjs(value).format(API_DATE_FORMAT));
				}}
				components={{
					OpenPickerIcon: fieldState.error ? CalenderErrorIcon : CalenderIcon,
				}}
				renderInput={(params) => {
					const formatPlaceholder = params.inputProps?.placeholder;
					return (
						<StyledTextField
							{...params}
							inputProps={{
								...params.inputProps,
								placeholder: placeholder ? placeholder : formatPlaceholder,
							}}
							sx={{
								'& .MuiInputLabel-outlined': {
									transform: 'translate(13.5px, 8px) scale(1)',
								},
								'& .MuiInputLabel-shrink': {
									transform: 'translate(13.5px, -8px) scale(1) !important',
								},
							}}
							label={required ? `*` : ``}
							error={!!errorMessage}
							large={large}
						/>
					);
				}}
			/>
		</AppInputLayout>
	);
}

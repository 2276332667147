/* eslint-disable no-mixed-spaces-and-tabs */
import { ReactNode } from 'react';
import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { FormControl, Stack, TextFieldProps, Typography } from '@mui/material';
import Option from '../../types/option.type';
import AppInputLayout from '../form-layout/AppInputLayout';
import CountryFlag from '../form-data-display/CountryFlag';
import {
	StyledMenuItem,
	StyledSelect,
} from '../../../common/components/styled/StyledSelect';
import { ReactComponent as RakutenIcon } from '../../../assets/icon/Rakuten.svg';
import { ReactComponent as MeituanIcon } from '../../../assets/icon/Meituan.svg';
import { ApiSupplierType } from '../../../api/enum/ApiSupplier.enum';

interface AppDropDownMenuProps {
	passObjectValue?: boolean;
	label?: string;
	options: Option[];
	infoPopUp?: ReactNode;
	removeErrorPadding?: boolean;
}

export default function AppDropDownMenu<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	passObjectValue,
	label,
	options,
	infoPopUp,
	removeErrorPadding,
	disabled,
	placeholder,
	required,
	...props
}: AppDropDownMenuProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<AppInputLayout
			label={label}
			required={required}
			infoPopUp={infoPopUp}
			errorMessage={errorMessage}
			removeErrorPadding={removeErrorPadding}
		>
			<FormControl error={!!fieldState.error?.message}>
				<StyledSelect
					variant='outlined'
					{...field}
					sx={{
						// placeholder for Select
						'& .MuiSelect-select .notranslate::after': placeholder
							? {
									content: `"${placeholder}"`,
									color: '#808080',
									opacity: 1,
							  }
							: {},
					}}
					onChange={(e) => {
						field.onChange(
							passObjectValue
								? options.find((i) => i.id === e.target.value)
								: e.target.value
						);
					}}
					value={
						field.value == null
							? ''
							: passObjectValue
							? (field.value as Option).id
							: field.value
					} // to fix `value` prop on `input` should not be null
					disabled={disabled}
					MenuProps={{
						PaperProps: {
							sx: {
								borderRadius: '5px',
								boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
								'& .MuiMenuItem-root': {
									padding: '12px',
								},
								maxHeight: '250px',
							},
						},
					}}
				>
					{options.map((option) => (
						<StyledMenuItem key={option.id} value={option.id}>
							<Stack direction={'row'} alignItems={'center'}>
								{option.iso2 && (
									<CountryFlag
										iso2={option.iso2}
										size='small'
										style={{ marginRight: '12px' }}
									/>
								)}

								{(() => renderApiIcon(option.apiSupplierIcon))()}

								<Typography variant='regular' sx={{ fontSize: '0.75rem' }}>
									{option.name}
								</Typography>
							</Stack>
						</StyledMenuItem>
					))}
				</StyledSelect>
			</FormControl>
		</AppInputLayout>
	);
}

function renderApiIcon(apiSupplier: ApiSupplierType) {
	switch (apiSupplier) {
		case ApiSupplierType.RAKUTEN:
			return <RakutenIcon style={{ marginRight: '12px' }} />;
		case ApiSupplierType.MEITUAN:
			return <MeituanIcon style={{ marginRight: '12px' }} />;
		default:
			return <></>;
	}
}

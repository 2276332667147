import { Box, Button, Stack, Typography } from '@mui/material';
import {
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
} from '../../../../../common/components/AppAccordian';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	useBulkUpdateRoomAllotmentsMutation,
	useGetRoomSummaryQuery,
} from '../../../../../api/accommodationApiSlice';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppGridMultipleCheckbox from '../../../../../common/components/form-inputs/AppGridMultipleCheckbox';
import AppCounter from '../../../../../common/components/form-inputs/AppCounter';

export default function RoomToSell() {
	const { t } = useTranslation();
	const method = useFormContext();
	const { propertyId } = useParams();

	const [submitCount, setSubmitCount] = useState(0);
	const [submitted, setSubmitted] = useState(false);

	const { data: getRoomSummaryResponse } = useGetRoomSummaryQuery({
		propertyId: propertyId ?? '',
		rateModel: method.getValues('rateModel'),
	});
	const [bulkUpdateRoomAllotmentMutation] =
		useBulkUpdateRoomAllotmentsMutation();

	const defaultValues: DefaultValues<FieldValues> = {
		roomIds: [],
		adjustment: 0,
	};

	const formSchema = Yup.object().shape({
		roomIds: Yup.array().min(1, t('errorMessage.atLeastOneCheckbox')),
		adjustment: Yup.number().required(t('errorMessage.required')),
	});

	const roomToSellMethod = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const watchParentFrom = method.watch('from');
	const watchParentTo = method.watch('to');
	const watchParentDays = method.watch('days');
	const watchAdjustment = roomToSellMethod.watch('adjustment');
	const watchRoomIds = roomToSellMethod.watch('roomIds');

	useEffect(() => {
		if (submitCount === 0) return;
		method.trigger().then((r) => roomToSellMethod.trigger().then());
	}, [
		watchParentFrom,
		watchParentTo,
		watchParentDays,
		watchAdjustment,
		watchRoomIds,
		submitCount,
	]);

	useEffect(() => {
		if (submitted) {
			setTimeout(() => {
				setSubmitted(false);
			}, 3000);
		}
	}, [submitted]);

	const onSubmit: SubmitHandler<FieldValues> = () => {
		setSubmitCount((prev) => prev + 1);
		method.trigger().then((methodValidated) => {
			roomToSellMethod.trigger().then((roomToSellMethodValidated) => {
				if (methodValidated && roomToSellMethodValidated) {
					roomToSellMethod.setValue('days', method.getValues('days'));
					roomToSellMethod.setValue('from', method.getValues('from'));
					roomToSellMethod.setValue('to', method.getValues('to'));
					roomToSellMethod.setValue('rateModel', method.getValues('rateModel'));

					bulkUpdateRoomAllotmentMutation({
						propertyId: propertyId ?? '',
						body: {
							rateModel: roomToSellMethod.getValues('rateModel'),
							from: roomToSellMethod.getValues('from'),
							to: roomToSellMethod.getValues('to'),
							days: roomToSellMethod.getValues('days'),
							roomIds: roomToSellMethod.getValues('roomIds'),
							adjustment: roomToSellMethod.getValues('adjustment'),
						},
					})
						.unwrap()
						.then((response) => {
							if (response.success) {
								setSubmitCount(0);
								setSubmitted(true);
							}
						});
				}
			});
		});
	};

	return (
		<StyledAccordion>
			<StyledAccordionSummary>
				<Typography
					color={'gray'}
					sx={{ flexGrow: 1 }}
					fontWeight={'bold'}
					fontSize={'14px'}
				>
					{t('rateAndInventory.AdjustMaxAllotment')}
				</Typography>
			</StyledAccordionSummary>
			<StyledAccordionDetails>
				<AppCounter
					control={roomToSellMethod.control}
					name={'adjustment'}
					allowNegative
				/>
				<Box>
					<Typography
						color={'gray'}
						sx={{ flexGrow: 1 }}
						fontWeight={'bold'}
						fontSize={'14px'}
					>
						{t('rateAndInventory.ApplyToTheFollowingRooms')}
					</Typography>
					<Stack
						display={'flex'}
						direction={'column'}
						alignItems={'flex-start'}
					>
						<AppGridMultipleCheckbox
							control={roomToSellMethod.control}
							label={''}
							options={
								getRoomSummaryResponse?.data.map((room) => {
									return { id: room.id, name: room.nameEn };
								}) ?? []
							}
							name={'roomIds'}
							columns={1}
						/>
					</Stack>
				</Box>

				<Button
					variant={'contained'}
					onClick={roomToSellMethod.handleSubmit(onSubmit)}
					fullWidth={true}
					disabled={submitted}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{submitted ? 'Applied' : 'Confirm'}
					</Typography>
				</Button>
			</StyledAccordionDetails>
		</StyledAccordion>
	);
}

export enum ConfirmType {
	INSTANT = 0,
	ON_REQUEST = 1,
}

export function getRatePlanConfirmType(confirmType?: number): string {
	switch (confirmType) {
		case ConfirmType.ON_REQUEST:
			return 'On Request';
		case ConfirmType.INSTANT:
			return 'Instant Confirmation';
		default:
			return '';
	}
}

export const ratePlanConfirmTypeOptions = [
	{
		id: ConfirmType.INSTANT,
		name: 'Instant Confirmation',
	},
	{
		id: ConfirmType.ON_REQUEST,
		name: 'On Request',
	},
];

interface PropertyStatus {
	isOnline: boolean;
	i18nLabel: string;
}

interface PropertyStatusProps {
	name: string;
	color: string;
	backgroundColor: string;
}

export function getPropertyStatus(status: number): PropertyStatus {
	switch (status) {
		case 0:
			return {
				isOnline: true,
				i18nLabel: 'property.Online',
			};
		case 1:
			return {
				isOnline: false,
				i18nLabel: 'property.Offline',
			};
		default:
			return {
				isOnline: false,
				i18nLabel: 'common.Error',
			};
	}
}

export function getPropertyStatusDisplay(status: number): PropertyStatusProps {
	switch (status) {
		case 0:
			return {
				name: 'Online',
				color: '#4B9831',
				backgroundColor: '#E4F0DB',
			};
		case 1:
			return {
				name: 'Offline',
				color: '#DA5150',
				backgroundColor: '#FFDDDC',
			};
		default:
			return {
				name: '-',
				color: 'black',
				backgroundColor: 'transparent',
			};
	}
}

import {
	Box,
	InputLabel,
	InputLabelProps,
	styled,
	Tooltip,
} from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';
import { ReactComponent as CircleExclamationMarkIcon } from '../../../../assets/icon/circleExclamationMark.svg';
import { ReactComponent as CircleInfoIcon } from '../../../../assets/icon/circleInfo.svg';

interface AppInputLabelProps {
	label: string;
	infoPopUp?: ReactNode;
	errorMessage?: string;
	required?: boolean;
}

const Label = styled(Box)({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

export default function AppInputLabel({
	label,
	infoPopUp,
	errorMessage,
	required,
	...props
}: AppInputLabelProps & InputLabelProps) {
	return (
		<>
			<InputLabel {...props} focused={false}>
				<Stack direction={'row'} alignItems={'center'} spacing='8px'>
					<Label>
						{label}
						{required ? '*' : ''}
					</Label>
					{!!infoPopUp && (
						<Tooltip title={infoPopUp} placement='bottom-start'>
							<CircleInfoIcon />
						</Tooltip>
					)}
					{!!errorMessage && (
						<Tooltip title={errorMessage} placement='bottom-start'>
							<CircleExclamationMarkIcon />
						</Tooltip>
					)}
				</Stack>
			</InputLabel>
		</>
	);
}

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { RatePlan } from '../../../../api/DTO/ratePlan.interface';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { FormCardType } from '../../../../common/components/card/FormCard';
import RatePlanBaseInfoCard from './baseInfo/RatePlanBaseInfoCard';
import RatePlanPaymentAndPolicyCard from './paymentAndPolicy/RatePlanPaymentAndPolicyCard';
import RatePlanInclusionCard from './inclusion/RatePlanInclusionCard';
import { Button, Grid, Stack } from '@mui/material';
import { RatePlanNameType } from '../../../../api/enum/ratePlanNameType.enum';
import { MealType } from '../../../../api/enum/mealType.enum';
import { useAddRatePlanMutation } from '../../../../api/accommodationApiSlice';
import * as Yup from 'yup';
import { RatePlanChannel } from '../../../../api/enum/ratePlanChannel.enum';
import { NONE_INVALID_DATE_PATTERN } from '../../../../common/constants/pattern';
import { SuccessResponse } from '../../../../api/response/shared/success.response';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

export default function CreateRatePlanPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { propertyId, roomId } = useParams();

	// form validation rules for create
	const formSchema = Yup.object().shape({
		baseInfo: Yup.object()
			.shape({
				channel: Yup.number().required(t('errorMessage.atLeastOneCheckbox')),
				rateModel: Yup.string().required(t('errorMessage.pleaseSelect')),
				apiSupplier: Yup.number()
					.nullable()
					.when('channel', {
						is: RatePlanChannel.APISUPPLIER,
						then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
					}),
				nameType: Yup.number().required(t('errorMessage.pleaseSelect')),
				name: Yup.string().required(t('errorMessage.required')),
				nameEn: Yup.string().required(t('errorMessage.required')),
				originalName: Yup.string()
					.nullable()
					.when('nameType', {
						is: RatePlanNameType.CUSTOM,
						then: (schema) => schema.required(t('errorMessage.required')),
					}),
				mealType: Yup.string().required(t('errorMessage.pleaseSelect')),
				withMealSupplement: Yup.boolean(),
				bookingPeriod: Yup.object().shape({
					start: Yup.string()
						.required(t('errorMessage.required'))
						.matches(
							NONE_INVALID_DATE_PATTERN,
							t('errorMessage.incorrectTime')
						),
					end: Yup.string()
						.nullable()
						.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
						.when('start', (start: any, schema) => {
							return schema.test(
								'shouldGreaterThanStart',
								() => t('errorMessage.incorrectPeriodTime'),
								(value) => !!start && dayjs(value).isAfter(dayjs(start))
							);
						}),
				}),
				checkInPeriod: Yup.object().shape({
					start: Yup.string()
						.required(t('errorMessage.required'))
						.matches(
							NONE_INVALID_DATE_PATTERN,
							t('errorMessage.incorrectTime')
						),
					end: Yup.string()
						.nullable()
						.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
						.when('start', (start: any, schema) => {
							return schema.test(
								'shouldSameOrGreaterThanStart',
								() => t('errorMessage.incorrectPeriodTime'),
								(value) => {
									return (
										!!start && dayjs(value).isSameOrAfter(dayjs(start), 'day')
									);
								}
							);
						}),
				}),
				stayDay: Yup.object().shape({
					min: Yup.string().required(t('errorMessage.required')),
					max: Yup.string()
						.required(t('errorMessage.required'))
						.when('min', (min, schema) => {
							return schema.test(
								'shouldSameOrGreaterThanMin',
								() => t('errorMessage.inputDayError'),
								(value) => !!min && Number(value) >= Number(min)
							);
						}),
				}),
				cutOffDay: Yup.string().required(t('errorMessage.required')),
			})
			.required(),
		paymentAndPolicy: Yup.object()
			.shape({
				description: Yup.string().nullable(),
				uniquePolicy: Yup.string().nullable(),
				refundable: Yup.boolean(),
				cancellationPolicies: Yup.array().of(
					Yup.object().shape({
						day: Yup.string().required(t('errorMessage.required')),
						deduction: Yup.string().required(t('errorMessage.required')),
					})
				),
			})
			.required(),
		inclusion: Yup.object()
			.shape({
				supplierInclusion: Yup.string(),
				inclusion: Yup.string()
					.nullable()
					.when('hasInclusion', {
						is: true,
						then: (schema) => schema,
					}),
				thumbnail: Yup.object().required(t('errorMessage.imageRequired')),
			})
			.required(),
	});

	const defaultValues: DefaultValues<RatePlan> = {
		baseInfo: {
			channel: RatePlanChannel.DIRECT,
			rateModel: undefined,
			apiSupplier: undefined,
			enableSupplement: false,
			nameType: RatePlanNameType.SYS_GEN,
			name: '',
			nameEn: '',
			originalName: '',
			mealType: MealType.NO_MEAL,
			withMealSupplement: false,
			enableOptionalMeal: false,
			optionalMealTypes: [],
			bookingPeriod: {
				start: undefined,
				end: undefined,
			},
			checkInPeriod: {
				start: undefined,
				end: undefined,
			},
			stayDay: {
				min: undefined,
				max: undefined,
			},
			cutOffDay: 0,
			estimatedPrice: false,
		},
		paymentAndPolicy: {
			description: '',
			uniquePolicy: '',
			cancellationPolicies: [],
			refundable: false,
		},
		inclusion: {
			supplierInclusion: '',
			hasInclusion: false,
			inclusion: '',
		},
	};

	const [
		addRatePlan,
		{ data: addRatePlanRespose, isSuccess: addRatePlanSuccess },
	] = useAddRatePlanMutation();

	const onSubmit: SubmitHandler<RatePlan> = (data) => {
		addRatePlan({
			roomId: roomId ?? '',
			body: data,
		})
			.unwrap()
			.then((res: SuccessResponse<RatePlan>) => {
				if (res.success) {
					navigate(
						`/property/${propertyId}/room/${roomId}/rate-plan/${res.data.baseInfo.id}`
					);
				}
			});
	};

	const methods = useForm<RatePlan>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Stack spacing='16px'>
						<Grid container alignItems='stretch' spacing={'10px'}>
							<Grid item xs={6} display='flex'>
								<RatePlanBaseInfoCard initialType={FormCardType.create} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<RatePlanPaymentAndPolicyCard
									initialType={FormCardType.create}
								/>
							</Grid>
							<Grid item xs={6} display='flex'>
								<RatePlanInclusionCard initialType={FormCardType.create} />
							</Grid>
							<Grid item xs={6} display='flex'></Grid>
						</Grid>

						<Stack spacing='16px'>
							<Grid item xs={12}>
								<Stack direction='row-reverse' spacing='12px'>
									<Button type='submit' variant='contained' color='success'>
										{t('button.Save')}
									</Button>
									<Button
										variant='outlined'
										color='info'
										onClick={() =>
											navigate(
												`/property/${propertyId}/room/${roomId}/rate-plan`
											)
										}
									>
										{t('button.Discard')}
									</Button>
								</Stack>
							</Grid>
						</Stack>
					</Stack>
				</form>
			</FormProvider>
		</>
	);
}

import { Button, Box, Divider, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import { FormCardType } from '../../../../common/components/card/FormCard';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AppSubtitleLayout, {
	Label,
} from '../../../../common/components/form-layout/AppSubtitleLayout';
import { RestaurantSizeTypeOption } from '../../../../api/enum/restaurantSize.enum';
import { Place } from '../../../../api/DTO/place.interface';
import {
	useGetPlaceQuery,
	useUpdatePlaceCatAndFacMutation,
	useGetCategoriesQuery,
} from '../../../../api/contentApiSlice';
import { PlaceIdContext } from '../ViewPlacePage';
import FacilityTags from '../../components/FacilityTags';
import AppInputLayoutSingleCheckbox from '../../../../common/components/form-inputs/AppInputLayoutSingleCheckBox';
import { useGetFacTagsQuery } from '../../../../api/contentApiSlice';

interface CatAndFacFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
	placeType: number;
}

export default function CatAndFacForm({
	type,
	setType,
	placeType,
}: CatAndFacFormProps) {
	const { t } = useTranslation();
	const placeId = useContext(PlaceIdContext);

	const formSchema = Yup.object().shape({});

	const {
		data: getPlaceResponse,
		isLoading: isGetPlaceLoading,
		isSuccess: isGetPlaceSuccess,
	} = useGetPlaceQuery(placeId, {
		skip: type === FormCardType.create,
	});

	const { data: getCategoriesResponse, isSuccess: isGetCategoriesSuccess } =
		useGetCategoriesQuery(placeType);

	const { handleSubmit, control, setValue } =
		useFormContext() ??
		useForm<Place>({
			defaultValues: getPlaceResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const [updatePlaceCatAndFac, { isSuccess: isUpdatePlaceCatAndFacSuccess }] =
		useUpdatePlaceCatAndFacMutation();

	useEffect(() => {
		if (isUpdatePlaceCatAndFacSuccess) setType(FormCardType.view);
	}, [isUpdatePlaceCatAndFacSuccess]);

	useEffect(() => {
		if (isGetPlaceSuccess) {
			getTagsResponse?.data.map((tags, index) => {
				setValue(`catAndFac.tagCategories.${index}.id`, tags.id);
				setValue(`catAndFac.tagCategories.${index}.name`, tags.name);
				setValue(`catAndFac.tagCategories.${index}.nameEn`, tags.nameEn);
			});
		}
	}, [isGetPlaceSuccess]);

	const { data: getTagsResponse, isSuccess: itGetTagsSuccess } =
		useGetFacTagsQuery(placeType);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updatePlaceCatAndFac({
			placeId: placeId ?? '',
			body: data.catAndFac,
		});
	};

	const formContent = (
		<Stack>
			{placeType == 0 && (
				<>
					<AppDropDownMenu
						control={control}
						name='catAndFac.category'
						label={t('place.Category')}
						options={getCategoriesResponse?.data ?? []}
						passObjectValue
						required
					/>

					<AppInputLayoutSingleCheckbox
						control={control}
						name='catAndFac.hiddenGem'
						label={t('place.HiddenGem')}
					/>

					<Divider sx={{ marginBottom: '15px' }} />
				</>
			)}

			{placeType == 1 && (
				<>
					<AppSubtitleLayout label={t('place.Category')} />

					<AppDropDownMenu
						control={control}
						name='catAndFac.category'
						label={t('place.Type')}
						options={getCategoriesResponse?.data ?? []}
						passObjectValue
						required
					/>

					<AppDropDownMenu
						control={control}
						name='catAndFac.restaurantSize'
						label={t('place.RestaurantSize')}
						options={RestaurantSizeTypeOption}
					/>

					<AppInputLayoutSingleCheckbox
						control={control}
						name='catAndFac.familyFriendly'
						label={t('place.FamilyFriendly')}
					/>

					<AppInputLayoutSingleCheckbox
						control={control}
						name='catAndFac.hiddenGem'
						label={t('place.HiddenGem')}
					/>

					<AppInputLayoutSingleCheckbox
						control={control}
						name='catAndFac.kolRecommended'
						label={t('place.KolRecommended')}
					/>
					<Divider sx={{ marginBottom: '15px' }} />
				</>
			)}
			{getTagsResponse?.data.map((tags, index) => {
				return (
					<Box key={tags.id}>
						<Label
							marginBottom={'15px'}
						>{`${tags.nameEn} / ${tags.name}`}</Label>
						<FacilityTags
							tagCatId={tags.id ?? ''}
							control={control}
							name={`catAndFac.tagCategories.${index}.tags`}
							nameEn={`${tags.nameEn}`}
							tags={tags.tags ?? []}
						/>
						{index + 1 < getTagsResponse?.data.length && (
							<Divider sx={{ marginBottom: '15px' }} />
						)}
					</Box>
				);
			})}

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetPlaceLoading && form}</>;
}

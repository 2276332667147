import { Button, Typography } from '@mui/material';
import AppModal from './AppModal';
import { useUpdateUserStatusMutation } from '../../../api/userApiSlice';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal } from './modalSlice';

interface ActivateModalProps {
	userId: string;
	nickname: string;
}

const ActivateModal = ({ userId, nickname }: ActivateModalProps) => {
	const dispatch = useAppDispatch();
	const [updateUserStatus] = useUpdateUserStatusMutation();

	const activateButtonOnClick = () => {
		updateUserStatus({
			userId,
			body: {
				status: 0,
			},
		})
			.unwrap()
			.then(() => {
				dispatch(hideModal());
			});
	};

	return (
		<AppModal
			title={
				<>
					Activate{' '}
					<Typography variant='bold' color='#4EA4D3'>
						{nickname}
					</Typography>
				</>
			}
			content={
				<>
					Are you sure to activate the account of{' '}
					<Typography component='span' variant='inherit' color='#4EA4D3'>
						{nickname}
					</Typography>
					, which enable the user to access to the admin portal accordingly?
				</>
			}
			actionButtonEl={
				<Button variant='contained' onClick={activateButtonOnClick}>
					Activate
				</Button>
			}
		/>
	);
};

export default ActivateModal;

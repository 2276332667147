import { Box, Typography } from '@mui/material';
import { ReactComponent as CircleTickIcon } from '../../../assets/icon/circleTick.svg';
import { ReactComponent as CircleCrossIcon } from '../../../assets/icon/circleCross.svg';

interface TickOrCrossWithLabelDisplayProps {
	value?: boolean;
	label: string;
}

export default function TickOrCrossWithLabelDisplay({
	value,
	label,
}: TickOrCrossWithLabelDisplayProps) {
	return (
		<>
			{value !== undefined && (
				<Box sx={{ display: 'flex', gap: '12px', alignItems: 'center', marginLeft: '0 !important'}}>
					{value && (
						<>
							<CircleTickIcon color='#79B765' />
							<Typography fontSize='12px'>{label}</Typography>
						</>
					)}
					{!value && (
						<>
							<CircleCrossIcon color='#F6403F' />
							<Typography fontSize='12px'>{label}</Typography>
						</>
					)}
				</Box>
			)}
		</>
	);
}

import { Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteSectionMutation } from '../../../api/pageApiSlice';
import { useAppDispatch } from '../../../redux-hooks';
import AppModal from './AppModal';
import { hideModal } from './modalSlice';

interface DeleteSectionModalProps {
	sectionId: string;
	title: string;
	page: string;
}

const DeleteSectionModal = ({
	sectionId,
	title,
	page,
}: DeleteSectionModalProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [deleteSection, { isSuccess }] = useDeleteSectionMutation();

	const deleteSectionButtonOnClick = () => {
		deleteSection(sectionId);
	};

	useEffect(() => {
		if (isSuccess) {
			navigate(`/page/${page}`);
			dispatch(hideModal());
		}
	}, [isSuccess]);

	return (
		<AppModal
			title={
				<Typography variant='bold' color='#DA5150'>
					Delete {title}
				</Typography>
			}
			content={
				<>
					Are you sure to delete the section of{' '}
					<Typography component='span' variant='inherit' color='#DA5150'>
						{title}
					</Typography>
					, which the section will no longer exist in the admin portal?
				</>
			}
			actionButtonEl={
				<Button
					variant='contained'
					color='error'
					onClick={deleteSectionButtonOnClick}
				>
					Delete
				</Button>
			}
		/>
	);
};

export default DeleteSectionModal;

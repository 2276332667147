import {
	Button,
	Card,
	Divider,
	Grid,
	Stack,
	styled,
	Typography,
} from '@mui/material';
import DisplayText from '../../common/components/DisplayText';
import InfoCard from '../../common/components/card/InfoCard';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/components/PageHeader';
import { SubmitHandler, DefaultValues, useForm } from 'react-hook-form';
import AppTextField from '../../common/components/form-inputs/archive/AppTextField';
import AppDropDownMenu from '../../common/components/form-inputs/archive/AppDropDownMenu';
import AppEmailTextField from '../../common/components/form-inputs/AppEmailTextField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppStatusToggle from '../../common/components/AppStatusToggle';
import ChangePasswordDialog from './ChangePasswordDialog';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	useGetMeQuery,
	useGetUserQuery,
	useUpdateUserMutation,
} from '../../api/userApiSlice';
import FullScreenLoader from '../../common/components/FullScreenLoader';
import defineAbilityFor from '../../casl/defineAbility';
import { subject } from '@casl/ability';
import { Can } from '@casl/react';
import { getUserStatus } from '../../utils/systemUserHelper';
import { useAppDispatch } from '../../redux-hooks';
import { showModal } from '../../common/components/modal/modalSlice';
import { DISPLAY_DATE_TIME_FORMAT } from '../../utils/dateHelper';
import dayjs from 'dayjs';
import { userRoleOptions } from '../../api/enum/userRole.enum';
import {
	showSnackbar,
	SnackbarType,
} from '../../common/components/snackbar/snackbarSlice';

const StyledCard = styled(Card)(({ theme }) => ({
	width: 884,
	background: theme.palette.background.infoCard,
	boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
	borderRadius: 5,
	paddingTop: 40,
	paddingLeft: 40,
	paddingRight: 40,
	paddingBottom: 23,
}));

export type FormValues = {
	nickname: string;
	role: number;
	email: string;
};

export const defaultValues: DefaultValues<FormValues> = {
	nickname: '',
	// role: null,
	email: '',
};

export default function SystemUserDetailPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { state } = useLocation();

	const [systemUserSummarySearchParams, setSystemUserSummarySearchParams] =
		useState<string | undefined>(undefined);

	useEffect(() => {
		if (state?.systemUserSummarySearchParams) {
			setSystemUserSummarySearchParams(state?.systemUserSummarySearchParams);
		}
	}, []);

	const backPath = systemUserSummarySearchParams
		? `/sys-user?${systemUserSummarySearchParams}`
		: '/sys-user';

	const { userId } = useParams();
	const {
		data: getUserResponse,
		isLoading: isGetUserLoading,
		isSuccess: isGetUserSuccess,
		isError: isGetUserError,
	} = useGetUserQuery(userId ?? '', { refetchOnMountOrArgChange: true });

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	useEffect(() => {
		reset({
			nickname: getUserResponse?.data.nickname ?? '',
			role: getUserResponse?.data.role,
			email: getUserResponse?.data.email ?? '',
		});
	}, [getUserResponse]);

	const [updateUser, { isSuccess: isUpdateSuccess }] = useUpdateUserMutation();

	const formSchema = Yup.object().shape({
		nickname: Yup.string().required(t('errorMessage.required')),
		role: Yup.string().required(t('errorMessage.required')),
		email: Yup.string()
			.required(t('errorMessage.required'))
			.email(t('errorMessage.email')),
	});

	const { handleSubmit, control, reset } = useForm<FormValues>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		updateUser({ userId: userId ?? '', body: data });
	};

	useEffect(() => {
		if (isUpdateSuccess) {
			dispatch(
				showSnackbar({
					snackbarType: SnackbarType.SAVE_SUCCESS,
					snackbarProps: {
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'center',
						},
						message: t('snackbar.SaveSuccessMessage'),
					},
				})
			);
		}
	}, [isUpdateSuccess]);

	// change password dialog

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// delete user

	const handleDeleteUser = (userId: string, nickname: string) => {
		dispatch(
			showModal({
				modalType: 'DELETE_USER',
				modalProps: {
					userId,
					nickname,
				},
			})
		);
	};

	const handleStatusChange = (
		event: React.MouseEvent<HTMLElement>,
		activated: boolean | null
	) => {
		if (activated == null) return;
		if (activated) {
			dispatch(
				showModal({
					modalType: 'ACTIVATE_USER',
					modalProps: {
						userId: getUserResponse?.data.id,
						nickname: getUserResponse?.data.nickname,
					},
				})
			);
		} else {
			dispatch(
				showModal({
					modalType: 'DEACTIVATE_USER',
					modalProps: {
						userId: getUserResponse?.data.id,
						nickname: getUserResponse?.data.nickname,
					},
				})
			);
		}
	};

	let pageContent;
	if (isGetUserLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetUserSuccess) {
		pageContent = (
			<>
				<PageHeader
					showBackButton={true}
					backPath={backPath}
					title={`${getUserResponse?.data.nickname}’s Profile`}
					actionJsx={
						<AppStatusToggle
							enableI18nLabel={t('systemUser.Activated')}
							disableI18nLabel={t('systemUser.NonActivated')}
							initialValue={
								getUserStatus(getUserResponse.data.status).isActivated
							}
							statusOnChange={handleStatusChange}
						/>
					}
				/>

				<Stack direction='row' alignItems='flex-start' spacing='20px'>
					<StyledCard>
						<Can
							I='update'
							this={subject('User', { role: getUserResponse?.data.role })}
							ability={ability}
							passThrough
						>
							{(allowed) => (
								<form onSubmit={handleSubmit(onSubmit)}>
									<Grid container columnSpacing='40px' rowSpacing='24px'>
										<Grid item xs={6}>
											<AppTextField
												control={control}
												name='nickname'
												label={t('systemUser.UserName')}
												placeholder={t('systemUser.UserNamePlaceholder')}
												disabled={!allowed}
												required
											/>
										</Grid>
										<Grid item xs={6}>
											<AppDropDownMenu
												control={control}
												name='role'
												label={t('systemUser.Role')}
												placeholder={t('systemUser.RolePlaceholder')}
												disabled={!allowed}
												options={userRoleOptions}
												required
												infoPopUp={
													<>
														<b>System Admin</b>
														<br />
														This role has full access and control over the admin
														portal.
														<br />
														<br />
														<b>Admin</b>
														<br />
														This role has full access to the admin portal, but
														no control over <b>System User</b>.
														<br />
														<br />
														<b>Customer Service Officer</b>
														<br />
														This role only has limited access to the admin
														portal, except System User. It also has the
														permission to view, create, and update in{' '}
														<b>Bookings</b>.
														<br />
														<br />
														<b>Data Entry Officer</b>
														<br />
														This role only has few access to the admin portal,
														include <b>Property</b>, <b>Rate & Inventory</b>,
														and <b>Product</b>
														. It has permission to view, create, and update
														details.
														<br />
														<br />
														<b>Accountant</b>
														<br />
														This role only has access to <b>Bookings</b> to view
														the details.
													</>
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<Divider />
										</Grid>
										<Grid item xs={6}>
											<AppEmailTextField
												control={control}
												name='email'
												label={t('systemUser.UsersEmailAddress')}
												placeholder={t('common.TypeHere')}
												disabled
												required
											/>
										</Grid>
										<Grid item xs={6}></Grid>
										{allowed && (
											<>
												<Grid item xs={12}>
													<Divider />
												</Grid>
												<Grid item xs={6}>
													<Stack
														direction='row'
														justifyContent='space-between'
														spacing='20px'
													>
														<Typography fontSize={14}>
															{t('common.Password')}
														</Typography>
														<Typography
															sx={{
																textDecoration: 'underline',
																cursor: 'pointer',
															}}
															fontSize={14}
															color='text.clickableBlue'
															marginLeft={10}
															onClick={handleClickOpen}
														>
															{t('button.ChangePassword')}
														</Typography>
													</Stack>
												</Grid>
												<Grid item xs={6}></Grid>
											</>
										)}

										<Grid item xs={12}>
											<Stack direction='row-reverse' spacing='12px'>
												{allowed && (
													<Button
														type='submit'
														variant='contained'
														color='success'
													>
														{t('button.Save')}
													</Button>
												)}

												{ability.can(
													'delete',
													subject('User', { role: getUserResponse?.data.role })
												) && (
													<Button
														variant='outlined'
														color='warning'
														onClick={() =>
															handleDeleteUser(
																getUserResponse?.data.id,
																getUserResponse?.data.nickname
															)
														}
													>
														{t('button.DeleteAccount')}
													</Button>
												)}

												<Button
													variant='outlined'
													color='info'
													onClick={() => navigate(backPath)}
												>
													{t('button.Back')}
												</Button>
											</Stack>
										</Grid>
									</Grid>
								</form>
							)}
						</Can>
					</StyledCard>
					<InfoCard
						title='System info'
						content={
							<>
								<DisplayText
									label={t('systemUser.UserID')}
									value={getUserResponse?.data.id}
								/>
								<DisplayText
									label={t('systemUser.CreatedDate')}
									value={dayjs(getUserResponse?.data.createdAt).format(
										DISPLAY_DATE_TIME_FORMAT
									)}
								/>
								<DisplayText
									label={t('systemUser.UpdatedDate')}
									value={dayjs(getUserResponse?.data.updatedAt).format(
										DISPLAY_DATE_TIME_FORMAT
									)}
								/>
								<DisplayText
									label={t('systemUser.LastLogin')}
									value={
										getUserResponse?.data.lastLogin
											? dayjs(getUserResponse?.data.lastLogin).format(
													DISPLAY_DATE_TIME_FORMAT
											  )
											: '-'
									}
								/>
							</>
						}
					/>
				</Stack>
				<ChangePasswordDialog
					userId={getUserResponse.data.id}
					open={open}
					onClose={handleClose}
				/>
			</>
		);
	} else if (isGetUserError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}

import dayjs from 'dayjs';
import { Markup } from '../api/DTO/room.interface';
import { ApiSupplierType } from '../api/enum/ApiSupplier.enum';
import { MarkupType } from '../api/enum/markupType.enum';
import { MealType } from '../api/enum/mealType.enum';
import { RateModel } from '../api/enum/rateModel.enum';
import { RatePlanChannel } from '../api/enum/ratePlanChannel.enum';

interface RatePlanStatus {
	isOnline: boolean;
	i18nLabel: string;
}

export function getRatePlanStatus(status: number): RatePlanStatus {
	switch (status) {
		case 0:
			return {
				isOnline: true,
				i18nLabel: 'room.ratePlanSummary.Online',
			};
		case 1:
			return {
				isOnline: false,
				i18nLabel: 'room.ratePlanSummary.Offline',
			};
		default:
			return {
				isOnline: false,
				i18nLabel: 'common.Error',
			};
	}
}

export function getRatePlanMealType(mealType?: number): string {
	switch (mealType) {
		case MealType.NO_MEAL:
			return 'No meal';
		case MealType.BREAKFAST:
			return 'Breakfast';
		case MealType.LUNCH:
			return 'Lunch';
		case MealType.DINNER:
			return 'Dinner';
		case MealType.HALF_BOARD:
			return 'Half board';
		case MealType.FULL_BOARD:
			return 'Full board';
		default:
			return '';
	}
}

export function getRatePlanChannelAndSupplier(
	ratePlanChannel: RatePlanChannel,
	apiSupplier?: ApiSupplierType
): string {
	switch (ratePlanChannel) {
		case RatePlanChannel.DIRECT:
			return 'Direct Hotel';
		case RatePlanChannel.APISUPPLIER:
			switch (apiSupplier) {
				case ApiSupplierType.RAKUTEN:
					return 'API: Rakuten';
				case ApiSupplierType.MEITUAN:
					return 'API: Meituan';
				default:
					return '';
			}
	}
}

export function getRateModel(rateModel?: RateModel): string {
	switch (rateModel) {
		case RateModel.AGENCY:
			return 'NETT Model';
		case RateModel.MERCHANT:
			return 'Commissionable Model';
		default:
			return '';
	}
}

export function getDefaultMarkUp(
	markup: Markup | undefined,
	globalMarkup: boolean | undefined,
	currency?: string
): string {
	if (markup === null || markup === undefined || markup.amount === 0) {
		return '';
	}

	switch (markup.type) {
		case MarkupType.PERCENTAGE:
			if (globalMarkup) {
				return markup.amount + '% (Using Global Markup)';
			} else {
				return markup.amount + '% ';
			}
		case MarkupType.FIXED:
			if (globalMarkup) {
				return markup.amount + ' ' + currency + ' (Using Global Markup)';
			} else {
				return markup.amount + ' ' + currency;
			}
		default:
			return '';
	}
}

export function getMarkupWording(
	markup: Markup | undefined,
	currency?: string
): string {
	if (markup === undefined || markup.amount === 0) {
		return '';
	}

	switch (markup.type) {
		case MarkupType.PERCENTAGE:
			return markup.amount + '\t%';
		case MarkupType.FIXED:
			return markup.amount + '\t fixed in ' + currency;
		default:
			return '';
	}
}

export function isDateListOverlapped(
	dateList: { start: Date; end: Date }[]
): boolean {
	dateList.sort((a, b) => a.start.getTime() - b.start.getTime());

	for (let i = 1; i < dateList.length; i++) {
		if (
			dayjs(dateList[i].start).format('YYYY-MM-DD') <=
			dayjs(dateList[i - 1].end).format('YYYY-MM-DD')
		) {
			return true;
		}
	}

	return false;
}

import { Box, Link, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

interface LinkRouterProps extends LinkProps {
	to: string;
	replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
	return (
		<Link
			{...props}
			color='#808080'
			underline='hover'
			sx={{
				'&:hover': {
					color: '#2C2C2C',
				},
			}}
			component={RouterLink}
		/>
	);
}

interface StyledBreadcrumbsProps {
	icon?: ReactNode;
	label: string;
	to?: string;
	active?: boolean;
}

export default function StyledBreadcrumb(
	props: React.PropsWithChildren<StyledBreadcrumbsProps>
) {
	const { icon, label, to, active } = props;

	return active ? (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '10px',
				color: '#32A5F1',
			}}
		>
			{icon}
			<Typography color='#32A5F1'>{label}</Typography>
		</Box>
	) : (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '10px',
				color: '#808080',
				'&:hover': {
					color: '#2C2C2C',
				},
			}}
		>
			{icon}
			<LinkRouter to={to ?? ''}>{label}</LinkRouter>
		</Box>
	);
}

import fileDownload from 'js-file-download';
import {
	TangleFilter,
	TangleLog,
	TanglePeek,
	TangleVersion,
} from './DTO/propertyImport.interface';
import { apiSlice } from './apiSlice';
import { SuccessResponse } from './response/shared/success.response';

const SERVICE_PATH = '/scraper-service';

export const scraperApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// Tangle

		getTangleVersion: builder.query<SuccessResponse<TangleVersion>, void>({
			query: () => `${SERVICE_PATH}/tangle/version`,
			providesTags: ['PropertyImport'],
		}),

		addTanglePeek: builder.mutation<SuccessResponse<TanglePeek>, TangleFilter>({
			query: (body) => ({
				url: `${SERVICE_PATH}/tangle/peek`,
				method: 'POST',
				body,
			}),
		}),
		addTangleWrite: builder.mutation<SuccessResponse<string>, TangleFilter>({
			query: (body) => ({
				url: `${SERVICE_PATH}/tangle/write`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['PropertyImport'],
		}),
		getTangleLog: builder.query<SuccessResponse<TangleLog[]>, void>({
			query: () => `${SERVICE_PATH}/tangle/logs`,
			providesTags: ['PropertyImport'],
		}),

		getTangleReport: builder.query<Blob, string>({
			query(logId) {
				return {
					url: `${SERVICE_PATH}/tangle/logs/${logId}/report`,
					method: 'GET',
					responseHandler: async (response) => {
						const contentDisposition = response.headers.get(
							'Content-Disposition'
						);
						const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

						if (contentDisposition) {
							const matches = fileNameRegex.exec(contentDisposition);
							if (matches != null && matches[1]) {
								const fileName = decodeURIComponent(
									JSON.parse('"' + matches[1].replace(/['"]/g, '') + '"')
								);
								fileDownload(await response.blob(), fileName);
							}
						}
					},
					cache: 'no-cache',
				};
			},
		}),
	}),
});

export const {
	useGetTangleVersionQuery,
	useAddTanglePeekMutation,
	useAddTangleWriteMutation,
	useGetTangleLogQuery,
	useLazyGetTangleReportQuery,
} = scraperApi;

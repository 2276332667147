import TitleCard from '../../../../common/components/card/TitleCard';
import { useTranslation } from 'react-i18next';
import RoomImageForm from './roomImageForm';
import AppAlert from '../../../../common/components/AppAlert';
import { Box } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';

export default function RoomImageCard() {
	const { t } = useTranslation();
	const { propertyId } = useParams();

	return (
		<TitleCard title={t('gallery.Room')}>
			<Box
				position={'absolute'}
				sx={{
					top: '50px',
					right: '30px',
				}}
			>
				<AppAlert alertColor='#4B9831' alertBgColor='#E1F2DB' alertBar={true}>
					<Trans
						i18nKey={'gallery.VisitRoomAlert'}
						components={{
							TranLink: (
								<Box
									to={`/property/${propertyId}/room`}
									component={Link}
									color='#4B9831'
									paddingX={'3px'}
									fontWeight={700}
								/>
							),
						}}
					/>
				</AppAlert>
			</Box>
			{(() => {
				return <RoomImageForm />;
			})()}
		</TitleCard>
	);
}

import { useState } from 'react';
import { styled } from '@mui/system';
import {
	DataGrid,
	GridSelectionModel,
	GridRowIdGetter,
	GridToolbar,
	GridHeader,
} from '@mui/x-data-grid';

/* eslint-disable */
interface CustomDataGridProps {
	rows: any[];
	columns: any[];
	rowCount: number;
	getRowId: GridRowIdGetter<any> | undefined;
	onPageChange?: (page: number) => void;
	onPageSizeChange?: (pageSize: number) => void;
	pagination?: boolean;
}
/* eslint-enable */

/**
 * @deprecated This component should not be used anymore. Please use AppDataGrid instead.
 */
export default function CustomDataGrid({
	rows,
	columns,
	rowCount,
	getRowId,
	onPageChange,
	onPageSizeChange,
	pagination,
}: CustomDataGridProps) {
	const initialPageSize = 20;
	const initialPage = 0;
	const [page, setPage] = useState<number>(initialPage);
	const [pageSize, setPageSize] = useState<number>(initialPageSize);
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

	return (
		<StyledDataGrid
			rows={rows}
			columns={columns}
			getRowId={getRowId}
			rowCount={rowCount}
			hideFooter={pagination == null || pagination == true ? false : true}
			paginationMode='server'
			page={page}
			onPageChange={(page: number) => {
				setPage(page);
				onPageChange ? onPageChange(page) : () => {};
			}}
			pageSize={pagination ? pageSize : 100}
			onPageSizeChange={(pageSize: number) => {
				setPageSize(pageSize);
				onPageSizeChange ? onPageSizeChange(pageSize) : () => {};
			}}
			pagination={pagination ? pagination : true}
			rowsPerPageOptions={pagination ? [10, 20, 50, 100] : []}
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableDensitySelector
			disableColumnMenu
			sortingMode='server'
			// experimentalFeatures={{ newEditingApi: true }}
			autoHeight
			checkboxSelection={false}
			headerHeight={44}
			getRowHeight={() => 'auto'}
			// rowHeight={60}
			onSelectionModelChange={(newSelectionModel) => {
				setSelectionModel(newSelectionModel);
			}}
			selectionModel={selectionModel}
			components={{
				Toolbar: GridToolbar,
				Header: StyledGridHeader,
			}}
			componentsProps={{
				toolbar: {
					csvOptions: { disableToolbarButton: true },
					printOptions: { disableToolbarButton: true },
					quickFilterProps: { debounceMs: 250 },
					// showQuickFilter: true,
				},
			}}
		/>
	);
}

const StyledDataGrid = styled(DataGrid)(() => ({
	boxShadow: ' 0px 4px 3px 0px rgba(0, 0, 0, 0.05)',
	backgroundColor: '#FFFFFF',
	borderRadius: 5,
	border: 1,
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: '#E8EBED',
	},
	'& .MuiDataGrid-columnHeaderTitle': {
		fontSize: '14px',
		fontWeight: 700,
		whiteSpace: 'break-spaces',
		lineHeight: '14px',
	},
	'& .MuiDataGrid-columnSeparator': {
		visibility: 'hidden',
	},
	'& .MuiDataGrid-iconButtonContainer button': {
		display: 'none',
	},
	// '& .MuiDataGrid-columnHeaderTitleContainerContent': {
	// 	width: '100%',
	// 	justifyContent: 'center',
	// }
	// ,
	'.MuiDataGrid-columnHeader:first-of-type': {
		padding: '0 10px 0 24px',
	},
	'.MuiDataGrid-cell:first-of-type': {
		padding: '0 10px 0 24px',
	},
	'.MuiDataGrid-columnHeader:last-of-type': {
		padding: '0 24px 0 10px',
	},
	'.MuiDataGrid-cell:last-of-type': {
		padding: '0 24px 0 10px',
	},
	'.MuiDataGrid-cellContent': {
		fontSize: '12px',
		lineHeight: '1.5',
		wordWrap: 'break-word',
		whiteSpace: 'normal',
		padding: '15px 0',
	},
}));

const StyledGridHeader = styled(GridHeader)({
	fontSize: 50,
});

import {
	Paper,
	styled,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';

export enum PerPersonRateType {
	EDIT_RATE,
	NETT,
	SELLING_RATE,
}

interface PerPersonRateToggleProps {
	value: PerPersonRateType;
	handleChange: (
		event: React.MouseEvent<HTMLElement>,
		newPerPersonRateType: PerPersonRateType
	) => void;
	selectedColor?: string;
	showSupplierCost?: boolean;
}

export default function PerPersonRateToggle({
	value,
	handleChange,
	selectedColor,
	showSupplierCost,
}: PerPersonRateToggleProps) {
	return (
		<StyledPaper elevation={0} showSupplierCost={showSupplierCost}>
			<StyledToggleButtonGroup
				exclusive={true}
				value={value}
				onChange={handleChange}
				onClick={(event) => event.stopPropagation()}
				selectedColor={selectedColor}
			>
				<ToggleButton value={PerPersonRateType.EDIT_RATE}>
					<Typography
						display={'inline-block'}
						variant='bold'
						fontSize='12px'
						marginLeft='4px'
					>
						Edit Rate
					</Typography>
				</ToggleButton>
				{showSupplierCost && (
					<ToggleButton value={PerPersonRateType.NETT}>
						<Typography
							display={'inline-block'}
							variant='bold'
							fontSize='12px'
							marginLeft='4px'
						>
							NETT
						</Typography>
					</ToggleButton>
				)}
				<ToggleButton value={PerPersonRateType.SELLING_RATE}>
					<Typography
						display={'inline-block'}
						variant='bold'
						fontSize='12px'
						marginLeft='4px'
					>
						Selling
					</Typography>
				</ToggleButton>
			</StyledToggleButtonGroup>
		</StyledPaper>
	);
}

const StyledPaper = styled(Paper, {
	shouldForwardProp: (prop) => prop !== 'showSupplierCost',
})<{
	showSupplierCost?: boolean;
}>(({ showSupplierCost }) => ({
	width: showSupplierCost ? '240px' : '170px',
	backgroundColor: 'white',
	borderRadius: '5px',
	border: '1px solid #EAEAEA',
	display: 'flex',
	justifyContent: 'center',
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
	shouldForwardProp: (prop) => prop !== 'selectedColor',
})<{
	selectedColor?: string;
}>(({ selectedColor }) => ({
	'& .MuiToggleButtonGroup-grouped': {
		margin: '4px',
		border: 0,
		textTransform: 'none',
		padding: '3px 15px',
		color: '#808080',
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: selectedColor ? selectedColor : '#32A5F1',
		},
		': hover': {
			backgroundColor: 'transparent',
		},

		'&.Mui-disabled': {
			border: 0,
		},
		'&:not(:first-of-type)': {
			borderRadius: '5px',
			'&.Mui-selected': {
				color: '#FFFFFF',
			},
		},
		'&:first-of-type': {
			borderRadius: '5px',
			'&.Mui-selected': {
				color: '#FFFFFF',
			},
		},
	},
}));

import { Button, Box, Grid, Stack, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import AppToggleButtonGroup from '../../../../common/components/form-inputs/AppToggleButtonGroup';
import TitleColorDisplay from '../../../../common/components/form-data-display/TitleColorDisplay';
import {
	useUpdateRoomFacilitiesMutation,
	useGetRoomSummaryQuery,
	useGetRfsubcategoriesQuery,
	useLazyGetRoomFacilitiesQuery,
} from '../../../../api/accommodationApiSlice';
import { useParams } from 'react-router-dom';
import { facilityOption } from '../../../../api/enum/facility.enum';
import QuickActionDialog from './QuickActionDialog';
import Option from '../../../../common/types/option.type';
import { FormValues } from './QuickActionDialog';
import { ReactComponent as LightningIcon } from '../../../../assets/icon/lightning.svg';
import {
	RFSubcategoryWithFacilities,
	RFacility,
	RFacilityMapping,
} from '../../../../api/DTO/facility.interface';
import AppAlert from '../../../../common/components/AppAlert';

interface RoomFacilityFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function RoomFacilityForm({
	type,
	setType,
}: RoomFacilityFormProps) {
	const { propertyId, rfcategoryId } = useParams();

	const { t } = useTranslation();

	const { data: getRfsubcategoriesResponse } = useGetRfsubcategoriesQuery(
		rfcategoryId ?? ''
	);

	const [getRoomFacilities] = useLazyGetRoomFacilitiesQuery();

	const { data: getRoomResponse } = useGetRoomSummaryQuery({
		propertyId: propertyId ?? '',
	});

	const { control, handleSubmit, setValue, getValues, reset } =
		useForm<RFacilityMapping>({
			mode: 'onChange',
		});

	const [updateRoomFacilities, { isSuccess: isUpdateRoomFacilitiesSuccess }] =
		useUpdateRoomFacilitiesMutation();

	useEffect(() => {
		const fetchTargetLocaleData = async () => {
			const targetLocaleResponse = await getRoomFacilities({
				propertyId: propertyId ?? '',
				rfcategoryId: rfcategoryId ?? '',
			});
			reset(targetLocaleResponse.data?.data);
		};
		fetchTargetLocaleData();
	}, [propertyId, rfcategoryId]);

	useEffect(() => {
		if (isUpdateRoomFacilitiesSuccess) setType(FormCardType.view);
	}, [isUpdateRoomFacilitiesSuccess]);

	const onSubmit: SubmitHandler<RFacilityMapping> = (data) => {
		Object.keys(data).forEach((key) => {
			Object.keys(data[key]).forEach((key2) => {
				if (data[key][key2] === '' || data[key][key2] == null) {
					delete data[key][key2];
				}
			});
		});

		updateRoomFacilities({
			propertyId: propertyId ?? '',
			rfcategoryId: rfcategoryId ?? '',
			body: data,
		});
	};

	// change room facility dialog

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const rooms: Option[] = getRoomResponse?.data || [{ id: '', name: '' }];

	const onQuickActionSubmit = (result: FormValues) => {
		Object.keys(getValues(result.baseRoomId)).forEach((baseRoom) => {
			result.applyRoomId?.forEach((item) => {
				if (getValues(result.baseRoomId + `.${baseRoom}`) === undefined) {
					setValue(item + `.${baseRoom}`, '');
				} else {
					setValue(item, getValues(result.baseRoomId));
				}
			});
		});
	};
	const roomFacilityRefs = useRef<HTMLDivElement[]>([]);

	const formContent = (
		<Stack position={'relative'}>
			{type === FormCardType.edit && (
				<Box sx={{ width: '100%' }}>
					<Stack direction='row-reverse' spacing='12px'>
						<Button
							variant='outlined'
							color='primary'
							onClick={handleClickOpen}
							sx={{ gap: '5px' }}
						>
							<LightningIcon />
							{t('button.QuickAction')}
						</Button>
					</Stack>
				</Box>
			)}
			<Box
				position={'absolute'}
				sx={{
					top: 0,
					left: 0,
				}}
			>
				<AppAlert alertColor='#32A5F1' alertBar={false}>
					{t('facility.TagStyleMessage')}
				</AppAlert>
			</Box>
			<Box maxWidth={'100%'} marginY={'20px'}>
				{getRfsubcategoriesResponse?.data &&
					getRfsubcategoriesResponse?.data.map(
						(
							subcategory: RFSubcategoryWithFacilities,
							subcategoryIndex: number
						) => {
							return (
								<Grid
									container
									padding='0px'
									position='relative'
									key={subcategory.id}
								>
									<Grid item key={subcategory.id}>
										<Box display={'flex'} alignItems={'center'} height={'72px'}>
											<Box minWidth={'17rem'}>
												<Typography fontSize={14} fontWeight={700}>
													{subcategory.nameEn}
												</Typography>
											</Box>
										</Box>
										{subcategory.facilities?.map((facility: RFacility) => {
											return (
												<Box
													key={facility.id}
													display={'flex'}
													alignItems={'center'}
													height={'60px'}
													paddingBottom={'5px'}
												>
													<Box minWidth={'17rem'} maxWidth={'270px'}>
														<Typography
															key={facility.id}
															fontSize={14}
															color={
																facility.highlighted ? '#32A5F1' : '#808080'
															}
														>
															{facility.nameEn}
														</Typography>
													</Box>
												</Box>
											);
										})}
									</Grid>
									<Grid
										item
										xs
										sx={{ overflowX: 'auto' }}
										ref={(el: HTMLDivElement) =>
											(roomFacilityRefs.current[subcategoryIndex] = el)
										}
										onScroll={() => {
											roomFacilityRefs.current.forEach((el, i) => {
												if (i !== subcategoryIndex && el) {
													el.scrollLeft =
														roomFacilityRefs.current[
															subcategoryIndex
														].scrollLeft;
												}
											});
										}}
									>
										<Box
											display={'flex'}
											alignItems={'center'}
											minHeight={'72px'}
										>
											{subcategoryIndex == 0 &&
												getRoomResponse?.data.map((room, index) => {
													return (
														<TitleColorDisplay
															key={room.id}
															name={getRoomResponse?.data[index].nameEn}
														/>
													);
												})}
										</Box>
										{subcategory.facilities?.map((facility: RFacility) => {
											return (
												<Box
													key={facility.id}
													display={'flex'}
													alignItems={'center'}
												>
													{getRoomResponse?.data.map((room) => {
														const name = room.id + '.' + facility.id;
														return (
															<Box
																key={room.id}
																minWidth={'17rem'}
																margin={'8px 1rem 0px 1rem'}
															>
																<Box
																	key={facility.id}
																	display={'flex'}
																	alignItems={'center'}
																>
																	<AppToggleButtonGroup
																		name={name ?? ''}
																		options={facilityOption}
																		control={control}
																		formLabel={''}
																	/>
																</Box>
															</Box>
														);
													})}
												</Box>
											);
										})}
									</Grid>
									<Grid item xs={12}>
										{subcategoryIndex <
											getRfsubcategoriesResponse?.data.length - 1 && (
											<Divider sx={{ marginTop: '20px' }} />
										)}
									</Grid>
								</Grid>
							);
						}
					)}
			</Box>
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form = <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;

	return (
		<>
			{form}
			<QuickActionDialog
				open={open}
				onClose={handleClose}
				rooms={rooms}
				onQuickActionSubmit={onQuickActionSubmit}
			/>
		</>
	);
}

import {
	Abk,
	AbkFilter,
	AbkOtherInfo,
	AbkTransactions,
	AbkUpdateStatus,
	Amendments,
	FullAbkTransactionTable,
	TransactionLogs,
	AbkCustomerEmailItem,
	AbkSupplierEmailItem,
	AbkContactPoints,
} from './DTO/abk.interface';
import { apiSlice } from './apiSlice';
import { AbkSummaryResponse } from './response/abkSummary.response';
import { SuccessResponse } from './response/shared/success.response';
import fileDownload from 'js-file-download';

const SERVICE_PATH = '/booking-service';

export const AbkApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		//ABK
		addAbk: builder.mutation<SuccessResponse<Abk>, Abk>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/abks`,
				method: 'POST',
				body,
			}),
		}),

		getAbk: builder.query<
			SuccessResponse<Abk>,
			{ abk_id: string; version: number | undefined }
		>({
			query: ({ abk_id, version }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}?version=${
					version === undefined ? '' : version
				}`,
			}),
			providesTags: ['Abk'],
		}),

		getFilteredAbk: builder.query<AbkSummaryResponse, AbkFilter>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/abks/filter`,
				method: 'POST',
				body,
			}),
		}),

		updateAbk: builder.mutation<
			SuccessResponse<Abk>,
			{ abkId: string; body: Abk }
		>({
			query: ({ abkId, body }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abkId}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Abk', 'AbkStatus'],
		}),

		deleteAbk: builder.mutation<SuccessResponse<null>, string>({
			query: (abk_id) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}`,
				method: 'DELETE',
			}),
		}),

		updateAbkStatus: builder.mutation<
			SuccessResponse<AbkUpdateStatus>,
			{ abkId: string; body: AbkUpdateStatus }
		>({
			query: ({ abkId, body }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abkId}/status`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Abk', 'AbkStatus'],
		}),

		//Amendments
		getAmendments: builder.query<SuccessResponse<Amendments[]>, string>({
			query: (abk_id) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/amendments`,
			}),
			providesTags: ['Abk'],
		}),

		//Other Info
		getOtherInfo: builder.query<SuccessResponse<AbkOtherInfo>, string>({
			query: (abk_id) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/other-info`,
			}),
			providesTags: ['AbkOtherInfo'],
		}),

		updateOtherInfo: builder.mutation<
			SuccessResponse<AbkOtherInfo>,
			{ abkId: string; body: AbkOtherInfo }
		>({
			query: ({ abkId, body }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abkId}/other-info`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['AbkOtherInfo'],
		}),

		//Transactions
		getAbkTransactions: builder.query<
			SuccessResponse<FullAbkTransactionTable>,
			string
		>({
			query: (abk_id) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/transactions`,
			}),
			providesTags: ['AbkTransactions'],
		}),

		addAbkTransactions: builder.mutation<
			SuccessResponse<AbkTransactions>,
			{ abkId: string; body: AbkTransactions }
		>({
			query: ({ abkId, body }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abkId}/transactions`,
				method: 'POST',
				body,
			}),
		}),

		getAAbkTransaction: builder.query<SuccessResponse<AbkTransactions>, string>(
			{
				query: (transactionId) => ({
					url: `${SERVICE_PATH}/admin/abk-transactions/${transactionId}`,
				}),
				providesTags: ['AbkTransactions'],
			}
		),

		updateTransaction: builder.mutation<
			SuccessResponse<AbkTransactions>,
			{ transactionId: string; body: AbkTransactions }
		>({
			query: ({ transactionId, body }) => ({
				url: `${SERVICE_PATH}/admin/abk-transactions/${transactionId}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['AbkTransactions'],
		}),

		getOtherInfoLogging: builder.query<
			SuccessResponse<TransactionLogs[]>,
			{ abkId: string; logFrom: string; logTo: string }
		>({
			query: ({ abkId, logFrom, logTo }) =>
				`${SERVICE_PATH}/admin/abks/${abkId}/other-info/logs?from=${logFrom}&to=${logTo}`,
		}),

		getTransactionLogging: builder.query<
			SuccessResponse<TransactionLogs[]>,
			{ abkId: string; logFrom: string; logTo: string; forCustomer: boolean }
		>({
			query: ({ abkId, logFrom, logTo, forCustomer }) =>
				`${SERVICE_PATH}/admin/abks/${abkId}/transactions/logs?from=${logFrom}&to=${logTo}&forCustomer=${forCustomer}`,
		}),

		//Email
		getCustomerEmails: builder.query<
			SuccessResponse<AbkCustomerEmailItem[]>,
			string
		>({
			query: (abk_id) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/customer/emails`,
			}),
			providesTags: ['AbkCusEmail', 'AbkStatus'],
		}),

		getSupplierEmails: builder.query<
			SuccessResponse<AbkSupplierEmailItem[]>,
			string
		>({
			query: (abk_id) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/supplier/emails`,
			}),
			providesTags: ['AbkSupEmail', 'AbkStatus'],
		}),

		getContactPoints: builder.query<
			SuccessResponse<AbkContactPoints[]>,
			string
		>({
			query: (abk_id) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/contact-points`,
			}),
			providesTags: ['AbkSupEmail', 'AbkStatus'],
		}),

		getPreviewCustomerEmail: builder.query<
			SuccessResponse<string>,
			{ abk_id: string; email: number }
		>({
			query: ({ abk_id, email }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/customer/emails/preview?email=${email}`,
			}),
		}),

		getPreviewSupplierEmail: builder.query<
			SuccessResponse<string>,
			{ abk_id: string; email: number; contactPointId: string }
		>({
			query: ({ abk_id, email, contactPointId }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/supplier/emails/preview?email=${email}&contactPointId=${contactPointId}`,
			}),
		}),

		getSendCustomerEmail: builder.mutation<
			SuccessResponse<null>,
			{ abk_id: string; email: number }
		>({
			query: ({ abk_id, email }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/customer/emails/send?email=${email}`,
				method: 'GET',
			}),
			invalidatesTags: ['AbkCusEmail'],
		}),

		getSendSupplierEmail: builder.mutation<
			SuccessResponse<null>,
			{ abk_id: string; email: number; contactPointId: string }
		>({
			query: ({ abk_id, email, contactPointId }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/supplier/emails/send?email=${email}&contactPointId=${contactPointId}`,
				method: 'GET',
			}),
			invalidatesTags: ['AbkSupEmail'],
		}),

		//PDF
		getCustomerPDFs: builder.query<
			SuccessResponse<AbkCustomerEmailItem[]>,
			{ abk_id: string; version: number }
		>({
			query: ({ abk_id, version }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/customer/pdfs?version=${version}`,
			}),
			providesTags: ['AbkCusPDF', 'AbkStatus'],
		}),

		getSupplierPDFs: builder.query<
			SuccessResponse<AbkSupplierEmailItem[]>,
			{ abk_id: string; version: number }
		>({
			query: ({ abk_id, version }) => ({
				url: `${SERVICE_PATH}/admin/abks/${abk_id}/supplier/pdfs?version=${version}`,
			}),
			providesTags: ['AbkSupPDF', 'AbkStatus'],
		}),

		getExportCustomerPDF: builder.mutation<
			Blob,
			{ abk_id: string; version: number; email: number }
		>({
			query({ abk_id, version, email }) {
				return {
					url: `${SERVICE_PATH}/admin/abks/${abk_id}/customer/pdfs/export?version=${version}&email=${email}`,
					method: 'GET',
					responseHandler: async (response) => {
						const contentDisposition = response.headers.get(
							'Content-Disposition'
						);
						const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
						if (contentDisposition) {
							const matches = fileNameRegex.exec(contentDisposition);
							if (matches != null && matches[1]) {
								const fileName = decodeURIComponent(
									JSON.parse('"' + matches[1].replace(/['"]/g, '') + '"')
								);
								fileDownload(await response.blob(), fileName);
							}
						}
					},
					cache: 'no-cache',
				};
			},
			invalidatesTags: ['AbkCusPDF'],
		}),

		getExportSupplierPDF: builder.mutation<
			Blob,
			{ abk_id: string; version: number; email: number; contactPointId: string }
		>({
			query({ abk_id, version, email, contactPointId }) {
				return {
					url: `${SERVICE_PATH}/admin/abks/${abk_id}/supplier/pdfs/export?version=${version}&email=${email}&contactPointId=${contactPointId}`,
					method: 'GET',
					responseHandler: async (response) => {
						const contentDisposition = response.headers.get(
							'Content-Disposition'
						);
						const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
						if (contentDisposition) {
							const matches = fileNameRegex.exec(contentDisposition);
							if (matches != null && matches[1]) {
								const fileName = decodeURIComponent(
									JSON.parse('"' + matches[1].replace(/['"]/g, '') + '"')
								);
								fileDownload(await response.blob(), fileName);
							}
						}
					},
					cache: 'no-cache',
				};
			},
			invalidatesTags: ['AbkSupPDF'],
		}),
	}),
});

export const {
	useAddAbkMutation,
	useGetAbkQuery,
	useGetFilteredAbkQuery,
	useUpdateAbkMutation,
	useDeleteAbkMutation,
	useUpdateAbkStatusMutation,
	useGetAmendmentsQuery,
	useLazyGetAmendmentsQuery,
	useGetOtherInfoQuery,
	useUpdateOtherInfoMutation,
	useLazyGetAbkTransactionsQuery,
	useAddAbkTransactionsMutation,
	useGetAAbkTransactionQuery,
	useUpdateTransactionMutation,
	useGetOtherInfoLoggingQuery,
	useGetTransactionLoggingQuery,
	useLazyGetCustomerEmailsQuery,
	useLazyGetSupplierEmailsQuery,
	useLazyGetContactPointsQuery,
	useGetPreviewCustomerEmailQuery,
	useGetPreviewSupplierEmailQuery,
	useGetSendCustomerEmailMutation,
	useGetSendSupplierEmailMutation,
	useLazyGetCustomerPDFsQuery,
	useLazyGetSupplierPDFsQuery,
	useGetExportCustomerPDFMutation,
	useGetExportSupplierPDFMutation,
} = AbkApiSlice;

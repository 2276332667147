import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	ButtonBase,
	Divider,
	Stack,
	Typography,
	styled,
	Grid,
} from '@mui/material';
import { ReactComponent as FilterIcon } from '../../../assets/icon/filter.svg';
import { ReactComponent as InvertedTriangle } from '../../../assets/icon/inverted-triangle.svg';
import { useFormContext } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useTranslation } from 'react-i18next';
import { BlogPostFilter } from '../../../api/DTO/blogPost.interface';
import AppFilterSectionLayout from '../../../common/components/form-layout/AppFilterSectionLayout';
import AppMultipleDropDownMenu from '../../../common/components/form-inputs/AppMultipleDropDownMenu';
import AppSummarySearchBar from '../../../common/components/form-inputs/AppSummarySearchBar';
import AppInputLabel from '../../../common/components/form-inputs/archive/AppInputLabel';
import AppHorizontalCheckboxGroup from '../../../common/components/form-inputs/AppHorizontalCheckboxGroup';
import { blogPostStatusOptions } from '../../../api/enum/blogPostStatus.enum';
import {
	useGetBlogPostCategoriesQuery,
	useGetBlogPostAuthorsQuery,
} from '../../../api/contentApiSlice';
import {
	AppFilterChip,
	FilterChipLayout,
} from '../../../common/components/chip/AppFilterChip';
import dayjs from 'dayjs';
import AppISO8601DatePicker from '../../../common/components/form-inputs/AppISO8601DatePicker';
import { DISPLAY_DATE_FORMAT } from '../../../utils/dateHelper';
import { useEffect } from 'react';

interface BlogPostSummaryFilterSectionProps {
	isInitializedForm: boolean;
	transformedSearchParams: BlogPostFilter;
}

export default function BlogPostSummaryFilterSection({
	isInitializedForm,
	transformedSearchParams,
}: BlogPostSummaryFilterSectionProps) {
	const { t } = useTranslation();

	const { control, watch, resetField, setValue } =
		useFormContext<BlogPostFilter>();

	const resetFilterRHFState = () => {
		resetField('categoryIds');
		resetField('userIds');
		resetField('createdAtFrom');
		resetField('createdAtTo');
		resetField('statuses');
	};

	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetFilterRHFState();
	};

	const { data: getBlogPostCategoriesResponse } =
		useGetBlogPostCategoriesQuery();
	const { data: getBlogPostAuthorsResponse } = useGetBlogPostAuthorsQuery();

	const watchCategoryId = watch('categoryIds');
	const watchUserId = watch('userIds');
	const watchCreatedAtFrom = watch('createdAtFrom');
	const watchCreatedAtTo = watch('createdAtTo');
	const watchStatuses = watch('statuses');

	const clearCategory = () => {
		resetField('categoryIds');
	};
	const clearAuthor = () => {
		resetField('userIds');
	};
	const clearCreateDate = () => {
		resetField('createdAtFrom');
		resetField('createdAtTo');
	};

	const clearStatues = () => {
		resetField('statuses');
	};

	useEffect(() => {
		if (watchCreatedAtFrom && dayjs(watchCreatedAtFrom).isValid()) {
			if (isInitializedForm) {
				setValue(
					'createdAtFrom',
					dayjs(watchCreatedAtFrom).startOf('date').toISOString()
				);
			}
		}
	}, [watchCreatedAtFrom]);

	useEffect(() => {
		if (watchCreatedAtTo && dayjs(watchCreatedAtTo).isValid()) {
			if (isInitializedForm) {
				setValue(
					'createdAtTo',
					dayjs(watchCreatedAtTo).endOf('date').toISOString()
				);
			}
		}
	}, [watchCreatedAtTo]);

	const isCategoryDirty = watchCategoryId !== undefined;
	const isAuthorDirty = watchUserId !== undefined;
	const isCreatedAtDirty = watchCreatedAtTo || watchCreatedAtFrom;
	const isStatusesDirty = watchStatuses !== undefined;

	const isFilterDirty =
		isCategoryDirty || isAuthorDirty || isCreatedAtDirty || isStatusesDirty;

	return (
		<Stack gap={'1rem'}>
			<AppSummarySearchBar
				name='searchKey'
				control={control}
				placeholder={t('blogPost.SearchBarPlaceholder')}
			/>
			<Accordion disableGutters>
				<AccordionSummary
					sx={{
						padding: '0 1.25rem 0 1rem',
						'.expanded': {
							minHeight: 'auto',
							margin: '0',
						},
					}}
					expandIcon={<InvertedTriangle />}
				>
					<Box display={'flex'} width={'100%'}>
						<Stack
							sx={{ marginRight: '1rem', flexShrink: 0 }}
							direction={'row'}
							alignItems={'center'}
						>
							<StyledFilterIcon />
							<FilterHeaderText variant='bold'>
								{t('common.filter')}
							</FilterHeaderText>
						</Stack>
						<Box sx={{ width: '100%' }}>
							<FilterChipLayout>
								{transformedSearchParams.categoryIds &&
									transformedSearchParams.categoryIds.length > 0 && (
										<AppFilterChip
											label='Category'
											value={
												getBlogPostCategoriesResponse
													? getBlogPostCategoriesResponse?.data
															.filter((option) =>
																transformedSearchParams.categoryIds?.includes(
																	option.id
																)
															)
															.map((option) => option.name)
															.join(', ')
													: ''
											}
										/>
									)}
								{transformedSearchParams.userIds &&
									transformedSearchParams.userIds.length > 0 && (
										<AppFilterChip
											label='Author'
											value={
												getBlogPostAuthorsResponse
													? getBlogPostAuthorsResponse?.data
															.filter((option) =>
																transformedSearchParams.userIds?.includes(
																	option.id
																)
															)
															.map((option) => option.name)
															.join(', ')
													: ''
											}
										/>
									)}
								{(transformedSearchParams.createdAtFrom ||
									transformedSearchParams.createdAtTo) && (
									<AppFilterChip
										label='Created Date'
										value={`From ${
											transformedSearchParams.createdAtFrom
												? dayjs(transformedSearchParams.createdAtFrom).format(
														DISPLAY_DATE_FORMAT
												  )
												: '-'
										} to ${
											transformedSearchParams.createdAtTo
												? dayjs(transformedSearchParams.createdAtTo).format(
														DISPLAY_DATE_FORMAT
												  )
												: '-'
										}`}
									/>
								)}
								{transformedSearchParams.statuses &&
									transformedSearchParams.statuses.length > 0 && (
										<AppFilterChip
											label='Status'
											value={blogPostStatusOptions
												.filter((option) =>
													transformedSearchParams.statuses?.includes(option.id)
												)
												.map((option) => option.name)
												.join(', ')}
										/>
									)}
							</FilterChipLayout>
						</Box>
						<ClearAllButton
							sx={{ flexShrink: 0 }}
							disabled={!isFilterDirty}
							onClick={clearAllFilter}
						>
							<Typography variant='regular'>{t('common.clearAll')}</Typography>
						</ClearAllButton>
					</Box>
				</AccordionSummary>
				<AccordionDetails
					sx={{
						padding: '0',
						borderTopWidth: '1px',
						borderTopStyle: 'solid',
						borderTopColor: 'common.whisper',
						display: 'flex',
					}}
				>
					<Stack direction={'row'} flexBasis='100%'>
						<Grid container>
							<Grid item xs={3} sx={{ position: 'relative' }}>
								<AppFilterSectionLayout
									label={t('blogPost.Category')}
									clearDisabled={!isCategoryDirty}
									clearOnClick={clearCategory}
								>
									<Stack direction={'column'} gap={'0.75rem'}>
										<AppMultipleDropDownMenu
											control={control}
											name='categoryIds'
											options={getBlogPostCategoriesResponse?.data ?? []}
										/>
									</Stack>
								</AppFilterSectionLayout>
								<FullHeightDivider
									orientation={'vertical'}
									flexItem={true}
									sx={{
										margin: '0',
										position: 'absolute',
										right: '0',
										top: '0',
										height: '100%',
									}}
								/>
							</Grid>
							<Grid item xs={3} sx={{ position: 'relative' }}>
								<AppFilterSectionLayout
									label={t('blogPost.Author')}
									clearDisabled={!isAuthorDirty}
									clearOnClick={clearAuthor}
								>
									<Stack direction={'column'} gap={'0.75rem'}>
										<AppMultipleDropDownMenu
											control={control}
											name='userIds'
											options={getBlogPostAuthorsResponse?.data ?? []}
										/>
									</Stack>
								</AppFilterSectionLayout>
								<FullHeightDivider
									orientation={'vertical'}
									flexItem={true}
									sx={{
										margin: '0',
										position: 'absolute',
										right: '0',
										top: '0',
										height: '100%',
									}}
								/>
							</Grid>
							<Grid item xs={3} sx={{ position: 'relative' }}>
								<AppFilterSectionLayout
									label={t('blogPost.CreatedDate')}
									clearDisabled={!isCreatedAtDirty}
									clearOnClick={clearCreateDate}
								>
									<AppInputLabel label={t('common.From')} />
									<AppISO8601DatePicker
										control={control}
										name={'createdAtFrom'}
										placeholder={t('blogPost.StartingDate')}
									/>
									<AppInputLabel label={t('common.To')} />
									<AppISO8601DatePicker
										control={control}
										name={'createdAtTo'}
										placeholder={t('blogPost.EndingDate')}
										minimumDate={watchCreatedAtFrom}
									/>
								</AppFilterSectionLayout>
								<FullHeightDivider
									orientation={'vertical'}
									flexItem={true}
									sx={{
										margin: '0',
										position: 'absolute',
										right: '0',
										top: '0',
										height: '100%',
									}}
								/>
							</Grid>

							<Grid item xs={3} sx={{ position: 'relative' }}>
								<AppFilterSectionLayout
									label={t('blogPost.BlogPostStatus')}
									clearDisabled={!isStatusesDirty}
									clearOnClick={clearStatues}
								>
									<AppHorizontalCheckboxGroup
										name='statuses'
										control={control}
										options={blogPostStatusOptions}
										undefinedIfEmptyArray
									/>
								</AppFilterSectionLayout>
							</Grid>
						</Grid>
					</Stack>
				</AccordionDetails>
			</Accordion>
			<DevTool control={control} />
		</Stack>
	);
}

const StyledFilterIcon = styled(FilterIcon)`
	width: 20px;
	height: 16px;
	path {
		fill: #2c2c2c;
		stroke: #2c2c2c;
	}
`;

const ClearAllButton = styled(ButtonBase)(({ theme }) => ({
	fontSize: '0.75rem',
	lineHeight: '0.9375rem',
	marginRight: '1.5rem',
	color: theme.palette.primary.main,
	textDecoration: 'underline',
	'&.Mui-disabled': {
		display: 'none',
	},
}));

const FilterHeaderText = styled(Typography)({
	marginLeft: '0.75rem',
});

const FullHeightDivider = styled(Divider)({
	margin: '-1rem 0 -1.5rem 0',
});

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Box, Chip, Typography } from '@mui/material';
import { Tag } from '../../../api/DTO/place.interface';
import { ReactComponent as AddIcon } from '../../../assets/icon/button-add.svg';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import AddFacilityTagDialog from './AddFacilityTagDialog';
import { useEffect } from 'react';

interface FacilityTagProps {
	tagCatId: string;
	nameEn: string;
	tags: Tag[];
}

export default function FacilityTags<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	tagCatId,
	nameEn,
	tags,
	...props
}: UseControllerProps<TFieldValues, TName> & FacilityTagProps) {
	const { t } = useTranslation();
	const { field } = useController(props);

	const selectedTags: Tag[] = field.value ?? [];
	const onChange = field.onChange;
	const [allTag, setAllTag] = useState<Tag[]>([]);

	const selectedTagsId: string[] = [];

	selectedTags.map((tag) => {
		selectedTagsId.push(tag.id);
	});

	useEffect(() => {
		setAllTag(tags);
		if (!field.value) {
			onChange([]);
		}
	});

	const handleAdd = (tag: Tag) => {
		const arr: Tag[] = selectedTags ?? [];
		const found = arr.some((el) => el.id === tag.id);
		if (!found) {
			arr.push(tag);
			onChange(arr);
		} else {
			onChange(arr.filter((item) => item.id !== tag.id));
		}
	};

	// add tag dialog

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (addedTag?: Tag) => {
		setOpen(false);
		if (addedTag) {
			handleAdd(addedTag);
		}
	};

	const handleClick = (addedTag?: Tag) => () => {
		if (addedTag) {
			handleAdd(addedTag);
		}
	};

	return (
		<>
			<Box
				sx={{
					paddingBottom: '20px',
				}}
			>
				{allTag.map((option: Tag) => (
					<Chip
						key={option.id}
						label={option.name}
						onClick={handleClick(option)}
						variant={selectedTagsId.includes(option.id) ? 'filled' : 'outlined'}
						color='primary'
						sx={{
							height: '28px',
							fontSize: '12px',
							marginRight: '6px',
							marginBottom: '6px',
						}}
						clickable
						{...field}
					/>
				))}

				<Chip
					label={
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<AddIcon />
							<Typography marginLeft='4px' fontSize='12px'>
								{t('button.Add')}
							</Typography>
						</Box>
					}
					variant='outlined'
					color='primary'
					sx={{
						height: '28px',
						background: 'white',
						marginRight: '6px',
						marginBottom: '6px',
					}}
					onClick={handleClickOpen}
				/>
			</Box>
			<AddFacilityTagDialog
				open={open}
				onClose={handleClose}
				tagCatId={tagCatId}
				nameEnTitle={nameEn}
			/>
		</>
	);
}

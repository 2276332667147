import { Button, Divider, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import AppTextField from '../../../../common/components/form-inputs/AppTextField';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import { FormCardType } from '../../../../common/components/card/FormCard';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
	useGetBlogPostCategoriesQuery,
	useGetBlogPostQuery,
	useUpdateBlogPostMutation,
} from '../../../../api/contentApiSlice';
import { BlogPost } from '../../../../api/DTO/blogPost.interface';
import { BlogIdContext } from '../ViewBlogPostPage';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import AppBlogPostEditor from '../../../../common/components/editor/AppBlogPostEditor';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../../utils/dateHelper';

interface BlogPostFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function BlogPostForm({ type, setType }: BlogPostFormProps) {
	const { t } = useTranslation();
	const blogId = useContext(BlogIdContext);

	const formSchema = Yup.object().shape({
		title: Yup.string().required(t('errorMessage.required')),
	});

	const { data: getBlogPostResponse, isLoading: isGetBlogPostLoading } =
		useGetBlogPostQuery(blogId, {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control, watch } =
		useFormContext() ??
		useForm<BlogPost>({
			defaultValues: getBlogPostResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const { data: getBlogPostCategoriesResponse } =
		useGetBlogPostCategoriesQuery();

	const [updateBlogPost, { isSuccess: isUpdateBlogPostSuccess }] =
		useUpdateBlogPostMutation();

	useEffect(() => {
		if (isUpdateBlogPostSuccess) setType(FormCardType.view);
	}, [isUpdateBlogPostSuccess]);

	const onSubmit: SubmitHandler<any> = (data) => {
		updateBlogPost({
			blogId: blogId ?? '',
			body: data,
		});
	};

	const formContent = (
		<Stack>
			{type === FormCardType.edit && (
				<>
					<AppInputLayout label={t('blogPost.BlogPostId')}>
						<NonEditableDisplayText text={getBlogPostResponse?.data.id} />
					</AppInputLayout>

					<Divider sx={{ marginBottom: '15px' }} />
				</>
			)}

			<AppTextField
				control={control}
				name='title'
				label={t('blogPost.Title')}
				required
			/>
			<AppDropDownMenu
				control={control}
				name='category'
				label={t('blogPost.Category')}
				options={getBlogPostCategoriesResponse?.data ?? []}
				passObjectValue
				required
			/>

			{type === FormCardType.edit && (
				<>
					<AppInputLayout label={t('blogPost.CreatedDate')}>
						<NonEditableDisplayText
							text={dayjs(getBlogPostResponse?.data.createdAt).format(
								DISPLAY_DATE_TIME_FORMAT
							)}
						/>
					</AppInputLayout>

					<AppInputLayout label={t('blogPost.Author')}>
						<NonEditableDisplayText
							text={getBlogPostResponse?.data.createdBy}
						/>
					</AppInputLayout>
				</>
			)}
			<Divider sx={{ marginBottom: '15px' }} />
			<AppTextField
				control={control}
				name='description'
				label={t('blogPost.ShortDescription')}
			/>

			<AppInputLayout label={t('blogPost.Content')} labelAlignItemsFlexStart>
				<AppBlogPostEditor control={control} name='content' />
			</AppInputLayout>

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button
								type='submit'
								variant='contained'
								color='success'
								onClick={handleSubmit(onSubmit)}
							>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? <form>{formContent}</form> : formContent;

	return <>{!isGetBlogPostLoading && form}</>;
}

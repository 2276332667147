import Option from "../../common/types/option.type";

export enum PlaceType {
    ATTRACTION = 0,
	RESTAURANT = 1
}

export const placeTypeOption: Option[] = [
    {
        id: PlaceType.ATTRACTION,
        name: 'Attraction'
    },
    {
        id: PlaceType.RESTAURANT,
        name: 'Restaurant'
    }
]
import {
	Paper,
	styled,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import { ReactComponent as StatusDot } from '../../assets/icon/statusDot.svg';

interface AppStatusToggleProps {
	enableI18nLabel: string;
	disableI18nLabel: string;
	initialValue: boolean;
	label?: string;
	statusOnChange: (
		event: React.MouseEvent<HTMLElement>,
		activated: boolean | null
	) => void;
}

export default function AppStatusToggle({
	enableI18nLabel,
	disableI18nLabel,
	initialValue,
	label,
	statusOnChange,
}: AppStatusToggleProps) {
	return (
		<>
			<Typography
				sx={{ fontSize: '16px', color: '#808080', paddingRight: '7px' }}
			>
				{label}
			</Typography>

			<StyledPaper elevation={0}>
				<StyledToggleButtonGroup
					exclusive={true}
					value={initialValue}
					onChange={statusOnChange}
				>
					<ToggleButton value={true}>
						<StatusDot />
						<Typography
							display={'inline-block'}
							variant='bold'
							fontSize={14}
							marginLeft='4px'
						>
							{enableI18nLabel}
						</Typography>
					</ToggleButton>
					<ToggleButton value={false}>
						<StatusDot />
						<Typography display={'inline-block'} fontSize={14} marginLeft='4px'>
							{disableI18nLabel}
						</Typography>
					</ToggleButton>
				</StyledToggleButtonGroup>
			</StyledPaper>
		</>
	);
}

const StyledPaper = styled(Paper)({
	width: 'fit-content',
	backgroundColor: '#E8EBED',
	borderRadius: '5px',
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
	'& .MuiToggleButtonGroup-grouped': {
		margin: '4px',
		border: 0,
		textTransform: 'none',
		padding: '3px 12px 3px 12px',
		color: '#BCBCBC',
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: '#FFFFFF',
		},
		': hover': {
			backgroundColor: 'transparent',
		},

		'&.Mui-disabled': {
			border: 0,
		},
		'&:not(:first-of-type)': {
			borderRadius: '3px',
			'&.Mui-selected': {
				color: '#808080',
			},
		},
		'&:first-of-type': {
			borderRadius: '3px',
			'&.Mui-selected': {
				color: '#4B9831',
			},
		},
	},
});

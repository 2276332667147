import { Box, ButtonBase, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface AppStatusDisplayProps {
	name: string;
	color: string;
	bgcolor: string;
}

const AppStatusDisplay = ({ name, color, bgcolor }: AppStatusDisplayProps) => {
	const { t } = useTranslation();

	return (
		<Box>
			<StyledStatusButton color={color} bgcolor={bgcolor}>
				<Typography
					sx={{ fontSize: '0.75rem', width: '100%' }}
					component={'span'}
					variant='bold'
				>
					{name}
				</Typography>
			</StyledStatusButton>
		</Box>
	);
};

const StyledStatusButton = styled(ButtonBase, {
	shouldForwardProp: (prop) => prop !== 'isActivated',
})<{ color: string; bgcolor: string }>(({ color, bgcolor }) => ({
	padding: '0.5rem 0.6rem',
	borderRadius: '5px',
	minWidth: '60px',
	color: color,
	backgroundColor: bgcolor,
	justifyContent: 'space-between',
}));

export default AppStatusDisplay;

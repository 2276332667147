import { FieldValues, FieldPath, UseControllerProps } from 'react-hook-form';
import { FormControl, Typography } from '@mui/material';
import Option from '../../types/option.type';
import FacilityIcon from '../form-data-display/FacilityIcon';
import { StyledToggleButtonGroup } from '../form-inputs/AppToggleButtonGroup';
import { StyledToggleButton } from '../form-inputs/AppToggleButtonGroup';
import { StyledButtonContent } from '../form-inputs/AppToggleButtonGroup';

interface AppToggleButtonGroupProps {
	formLabel?: string;
	disabled?: boolean;
	options: Option[];
	defaultValue?: string | number;
}

export default function ToggleButtonDisplay<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	formLabel,
	disabled,
	options,
	defaultValue,
}: AppToggleButtonGroupProps & UseControllerProps<TFieldValues, TName>) {
	return (
		<FormControl sx={{ display: 'flex', flexDirection: 'column' }}>
			<Typography paddingBottom={1} fontSize={'14px'}>
				{formLabel}
			</Typography>
			<StyledToggleButtonGroup
				color='primary'
				exclusive
				value={defaultValue}
				aria-label={formLabel}
				disabled={disabled}
			>
				{options.map((option) => (
					<StyledToggleButton
						key={option.id}
						value={option.id}
						selectedbackgroundcolor={option.selectedbackgroundcolor}
						selectedcolor={option.selectedcolor}
					>
						<StyledButtonContent>
							<FacilityIcon icon={option.icon} height={'20px'} width={'20px'} />
							{option.name}
						</StyledButtonContent>
					</StyledToggleButton>
				))}
			</StyledToggleButtonGroup>
		</FormControl>
	);
}

import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormCardType } from '../../../../common/components/card/FormCard';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import { Dispatch, SetStateAction, useRef } from 'react';
import ToggleButtonDisplay from '../../../../common/components/form-data-display/ToggleButtonDisplay';
import TitleColorDisplay from '../../../../common/components/form-data-display/TitleColorDisplay';
import { facilityOption } from '../../../../api/enum/facility.enum';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import {
	useGetRfsubcategoriesQuery,
	useGetRoomFacilitiesQuery,
	useGetRoomSummaryQuery,
} from '../../../../api/accommodationApiSlice';
import {
	RFSubcategoryWithFacilities,
	RFacility,
} from '../../../../api/DTO/facility.interface';
import AppAlert from '../../../../common/components/AppAlert';

export default function RoomFacilityView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { propertyId, rfcategoryId } = useParams();
	const { t } = useTranslation();

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getRfsubcategoriesResponse } = useGetRfsubcategoriesQuery(
		rfcategoryId ?? ''
	);

	const { data: getRoomFacilitiesResponse } = useGetRoomFacilitiesQuery({
		propertyId: propertyId ?? '',
		rfcategoryId: rfcategoryId ?? '',
	});

	const { data: getRoomResponse } = useGetRoomSummaryQuery({
		propertyId: propertyId ?? '',
	});
	const roomFacilityRefs = useRef<HTMLDivElement[]>([]);

	return (
		<Stack position={'relative'}>
			{ability.can('create', 'Property') && (
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>
			)}
			<Box
				position={'absolute'}
				sx={{
					top: 0,
					left: 0,
				}}
			>
				<AppAlert alertColor='#32A5F1' alertBar={false}>
					{t('facility.TagStyleMessage')}
				</AppAlert>
			</Box>
			<Box maxWidth={'100%'} marginTop={'20px'}>
				{getRfsubcategoriesResponse?.data &&
					getRfsubcategoriesResponse?.data.map(
						(
							subcategory: RFSubcategoryWithFacilities,
							subcategoryIndex: number
						) => {
							return (
								<Grid
									container
									padding='0px'
									position='relative'
									key={subcategory.id}
								>
									<Grid item minWidth={'17rem'}>
										<Box display={'flex'} alignItems={'center'} height={'72px'}>
											<Box minWidth={'17rem'}>
												<Typography fontSize={14} fontWeight={700}>
													{subcategory.nameEn}
												</Typography>
											</Box>
										</Box>
										{subcategory.facilities?.map((facility: RFacility) => {
											return (
												<Box
													key={facility.id}
													display={'flex'}
													alignItems={'center'}
													height={'63px'}
												>
													<Box minWidth={'17rem'} maxWidth={'270px'}>
														<Typography
															key={facility.id}
															fontSize={14}
															color={
																facility.highlighted ? '#32A5F1' : '#808080'
															}
														>
															{facility.nameEn}
														</Typography>
													</Box>
												</Box>
											);
										})}
									</Grid>
									<Grid
										item
										xs
										sx={{ overflowX: 'auto' }}
										ref={(el: HTMLDivElement) =>
											(roomFacilityRefs.current[subcategoryIndex] = el)
										}
										onScroll={() => {
											roomFacilityRefs.current.forEach((el, i) => {
												if (i !== subcategoryIndex && el) {
													el.scrollLeft =
														roomFacilityRefs.current[
															subcategoryIndex
														].scrollLeft;
												}
											});
										}}
									>
										<Box
											display={'flex'}
											alignItems={'center'}
											minHeight={'72px'}
										>
											{subcategoryIndex == 0 &&
												getRoomResponse?.data.map((room, index) => {
													return (
														<TitleColorDisplay
															key={room.id}
															name={getRoomResponse?.data[index].nameEn}
														/>
													);
												})}
										</Box>
										{subcategory.facilities?.map((facility: RFacility) => {
											const value = facility.id ?? '';
											return (
												<Box
													key={facility.id}
													display={'flex'}
													alignItems={'center'}
												>
													{getRoomResponse?.data.map((room) => {
														const name = room.id + '.' + facility.id;
														return (
															<Box
																key={room.id}
																minWidth={'17rem'}
																marginLeft={'1rem'}
																marginRight={'1rem'}
															>
																<Box key={facility.id}>
																	<ToggleButtonDisplay
																		name={name ?? ''}
																		options={facilityOption}
																		defaultValue={
																			getRoomFacilitiesResponse?.data[room.id][
																				value
																			] ?? ''
																		}
																		disabled
																	/>
																</Box>
															</Box>
														);
													})}
												</Box>
											);
										})}
									</Grid>
									<Grid item xs={12}>
										{subcategoryIndex <
											getRfsubcategoriesResponse?.data.length - 1 && (
											<Divider sx={{ marginTop: '20px' }} />
										)}
									</Grid>
								</Grid>
							);
						}
					)}
			</Box>
		</Stack>
	);
}

import { Button, Grid, Stack } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ShortNotice } from '../../../api/DTO/page.interface';
import { shortNoticeStatusOptions } from '../../../api/enum/shortNotice.enum';
import {
	useGetShortNoticeQuery,
	useUpdateShortNoticeMutation,
} from '../../../api/pageApiSlice';
import { FormCardType } from '../../../common/components/card/FormCard';
import AppFormStatusToggle from '../../../common/components/form-inputs/AppFormStatusToggle';
import AppTextField from '../../../common/components/form-inputs/AppTextField';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';

interface ShortNoticeFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function ShortNoticeForm({
	type,
	setType,
}: ShortNoticeFormProps) {
	const { t } = useTranslation();

	const formSchema = Yup.object().shape({
		status: Yup.number().required(t('errorMessage.pleaseSelect')),
		content: Yup.string().required(t('errorMessage.required')),
	});

	const { data: getShortNoticeResponse, isLoading: isGetShortNoticeLoading } =
		useGetShortNoticeQuery();

	const { handleSubmit, control } = useForm<ShortNotice>({
		defaultValues: getShortNoticeResponse?.data,
		resolver: yupResolver(formSchema),
	});

	const [updateShortNotice, { isSuccess: isUpdateShortNoticeSuccess }] =
		useUpdateShortNoticeMutation();

	useEffect(() => {
		if (isUpdateShortNoticeSuccess) setType(FormCardType.view);
	}, [isUpdateShortNoticeSuccess]);

	const onSubmit: SubmitHandler<any> = (data) => {
		updateShortNotice(data);
	};

	const formContent = (
		<Stack>
			<Grid container columnSpacing={5}>
				<Grid item xs={6}>
					<AppInputLayout label={t('page.Status')}>
						<AppFormStatusToggle
							name='status'
							options={shortNoticeStatusOptions}
							control={control}
							formLabel={''}
						/>
					</AppInputLayout>
					<AppTextField
						control={control}
						name='content'
						label={t('page.ContentInChi')}
						required
					/>
				</Grid>
			</Grid>

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form = <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;

	return <>{!isGetShortNoticeLoading && form}</>;
}

import { Box, Grid, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import MealSupplementListCard from './components/MealSupplementListCard';

export default function MealSupplementContainer() {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Grid container spacing={'20px'}>
				<Grid item style={{ width: '252px' }}>
					<Stack width={'100%'} spacing={'15px'}>
						<MealSupplementListCard />
					</Stack>
				</Grid>
				<Grid item xs>
					<Stack spacing={'20px'}>
						<Outlet />
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
}

import { MenuItem, Select, styled } from '@mui/material';

export const StyledSelect = styled(Select)(({ theme }) => ({
	display: 'flex',
	fontSize: 12,
	height: '30px',

	'label + &': {
		marginTop: '28px',
	},

	'& fieldset': {
		borderColor: '#BCBCBC !important',
		borderWidth: '1px',
		borderRadius: '5px',
	},

	':hover ': {
		borderWidth: '1px',
		borderColor: '#BCBCBC !important',
	},

	'&.Mui-focused fieldset': {
		borderWidth: '1px !important',
		borderColor: '#4EA4D3 !important',
	},

	'&.Mui-error fieldset': {
		borderWidth: '1px !important',
		borderColor: '#DA5150 !important',
	},
	'&.Mui-disabled fieldset': {
		borderColor: '#F5F5F5 !important',
	},
	'&.Mui-disabled': {
		background: '#F5F5F5',
	},
	'.Mui-disabled': {
		WebkitTextFillColor: '#808080',
	},
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	fontSize: '12px',
	padding: '12px',
	
}));

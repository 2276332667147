import { Box, Typography } from '@mui/material';
import CountryFlag from './CountryFlag';

interface FlagLabelDisplayProps {
	iso2?: string;
	label?: string;
}

export default function FlagLabelDisplay({
	iso2,
	label,
}: FlagLabelDisplayProps) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<>
				{iso2 && (
					<CountryFlag
						iso2={iso2}
						size='small'
						style={{ marginRight: '12px' }}
					/>
				)}
				<Typography fontSize='12px'>{label}</Typography>
			</>
		</Box>
	);
}

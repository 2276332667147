import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import PromotionForm from './PromotionForm';
import PromotionView from './PromotionView';

interface PromotionCardProps {
	initialType: FormCardType;
}

export default function PromotionCard({ initialType }: PromotionCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<FormCard type={type} title={t('promotion.Promotion')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <PromotionView setType={setType} />;
					case FormCardType.create:
						return <PromotionForm type={type} setType={setType} />;
					case FormCardType.edit:
						return <PromotionForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

import { Button, Divider, InputAdornment, Grid, Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import AppTextField from '../../../../common/components/form-inputs/AppTextField';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';

import { ReactComponent as LinkIcon } from '../../../../assets/icon/link.svg';
import { currencies } from '../../../../common/constants/currencies';

import { FormCardType } from '../../../../common/components/card/FormCard';

import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import AppRadioGroup from '../../../../common/components/form-inputs/AppRadioGroup';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import { supplierPaymentMethodOption } from '../../../../api/enum/supplierPaymentMethod.enum';
import { transactionTypeOption } from '../../../../api/enum/transactionType.enum';
import { billingCycleOption } from '../../../../api/enum/BillingCycle.enum';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SupplierIdContext } from '../ViewSupplierPage';
import { Supplier } from '../../../../api/DTO/supplier.interface';
import { 
	useGetSupplierQuery,
	useUpdateSupplierBaseInfoMutation
} from '../../../../api/supplierApiSlice';


interface BaseInfoFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function BaseInfoForm({ type, setType }: BaseInfoFormProps) {
	const { t } = useTranslation();
	const supplierId = useContext(SupplierIdContext);

	const formSchema = Yup.object().shape({
		baseInfo: Yup.object()
			.shape({
				name: Yup.string().required(t('errorMessage.required')),
				nameEn: Yup.string().required(t('errorMessage.required')),
				currency: Yup.string().required(t('errorMessage.pleaseSelect')),
				paymentMethod: Yup.string().required(t('errorMessage.pleaseSelect')),
				transactionType: Yup.string().required(t('errorMessage.pleaseSelect')),
				billingCycle: Yup.string().required(t('errorMessage.pleaseSelect')),
			})
			.required(),
	});

	const { data: getSupplierResponse, isLoading: isGetSupplierLoading } =
		useGetSupplierQuery(supplierId, {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control } =
		useFormContext() ??
		useForm<Supplier>({
			defaultValues: getSupplierResponse?.data,
			resolver: yupResolver(formSchema),
	});


	const [
		updateSupplierBaseInfo,
		{ isSuccess: isUpdateSupplierBaseInfoSuccess },
	] = useUpdateSupplierBaseInfoMutation();

	useEffect(() => {
		if (isUpdateSupplierBaseInfoSuccess) setType(FormCardType.view);
	}, [isUpdateSupplierBaseInfoSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateSupplierBaseInfo({
			supplierId: supplierId ?? '',
			body: data.baseInfo,
		});
	};


	const formContent = (
		<Stack>
			{type === FormCardType.edit && (
				<>
					<AppInputLayout label={t('supplier.SupplierID')}>
						<NonEditableDisplayText text={getSupplierResponse?.data.baseInfo.id} />
					</AppInputLayout>

					<Divider sx={{ marginBottom: '15px' }} />
				</>
			)}

			<AppSubtitleLayout label={t('supplier.CompanyName')} />

			<AppTextField
				control={control}
				name='baseInfo.nameEn'
				label={t('common.English')}
				placeholder={t('placeholder.pleaseEnterEnglish')}
				required
			/>
			<AppTextField
				control={control}
				name='baseInfo.name'
				label={t('common.TraditionalChinese')}
				placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
				required
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppTextField
				control={control}
				name='baseInfo.brNumber'
				label={t('supplier.BRNumber')}
			/>

			<AppTextField
				control={control}
				name='baseInfo.website'
				label={t('supplier.CompanyWebsite')}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<LinkIcon />
						</InputAdornment>
					),
				}}
			/>
			<AppDropDownMenu
				control={control}
				name='baseInfo.currency'
				label={t('supplier.Currency')}
				options={currencies}
				required
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppRadioGroup
				control={control}
				name='baseInfo.paymentMethod'
				label={t('supplier.PaymentMethod')}
				options={supplierPaymentMethodOption}
				required
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppRadioGroup
				control={control}
				name='baseInfo.transactionType'
				label={t('supplier.TransactionType')}
				options={transactionTypeOption}
				required
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppRadioGroup
				control={control}
				name='baseInfo.billingCycle'
				label={t('supplier.BillingCycle')}
				options={billingCycleOption}
				required
			/>

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success' >
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);
		
	return <>{!isGetSupplierLoading && form}</>;
}

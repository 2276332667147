import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export enum ModalType {
	ACTIVATE_USER = 'ACTIVATE_USER',
	DEACTIVATE_USER = 'DEACTIVATE_USER',
	API_ERROR = 'API_ERROR',
	DELETE_USER = 'DELETE_USER',
	DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
	ACTIVATE_BLOG = 'ACTIVATE_BLOG',
	DEACTIVATE_BLOG = 'DEACTIVATE_BLOG',
	DELETE_GALLERY_IMAGE = 'DELETE_GALLERY_IMAGE',
	DELETE_GALLERY_VIDEO = 'DELETE_GALLERY_VIDEO',
	DELETE_ABK_ENQUIRY = 'DELETE_ABK_ENQUIRY',
	DELETE_ABK_BOOKING = 'DELETE_ABK_BOOKING',
	SEND_ABK_CUSTOMER_EMAIL = 'SEND_ABK_CUSTOMER_EMAIL',
	SEND_ABK_SUPPLIER_EMAIL = 'SEND_ABK_SUPPLIER_EMAIL',
	DELETE_SECTION = 'DELETE_SECTION',
	DELETE_BK_BOOKING = 'DELETE_BK_BOOKING',
}

interface ModalState {
	modalType: keyof typeof ModalType | null;
	modalProps: { [key: string]: any };
}

const initialState: ModalState = {
	modalType: null,
	modalProps: {},
};

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		showModal: (state, action: PayloadAction<ModalState>) => {
			const { modalType, modalProps } = action.payload;
			return { ...state, modalType, modalProps };
		},
		hideModal: () => {
			return initialState;
		},
	},
});

export const { showModal, hideModal } = modalSlice.actions;

export const selectModalState = (state: RootState) => state.modal;

export default modalSlice;

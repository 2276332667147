import { TextFieldProps } from '@mui/material';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import AppInputLayout from '../form-layout/AppInputLayout';
import { StyledTextField } from '../styled/StyledTextField';
import { ReactNode } from 'react';

export enum InputType {
	normal,
	number,
	amount, // amount of money
	english,
	lastName,
}

interface AppTextFieldProps {
	label?: string;
	infoPopUp?: ReactNode;
	inputType?: InputType;
	align?: string;
	removeErrorPadding?: boolean;
}

export default function AppTextField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	infoPopUp,
	inputType,
	required,
	disabled,
	placeholder,
	InputProps,
	multiline,
	rows,
	inputProps,
	align,
	removeErrorPadding,
	autoFocus,
	...props
}: AppTextFieldProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;
	const amountRegex = /^\d+(\.\d{0,2})?$/;
	const englishRegex = /^[a-zA-Z\s]*$/;

	return (
		<AppInputLayout
			label={label}
			infoPopUp={infoPopUp}
			required={required}
			errorMessage={errorMessage}
			labelAlignItemsFlexStart={multiline}
			removeErrorPadding={removeErrorPadding}
		>
			<StyledTextField
				{...field}
				value={field.value ?? ''}
				onChange={(e) => {
					if (inputType === InputType.number) {
						// extract number from string
						field.onChange(e.target.value.replace(/[^0-9]/g, ''));
					} else if (inputType === InputType.amount) {
						if (e.target.value === '' || amountRegex.test(e.target.value)) {
							field.onChange(e.target.value);
						}
					} else if (inputType === InputType.english) {
						if (e.target.value === '' || englishRegex.test(e.target.value)) {
							field.onChange(e.target.value);
						}
					} else if (inputType === InputType.lastName) {
						if (e.target.value === '' || englishRegex.test(e.target.value)) {
							field.onChange(e.target.value.toUpperCase());
						}
					} else field.onChange(e.target.value);
				}}
				inputProps={inputProps}
				multiline={multiline}
				rows={rows}
				disabled={disabled}
				placeholder={placeholder}
				InputProps={InputProps}
				align={align}
				error={!!errorMessage}
				autoFocus={autoFocus}
			/>
		</AppInputLayout>
	);
}

import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import BaseInfoForm from './BaseInfoForm';
import BaseInfoView from './BaseInfoView';

interface BaseInfoCardProps {
	initialType: FormCardType;
}

export default function BaseInfoCard({ initialType }: BaseInfoCardProps) {
	const { t } = useTranslation();

	return (
		<FormCard type={initialType} title={t('abk.BookingInformation')}>
			{(() => {
				switch (initialType) {
					case FormCardType.view:
						return <BaseInfoView />;
					case FormCardType.create:
						return <BaseInfoForm type={initialType} />;
					case FormCardType.edit:
						return <BaseInfoForm type={initialType} />;
					case FormCardType.specialEdit:
						return <BaseInfoForm type={initialType} />;
				}
			})()}
		</FormCard>
	);
}

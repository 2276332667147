import {
	AppBar,
	Box,
	Drawer,
	IconButton,
	Stack,
	Tab,
	Tabs,
	Toolbar,
	Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import AppDatePicker from '../../../common/components/form-inputs/AppDatePicker';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { RateModel } from '../../../api/enum/rateModel.enum';
import RoomToSell from './components/bulk-edit-room/RoomToSell';
import RoomRate from './components/bulk-edit-room/RoomRate';
import RatePlanStatus from './components/bulk-edit-rate-plan/RatePlanStatus';
import MarkupAdjustment from './components/bulk-edit-rate-plan/MarkupAdjustment';
import SellingRate from './components/bulk-edit-rate-plan/SellingRate';
import AdjustmentAllotment from './components/bulk-edit-rate-plan/AdjustmentAllotment';
import AdjustmentOnRequestAllotment from './components/bulk-edit-rate-plan/AdjustmentOnRequestAllotment';
import RatePlanSupplementRate from './components/bulk-edit-rate-plan/RatePlanSupplementRate';
import * as Yup from 'yup';
import { NONE_INVALID_DATE_PATTERN } from '../../../common/constants/pattern';
import { yupResolver } from '@hookform/resolvers/yup';
import AppGridMultipleCheckbox from '../../../common/components/form-inputs/AppGridMultipleCheckbox';
import dayjs from 'dayjs';
import { daysOfWeekOptions } from '../../../api/enum/daysOfWeek';
import AdultRate from './components/bulk-edit-rate-plan/AdultRate';
import { PriceMode } from '../../../api/enum/priceMode.enum';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

enum TabType {
	ROOM,
	RATE_PLAN,
}

export interface BulkEditDrawerProps {
	rateModel: RateModel;
	priceMode?: PriceMode;
	sellAtOfficialRate?: boolean;
	open: boolean;
	onClose: () => void;
}

export default function BulkEditDrawer({
	rateModel,
	priceMode,
	sellAtOfficialRate,
	open,
	onClose,
}: BulkEditDrawerProps) {
	const { t } = useTranslation();

	const [tabValue, setTabValue] = useState<TabType>(TabType.ROOM);
	const handleChange = (_event: SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	useEffect(() => {
		method.setValue('rateModel', rateModel);
	}, [rateModel]);

	const defaultValues: DefaultValues<any> = {
		days: daysOfWeekOptions.map((option) => option.id),
	};

	const formSchema = Yup.object().shape({
		days: Yup.array().min(1, t('errorMessage.atLeastOneCheckbox')),
		from: Yup.string()
			.required(t('errorMessage.required'))
			.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
			.test(
				'shouldGreaterThanToday',
				() => t('errorMessage.incorrectPeriodTime'),
				(value) =>
					dayjs(value).isSameOrAfter(dayjs(), 'day') &&
					dayjs(value).isSameOrBefore(dayjs().add(365, 'day'), 'day')
			),
		to: Yup.string()
			.required(t('errorMessage.required'))
			.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
			.test(
				'shouldGreaterThanToday',
				() => t('errorMessage.incorrectPeriodTime'),
				(value) => dayjs(value).isSameOrBefore(dayjs().add(365, 'day'), 'day')
			)
			.when('from', (from: any, schema) => {
				return schema.test(
					'shouldSameOrGreaterThanFrom',
					() => t('errorMessage.incorrectPeriodTime'),
					(value) => {
						return !!from && dayjs(value).isSameOrAfter(dayjs(from), 'day');
					}
				);
			}),
	});

	const method = useForm({
		defaultValues: defaultValues,
		reValidateMode: 'onChange',
		shouldUnregister: false,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		if (dayjs(method.watch('from')).diff(dayjs(method.watch('to'))) > 0) {
			method.setValue('to', '');
		}
	}, [method.watch('from')]);

	return (
		<Drawer anchor={'right'} open={open} onClose={onClose}>
			<FormProvider {...method}>
				<Box sx={{ width: 400, flexGrow: 1 }} role='presentation'>
					<AppBar
						sx={{ backgroundColor: '#E8EBED', width: 400 }}
						position='sticky'
						elevation={0}
					>
						<Toolbar>
							<Typography
								color={'black'}
								sx={{ flexGrow: 1 }}
								fontWeight={'bold'}
								fontSize={'16px'}
							>
								{t('rateAndInventory.BulkEdit')}
							</Typography>
							<IconButton aria-label='close' onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<Stack paddingX={'28px'} direction={'column'}>
						<Stack paddingTop={'20px'}>
							<Typography
								color={'gray'}
								sx={{ flexGrow: 1 }}
								fontWeight={'bold'}
								fontSize={'14px'}
							>
								{t('rateAndInventory.SetDate')}
							</Typography>
						</Stack>
						<Stack paddingTop={'5px'}>
							<AppDatePicker
								name={'from'}
								control={method.control}
								placeholder={t('common.From')}
								minimumDate={dayjs().startOf('day').format()}
								maximumDate={dayjs().add(365, 'day').endOf('day').format()}
							/>
							<AppDatePicker
								name={'to'}
								control={method.control}
								placeholder={t('common.To')}
								minimumDate={method.watch('from')}
								maximumDate={dayjs().add(365, 'day').endOf('day').format()}
							/>
						</Stack>

						<Stack>
							<Typography
								color={'gray'}
								sx={{ flexGrow: 1 }}
								fontWeight={'bold'}
								fontSize={'14px'}
							>
								{t('rateAndInventory.SetDay')}
							</Typography>
						</Stack>
						<AppGridMultipleCheckbox
							label={''}
							options={daysOfWeekOptions}
							name={'days'}
							control={method.control}
							columns={4}
						/>
					</Stack>
					<Box
						sx={{ borderBottom: 1, borderColor: 'divider' }}
						fontSize={'14px'}
					>
						<Tabs value={tabValue} onChange={handleChange}>
							<Tab
								label={t('rateAndInventory.Room')}
								sx={{ width: '50%', textTransform: 'none' }}
							/>
							<Tab
								label={t('rateAndInventory.RatePlan')}
								sx={{ width: '50%', textTransform: 'none' }}
							/>
						</Tabs>
					</Box>

					<Box hidden={tabValue !== TabType.ROOM}>
						<RoomToSell />
						{priceMode === PriceMode.PER_ROOM && <RoomRate />}
					</Box>

					<Box hidden={tabValue !== TabType.RATE_PLAN}>
						<RatePlanStatus />
						{priceMode === PriceMode.PER_PERSON && <AdultRate />}

						{rateModel === RateModel.AGENCY && (
							<>
								<MarkupAdjustment sellAtOfficialRate={sellAtOfficialRate} />
								{priceMode === PriceMode.PER_ROOM && !sellAtOfficialRate && (
									<SellingRate />
								)}
							</>
						)}
						<AdjustmentAllotment />
						<AdjustmentOnRequestAllotment />
						{priceMode === PriceMode.PER_ROOM && <RatePlanSupplementRate />}
					</Box>
				</Box>
			</FormProvider>
		</Drawer>
	);
}

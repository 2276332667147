import { Button, Divider, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import AppInputLayoutSingleCheckbox from '../../../../common/components/form-inputs/AppInputLayoutSingleCheckBox';
import { FormCardType } from '../../../../common/components/card/FormCard';
import { placeAgeGroupOption } from '../../../../api/enum/placeAgeGroup.enum';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AppMultipleCheckbox from '../../../../common/components/form-inputs/AppMultipleCheckbox';
import { PlaceIdContext } from '../ViewPlacePage';
import {
	useGetPlaceQuery,
	useUpdatePlaceAdmissionDetailsMutation,
} from '../../../../api/contentApiSlice';
import { Place } from '../../../../api/DTO/place.interface';

interface AdmissionDetailsFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
	placeType: number;
}

export default function AdmissionDetailsForm({
	type,
	setType,
	placeType,
}: AdmissionDetailsFormProps) {
	const { t } = useTranslation();

	const placeId = useContext(PlaceIdContext);

	const formSchema = Yup.object().shape({});

	const { data: getPlaceResponse, isLoading: isGetPlaceLoading } =
		useGetPlaceQuery(placeId, {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control } =
		useFormContext() ??
		useForm<Place>({
			mode: 'all',
			defaultValues: getPlaceResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const [
		updatePlaceAdmissionDetails,
		{ isSuccess: isUpdatePlaceAdmissionDetailsSuccess },
	] = useUpdatePlaceAdmissionDetailsMutation();

	useEffect(() => {
		if (isUpdatePlaceAdmissionDetailsSuccess) setType(FormCardType.view);
	}, [isUpdatePlaceAdmissionDetailsSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updatePlaceAdmissionDetails({
			placeId: placeId ?? '',
			body: data.admissionDetails,
		});
	};

	const formContent = (
		<Stack>
			{placeType == 0 && (
				<>
					<AppMultipleCheckbox
						control={control}
						name='admissionDetails.ageGroups'
						label={t('place.AgeGroup')}
						options={placeAgeGroupOption}
					/>
					<Divider sx={{ marginBottom: '15px' }} />
					<AppInputLayoutSingleCheckbox
						control={control}
						name='admissionDetails.ticketRequired'
						label={t('place.EntranceTicketRequired')}
					/>
				</>
			)}

			<AppInputLayoutSingleCheckbox
				control={control}
				name='admissionDetails.resInAdvance'
				label={t('place.ReservationInAdvance')}
				infoPopUp={' '}
			/>

			<AppInputLayoutSingleCheckbox
				control={control}
				name='admissionDetails.csButton'
				label={t('place.ActivateCustomerService')}
				infoPopUp={' '}
			/>

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetPlaceLoading && form}</>;
}

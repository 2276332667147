import {
	useUploadPropertyImagesMutation,
	useDeletePropertyImageMutation,
	useRepositionPropertyImagesMutation,
	useSelectPropertyImageMutation,
} from '../../../../api/accommodationApiSlice';
import {
	UploadImages,
	Reposition,
	Image,
} from '../../../../api/DTO/gallery.interface';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { arrayMove } from '@dnd-kit/sortable';

import DndImageListSection from './DndImageListSection';
import {
	showModal,
	hideModal,
} from '../../../../common/components/modal/modalSlice';
import { useAppDispatch } from '../../../../redux-hooks';

interface PropertyImageListSectionProps {
	image: Image[];
	categoryId?: string;
	section: number;
}

export default function PropertyImageListSection({
	image,
	categoryId,
	section,
}: PropertyImageListSectionProps) {
	const { propertyId } = useParams();

	const [
		uploadPropertyImagesMutation,
		{ data: uploadPropertyImagesMutationResponse, isSuccess: isUploadSuccess },
	] = useUploadPropertyImagesMutation();

	const [deletePropertyImageMutation] = useDeletePropertyImageMutation();

	const [repositionPropertyImagesMutation] =
		useRepositionPropertyImagesMutation();

	const [
		selectPropertyImageMutation,
		{ data: selectPropertyImageMutationResponse, isSuccess: isSelectSuccess },
	] = useSelectPropertyImageMutation();

	const [imageArray, setImageArray] = useState<Image[]>(image);

	useEffect(() => {
		if (isUploadSuccess) {
			setImageArray(uploadPropertyImagesMutationResponse?.data.images ?? image);
		}
	}, [isUploadSuccess]);

	useEffect(() => {
		if (isSelectSuccess) {
			const updatedArray = imageArray.map((image) => {
				if (image.id === selectPropertyImageMutationResponse?.data.id) {
					return selectPropertyImageMutationResponse?.data;
				}
				return image;
			});
			setImageArray(updatedArray);
		}
	}, [isSelectSuccess]);

	const dispatch = useAppDispatch();

	function handleDeleteClick(id: string, index: number) {
		deletePropertyImageMutation(id).then((res: any) => {
			if (res.data.success == true) {
				const list = [...imageArray];
				list.splice(index, 1);
				setImageArray(list);
				dispatch(hideModal());
			}
		});
	}

	function handleDeleteImage(id: string, index: number) {
		dispatch(
			showModal({
				modalType: 'DELETE_GALLERY_IMAGE',
				modalProps: {
					id,
					index,
					handleDeleteClick,
				},
			})
		);
	}

	function handleSelectImage(id: string) {
		selectPropertyImageMutation(id);
	}

	function onUploadFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		const target = event.target;
		const file = target.files?.[0];

		if (file) {
			const imageBody: UploadImages = {
				files: file,
				section: section,
				categoryId: categoryId ?? undefined,
			};
			uploadPropertyImagesMutation({
				propertyId: propertyId ?? '',
				body: imageBody,
			});
		}
	}

	const handleRepositionImage = (
		activeIndex: number,
		overIndex: number,
		activeItem: Image
	) => {
		const position: Reposition = {
			newPosition: overIndex,
		};
		repositionPropertyImagesMutation({
			property_image_id: activeItem.id,
			body: position,
		}).then((res: any) => {
			if (res.data.success == true) {
				setImageArray((prev) => arrayMove<Image>(prev, activeIndex, overIndex));
			}
		});
	};

	return (
		<>
			<DndImageListSection
				imageArray={imageArray}
				handleDeleteImage={handleDeleteImage}
				onUploadFileChange={onUploadFileChange}
				handleRepositionImage={handleRepositionImage}
				handleSelectImage={handleSelectImage}
			/>
		</>
	);
}

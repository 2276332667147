import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TanglePeek } from '../../api/DTO/propertyImport.interface';
import { FormCardType } from '../../common/components/card/FormCard';
import SearchPropertyCard from './property-import/import/SearchPropertyCard';
import SearchResultCard from './property-import/import/SearchResultCard';

export default function ImportPropertyPage() {
	const { state } = useLocation();

	const [propertySummarySearchParams, setPropertySummarySearchParams] =
		useState<string | undefined>(undefined);

	useEffect(() => {
		if (state?.propertySummarySearchParams) {
			setPropertySummarySearchParams(state?.propertySummarySearchParams);
		}
	}, []);

	const [searchResult, setSearchResult] = useState<TanglePeek | undefined>(
		undefined
	);

	const backPath = propertySummarySearchParams
		? `/property?${propertySummarySearchParams}`
		: '/property';

	return (
		<>
			<Stack spacing='16px'>
				<Grid container alignItems='stretch' spacing={'10px'}>
					<Grid item xs={12} display='flex'>
						<SearchPropertyCard
							initialType={FormCardType.create}
							setSearchResult={setSearchResult}
							searchResult={searchResult}
						/>
					</Grid>
					{searchResult && (
						<Grid item xs={12} display='flex'>
							<SearchResultCard
								initialType={FormCardType.create}
								searchResult={searchResult}
							/>
						</Grid>
					)}
				</Grid>
			</Stack>
		</>
	);
}

import styled from '@emotion/styled';
import { Box, Stack, Typography } from '@mui/material';
import { getDatesInRange, getMonthsInRange } from '../../../../utils/helper';
import { API_DATE_FORMAT } from '../../../../utils/dateHelper';
import { translateDaysOfWeekToJapanese } from '../../../../api/enum/daysOfWeek';

interface HorizontalCalendarProps {
	from?: string;
	to?: string;
}

export default function HorizontalCalendar({
	from,
	to,
}: HorizontalCalendarProps) {
	return (
		<>
			{getMonthsInRange(from, to).map((month, index) => (
				<div key={index}>
					<YearAndMonthContainer>
						<YearAndMonth>
							{month[0].format('MMM').toUpperCase() +
								' ' +
								month[0].format('YYYY')}
						</YearAndMonth>
					</YearAndMonthContainer>
					<div key={index} style={{ display: 'flex' }}>
						{month.map((date, index) => (
							<Date key={date.format(API_DATE_FORMAT)}>
								<DayOfTheWeek
									marginTop={'6px'}
									color={
										date.format('dddd') === 'Sunday' ? '#DA5150' : '#2C2C2C'
									}
								>
									{`${date.format('dddd')} ${translateDaysOfWeekToJapanese(
										date.format('dddd')
									)}`}
								</DayOfTheWeek>
								<Day
									marginTop={'4px'}
									color={
										date.format('dddd') === 'Sunday' ? '#DA5150' : '#2C2C2C'
									}
								>
									{date.format('DD')}
								</Day>
							</Date>
						))}
					</div>
				</div>
			))}
		</>
	);
}

export const DatesContainer = styled(Box)({
	display: 'flex',
	backgroundColor: '#FFF',
	borderRadius: '5px',
	boxShadow: '0px 4px 3px 0px rgba(0, 0, 0, 0.05)',
	overflow: 'auto',
});

const YearAndMonthContainer = styled('div')({
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '74px',
	width: '74px',
	marginTop: '3px',
	height: '10px',
	position: 'sticky',
	left: 0,
});

const YearAndMonth = styled(Typography)({
	fontSize: '10px',
	lineHeight: '10px',
	fontWeight: 700,
	color: '#4EA4D3',
	textAlign: 'center',
});

const Date = styled(Stack)({
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '74px',
	width: '74px',
	height: '36px',
	marginBottom: '3px',
});

const DayOfTheWeek = styled(Typography)({
	fontSize: '10px',
	lineHeight: '10px',
});

const Day = styled(Typography)({
	fontSize: '16px',
	lineHeight: '16px',
	fontWeight: 700,
});

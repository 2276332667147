import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { FormCardType } from '../../../../../common/components/card/FormCard';
import { Box, Divider, Stack } from '@mui/material';
import UnderlinedIconButton from '../../../../../common/components/buttons/UnderlinedIconButton';
import { ReactComponent as EditIcon } from '../../../../../assets/icon/edit.svg';
import { useLazyGetRatePlanQuery } from '../../../../../api/accommodationApiSlice';
import { useTranslation } from 'react-i18next';
import { RatePlanIdContext } from '../RatePlanDetailPage';
import AppInputLayout from '../../../../../common/components/form-layout/AppInputLayout';
import EditorDisplay from '../../../../../common/components/editor/EditorDisplay';
import YesNoDisplay from '../../../../../common/components/form-data-display/YesNoDisplay';
import NewDisplayText from '../../../../../common/components/form-data-display/NewDisplayText';

export default function RatePlanInclusionView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const ratePlanId = useContext(RatePlanIdContext);
	const [getRatePlan, { data: getRatePlanResponse }] =
		useLazyGetRatePlanQuery();

	useEffect(() => {
		getRatePlan(ratePlanId);
	}, [getRatePlanResponse]);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				{/* dummuy ui to avoid covering edit button */}
				<AppInputLayout label=' '></AppInputLayout>
				<AppInputLayout label=' '></AppInputLayout>
				<AppInputLayout
					label={t('room.ratePlan.Thumbnail')}
					labelAlignItemsFlexStart={true}
				>
					{getRatePlanResponse?.data.inclusion.thumbnail && (
						<Box>
							<img
								style={{
									width: '100%',
									maxWidth: '400px',
									borderRadius: '10px',
								}}
								src={getRatePlanResponse?.data.inclusion.thumbnail.url}
							/>
						</Box>
					)}
				</AppInputLayout>

				<AppInputLayout
					label={t('room.ratePlan.SupplierInclusion')}
					labelAlignItemsFlexStart={true}
				>
					<EditorDisplay
						editorStateString={
							getRatePlanResponse?.data.inclusion.supplierInclusion
						}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout
					label={t('room.ratePlan.EnablemyFamiGoInclusion')}
					labelAlignItemsFlexStart={true}
				>
					<YesNoDisplay
						value={getRatePlanResponse?.data.inclusion.hasInclusion}
					/>
				</AppInputLayout>

				{getRatePlanResponse?.data.inclusion.hasInclusion && (
					<>
						<AppInputLayout
							label={t('room.ratePlan.MyFamiGoInclusionTitleInChi')}
						>
							<NewDisplayText
								text={getRatePlanResponse?.data.inclusion.inclusionTitle}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('room.ratePlan.MyFamiGoInclusionDetailsInChi')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getRatePlanResponse?.data.inclusion.inclusion
								}
							/>
						</AppInputLayout>
					</>
				)}
			</Stack>
		</>
	);
}

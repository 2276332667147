import { Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../api/userApiSlice';
import defineAbilityFor from '../../casl/defineAbility';

import PageHeader from '../../common/components/PageHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAddSupplierMutation } from '../../api/supplierApiSlice';
import { useEffect, useState } from 'react';

import { FormCardType } from '../../common/components/card/FormCard';

import BaseInfoCard from './overview/baseInfo/BaseInfoCard';
import BankDetailsCard from './overview/bankDetail/BankDetailCard';
import ContactPointCard from './overview/contactPoint/ContactPointCard';
import DocumentCard from './overview/document/DocumentCard';
import { Supplier } from '../../api/DTO/supplier.interface';

export default function CreateSupplierPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();

	const [supplierSummarySearchParams, setSupplierSummarySearchParams] =
		useState<string | undefined>(undefined);

	useEffect(() => {
		if (state?.supplierSummarySearchParams) {
			setSupplierSummarySearchParams(state?.supplierSummarySearchParams);
		}
	}, []);

	const backPath = supplierSummarySearchParams
		? `/supplier?${supplierSummarySearchParams}`
		: '/supplier';

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const defaultValues: DefaultValues<Supplier> = {
		baseInfo: {
			name: '',
			nameEn: '',
			brNumber: '',
			website: '',
			currency: 'HKD',
			paymentMethod: undefined,
			transactionType: undefined,
			billingCycle: undefined,
		},
		bankDetail: {
			recipientName: '',
			recipientAddress: '',
			name: '',
			code: '',
			accountNumber: '',
			swiftCode: '',
			address: '',
			remarks: '',
		},
		contactPoints: [
			{
				department: undefined,
				name: '',
				phoneNum: '',
				email: '',
				selected: false,
			},
		],
		documents: {
			0: [],
			1: [],
		},
	};

	const formSchema = Yup.object().shape({
		baseInfo: Yup.object()
			.shape({
				name: Yup.string().required(t('errorMessage.required')),
				nameEn: Yup.string().required(t('errorMessage.required')),
				currency: Yup.string().required(t('errorMessage.pleaseSelect')),
				paymentMethod: Yup.string().required(t('errorMessage.pleaseSelect')),
				transactionType: Yup.string().required(t('errorMessage.pleaseSelect')),
				billingCycle: Yup.string().required(t('errorMessage.pleaseSelect')),
			})
			.required(),
		contactPoints: Yup.array().of(
			Yup.object()
				.shape({
					department: Yup.string().required(t('errorMessage.pleaseSelect')),
					name: Yup.string().required(t('errorMessage.required')),
					email: Yup.string().required(t('errorMessage.required')),
				})
				.required()
		),
	});

	const [
		addSupplier,
		{ data: AddSupplierResponse, isSuccess: isAddSupplierSuccess },
	] = useAddSupplierMutation();

	const methods = useForm<Supplier>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const onSubmit: SubmitHandler<Supplier> = (data) => {
		addSupplier(data);
	};

	useEffect(() => {
		if (isAddSupplierSuccess) {
			navigate(`/supplier/${AddSupplierResponse?.data.baseInfo.id}`);
		}
	}, [isAddSupplierSuccess]);

	return (
		<>
			<PageHeader
				showBackButton={true}
				backPath={backPath}
				title={t('supplier.CreateSupplier')}
			/>
			{ability.can('create', 'Property') && (
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Stack spacing='16px'>
							<Grid container alignItems='strech' spacing={'10px'}>
								<Grid item xs={6} display='flex'>
									<BaseInfoCard initialType={FormCardType.create} />
								</Grid>
								<Grid item xs={6} display='flex'>
									<BankDetailsCard initialType={FormCardType.create} />
								</Grid>
								<Grid item xs={6} display='flex'>
									<ContactPointCard initialType={FormCardType.create} />
								</Grid>
								<Grid item xs={6} display='flex'>
									<DocumentCard initialType={FormCardType.create} />
								</Grid>
							</Grid>

							<Stack spacing='16px'>
								<Grid item xs={12}>
									<Stack direction='row-reverse' spacing='12px'>
										<Button type='submit' variant='contained' color='success'>
											{t('button.Save')}
										</Button>
										<Button
											variant='outlined'
											color='info'
											onClick={() => navigate(backPath)}
										>
											{t('button.Discard')}
										</Button>
									</Stack>
								</Grid>
							</Stack>
						</Stack>
					</form>
				</FormProvider>
			)}
		</>
	);
}

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Box, Stack, TextFieldProps, Typography } from '@mui/material';
import { SupplierDocument } from '../../../api/DTO/supplier.interface';
import UploadedFileWithDescription from './UploadedFileWithDescription';
import { useTranslation } from 'react-i18next';
import AppAddButton from '../../../common/components/form-inputs/AppAddButton';
import { ReactComponent as AddIcon } from '../../../assets/icon/button-add.svg';
import { useUploadMediaMutation } from '../../../api/mediaApiSlice';
import { useEffect } from 'react';
import { SupplierDocumentType } from '../../../api/enum/supplierDocumentType.enum';

interface MultipleFilesWithDescriptionFieldProps {
	supplierDocumentType: SupplierDocumentType;
}

export default function MultipleFilesWithDescriptionField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	supplierDocumentType,
	...props
}: UseControllerProps<TFieldValues, TName> &
	TextFieldProps &
	MultipleFilesWithDescriptionFieldProps) {
	const { t } = useTranslation();
	const { field } = useController(props);
	const filesList: SupplierDocument[] = field.value ?? [];
	const onChange = field.onChange;

	const [uploadMedia, { data: uploadMediaResponse, isSuccess }] =
		useUploadMediaMutation();

	useEffect(() => {
		if (isSuccess && uploadMediaResponse?.data[0]) {
			const newFileWithDescription: SupplierDocument = {
				id: uploadMediaResponse?.data[0].id,
				type: supplierDocumentType,
				name: uploadMediaResponse?.data[0].name,
				description: '',
			};
			onChange([...filesList, newFileWithDescription]);
		}
	}, [isSuccess]);

	function onUploadFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		const target = event.target;
		const file = target.files?.[0];

		if (file) uploadMedia(file);
	}

	const handleFileRemove = (index: number) => {
		const list = [...filesList];
		list.splice(index, 1);
		onChange(list);
	};

	const handleFileChange = (value: SupplierDocument, index: number) => {
		const list = [...filesList];
		list[index] = value;
		onChange(list);
	};

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					gap: '20px',
					alignItems: 'center',
					marginBottom: '15px',
				}}
			>
				<Box>
					<Typography width={'160px'} fontSize={'12px'} color='#808080'>
						{t('supplier.FileName')}
					</Typography>
				</Box>
				<Stack sx={{ width: '100%' }}>
					<Typography width={'160px'} fontSize={'12px'} color='#808080'>
						{t('supplier.Description')}
					</Typography>
				</Stack>
			</Box>
			<Stack spacing={'15px'}>
				{filesList.map((supplierDocument: SupplierDocument, index: number) => (
					<UploadedFileWithDescription
						key={index}
						index={index}
						value={supplierDocument}
						onChange={(value: SupplierDocument) =>
							handleFileChange(value, index)
						}
						deleteOnClick={handleFileRemove}
					/>
				))}

				<AppAddButton component='label'>
					<input type='file' name='doc' hidden onChange={onUploadFileChange} />
					<AddIcon />
					{t('button.Add')}
				</AppAddButton>
			</Stack>
		</>
	);
}

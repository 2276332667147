import './styles.css';
import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { useEffect, useState } from 'react';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import Placeholder from './ui/Placeholder';
import { TableContext } from './plugins/TablePlugin';
import ContentEditable from './ui/ContentEditable';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { isJson } from '../../../utils/helper';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

interface AppRichTextEditorProps {
	showCopyButton?: boolean;
	handleCopyButtonClick?: () => void;
	editorStateToBePasted?: string;
}

export default function AppRichTextEditor<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	showCopyButton,
	handleCopyButtonClick,
	editorStateToBePasted,
	...props
}: AppRichTextEditorProps & UseControllerProps<TFieldValues, TName>) {
	// editor state sting from react hook form
	const { field: editorStateStringField } = useController(props);
	const formEditorStateString = editorStateStringField.value;
	const editorStateStringFieldOnChange = editorStateStringField.onChange;

	const placeholder = <Placeholder> </Placeholder>;

	const [floatingAnchorElem, setFloatingAnchorElem] =
		useState<HTMLDivElement | null>(null);

	const onRef = (_floatingAnchorElem: HTMLDivElement) => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	const editorConfig = {
		theme: PlaygroundEditorTheme,
		...(formEditorStateString &&
			(isJson(formEditorStateString)
				? {
						editorState: formEditorStateString,
				  }
				: {
						editorState: null,
				  })),
		onError(error: Error) {
			throw error;
		},
		nodes: [
			HeadingNode,
			ListNode,
			ListItemNode,
			AutoLinkNode,
			LinkNode,
			// HorizontalRuleNode,
		],
		namespace: 'Playground',
	};

	const [pastedEditorState, setPastedEditorState] = useState<
		string | undefined
	>(undefined);

	function PasteEditorStatePlugIn({
		editorStateToBePasted,
	}: {
		editorStateToBePasted?: string;
	}) {
		const [editor] = useLexicalComposerContext();

		useEffect(() => {
			setTimeout(() => {
				if (
					editorStateToBePasted &&
					isJson(editorStateToBePasted) &&
					editorStateToBePasted !== pastedEditorState
				) {
					const editorState = editor.parseEditorState(editorStateToBePasted);
					editor.setEditorState(editorState);
					setPastedEditorState(editorStateToBePasted);
				}
			}, 0);
		}, [editorStateToBePasted]);

		return null;
	}

	return (
		<LexicalComposer initialConfig={editorConfig}>
			<TableContext>
				<div className='editor-shell'>
					{showCopyButton ? (
						<>
							<ToolbarPlugin
								isBlogPostEditor={false}
								addCopyButton
								copyButtonOnClick={() => {
									if (handleCopyButtonClick) {
										handleCopyButtonClick();
									}
								}}
							/>
						</>
					) : (
						<ToolbarPlugin isBlogPostEditor={false} />
					)}
					<div className='editor-container tree-view'>
						<RichTextPlugin
							contentEditable={
								<div className='editor-scroller'>
									<div className='editor' ref={onRef}>
										<ContentEditable />
									</div>
								</div>
							}
							placeholder={placeholder}
							ErrorBoundary={LexicalErrorBoundary}
						/>
						<HistoryPlugin />
						<AutoFocusPlugin />
						<ListPlugin />
						<LinkPlugin />
						<AutoLinkPlugin />
						<ListMaxIndentLevelPlugin maxDepth={7} />
						<FloatingLinkEditorPlugin />
						{/* <HorizontalRulePlugin /> */}
						<AutoEmbedPlugin />
						<OnChangePlugin
							ignoreSelectionChange
							onChange={(editorState) => {
								// update react hook form editor state string
								editorStateStringFieldOnChange(JSON.stringify(editorState));
							}}
						/>
						<PasteEditorStatePlugIn
							editorStateToBePasted={editorStateToBePasted}
						/>
					</div>
				</div>
			</TableContext>
		</LexicalComposer>
	);
}

import { IconButton, InputAdornment, InputBase, styled } from '@mui/material';
import { ReactComponent as IncreaseIcon } from '../../../assets/icon/increase.svg';
import { ReactComponent as DecreaseIcon } from '../../../assets/icon/decrease.svg';
import { useEffect, useState } from 'react';

interface CounterProps {
	value?: number;
	onChange: (value: number | undefined) => void;
	errorBorder?: boolean;
	allowNegative?: boolean;
}

const StyledInputBase = styled(InputBase, {
	shouldForwardProp: (prop) => !(prop === 'errorBorder'),
})<{
	errorBorder?: boolean;
}>(({ errorBorder }) => ({
	width: '100%',
	padding: '5px 9px',
	borderRadius: '5px',
	border: errorBorder ? '1px solid #F6403F' : '1px solid #BCBCBC',
	background: 'white',
}));

export default function Counter({
	value,
	onChange,
	errorBorder,
	allowNegative = false,
}: CounterProps) {
	const regex = allowNegative ? /^-?[0-9\b]+$/ : /^[0-9\b]+$/;

	const [textValue, setTextValue] = useState<string>(
		value ? value.toString() : '0'
	);

	useEffect(() => {
		if (textValue === '' || textValue === '-') onChange(undefined);
		else onChange(Number(textValue));
	}, [textValue]);

	function increment() {
		setTextValue(((value ? value : 0) + 1).toString());
	}

	function decrement() {
		if (allowNegative || (value ?? 0) > 0) {
			setTextValue(((value ?? 0) - 1).toString());
		} else {
			onChange(0);
		}
	}

	return (
		<StyledInputBase
			errorBorder={errorBorder}
			inputProps={{
				style: {
					textAlign: 'center',
					fontSize: '12px',
					padding: '0px',
				},
			}}
			value={textValue}
			onChange={(e) => {
				if (
					e.target.value === '' ||
					(allowNegative && e.target.value === '-') ||
					regex.test(e.target.value)
				) {
					if (regex.test(e.target.value)) {
						setTextValue(Number(e.target.value).toString());
					} else setTextValue(e.target.value);
				}
			}}
			startAdornment={
				<InputAdornment position='start' onClick={decrement}>
					<IconButton disableRipple>
						<DecreaseIcon />
					</IconButton>
				</InputAdornment>
			}
			endAdornment={
				<InputAdornment position='end' onClick={increment}>
					<IconButton disableRipple>
						<IncreaseIcon />
					</IconButton>
				</InputAdornment>
			}
		/>
	);
}

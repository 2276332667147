import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import { FormCardType } from '../../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../../common/components/form-layout/AppInputLayout';
import AppSwitch from '../../../../../common/components/form-inputs/AppSwitch';
import AppMultipleCheckbox from '../../../../../common/components/form-inputs/AppMultipleCheckbox';
import { useTranslation } from 'react-i18next';
import NonEditableDisplayText from '../../../../../common/components/form-data-display/NonEditableDisplayText';
import { Box, Button, Divider, Grid, Stack } from '@mui/material';
import AppSubtitleLayout from '../../../../../common/components/form-layout/AppSubtitleLayout';
import {
	useGetPropertyQuery,
	useGetRatePlanQuery,
	useGetRoomQuery,
	useLazyCheckValidMealPlanSupplementQuery,
	useLazyGetRatePlanNameByMealTypeQuery,
	useUpdateRatePlanBaseInfoMutation,
} from '../../../../../api/accommodationApiSlice';
import { useParams } from 'react-router-dom';
import {
	RatePlanNameType,
	ratePlanTypeOptions,
} from '../../../../../api/enum/ratePlanNameType.enum';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import {
	CheckValidMealSupplement,
	RatePlan,
} from '../../../../../api/DTO/ratePlan.interface';
import AppRadioGroup from '../../../../../common/components/form-inputs/AppRadioGroup';
import AppDropDownMenu from '../../../../../common/components/form-inputs/AppDropDownMenu';
import {
	MealType,
	getMealTypeOptions,
} from '../../../../../api/enum/mealType.enum';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';
import AppDateTimePicker from '../../../../../common/components/form-inputs/AppDateTimePicker';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppDatePicker from '../../../../../common/components/form-inputs/AppDatePicker';
import AppAlert from '../../../../../common/components/AppAlert';
import { RatePlanIdContext } from '../RatePlanDetailPage';
import {
	ApiSupplierType,
	apiSupplierOptions,
} from '../../../../../api/enum/ApiSupplier.enum';
import { rateModelOptions } from '../../../../../api/enum/rateModel.enum';
import {
	RatePlanChannel,
	ratePlanChannelOptions,
} from '../../../../../api/enum/ratePlanChannel.enum';
import { NONE_INVALID_DATE_PATTERN } from '../../../../../common/constants/pattern';
import AppInputLayoutSingleCheckbox from '../../../../../common/components/form-inputs/AppInputLayoutSingleCheckBox';
import { INVALID_DATE } from '../../../../../utils/dateHelper';
import { PriceMode } from '../../../../../api/enum/priceMode.enum';
import { mealRateCalculationOptions } from '../../../../../api/enum/mealRateCalculationOptions';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

interface RatePlanBaseInfoFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function RatePlanBaseInfoForm({
	type,
	setType,
}: RatePlanBaseInfoFormProps) {
	const { t } = useTranslation();
	const { propertyId, roomId } = useParams();
	const ratePlanId = useContext(RatePlanIdContext);

	// console.log(dayjs('2024-02-15').isSameOrAfter(dayjs('2024-02-15')));
	// console.log(dayjs('2024-02-15').isAfter(dayjs('2024-02-15')));

	// page query
	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '', {
		refetchOnMountOrArgChange: true,
	});
	const { data: getRoomResponse } = useGetRoomQuery(roomId ?? '');
	const { data: getRatePlanResponse, isLoading: isGetRatePlanLoading } =
		useGetRatePlanQuery(ratePlanId, {
			skip: type === FormCardType.create,
		});
	const [getRatePlanNameByMealType] = useLazyGetRatePlanNameByMealTypeQuery();
	const [checkValidMealPlan, { data: getCheckValidMealPlan }] =
		useLazyCheckValidMealPlanSupplementQuery();
	const [
		updateRatePlanBaseInfo,
		{ isSuccess: isUpdateRatePlanBaseInfoSuccess },
	] = useUpdateRatePlanBaseInfoMutation();

	const formSchema = Yup.object().shape({
		baseInfo: Yup.object()
			.shape({
				channel: Yup.number().required(t('errorMessage.atLeastOneCheckbox')),
				rateModel: Yup.number().required(t('errorMessage.pleaseSelect')),
				apiSupplier: Yup.number()
					.nullable()
					.when('channel', {
						is: RatePlanChannel.APISUPPLIER,
						then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
					}),
				nameType: Yup.number().required(t('errorMessage.pleaseSelect')),
				name: Yup.string().required(t('errorMessage.required')),
				nameEn: Yup.string().required(t('errorMessage.required')),
				originalName: Yup.string()
					.nullable()
					.when('nameType', {
						is: RatePlanNameType.CUSTOM,
						then: (schema) => schema.required(t('errorMessage.required')),
					}),
				mealType: Yup.string().required(t('errorMessage.pleaseSelect')),
				withMealSupplement: Yup.boolean(),
				bookingPeriod: Yup.object().shape({
					start: Yup.string()
						.required(t('errorMessage.required'))
						.matches(
							NONE_INVALID_DATE_PATTERN,
							t('errorMessage.incorrectTime')
						),
					end: Yup.string()
						.nullable()
						.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
						.when('start', (start: any, schema) => {
							return schema.test(
								'shouldGreaterThanStart',
								() => t('errorMessage.incorrectPeriodTime'),
								(value) =>
									(!!start && dayjs(value).isAfter(dayjs(start))) ||
									value === null
							);
						}),
				}),
				checkInPeriod: Yup.object().shape({
					start: Yup.string()
						.required(t('errorMessage.required'))
						.matches(
							NONE_INVALID_DATE_PATTERN,
							t('errorMessage.incorrectTime')
						),
					end: Yup.string()
						.nullable()
						.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
						.when('start', (start: any, schema) => {
							return schema.test(
								'shouldSameOrGreaterThanStart',
								() => t('errorMessage.incorrectPeriodTime'),
								(value) => {
									return (
										(!!start &&
											dayjs(value).isSameOrAfter(dayjs(start), 'day')) ||
										value === null
									);
								}
							);
						}),
				}),
				stayDay: Yup.object().shape({
					min: Yup.string().required(t('errorMessage.required')),
					max: Yup.string()
						.required(t('errorMessage.required'))
						.when('min', (min, schema) => {
							return schema.test(
								'shouldSameOrGreaterThanMin',
								() => t('errorMessage.inputDayError'),
								(value) => !!min && Number(value) >= Number(min)
							);
						}),
				}),
				cutOffDay: Yup.string().required(t('errorMessage.required')),
			})
			.required(),
	});

	const {
		handleSubmit,
		control,
		watch,
		reset,
		setValue,
		formState: { isSubmitted },
	} = useFormContext() ??
	useForm<RatePlan>({
		defaultValues: getRatePlanResponse?.data,
		resolver: yupResolver(formSchema),
	});

	// prefill logic

	const [isPrefilled, setIsPrefilled] = useState<boolean>(false);

	useEffect(() => {
		setIsPrefilled(false);
		if (type === FormCardType.edit) {
			reset({
				baseInfo: getRatePlanResponse?.data.baseInfo,
			});
		}
		setIsPrefilled(true);
	}, [getRatePlanResponse]);

	// watch

	const watchRatePlanChannel = watch('baseInfo.channel');
	const watchRatePlanRateModel = watch('baseInfo.rateModel');

	const watchRatePlanNameType = watch('baseInfo.nameType');
	const watchRatePlanMealType = watch('baseInfo.mealType');
	const watchRatePlanWithMealSupplement =
		watch('baseInfo.withMealSupplement') == true ||
		watch('baseInfo.withMealSupplement') == 'true'; // withMealSupplement might in string due to AppRadioGroup component

	const watchEnableOptionalMeal = watch('baseInfo.enableOptionalMeal');

	const watchRatePlanBookingPeriodStart = watch('baseInfo.bookingPeriod.start');
	const watchRatePlanCheckInPeriodStart = watch('baseInfo.checkInPeriod.start');
	const watchRatePlanCheckInPeriodEnd = watch('baseInfo.checkInPeriod.end');

	// rate plan channel disable logic

	const [disabledRatePlanChannelOptions, setDisabledRatePlanChannelOptions] =
		useState<RatePlanChannel[]>([]);

	useEffect(() => {
		const noDirectContract =
			!getPropertyResponse?.data?.supplier.directContract;
		const noApiSupplier =
			getRoomResponse?.data.supplier.mappings[ApiSupplierType.RAKUTEN]
				.length === 0 &&
			getRoomResponse?.data.supplier.mappings[ApiSupplierType.MEITUAN]
				.length === 0;
		const tempDisabledRatePlanChannelOptions = [];
		if (noDirectContract) {
			tempDisabledRatePlanChannelOptions.push(RatePlanChannel.DIRECT);
		}
		if (noApiSupplier) {
			tempDisabledRatePlanChannelOptions.push(RatePlanChannel.APISUPPLIER);
		}

		setDisabledRatePlanChannelOptions(tempDisabledRatePlanChannelOptions);
	}, [getPropertyResponse, getRoomResponse]);

	// rate model available options logic

	const propertyRateModelOptions = rateModelOptions.filter((option) =>
		getPropertyResponse?.data?.supplier.rateModels.includes(option.id)
	);

	// clear rate model selected value if needed

	useEffect(() => {
		if (
			isPrefilled &&
			watchRatePlanChannel == RatePlanChannel.DIRECT &&
			!getPropertyResponse?.data?.supplier.rateModels.includes(
				watchRatePlanRateModel
			)
		) {
			setValue('baseInfo.rateModel', null);
		}
	}, [isPrefilled, getPropertyResponse, watchRatePlanChannel]);

	// clear api supplier value if channel is direct

	useEffect(() => {
		if (watchRatePlanChannel == RatePlanChannel.DIRECT) {
			setValue('baseInfo.apiSupplier', null);
		}
	}, [watchRatePlanChannel]);

	// enable supplement logic

	useEffect(() => {
		if (
			getPropertyResponse?.data.supplier.priceMode !== PriceMode.PER_ROOM ||
			watchRatePlanChannel != RatePlanChannel.DIRECT
		) {
			setValue('baseInfo.enableSupplement', false);
		}
	}, [getPropertyResponse, watchRatePlanChannel]);

	// rate plan name logic

	useEffect(() => {
		if (watchRatePlanNameType == RatePlanNameType.CUSTOM) {
			if (isPrefilled) {
				setValue('baseInfo.nameEn', '', {
					shouldValidate: isSubmitted,
				});
				setValue('baseInfo.name', '', {
					shouldValidate: isSubmitted,
				});
				setValue('baseInfo.originalName', '', {
					shouldValidate: isSubmitted,
				});
			}
		} else {
			const fetchRatePlanName = async () => {
				const ratePlanNameResult = await getRatePlanNameByMealType(
					watchRatePlanMealType
				);

				setValue('baseInfo.nameEn', ratePlanNameResult.data?.data.nameEn, {
					shouldValidate: isSubmitted,
				});
				setValue('baseInfo.name', ratePlanNameResult.data?.data.name, {
					shouldValidate: isSubmitted,
				});
				setValue('baseInfo.originalName', null, {
					shouldValidate: isSubmitted,
				});
			};

			fetchRatePlanName();
		}
	}, [watchRatePlanNameType]);

	useEffect(() => {
		if (watchRatePlanNameType == RatePlanNameType.SYS_GEN) {
			const fetchRatePlanName = async () => {
				const ratePlanNameResult = await getRatePlanNameByMealType(
					watchRatePlanMealType
				);

				setValue('baseInfo.nameEn', ratePlanNameResult.data?.data.nameEn, {
					shouldValidate: isSubmitted,
				});
				setValue('baseInfo.name', ratePlanNameResult.data?.data.name, {
					shouldValidate: isSubmitted,
				});
				setValue('baseInfo.originalName', null, {
					shouldValidate: isSubmitted,
				});
			};

			fetchRatePlanName();
		}
	}, [watchRatePlanMealType]);

	// reset withMealSupplement if channel is api supplier or meal type is no meal

	useEffect(() => {
		if (watchRatePlanChannel == RatePlanChannel.APISUPPLIER) {
			setValue('baseInfo.withMealSupplement', false);
		}
	}, [watchRatePlanChannel]);

	useEffect(() => {
		if (watchRatePlanMealType === MealType.NO_MEAL) {
			setValue('baseInfo.withMealSupplement', false);
		}
	}, [watchRatePlanMealType]);

	// reset enableOptionalMeal if channel is api supplier

	useEffect(() => {
		if (watchRatePlanChannel == RatePlanChannel.APISUPPLIER) {
			setValue('baseInfo.enableOptionalMeal', false);
		}
	}, [watchRatePlanChannel]);

	// reset optional meal list options if enableOptionalMeal is false

	useEffect(() => {
		if (!watchEnableOptionalMeal) {
			if (isPrefilled) setValue('baseInfo.optionalMealTypes', []);
		}
	}, [watchEnableOptionalMeal]);

	// check valid meal supplement logic

	useEffect(() => {
		if (
			watchRatePlanWithMealSupplement &&
			watchRatePlanRateModel != null &&
			watchRatePlanMealType != null &&
			watchRatePlanCheckInPeriodStart != null &&
			watchRatePlanCheckInPeriodStart != INVALID_DATE &&
			watchRatePlanCheckInPeriodEnd != INVALID_DATE
		) {
			const request: CheckValidMealSupplement = {
				rateModel: watchRatePlanRateModel,
				mealType: watchRatePlanMealType,
				checkInPeriod: {
					start: watchRatePlanCheckInPeriodStart,
					end: watchRatePlanCheckInPeriodEnd,
				},
			};
			checkValidMealPlan({
				propertyId: propertyId ?? '',
				body: request,
			});
		}
	}, [
		watchRatePlanWithMealSupplement,
		watchRatePlanMealType,
		watchRatePlanCheckInPeriodStart,
		watchRatePlanCheckInPeriodEnd,
		watchRatePlanRateModel,
		getCheckValidMealPlan,
	]);

	// submit form

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateRatePlanBaseInfo({
			ratePlanId: ratePlanId ?? '',
			body: data.baseInfo,
		});
	};

	useEffect(() => {
		if (isUpdateRatePlanBaseInfoSuccess) setType(FormCardType.view);
	}, [isUpdateRatePlanBaseInfoSuccess]);

	const formContent = (
		<>
			{type === FormCardType.edit && (
				<>
					<AppInputLayout label={t('room.ratePlan.RatePlanId')}>
						<NonEditableDisplayText text={ratePlanId} />
					</AppInputLayout>

					<Divider sx={{ marginBottom: '15px' }} />
				</>
			)}

			<AppSubtitleLayout label={t('property.SupplierMapping')} />

			<AppRadioGroup
				label={t('room.ratePlan.RatePlanChannel')}
				control={control}
				name={'baseInfo.channel'}
				options={ratePlanChannelOptions}
				disabledOptions={disabledRatePlanChannelOptions}
				required
			/>

			<AppDropDownMenu
				control={control}
				name={'baseInfo.rateModel'}
				label={t('room.ratePlan.RateModel')}
				options={
					watchRatePlanChannel == RatePlanChannel.DIRECT
						? propertyRateModelOptions
						: rateModelOptions
				}
				required
			/>

			{watchRatePlanChannel == RatePlanChannel.APISUPPLIER && (
				<AppDropDownMenu
					control={control}
					name={'baseInfo.apiSupplier'}
					label={t('room.ratePlan.ApiSupplier')}
					options={apiSupplierOptions.filter((option) => {
						switch (option.id) {
							case ApiSupplierType.RAKUTEN:
								return (
									getRoomResponse?.data.supplier.mappings[
										ApiSupplierType.RAKUTEN
									].length !== 0
								);
							case ApiSupplierType.MEITUAN:
								return (
									getRoomResponse?.data.supplier.mappings[
										ApiSupplierType.MEITUAN
									].length !== 0
								);
							default:
								return false;
						}
					})}
					required
				/>
			)}

			{getPropertyResponse?.data.supplier.priceMode === PriceMode.PER_ROOM &&
				watchRatePlanChannel == RatePlanChannel.DIRECT && (
					<AppInputLayoutSingleCheckbox
						name={'baseInfo.enableSupplement'}
						label={t('room.ratePlan.EnableSupplement')}
						control={control}
					/>
				)}

			<Divider sx={{ marginBottom: '15px' }} />

			<AppSubtitleLayout label={t('room.ratePlan.RatePlanName')} />

			<AppInputLayout
				required
				label={t('room.ratePlan.Type')}
				removeErrorPadding
			>
				<AppRadioGroup
					label={''}
					options={ratePlanTypeOptions}
					control={control}
					name={'baseInfo.nameType'}
				/>
			</AppInputLayout>

			<AppTextField
				control={control}
				name='baseInfo.nameEn'
				label={t('common.English')}
				inputType={InputType.normal}
				placeholder='Please input in English'
				required
				disabled={watchRatePlanNameType == RatePlanNameType.SYS_GEN}
			/>
			<AppTextField
				control={control}
				name='baseInfo.name'
				label={t('common.TraditionalChinese')}
				placeholder='請輸入繁體中文'
				inputType={InputType.normal}
				required
				disabled={watchRatePlanNameType == RatePlanNameType.SYS_GEN}
			/>
			{watchRatePlanNameType == RatePlanNameType.CUSTOM && (
				<AppTextField
					control={control}
					name='baseInfo.originalName'
					label={t('common.Original')}
					inputType={InputType.normal}
					required
					disabled={watchRatePlanNameType == RatePlanNameType.SYS_GEN}
				/>
			)}

			<AppDropDownMenu
				control={control}
				name={'baseInfo.mealType'}
				label={t('room.ratePlan.MealType')}
				options={getMealTypeOptions()}
			/>

			{watchRatePlanMealType !== MealType.NO_MEAL && (
				<AppRadioGroup
					label={t('room.ratePlan.MealRateCalculation')}
					control={control}
					name={'baseInfo.withMealSupplement'}
					options={mealRateCalculationOptions}
					disabledOptions={
						watchRatePlanChannel == RatePlanChannel.APISUPPLIER ? [true] : []
					}
					required
				/>
			)}

			<AppInputLayoutSingleCheckbox
				control={control}
				name='baseInfo.estimatedPrice'
				label={t('room.ratePlan.EstimatedPrice')}
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			{watchRatePlanChannel == RatePlanChannel.DIRECT && (
				<>
					<AppSubtitleLayout label={t('room.ratePlan.OptionalMeals')} />

					<AppSwitch
						control={control}
						name='baseInfo.enableOptionalMeal'
						label={t('room.ratePlan.EnableOptionalMeals')}
					/>

					{watchEnableOptionalMeal && (
						<AppMultipleCheckbox
							control={control}
							name='baseInfo.optionalMealTypes'
							label={t('room.ratePlan.MealList')}
							options={getMealTypeOptions().filter(
								(option) => option.id != MealType.NO_MEAL
							)}
						/>
					)}
				</>
			)}

			<Divider sx={{ marginBottom: '15px' }} />

			<AppInputLayout
				label={t('room.ratePlan.BookingPeriod')}
				removeErrorPadding
			>
				<Box display='grid' gap='10px' gridTemplateColumns='repeat(2, 1fr)'>
					<AppDateTimePicker
						control={control}
						name={'baseInfo.bookingPeriod.start'}
						required
					/>
					<AppDateTimePicker
						control={control}
						name={'baseInfo.bookingPeriod.end'}
						minimumDate={watchRatePlanBookingPeriodStart}
					/>
				</Box>
			</AppInputLayout>

			<AppInputLayout
				label={t('room.ratePlan.CheckInPeriod')}
				removeErrorPadding
				infoPopUp=' '
			>
				<Box display='grid' gap='10px' gridTemplateColumns='repeat(2, 1fr)'>
					<AppDatePicker
						control={control}
						name={'baseInfo.checkInPeriod.start'}
						required
					/>
					<AppDatePicker
						control={control}
						name={'baseInfo.checkInPeriod.end'}
						minimumDate={watchRatePlanCheckInPeriodStart}
					/>
				</Box>
			</AppInputLayout>

			{watchRatePlanWithMealSupplement &&
				getCheckValidMealPlan != null &&
				!getCheckValidMealPlan?.data.valid && (
					<>
						<AppInputLayout label=' '>
							{!getCheckValidMealPlan?.data.valid && (
								<AppAlert alertColor='#F6403F'>
									{getCheckValidMealPlan?.data.message}
								</AppAlert>
							)}
						</AppInputLayout>
					</>
				)}

			<AppTextField
				control={control}
				name='baseInfo.stayDay.min'
				label={t('room.ratePlan.MinimumStayDays')}
				inputType={InputType.number}
				required
			/>

			<AppTextField
				control={control}
				name='baseInfo.stayDay.max'
				label={t('room.ratePlan.MaximumStayDays')}
				inputType={InputType.number}
				required
			/>

			<AppTextField
				control={control}
				name='baseInfo.cutOffDay'
				label={t('room.ratePlan.CutOffDays')}
				inputType={InputType.number}
				required
			/>

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</>
	);

	const form =
		type === FormCardType.edit
			? (() => {
					return <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;
			  })()
			: formContent;

	return <>{!isGetRatePlanLoading && form}</>;
}

import { useAppSelector } from '../../../redux-hooks';
import { selectFullScreenLoaderState } from './fullScreenLoaderSlice';
import FullScreenLoader from '../FullScreenLoader';

const FullScreenLoaderRoot = () => {
	const { display } = useAppSelector(selectFullScreenLoaderState);

	if (!display) {
		return null;
	}

	return <FullScreenLoader />;
};

export default FullScreenLoaderRoot;

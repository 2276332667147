import {
	Button,
	Grid,
	Paper,
	Stack,
	TableCell,
	TableContainer,
	TableRow,
} from '@mui/material';

import { arrayMove } from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Section, SectionItem } from '../../../../api/DTO/page.interface';
import {
	useGetASectionQuery,
	useUpdateSectionMutation,
} from '../../../../api/pageApiSlice';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppTextField from '../../../../common/components/form-inputs/AppTextField';
import {
	StyledTable,
	StyledTableHead,
} from '../../../../common/components/styled/StyledLightBlueTable';
import DndPropertyListSection from '../../components/DndPropertyListSection';
import SectionSelectProperties from '../../components/SectionSelectProperties';
import { showModal } from '../../../../common/components/modal/modalSlice';
import { useAppDispatch } from '../../../../redux-hooks';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import { pageIconOptions } from '../../../../api/enum/pageIcon.enum';

interface HomeSectionFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function HomeSectionForm({
	type,
	setType,
}: HomeSectionFormProps) {
	const { t } = useTranslation();
	const { sectionId } = useParams();
	const [propertyItems, setPropertyItems] = useState<SectionItem[]>([]);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const backPath = '/page/home';

	const formSchema = Yup.object().shape({
		icon: Yup.string().required(t('errorMessage.pleaseSelect')),
		title: Yup.string().required(t('errorMessage.required')),
	});

	const { data: getASectionResponse, isLoading: isGetSectionLoading } =
		useGetASectionQuery(sectionId ?? '', {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control, watch, setValue } =
		useFormContext() ??
		useForm<Section>({
			defaultValues: getASectionResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const watchItems = watch('items');

	useEffect(() => {
		setPropertyItems(watchItems);
	}, [watchItems]);

	const handleDeleteItem = (index: number) => {
		const newArray = [...propertyItems];
		newArray.splice(index, 1);
		setPropertyItems(newArray);
		setValue('items', newArray);
	};

	const [updateSection, { isSuccess: isUpdateSectionSuccess }] =
		useUpdateSectionMutation();

	useEffect(() => {
		if (isUpdateSectionSuccess) navigate(backPath);
	}, [isUpdateSectionSuccess]);

	const handleRepositionItem = (activeIndex: number, overIndex: number) => {
		setPropertyItems((prev) => arrayMove(prev, activeIndex, overIndex));
		const newArray = arrayMove(propertyItems, activeIndex, overIndex);
		setValue('items', newArray);
	};

	const deleteSectionButtonOnClick = (
		sectionId: string,
		title: string,
		page: string
	) => {
		dispatch(
			showModal({
				modalType: 'DELETE_SECTION',
				modalProps: {
					sectionId,
					title,
					page,
				},
			})
		);
	};

	const SectionSelectPropertiesOnClick = () => {
		setValue('items', propertyItems);
	};

	const onSubmit: SubmitHandler<any> = (data) => {
		updateSection({ sectionId: sectionId ?? '', body: data });
	};

	const formContent = (
		<Stack>
			<Grid container columnSpacing={5}>
				<Grid item xs={12}>
					<AppDropDownMenu
						control={control}
						name='icon'
						label={t('page.Icon')}
						options={pageIconOptions}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<AppTextField
						control={control}
						name='title'
						label={t('page.TitleInChi')}
						placeholder='最多40個字符（20文字）'
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<SectionSelectProperties
						control={control}
						name='items'
						label={t('promotion.Properties')}
						onClick={SectionSelectPropertiesOnClick}
						defaultItems={getASectionResponse?.data.items ?? []}
					/>
					{propertyItems && (
						<TableContainer
							component={Paper}
							sx={{
								marginTop: '13px',
								borderRadius: '5px',
								boxShadow: 'none',
								border: '1px solid #32A5F1',
							}}
						>
							<StyledTable>
								<StyledTableHead>
									<TableRow>
										<TableCell width={'80px'}></TableCell>

										<TableCell width={'120px'}>{t('page.Category')}</TableCell>
										<TableCell width={'100px'}>
											{t('page.PropertyId')}
										</TableCell>
										<TableCell
											width={'auto'}
											style={{ minWidth: '220px' }}
										>{`${t('page.PropertyName')}`}</TableCell>
										<TableCell width={'120px'}>{`${t(
											'page.PropertyStatus'
										)}`}</TableCell>
										<TableCell width={'180px'}>{`${t(
											'page.Tag'
										)} 1`}</TableCell>
										<TableCell width={'180px'}>{`${t(
											'page.Tag'
										)} 2`}</TableCell>
										<TableCell width={'180px'}>{`${t(
											'page.Tag'
										)} 3`}</TableCell>
										<TableCell width={'80px'}></TableCell>
										<TableCell></TableCell>
									</TableRow>
								</StyledTableHead>

								<DndPropertyListSection
									control={control}
									propertyItems={propertyItems}
									handleRepositionItem={handleRepositionItem}
									handleDeleteItem={handleDeleteItem}
								/>
							</StyledTable>
						</TableContainer>
					)}
				</Grid>
			</Grid>

			{type === FormCardType.edit && (
				<Grid container spacing='16px' marginTop={'16px'}>
					<Grid item xs={5}>
						<Button
							variant='contained'
							color='error'
							onClick={() =>
								deleteSectionButtonOnClick(
									getASectionResponse?.data.id ?? '',
									getASectionResponse?.data.title ?? '',
									'home'
								)
							}
						>
							{t('button.DeleteSection')}
						</Button>
					</Grid>
					<Grid item xs>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => navigate(backPath)}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Grid>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetSectionLoading && form}</>;
}

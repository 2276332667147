import { Box, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactComponent as MyfamigoSimpleLogoLargeIcon } from '../../../assets/icon/myfamigoSimpleLogoLarge.svg';
import loginBackground from '../../../assets/images/loginBackground.svg';

interface AuthContainerProps {
	title: string;
	message: string;
}

const BackgroundContainer = styled('div')({
	backgroundImage: `url(${loginBackground})`,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
});

const ContentBox = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100vh',
});

export default function AuthContainer(
	props: React.PropsWithChildren<AuthContainerProps>
) {
	return (
		<>
			<BackgroundContainer>
				<ContentBox>
					<Stack width='382px' alignItems='center' display='flex'>
						<MyfamigoSimpleLogoLargeIcon />
						<Typography
							fontSize='48px'
							variant='bold'
							marginTop='22px'
							whiteSpace='nowrap'
						>
							{props.title}
						</Typography>
						<Typography fontSize='14px' marginTop='7px'>
							{props.message}
						</Typography>
						{props.children}
					</Stack>
				</ContentBox>
			</BackgroundContainer>
		</>
	);
}

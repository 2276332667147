import { Typography } from '@mui/material';

interface NonEditableDisplayTextProps {
	text?: string;
}

export default function NonEditableDisplayText({
	text,
}: NonEditableDisplayTextProps) {
	return (
		<Typography fontSize='12px' color='#808080' whiteSpace='pre-wrap'>
			{text}
		</Typography>
	);
}

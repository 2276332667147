import { hideModal } from './modalSlice';
import {
	Button,
	Dialog,
	Divider,
	Stack,
	styled,
	Typography,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/icon/close.svg';
import { useAppDispatch } from '../../../redux-hooks';
import { useTranslation } from 'react-i18next';

interface DeleteUserSuccessModalProps {
	nickname: string;
}

const DeleteUserSuccessModal = ({ nickname }: DeleteUserSuccessModalProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const handleClose = () => {
		dispatch(hideModal());
	};

	return (
		<Dialog
			sx={{
				'& .MuiDialog-paper': {
					padding: '1rem 1.75rem 1.5rem 1.75rem',
				},
			}}
			open={nickname != null}
		>
			<Stack direction={'row'} alignItems={'center'}>
				<DialogTitle variant='bold' sx={{ flexGrow: 1 }}>
					<Typography variant='bold' color='#DA5150'>
						{nickname}
					</Typography>{' '}
					is now removed
				</DialogTitle>
				<CloseIcon onClick={handleClose} cursor='pointer' />
			</Stack>
			<StyledDivider />
			<DialogContent variant='regular'>
				We have successfully remove{' '}
				<Typography component='span' variant='inherit' color='#DA5150'>
					{nickname}
				</Typography>{' '}
				from the admin portal, and the account is now deleted.
			</DialogContent>
			<Stack
				direction={'row'}
				gap={'0.75rem'}
				sx={{ marginTop: '1.5rem', marginLeft: 'auto' }}
			>
				<Button variant='outlined' color='inherit' onClick={handleClose}>
					{t('button.Okay')}
				</Button>
			</Stack>
		</Dialog>
	);
};

const DialogTitle = styled(Typography)({
	fontSize: '1rem',
});

const DialogContent = styled(Typography)({
	fontSize: '0.875rem',
});

const StyledDivider = styled(Divider)({
	border: '1.5px solid #E4E8EA',
	marginTop: '1rem',
	marginBottom: '1.5rem',
});

export default DeleteUserSuccessModal;

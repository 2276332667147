/* eslint-disable no-mixed-spaces-and-tabs */
import { useState } from 'react';

import {
	Box,
	Button,
	Divider,
	Menu,
	MenuProps,
	Stack,
	Typography,
	styled,
} from '@mui/material';

import dayjs from 'dayjs';
import { ReactComponent as ViewDetailIcon } from '../../../assets/icon/view-detail.svg';
import { StyledMenuItem } from '../../../common/components/styled/StyledSelect';
import { DISPLAY_DATE_FORMAT } from '../../../utils/dateHelper';

interface AbkViewRecordButtonProps {
	passObjectValue?: boolean;
	label?: string;
	data: any[];
	AmVersion?: number;
	onChange: (value: any) => void;
}

const AbkViewRecordButton = ({
	passObjectValue,
	label,
	data,
	AmVersion,
	onChange,
}: AbkViewRecordButtonProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [open, setOpen] = useState(false);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen((previousOpen) => !previousOpen);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	return (
		<Box position={'relative'}>
			<Button
				variant='contained'
				startIcon={<ViewDetailIcon />}
				sx={{
					backgroundColor: '#4EA4D3',
					borderColor: '#4EA4D3',
					boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
				}}
				onClick={handleClick}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						textAlign: 'start',
					}}
				>
					<Typography
						variant='regular'
						sx={{
							lineHeight: '1rem',
						}}
					>
						{label}
					</Typography>
					<Typography
						variant='regular'
						sx={{
							fontSize: '0.65rem',
							lineHeight: '0.9rem',

							display: 'flex',
							alignItems: 'center',
							minWidth: '130px',
						}}
					>
						{AmVersion === 0
							? `Current ver: Original`
							: `Current ver: Amendment ${AmVersion}`}
					</Typography>
				</Box>
			</Button>
			<StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				{data &&
					data.map((option, index) => (
						<Box key={option.version}>
							<StyledMenuItem
								value={option.version}
								onClick={() => {
									setOpen(false);
									onChange(option.version);
								}}
								sx={{
									backgroundColor: option.backgroundColor,
								}}
							>
								<Stack
									direction={'row'}
									alignItems={'center'}
									sx={{
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<Typography
										variant='regular'
										color={option.color}
										sx={{
											fontSize: '0.75rem',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										{option.name}
									</Typography>
									<Typography
										variant='regular'
										color='#808080'
										sx={{
											fontSize: '0.75rem',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										{dayjs(option.createdAt).format(DISPLAY_DATE_FORMAT)}
									</Typography>
								</Stack>
							</StyledMenuItem>
							{index + 1 < data.length && <StyledDivider />}
						</Box>
					))}
			</StyledMenu>
		</Box>
	);
};

export default AbkViewRecordButton;

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
	({ theme }) => ({
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 180,
			back: 0,
			color:
				theme.palette.mode === 'light'
					? 'rgb(55, 65, 81)'
					: theme.palette.grey[300],
			boxShadow:
				'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
			'& .MuiMenu-list': {
				padding: '0',
			},
			'& .MuiMenuItem-root': {
				'& .MuiSvgIcon-root': {
					fontSize: 18,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(1.5),
				},
			},
		},
	})
);

const StyledDivider = styled(Divider)({
	border: '1.5px solid #F5F5F5',
	marginTop: '0 !important',
	marginBottom: '0 !important',
});

import { Box, Divider, InputAdornment, Grid, Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useFormContext, useFieldArray } from 'react-hook-form';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';

import { currencies } from '../../../../common/constants/currencies';

import { FormCardType } from '../../../../common/components/card/FormCard';

import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';
import { ReactComponent as EmailArrow } from '../../../../assets/icon/emailArrow.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icon/button-add.svg';

import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { abkLocaleOptions } from '../../../../api/enum/locale.enum';
import AppPhoneCodeDropDownMenuV2 from '../../../../common/components/form-inputs/AppPhoneCodeDropDownMenuV2';
import { useGetPhoneCodesQuery } from '../../../../api/locationApiSlice';
import { departmentOption } from '../../../../api/enum/department.enum';
import AppAddButton from '../../../../common/components/form-inputs/AppAddButton';

interface SupplierInfoFormProps {
	type: FormCardType;
}

export default function SupplierInfoForm({ type }: SupplierInfoFormProps) {
	const { t } = useTranslation();

	const { data: getPhoneCodesResponse } = useGetPhoneCodesQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	const { control } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'supplier.contactPoints',
	});

	const showDeleteButton = fields.length > 1;

	const formContent = (
		<Stack>
			<AppTextField
				control={control}
				name={'supplier.name'}
				label={t('abk.SupplierCompanyName')}
				required
			/>

			<AppDropDownMenu
				control={control}
				name={'supplier.locale'}
				label={t('abk.SupplierLanguage')}
				options={abkLocaleOptions}
				required
			/>

			<AppDropDownMenu
				control={control}
				name='supplier.currency'
				label={t('abk.SupplierCurrency')}
				options={currencies}
				required
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppSubtitleLayout label={t('abk.SupplierContactPoint')} />

			{fields.map((item, index) => {
				return (
					<Box key={item.id} sx={{ position: 'relative' }}>
						{showDeleteButton && (
							<>
								{index > 0 && (
									<>
										<Divider sx={{ marginBottom: '15px' }} />
										<Box
											sx={{
												position: 'absolute',
												top: 0,
												right: 0,
											}}
										>
											<DeleteIcon
												color='#F6403F'
												cursor={'pointer'}
												onClick={() => remove(index)}
											/>
										</Box>
									</>
								)}
								{index == 0 && (
									<Box
										sx={{
											position: 'absolute',
											top: '-15px',
											right: 0,
										}}
									>
										<DeleteIcon
											color='#F6403F'
											cursor={'pointer'}
											onClick={() => remove(index)}
										/>
									</Box>
								)}
							</>
						)}
						<AppInputLayout
							removeErrorPadding
							label={t('supplier.Department')}
							required
						>
							<Box
								display='grid'
								gap='10px'
								alignItems='start'
								paddingTop={'20px'}
							>
								<AppDropDownMenu
									control={control}
									name={`supplier.contactPoints.${index}.department`}
									options={departmentOption}
									label={''}
									required
									fullWidth
								/>
							</Box>
						</AppInputLayout>

						<AppTextField
							control={control}
							name={`supplier.contactPoints.${index}.name`}
							label={t('supplier.ContactName')}
							required
						/>

						<AppInputLayout removeErrorPadding label={t('abk.PhoneNumber')}>
							<Grid container columnSpacing={2}>
								<Grid item xs={4}>
									<AppPhoneCodeDropDownMenuV2
										control={control}
										name={`supplier.contactPoints.${index}.phoneCode`}
										options={getPhoneCodesResponse?.data ?? []}
										label={''}
										passObjectValue={true}
										placeholder={'-'}
										fullWidth
									/>
								</Grid>
								<Grid item xs={8}>
									<AppTextField
										control={control}
										name={`supplier.contactPoints.${index}.phoneNum`}
										inputType={InputType.number}
										label={''}
									/>
								</Grid>
							</Grid>
						</AppInputLayout>

						<AppTextField
							control={control}
							name={`supplier.contactPoints.${index}.email`}
							label={t('abk.SupplierEmail')}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<EmailArrow />
									</InputAdornment>
								),
							}}
						/>
					</Box>
				);
			})}

			<AppAddButton
				onClick={() =>
					append({
						department: undefined,
						name: undefined,
						phoneCode: undefined,
						phoneNum: undefined,
						email: undefined,
					})
				}
			>
				<AddIcon />
				{t('button.Add')}
			</AppAddButton>
		</Stack>
	);

	const form = formContent;

	return <>{form}</>;
}

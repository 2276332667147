import { ButtonBase, Stack, Typography } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../../assets/icon/button-add.svg';

interface AddbuttonProps {
	icon?: ReactNode;
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function Addbutton({
	onClick,
}: React.PropsWithChildren<AddbuttonProps>) {
	const { t } = useTranslation();

	return (
		<ButtonBase onClick={onClick}>
			<Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
				<AddIcon />
				<Typography color={'#32A5F1'} fontSize={'12px'}>
					{t('button.Add')}
				</Typography>
			</Stack>
		</ButtonBase>
	);
}

import {
	FormControl,
	Paper,
	styled,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import {
	FieldPath,
	FieldValues,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { ReactComponent as StatusDot } from '../../../assets/icon/statusDot.svg';
import Option from '../../types/option.type';

interface AppStatusToggleProps {
	formLabel?: string;
	disabled?: boolean;
	options: Option[];
	defaultValue?: any;
	buttonOnChange?: (value: any) => void;
}

export default function AppFormStatusToggle<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	formLabel,
	disabled,
	options,
	defaultValue,
	buttonOnChange,
	...props
}: AppStatusToggleProps & UseControllerProps<TFieldValues, TName>) {
	const { field, fieldState } = useController(props);

	const handleAlignment = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: any
	) => {
		if (newAlignment !== null) {
			field.onChange(newAlignment);

			if (buttonOnChange) {
				buttonOnChange(newAlignment);
			}
		}
	};
	return (
		<>
			<FormControl sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography fontSize={'14px'}>{formLabel}</Typography>
				<StyledPaper elevation={0}>
					<StyledToggleButtonGroup
						{...field}
						color='primary'
						exclusive
						onChange={(e, value) => {
							handleAlignment(e, value);
						}}
						value={field.value ?? ''}
						aria-label={formLabel}
						disabled={disabled}
					>
						{options.map((option) => (
							<ToggleButton key={option.id} value={option.id}>
								<StatusDot />
								<Typography
									display={'inline-block'}
									variant='bold'
									fontSize={14}
									marginLeft='4px'
								>
									{option.name}
								</Typography>
							</ToggleButton>
						))}
					</StyledToggleButtonGroup>
				</StyledPaper>
			</FormControl>
		</>
	);
}

const StyledPaper = styled(Paper)({
	width: 'fit-content',
	backgroundColor: '#E8EBED',
	borderRadius: '5px',
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
	'& .MuiToggleButtonGroup-grouped': {
		margin: '4px',
		border: 0,
		textTransform: 'none',
		padding: '3px 12px 3px 12px',
		color: '#BCBCBC',
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: '#FFFFFF',
		},
		': hover': {
			backgroundColor: 'transparent',
		},

		'&.Mui-disabled': {
			border: 0,
		},
		'&:not(:first-of-type)': {
			borderRadius: '3px',
			'&.Mui-selected': {
				color: '#808080',
			},
		},
		'&:first-of-type': {
			borderRadius: '3px',
			'&.Mui-selected': {
				color: '#4B9831',
			},
		},
	},
});

import Option from '../../common/types/option.type';

export enum PropertyCategory {
	HOTEL = 0,
	CAMPING = 1,
	APARTMENT = 2,
	GLAMPING = 3,
	HOUSE = 4,
	KOMINKA = 5,
	RYOKAN = 6,
}

export const propertyCategoryOptions: Option[] = [
	{
		id: PropertyCategory.APARTMENT,
		name: 'Apartment',
	},
	{
		id: PropertyCategory.CAMPING,
		name: 'Camping',
	},
	{
		id: PropertyCategory.GLAMPING,
		name: 'Glamping',
	},
	{
		id: PropertyCategory.HOTEL,
		name: 'Hotel',
	},
	{
		id: PropertyCategory.HOUSE,
		name: 'House',
	},
	{
		id: PropertyCategory.KOMINKA,
		name: 'Kominka',
	},
	{
		id: PropertyCategory.RYOKAN,
		name: 'Ryokan',
	},
];

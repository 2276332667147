import { IconButton, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TangleLog } from '../../../../api/DTO/propertyImport.interface';
import {
	useGetTangleLogQuery,
	useLazyGetTangleReportQuery,
} from '../../../../api/scraperApiSlice';
import { ReactComponent as DownloadIcon } from '../../../../assets/icon/download.svg';
import LocalDataGrid from '../../../../common/components/dataGrid/LocalDataGrid';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../../utils/dateHelper';

export default function ImportLoggingCard() {
	const { t } = useTranslation();

	const { data: getTangleLogResponse } = useGetTangleLogQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	const [getTangleReport] = useLazyGetTangleReportQuery();

	const columns: GridColDef[] = [
		{
			field: 'engineVersion',
			headerName: t('property.propertyImport.EngineVersion'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'startTime',
			headerName: t('property.propertyImport.StartTime'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
			renderCell: (params) => (
				<Stack direction={'row'} gap={'0.5rem'} alignItems={'center'}>
					<Typography
						variant='regular'
						sx={{
							fontSize: '0.75rem',
							wordWrap: 'break-word',
							whiteSpace: 'normal',
						}}
					>
						{dayjs(params.row.startTime).format(DISPLAY_DATE_TIME_FORMAT)}
					</Typography>
				</Stack>
			),
		},
		{
			field: 'endTime',
			headerName: t('property.propertyImport.EndTime'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
			renderCell: (params) => (
				<Stack direction={'row'} gap={'0.5rem'} alignItems={'center'}>
					<Typography
						variant='regular'
						sx={{
							fontSize: '0.75rem',
							wordWrap: 'break-word',
							whiteSpace: 'normal',
						}}
					>
						{dayjs(params.row.endTime).format(DISPLAY_DATE_TIME_FORMAT)}
					</Typography>
				</Stack>
			),
		},
		{
			field: 'status',
			headerName: t('property.Status'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'successNo',
			headerName: t('property.propertyImport.SuccessNo'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'failureNo',
			headerName: t('property.propertyImport.FailureNo'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'export',
			headerName: t('property.propertyImport.Export'),
			// flex: 1,
			width: 86,
			sortable: false,
			renderCell: (params) => (
				<IconButton
					color='primary'
					onClick={() => getTangleReport(params.row.id)}
					disabled={params.row.status !== 'completed'}
				>
					<DownloadIcon
						color={params.row.status === 'completed' ? '#4EA4D3' : '#808080'}
					/>
				</IconButton>
			),
		},
	];

	const transformResponse = useCallback((response?: TangleLog[]) => {
		if (response == null) return;
		if (response.length == 0)
			return [
				{
					id: '-',
					name: '-',
					description: '-',
					category: '-',
					numOfRooms: '-',
				},
			];

		const newResponse: any[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				id: item.id ?? '-',
				engineVersion: item.engineVersion ?? '-',
				startTime: item.startTime ?? '-',
				endTime: item.endTime ?? '-',
				status: item.status ?? '-',
				successNo: item.successNo ?? '-',
				failureNo: item.failureNo ?? '-',
			});
		});
		return newResponse;
	}, []);

	return (
		<Stack>
			<LocalDataGrid
				rows={transformResponse(getTangleLogResponse?.data) ?? []}
				columns={columns}
				rowCount={0}
				getRowId={(row) => row.id}
				pagination={false}
			/>
		</Stack>
	);
}

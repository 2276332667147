import { Box, Button, Dialog, Typography, styled } from '@mui/material';
import { Stack } from '@mui/system';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PropertyFilter } from '../../../api/DTO/property.interface';
import { useGetFilteredPropertiesQuery } from '../../../api/accommodationApiSlice';
import { propertyCategoryOptions } from '../../../api/enum/propertyCategories.enum';
import { ReactComponent as SuppplierMeituanIcon } from '../../../assets/icon/supplier-meituan.svg';
import { ReactComponent as SuppplierMyfamigoIcon } from '../../../assets/icon/supplier-myfamigo.svg';
import { ReactComponent as SuppplierRakutenIcon } from '../../../assets/icon/supplier-rakuten.svg';
import AppDataGridHeaderWithSorting from '../../../common/components/dataGrid/AppDataGridHeaderWithSorting';
import { PropertyDataGrid } from '../../../pages/property/PropertySummaryPage';
import PropertySummaryFilterSection from '../../../pages/property/components/PropertySummaryFilterSection';
import { DISPLAY_DATE_FORMAT } from '../../../utils/dateHelper';
import { getPropertyStatusDisplay } from '../../../utils/propertyHelper';
import { PropertySummaryItem } from '../../interface/propertySummaryItem.interface';
import AppStatusDisplay from '../AppStatusDisplay';
import AppDataGrid, { initialPagination } from '../dataGrid/AppDataGrid';
import CountryFlag from '../form-data-display/CountryFlag';

export interface SelectPropertiesModalProps {
	open: boolean;
	onClose: (selectedProperties?: PropertySummaryItem[]) => void;
	selectedProperties?: PropertySummaryItem[];
}

export default function SelectPropertiesModal(
	props: SelectPropertiesModalProps
) {
	const { t } = useTranslation();

	const { onClose, open, selectedProperties } = props;

	const [properties, setProperties] = useState<PropertySummaryItem[]>(
		selectedProperties ?? []
	);

	const defaultPropertyFilter: PropertyFilter = {
		pagination: initialPagination,
		sortings: [],
		searchKey: '',
		statuses: undefined,
		countryId: undefined,
		majorAreaId: undefined,
		areaId: undefined,
		categories: undefined,
		hasOwner: undefined,
		ownerId: undefined,
	};

	const defaultValues: DefaultValues<PropertyFilter> = defaultPropertyFilter;

	const methods = useForm<PropertyFilter>({
		defaultValues,
	});

	const [propertyFilterResult, setPropertyFilterResult] =
		useState<PropertyFilter>(defaultPropertyFilter);

	const { data: propertySummary, isSuccess: isGetPropertySummarySuccess } =
		useGetFilteredPropertiesQuery(propertyFilterResult, {
			refetchOnMountOrArgChange: true,
		});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch(() => {
			setPropertyFilterResult(methods.watch());
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: PropertySummaryItem[]) => {
		if (response == null) return;
		const newResponse: PropertyDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.id,
				name: item.name,
				nameEn: item.nameEn,
				status: { propertyId: item.id, status: item.status },
				majorArea: { name: item.majorArea, countryCode: item.countryCode },
				area: item.area,
				ownerShip: item.owner ?? t('property.Individual'),
				category:
					propertyCategoryOptions.find((option) => option.id == item.category)
						?.name ?? '',
				suppliersType: {
					directContract: item.directContract,
					apiSuppliers: item.apiSuppliers,
				},
				createdAt: dayjs(item.createdAt).format(DISPLAY_DATE_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const DataGridHeader = (params: GridColumnHeaderParams) => {
		return (
			<AppDataGridHeaderWithSorting
				headerName={params.colDef.headerName}
				sortingsPropertyName={params.colDef.field}
			/>
		);
	};

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: t('property.Id'),
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			renderHeader: DataGridHeader,
		},
		{
			field: 'name',
			headerName: t('property.Name'),
			flex: 1,
			minWidth: 200,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
							fontSize: '0.75rem',
						}}
						variant='regular'
					>
						{params.row.nameEn}
					</Typography>
					<Typography
						sx={{
							display: 'block',
							fontSize: '0.625rem',
						}}
						variant='regular'
					>
						{params.row.name}
					</Typography>
				</Box>
			),
		},
		{
			field: 'status',
			headerName: t('property.Status'),
			width: 80, // 10(cell padding left) + 60(status fixed width) + 10(cell padding right)
			renderCell: (params) => (
				<AppStatusDisplay
					name={getPropertyStatusDisplay(params.row.status.status).name}
					color={getPropertyStatusDisplay(params.row.status.status).color}
					bgcolor={
						getPropertyStatusDisplay(params.row.status.status).backgroundColor
					}
				/>
			),
		},
		{
			field: 'majorArea',
			headerName: t('property.MajorArea'),
			flex: 1,
			minWidth: 95,
			renderCell: (params) => (
				<Stack direction={'row'} alignItems={'center'}>
					<CountryFlag
						iso2={params.row.majorArea.countryCode as string}
						size='medium'
						style={{ marginRight: '8px' }}
					/>
					<Typography
						variant='regular'
						sx={{
							fontSize: '12px',
							wordWrap: 'anywhere',
							whiteSpace: 'normal',
						}}
					>
						{params.row.majorArea.name}
					</Typography>
				</Stack>
			),
		},
		{
			field: 'area',
			headerName: t('property.Area'),
			flex: 1,
		},
		{
			field: 'ownerShip',
			headerName: t('property.OwnerShip'),
			flex: 1,
		},
		{
			field: 'category',
			headerName: t('property.Category'),
			flex: 1,
		},
		{
			field: 'suppliersType',
			headerName: t('property.Suppliers'),
			width: 112,
			renderCell: (params) => (
				<Stack direction={'row'} gap={'0.25rem'}>
					{params.row.suppliersType.directContract && <SuppplierMyfamigoIcon />}
					{(params.row.suppliersType.apiSuppliers as number[]).includes(0) && (
						<SuppplierRakutenIcon />
					)}
					{(params.row.suppliersType.apiSuppliers as number[]).includes(1) && (
						<SuppplierMeituanIcon />
					)}
				</Stack>
			),
		},
		{
			field: 'createdAt',
			headerName: t('property.DateCreated'),
			width: 140, // 10(cell padding left) + 120(header fixed width) + 10(cell padding right)
			renderHeader: DataGridHeader,
		},
	];

	return (
		<Dialog
			sx={{
				width: '100%',
				'& .MuiDialog-paper': {
					display: 'flex',
					width: '100%',
					padding: '1rem 1.75rem 1.5rem 1.75rem',
				},
				overflow: 'hidden',
			}}
			fullWidth={true}
			maxWidth={'lg'}
			open={open}
		>
			<Stack direction={'row'} alignItems={'center'}>
				<DialogTitle variant='bold' sx={{ flexGrow: 1 }}>
					Select Properties
				</DialogTitle>
				{/* <CloseIcon onClick={handleClose} cursor='pointer' /> */}
			</Stack>
			<DialogContent
				variant='regular'
				sx={{
					border: '2px solid #E8EBED',
					borderRadius: '10px',
					padding: '15px',
					marginTop: '15px',
					overflow: 'auto',
				}}
			>
				<FormProvider {...methods}>
					<PropertySummaryFilterSection
						isInitializedForm={true}
						transformedSearchParams={propertyFilterResult}
					/>

					<Box height={'500px'} marginTop={'28px'}>
						<AppDataGrid
							loading={!isGetPropertySummarySuccess}
							rows={transformResponse(propertySummary?.data?.items) ?? []}
							columns={columns}
							rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
							getRowId={(row) => row.id}
							autoHeight={false}
							checkboxSelection
							selectionModel={properties.map((item) => item.id)}
							onSelectionModelChange={(newSelectionModel) => {
								const newItemsAdded =
									propertySummary?.data?.items.filter(
										(item) =>
											!properties.find((object) => object.id == item.id) &&
											newSelectionModel.includes(item.id)
									) ?? [];
								const newSelectedProperties = [
									...properties,
									...newItemsAdded,
								].filter((item) => newSelectionModel.includes(item.id));

								setProperties(newSelectedProperties);
							}}
						/>
					</Box>
				</FormProvider>
			</DialogContent>
			<Stack
				direction={'row'}
				gap={'0.75rem'}
				sx={{ marginTop: '1.5rem', marginLeft: 'auto' }}
			>
				<Button
					variant='outlined'
					color='info'
					onClick={() => {
						onClose();
					}}
				>
					{t('button.Discard')}
				</Button>
				<Button
					variant='contained'
					color='success'
					onClick={() => {
						onClose(properties);
					}}
				>
					{t('button.Save')}
				</Button>
			</Stack>
		</Dialog>
	);
}

const DialogTitle = styled(Typography)({
	fontSize: '1rem',
});

const DialogContent = styled(Typography)({
	fontSize: '0.875rem',
});

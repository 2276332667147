import { Box, Typography, styled } from '@mui/material';

interface AppNightsDisplay {
	nights: number;
}

export default function AppNightsDisplay({ nights }: AppNightsDisplay) {
	return (
		<NightsBox
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '2px',
				flexDirection: 'column',
			}}
		>
			<Typography
				sx={{ fontSize: '1.3rem', fontWeight: '700', lineHeight: '0.75rem' }}
			>
				{nights}
			</Typography>
			<Typography sx={{ fontSize: '0.5rem', lineHeight: '0.5rem' }}>
				nights
			</Typography>
		</NightsBox>
	);
}

const NightsBox = styled(Box)(({ theme }) => ({
	color: 'white',
	backgroundColor: '#808080',
	borderRadius: '7px',
	padding: '5px',
	height: '30px',
	width: '30px',
}));

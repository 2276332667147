import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getMealTypeNameByMealType } from '../../../api/enum/mealType.enum';
import FormCard, {
	FormCardType,
} from '../../../common/components/card/FormCard';
import MealForm from './MealForm';
import MealView from './MealView';

interface HotelFacilityCardProps {
	initialType: FormCardType;
}

export default function HotelFacilityCard({
	initialType,
}: HotelFacilityCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);
	const { mealTypeId } = useParams();

	return (
		<FormCard
			title={`${t(
				`mealSupplement.${getMealTypeNameByMealType(Number(mealTypeId))}`
			)} Supplement`}
			type={type}
		>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <MealView setType={setType} />;
					case FormCardType.edit:
						return <MealForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

import { Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../api/userApiSlice';
import defineAbilityFor from '../../casl/defineAbility';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import PageHeader from '../../common/components/PageHeader';
import { FormCardType } from '../../common/components/card/FormCard';
import { Promotion } from '../../api/DTO/promotion.interface';
import { DiscountType } from '../../api/enum/discountType.enum';
import { useAddPromotionMutation } from '../../api/promotionApiSlice';
import { NONE_INVALID_DATE_PATTERN } from '../../common/constants/pattern';
import PromotionCard from './overview/promotion/PromotionCard';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

export default function CreatePromotionPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();

	const [promotionSummarySearchParams, setPromotionSummarySearchParams] =
		useState<string | undefined>(undefined);

	useEffect(() => {
		if (state?.promotionSummarySearchParams) {
			setPromotionSummarySearchParams(state?.promotionSummarySearchParams);
		}
	}, []);

	const backPath = promotionSummarySearchParams
		? `/promotion?${promotionSummarySearchParams}`
		: '/promotion';

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const defaultValues: DefaultValues<Promotion> = {
		code: undefined,
		description: undefined,
		discount: undefined,
		quota: undefined,
		properties: [],
	};

	const formSchema = Yup.object().shape({
		code: Yup.string().required(t('errorMessage.required')),
		discount: Yup.object()
			.shape({
				amount: Yup.string().required(t('errorMessage.required')),
				type: Yup.string().required(t('errorMessage.pleaseSelect')),
				currency: Yup.string()
					.nullable()
					.when('type', {
						is: DiscountType.FIXED,
						then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
					}),
			})
			.required(),
		quota: Yup.string().required(t('errorMessage.required')),
		validPeriod: Yup.object().shape({
			start: Yup.string()
				.required(t('errorMessage.required'))
				.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
				.test(
					'shouldGreaterThanToday',
					() => t('errorMessage.incorrectPeriodTime'),
					(value) => dayjs(value).isSameOrAfter(dayjs(), 'day')
				),
			end: Yup.string()
				.required(t('errorMessage.required'))
				.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
				.test(
					'shouldGreaterThanToday',
					() => t('errorMessage.incorrectPeriodTime'),
					(value) => dayjs(value).isSameOrAfter(dayjs(), 'day')
				)
				.when('start', (start: any, schema) => {
					return schema.test(
						'shouldGreaterThanStart',
						() => t('errorMessage.incorrectPeriodTime'),
						(value) => !!start && dayjs(value).isAfter(dayjs(start))
					);
				}),
		}),
	});

	const [
		addPromotion,
		{ data: AddPromotionResponse, isSuccess: isAddPromotionSuccess },
	] = useAddPromotionMutation();

	const methods = useForm<Promotion>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const onSubmit: SubmitHandler<Promotion> = (data) => {
		if (data.discount.type === DiscountType.PERCENTAGE) {
			data.discount.currency = undefined;
		}
		if (data.description == '') {
			data.description = undefined;
		}
		addPromotion(data);
	};

	useEffect(() => {
		if (isAddPromotionSuccess) {
			navigate(`/promotion/${AddPromotionResponse?.data.id}`);
		}
	}, [isAddPromotionSuccess]);

	return (
		<>
			<PageHeader
				showBackButton={true}
				backPath={backPath}
				title={t('promotion.CreatePromotion')}
			/>
			{ability.can('create', 'Promotion') && (
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Stack spacing='16px'>
							<Grid container alignItems='strech' spacing={'10px'}>
								<Grid item xs={12} display='flex'>
									<PromotionCard initialType={FormCardType.create} />
								</Grid>
							</Grid>

							<Stack spacing='16px'>
								<Grid item xs={12}>
									<Stack direction='row-reverse' spacing='12px'>
										<Button type='submit' variant='contained' color='success'>
											{t('button.Save')}
										</Button>
										<Button
											variant='outlined'
											color='info'
											onClick={() => navigate(backPath)}
										>
											{t('button.Discard')}
										</Button>
									</Stack>
								</Grid>
							</Stack>
						</Stack>
					</form>
				</FormProvider>
			)}
		</>
	);
}

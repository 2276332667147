import { Dispatch, SetStateAction, useEffect } from 'react';
import { FormCardType } from '../../../../common/components/card/FormCard';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Button,
	Divider,
	Grid,
	InputAdornment,
	Stack,
} from '@mui/material';
import {
	Controller,
	FieldError,
	FieldValues,
	SubmitHandler,
	get,
	useForm,
	useFormContext,
} from 'react-hook-form';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';
import AppSingleCheckbox from '../../../../common/components/form-inputs/AppSingleCheckbox';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import { roomViewOption } from '../../../../api/enum/roomView.enum';
import AppSwitch from '../../../../common/components/form-inputs/AppSwitch';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import { StyledFormControlLabel } from '../../../../common/components/form-inputs/AppMultipleCheckbox';
import AppCheckbox from '../../../../common/components/form-inputs/AppCheckbox';
import AppRoomSizeInputField from '../../../../common/components/form-inputs/AppRoomSizeInputField';
import BedConfigsField from '../components/BedConfigsField';
import Yup from '../../../../utils/yup-extended';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	useGetRoomQuery,
	useUpdateRoomInfoMutation,
} from '../../../../api/accommodationApiSlice';
import { useParams } from 'react-router-dom';
import { Room } from '../../../../api/DTO/room.interface';
import NewRoomTags from '../components/NewRoomTags';
import AppRichTextEditor from '../../../../common/components/editor/AppRichTextEditor';

interface RoomFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function RoomInfoForm({ type, setType }: RoomFormProps) {
	const { t } = useTranslation();

	const { roomId } = useParams();

	const formSchema = Yup.object().shape({
		info: Yup.object()
			.shape({
				originalName: Yup.string().required(t('errorMessage.required')),
				nameEn: Yup.string().required(t('errorMessage.required')),
				name: Yup.string().required(t('errorMessage.required')),
				maxCapacity: Yup.string().required(t('errorMessage.required')),
				maxAdultCapacity: Yup.string().required(t('errorMessage.required')),
				maxChildCapacity: Yup.string().required(t('errorMessage.required')),
				view: Yup.number().required(t('errorMessage.pleaseSelect')),
				bedConfigs: Yup.array()
					.nullable()
					.requireBedConfigs(t('errorMessage.atLeastOneBedType'))
					.validateBedConfigs(t('errorMessage.pleaseSelect')),
				atLeastOneChecked: Yup.bool().when(
					[
						'allowSharedBed',
						'infantSharedBed',
						'toddlerSharedBed',
						'childSharedBed',
						'adultSharedBed',
					],
					{
						is: (
							allowSharedBed: boolean,
							infantSharedBed: boolean,
							toddlerSharedBed: boolean,
							childSharedBed: boolean,
							adultSharedBed: boolean
						) =>
							allowSharedBed &&
							!(
								infantSharedBed ||
								toddlerSharedBed ||
								childSharedBed ||
								adultSharedBed
							),
						then: (schema) =>
							schema.required(t('errorMessage.atLeastOneCheckbox')),
					}
				),
			})
			.required(),
	});

	const { data: getRoomResponse, isLoading: isGetRoomLoading } =
		useGetRoomQuery(roomId ?? '', {
			skip: type === FormCardType.create,
		});

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors },
		trigger,
	} = useFormContext() ??
	useForm<Room>({
		defaultValues: getRoomResponse?.data,
		resolver: yupResolver(formSchema),
	});

	const watchAllowSharedBed = watch('info.allowSharedBed');

	useEffect(() => {
		if (watchAllowSharedBed === false) {
			setValue('info.infantSharedBed', false);
			setValue('info.toddlerSharedBed', false);
			setValue('info.childSharedBed', false);
			setValue('info.adultSharedBed', false);
			setValue('info.sharedBedExtraCost', false);
		}
	}, [watchAllowSharedBed]);

	useEffect(() => {
		trigger('info.atLeastOneChecked');
	}, [
		watch('info.infantSharedBed'),
		watch('info.toddlerSharedBed'),
		watch('info.childSharedBed'),
		watch('info.adultSharedBed'),
	]);

	const [updateRoomInfo, { isSuccess: isUpdateRoomInfoSuccess }] =
		useUpdateRoomInfoMutation();

	useEffect(() => {
		if (isUpdateRoomInfoSuccess) setType(FormCardType.view);
	}, [isUpdateRoomInfoSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateRoomInfo({
			roomId: roomId ?? '',
			body: data.info,
		});
	};

	const formContent = (
		<Stack>
			<AppSubtitleLayout label={t('room.RoomType')} />

			<AppTextField
				control={control}
				name='info.nameEn'
				label={t('common.English')}
				placeholder={t('placeholder.pleaseEnterEnglish')}
				required
			/>

			<AppTextField
				control={control}
				name='info.name'
				label={t('common.TraditionalChinese')}
				placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
				required
			/>

			<AppTextField
				control={control}
				name='info.originalName'
				label={t('common.Original')}
				required
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<Box display='grid' gap='37px' gridTemplateColumns='repeat(2, 1fr)'>
				<AppSingleCheckbox
					control={control}
					name='info.familyFriendly'
					label={t('room.FamilyFriendly')}
					infoPopUp={' '}
				/>

				<AppTextField
					control={control}
					name='info.maxCapacity'
					label={t('room.MaxRoomCapacity')}
					inputType={InputType.number}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								{t('unit.PersonsInTotal')}
							</InputAdornment>
						),
					}}
					required
				/>
			</Box>

			<Box display='grid' gap='37px' gridTemplateColumns='repeat(2, 1fr)'>
				<AppTextField
					control={control}
					name='info.maxAdultCapacity'
					label={t('room.MaxAdultCapacity')}
					inputType={InputType.number}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>{t('unit.Adults')}</InputAdornment>
						),
					}}
					required
				/>

				<AppTextField
					control={control}
					name='info.maxChildCapacity'
					label={t('room.MaxChildCapacity')}
					inputType={InputType.number}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								{t('unit.Children')}
							</InputAdornment>
						),
					}}
					required
				/>
			</Box>

			<Divider sx={{ marginBottom: '15px' }} />

			<Box display='grid' gap='37px' gridTemplateColumns='repeat(2, 1fr)'>
				<AppDropDownMenu
					control={control}
					name='info.view'
					label={t('room.RoomView')}
					options={roomViewOption}
					required
				/>

				<AppRoomSizeInputField
					label={t('room.RoomSize')}
					control={control}
					name='info.size'
				/>
			</Box>

			<AppSingleCheckbox
				control={control}
				name='info.allowSmoking'
				label={t('room.SmokingRoom')}
			/>

			<AppTextField
				control={control}
				name='info.description'
				label={t('room.RoomDescriptionInTraditionalChinese')}
				placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
				multiline
				rows={3}
			/>

			<AppInputLayout
				label={t('room.UniquePolicyInTraditionalChinese')}
				labelAlignItemsFlexStart
			>
				<AppRichTextEditor control={control} name='info.policy' />
			</AppInputLayout>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppInputLayout label={t('room.BedType')} infoPopUp=' ' />

			<BedConfigsField control={control} name='info.bedConfigs' />

			<AppSwitch
				control={control}
				name='info.allowSharedBed'
				label={t('room.EnableShareBed')}
			/>

			{watchAllowSharedBed && (
				<>
					<AppInputLayout
						label={t('room.FitForAdultsShareWith')}
						errorMessage={
							(get(errors, 'info.atLeastOneChecked') as FieldError)?.message
						}
						required
					>
						<Stack direction='row'>
							<Controller
								name='info.infantSharedBed'
								control={control}
								render={({ field }) => (
									<StyledFormControlLabel
										key={'info.infantSharedBed'}
										control={
											<AppCheckbox
												checked={field.value}
												{...field}
												ref={null}
											/>
										}
										label={t('common.Infants')}
									/>
								)}
							/>

							<Controller
								name='info.toddlerSharedBed'
								control={control}
								render={({ field }) => (
									<StyledFormControlLabel
										key={'info.toddlerSharedBed'}
										control={
											<AppCheckbox
												checked={field.value}
												{...field}
												ref={null}
											/>
										}
										label={t('common.Toddlers')}
									/>
								)}
							/>

							<Controller
								name='info.childSharedBed'
								control={control}
								render={({ field }) => (
									<StyledFormControlLabel
										key={'info.childSharedBed'}
										control={
											<AppCheckbox
												checked={field.value}
												{...field}
												ref={null}
											/>
										}
										label={t('common.Children')}
									/>
								)}
							/>

							<Controller
								name='info.adultSharedBed'
								control={control}
								render={({ field }) => (
									<StyledFormControlLabel
										key={'info.adultSharedBed'}
										control={
											<AppCheckbox
												checked={field.value}
												{...field}
												ref={null}
											/>
										}
										label={t('common.Adults')}
									/>
								)}
							/>
						</Stack>
					</AppInputLayout>

					<AppSingleCheckbox
						control={control}
						name='info.sharedBedExtraCost'
						label={t('room.ExtraCostIsRequired')}
					/>
				</>
			)}

			<Divider sx={{ marginBottom: '15px' }} />

			<AppInputLayout
				removeErrorPadding
				label={t('room.CustomTags')}
				infoPopUp={' '}
			>
				<NewRoomTags control={control} name='info.tags' />
			</AppInputLayout>

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);
	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetRoomLoading && form}</>;
}

import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useState } from 'react';
import AdmissionDetailsView from './AdmissionDetailsView';
import AdmissionDetailsForm from './AdmissionDetailsForm';

interface AdmissionDetailsCardProps {
	initialType: FormCardType;
	placeType: number;
}

export default function AdmissionDetailsCard({ initialType, placeType }: AdmissionDetailsCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<FormCard type={type} title={placeType==0? t('place.AdmissionDetails'): t('place.RestaurantOperationsDetails')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <AdmissionDetailsView setType={setType} />;
					case FormCardType.create:
						return <AdmissionDetailsForm type={type} setType={setType} placeType={placeType}/>;
					case FormCardType.edit:
						return <AdmissionDetailsForm type={type} setType={setType} placeType={placeType}/>;
				}
			})()}
		</FormCard>
	);
}

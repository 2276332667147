import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { TextFieldProps, Stack, styled, Typography } from '@mui/material';
import { StyledTextField } from '../styled/StyledTextField';
import AppSubtitleLayout from '../form-layout/AppSubtitleLayout';
import { ReactNode } from 'react';

export enum InputType {
	normal,
	number,
}

interface AppTextFieldProps {
	label?: string;
	inputType?: InputType;
	infoPopUp?: ReactNode;
}

export default function AppFaqTextField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	inputType,
	required,
	disabled,
	placeholder,
	InputProps,
	multiline,
	rows,
	inputProps,
	infoPopUp,
	...props
}: AppTextFieldProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;
	const regex = /^[0-9\b]+$/;

	return (
		<>
			<AppSubtitleLayout label={label} />
			<Stack sx={{ width: '100%' }}>
				<StyledTextField
					{...field}
					value={field.value ?? ''}
					onChange={(e) => {
						if (inputType === InputType.number) {
							// extract number from string
							field.onChange(e.target.value.replace(/[^0-9]/g, ''));
						} else field.onChange(e.target.value);
					}}
					inputProps={inputProps}
					multiline={multiline}
					rows={rows}
					disabled={disabled}
					placeholder={placeholder}
					InputProps={InputProps}
					error={!!errorMessage}
				/>
				<Error>{errorMessage ?? '\n'}</Error>
			</Stack>
		</>
	);
}

const Error = styled(Typography)(({ theme }) => ({
	minHeight: '15px',
	paddingTop: '2px',
	fontSize: '10px',
	lineHeight: '10px',
	color: theme.palette.error.main,
	whiteSpace: 'pre-line',
}));

import FormCard, {
	FormCardType,
} from '../../../common/components/card/FormCard';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { RateModel, rateModelList } from '../../../api/enum/rateModel.enum';
import RateModelToggleButton from '../../../common/components/RateModelToggleButton';
import {
	Box,
	Button,
	Paper,
	Stack,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import AppAlert from '../../../common/components/AppAlert';
import {
	useGetPropertyQuery,
	useLazyGetRoomRateConfigListQuery,
	useUpdateRoomRateConfigListMutation,
} from '../../../api/accommodationApiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import AppDropDownMenu from '../../../common/components/form-inputs/AppDropDownMenu';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RoomRateConfigList } from '../../../api/DTO/room.interface';
import { RoomClass, roomClassOption } from '../../../api/enum/roomClass.enum';
import AppCheckbox from '../../../common/components/form-inputs/AppCheckbox';
import NonEditableDisplayText from '../../../common/components/form-data-display/NonEditableDisplayText';
import {
	StyledTable,
	StyledTableBody,
	StyledTableCell,
	StyledTableHead,
} from '../../../common/components/styled/StyledLightBlueTable';
import { useAppDispatch } from '../../../redux-hooks';
import {
	SnackbarType,
	showSnackbar,
} from '../../../common/components/snackbar/snackbarSlice';

export default function SelectRoomClassPage() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { propertyId } = useParams();

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');
	const [rateModel, setRateModel] = useState<RateModel>(
		getPropertyResponse?.data?.supplier.rateModels?.includes(RateModel.AGENCY)
			? RateModel.AGENCY
			: getPropertyResponse?.data?.supplier.rateModels?.[0] ?? RateModel.AGENCY
	);

	const [roomRateConfigList, setRoomRateConfigList] = useState<
		RoomRateConfigList | undefined
	>(undefined);
	const [getRoomRateConfigList] = useLazyGetRoomRateConfigListQuery();

	useEffect(() => {
		const fetchData = async () => {
			const response = await getRoomRateConfigList({
				propertyId: propertyId ?? '',
				rateModel: rateModel,
			});
			setRoomRateConfigList(response.data?.data);
			reset(response.data?.data);
		};
		fetchData();
	}, [rateModel]);

	const { handleSubmit, control, watch, setValue, reset } =
		useForm<RoomRateConfigList>();

	// view and logic

	function isBasicRoom(roomId: string): boolean {
		return watch('basicRoomId') === roomId;
	}

	const hasBasicRoom: boolean =
		watch('basicRoomId') !== undefined && watch('basicRoomId') !== null;

	const handleBasicRoomCheckboxChange = (
		index: number,
		roomId: string,
		checked: boolean
	) => {
		if (checked) {
			setValue('basicRoomId', roomId);
			setValue(`rooms.${index}.roomClass`, RoomClass.INDEPENDENT);
		} else {
			setValue('basicRoomId', undefined);
			roomRateConfigList?.rooms.forEach((_, index) => {
				setValue(`rooms.${index}.roomClass`, RoomClass.INDEPENDENT);
			});
		}
	};

	// submit

	const [updateRoomRateConfigList] = useUpdateRoomRateConfigListMutation();

	const onSubmit: SubmitHandler<RoomRateConfigList> = (data) => {
		const update = async () => {
			await updateRoomRateConfigList({
				propertyId: propertyId ?? '',
				rateModel: rateModel,
				body: data,
			});

			dispatch(
				showSnackbar({
					snackbarType: SnackbarType.SAVE_SUCCESS,
					snackbarProps: {
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'center',
						},
						message: t('snackbar.SaveSuccessMessage'),
					},
				})
			);
		};

		update();
	};

	return (
		<FormCard type={FormCardType.edit} title={t('room.RoomRelationship')}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box display={'flex'} justifyContent={'end'}>
					<RateModelToggleButton
						value={rateModel}
						handleChange={(_, newRateModel) => {
							if (newRateModel != null) setRateModel(newRateModel);
						}}
						disableRateModel={rateModelList.filter(
							(rateModel) =>
								!getPropertyResponse?.data.supplier.rateModels?.includes(
									rateModel
								)
						)}
					/>
				</Box>

				<AppAlert alertColor='#808080'>
					<Typography fontSize={'10px'} whiteSpace={'pre-wrap'}>
						You may setup the rate structure with room supplement by selecting a
						<b> Basic Room</b> and choose the
						<br />
						<b> Room Relationship</b> status to Supplement instead of
						Independent. After<b> Save</b>, supplement rate can be
						<br />
						edited under the tap of Rates & Inventory on the above navigation
						bar.
					</Typography>
				</AppAlert>

				<TableContainer
					component={Paper}
					sx={{
						marginTop: '13px',
						borderRadius: '5px',
						boxShadow: 'none',
						border: '1px solid #32A5F1',
					}}
				>
					<StyledTable>
						<StyledTableHead>
							<TableRow>
								<TableCell width={'240px'}>{t('room.RoomType')}</TableCell>
								<TableCell width={'100px'} sx={{ textAlign: 'center' }}>
									{t('room.BasicRoom')}
								</TableCell>
								<TableCell width={'180px'} sx={{ textAlign: 'center' }}>{`${t(
									'room.RoomRelationship'
								)}`}</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</StyledTableHead>
						<StyledTableBody>
							{roomRateConfigList?.rooms.map((room, index) => (
								<TableRow key={room.id}>
									<StyledTableCell component='th' scope='row'>
										<NonEditableDisplayText
											text={`${room.nameEn}\n${room.name}`}
										/>
									</StyledTableCell>
									<StyledTableCell sx={{ textAlign: 'center' }}>
										<AppCheckbox
											checked={isBasicRoom(room.id)}
											onChange={(_, checked) =>
												handleBasicRoomCheckboxChange(index, room.id, checked)
											}
											disabled={hasBasicRoom ? !isBasicRoom(room.id) : false}
										/>
									</StyledTableCell>
									<StyledTableCell sx={{ textAlign: 'center' }}>
										{isBasicRoom(room.id) ? (
											<NonEditableDisplayText text={t('room.BasicRoom')} />
										) : (
											<AppDropDownMenu
												control={control}
												name={`rooms.${index}.roomClass`}
												options={roomClassOption}
												disabled={!watch('basicRoomId')}
												removeErrorPadding={true}
											/>
										)}
									</StyledTableCell>
								</TableRow>
							))}
						</StyledTableBody>
					</StyledTable>
				</TableContainer>

				<Stack direction='row-reverse' spacing='12px' marginTop={'20px'}>
					<Button type='submit' variant='contained' color='success'>
						{t('button.Save')}
					</Button>
					<Button
						variant='outlined'
						color='info'
						onClick={() => {
							navigate(`/property/${propertyId}/room`);
						}}
					>
						{t('button.Discard')}
					</Button>
				</Stack>
			</form>
		</FormCard>
	);
}

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Stack } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Transportation } from '../../../../api/DTO/transportation.interface';
import {
	useGetTransportationQuery,
	useUpdateTransportationMutation,
} from '../../../../api/accommodationApiSlice';
import { FormCardType } from '../../../../common/components/card/FormCard';
import MultipleImagesWithTitleAndDesField from '../components/MultipleImagesWithTitleAndDesField';

interface TransportationFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function TransportationForm({
	type,
	setType,
}: TransportationFormProps) {
	const { t } = useTranslation();
	const { propertyId } = useParams();

	const formSchema = Yup.object().shape({
		transportation: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().required(t('errorMessage.required')),
			})
		),
	});

	const {
		data: getTransportationResponse,
		isLoading: isGetTransportationLoading,
	} = useGetTransportationQuery(propertyId ?? '');

	const { handleSubmit, control } = useForm<Transportation>({
		defaultValues: { transportation: getTransportationResponse?.data },
		resolver: yupResolver(formSchema),
	});

	const [updateTransportation, { isSuccess: isUpdateTransportationSuccess }] =
		useUpdateTransportationMutation();

	useEffect(() => {
		if (isUpdateTransportationSuccess) {
			setType(FormCardType.view);
		}
	}, [isUpdateTransportationSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateTransportation({
			propertyId: propertyId ?? '',
			body: data.transportation,
		});
	};

	const formContent = (
		<Stack spacing={'15px'}>
			<MultipleImagesWithTitleAndDesField
				control={control}
				name='transportation'
			/>
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form = <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;

	return <>{!isGetTransportationLoading && form}</>;
}

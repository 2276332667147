import { Box, styled } from '@mui/material';

interface RoomTypeLabelProps {
	label: string;
	textColor: string;
}

export default function RoomTypeLabel({
	label,
	textColor,
}: RoomTypeLabelProps) {
	return <StyledLabel color={textColor}>{label}</StyledLabel>;
}

const StyledLabel = styled(Box)({
	fontSize: '12px',
	fontWeight: 700,
	background: 'white',
	padding: '5px 10px',
	borderRadius: '5px',
});

import Option from '../../common/types/option.type';

export enum RoomView {
	NO_VIEW = 0,
	STREET_VIEW = 1,
	CITY_VIEW = 2,
	GARDEN_VIEW = 3,
	POOL_VIEW = 4,
	LAKE_VIEW = 5,
	MOUNTAIN_VIEW = 6,
	PARTIAL_VIEW = 7,
	SEA_VIEW = 8,
	HARBOUR_VIEW = 9,
}

export const roomViewOption: Option[] = [
	{
		id: RoomView.NO_VIEW,
		name: 'No View',
	},
	{
		id: RoomView.STREET_VIEW,
		name: 'Street View',
	},
	{
		id: RoomView.CITY_VIEW,
		name: 'City View',
	},
	{
		id: RoomView.GARDEN_VIEW,
		name: 'Garden View',
	},
	{
		id: RoomView.POOL_VIEW,
		name: 'Pool View',
	},
	{
		id: RoomView.LAKE_VIEW,
		name: 'Lake View',
	},
	{
		id: RoomView.MOUNTAIN_VIEW,
		name: 'Mountain View',
	},
	{
		id: RoomView.PARTIAL_VIEW,
		name: 'Partial View',
	},
	{
		id: RoomView.SEA_VIEW,
		name: 'Sea View',
	},
	{
		id: RoomView.HARBOUR_VIEW,
		name: 'Harbour View',
	},
];

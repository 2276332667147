import { Box, Button, Grid, InputAdornment, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	Controller,
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';
import { FormCardType } from '../../../../common/components/card/FormCard';
import {
	useGetPropertyQuery,
	useUpdatePropertyPolicyMutation,
} from '../../../../api/accommodationApiSlice';
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from '../../../../utils/yup-extended';
import { PropertyIdContext } from '../ViewPropertyPage';
import { NONE_INVALID_DATE_PATTERN } from '../../../../common/constants/pattern';
import AppTimePicker from '../../../../common/components/form-inputs/AppTimePicker';
import { StyledFormControlLabel } from '../../../../common/components/form-inputs/AppMultipleCheckbox';
import AppCheckbox from '../../../../common/components/form-inputs/AppCheckbox';
import AppInputLayoutSingleCheckbox from '../../../../common/components/form-inputs/AppInputLayoutSingleCheckBox';
import { Property } from '../../../../api/DTO/property.interface';
import AppAgeRangeTextField from '../../../../common/components/form-inputs/AppAgeRangeTextField';
import AppRichTextEditor from '../../../../common/components/editor/AppRichTextEditor';

interface PolicyFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function PolicyForm({ type, setType }: PolicyFormProps) {
	const { t } = useTranslation();
	const propertyId = useContext(PropertyIdContext);

	const [isPrefilled, setIsPrefilled] = useState<boolean>(false);

	const defaultValues: DefaultValues<Property> = {
		policy: {
			minCheckInTime: undefined,
			maxCheckInTime: undefined,
			checkOutTime: undefined,
			minCheckInAge: '18',
			uniquePolicy: '',
			allowInfant: true,
			allowToddler: true,
			allowChildren: true,
			infantAge: undefined,
			toddlerAge: undefined,
			childAge: undefined,
			allowSmoking: false,
		},
	};

	const formSchema = Yup.object().shape({
		policy: Yup.object().shape({
			minCheckInTime: Yup.string()
				.required(t('errorMessage.required'))
				.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
			maxCheckInTime: Yup.string()
				.required(t('errorMessage.required'))
				.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
			checkOutTime: Yup.string()
				.required(t('errorMessage.required'))
				.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
			minCheckInAge: Yup.string().required(t('errorMessage.required')),
			infantAge: Yup.object().nullable().validateAgeRange(' '),
			toddlerAge: Yup.object().nullable().validateAgeRange(' '),
			childAge: Yup.object().requireAgeRange(t('errorMessage.required')),
		}),
	});

	const { data: getPropertyResponse, isLoading: isGetPropertyLoading } =
		useGetPropertyQuery(propertyId, {
			skip: type === FormCardType.create,
		});

	useEffect(() => {
		if (type === FormCardType.edit) {
			reset({
				policy: getPropertyResponse?.data.policy,
			});
			setIsPrefilled(true);
		}
	}, [getPropertyResponse]);

	const { handleSubmit, control, reset } =
		useFormContext() ??
		useForm<Property>({
			defaultValues,
			resolver: yupResolver(formSchema),
		});

	const [updatePropertyPolicy, { isSuccess: isUpdatePropertyPolicySuccess }] =
		useUpdatePropertyPolicyMutation();

	useEffect(() => {
		if (isUpdatePropertyPolicySuccess) setType(FormCardType.view);
	}, [isUpdatePropertyPolicySuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updatePropertyPolicy({
			propertyId: propertyId ?? '',
			body: data.policy,
		});
	};

	const formContent = (
		<Stack>
			<AppInputLayout
				removeErrorPadding
				label={t('property.CheckInTime')}
				required
			>
				<Box display='grid' gap='10px' gridTemplateColumns='repeat(2, 1fr)'>
					<AppTimePicker
						control={control}
						name='policy.minCheckInTime'
						prefix={t('common.From')}
						placeholder='14:00'
					/>
					<AppTimePicker
						control={control}
						name='policy.maxCheckInTime'
						prefix={t('common.To')}
						placeholder='23:59'
					/>
				</Box>
			</AppInputLayout>

			<AppInputLayout
				removeErrorPadding
				label={t('property.CheckOutTime')}
				required
			>
				<Box display='grid' gap='10px' gridTemplateColumns='repeat(2, 1fr)'>
					<AppTimePicker
						control={control}
						name='policy.checkOutTime'
						prefix={t('common.Until')}
						placeholder='11:00'
					/>
				</Box>
			</AppInputLayout>

			<AppInputLayout
				removeErrorPadding
				label={t('property.MinCheckinAge')}
				required
			>
				<Box display='grid' gap='10px' gridTemplateColumns='repeat(2, 1fr)'>
					<AppTextField
						control={control}
						name='policy.minCheckInAge'
						inputType={InputType.number}
						inputProps={{ maxLength: 3 }}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									{t('common.From')}
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position='end'>
									{t('common.YearsOld')}
								</InputAdornment>
							),
						}}
					/>
				</Box>
			</AppInputLayout>

			<AppInputLayout
				label={t('property.UniquePolicyInChi')}
				labelAlignItemsFlexStart
			>
				{(type === FormCardType.edit ? isPrefilled : true) && (
					<AppRichTextEditor control={control} name='policy.uniquePolicy' />
				)}
			</AppInputLayout>

			<AppInputLayout
				removeErrorPadding
				label={t('property.WhoAreAllowedToStay')}
				infoPopUp=' '
			>
				<Stack direction='row'>
					<Controller
						name='policy.allowInfant'
						control={control}
						render={({ field }) => (
							<StyledFormControlLabel
								key={'policy.allowInfant'}
								control={
									<AppCheckbox checked={field.value} {...field} ref={null} />
								}
								label={t('common.Infants')}
							/>
						)}
					/>

					<Controller
						name='policy.allowToddler'
						control={control}
						render={({ field }) => (
							<StyledFormControlLabel
								key={'policy.allowToddler'}
								control={
									<AppCheckbox checked={field.value} {...field} ref={null} />
								}
								label={t('common.Toddlers')}
							/>
						)}
					/>

					<Controller
						name='policy.allowChildren'
						control={control}
						render={({ field }) => (
							<StyledFormControlLabel
								key={'policy.allowChildren'}
								control={
									<AppCheckbox checked={field.value} {...field} ref={null} />
								}
								label={t('common.Children')}
							/>
						)}
					/>
				</Stack>
			</AppInputLayout>

			<AppAgeRangeTextField
				label={t('property.AgeofInfants')}
				control={control}
				name='policy.infantAge'
			/>

			<AppAgeRangeTextField
				label={t('property.AgeofToddlers')}
				control={control}
				name='policy.toddlerAge'
			/>

			<AppAgeRangeTextField
				label={t('property.AgeofChildren')}
				control={control}
				name='policy.childAge'
				required
			/>

			<AppInputLayoutSingleCheckbox
				control={control}
				name='policy.allowSmoking'
				label={t('property.SmokingIsAllowedInPublicArea')}
			/>
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetPropertyLoading && form}</>;
}

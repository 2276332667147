import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	FormControl,
	InputAdornment,
	styled,
	TextField,
	TextFieldProps,
} from '@mui/material';
import AppInputLabel from './archive/AppInputLabel';
import { ReactComponent as EmailIcon } from '../../../assets/icon/email.svg';

const StyledTextField = styled(TextField)(({ theme }) => ({
	'label + &': {
		marginTop: '28px',
	},

	'& .MuiOutlinedInput-root': {
		padding: '12px',

		'& fieldset': {
			borderColor: '#BCBCBC',
			borderRadius: '5px',
		},
		'&:hover fieldset': {
			borderWidth: '1px',
			borderColor: '#BCBCBC',
		},
		'&.Mui-focused fieldset': {
			borderWidth: '1px',
			borderColor: theme.palette.primary.main,
		},
		'&.Mui-error fieldset': {
			borderWidth: '1px',
			borderColor: theme.palette.error.main,
		},
		'&.Mui-disabled fieldset': {
			borderColor: '#F5F5F5',
		},
		'&.Mui-disabled': {
			background: '#F5F5F5',
		},
		'.Mui-disabled': {
			WebkitTextFillColor: '#808080',
		},
	},
	'& .MuiInputBase-input': {
		fontSize: 12,
		height: '12px',
		padding: '0px',

		'&::placeholder': {
			color: '#808080',
			opacity: 1,
		},
	},
}));

interface AppEmailTextFieldProps {
	label: string;
}

export default function AppEmailTextField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	disabled,
	placeholder,
	required,
	...props
}: AppEmailTextFieldProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<FormControl fullWidth={true} variant='standard' error={!!errorMessage}>
			<AppInputLabel
				label={label}
				errorMessage={errorMessage}
				required={required}
			/>
			<StyledTextField
				{...field}
				disabled={disabled}
				placeholder={placeholder}
				error={!!errorMessage}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<EmailIcon />
						</InputAdornment>
					),
				}}
			/>
		</FormControl>
	);
}

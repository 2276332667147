import { useTranslation } from 'react-i18next';
import { TanglePeek } from '../../../../api/DTO/propertyImport.interface';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import SearchResultForm from './SearchResultForm';

interface SearchResultCardProps {
	initialType: FormCardType;
	searchResult: TanglePeek;
}

export default function SearchResultCard({
	initialType,
	searchResult,
}: SearchResultCardProps) {
	const { t } = useTranslation();

	return (
		<FormCard
			type={initialType}
			title={t('property.propertyImport.SearchResult')}
		>
			<SearchResultForm searchResult={searchResult} />
		</FormCard>
	);
}

import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { Bed, bedOption } from '../../../api/enum/bed.enum';
import AppAlert from '../../../common/components/AppAlert';
import BedTypeCounter from '../../../common/components/counter/BedTypeCounter';

interface AbkSingleBedConfigProps {
	bedConfigValue: any;
	onChange: (value: any) => void;
	errorMsg?: string;
}

export default function AbkSingleBedConfig({
	bedConfigValue,
	onChange,
	errorMsg,
}: AbkSingleBedConfigProps) {
	const handleChange = (bedType: Bed, value: number) => {
		const onChangeBeddings = bedConfigValue;
		onChangeBeddings[bedType] = value === 0 ? undefined : value;

		onChange(onChangeBeddings);
	};

	function isValidBeddings(): boolean {
		let isValid = false;
		bedOption.forEach((option) => {
			const count = bedConfigValue[option.id as Bed];
			if (count && !isNaN(Number(count)) && Number(count) > 0) {
				isValid = true;
				return;
			}
		});
		return isValid;
	}

	return (
		<>
			<BedOptionContainer>
				<Stack direction={'row'} alignItems={'center'} marginBottom={'15px'}>
					{errorMsg && !isValidBeddings() && (
						<Box>
							<AppAlert alertColor='#F6403F'>{errorMsg}</AppAlert>
						</Box>
					)}
				</Stack>

				<Grid
					container
					flexGrow={1}
					alignItems='stretch'
					columnSpacing={'30px'}
					rowSpacing={'5px'}
				>
					{bedOption.map((item) => (
						<Grid key={item.id} item xs={6}>
							<Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
								<Typography
									minWidth={'130px'}
									fontSize={'12px'}
									color={'#808080'}
								>
									{item.shortName}
									<br />
									{item.remark}
								</Typography>
								<BedTypeCounter
									errorBorder={!!(errorMsg && !isValidBeddings())}
									value={bedConfigValue[item.id as Bed]}
									onChange={(value: number) => handleChange(item.id, value)}
									noSelectedColor={true}
								/>
							</Box>
						</Grid>
					))}
				</Grid>
			</BedOptionContainer>
		</>
	);
}

const BedOptionContainer = styled(Box)(({ theme }) => ({
	position: 'relative',
	gap: '20px',
	padding: '15px 20px 20px 20px',
	borderRadius: '10px',
}));

import { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyGetRatePlanQuery } from '../../../../api/accommodationApiSlice';
import { Grid, Stack } from '@mui/material';
import { FormCardType } from '../../../../common/components/card/FormCard';
import FullScreenLoader from '../../../../common/components/FullScreenLoader';
import RatePlanBaseInfoCard from './baseInfo/RatePlanBaseInfoCard';
import RatePlanInclusionCard from './inclusion/RatePlanInclusionCard';
import RatePlanPaymentAndPolicyCard from './paymentAndPolicy/RatePlanPaymentAndPolicyCard';

export const RatePlanIdContext = createContext('');

export default function RatePlanDetailPage() {
	const { ratePlanId } = useParams();
	const [, { isLoading: isGetRatePlanLoading }] = useLazyGetRatePlanQuery();
	let pageContent;

	if (isGetRatePlanLoading) {
		pageContent = <FullScreenLoader />;
	} else {
		pageContent = (
			<>
				<RatePlanIdContext.Provider value={ratePlanId ?? ''}>
					<Stack spacing={'16px'} display={'flex flex-row'}>
						<Grid container alignItems='stretch' spacing={'10px'}>
							<Grid item xs={6} display='flex'>
								<RatePlanBaseInfoCard initialType={FormCardType.view} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<RatePlanPaymentAndPolicyCard initialType={FormCardType.view} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<RatePlanInclusionCard initialType={FormCardType.view} />
							</Grid>
							<Grid item xs={6} display='flex'></Grid>
						</Grid>
					</Stack>
				</RatePlanIdContext.Provider>
			</>
		);
	}

	return <div>{pageContent}</div>;
}

import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AbkGuest } from '../../../api/DTO/abk.interface';
import { AbkGender, AbkGenderOptions } from '../../../api/enum/abkGender.enum';
import { abkGuestTypeOptions } from '../../../api/enum/abkGuestType.enum';
import AppDropDownMenu from '../../../common/components/form-inputs/AppDropDownMenu';
import AppFieldArrayCounter from '../../../common/components/form-inputs/AppFieldArrayCounter';
import AppTextField, {
	InputType,
} from '../../../common/components/form-inputs/AppTextField';

interface AbkGuestInfoCardProps {
	index: number;
	control: any;
	label?: string;
}

export default function AbkGuestInfoCard({
	index,
	control,
	label,
}: AbkGuestInfoCardProps) {
	const { t } = useTranslation();

	const watchGuest = useWatch({ name: `rate.rooms.${index}.guests` });

	const {
		watch,
		setValue,
		formState: { isSubmitted },
		trigger,
	} = useFormContext();

	const {
		fields: guestsFields,
		append: guestsAppend,
		remove: guestsRemove,
	} = useFieldArray({
		control,
		name: `rate.rooms.${index}.guests`,
	});

	// clear guest age if guest is adult

	useEffect(() => {
		watchGuest.map((item: AbkGuest, guestIndex: number) => {
			if (
				item.adult &&
				watch(`rate.rooms.${index}.guests.${guestIndex}.age`) !== null
			) {
				setValue(`rate.rooms.${index}.guests.${guestIndex}.age`, null);
			}
			if (isSubmitted) {
				trigger(`rate.rooms.${index}.guests.${guestIndex}.age`);
			}
		});
	}, [watchGuest]);

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					gap: '5px',
					alignItems: 'center',
					marginBottom: '15px',
				}}
			>
				<Typography minWidth={'215px'} fontSize={'12px'} color={'#808080'}>
					{label}
				</Typography>
				<AppFieldArrayCounter
					value={guestsFields.length ?? 0}
					greaterZero
					onChange={(value: number) => {
						// if (value > guestsFields.length) {
						// 	const test = guestsFields.length;
						// 	for (let i = 0; i <= value; i++) {
						// 		guestsRemove(test - i);
						// 	}
						// 	for (let i = 1; i <= value; i++) {
						// 		guestsAppend({
						// 			name: undefined,
						// 			adult: true,
						// 			age: undefined,
						// 		});
						// 	}
						// } else if (value < guestsFields.length) {
						// 	const test = guestsFields.length;
						// 	for (let i = 0; i <= test - value; i++) {
						// 		guestsRemove(test - i);
						// 	}
						// }
					}}
					append={() =>
						guestsAppend({
							firstName: undefined,
							lastName: undefined,
							adult: true,
							age: undefined,
							gender: AbkGender.MALE,
						})
					}
					remove={(value) => guestsRemove(value)}
					noSelectedColor={true}
				/>
			</Box>

			{guestsFields.map((item, guestIndex) => {
				return (
					<Box
						sx={{
							display: 'flex',
							gap: '5px',
							alignItems: 'flex-start',
							width: '100%',
						}}
						key={item.id}
					>
						<Typography minWidth={'215px'} fontSize={'12px'} color={'#808080'}>
							{`${t('abk.Guest')} ${guestIndex + 1}`}
						</Typography>
						<Box width={'100%'}>
							<Grid container alignItems='stretch' spacing={'5px'}>
								<Grid
									item
									xs={12}
									display='flex'
									flexDirection={'row'}
									gap={'5px'}
								>
									<Grid item xs={6}>
										<AppTextField
											control={control}
											name={`rate.rooms.${index}.guests.${guestIndex}.firstName`}
											placeholder={t('abk.FirstName')}
											inputType={InputType.english}
										/>
									</Grid>
									<Grid item xs={6}>
										<AppTextField
											control={control}
											name={`rate.rooms.${index}.guests.${guestIndex}.lastName`}
											placeholder={t('abk.LastName')}
											inputType={InputType.lastName}
										/>
									</Grid>
								</Grid>
								<Grid item xs={4} display='flex' flexDirection={'column'}>
									<AppDropDownMenu
										control={control}
										name={`rate.rooms.${index}.guests.${guestIndex}.gender`}
										options={AbkGenderOptions}
									/>
								</Grid>
								<Grid item xs={4} display='flex' flexDirection={'column'}>
									<AppDropDownMenu
										control={control}
										name={`rate.rooms.${index}.guests.${guestIndex}.adult`}
										options={abkGuestTypeOptions}
									/>
								</Grid>
								<Grid item xs={4} display='flex' flexDirection={'column'}>
									<AppTextField
										control={control}
										disabled={watchGuest?.[guestIndex]?.adult ?? false}
										name={`rate.rooms.${index}.guests.${guestIndex}.age`}
										inputType={InputType.number}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													{t('common.YearsOld')}
												</InputAdornment>
											),
										}}
									/>
								</Grid>
							</Grid>
						</Box>
					</Box>
				);
			})}
		</>
	);
}

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { ReactNode, useCallback, useState } from 'react';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { propertyCategoryOptions } from '../../../api/enum/propertyCategories.enum';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/delete.svg';
import { ReactComponent as SuppplierMeituanIcon } from '../../../assets/icon/supplier-meituan.svg';
import { ReactComponent as SuppplierMyfamigoIcon } from '../../../assets/icon/supplier-myfamigo.svg';
import { ReactComponent as SuppplierRakutenIcon } from '../../../assets/icon/supplier-rakuten.svg';
import LocalDataGrid from '../../../common/components/dataGrid/LocalDataGrid';
import { PropertyDataGrid } from '../../../pages/property/PropertySummaryPage';
import { DISPLAY_DATE_FORMAT } from '../../../utils/dateHelper';
import { getPropertyStatusDisplay } from '../../../utils/propertyHelper';
import { PropertySummaryItem } from '../../interface/propertySummaryItem.interface';
import AppStatusDisplay from '../AppStatusDisplay';
import CountryFlag from '../form-data-display/CountryFlag';
import AppInputLayout from '../form-layout/AppInputLayout';
import SelectPropertiesModal from '../modal/SelectPropertiesModal';

interface AppSelectPropertiesProps {
	label?: string;
	infoPopUp?: ReactNode;
	removeErrorPadding?: boolean;
	required?: boolean;
}

export default function AppSelectProperties<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	infoPopUp,
	required,
	removeErrorPadding,
	...props
}: AppSelectPropertiesProps & UseControllerProps<TFieldValues, TName>) {
	const { t } = useTranslation();
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	const selectedProperties: PropertySummaryItem[] = field.value ?? [];

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (selectedProperties?: PropertySummaryItem[]) => {
		setOpen(false);
		if (selectedProperties) {
			field.onChange(selectedProperties);
		}
	};

	// selected properties data grid

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: t('property.Id'),
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
		},
		{
			field: 'name',
			headerName: t('property.Name'),
			flex: 1,
			minWidth: 150,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
							fontSize: '0.75rem',
						}}
						variant='regular'
					>
						{params.row.nameEn}
					</Typography>
					<Typography
						sx={{
							display: 'block',
							fontSize: '0.625rem',
						}}
						variant='regular'
					>
						{params.row.name}
					</Typography>
				</Box>
			),
		},
		{
			field: 'status',
			headerName: t('property.Status'),
			width: 80, // 10(cell padding left) + 60(status fixed width) + 10(cell padding right)
			sortable: false,
			renderCell: (params) => (
				<AppStatusDisplay
					name={getPropertyStatusDisplay(params.row.status.status).name}
					color={getPropertyStatusDisplay(params.row.status.status).color}
					bgcolor={
						getPropertyStatusDisplay(params.row.status.status).backgroundColor
					}
				/>
			),
		},
		{
			field: 'majorArea',
			headerName: t('property.MajorArea'),
			flex: 1,
			minWidth: 95,
			sortable: false,
			renderCell: (params) => (
				<Stack direction={'row'} alignItems={'center'}>
					<CountryFlag
						iso2={params.row.majorArea.countryCode as string}
						size='medium'
						style={{ marginRight: '8px' }}
					/>
					<Typography
						variant='regular'
						sx={{
							fontSize: '12px',
							wordWrap: 'anywhere',
							whiteSpace: 'normal',
						}}
					>
						{params.row.majorArea.name}
					</Typography>
				</Stack>
			),
		},
		{
			field: 'area',
			headerName: t('property.Area'),
			flex: 1,
			sortable: false,
		},
		{
			field: 'ownerShip',
			headerName: t('property.OwnerShip'),
			flex: 1,
			sortable: false,
		},
		{
			field: 'category',
			headerName: t('property.Category'),
			flex: 1,
			sortable: false,
		},
		{
			field: 'suppliersType',
			headerName: t('property.Suppliers'),
			width: 112,
			sortable: false,
			renderCell: (params) => (
				<Stack direction={'row'} gap={'0.25rem'}>
					{params.row.suppliersType.directContract && <SuppplierMyfamigoIcon />}
					{(params.row.suppliersType.apiSuppliers as number[]).includes(0) && (
						<SuppplierRakutenIcon />
					)}
					{(params.row.suppliersType.apiSuppliers as number[]).includes(1) && (
						<SuppplierMeituanIcon />
					)}
				</Stack>
			),
		},
		{
			field: 'createdAt',
			headerName: t('property.DateCreated'),
			width: 140, // 10(cell padding left) + 120(header fixed width) + 10(cell padding right)
		},
		{
			field: 'delete',
			headerName: '',
			width: 68, // 10(cell padding left) + 34(icon button width) + 24(cell padding right)
			sortable: false,
			renderCell: (params) => (
				<IconButton
					onClick={() => {
						const objWithIdIndex = selectedProperties.findIndex(
							(item) => item.id === params.id
						);
						selectedProperties.splice(objWithIdIndex, 1);
						field.onChange(selectedProperties);
					}}
				>
					<DeleteIcon color={'#DA5150'} />
				</IconButton>
			),
		},
	];

	const transformResponse = useCallback((response?: PropertySummaryItem[]) => {
		if (response == null) return;
		const newResponse: PropertyDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.id,
				name: item.name,
				nameEn: item.nameEn,
				status: { propertyId: item.id, status: item.status },
				majorArea: { name: item.majorArea, countryCode: item.countryCode },
				area: item.area,
				ownerShip: item.owner ?? t('property.Individual'),
				category:
					propertyCategoryOptions.find((option) => option.id == item.category)
						?.name ?? '',
				suppliersType: {
					directContract: item.directContract,
					apiSuppliers: item.apiSuppliers,
				},
				createdAt: dayjs(item.createdAt).format(DISPLAY_DATE_FORMAT),
			});
		});
		return newResponse;
	}, []);

	return (
		<Stack justifyContent='flex-start' width={'100%'}>
			<AppInputLayout
				label={label}
				infoPopUp={infoPopUp}
				required={required}
				errorMessage={errorMessage}
				removeErrorPadding={removeErrorPadding}
			>
				<Button
					sx={{ width: '150px' }}
					variant={'contained'}
					onClick={handleClickOpen}
				>
					<Typography fontSize={'12px'} fontWeight={'bolder'}>
						Select Properties
					</Typography>
				</Button>
			</AppInputLayout>

			{selectedProperties.length > 0 && (
				<AppInputLayout label={' '} infoPopUp={infoPopUp}>
					<Box width={'100%'}>
						<LocalDataGrid
							rows={transformResponse(selectedProperties) ?? []}
							columns={columns}
							rowCount={selectedProperties.length ?? 0}
							getRowId={(row) => row.id}
							pagination={false}
						/>
					</Box>
				</AppInputLayout>
			)}

			{open && (
				<SelectPropertiesModal
					open={open}
					onClose={handleClose}
					selectedProperties={selectedProperties ?? []}
				/>
			)}
		</Stack>
	);
}

import { Typography } from '@mui/material';
import { useDownloadMediaMutation } from '../../../api/mediaApiSlice';

interface DocumentDisplayProps {
	fileName: string;
	fileId: string;
}

export default function DocumentDisplay({
	fileName,
	fileId,
}: DocumentDisplayProps) {
	const [downloadMedia] = useDownloadMediaMutation();

	return (
		<Typography
			fontSize='12px'
			color='#32A5F1'
			whiteSpace={'break-spaces'}
			sx={{ cursor: 'pointer', wordBreak: 'break-word' }}
			onClick={() => {
				downloadMedia([fileId]);
			}}
		>
			{fileName?.length ? fileName : '-'}
		</Typography>
	);
}

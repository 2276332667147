import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPfcategoriesQuery } from '../../../../api/accommodationApiSlice';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import PropertyFacilityForm from './PropertyFacilityForm';
import PropertyFacilityView from './PropertyFacilityView';

interface PropertyFacilityCardProps {
	initialType: FormCardType;
}

export default function PropertyFacilityCard({
	initialType,
}: PropertyFacilityCardProps) {
	const [type, setType] = useState<FormCardType>(initialType);

	const { propertyId, pfcategoryId } = useParams();

	const { data: getPfcategoriesResponse } = useGetPfcategoriesQuery(
		propertyId ?? ''
	);
	const title = getPfcategoriesResponse?.data.find(
		(category) => category.id === pfcategoryId ?? ''
	);

	return (
		<FormCard type={type} title={title?.nameEn ?? ''}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <PropertyFacilityView setType={setType} />;
					case FormCardType.edit:
						return <PropertyFacilityForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

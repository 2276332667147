import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	Checkbox,
	CheckboxProps,
	FormControlLabel,
	styled,
} from '@mui/material';
import { ReactComponent as CheckedIcon } from '../../../assets/icon/checkbox-checked.svg';
import { ReactComponent as UnCheckedIcon } from '../../../assets/icon/checkbox-unchecked.svg';

interface SingleCheckboxProps {
	label: string;
}

export default function SingleCheckbox<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	...props
}: SingleCheckboxProps &
	UseControllerProps<TFieldValues, TName> &
	CheckboxProps) {
	const { field } = useController(props);

	return (
		<StyledFormControlLabel
			key={field.value}
			control={
				<Checkbox
					{...field}
					checked={field.value}
					icon={<UnCheckedIcon />}
					checkedIcon={<CheckedIcon />}
				/>
			}
			label={label}
		/>
	);
}

const StyledFormControlLabel = styled(FormControlLabel)({
	width: '100%',
	'& .MuiFormControlLabel-label': {
		fontSize: '12px',
	},

	'& .MuiCheckbox-root': {
		padding: '0px 8px 0px 0px',
	},
});

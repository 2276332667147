import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LightningIcon } from '../../../assets/icon/lightning.svg';
import AppFormLabel from './AppFormLabel';

interface AppSplitInputLayoutProps {
	required?: boolean;
	label?: string;
	showQuickActionButton?: boolean;
	subTitle?: string;
	infoPopUp?: ReactNode;
	removeErrorPadding?: boolean;
	errorMessage?: string;
	labelAlignItemsFlexStart?: boolean;
	children1?: ReactNode;
	children2?: ReactNode;
}

const Error = styled(Typography)(({ theme }) => ({
	minHeight: '15px',
	paddingTop: '2px',
	fontSize: '10px',
	lineHeight: '10px',
	color: theme.palette.error.main,
	whiteSpace: 'pre-line',
}));

export default function AppSplitInputLayout({
	required,
	label,
	showQuickActionButton,
	subTitle,
	infoPopUp,
	removeErrorPadding,
	errorMessage,
	labelAlignItemsFlexStart,
	children1,
	children2,
	...props
}: React.PropsWithChildren<AppSplitInputLayoutProps>) {
	const alignItems = labelAlignItemsFlexStart ? 'flex-start' : 'center';
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				display: 'flex',
				gap: '20px',
				alignItems: { alignItems },
				width: '100%',
			}}
		>
			{label && (
				<Box display={'flex'} flexDirection={'column'}>
					<AppFormLabel
						required={required}
						label={label}
						infoPopUp={infoPopUp}
					/>
					{showQuickActionButton && (
						<>
							<Button
								variant='outlined'
								color='primary'
								onClick={() => {
									console.log('click');
								}}
								sx={{ gap: '5px', width: '100px', fontSize: '10px' }}
							>
								<LightningIcon width={'10px'} />
								{t('button.QuickAction')}
							</Button>
						</>
					)}
				</Box>
			)}
			{subTitle && (
				<Typography
					fontSize={12}
					fontWeight={700}
					minWidth={'160px'}
					marginBottom={'15px'}
					whiteSpace={'pre-wrap'}
				>
					{subTitle}
				</Typography>
			)}
			<Stack sx={{ width: '100%', minWidth: 0 }}>
				<Grid container alignItems='stretch' spacing={''}>
					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						paddingX={'10px'}
						paddingY={'3px'}
						marginY={'-3px'}
						borderRight={'1px solid #E4E8EA'}
					>
						{children1}
					</Grid>
					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						paddingX={'10px'}
					>
						{children2}
					</Grid>
				</Grid>
			</Stack>
		</Box>
	);
}

import Option from '../../common/types/option.type';

export enum RatePlanChannel {
	DIRECT = 0,
	APISUPPLIER = 1,
}

export function getRatePlanChannel(ratePlanChannel?: RatePlanChannel): string {
	switch (ratePlanChannel) {
		case RatePlanChannel.DIRECT:
			return 'Direct Contact';
		case RatePlanChannel.APISUPPLIER:
			return 'API Supplier';
		default:
			return '';
	}
}

export const ratePlanChannelOptions: Option[] = [
	{
		id: RatePlanChannel.DIRECT,
		name: 'Direct Contact',
	},
	{
		id: RatePlanChannel.APISUPPLIER,
		name: 'API Supplier',
	},
];

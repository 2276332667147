import { Button, styled } from '@mui/material';
import { ReactComponent as BannedIcon } from '../../../assets/icon/banned.svg';

interface BannedIconButtonProps {
	label?: string;
}

export default function BannedIconButton({
	label,
}: React.PropsWithChildren<BannedIconButtonProps>) {
	return (
		<StyledButton disabled>
			<BannedIcon />
			{label}
		</StyledButton>
	);
}

const StyledButton = styled(Button)({
	background: '#BCBCBC',
	color: 'white',
	fontSize: 12,
	gap: '5px',
	padding: '0px',
	display: 'flex',
	alignItems: 'center',
	minWidth: '100px',

	':disabled': {
		color: 'white',
	},
});

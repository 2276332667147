import { styled } from '@mui/system';
import {
	DataGrid,
	GridRowIdGetter,
	GridToolbar,
	GridHeader,
	GridValidRowModel,
	GridColumns,
	GridSelectionModel,
	GridCallbackDetails,
} from '@mui/x-data-grid';
import { useController, useFormContext } from 'react-hook-form';
import { Pagination } from '../../../api/DTO/common.interface';
import AppCheckbox from '../form-inputs/AppCheckbox';

export const initialPagination: Pagination = {
	page: 1,
	size: 100,
};

interface AppDataGridProps {
	loading: boolean;
	rows: GridValidRowModel[];
	columns: GridColumns<GridValidRowModel>;
	rowCount: number;
	getRowId: GridRowIdGetter<GridValidRowModel> | undefined;
	autoHeight?: boolean;
	checkboxSelection?: boolean;
	selectionModel?: GridSelectionModel;
	onSelectionModelChange?:
		| ((
				selectionModel: GridSelectionModel,
				details: GridCallbackDetails<any>
		  ) => void)
		| undefined;
}

/**
 * Custom Data Grid with React Hook Form controlled pagination
 */
export default function AppDataGrid({
	loading,
	rows,
	columns,
	rowCount,
	getRowId,
	autoHeight,
	checkboxSelection,
	selectionModel,
	onSelectionModelChange,
}: AppDataGridProps) {
	const { control } = useFormContext();
	const { field } = useController({ control: control, name: 'pagination' });
	const pagination = field.value as Pagination;
	const paginationOnChange = field.onChange;

	return (
		<StyledDataGrid
			loading={loading}
			rows={rows}
			columns={columns}
			getRowId={getRowId}
			rowCount={rowCount}
			paginationMode='server'
			page={pagination.page - 1} // -1 because MUI Data Grid page is start from 0
			onPageChange={(page: number) => {
				paginationOnChange({ ...pagination, page: page + 1 }); // +1 because MUI Data Grid page is start from 0
			}}
			pageSize={pagination.size}
			onPageSizeChange={(pageSize: number) => {
				paginationOnChange({ page: 1, size: pageSize });
			}}
			pagination={true}
			rowsPerPageOptions={[10, 20, 50, 100]}
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableDensitySelector
			disableColumnMenu
			sortingMode='server'
			autoHeight={autoHeight ?? true}
			checkboxSelection={checkboxSelection ?? false}
			selectionModel={selectionModel}
			onSelectionModelChange={onSelectionModelChange}
			keepNonExistentRowsSelected
			headerHeight={44}
			getRowHeight={() => 'auto'}
			components={{
				Toolbar: GridToolbar,
				Header: StyledGridHeader,
				BaseCheckbox: (props) => <AppCheckbox {...props} />,
			}}
			componentsProps={{
				toolbar: {
					csvOptions: { disableToolbarButton: true },
					printOptions: { disableToolbarButton: true },
					quickFilterProps: { debounceMs: 250 },
				},
			}}
		/>
	);
}

const StyledDataGrid = styled(DataGrid)(() => ({
	boxShadow: ' 0px 4px 3px 0px rgba(0, 0, 0, 0.05)',
	backgroundColor: '#FFFFFF',
	borderRadius: 5,
	border: 1,
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: '#E8EBED',
	},
	'& .MuiDataGrid-columnHeaderTitle': {
		fontSize: '14px',
		fontWeight: 700,
		whiteSpace: 'break-spaces',
		lineHeight: '14px',
	},
	'& .MuiDataGrid-columnSeparator': {
		visibility: 'hidden',
	},
	'& .MuiDataGrid-iconButtonContainer button': {
		display: 'none',
	},
	// '& .MuiDataGrid-columnHeaderTitleContainerContent': {
	// 	width: '100%',
	// 	justifyContent: 'center',
	// }
	// ,
	'.MuiDataGrid-columnHeader:first-of-type': {
		padding: '0 10px 0 24px',
	},
	'.MuiDataGrid-cell:first-of-type': {
		padding: '0 10px 0 24px',
	},
	'.MuiDataGrid-columnHeader:last-of-type': {
		padding: '0 24px 0 10px',
	},
	'.MuiDataGrid-cell:last-of-type': {
		padding: '0 24px 0 10px',
	},
	'.MuiDataGrid-cellContent': {
		fontSize: '12px',
		lineHeight: '1.5',
		wordWrap: 'break-word',
		whiteSpace: 'normal',
		padding: '15px 0',
	},
	'.MuiCheckbox-root': {
		padding: '0',
	},
}));

const StyledGridHeader = styled(GridHeader)({
	fontSize: 50,
});

import KidsSupplementForm from './KidsSupplementForm';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../common/components/card/FormCard';
import KidsSupplementView from './KidsSupplementView';

interface KidsSupplementCardProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function KidsSupplementCard({
	type,
	setType,
}: KidsSupplementCardProps) {
	const { t } = useTranslation();

	return (
		<FormCard type={type} title={t('kidsSupplement.KidsSupplement')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <KidsSupplementView setType={setType} />;
					case FormCardType.edit:
						return <KidsSupplementForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

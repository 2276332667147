import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	Button,
	InputAdornment,
	MenuItem,
	Paper,
	styled,
	TextFieldProps,
	Typography,
} from '@mui/material';
import Option from '../../../common/types/option.type';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import { ReactNode, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icon/dropdownmenu-search.svg';
import { ReactComponent as AddIcon } from '../../../assets/icon/dropdownmenu-add.svg';
import AddOwnerDialog from './AddOwnershipDialog';
import { useGetOwnersQuery } from '../../../api/accommodationApiSlice';
import { StyledTextField } from '../../../common/components/styled/StyledTextField';
import { StyledAutocomplete } from '../../../common/components/styled/StyledAutocomplete';

interface OwnershipDropDownMenuProps {
	label: string;
	infoPopUp?: ReactNode;
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	fontSize: '12px',
	padding: '12px',
}));

export default function OwnershipDropDownMenu<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	infoPopUp,
	required,
	...props
}: OwnershipDropDownMenuProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	const { data: getOwnersResponse } = useGetOwnersQuery();

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (addedOption?: Option) => {
		setOpen(false);
		if (addedOption) {
			field.onChange(addedOption);
		}
	};

	const toNullIfNullOption = (value?: unknown): Option | null | unknown => {
		const option = value as Option | null | undefined;

		if (option) {
			if (option.id === null) return null;
		}
		return value;
	};

	return (
		<>
			<AppInputLayout
				label={label}
				required={required}
				infoPopUp={infoPopUp}
				errorMessage={errorMessage}
			>
				<StyledAutocomplete
					{...field}
					disablePortal
					options={[{ id: null, name: 'Individual' }].concat(
						getOwnersResponse?.data ?? []
					)}
					value={
						field.value ?? {
							id: null,
							name: 'Individual',
						}
					}
					onChange={(_, newValue) =>
						field.onChange(toNullIfNullOption(newValue))
					}
					getOptionLabel={(option: any) => option.name}
					isOptionEqualToValue={(option: any, value: any) =>
						option.id === value.id
					}
					PaperComponent={({ children }) => {
						return (
							<Paper>
								{children}
								<Button
									color='primary'
									fullWidth
									sx={{ justifyContent: 'center' }}
									onMouseDown={handleClickOpen}
								>
									<AddIcon />
									<Typography marginLeft='4px' fontSize='12px'>
										Add
									</Typography>
								</Button>
							</Paper>
						);
					}}
					renderInput={(params) => (
						<StyledTextField
							{...params}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<InputAdornment sx={{ marginRight: '14px' }} position='end'>
										<SearchIcon />
										{/* {params.InputProps.endAdornment} */}
									</InputAdornment>
								),
							}}
						/>
					)}
					ListboxProps={{
						style: {
							maxHeight: '250px',
						},
					}}
					renderOption={(props: object, option: any) => (
						<StyledMenuItem {...props} key={option.id}>{option.name}</StyledMenuItem>
					)}
				></StyledAutocomplete>
			</AppInputLayout>
			<AddOwnerDialog open={open} onClose={handleClose} />
		</>
	);
}

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Box, Stack, TextFieldProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppAddPhotoButton from '../../../common/components/form-inputs/AppAddPhotoButton';
import { ReactComponent as AddIcon } from '../../../assets/icon/button-add.svg';
import { useUploadPublicMediaMutation } from '../../../api/mediaApiSlice';
import { useEffect } from 'react';
import { SupplierDocumentType } from '../../../api/enum/supplierDocumentType.enum';
import { Images } from '../../../api/DTO/place.interface';
import UploadedImageWithCredit from './UploadedImageWithCredit';
import { fileSizeValidator } from '../../../utils/fileValidator';

interface MultipleImagesWithCreditFieldProps {
	supplierDocumentType?: SupplierDocumentType;
}

export default function MultipleImagesWithCreditField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	supplierDocumentType,
	...props
}: UseControllerProps<TFieldValues, TName> &
	TextFieldProps &
	MultipleImagesWithCreditFieldProps) {
	const { t } = useTranslation();
	const { field } = useController(props);
	const filesList: Images[] = field.value ?? [];
	const onChange = field.onChange;

	const [uploadMedia, { data: uploadMediaResponse, isSuccess }] =
		useUploadPublicMediaMutation();

	useEffect(() => {
		if (isSuccess && uploadMediaResponse?.data[0]) {
			const newFileWithDescription: Images = {
				id: uploadMediaResponse?.data[0].id,
				url: uploadMediaResponse?.data[0].url,
				credit: '',
			};
			onChange([...filesList, newFileWithDescription]);
		}
	}, [isSuccess]);

	async function onUploadFileChange(
		event: React.ChangeEvent<HTMLInputElement>
	) {
		const target = event.target;
		const file = target.files?.[0];

		if (file) {
			uploadMedia(file);
		}
	}

	const handleFileRemove = (index: number) => {
		const list = [...filesList];
		list.splice(index, 1);
		onChange(list);
	};

	const handleFileChange = (value: Images, index: number) => {
		const list = [...filesList];
		list[index] = value;
		onChange(list);
	};

	return (
		<>
			<Stack spacing={'15px'}>
				{filesList.map((Images: Images, index: number) => (
					<Box key={Images.id}>
						<UploadedImageWithCredit
							key={index}
							index={index}
							value={Images}
							onChange={(value: Images) => handleFileChange(value, index)}
							deleteOnClick={handleFileRemove}
						/>
					</Box>
				))}
				<Box height={'200px'}>
					<AppAddPhotoButton component='label'>
						<input
							type='file'
							name='doc'
							hidden
							onChange={onUploadFileChange}
							accept='image/jpeg, image/png, image/gif, image/webp'
						/>
						<Typography fontSize={'14px'} fontWeight={'700'}>
							<AddIcon />
							{t('button.AddPhoto')}
						</Typography>
						<Typography fontSize={'10px'}>
							{t('place.SuggestedFormat')}
							<b>{t('place.FileType')}</b>
						</Typography>
						<Typography fontSize={'10px'}>
							{t('place.SuggestedDimension')}
							<b>{t('place.Dimension')}</b>
						</Typography>
					</AppAddPhotoButton>
				</Box>
			</Stack>
		</>
	);
}

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../common/components/card/FormCard';
import ShortNoticeForm from './ShortNoticeForm';
import ShortNoticeView from './ShortNoticeView';
import { Box } from '@mui/material';

interface ShortNoticeCardProps {
	initialType: FormCardType;
}

export default function ShortNoticeCard({ initialType }: ShortNoticeCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<Box sx={{ width: '100%', marginBottom: '15px' }}>
			<FormCard type={type} title={t('page.ShortNotice')}>
				{(() => {
					switch (type) {
						case FormCardType.view:
							return <ShortNoticeView setType={setType} />;
						case FormCardType.edit:
							return <ShortNoticeForm type={type} setType={setType} />;
					}
				})()}
			</FormCard>
		</Box>
	);
}

import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import RateInfoView from './RateInfoView';

interface RateInfoCardProps {
	initialType: FormCardType;
}

export default function RateInfoCard({ initialType }: RateInfoCardProps) {
	const { t } = useTranslation();

	return (
		<FormCard type={initialType} title={t('bk.RateInformation')}>
			{(() => {
				switch (initialType) {
					case FormCardType.view:
						return <RateInfoView />;
				}
			})()}
		</FormCard>
	);
}

import { Navigate, To, useLocation } from 'react-router-dom';

interface NavigateWithStateProps {
	to: To;
}

export default function NavigateWithState({ to }: NavigateWithStateProps) {
	const { state } = useLocation();

	return <Navigate to={to} state={state} replace />;
}

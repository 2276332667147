import Option from '../../common/types/option.type';

export enum AbkSalutation {
	MR = 0,
	MRS = 1,
	MS = 2,
}

export function getAbkSalutation(abkSalutation: AbkSalutation): string {
	switch (abkSalutation) {
		case AbkSalutation.MR:
			return 'Mr.';
		case AbkSalutation.MRS:
			return 'Mrs.';
		case AbkSalutation.MS:
			return 'Ms.';
		default:
			return '';
	}
}

export const AbkSalutationOptions: Option[] = [
	{
		id: AbkSalutation.MR,
		name: 'Mr.',
	},
	{
		id: AbkSalutation.MRS,
		name: 'Mrs.',
	},
	{
		id: AbkSalutation.MS,
		name: 'Ms.',
	},
];

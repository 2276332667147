import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Link,
	Outlet,
	matchPath,
	useLocation,
	useParams,
} from 'react-router-dom';
import { useGetBkQuery, useUpdateBkStatusMutation } from '../../api/bkApiSlice';
import {
	BkMoreAction,
	bkConfirmedMoreActionOptions,
	bkNewMoreActionOptions,
} from '../../api/enum/bkMoreAction.enum';
import { BkStatus, getBkStatusDisplay } from '../../api/enum/bkStatus.enum';
import AppDropDownButton from '../../common/components/AppDropDownButton';
import AppStatusDisplay from '../../common/components/AppStatusDisplay';
import FullScreenLoader from '../../common/components/FullScreenLoader';
import PageHeader from '../../common/components/PageHeader';
import { FormCardType } from '../../common/components/card/FormCard';
import { showModal } from '../../common/components/modal/modalSlice';
import { useAppDispatch } from '../../redux-hooks';

function useRouteMatch(patterns: readonly string[]) {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}

	return null;
}

function BkTabs({ currentTab }: { currentTab?: string }) {
	const { t } = useTranslation();
	const { bkId } = useParams();

	return (
		<Box
			sx={{
				backgroundColor: '#FFFFFF',
				borderRadius: '5px',
				boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
				padding: '0px 20px',
				marginBottom: '15px',
			}}
		>
			{currentTab && (
				<Tabs value={currentTab}>
					<Tab
						label={t('tabs.Overview')}
						value='/bks/:bkId/overview'
						to={`/bks/${bkId}/overview`}
						component={Link}
					/>
				</Tabs>
			)}
		</Box>
	);
}

export default function BkContainer() {
	const { t } = useTranslation();
	const { bkId } = useParams();
	const { state } = useLocation();
	const dispatch = useAppDispatch();

	const [bkSummarySearchParams, setBkSummarySearchParams] = useState<
		string | undefined
	>(undefined);

	// You need to provide the routes in descendant order.
	// This means that if you have nested routes like:
	// users, users/new, users/edit.
	// Then the order should be ['users/add', 'users/edit', 'users'].
	const routeMatch = useRouteMatch(['/bks/:bkId/overview']);

	const currentTab = routeMatch?.pattern?.path;

	useEffect(() => {
		if (state?.bkSummarySearchParams) {
			setBkSummarySearchParams(state?.bkSummarySearchParams);
		}
	}, []);

	const backPath = bkSummarySearchParams
		? `/bks?${bkSummarySearchParams}`
		: '/bks';

	const [type, setType] = useState<FormCardType>(FormCardType.view);

	const {
		data: getBkResponse,
		isLoading: isGetBkLoading,
		isSuccess: isGetBkSuccess,
		isError: isGetBkError,
	} = useGetBkQuery(
		{
			bk_id: bkId ?? '',
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const [updateBkStatus, { isSuccess: isUpdateBkStatusSuccess }] =
		useUpdateBkStatusMutation();

	const handleCancelBkBooking = (bkId: string, status: BkStatus) => {
		dispatch(
			showModal({
				modalType: 'DELETE_BK_BOOKING',
				modalProps: {
					id: bkId,
					status: status,
				},
			})
		);
	};

	const handleChange = (value: number) => {
		switch (value) {
			case BkMoreAction.CONFIRM:
				return updateBkStatus({
					bkId: bkId ?? '',
					body: { status: BkStatus.CONFIRMED },
				});
			case BkMoreAction.CANCEL:
				return handleCancelBkBooking(bkId ?? '', BkStatus.CANCELLED);
			default:
				return undefined;
		}
	};

	const getActionButtonOption = (value: number) => {
		switch (value) {
			case BkStatus.NEW:
				return bkNewMoreActionOptions;
			case BkStatus.CONFIRMED:
				return bkConfirmedMoreActionOptions;
			default:
				return undefined;
		}
	};

	let pageContent;
	if (isGetBkLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetBkSuccess) {
		pageContent = (
			<Box sx={{ width: '100%' }}>
				<PageHeader
					showBackButton={currentTab == '/bks/:bkId/overview'}
					backPath={backPath}
					title={getBkResponse?.data.info.id}
					statusJsx={
						<>
							<AppStatusDisplay
								name={
									getBkStatusDisplay(getBkResponse?.data.info.status ?? 0).name
								}
								color={
									getBkStatusDisplay(getBkResponse?.data.info.status ?? 0).color
								}
								bgcolor={
									getBkStatusDisplay(getBkResponse?.data.info.status ?? 0)
										.backgroundColor
								}
							/>
						</>
					}
					actionJsx={
						<>
							{currentTab === '/bks/:bkId/overview' && (
								<>
									{type === FormCardType.view && (
										<Box gap={'10px'} display={'flex'}>
											{getBkResponse?.data.info.status !==
											BkStatus.CANCELLED ? (
												<AppDropDownButton
													label={t('button.MoreActions')}
													options={getActionButtonOption(
														getBkResponse?.data.info.status
													)}
													onChange={(value: number) => handleChange(value)}
												/>
											) : (
												<></>
											)}
										</Box>
									)}
								</>
							)}
						</>
					}
				/>
				<BkTabs currentTab={currentTab} />
				<Outlet />
			</Box>
		);
	} else if (isGetBkError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}

import { Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Section } from '../../../../api/DTO/page.interface';
import { useAddSectionMutation } from '../../../../api/pageApiSlice';
import PageHeader from '../../../../common/components/PageHeader';
import { FormCardType } from '../../../../common/components/card/FormCard';
import SectionCard from './HomeSectionCard';

export default function CreateHomeSectionPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const backPath = '/page/home';

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const defaultValues: DefaultValues<Section> = {
		items: [],
	};

	const formSchema = Yup.object().shape({
		icon: Yup.string().required(t('errorMessage.pleaseSelect')),
		title: Yup.string().required(t('errorMessage.required')),
	});

	const [addSection, { isSuccess: isAddSectionSuccess }] =
		useAddSectionMutation();

	const methods = useForm<Section>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const onSubmit: SubmitHandler<Section> = (data) => {
		addSection(data);
	};

	useEffect(() => {
		if (isAddSectionSuccess) {
			navigate(backPath);
		}
	}, [isAddSectionSuccess]);

	return (
		<>
			<PageHeader
				showBackButton={true}
				backPath={backPath}
				title={t('page.CreateSection')}
			/>
			{ability.can('create', 'Section') && (
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Stack spacing='16px'>
							<Grid container alignItems='strech' spacing={'10px'}>
								<Grid item xs={12} display='flex'>
									<SectionCard initialType={FormCardType.create} />
								</Grid>
							</Grid>

							<Stack spacing='16px'>
								<Grid item xs={12}>
									<Stack direction='row-reverse' spacing='12px'>
										<Button type='submit' variant='contained' color='success'>
											{t('button.Save')}
										</Button>
										<Button
											variant='outlined'
											color='info'
											onClick={() => navigate(backPath)}
										>
											{t('button.Discard')}
										</Button>
									</Stack>
								</Grid>
							</Stack>
						</Stack>
					</form>
				</FormProvider>
			)}
		</>
	);
}

import {
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	PointerSensor,
	TouchSensor,
	closestCenter,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { useState } from 'react';

import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';

import { styled } from '@mui/material';
import { SectionItem } from '../../../api/DTO/page.interface';
import { StyledTableBody } from '../../../common/components/styled/StyledLightBlueTable';
import SortablePropertyItem from './SortablePropertyItem';

interface DndImageListSectionProps {
	control: any;
	propertyItems: any[];
	handleRepositionItem: (activeIndex: number, overIndex: number) => void;
	handleDeleteItem: (index: number) => void;
}

export default function DndPropertyListSection({
	control,
	propertyItems,
	handleRepositionItem,
	handleDeleteItem,
}: DndImageListSectionProps) {
	// for drag overlay
	const [activeItem, setActiveItem] = useState<any>();

	// for input methods detection
	const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

	// triggered when dragging starts
	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event;

		setActiveItem(
			propertyItems.find((item: SectionItem) => item.propertyId === active.id)
		);
	};

	// triggered when dragging ends
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		if (!over) return;

		const activeItem = propertyItems.find(
			(item: SectionItem) => item.propertyId === active.id
		);
		const overItem = propertyItems.find(
			(item: SectionItem) => item.propertyId === over.id
		);

		if (!activeItem || !overItem) {
			return;
		}

		const activeIndex = propertyItems.findIndex(
			(item: SectionItem) => item.propertyId === active.id
		);
		const overIndex = propertyItems.findIndex(
			(item: SectionItem) => item.propertyId === over.id
		);

		if (activeIndex !== overIndex) {
			handleRepositionItem(activeIndex, overIndex);
		}
		setActiveItem(undefined);
	};

	const handleDragCancel = () => {
		setActiveItem(undefined);
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
			onDragCancel={handleDragCancel}
		>
			<SortableContext
				items={propertyItems.map((i) => i.propertyId)}
				strategy={rectSortingStrategy}
			>
				<StyledTableBody>
					{propertyItems?.map((item: SectionItem, index: number) => (
						<SortablePropertyItem
							control={control}
							key={item.propertyId}
							item={item}
							index={index}
							DeleteCallBack={handleDeleteItem}
						/>
					))}
				</StyledTableBody>
			</SortableContext>
			<StyledDragOverlayContainer>
				{activeItem ? (
					<SortablePropertyItem
						control={control}
						item={activeItem}
						index={activeItem.index}
						DeleteCallBack={handleDeleteItem}
					/>
				) : null}
			</StyledDragOverlayContainer>
		</DndContext>
	);
}

const StyledDragOverlayContainer = styled(DragOverlay)(() => ({
	opacity: 0,
	width: '100%',
}));

import {
	Promotion,
	PromotionFilter,
	PromotionSummaryResponse,
	UpdatePromotionStatus,
} from './DTO/promotion.interface';
import { apiSlice } from './apiSlice';
import { SuccessResponse } from './response/shared/success.response';

const SERVICE_PATH = '/promotion-service';

export const PromotionApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// Promotion

		addPromotion: builder.mutation<SuccessResponse<Promotion>, Promotion>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/promotions`,
				method: 'POST',
				body,
			}),
		}),

		getPromotion: builder.query<SuccessResponse<Promotion>, string>({
			query: (promotionId) => ({
				url: `${SERVICE_PATH}/admin/promotions/${promotionId}`,
			}),
			providesTags: ['Promotion'],
		}),

		getFilteredPromotions: builder.query<
			PromotionSummaryResponse,
			PromotionFilter
		>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/promotions/filter`,
				method: 'POST',
				body,
			}),
			providesTags: ['PromotionSummary'],
		}),

		updatePromotion: builder.mutation<
			SuccessResponse<Promotion>,
			{ promotionId: string; body: Promotion }
		>({
			query: ({ promotionId, body }) => ({
				url: `${SERVICE_PATH}/admin/promotions/${promotionId}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Promotion'],
		}),

		updatePromotionStatus: builder.mutation<
			SuccessResponse<UpdatePromotionStatus>,
			{ promotionId: string; body: UpdatePromotionStatus }
		>({
			query: ({ promotionId, body }) => ({
				url: `${SERVICE_PATH}/admin/promotions/${promotionId}/status`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PromotionSummary', 'Promotion'],
		}),
	}),
});

export const {
	useAddPromotionMutation,
	useGetPromotionQuery,
	useGetFilteredPromotionsQuery,
	useUpdatePromotionMutation,
	useUpdatePromotionStatusMutation,
} = PromotionApiSlice;

import { Box, Collapse, List, ListItemText, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ReactComponent as ExpandMoreIcon } from '../../../../assets/icon/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from '../../../../assets/icon/expand-less.svg';
import { useGetRoomSummaryQuery } from '../../../../api/accommodationApiSlice';
import ListItemLinkV2 from '../../../../common/components/list/ListItemLinkV2';
import RatePlanList from './RatePlanList';
import { StyledListItemButton } from '../../../../common/components/styled/StyledListItemButton';
import { useTranslation } from 'react-i18next';

export default function RoomList() {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { propertyId, roomId } = useParams();

	const { data: getRoomSummaryResponse } = useGetRoomSummaryQuery({
		propertyId: propertyId ?? '',
	});

	const [open, setOpen] = useState(roomId ? true : false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<>
			<StyledListItemButton onClick={handleClick} selected={false}>
				<ListItemText
					primary={
						<Box>
							<Typography fontSize='12px' color={'text.grey'}>
								{t('logging.Room')}
							</Typography>
						</Box>
					}
				/>
				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</StyledListItemButton>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding sx={{ paddingLeft: '10px' }}>
					{getRoomSummaryResponse?.data?.map((room) => (
						<div key={room.id}>
							<ListItemLinkV2
								primarytext={room.nameEn}
								secondaryText={room.name}
								to={`/property/${propertyId}/logging/room/${room.id}`}
								selected={pathname.includes(`/logging/room/${room.id}`)}
							/>
							<RatePlanList key={`${room.id}-rate-plan`} roomId={room.id} />
						</div>
					))}
				</List>
			</Collapse>
		</>
	);
}

import Option from '../../common/types/option.type';

export enum ShortNoticeStatus {
	ACTIVE = 0,
	INACTIVE = 1,
}

export const shortNoticeStatusOptions: Option[] = [
	{
		id: ShortNoticeStatus.ACTIVE,
		name: 'Online',
	},
	{
		id: ShortNoticeStatus.INACTIVE,
		name: 'Offline',
	},
];

export function getShortNoticeStatus(
	status: ShortNoticeStatus | undefined
): string {
	switch (status) {
		case ShortNoticeStatus.ACTIVE:
			return 'Online';
		case ShortNoticeStatus.INACTIVE:
			return 'Offline';
		default:
			return '';
	}
}

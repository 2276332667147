import { Bk, BkFilter, UpdateBkStatus } from './DTO/bk.interface';
import { apiSlice } from './apiSlice';
import { BkSummaryResponse } from './response/bkSummary.response';
import { SuccessResponse } from './response/shared/success.response';

const SERVICE_PATH = '/booking-service';

export const BkApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		//BK
		getFilteredBk: builder.query<BkSummaryResponse, BkFilter>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/bks/filter`,
				method: 'POST',
				body,
			}),
		}),

		getBk: builder.query<SuccessResponse<Bk>, { bk_id: string }>({
			query: ({ bk_id }) => ({
				url: `${SERVICE_PATH}/admin/bks/${bk_id}`,
			}),
			providesTags: ['Bk'],
		}),

		updateBkStatus: builder.mutation<
			SuccessResponse<UpdateBkStatus>,
			{ bkId: string; body: UpdateBkStatus }
		>({
			query: ({ bkId, body }) => ({
				url: `${SERVICE_PATH}/admin/bks/${bkId}/status`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Bk'],
		}),
	}),
});

export const {
	useGetFilteredBkQuery,
	useGetBkQuery,
	useUpdateBkStatusMutation,
} = BkApiSlice;

import Option from "../../common/types/option.type";

export enum PlaceAgeGroup {
    INFANT = 0,
	TODDLER = 1,
	CHILD = 2,
	YOUNG_TEEN = 3,
	TEEN = 4,
}

export const placeAgeGroupOption: Option[] = [
	{
		id: PlaceAgeGroup.INFANT,
		name: '嬰兒',
	},
	{
		id: PlaceAgeGroup.TODDLER,
		name: '幼稚園',
	},
    {
		id: PlaceAgeGroup.CHILD,
		name: '小學',
	},
    {
		id: PlaceAgeGroup.YOUNG_TEEN,
		name: '初中',
	},
    {
		id: PlaceAgeGroup.TEEN,
		name: '高中或以上',
	},
];
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	useLazyGetRatePlanSummaryQuery,
	useUpdateRatePlanStatusMutation,
} from '../../../../api/accommodationApiSlice';
import { useCallback, useEffect } from 'react';
import CustomDataGrid from '../../../../common/components/dataGrid/CustomDataGrid';
import { RatePlanSummary } from '../../../../api/DTO/ratePlan.interface';
import {
	GridColDef,
	GridColumnHeaderParams,
	GridRenderCellParams,
} from '@mui/x-data-grid';
import CustomDataGridHeader from '../../../../common/components/dataGrid/CustomDataGridHeader';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import {
	getRateModel,
	getRatePlanChannelAndSupplier,
	getRatePlanMealType,
	getRatePlanStatus,
} from '../../../../utils/accomodationHelper';
import React from 'react';
import {} from '../../../../api/enum/ratePlanChannel.enum';
import SmallerAppStatusButton from '../../../../common/components/SmallerAppStatusButton';
import { ReactComponent as ViewDetailIcon } from '../../../../assets/icon/view-detail.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icon/add.svg';
import {
	API_DATE_FORMAT,
	DISPLAY_DATE_FORMAT,
} from '../../../../utils/dateHelper';
import dayjs from 'dayjs';

interface RatePlanDataGrid {
	id: string;
	name: string;
	nameEn: string;
	mealType: string;
	status: number;
	bookingPeriod: {
		start: string;
		end?: string;
	};
	checkInPeriod: {
		start: string;
		end?: string;
	};
	refundable: boolean;
	cancellationPolicies: string[];
	ratePlanChannelAndSupplier: string;
	rateModel: string;
}

export default function RatePlanSummaryPage() {
	const navigate = useNavigate();
	const { roomId, propertyId } = useParams();
	const [getRatePlanSummary, { data: getRatePlanSummaryResponse }] =
		useLazyGetRatePlanSummaryQuery();
	const { t } = useTranslation();
	const [updateRatePlanStatus] = useUpdateRatePlanStatusMutation();

	useEffect(() => {
		getRatePlanSummary(roomId ?? '');
	}, [getRatePlanSummaryResponse]);

	const handleStatusChange = (
		event: React.MouseEvent<HTMLElement>,
		status: boolean | null,
		params: GridRenderCellParams
	) => {
		if (params.row.status == null) return;

		updateRatePlanStatus({
			ratePlanId: params.row.id,
			body: {
				status: params.row.status == 1 ? 0 : 1,
			},
		}).then(() => {
			getRatePlanSummary(roomId ?? '');
		});
	};

	const transformResponse = useCallback((response: RatePlanSummary[]) => {
		if (response == null) return;
		const newResponse: RatePlanDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.id,
				name: item.name,
				nameEn: item.nameEn,
				mealType: getRatePlanMealType(item.mealType),
				status: item.status,
				bookingPeriod: {
					start: item.bookingPeriod.start,
					end: item.bookingPeriod.end,
				},
				checkInPeriod: {
					start: item.checkInPeriod.start,
					end: item.checkInPeriod.end,
				},
				refundable: item.refundable,
				cancellationPolicies: item.cancellationPolicies,
				ratePlanChannelAndSupplier: getRatePlanChannelAndSupplier(
					item.channel,
					item.apiSupplier
				), // todo: to update later after supplier mapping api is completed
				rateModel: getRateModel(item.rateModel),
			});
		});

		return newResponse;
	}, []);

	const DataGridHeader = (params: GridColumnHeaderParams) => {
		return (
			<CustomDataGridHeader
				headerName={params.colDef.headerName}
				fieldName={params.colDef.field}
				enableSorting={false}
			/>
		);
	};

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: t('room.ratePlanSummary.Id'),
			width: 105,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<>
					<Typography
						display={'block'}
						fontSize={'0.75rem'}
						variant='regular'
						color={'#808080'}
					>
						{params.row.id}
					</Typography>
				</>
			),
		},
		{
			field: 'name',
			headerName: t('room.ratePlanSummary.RatePlanName'),
			flex: 1,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<Typography
					display={'block'}
					fontSize={'0.75rem'}
					variant='regular'
					whiteSpace={'pre-line'}
				>
					{params.row.nameEn}
					{'\n'}
					{params.row.name}
				</Typography>
			),
		},
		{
			field: 'mealType',
			headerName: t('room.ratePlanSummary.MealType'),
			width: 75,
			renderHeader: DataGridHeader,
		},
		{
			field: 'status',
			headerName: t('room.ratePlanSummary.Status'),
			width: 85,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<SmallerAppStatusButton
					initialStatusI18nLabel={
						getRatePlanStatus(params.row.status).i18nLabel
					}
					initialStatusValue={getRatePlanStatus(params.row.status).isOnline}
					enableI18nLabel={t('room.ratePlanSummary.Online')}
					disableI18nLabel={t('room.ratePlanSummary.Offline')}
					statusOnChange={(event, status) => {
						handleStatusChange(event, status, params);
					}}
				/>
			),
		},
		{
			field: 'bookingPeriod',
			headerName: t('room.ratePlanSummary.BookingPeriod'),
			width: 90,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<>
					<Typography
						display={'block'}
						fontSize={'0.75rem'}
						variant='regular'
						whiteSpace={'pre-line'}
					>
						{dayjs(params.row.bookingPeriod.start).format(DISPLAY_DATE_FORMAT)}-
						{'\n'}
						{params.row.bookingPeriod.end
							? dayjs(params.row.bookingPeriod.end).format(DISPLAY_DATE_FORMAT)
							: '\t∞'}
					</Typography>
				</>
			),
		},
		{
			field: 'checkInPeriod',
			headerName: t('room.ratePlanSummary.CheckInPeriod'),
			width: 90,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<>
					<Typography
						display={'block'}
						fontSize={'0.75rem'}
						variant='regular'
						whiteSpace={'pre-line'}
					>
						{dayjs(params.row.checkInPeriod.start, API_DATE_FORMAT).format(
							DISPLAY_DATE_FORMAT
						)}
						-{'\n'}
						{params.row.checkInPeriod.end
							? dayjs(params.row.checkInPeriod.end, API_DATE_FORMAT).format(
									DISPLAY_DATE_FORMAT
							  )
							: '\t∞'}
					</Typography>
				</>
			),
		},
		{
			field: 'cancellationPolicies',
			headerName: t('room.ratePlanSummary.CancellationPolicies'),
			flex: 1,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<>
					{!params.row.refundable && (
						<Typography
							display={'block'}
							fontSize={'0.75rem'}
							variant='regular'
						>
							{t('room.ratePlanSummary.NonRefundable')}
						</Typography>
					)}

					<Typography
						display={'block'}
						fontSize={'0.75rem'}
						variant='regular'
						whiteSpace={'pre-line'}
					>
						{params.row.cancellationPolicies
							.map((item: string) => {
								return item;
							})
							.join('\n')}
					</Typography>
				</>
			),
		},
		{
			field: 'ratePlanChannelAndSupplier',
			headerName: t('room.ratePlanSummary.RatePlanSupplier'),
			width: 122,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<>
					<Typography
						display={'block'}
						fontSize={'0.75rem'}
						variant='regular'
						whiteSpace={'pre-line'}
					>
						{params.row.ratePlanChannelAndSupplier} {'\n'}
						{params.row.rateModel}
					</Typography>
				</>
			),
		},
		{
			field: 'edit',
			headerName: '',
			width: 68, // 10(cell padding left) + 34(icon button width) + 24(cell padding right)
			renderCell: (params) => (
				<Link
					to={`/property/${propertyId}/room/${roomId}/rate-plan/${params.id}`}
				>
					<IconButton color='primary'>
						<ViewDetailIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -48, right: 0, zIndex: 20 }}>
					<Button
						variant='contained'
						startIcon={<AddIcon />}
						onClick={() =>
							navigate(
								`/property/${propertyId}/room/${roomId}/rate-plan/create`
							)
						}
					>
						Create Rate Plan
					</Button>
				</Box>
				<CustomDataGrid
					rows={transformResponse(getRatePlanSummaryResponse?.data ?? []) ?? []}
					columns={columns}
					rowCount={getRatePlanSummaryResponse?.data.length ?? 0}
					getRowId={(row) => row.id}
					pagination={false}
				/>
			</Stack>
		</>
	);
}

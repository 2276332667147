import styled from '@emotion/styled';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { RateModel, rateModelOptions } from '../../api/enum/rateModel.enum';

interface RateModelToggleButtonProps {
	value: RateModel;
	handleChange: (
		event: React.MouseEvent<HTMLElement>,
		newRateModel: RateModel
	) => void;
	disableRateModel?: RateModel[];
}

export default function RateModelToggleButton({
	value,
	handleChange,
	disableRateModel,
}: RateModelToggleButtonProps) {
	return (
		<StyledToggleButtonGroup
			color='primary'
			value={value}
			exclusive
			onChange={handleChange}
			aria-label='Model-Selection'
		>
			<ToggleButton
				value={RateModel.AGENCY}
				disabled={disableRateModel?.includes(RateModel.AGENCY) ?? false}
			>
				{rateModelOptions[RateModel.AGENCY].name}
			</ToggleButton>
			<ToggleButton
				value={RateModel.MERCHANT}
				disabled={disableRateModel?.includes(RateModel.MERCHANT) ?? false}
			>
				{rateModelOptions[RateModel.MERCHANT].name}
			</ToggleButton>
		</StyledToggleButtonGroup>
	);
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
	border: 'unset !important',
	'& .MuiToggleButtonGroup-grouped': {
		border: '0.5px solid !important',
		borderRadius: '5px',
		borderColor: '#BCBCBC',
		color: '#BCBCBC',
		textTransform: 'none',
		padding: '6px 15px',
		lineHeight: '18px',
		fontSize: '12px',
		whiteSpace: 'pre-wrap',
		backgroundColor: '#FFFFFF',
		'&.Mui-selected, &.Mui-selected:hover': {
			border: '1px solid !important',
			color: '#32A5F1',
			fontWeight: '700',
			backgroundColor: '#F0F7FF',
		},
		'&.Mui-disabled': {
			border: '0.5px solid !important',
			borderColor: 'rgba(188, 188, 188, 0.26)',
			color: 'rgba(188, 188, 188, 0.6)',
			textTransform: 'none',
			padding: '6px 15px',
			lineHeight: '18px',
			fontSize: '12px',
			backgroundColor: 'rgba(188, 188, 188, 0.08)',
		},
	},
});

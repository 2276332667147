import { DevTool } from '@hookform/devtools';
import { Stack } from '@mui/system';
import { SubmitHandler, DefaultValues, useForm } from 'react-hook-form';
import AppDatePicker from '../../common/components/form-inputs/AppDatePicker';
import AppDropDownMenu from '../../common/components/form-inputs/archive/AppDropDownMenu';
import AppMultipleCheckbox from '../../common/components/form-inputs/AppMultipleCheckbox';
import AppPasswordTextField from '../../common/components/form-inputs/AppPasswordTextField';
import AppRadioGroup from '../../common/components/form-inputs/AppRadioGroup';
import AppTextField from '../../common/components/form-inputs/archive/AppTextField';
import { useGetMeQuery } from '../../api/userApiSlice';
import AppSelectProperties from '../../common/components/form-inputs/AppSelectProperties';
import { PropertySummaryItem } from '../../common/interface/propertySummaryItem.interface';

export type FormValues = {
	TextField: string;
	PasswordTextField: string;
	DropDownMenu: number;
	RadioGroup: number;
	DatePicker: Date | null;
	MultipleCheckbox: any[];

	Checkbox: boolean;
	switch: boolean;
	numberFormat: number;
	properties: PropertySummaryItem[];
};

export const defaultValues: DefaultValues<FormValues> = {
	TextField: '',
	PasswordTextField: '',
	DropDownMenu: 1,
	RadioGroup: 3,
	DatePicker: null,
	MultipleCheckbox: [3, 4],

	Checkbox: false,
	switch: false,
	numberFormat: 123456789,
	properties: [],
};

const testOptions = [
	{
		id: 1,
		name: 'Option 1',
	},
	{
		id: 2,
		name: 'Option 2',
	},
	{
		id: 3,
		name: 'Option 3',
	},
	{
		id: 4,
		name: 'Option 4',
	},
];

export default function TestForm() {
	const { data } = useGetMeQuery();

	const {
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues,
	});

	const onSubmit: SubmitHandler<FormValues> = (data) =>
		alert(JSON.stringify(data));

	console.log(errors);
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Stack alignItems='flex-start' spacing={2}>
					<AppTextField control={control} name='TextField' label='Text Field' />
					<AppPasswordTextField
						control={control}
						name='PasswordTextField'
						label='Password Text Field'
					/>
					<AppDropDownMenu
						control={control}
						name='DropDownMenu'
						label='Drop Down Menu'
						options={testOptions}
					/>
					<AppRadioGroup
						control={control}
						name='RadioGroup'
						label='Radio Group'
						options={testOptions}
					/>
					<AppDatePicker
						control={control}
						name='DatePicker'
						label='Date Picker'
					/>

					<AppMultipleCheckbox
						control={control}
						name='MultipleCheckbox'
						label='Multiple Checkbox'
						options={testOptions}
					/>
					<AppSelectProperties
						control={control}
						name='properties'
						label='Properties'
					/>
					<>
						<button
							type='button'
							onClick={() => {
								reset(defaultValues);
							}}
						>
							Reset Form
						</button>
						<button>submit</button>
					</>
				</Stack>
			</form>
			<DevTool control={control} />
		</>
	);
}

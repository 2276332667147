import { ListItemButton, styled } from '@mui/material';

export const StyledListItemButton = styled(ListItemButton)({
	padding: '4px 0px 4px 10px',
	borderRadius: '5px',
	'&.Mui-selected': {
		color: '#32A5F1',
		backgroundColor: '#E8F5FD',
		border: '1px solid #E8F5FD',
	},
});

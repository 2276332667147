import { Box } from '@mui/material';
import EditorDisplay from '../../../../common/components/editor/EditorDisplay';

interface RichTextDisplayProps {
	editorStateString?: string;
}

export default function RichTextDisplay({
	editorStateString: htmlString,
}: RichTextDisplayProps) {
	return (
		<Box
			sx={{
				height: '100%',
				backgroundColor: '#F5F5F5',
				borderRadius: '5px',
				padding: '9px 10px',
			}}
		>
			<EditorDisplay editorStateString={htmlString} isTransparentBackground />
		</Box>
	);
}

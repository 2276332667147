import { useTranslation } from 'react-i18next';
import { Bed, bedOption } from '../../../../api/enum/bed.enum';
import { Grid, Stack, Typography, styled } from '@mui/material';
import { BedConfig } from '../../../../api/DTO/room.interface';

interface BedConfigsDisplayProps {
	bedConfigs: BedConfig[];
}

export default function BedConfigsDisplay({
	bedConfigs,
}: BedConfigsDisplayProps) {
	const { t } = useTranslation();

	return (
		<>
			<Grid container rowSpacing={'15px'} columnSpacing={'10px'}>
				{bedConfigs.map((bedConfig: BedConfig, index: number) => (
					<Grid item key={bedConfig.id}>
						<BedConfigContainer>
							<Typography fontSize={'10px'}>
								{t('common.Option')} {index + 1}
							</Typography>
							<Grid container rowSpacing={'0px'} columnSpacing={'0px'}>
								{Object.keys(bedConfig.beddings).map((bedType, index) => (
									<Grid item key={index}>
										<Stack direction={'row'}>
											{index !== 0 && ', '}
											{`${
												bedOption.find((option) => option.id == Number(bedType))
													?.shortName
											}: `}
											<NumberOfBed>
												{bedConfig.beddings[Number(bedType) as Bed]}
											</NumberOfBed>
										</Stack>
									</Grid>
								))}
							</Grid>
						</BedConfigContainer>
					</Grid>
				))}
			</Grid>
		</>
	);
}

const BedConfigContainer = styled(Stack)({
	padding: '8px 10px',
	color: '#32A5F1',
	fontSize: '12px',
	borderRadius: '5px',
	border: '1px solid #32A5F1',
	background: '#E8F5FD',
	whiteSpace: 'pre-wrap',
});

const NumberOfBed = styled(Typography)({
	color: '#32A5F1',
	fontSize: '12px',
	fontWeight: '700',
});

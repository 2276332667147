import Option from '../../common/types/option.type';
import { ReactComponent as GreenStatusDot } from '../../assets/icon/green-status-dot.svg';
import { ReactComponent as GreyStatusDot } from '../../assets/icon/grey-status-dot.svg';

export enum PropertyStatus {
	ACTIVE = 0,
	INACTIVE = 1,
}

export const propertyStatusOptions: Option[] = [
	{
		id: PropertyStatus.ACTIVE,
		name: 'Online',
		startIcon: <GreenStatusDot />,
	},
	{
		id: PropertyStatus.INACTIVE,
		name: 'Offline',
		startIcon: <GreyStatusDot />,
	},
];

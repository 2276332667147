import { styled } from '@mui/system';

import { Card, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledCard = styled(Card)(({ theme }) => ({
	width: 440,
	background: theme.palette.background.infoCard,
	boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
	borderRadius: 5,
	padding: 40,
}));

const HeaderWrapper = styled('div')({
	display: 'flex',
});

const StyledDivider = styled(Divider)({
	border: '1.5px solid #E4E8EA',
	marginTop: 20,
});

const ContentWrapper = styled('div')({});

interface InfoCardProps {
	title: string;
	content: JSX.Element;
}

export default function InfoCard(props: InfoCardProps) {
	const { t } = useTranslation();

	return (
		<StyledCard>
			<HeaderWrapper>
				<Typography width='100%' variant='bold' fontSize={14}>
					{props.title}
				</Typography>
				{/* <Typography
          sx={{ textDecoration: "underline" }}
          fontSize={14}
          color="text.clickableBlue"
          marginLeft={10}
        >
          {t("button.edit")}
        </Typography> */}
			</HeaderWrapper>
			<StyledDivider />
			<ContentWrapper>{props.content}</ContentWrapper>
		</StyledCard>
	);
}

import React from 'react';
import { useAppSelector } from '../../../redux-hooks';
import ApiErrorModal from './ApiErrorModal';
import DeactivateModal from './DeactivateModal';
import { ModalType, selectModalState } from './modalSlice';
import ActivateModal from './ActivateModal';
import DeleteUserModal from './DeleteUserModal';
import DeleteUserSuccessModal from './DeleteUserSuccessModal';
import ActivateBlogModal from './ActivateBlogModal';
import DeactivateBlogModal from './DeactivateBlogModal';
import DeleteGalleryImageModal from './DeleteGalleryImageModal';
import DeleteAbkEnquiryImageModal from './DeleteAbkEnquiryModal';
import DeleteAbkBookingImageModal from './DeleteAbkBookingModal';
import SendAbkCustomerEmailModal from './SendAbkCustomerEmailModal';
import SendAbkSupplierEmailModal from './SendAbkSupplierEmailModal';
import DeleteGalleryVideoModal from './DeleteGalleryVideoModal';
import DeleteSectionModal from './DeleteSectionModal';
import DeleteBkBookingModal from './DeleteBkBookingModal';

const MODAL_COMPONENTS: { [key in ModalType]: React.ElementType } = {
	[ModalType.ACTIVATE_USER]: ActivateModal,
	[ModalType.DEACTIVATE_USER]: DeactivateModal,
	[ModalType.API_ERROR]: ApiErrorModal,
	[ModalType.DELETE_USER]: DeleteUserModal,
	[ModalType.DELETE_USER_SUCCESS]: DeleteUserSuccessModal,
	[ModalType.ACTIVATE_BLOG]: ActivateBlogModal,
	[ModalType.DEACTIVATE_BLOG]: DeactivateBlogModal,
	[ModalType.DELETE_GALLERY_IMAGE]: DeleteGalleryImageModal,
	[ModalType.DELETE_GALLERY_VIDEO]: DeleteGalleryVideoModal,
	[ModalType.DELETE_ABK_ENQUIRY]: DeleteAbkEnquiryImageModal,
	[ModalType.DELETE_ABK_BOOKING]: DeleteAbkBookingImageModal,
	[ModalType.SEND_ABK_CUSTOMER_EMAIL]: SendAbkCustomerEmailModal,
	[ModalType.SEND_ABK_SUPPLIER_EMAIL]: SendAbkSupplierEmailModal,
	[ModalType.DELETE_SECTION]: DeleteSectionModal,
	[ModalType.DELETE_BK_BOOKING]: DeleteBkBookingModal,
};

const ModalRoot = () => {
	const { modalType, modalProps } = useAppSelector(selectModalState);

	if (!modalType) {
		return null;
	}

	const SpecificModal = MODAL_COMPONENTS[modalType];
	return <SpecificModal {...modalProps} />;
};

export default ModalRoot;

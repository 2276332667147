import { List, styled } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListCard from '../../../../common/components/card/ListCard';
import ListItemLinkV2 from '../../../../common/components/list/ListItemLinkV2';
import RoomList from './RoomList';

export default function TranslationListCard() {
	const { pathname } = useLocation();

	const { propertyId } = useParams();
	const { t } = useTranslation();

	return (
		<ListCard
			headerTitle={t('translation.Translation')}
			content={
				<StyledList>
					<ListItemLinkV2
						primarytext={t('translation.Overview')}
						to={`/property/${propertyId}/translation/overview`}
						selected={pathname.includes('/translation/overview')}
					/>
					<RoomList />
					<ListItemLinkV2
						primarytext={t('translation.FAQ')}
						to={`/property/${propertyId}/translation/faq`}
						selected={pathname.includes('/translation/faq')}
					/>
				</StyledList>
			}
		/>
	);
}

const StyledList = styled(List)({
	marginTop: '20px',
	padding: 0,
});

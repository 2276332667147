import { Button, Divider, Stack, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { FormCardType } from '../../../../common/components/card/FormCard';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Supplier } from '../../../../api/DTO/supplier.interface';
import { useGetSupplierQuery } from '../../../../api/supplierApiSlice';
import { SupplierIdContext } from '../ViewSupplierPage';
import { useUpdateSupplierDocUploadMutation } from '../../../../api/supplierApiSlice';
import MultipleFilesWithDescriptionField from '../../components/MultipleFilesWithDescriptionField';
import { SupplierDocumentType } from '../../../../api/enum/supplierDocumentType.enum';

interface DocumentFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function DocumentForm({ type, setType }: DocumentFormProps) {
	const { t } = useTranslation();
	const supplierId = useContext(SupplierIdContext);

	const formSchema = Yup.object().shape({});

	const { data: getSupplierResponse, isLoading: isGetSupplierLoading } =
		useGetSupplierQuery(supplierId, {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control } =
		useFormContext() ??
		useForm<Supplier>({
			defaultValues: getSupplierResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const [
		updateSupplierDocument,
		{ isSuccess: isUpdateSupplierDocumentSuccess },
	] = useUpdateSupplierDocUploadMutation();

	useEffect(() => {
		if (isUpdateSupplierDocumentSuccess) setType(FormCardType.view);
	}, [isUpdateSupplierDocumentSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateSupplierDocument({
			supplierId: supplierId ?? '',
			body: data.documents,
		});
	};

	const formContent = (
		<Stack>
			<AppSubtitleLayout label={t('supplier.Agreement')} />

			<MultipleFilesWithDescriptionField
				control={control}
				name={`documents.${SupplierDocumentType.AGREEMENT}`}
				supplierDocumentType={SupplierDocumentType.AGREEMENT}
			/>

			<Divider sx={{ marginTop: '15px', marginBottom: '15px' }} />

			<AppSubtitleLayout label={t('supplier.Other')} />

			<MultipleFilesWithDescriptionField
				control={control}
				name={`documents.${SupplierDocumentType.OTHER}`}
				supplierDocumentType={SupplierDocumentType.OTHER}
			/>

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetSupplierLoading && form}</>;
}

import { Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/components/PageHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAddPropertyMutation } from '../../api/accommodationApiSlice';
import { useEffect, useState } from 'react';
import {
	COORDINATES_PATTERN,
	NONE_INVALID_DATE_PATTERN,
} from '../../common/constants/pattern';
import { FormCardType } from '../../common/components/card/FormCard';
import BaseInfoCard from './overview/baseInfo/BaseInfoCard';
import SupplierCard from './overview/supplier/SupplierCard';
import LocationCard from './overview/location/LocationCard';
import PolicyCard from './overview/policy/PolicyCard';
import { Property } from '../../api/DTO/property.interface';
import { PriceMode } from '../../api/enum/priceMode.enum';
import { RateModel } from '../../api/enum/rateModel.enum';

export default function CreatePropertyPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();

	const [propertySummarySearchParams, setPropertySummarySearchParams] =
		useState<string | undefined>(undefined);

	useEffect(() => {
		if (state?.propertySummarySearchParams) {
			setPropertySummarySearchParams(state?.propertySummarySearchParams);
		}
	}, []);

	const backPath = propertySummarySearchParams
		? `/property?${propertySummarySearchParams}`
		: '/property';

	const defaultValues: DefaultValues<Property> = {
		baseInfo: {
			originalName: '',
			name: '',
			nameEn: '',
			owner: undefined,
			category: 0,
			starRating: 3.0,
			numOfRooms: '',
			website: '',
			phoneNum: '',
			description: '',
		},
		supplier: {
			directContract: false,
			supplier: undefined,
			currency: 'HKD',
			priceMode: PriceMode.PER_ROOM,
			rateModels: [],
			agencyConfig: {
				commissionLevel: '',
				sellAtOfficialRate: false,
			},
			merchantConfig: {
				serviceChargeIncluded: true,
				taxIncluded: true,
				commissionLevel: '',
				commissionServiceChargeIncluded: true,
				commissionTaxIncluded: true,
			},
			mappings: {
				0: undefined,
				1: undefined,
			},
		},
		location: {
			address: '',
			mapLink: '',
			coordinatesInString: '',
			withinAirportArea: false,
			nearbyAirports: [],
			nearbyRailwayStations: [],
		},
		policy: {
			minCheckInTime: undefined,
			maxCheckInTime: undefined,
			checkOutTime: undefined,
			minCheckInAge: '18',
			uniquePolicy: '',
			allowInfant: true,
			allowToddler: true,
			allowChildren: true,
			infantAge: {
				min: undefined,
				max: undefined,
			},
			toddlerAge: {
				min: undefined,
				max: undefined,
			},
			childAge: {
				min: undefined,
				max: undefined,
			},
			allowSmoking: false,
		},
	};

	const formSchema = Yup.object().shape({
		baseInfo: Yup.object()
			.shape({
				originalName: Yup.string().required(t('errorMessage.required')),
				name: Yup.string().required(t('errorMessage.required')),
				nameEn: Yup.string().required(t('errorMessage.required')),
				category: Yup.number().required(t('errorMessage.pleaseSelect')),
				starRating: Yup.number().when('category', {
					is: 0,
					then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
				}),
			})
			.required(),
		supplier: Yup.object()
			.shape({
				directContract: Yup.boolean().required(t('errorMessage.required')),
				supplier: Yup.object()
					.nullable()
					.when('directContract', {
						is: true,
						then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
					}),
				currency: Yup.string().required(t('errorMessage.pleaseSelect')),
				rateModels: Yup.array().when('directContract', {
					is: true,
					then: (schema) =>
						schema
							.min(1, t('errorMessage.pleaseSelect'))
							.required(t('errorMessage.pleaseSelect')),
				}),
				merchantConfig: Yup.object()
					.nullable()
					.when('rateModels', {
						is: (rateModels?: RateModel[]) =>
							rateModels?.includes(RateModel.MERCHANT),
						then: (schema) =>
							schema.shape({
								commissionLevel: Yup.string()
									.nullable()
									.required(t('errorMessage.pleaseSelect')),
							}),
					}),
			})
			.required(),
		location: Yup.object()
			.shape({
				country: Yup.object().required(t('errorMessage.pleaseSelect')),
				majorArea: Yup.object().required(t('errorMessage.pleaseSelect')),
				area: Yup.object().required(t('errorMessage.pleaseSelect')),
				address: Yup.string().required(t('errorMessage.required')),
				mapLink: Yup.string().required(t('errorMessage.required')),
				coordinatesInString: Yup.string()
					.required(t('errorMessage.required'))
					.matches(
						COORDINATES_PATTERN,
						t('errorMessage.incorrectCoordinatesFormat')
					),
			})
			.required(),
		policy: Yup.object()
			.shape({
				minCheckInTime: Yup.string()
					.required(t('errorMessage.required'))
					.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
				maxCheckInTime: Yup.string()
					.required(t('errorMessage.required'))
					.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
				checkOutTime: Yup.string()
					.required(t('errorMessage.required'))
					.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
				minCheckInAge: Yup.string().required(t('errorMessage.required')),
				infantAge: Yup.object().nullable().validateAgeRange(' '),
				toddlerAge: Yup.object().nullable().validateAgeRange(' '),
				childAge: Yup.object().requireAgeRange(t('errorMessage.required')),
			})
			.required(),
	});

	const [
		addProperty,
		{ data: AddPropertyResponse, isSuccess: isAddPropertySuccess },
	] = useAddPropertyMutation();

	const methods = useForm<Property>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const onSubmit: SubmitHandler<Property> = (data) => {
		// convert coordinates string to object
		const coordinatesInString = data.location.coordinatesInString;
		if (coordinatesInString) {
			data.location.coordinates = {
				latitude: parseFloat(coordinatesInString.split(/,(.*)/s)[0]),
				longitude: parseFloat(coordinatesInString.split(/,(.*)/s)[1]),
			};
		}

		addProperty(data);
	};

	useEffect(() => {
		if (isAddPropertySuccess) {
			navigate(`/property/${AddPropertyResponse?.data.baseInfo.id}`);
		}
	}, [isAddPropertySuccess]);

	return (
		<>
			<PageHeader
				showBackButton={true}
				backPath={
					propertySummarySearchParams
						? `/property?${propertySummarySearchParams}`
						: '/property'
				}
				title={t('property.CreateProperty')}
			/>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Stack spacing='16px'>
						<Grid container alignItems='stretch' spacing={'10px'}>
							<Grid item xs={6} display='flex'>
								<BaseInfoCard initialType={FormCardType.create} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<SupplierCard initialType={FormCardType.create} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<LocationCard initialType={FormCardType.create} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<PolicyCard initialType={FormCardType.create} />
							</Grid>
						</Grid>

						<Stack spacing='16px'>
							<Grid item xs={12}>
								<Stack direction='row-reverse' spacing='12px'>
									<Button type='submit' variant='contained' color='success'>
										{t('button.Save')}
									</Button>
									<Button
										variant='outlined'
										color='info'
										onClick={() => navigate(backPath)}
									>
										{t('button.Discard')}
									</Button>
								</Stack>
							</Grid>
						</Stack>
					</Stack>
				</form>
			</FormProvider>
		</>
	);
}

import {
	useWatch,
	useController,
	FieldValues,
	UseControllerProps,
} from 'react-hook-form';
import {
	FormGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	Stack,
	styled,
	Typography,
	ButtonBase,
} from '@mui/material';
import AppCheckbox from './AppCheckbox';
import Option from '../../types/option.type';

interface AppCheckboxGroupProps<T extends FieldValues>
	extends UseControllerProps<T> {
	formLabel?: string;
	errorMessage?: string;
	options: Option[];
	onChangeCallback?: (checkboxIds: string[]) => void;
	disableOption?: string;
}

const AppCheckboxGroupV2 = <T extends FieldValues>({
	name,
	control,
	formLabel,
	options,
	disableOption,
}: AppCheckboxGroupProps<T>) => {
	const {
		field: { value, onChange, ref, ...props },
	} = useController({
		name,
		control,
	});
	const checkboxIds: string[] = useWatch({ control, name }) || [];

	const handleChange = (value: string) => {
		const newArray = [...checkboxIds];

		if (newArray.length > 0) {
			//Attempt to find an item in array with matching id
			const index = newArray.findIndex((x) => x === value);

			// If theres no match add item to the array
			if (index === -1) {
				newArray.push(value);
			} else {
				//If there is a match, remove the item from the array
				newArray.splice(index, 1);
			}
		} else {
			newArray.push(value);
		}
		onChange(newArray);
	};

	return (
		<>
			<FormControl fullWidth variant='standard'>
				<FormLabel sx={{ marginBottom: '1rem' }}>
					<Stack direction='row' justifyContent='space-between'>
						<StyledFormLabelText>
							{formLabel != undefined ? formLabel : ''}
						</StyledFormLabelText>
					</Stack>
				</FormLabel>
				<FormGroup>
					{options.map((option) => (
						<StyledFormControlLabel
							ref={ref}
							key={option.id}
							disabled={option.id === disableOption}
							label={option.nameEn}
							control={
								<>
									<AppCheckbox
										checked={
											option.id === disableOption ||
											value.some((value: string) => value === option.id)
										}
										onChange={() => handleChange(option.id)}
										disabled={option.id === disableOption}
										{...props}
									/>
									{option.startIcon ? <>{option.startIcon}</> : <></>}
								</>
							}
						/>
					))}
				</FormGroup>
			</FormControl>
		</>
	);
};

const StyledFormLabelText = styled(Typography)({
	fontSize: '0.875rem',
	lineHeight: '1.125rem',
	color: '#2C2C2C !important',
	'& .Mui-focused': {
		color: 'red',
	},
});

const ClearButton = styled(ButtonBase)(({ theme }) => ({
	fontSize: '0.75rem',
	lineHeight: '0.9375rem',
	height: '100%',
	color: theme.palette.primary.main,
	textDecoration: 'underline',
	'&.Mui-disabled': {
		color: theme.palette.common.silver,
		textDecoration: 'none',
	},
}));

const StyledFormControlLabel = styled(FormControlLabel)({
	margin: '0 0 0.625rem 0',
	'& .MuiFormControlLabel-label': {
		fontSize: '0.75rem',
		lineHeight: '0.9375rem',
		fontWeight: '450',
		marginLeft: '0.25rem',
	},
	'& .MuiCheckbox-root': {
		padding: '0 8px 0 0',
	},
	'& .Mui-focused': {
		color: 'red',
	},
});

export default AppCheckboxGroupV2;

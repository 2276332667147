import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../common/components/card/FormCard';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { LocalizedProperty } from '../../../api/DTO/property.interface';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import AppTextField from '../../../common/components/form-inputs/AppTextField';
import {
	useLazyGetPropertyLocalizationQuery,
	useUpdatePropertyLocalizationMutation,
} from '../../../api/accommodationApiSlice';
import { Locale } from '../../../api/enum/locale.enum';
import LocaleDropDownMenu from './components/LocaleDropDownMenu';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../redux-hooks';
import {
	SnackbarType,
	showSnackbar,
} from '../../../common/components/snackbar/snackbarSlice';
import TranslationRow from './components/TranslationRow';
import RichTextDisplay from './components/RichTextDisplay';
import AppRichTextEditor from '../../../common/components/editor/AppRichTextEditor';

export default function OverviewTranslationCard() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { propertyId } = useParams();

	const [targetLocale, setTargetLocale] = useState<Locale>(Locale.ENGLISH);

	// load and display Chinese data
	const {
		control: chiControl,
		watch: watchChi,
		reset: resetChi,
	} = useForm<LocalizedProperty>();
	const [getPropertyLocalization] = useLazyGetPropertyLocalizationQuery();

	useEffect(() => {
		const fetchChiData = async () => {
			const chiResponse = await getPropertyLocalization({
				propertyId: propertyId ?? '',
				locale: Locale.CHINESE,
			});
			resetChi(chiResponse.data?.data);
		};
		fetchChiData();
	}, []);

	// load target locale data and set form
	const [isPrefilled, setIsPrefilled] = useState<boolean>(false);

	useEffect(() => {
		const fetchTargetLocaleData = async () => {
			setIsPrefilled(false);
			const targetLocaleResponse = await getPropertyLocalization({
				propertyId: propertyId ?? '',
				locale: targetLocale,
			});
			reset(targetLocaleResponse.data?.data);
			setIsPrefilled(true);
		};
		fetchTargetLocaleData();
	}, [targetLocale]);

	const formSchema = Yup.object().shape({
		name: Yup.string().required(t('errorMessage.required')),
		address: Yup.string().required(t('errorMessage.required')),
	});

	const { handleSubmit, control, reset } = useForm<LocalizedProperty>({
		resolver: yupResolver(formSchema),
	});

	// submit form

	const [updatePropertyLocalization, { isSuccess: isUpdateSuccess }] =
		useUpdatePropertyLocalizationMutation();

	const onSubmit: SubmitHandler<LocalizedProperty> = (data) => {
		const update = async () => {
			await updatePropertyLocalization({
				propertyId: propertyId ?? '',
				locale: targetLocale,
				body: data,
			});
		};
		update();
	};

	useEffect(() => {
		if (isUpdateSuccess) {
			dispatch(
				showSnackbar({
					snackbarType: SnackbarType.SAVE_SUCCESS,
					snackbarProps: {
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'center',
						},
						message: t('snackbar.SaveSuccessMessage'),
					},
				})
			);
		}
	}, [isUpdateSuccess]);

	return (
		<FormCard
			type={FormCardType.edit}
			title={t('translation.Overview')}
			disableContentPadding={true}
		>
			<TranslationRow
				leftNode={<Box sx={{ height: '20px' }} />}
				rightNode={<Box sx={{ height: '20px' }} />}
			/>
			<TranslationRow
				leftNode={
					<Box
						display='flex'
						justifyContent={'center'}
						marginLeft={'180px'}
						marginBottom={'20px'}
					>
						<Typography fontSize={'16px'}>繁體中文</Typography>
					</Box>
				}
				rightNode={
					<Box display='flex' justifyContent={'center'} marginBottom={'20px'}>
						<LocaleDropDownMenu
							value={targetLocale}
							onChangeCallback={(newValue) => {
								setTargetLocale(newValue);
							}}
						/>
					</Box>
				}
			/>
			<TranslationRow
				leftNode={
					<AppTextField
						multiline
						control={chiControl}
						name='name'
						label={t('property.PropertyName')}
						required
						disabled
					/>
				}
				rightNode={
					<AppTextField multiline control={control} name='name' required />
				}
			/>
			<TranslationRow
				leftNode={<Divider sx={{ marginBottom: '15px' }} />}
				rightNode={<Divider sx={{ marginBottom: '15px' }} />}
			/>
			<TranslationRow
				leftNode={
					<AppTextField
						multiline
						control={chiControl}
						name='address'
						label={t('property.AddressInDetail')}
						required
						disabled
					/>
				}
				rightNode={<AppTextField multiline control={control} name='address' />}
			/>
			<TranslationRow
				leftNode={<Divider sx={{ marginBottom: '15px' }} />}
				rightNode={<Divider sx={{ marginBottom: '15px' }} />}
			/>
			<TranslationRow
				leftNode={
					<AppTextField
						multiline
						control={chiControl}
						name='description'
						label={t('property.PropertyDescription')}
						disabled
					/>
				}
				rightNode={
					<AppTextField
						multiline
						control={control}
						name='description'
						required
					/>
				}
			/>
			<TranslationRow
				leftNode={
					<AppInputLayout
						label={t('property.UniquePolicy')}
						labelAlignItemsFlexStart
					>
						<RichTextDisplay editorStateString={watchChi('uniquePolicy')} />
					</AppInputLayout>
				}
				rightNode={
					isPrefilled && (
						<AppRichTextEditor control={control} name='uniquePolicy' />
					)
				}
			/>
			<TranslationRow
				leftNode={<></>}
				rightNode={
					<Stack spacing='16px' marginBottom={'25px'}>
						<Grid item xs={12}>
							<Stack direction='row-reverse' spacing='12px'>
								<Button
									type='submit'
									variant='contained'
									color='success'
									onClick={handleSubmit(onSubmit)}
								>
									{t('button.Save')}
								</Button>
								<Button
									variant='outlined'
									color='info'
									onClick={() => {
										navigate(`/property/${propertyId}/translation`);
									}}
								>
									{t('button.Discard')}
								</Button>
							</Stack>
						</Grid>
					</Stack>
				}
			/>
		</FormCard>
	);
}

import { Grid, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetSupplierQuery } from '../../../api/supplierApiSlice';
import FullScreenLoader from '../../../common/components/FullScreenLoader';

import BaseInfoCard from './baseInfo/BaseInfoCard';
import BankDetailsCard from './bankDetail/BankDetailCard';
import ContactPointCard from './contactPoint/ContactPointCard';
import DocumentCard from './document/DocumentCard';
import { FormCardType } from '../../../common/components/card/FormCard';
import { createContext } from 'react';

export const SupplierIdContext = createContext('');

export default function ViewSupplierPage() {

    const { supplierId } = useParams();

    const {
		data: getSupplierResponse,
		isLoading: isGetSupplierLoading,
		isSuccess: isGetSupplierSuccess,
		isError: isGetSupplierError,
	} = useGetSupplierQuery(supplierId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	let pageContent;
    if (isGetSupplierLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetSupplierSuccess) {
		pageContent = (
			<>
				<SupplierIdContext.Provider value={supplierId ?? ''}>
					<Stack spacing='16px'>
						<Grid container alignItems='stretch' spacing={'10px'}>
							<Grid item xs={6} display='flex'>
								<BaseInfoCard initialType={FormCardType.view} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<BankDetailsCard initialType={FormCardType.view} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<ContactPointCard initialType={FormCardType.view} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<DocumentCard initialType={FormCardType.view} />
							</Grid>
						</Grid>
					</Stack>
				</SupplierIdContext.Provider>
			</>
		);
	} else if (isGetSupplierError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}
import { createTheme, popoverClasses } from '@mui/material';
import './App.css';

declare module '@mui/material/styles/createPalette' {
	interface TypeBackground {
		footer: string;
		navigationDrawer: string;
		infoCard: string;
	}

	interface TypeText {
		grey: string;
		clickableBlue: string;
		green: string;
		silver: string;
		placeholder: string;
	}

	interface CommonColors {
		whisper: string;
		silver: string;
	}
}

declare module '@mui/material/styles' {
	interface Palette {
		dateRangePicker: {
			startEndDateBg: string;
			startEndDateText: string;
			startEndEdgeBg: string;
			rangeBgColor: string;
			hoverDateBg: string;
			hoverDateText: string;
			disableBg: string;
			disableText: string;
		};
	}

	interface PaletteOptions {
		dateRangePicker: {
			startEndDateBg: string;
			startEndDateText: string;
			startEndEdgeBg: string;
			rangeBgColor: string;
			hoverDateBg: string;
			hoverDateText: string;
			disableBg: string;
			disableText: string;
		};
	}

	interface TypographyVariants {
		regular: React.CSSProperties;
		medium: React.CSSProperties;
		bold: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		regular?: React.CSSProperties;
		medium?: React.CSSProperties;
		bold?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		regular: true;
		medium: true;
		bold: true;
	}
}

export const mainTheme = createTheme({
	breakpoints: {
		values: {
			xs: 500,
			sm: 768,
			md: 992,
			lg: 1160,
			xl: 1352,
		},
	},
	palette: {
		primary: {
			main: '#32A5F1',
			light: '#F4FBFF',
			contrastText: '#FFFFFF',
		},
		success: {
			main: '#32A5F1',
			contrastText: '#fff',
		},
		info: {
			main: '#BCBCBC',
			contrastText: '#FFFFFF',
		},
		warning: {
			main: '#DA5150',
			contrastText: '#DA5150',
		},
		background: {
			default: '#F5F5F5',
			footer: '#43464B',
			navigationDrawer: '#FFFFFF',
			infoCard: '#FFFFFF',
		},
		text: {
			primary: '#2C2C2C',
			secondary: '#000000',
			grey: '#808080',
			clickableBlue: '#4EA4D3',
			green: '#4B9831',
			silver: '#BCBCBC',
			placeholder: '#BCBCBC',
		},
		common: {
			whisper: '#EAEAEA',
			silver: '#BCBCBC',
		},
		dateRangePicker: {
			startEndDateBg: '#BEE5FA',
			startEndDateText: '#4ea4d3',
			startEndEdgeBg: 'rgba(78, 164, 211, 0.1)', // * startEndEdge's color should be same as rangeColor
			rangeBgColor: 'rgba(78, 164, 211, 0.1)',
			hoverDateBg: '#BEE5FA',
			hoverDateText: '#4ea4d3',
			disableBg: 'transparent',
			disableText: '#a8a8a8',
		},
		error: {
			main: '#F6403F',
		},
	},
	typography: {
		fontFamily: ['Circular-Std', 'Noto Sans HK', 'sans-serif'].join(','),
		fontSize: 16,
		regular: {
			fontFamily: ['Circular-Std', 'Noto Sans HK', 'sans-serif'].join(','),
			fontWeight: 450,
		},
		medium: {
			fontFamily: ['Circular-Std', 'Noto Sans HK', 'sans-serif'].join(','),
			fontWeight: 500,
		},
		bold: {
			fontFamily: ['Circular-Std', 'Noto Sans HK', 'sans-serif'].join(','),
			fontWeight: 700,
		},
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					minWidth: '122px',
					padding: '5px 16px 5px 16px',
					boxShadow: 'none',
					textTransform: 'none',
					fontWeight: 'bold',
					borderRadius: '5px',
					'&:hover': {
						boxShadow: 'none',
					},
				},
				sizeSmall: {
					fontSize: '1rem',
				},
				sizeMedium: {
					fontSize: '1rem',
				},
				sizeLarge: {
					fontSize: '1.5rem',
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiIconButton: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiMenu: {
			defaultProps: {
				sx: {
					[`&.${popoverClasses.root}`]: {
						'& .MuiBackdrop-root': {
							backgroundColor: 'transparent',
						},
					},
				},
			},
		},
		MuiBackdrop: {
			defaultProps: {
				sx: {
					backgroundColor: 'rgba(0,0,0, 0.32)',
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				sx: {
					color: '#BCBCBC',
					'&.Mui-checked': {
						color: '#BCBCBC',
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					transform: 'none',
				},
			},
			defaultProps: {
				sx: {
					fontSize: '14px',
					color: 'text.primary',
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '10px',
					backgroundColor: 'rgba(44, 44, 44, 0.9)',
					whiteSpace: 'break-spaces',
					margin: '0px',
					padding: '8px',
					borderRadius: '2px',
					transform: 'none',
				},
			},
		},
		MuiFormHelperText: {
			defaultProps: {
				sx: {
					fontSize: '10px',
					color: '#808080',
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				container: {
					transition: 'none',
					backgroundColor: 'rgba(44, 44, 44, 0.6)',
				},
				paper: {
					borderRadius: '10px',
					background: '#FFFFFF',
					boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
					width: '430px',
					padding: '16px 28px 24px 28px',
				},
			},
		},
		MuiAccordion: {
			defaultProps: {
				sx: {
					border: '1px solid #EAEAEA',
					boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
					borderRadius: '5px',
					'&:before': {
						display: 'none',
					},
				},
			},
		},
		MuiChip: {
			defaultProps: {
				sx: {
					height: 'auto',
					maxWidth: '800px',

					background: 'rgba(78, 164, 211, 0.1)',
					border: '1px solid #4EA4D3',
					'& .MuiChip-label': {
						padding: '5px 12px',
						display: 'block',
						whiteSpace: 'normal',
					},
					'& .MuiChip-label span': {
						color: '#4EA4D3',
						fontSize: '12px',
						lineHeight: '12px',
					},
				},
			},
		},
		MuiTabs: {
			defaultProps: {
				sx: {
					textTransform: 'none',
					'.Mui-selected': {
						fontWeight: 700,
					},
					'& .MuiTabs-indicator': {
						height: '6px',
						borderRadius: '2px 2px 0px 0px',
					},
				},
			},
		},
		MuiTab: {
			defaultProps: {
				sx: {
					minWidth: '0px',
					minHeight: '0px',
					maxHeight: '60px',
					margin: '0px 15px',
					padding: '20px 0px',
					textTransform: 'none',
					fontSize: '16px',
					whiteSpace: 'pre-wrap',
				},
			},
		},
		MuiTypography: {
			defaultProps: {
				sx: {
					wordBreak: 'break-word',
				},
			},
		},
	},
});

export default mainTheme;

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../common/components/card/FormCard';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import AppTextField from '../../../common/components/form-inputs/AppTextField';
import {
	useLazyGetCustomFaqLocalizationQuery,
	useUpdateCustomFaqLocalizationMutation,
} from '../../../api/accommodationApiSlice';
import { Locale } from '../../../api/enum/locale.enum';
import LocaleDropDownMenu from './components/LocaleDropDownMenu';
import { useAppDispatch } from '../../../redux-hooks';
import {
	SnackbarType,
	showSnackbar,
} from '../../../common/components/snackbar/snackbarSlice';
import TranslationRow from './components/TranslationRow';
import { Faq } from '../../../api/DTO/faq.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function FAQTranslationCard() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { propertyId } = useParams();

	const [targetLocale, setTargetLocale] = useState<Locale>(Locale.ENGLISH);

	// load and display Chinese data
	const { control: chiControl, reset: resetChi } = useForm<Faq>();
	const [getCustomFaqLocalization] = useLazyGetCustomFaqLocalizationQuery();

	useEffect(() => {
		const fetchChiData = async () => {
			const chiResponse = await getCustomFaqLocalization({
				propertyId: propertyId ?? '',
				locale: Locale.CHINESE,
			});
			resetChi({ Faq: chiResponse.data?.data });
		};
		fetchChiData();
	}, []);

	// load target locale data and set form
	useEffect(() => {
		const fetchTargetLocaleData = async () => {
			const targetLocaleResponse = await getCustomFaqLocalization({
				propertyId: propertyId ?? '',
				locale: targetLocale,
			});
			reset({ Faq: targetLocaleResponse.data?.data });
		};
		fetchTargetLocaleData();
	}, [targetLocale]);

	const formSchema = Yup.object().shape({
		Faq: Yup.array().of(
			Yup.object()
				.shape(
					{
						question: Yup.string()
							.nullable()
							.when('answer', {
								is: (answer?: string | null) =>
									answer !== '' && answer !== null && answer !== undefined,
								then: (schema) => schema.required(t('errorMessage.required')),
							}),
						answer: Yup.string()
							.nullable()
							.when('question', {
								is: (question?: string | null) =>
									question !== '' &&
									question !== null &&
									question !== undefined,
								then: (schema) => schema.required(t('errorMessage.required')),
							}),
					},
					[
						['question', 'answer'],
						['answer', 'question'],
					]
				)
				.required()
		),
	});

	const { handleSubmit, control, reset } = useForm<Faq>({
		resolver: yupResolver(formSchema),
	});

	const { fields } = useFieldArray({
		control,
		name: 'Faq',
	});

	// submit form

	const [updateCustomFaqLocalization, { isSuccess: isUpdateSuccess }] =
		useUpdateCustomFaqLocalizationMutation();

	const onSubmit: SubmitHandler<Faq> = (formResult) => {
		const update = async () => {
			await updateCustomFaqLocalization({
				propertyId: propertyId ?? '',
				locale: targetLocale,
				body: formResult.Faq,
			});
		};
		update();
	};

	useEffect(() => {
		if (isUpdateSuccess) {
			dispatch(
				showSnackbar({
					snackbarType: SnackbarType.SAVE_SUCCESS,
					snackbarProps: {
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'center',
						},
						message: t('snackbar.SaveSuccessMessage'),
					},
				})
			);
		}
	}, [isUpdateSuccess]);

	return (
		<FormCard
			type={FormCardType.edit}
			title={t('translation.FAQ')}
			disableContentPadding={true}
		>
			<TranslationRow
				leftNode={<Box sx={{ height: '20px' }} />}
				rightNode={<Box sx={{ height: '20px' }} />}
			/>
			<TranslationRow
				leftNode={
					<Box
						display='flex'
						justifyContent={'center'}
						marginLeft={'180px'}
						marginBottom={'20px'}
					>
						<Typography fontSize={'16px'}>繁體中文</Typography>
					</Box>
				}
				rightNode={
					<Box display='flex' justifyContent={'center'} marginBottom={'20px'}>
						<LocaleDropDownMenu
							value={targetLocale}
							onChangeCallback={(newValue) => {
								setTargetLocale(newValue);
							}}
						/>
					</Box>
				}
			/>

			{fields.map((item, index) => {
				return (
					<div key={item.id}>
						<TranslationRow
							leftNode={<Divider sx={{ marginBottom: '15px' }} />}
							rightNode={<Divider sx={{ marginBottom: '15px' }} />}
						/>

						<TranslationRow
							leftNode={
								<AppTextField
									multiline
									control={chiControl}
									name={`Faq.${index}.question`}
									label={`Custom Question (${index + 1})`}
									disabled
								/>
							}
							rightNode={
								<AppTextField
									multiline
									control={control}
									name={`Faq.${index}.question`}
								/>
							}
						/>

						<TranslationRow
							leftNode={
								<AppTextField
									multiline
									control={chiControl}
									name={`Faq.${index}.answer`}
									label={'Answer'}
									disabled
								/>
							}
							rightNode={
								<AppTextField
									multiline
									control={control}
									name={`Faq.${index}.answer`}
								/>
							}
						/>
					</div>
				);
			})}

			<TranslationRow
				leftNode={<></>}
				rightNode={
					<Stack spacing='16px' marginBottom={'25px'}>
						<Grid item xs={12}>
							<Stack direction='row-reverse' spacing='12px'>
								<Button
									type='submit'
									variant='contained'
									color='success'
									onClick={handleSubmit(onSubmit)}
								>
									{t('button.Save')}
								</Button>
								<Button
									variant='outlined'
									color='info'
									onClick={() => {
										navigate(`/property/${propertyId}/translation`);
									}}
								>
									{t('button.Discard')}
								</Button>
							</Stack>
						</Grid>
					</Stack>
				}
			/>
		</FormCard>
	);
}

import { Button, Grid, Stack } from '@mui/material';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import PageHeader from '../../common/components/PageHeader';
import { FormCardType } from '../../common/components/card/FormCard';
import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import BlogPostCard from './overview/blogPost/blogPostCard';
import { BlogPost } from '../../api/DTO/blogPost.interface';
import { useAddBlogPostMutation } from '../../api/contentApiSlice';

export default function CreateBlogPostPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();

	const [blogPostSummarySearchParams, setBlogPostSummarySearchParams] =
		useState<string | undefined>(undefined);

	useEffect(() => {
		if (state?.blogPostSummarySearchParams) {
			setBlogPostSummarySearchParams(state?.blogPostSummarySearchParams);
		}
	}, []);

	const [
		addBlogPost,
		{ data: AddBlogPostResponse, isSuccess: isAddBlogPostSuccess },
	] = useAddBlogPostMutation();

	const defaultValues: DefaultValues<BlogPost> = {
		category: undefined,
		title: undefined,
		description: undefined,
		content: undefined,
		images: [],
	};

	const formSchema = Yup.object().shape({
		title: Yup.string().required(t('errorMessage.required')),
		category: Yup.object().required(t('errorMessage.pleaseSelect')),
	});

	const methods = useForm<BlogPost>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		if (AddBlogPostResponse) {
			navigate(`/blog/${AddBlogPostResponse?.data.id}`);
		}
	}, [AddBlogPostResponse]);

	const onSubmit: SubmitHandler<BlogPost> = (data) => {
		addBlogPost(data);
	};

	return (
		<>
			<PageHeader
				showBackButton={true}
				backPath={
					blogPostSummarySearchParams
						? `/blog?${blogPostSummarySearchParams}`
						: '/blog'
				}
				title={t('blogPost.CreateBlogPost')}
			/>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Stack spacing='16px'>
						<Grid container alignItems='stretch' spacing={'10px'}>
							<Grid item xs={12} display='flex'>
								<BlogPostCard initialType={FormCardType.create} />
							</Grid>
						</Grid>

						<Stack spacing='16px'>
							<Grid item xs={12}>
								<Stack direction='row-reverse' spacing='12px'>
									<Button type='submit' variant='contained' color='success'>
										{t('button.Save')}
									</Button>
									<Button
										variant='outlined'
										color='info'
										onClick={() => navigate('/blog')}
									>
										{t('button.Discard')}
									</Button>
								</Stack>
							</Grid>
						</Stack>
					</Stack>
				</form>
			</FormProvider>
		</>
	);
}

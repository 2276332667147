import { yupResolver } from '@hookform/resolvers/yup';
import {
	Box,
	Button,
	Divider,
	Grid,
	InputAdornment,
	Stack,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
	Controller,
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useFieldArray,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import util from 'util';
import * as Yup from 'yup';
import { PropertyMealSupplementList } from '../../../api/DTO/propertyMealSupplement.interface';
import {
	useGetMealPlanSupplementQuery,
	useGetPropertyQuery,
	useUpdateMealPlanSupplementMutation,
} from '../../../api/accommodationApiSlice';
import { markupTypeOptions } from '../../../api/enum/markupType.enum';
import { getMealTypeNameByMealType } from '../../../api/enum/mealType.enum';
import { RateModel, rateModelList } from '../../../api/enum/rateModel.enum';
import { ReactComponent as AddIcon } from '../../../assets/icon/button-add.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/delete.svg';
import AppAlert from '../../../common/components/AppAlert';
import RateModelToggleButton from '../../../common/components/RateModelToggleButton';
import { FormCardType } from '../../../common/components/card/FormCard';
import AppAddButton from '../../../common/components/form-inputs/AppAddButton';
import AppCheckbox from '../../../common/components/form-inputs/AppCheckbox';
import AppDatePicker from '../../../common/components/form-inputs/AppDatePicker';
import AppDropDownMenu from '../../../common/components/form-inputs/AppDropDownMenu';
import { StyledFormControlLabel } from '../../../common/components/form-inputs/AppMultipleCheckbox';
import AppTextField, {
	InputType,
} from '../../../common/components/form-inputs/AppTextField';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../common/components/form-layout/AppSubtitleLayout';
import { NONE_INVALID_DATE_PATTERN } from '../../../common/constants/pattern';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

interface MealFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function MealForm({ type, setType }: MealFormProps) {
	const { t } = useTranslation();
	const { propertyId, mealTypeId } = useParams();
	const [mealName, setMealName] = useState('');

	const [updateMealPlanSupplement, { isSuccess: isUpdateSuccess }] =
		useUpdateMealPlanSupplementMutation();
	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');

	const [rateModel, setRateModel] = useState<RateModel>(
		getPropertyResponse?.data?.supplier.rateModels?.includes(RateModel.AGENCY)
			? RateModel.AGENCY
			: getPropertyResponse?.data?.supplier.rateModels?.[0] ?? RateModel.AGENCY
	);

	const { data: getMealPlanSupplementResponse, refetch } =
		useGetMealPlanSupplementQuery(
			{
				propertyId: propertyId ?? '',
				mealTypeId: mealTypeId ?? '',
				rateModelId: rateModel.toString() ?? '',
			},
			{
				refetchOnMountOrArgChange: true,
			}
		);

	const formSchema = Yup.object().shape({
		merchantConfig: Yup.object()
			.nullable()
			.when([], {
				is: () =>
					getMealPlanSupplementResponse?.data.rateModel === RateModel.MERCHANT,
				then: (schema) =>
					schema.shape({
						commissionLevel: Yup.string().required(t('errorMessage.required')),
					}),
			}),
		mealSupplements: Yup.array().of(
			Yup.object().shape({
				markup: Yup.object()
					.nullable()
					.when([], {
						is: () =>
							getMealPlanSupplementResponse?.data.sellAtOfficialRate ===
								false &&
							getMealPlanSupplementResponse?.data.rateModel ===
								RateModel.AGENCY,
						then: (schema) =>
							schema.shape({
								amount: Yup.string().required(t('errorMessage.required')),
								type: Yup.string().required(t('errorMessage.pleaseSelect')),
							}),
					}),
				adultRate: Yup.string().required(
					t('errorMessage.fillInZeroIfNoSupplement')
				),
				childRate: Yup.string().required(
					t('errorMessage.fillInZeroIfNoSupplement')
				),
				toddlerRate: Yup.string().required(
					t('errorMessage.fillInZeroIfNoSupplement')
				),
				infantRate: Yup.string().required(
					t('errorMessage.fillInZeroIfNoSupplement')
				),
				validPeriod: Yup.object().shape({
					start: Yup.string()
						.required(t('errorMessage.required'))
						.matches(
							NONE_INVALID_DATE_PATTERN,
							t('errorMessage.incorrectTime')
						),
					end: Yup.string()
						.required(t('errorMessage.required'))
						.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
						.when('start', (start: any, schema) => {
							return schema.test(
								'shouldGreaterThanStart',
								() => t('errorMessage.incorrectPeriodTime'),
								(value) =>
									!!start && dayjs(value).isSameOrAfter(dayjs(start), 'day')
							);
						}),
				}),
			})
		),
	});

	const defaultValues: DefaultValues<PropertyMealSupplementList> =
		getMealPlanSupplementResponse?.data ?? {
			currency: ' ',
			mealSupplements: [],
		};

	useEffect(() => {
		refetch();
		setValue(
			'mealSupplements',
			getMealPlanSupplementResponse?.data.mealSupplements ?? []
		);
		setValue(
			'merchantConfig',
			getMealPlanSupplementResponse?.data.merchantConfig
		);

		setMealName(
			t(`mealSupplement.${getMealTypeNameByMealType(Number(mealTypeId))}`)
		);
	}, [rateModel, mealTypeId, getMealPlanSupplementResponse]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		for (let i = 0; i < data.mealSupplements.length; i++) {
			data.mealSupplements[i].mealType = mealTypeId;
			data.mealSupplements[i].rateModel = rateModel;

			if (
				!(
					getMealPlanSupplementResponse?.data.sellAtOfficialRate === false &&
					getMealPlanSupplementResponse?.data.rateModel === RateModel.AGENCY
				)
			) {
				data.mealSupplements[i].markup = null;
			}
		}

		if (data.mealSupplements == undefined) {
			data.mealSupplements = [];
		}

		if (data.currency == undefined) {
			data.currency = ' ';
		}

		updateMealPlanSupplement({
			propertyId: propertyId ?? '',
			mealTypeId: mealTypeId ?? '',
			rateModelId: rateModel.toString() ?? '',
			body: data,
		});
	};

	useEffect(() => {
		if (isUpdateSuccess) {
			setType(FormCardType.view);
			// dispatch(
			// 	showSnackbar({
			// 		snackbarType: SnackbarType.SAVE_SUCCESS,
			// 		snackbarProps: {
			// 			anchorOrigin: {
			// 				vertical: 'bottom',
			// 				horizontal: 'center',
			// 			},
			// 			message: t('snackbar.SaveSuccessMessage'),
			// 		},
			// 	})
			// );
		}
	}, [isUpdateSuccess]);

	const { handleSubmit, control, setValue, watch, clearErrors } =
		useFormContext() ??
		useForm<PropertyMealSupplementList>({
			defaultValues,
			resolver: yupResolver(formSchema),
		});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'mealSupplements',
	});

	const watchMealSupplements = watch('mealSupplements');

	useEffect(() => {
		clearErrors('mealSupplements');
	}, [rateModel, mealTypeId, fields]);

	return (
		<>
			<Grid container>
				<Grid item xs={8}>
					{rateModel === RateModel.MERCHANT && (
						<>
							<Controller
								name={`merchantConfig.serviceChargeIncluded`}
								control={control}
								render={({ field }) => (
									<StyledFormControlLabel
										key={`merchantConfig.serviceChargeIncluded`}
										control={
											<AppCheckbox
												checked={field.value}
												{...field}
												ref={null}
											/>
										}
										label={t('mealSupplement.ServiceChargeIncluded')}
									/>
								)}
							/>
							<Controller
								name={`merchantConfig.taxIncluded`}
								control={control}
								render={({ field }) => (
									<StyledFormControlLabel
										key={`merchantConfig.taxIncluded`}
										control={
											<AppCheckbox
												checked={field.value}
												{...field}
												ref={null}
											/>
										}
										label={t('mealSupplement.TaxIncluded')}
									/>
								)}
							/>

							<Controller
								name={`merchantConfig.commissionServiceChargeIncluded`}
								control={control}
								render={({ field }) => (
									<StyledFormControlLabel
										key={`merchantConfig.commissionServiceChargeIncluded`}
										control={
											<AppCheckbox
												checked={field.value}
												{...field}
												ref={null}
											/>
										}
										label={t('mealSupplement.CommissionIncludedServiceCharge')}
									/>
								)}
							/>
							<Controller
								name={`merchantConfig.commissionTaxIncluded`}
								control={control}
								render={({ field }) => (
									<StyledFormControlLabel
										key={`merchantConfig.commissionTaxIncluded`}
										control={
											<AppCheckbox
												checked={field.value}
												{...field}
												ref={null}
											/>
										}
										label={t('mealSupplement.CommissionIncludedTax')}
									/>
								)}
							/>
							<AppInputLayout
								label={t('mealSupplement.CommissionLevel')}
								removeErrorPadding
								required
							>
								<Box
									display='grid'
									gap='60px'
									gridTemplateColumns='repeat(2, 1fr)'
								>
									<AppTextField
										control={control}
										name='merchantConfig.commissionLevel'
										inputType={InputType.number}
										inputProps={{ maxLength: 3 }}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>%</InputAdornment>
											),
										}}
									/>
								</Box>
							</AppInputLayout>
						</>
					)}
				</Grid>
				<Grid
					item
					xs={4}
					display={'flex'}
					justifyContent={'end'}
					alignItems={'start'}
					width={'100%'}
				>
					<RateModelToggleButton
						value={rateModel}
						handleChange={(_, newRateModel) => {
							if (newRateModel != null) setRateModel(newRateModel);
						}}
						disableRateModel={rateModelList.filter(
							(rateModel) =>
								!getPropertyResponse?.data.supplier.rateModels?.includes(
									rateModel
								)
						)}
					/>
				</Grid>
			</Grid>

			{watchMealSupplements.length === 0 && (
				<>
					<Box marginTop={'1.5rem'}>
						<AppAlert alertColor='#808080'>
							{util.format(
								t('mealSupplement.NoMealSupplementMessage'),
								mealName.toLowerCase(),
								mealName.toLowerCase()
							)}
						</AppAlert>
					</Box>
					<Box margin={'1.5rem'} display={'flex'} justifyContent={'center'}>
						<AppAddButton
							onClick={() => {
								append({
									adultRate: '',
									childRate: '',
									toddlerRate: '',
									infantRate: '',
									markup: {
										amount: null,
										type: null,
									},
									validPeriod: {
										start: '',
										end: '',
									},
								});
							}}
						>
							<AddIcon />
							{t('button.Add')}
						</AppAddButton>
					</Box>
				</>
			)}

			{watchMealSupplements.length !== 0 && (
				<>
					<Stack spacing={'20px'}>
						<Stack spacing={'15px'}>
							{fields.map((mealCardItem, index) => {
								const markupType = watch(
									`mealSupplements.${index}.markup.type`
								);

								return (
									<div key={mealCardItem.id}>
										<Box
											display={'flex'}
											justifyContent={'space-between'}
											marginTop={'1.5rem'}
										>
											<AppSubtitleLayout label={`${mealName} ${index + 1}`} />
											<DeleteIcon
												color='#F6403F'
												cursor={'pointer'}
												onClick={() => remove(index)}
											/>
										</Box>

										<Grid container columns={15} columnSpacing={5}>
											<Grid item xs={7}>
												<AppTextField
													label={t('mealSupplement.Adult')}
													control={control}
													name={`mealSupplements.${index}.adultRate`}
													inputType={InputType.amount}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																{getMealPlanSupplementResponse?.data.currency}
															</InputAdornment>
														),
													}}
												/>
											</Grid>
											<Grid item xs={8}>
												{getMealPlanSupplementResponse?.data
													.sellAtOfficialRate === false &&
													getMealPlanSupplementResponse?.data.rateModel ===
														RateModel.AGENCY && (
														<AppTextField
															label={t('mealSupplement.MarkUp')}
															control={control}
															name={`mealSupplements.${index}.markup.amount`}
															inputType={InputType.amount}
															required
															InputProps={{
																startAdornment: (
																	<>
																		{markupType == 1 && (
																			<InputAdornment position='start'>
																				{
																					getMealPlanSupplementResponse?.data
																						.currency
																				}
																			</InputAdornment>
																		)}
																	</>
																),
															}}
														/>
													)}
											</Grid>
											<Grid item xs={7}>
												<AppTextField
													label={t('mealSupplement.Child')}
													control={control}
													name={`mealSupplements.${index}.childRate`}
													inputType={InputType.amount}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																{getMealPlanSupplementResponse?.data.currency}
															</InputAdornment>
														),
													}}
												/>
											</Grid>
											<Grid item xs={8}>
												{getMealPlanSupplementResponse?.data
													.sellAtOfficialRate === false &&
													getMealPlanSupplementResponse?.data.rateModel ===
														RateModel.AGENCY && (
														<AppDropDownMenu
															control={control}
															name={`mealSupplements.${index}.markup.type`}
															label={' '}
															options={markupTypeOptions}
														/>
													)}
											</Grid>

											<Grid item xs={7}>
												<AppTextField
													label={t('mealSupplement.Toddler')}
													control={control}
													name={`mealSupplements.${index}.toddlerRate`}
													inputType={InputType.amount}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																{getMealPlanSupplementResponse?.data.currency}
															</InputAdornment>
														),
													}}
												/>
											</Grid>
											<Grid item xs={8}></Grid>

											<Grid item xs={7}>
												<AppTextField
													label={t('mealSupplement.Infant')}
													control={control}
													name={`mealSupplements.${index}.infantRate`}
													inputType={InputType.amount}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																{getMealPlanSupplementResponse?.data.currency}
															</InputAdornment>
														),
													}}
												/>
											</Grid>
											<Grid item xs={8}>
												<AppInputLayout
													label={t('mealSupplement.ValidPeriod')}
													removeErrorPadding
													required
												>
													<Box
														display='grid'
														gap='10px'
														gridTemplateColumns='repeat(2, 1fr)'
													>
														<AppDatePicker
															control={control}
															name={`mealSupplements.${index}.validPeriod.start`}
														/>
														<AppDatePicker
															control={control}
															name={`mealSupplements.${index}.validPeriod.end`}
															minimumDate={watch(
																`mealSupplements.${index}.validPeriod.start`
															)}
														/>
													</Box>
												</AppInputLayout>
											</Grid>
										</Grid>
										{index !== fields.length - 1 && (
											<Divider sx={{ marginTop: '1.5rem' }} />
										)}
									</div>
								);
							})}
						</Stack>
					</Stack>
					<Box margin={'1.5rem'} display={'flex'} justifyContent={'center'}>
						<AppAddButton
							onClick={() => {
								append({
									mealType: mealTypeId,
									rateModel: rateModel,
									adultRate: '',
									childRate: '',
									markup: {
										amount: null,
										type: null,
									},
									toddlerRate: '',
									infantRate: '',
									validPeriod: {
										start: '',
										end: '',
									},
								});
							}}
						>
							<AddIcon />
							{t('button.Add')}
						</AppAddButton>
					</Box>
				</>
			)}

			<Stack spacing='16px' marginBottom={'25px'}>
				<Grid item xs={12}>
					<Stack direction='row-reverse' spacing='12px'>
						<Button
							type='submit'
							variant='contained'
							color='success'
							onClick={handleSubmit(onSubmit)}
						>
							{t('button.Save')}
						</Button>
						<Button
							variant='outlined'
							color='info'
							onClick={() => {
								setType(FormCardType.view);
							}}
						>
							{t('button.Discard')}
						</Button>
					</Stack>
				</Grid>
			</Stack>
		</>
	);
}

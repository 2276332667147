import { styled } from '@mui/system';

import { Box, Stack, Tooltip, Typography } from '@mui/material';
import Option from '../../common/types/option.type';
import { ReactComponent as RakutenIcon } from '../../assets/icon/Rakuten.svg';
import { ReactComponent as MeituanIcon } from '../../assets/icon/Meituan.svg';
import { ReactComponent as CloseIcon } from '../../assets/icon/close10.svg';
import { ReactComponent as PasteIcon } from '../../assets/icon/paste.svg';
import { useTranslation } from 'react-i18next';
import { ApiSupplierType } from '../../api/enum/ApiSupplier.enum';
import { ReactNode } from 'react';

const Container = styled(Box)({
	background: '#E8F5FD',
	borderRadius: '5px',
	padding: '5px 10px',
	marginBottom: '20px',
});

interface SelectedApiMappingProps {
	apiSupplier: ApiSupplierType;
	option: Option;
	onDeleteClick: () => void;
	returnRateNode?: ReactNode;
}

export default function SelectedApiMapping({
	apiSupplier,
	option,
	onDeleteClick,
	returnRateNode,
}: SelectedApiMappingProps) {
	const { t } = useTranslation();

	return (
		<Container>
			<Stack>
				<Box display={'flex'} alignItems={'center'}>
					{apiSupplier === ApiSupplierType.RAKUTEN && <RakutenIcon />}
					{apiSupplier === ApiSupplierType.MEITUAN && <MeituanIcon />}
					<Typography
						width={'100%'}
						fontSize={'12px'}
						fontWeight={500}
						marginLeft={'10px'}
						marginRight={'10px'}
					>
						{option.id} {option.name}
					</Typography>
					<CloseIcon cursor={'pointer'} onClick={onDeleteClick} />
				</Box>

				{option.address && (
					<Typography fontSize={'12px'} marginLeft={'30px'}>
						{option.address}
						{/* <Box component={'span'} sx={{ marginLeft: '5px' }}>
							<Tooltip
								title={t('toolTip.AddressCopyAndPaste')}
								placement='bottom-start'
							>
								<PasteIcon cursor={'pointer'} />
							</Tooltip>
						</Box> */}
					</Typography>
				)}

				{returnRateNode && <Box marginLeft={'30px'}>{returnRateNode}</Box>}
			</Stack>
		</Container>
	);
}

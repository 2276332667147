import { apiSlice } from './apiSlice';
import { AddUserRequest } from './request/addUser.request';
import { UpdateUserRequest } from './request/updateUser.request';
import { FilterUserResponse } from './response/filterUser.response';
import { GetMeResponse } from './response/getMe.reponse';
import { AddUserResponse } from './response/addUser.response';
import { GetUserResponse } from './response/getUser.response';
import { UpdateUserResponse } from './response/updateUser.response';
import { UpdateUserStatusRequest } from './request/updateUserStatus.request';
import { UpdateUserStatusResponse } from './response/updateUserStatus.response';
import { UpdateUserPasswordResponse } from './response/updateUserPassword.response';
import { UpdateUserPasswordRequest } from './request/updateUserPassword.request';
import { SuccessResponse } from './response/shared/success.response';
import { SystemUserFilter } from './DTO/user.interface';

const SERVICE_PATH = '/user-service';

export const userApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getMe: builder.query<GetMeResponse, void>({
			query: () => `${SERVICE_PATH}/admin/users/me`,
		}),
		addUser: builder.mutation<AddUserResponse, AddUserRequest>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/users`,
				method: 'POST',
				body,
			}),
		}),
		getUser: builder.query<GetUserResponse, string>({
			query: (userId) => ({ url: `${SERVICE_PATH}/admin/users/${userId}` }),
			providesTags: ['SystemUser'],
		}),
		updateUser: builder.mutation<
			UpdateUserResponse,
			{ userId: string; body: UpdateUserRequest }
		>({
			query: ({ userId, body }) => ({
				url: `${SERVICE_PATH}/admin/users/${userId}`,
				method: 'PUT',
				body,
			}),
		}),
		updateUserStatus: builder.mutation<
			UpdateUserStatusResponse,
			{ userId: string; body: UpdateUserStatusRequest }
		>({
			query: ({ userId, body }) => ({
				url: `${SERVICE_PATH}/admin/users/${userId}/status`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['SystemUsers', 'SystemUser'],
		}),
		getFilteredUsers: builder.query<FilterUserResponse, SystemUserFilter>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/users/filter`,
				method: 'POST',
				body,
			}),
			providesTags: ['SystemUsers'],
		}),
		updateUserPassword: builder.mutation<
			UpdateUserPasswordResponse,
			{ userId: string; body: UpdateUserPasswordRequest }
		>({
			query: ({ userId, body }) => ({
				url: `${SERVICE_PATH}/admin/users/${userId}/password`,
				method: 'PUT',
				body,
			}),
		}),
		deleteUser: builder.mutation<SuccessResponse<null>, string>({
			query: (userId) => ({
				url: `${SERVICE_PATH}/admin/users/${userId}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export const {
	useGetMeQuery,
	useAddUserMutation,
	useGetUserQuery,
	useUpdateUserMutation,
	useUpdateUserStatusMutation,
	useGetFilteredUsersQuery,
	useUpdateUserPasswordMutation,
	useDeleteUserMutation,
} = userApiSlice;

import { styled } from '@mui/system';
import { Card, Typography } from '@mui/material';
import { FormCardType } from './FormCard';

interface TitleCardProps {
	title: string;
	type?: FormCardType;
}

const StyledCard = styled(Card)<{ type?: FormCardType }>(({ theme, type }) => ({
	background: theme.palette.background.infoCard,
	boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
	borderRadius: 5,
	padding: 40,
}));

const HeaderWrapper = styled('div')({
	display: 'flex',
});

const ContentWrapper = styled('div')({});

export default function TitleCard(
	props: React.PropsWithChildren<TitleCardProps>
) {
	return (
		<StyledCard sx={{ position: 'relative' }}>
			<HeaderWrapper>
				<Typography width='100%' variant='bold' fontSize={20}>
					{props.title}
				</Typography>
			</HeaderWrapper>
			<ContentWrapper>{props.children}</ContentWrapper>
		</StyledCard>
	);
}

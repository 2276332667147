import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { HTMLAttributes } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';
import { ReactComponent as ArrowMoveIcon } from '../../../../assets/icon/arrow-move.svg';
import { ReactComponent as StarIcon } from '../../../../assets/icon/star.svg';
import { ReactComponent as TickIcon } from '../../../../assets/icon/tick.svg';
import { useTranslation } from 'react-i18next';
import { Image } from '../../../../api/DTO/gallery.interface';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
	item: Image;
	index: number;
	DeleteCallBack: (id: string, index: number) => void;
	SelectCallBack: (id: string) => void;
} & HTMLAttributes<HTMLDivElement>;

const SortableItem = ({
	item,
	index,
	DeleteCallBack,
	SelectCallBack,
	...props
}: Props) => {
	const { t } = useTranslation();
	const thumbnails = item.selected;
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({
		id: item.id,
	});

	const styles = {
		transform: CSS.Transform.toString(transform),
		transition: transition || undefined,
	};

	const itemStyles = {
		opacity: isDragging ? '0.4' : '1',
		transform: isDragging ? 'scale(1.05)' : 'scale(1)',
	};

	return (
		<Box ref={setNodeRef} style={styles}>
			<Box style={itemStyles} {...props} sx={{ position: 'relative' }}>
				<StyledImageOverlay>
					<StyledImageContainer>
						<LazyLoadImage
							src={item.url}
							style={{
								maxWidth: '100%',
								height: 'auto',
							}}
						/>
						{thumbnails && (
							<StyledDisplayThumbnailsButton>
								<Typography
									sx={{
										color: 'white',
										fontSize: '12px',
										userSelect: 'none',
										width: '20px',
										height: '20px',
									}}
								>
									<StarIcon width={'20px'} height={'20px'} cursor={'pointer'} />
								</Typography>
							</StyledDisplayThumbnailsButton>
						)}
					</StyledImageContainer>
					<StyledHoverOverlay>
						<StyledPositionDisplay>{index + 1}</StyledPositionDisplay>
						<StyledDeleteButton onClick={() => DeleteCallBack(item.id, index)}>
							<DeleteIcon color='white' cursor={'pointer'} />
							<Typography
								sx={{
									color: 'white',
									fontSize: '12px',
									userSelect: 'none',
									boxShadow: isDragging
										? 'none'
										: 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
								}}
							>
								{t('button.Delete')}
							</Typography>
						</StyledDeleteButton>
						{thumbnails ? (
							<StyledThumbnailsButton onClick={() => SelectCallBack(item.id)}>
								<TickIcon width={'12px'} height={'12px'} cursor={'pointer'} />

								<Typography
									sx={{
										color: 'white',
										fontSize: '12px',
										userSelect: 'none',
									}}
								>
									{t('button.SetAsThumbnails')}
								</Typography>
							</StyledThumbnailsButton>
						) : (
							<StyledSetThumbnailsButton
								onClick={() => SelectCallBack(item.id)}
							>
								<StarIcon width={'12px'} height={'12px'} cursor={'pointer'} />

								<Typography
									sx={{
										color: '#32A5F1',
										fontSize: '12px',
										userSelect: 'none',
									}}
								>
									{t('button.SetAsThumbnails')}
								</Typography>
							</StyledSetThumbnailsButton>
						)}

						<StyledMoveButton
							{...listeners}
							{...attributes}
							sx={{
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
						>
							<ArrowMoveIcon width={'20px'} height={'20px'} />
						</StyledMoveButton>
					</StyledHoverOverlay>
				</StyledImageOverlay>
			</Box>
		</Box>
	);
};

export default SortableItem;

const StyledImageContainer = styled(Box)(() => ({
	width: '240px',
	height: '135px',
	background: 'black',
	borderRadius: '10px',
	display: 'flex',
	justifyContent: 'center',
	overflow: 'hidden',
	'& img': {
		objectFit: 'contain',
	},
}));

const StyledHoverOverlay = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	opacity: 0,
	transition: theme.transitions.create('opacity'),
	userSelect: 'none',
	'&:hover': {
		opacity: 1,
	},
	borderRadius: '10px',
}));

const StyledImageOverlay = styled(Box)(() => ({
	position: 'relative',
	userSelect: 'none',
	'&:hover img': {
		transitionDuration: '0.5s',
		filter: 'brightness(40%)',
	},
}));

const StyledPositionDisplay = styled(Typography)(() => ({
	position: 'absolute',
	top: '10px',
	left: '10px',
	fontSize: '15px',
	color: 'black',
	background: 'white',
	width: '25px',
	height: '25px',
	display: 'flex',
	textAlign: 'center',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '50%',
	userSelect: 'none',
}));

const StyledDeleteButton = styled(Box)(() => ({
	position: 'absolute',
	bottom: '10px',
	left: '10px',
	color: 'white',
	fontSize: '14px',
	gap: '5px',
	background: '#DA5150',
	padding: '3px 10px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '5px',
	cursor: 'pointer',
}));

const StyledMoveButton = styled(Box)(() => ({
	position: 'absolute',
	top: '5px',
	right: '5px',
	color: 'white',
	padding: '5px',
}));

const StyledThumbnailsButton = styled(Box)(() => ({
	position: 'absolute',
	bottom: '10px',
	right: '10px',
	color: 'white',
	fontSize: '14px',
	gap: '5px',
	background: '#32A5F1',
	border: '1px solid #32A5F1',
	padding: '2px 5px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '5px',
	cursor: 'pointer',
}));

const StyledSetThumbnailsButton = styled(Box)(() => ({
	position: 'absolute',
	bottom: '10px',
	right: '10px',
	color: 'white',
	fontSize: '14px',
	gap: '5px',
	background: '#ffffff',
	border: '1px solid #32A5F1',
	padding: '2px 5px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '5px',
	cursor: 'pointer',
}));

const StyledDisplayThumbnailsButton = styled(Box)(() => ({
	position: 'absolute',
	bottom: '10px',
	right: '10px',
	color: 'white',
	fontSize: '14px',
	gap: '5px',
	background: '#32A5F1',
	border: '1px solid #32A5F1',
	padding: '1px 1px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '5px',
	cursor: 'pointer',
}));

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { SubmitHandler, DefaultValues, useForm } from 'react-hook-form';
import AppPasswordTextField from '../../common/components/form-inputs/AppPasswordTextField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import AuthContainer from './components/AuthContainer';
import { useConfirmPasswordMutation } from '../../api/authApiSlice';
import { useSelector } from 'react-redux';
import { PASSWORD_PATTERN } from '../../common/constants/pattern';
import {
	clearResetPasswordData,
	selectResetPasswordToken,
	selectResetSessionId,
} from './authSlice';
import { useEffect } from 'react';
import { useAppDispatch } from '../../redux-hooks';

export type FormValues = {
	newPassword: string;
	confirmPassword: string;
};

export const defaultValues: DefaultValues<FormValues> = {
	newPassword: '',
	confirmPassword: '',
};

export default function ResetPasswordPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const resetSessionId = useSelector(selectResetSessionId);
	const resetPasswordToken = useSelector(selectResetPasswordToken);

	const formSchema = Yup.object().shape({
		newPassword: Yup.string()
			.required(t('errorMessage.required'))
			.matches(PASSWORD_PATTERN, t('errorMessage.password')),
		confirmPassword: Yup.string()
			.required(t('errorMessage.required'))
			.oneOf([Yup.ref('newPassword')], t('errorMessage.password')),
	});

	const { handleSubmit, control } = useForm<FormValues>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [confirmPassword, { isSuccess: isConfirmPasswordSuccess }] =
		useConfirmPasswordMutation();

	const onSubmit: SubmitHandler<FormValues> = async (formResult) => {
		if (resetSessionId && resetPasswordToken)
			confirmPassword({
				id: resetSessionId,
				body: {
					token: resetPasswordToken,
					newPassword: formResult.newPassword,
				},
			});
		else console.log('missing reset sessionId/resetPasswordToken');
	};

	useEffect(() => {
		if (isConfirmPasswordSuccess) {
			dispatch(clearResetPasswordData());
			navigate('/login');
		}
	}, [isConfirmPasswordSuccess]);

	return (
		<AuthContainer
			title={t('forgotPassword.ResetPasswordTitle')}
			message={t('forgotPassword.ResetPasswordMesaage')}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Stack
					width='382px'
					alignItems='center'
					spacing='24px'
					marginTop='40px'
				>
					<AppPasswordTextField
						name='newPassword'
						control={control}
						label={t('systemUser.NewPassword')}
						placeholder={t('common.TypeHere')}
						helperText={t('systemUser.NewPasswordHelperText')}
					/>

					<AppPasswordTextField
						name='confirmPassword'
						control={control}
						label={t('systemUser.ConfirmPassword')}
						placeholder={t('common.TypeHere')}
					/>
				</Stack>

				<Button
					fullWidth
					type='submit'
					variant='contained'
					sx={{
						marginTop: '40px',
					}}
				>
					{t('button.Submit')}
				</Button>
			</form>
		</AuthContainer>
	);
}

import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import BookInfoView from './BookInfoView';

interface BookInfoCardProps {
	initialType: FormCardType;
}

export default function BookInfoCard({ initialType }: BookInfoCardProps) {
	const { t } = useTranslation();

	return (
		<FormCard type={initialType} title={t('bk.BookingInformation')}>
			{(() => {
				switch (initialType) {
					case FormCardType.view:
						return <BookInfoView />;
				}
			})()}
		</FormCard>
	);
}

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { apiSlice } from './api/apiSlice';
import modalSlice from './common/components/modal/modalSlice';
import authReducer from './pages/auth/authSlice';
import snackbarSlice from './common/components/snackbar/snackbarSlice';
import fullScreenLoaderSlice from './common/components/fullScreenLoader/fullScreenLoaderSlice';
import serviceWorkeReducer from './serviceWorkerSlice';

export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		modal: modalSlice.reducer,
		snackbar: snackbarSlice.reducer,
		fullScreenLoader: fullScreenLoaderSlice.reducer,
		auth: authReducer,
		servieWorker: serviceWorkeReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation, useParams, Outlet } from 'react-router-dom';
import PageHeader from '../../common/components/PageHeader';
import { useGetPlaceQuery } from '../../api/contentApiSlice';
import FullScreenLoader from '../../common/components/FullScreenLoader';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '../../utils/dateHelper';

export default function PlaceContainer() {
	const { t } = useTranslation();
	const { placeId } = useParams();
	const { state } = useLocation();

	const [placeSummarySearchParams, setPlaceSummarySearchParams] = useState<
		string | undefined
	>(undefined);

	useEffect(() => {
		if (state?.placeSummarySearchParams) {
			setPlaceSummarySearchParams(state?.placeSummarySearchParams);
		}
	}, []);

	const backPath = placeSummarySearchParams
		? `/places?${placeSummarySearchParams}`
		: '/places';

	const {
		data: getPlaceResponse,
		isLoading: isGetPlaceLoading,
		isSuccess: itGetPlaceSuccess,
		isError: isGetPlaceError,
	} = useGetPlaceQuery(placeId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	let pageContent;
	if (isGetPlaceLoading) {
		pageContent = <FullScreenLoader />;
	} else if (itGetPlaceSuccess) {
		pageContent = (
			<Box sx={{ width: '100%' }}>
				<PageHeader
					showBackButton={true}
					backPath={backPath}
					title={getPlaceResponse.data.baseInfo.nameOg}
					subtitleJsx={
						<Typography fontSize='12px' color='#808080'>{`${t(
							'common.Lastupdate'
						)}: ${
							getPlaceResponse?.data.baseInfo.updatedAt
								? dayjs(getPlaceResponse?.data.baseInfo.updatedAt).format(
										DISPLAY_DATE_TIME_FORMAT
								  )
								: '-'
						}`}</Typography>
					}
				/>
				<Outlet />
			</Box>
		);
	} else if (isGetPlaceError) {
		pageContent = <div>API Error</div>;
	}
	return <div>{pageContent}</div>;
}

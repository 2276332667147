import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import PageHeader from '../../common/components/PageHeader';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import {
	Abk,
	AbkContactPoints,
	AbkPhoneCode,
} from '../../api/DTO/abk.interface';
import { useAddAbkMutation } from '../../api/abkApiSlice';
import { AbkEditOptions, AbkStatus } from '../../api/enum/abkStatus.enum';
import { PaymentMethod } from '../../api/enum/paymentMethod.enum';
import { RateModel } from '../../api/enum/rateModel.enum';
import { FormCardType } from '../../common/components/card/FormCard';
import AppToggleButton from '../../common/components/form-inputs/AppToggleButton';
import { NONE_INVALID_DATE_PATTERN } from '../../common/constants/pattern';
import { yupMapRules } from '../../utils/yup-extended';
import BaseInfoCard from './overview/baseInfo/BaseInfoCard';
import CustomerPaymentInfoCard from './overview/customerPaymentInfo/CustomerPaymentInfoCard';
import RateInfoCard from './overview/rateInfo/RateInfoCard';
import SupplierInfoCard from './overview/supplierInfo/SupplierInfoCard';
import { AbkGender } from '../../api/enum/abkGender.enum';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

export default function CreateAbkPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();

	const [abkSummarySearchParams, setAbkSummarySearchParams] = useState<
		string | undefined
	>(undefined);

	const [abkSubmitted, setAbkSubmitted] = useState<boolean>(false);

	useEffect(() => {
		if (state?.abkSummarySearchParams) {
			setAbkSummarySearchParams(state?.abkSummarySearchParams);
		}
	}, []);

	const defaultValues: DefaultValues<Abk> = {
		baseInfo: {
			creationDate: dayjs().toISOString(),
			relatedAccommodationBookings: [],
			relatedLandServiceBookings: [],
			contactPerson: {
				currency: 'HKD',
			},
			status: 1,
		},
		supplier: {
			currency: 'HKD',
			contactPoints: [
				{
					department: undefined,
					phoneCode: undefined,
					phoneNum: '',
					email: undefined,
				},
			],
		},
		rate: {
			property: {
				phoneNum: undefined,
				supName: undefined,
				cusName: undefined,
				supAddress: undefined,
				cusAddress: undefined,
				supUniquePolicy: undefined,
				cusUniquePolicy: undefined,
			},
			refundable: false,
			supCancellationPolicy: undefined,
			cusCancellationPolicy: undefined,
			sameRateWholePeriod: false,
			rateModel: undefined,
			commissionLevel: undefined,
			commissionTaxIncluded: false,
			commissionServiceChargeIncluded: false,
			taxRate: undefined,
			serviceCharge: undefined,
			rooms: [
				{
					supSpecialRequest: undefined,
					cusSpecialRequest: undefined,
					withBreakfast: false,
					supSpecialOffer: undefined,
					cusSpecialOffer: undefined,
					guests: [
						{
							firstName: undefined,
							lastName: undefined,
							adult: true,
							age: undefined,
							gender: AbkGender.MALE,
						},
					],
					beddings: {},
					dailyRates: {},
					otherMealMerchantConfig: {
						commissionApply: false,
						commissionTaxIncluded: false,
						commissionServiceChargeIncluded: false,
					},
					otherMeals: [],
					otherSupplementMerchantConfig: {
						commissionApply: false,
						commissionTaxIncluded: false,
						commissionServiceChargeIncluded: false,
					},
					otherSupplements: [],
				},
			],
			otherServices: [],
		},
		customerPayment: {
			confirmType: 1,
			paymentType: 0,
			paymentMethod: 0,
			paymentLink: undefined,
			payDate: undefined,
			furtherPayment: undefined,
		},
	};

	interface ValidateOptionsExtended {
		options: {
			index: number;
		};
	}

	const formSchema = Yup.object().shape({
		baseInfo: Yup.object().shape({
			creationDate: Yup.string()
				.required(t('errorMessage.pleaseSelect'))
				.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
			stayPeriod: Yup.object().shape({
				start: Yup.string()
					.required(t('errorMessage.pleaseSelect'))
					.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
					.test(
						'shouldGreaterThanToday',
						() => t('errorMessage.incorrectPeriodTime'),
						(value) => dayjs(value).isSameOrAfter(dayjs(), 'day')
					),
				end: Yup.string()
					.required(t('errorMessage.pleaseSelect'))
					.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
					.test(
						'shouldGreaterThanToday',
						() => t('errorMessage.incorrectPeriodTime'),
						(value) => dayjs(value).isSameOrAfter(dayjs(), 'day')
					)
					.when('start', (start: any, schema) => {
						return schema.test(
							'shouldGreaterThanStart',
							() => t('errorMessage.incorrectPeriodTime'),
							(value) => !!start && dayjs(value).isAfter(dayjs(start))
						);
					}),
			}),
			contactPerson: Yup.object().shape(
				{
					locale: Yup.string().required(t('errorMessage.pleaseSelect')),
					salutation: Yup.number().required(t('errorMessage.pleaseSelect')),
					firstName: Yup.string().required(t('errorMessage.required')),
					lastName: Yup.string().required(t('errorMessage.required')),

					phoneNum: Yup.string()
						.nullable()
						.when(['phoneCode', 'email'], {
							is: (phoneCode: AbkPhoneCode, email: string) =>
								phoneCode || !email,
							then: (schema) => schema.required(t('errorMessage.emailOrPhone')),
						}),
					phoneCode: Yup.object()
						.nullable()
						.when(['phoneNum', 'email'], {
							is: (phoneNum: string, email: string) => phoneNum || !email,
							then: (schema) => schema.required(t('errorMessage.emailOrPhone')),
						}),

					email: Yup.string()
						.nullable()
						.when(['phoneCode', 'phoneNum'], {
							is: (phoneCode: AbkPhoneCode, phoneNum: string) =>
								!phoneCode || !phoneNum,
							then: (schema) =>
								schema
									.required(t('errorMessage.emailOrPhone'))
									.email(t('errorMessage.email')),
						}),
				},
				[
					['phoneNum', 'phoneCode'],
					['phoneNum', 'email'],
					['phoneCode', 'email'],
				]
			),
		}),
		supplier: Yup.object().shape({
			name: Yup.string().required(t('errorMessage.required')),
			locale: Yup.string().required(t('errorMessage.pleaseSelect')),
			currency: Yup.string().required(t('errorMessage.pleaseSelect')),
			contactPoints: Yup.array().of(
				Yup.object().shape({
					department: Yup.string().required(t('errorMessage.pleaseSelect')),
					name: Yup.string().required(t('errorMessage.required')),
				})
			),
		}),
		customerPayment: Yup.object()
			.nullable()
			.when('baseInfo.status', {
				is: AbkStatus.NEW,
				then: (schema) =>
					schema.shape({
						payDate: Yup.string()
							.required(t('errorMessage.pleaseSelect'))
							.matches(
								NONE_INVALID_DATE_PATTERN,
								t('errorMessage.incorrectTime')
							)
							.test(
								'shouldGreaterThanToday',
								() => t('errorMessage.incorrectPeriodTime'),
								(value) => dayjs(value).isSameOrAfter(dayjs(), 'day')
							),
						paymentLink: Yup.string()
							.nullable()
							.when('paymentMethod', {
								is: (paymentMethod: PaymentMethod) =>
									paymentMethod == PaymentMethod.CREDIT_CARD,
								then: (schema) => schema.required(t('errorMessage.required')),
							}),
					}),
			}),
		rate: Yup.object()
			.nullable()
			.when('baseInfo.status', {
				is: AbkStatus.NEW,
				then: (schema) =>
					schema.shape({
						property: Yup.object().shape({
							supName: Yup.string().required(t('errorMessage.required')),
							cusName: Yup.string().required(t('errorMessage.required')),
							country: Yup.object().required(t('errorMessage.pleaseSelect')),
							majorArea: Yup.object().required(t('errorMessage.pleaseSelect')),
							area: Yup.object().required(t('errorMessage.pleaseSelect')),
							supAddress: Yup.string().required(t('errorMessage.pleaseSelect')),
							cusAddress: Yup.string().required(t('errorMessage.pleaseSelect')),
						}),
					}),
			})
			.when('baseInfo.status', {
				is: AbkStatus.ENQUIRY,
				then: (schema) =>
					schema.shape({
						property: Yup.object().shape({
							supName: Yup.string().required(t('errorMessage.required')),
						}),
						rooms: Yup.array().of(
							Yup.object().shape({
								supName: Yup.string().required(t('errorMessage.required')),
								ratePlanSupName: Yup.string().required(
									t('errorMessage.required')
								),
								beddings: Yup.object().requireBeddings(
									t('errorMessage.atLeastOneBedType')
								),
								dailyRates: Yup.lazy((map) => {
									return Yup.object(
										yupMapRules(
											map,
											Yup.object().shape({
												supRate: Yup.string().required(
													t('errorMessage.required')
												),
											})
										)
									);
								}),
								otherMeals: Yup.array().of(
									Yup.object().shape({
										supName: Yup.string().required(t('errorMessage.required')),
										supRate: Yup.string().required(t('errorMessage.required')),
									})
								),
								otherSupplements: Yup.array().of(
									Yup.object().shape({
										supName: Yup.string().required(t('errorMessage.required')),
										supRate: Yup.string().required(t('errorMessage.required')),
									})
								),
								guests: Yup.array().of(
									Yup.object().shape({
										firstName: Yup.string().nullable(),
										lastName: Yup.string().nullable(),
										age: Yup.string()
											.nullable()
											.when('adult', {
												is: false,
												then: (schema) =>
													schema.required(t('errorMessage.required')),
											}),
									})
								),
							})
						),
						otherServices: Yup.array().of(
							Yup.object().shape({
								providedBySupplier: Yup.boolean(),
								supName: Yup.string().required(t('errorMessage.required')),
								supRate: Yup.string().required(t('errorMessage.required')),
							})
						),
					}),
			})
			.shape({
				property: Yup.object().shape({
					supName: Yup.string().required(t('errorMessage.required')),
					cusName: Yup.string().required(t('errorMessage.required')),
				}),
				rateModel: Yup.number().required(t('errorMessage.pleaseSelect')),
				commissionLevel: Yup.string()
					.nullable()
					.when('rateModel', {
						is: RateModel.MERCHANT,
						then: (schema) => schema.required(t('errorMessage.required')),
					}),
				taxRate: Yup.string()
					.nullable()
					.when('rateModel', {
						is: RateModel.MERCHANT,
						then: (schema) => schema.required(t('errorMessage.required')),
					}),
				serviceCharge: Yup.string()
					.nullable()
					.when('rateModel', {
						is: RateModel.MERCHANT,
						then: (schema) => schema.required(t('errorMessage.required')),
					}),

				rooms: Yup.array().of(
					Yup.object().shape({
						supName: Yup.string().required(t('errorMessage.required')),
						cusName: Yup.string().required(t('errorMessage.required')),
						ratePlanSupName: Yup.string().required(t('errorMessage.required')),
						ratePlanCusName: Yup.string().required(t('errorMessage.required')),
						beddings: Yup.object().requireBeddings(
							t('errorMessage.atLeastOneBedType')
						),
						dailyRates: Yup.lazy((map) => {
							return Yup.object(
								yupMapRules(
									map,
									Yup.object().shape({
										supRate: Yup.string().required(t('errorMessage.required')),
										cusRate: Yup.string().required(t('errorMessage.required')),
									})
								)
							);
						}),
						otherMeals: Yup.array().of(
							Yup.object().shape({
								supName: Yup.string().required(t('errorMessage.required')),
								cusName: Yup.string().required(t('errorMessage.required')),
								supRate: Yup.string().required(t('errorMessage.required')),
								cusRate: Yup.string().required(t('errorMessage.required')),
							})
						),
						otherSupplements: Yup.array().of(
							Yup.object().shape({
								supName: Yup.string().required(t('errorMessage.required')),
								cusName: Yup.string().required(t('errorMessage.required')),
								supRate: Yup.string().required(t('errorMessage.required')),
								cusRate: Yup.string().required(t('errorMessage.required')),
							})
						),
						guests: Yup.array().of(
							Yup.object().shape({
								firstName: Yup.string()
									.trim()
									.nullable()
									.test({
										name: 'first',
										message: t('errorMessage.required'),
										test: function (item) {
											const { options } = this as Yup.TestContext &
												ValidateOptionsExtended;
											// if not index 0 then exit we are not checking others here
											if (options.index > 0) {
												return true;
											}
											if (options.index === 0) {
												return !!item;
											}
										},
									}),
								lastName: Yup.string()
									.trim()
									.nullable()
									.test({
										name: 'first',
										message: t('errorMessage.required'),
										test: function (item) {
											const { options } = this as Yup.TestContext &
												ValidateOptionsExtended;
											// if not index 0 then exit we are not checking others here
											if (options.index > 0) {
												return true;
											}
											if (options.index === 0) {
												return !!item;
											}
										},
									}),
								age: Yup.string()
									.nullable()
									.when('adult', {
										is: false,
										then: (schema) =>
											schema.required(t('errorMessage.required')),
									}),
							})
						),
					})
				),
				otherServices: Yup.array().of(
					Yup.object().shape({
						providedBySupplier: Yup.boolean(),
						supName: Yup.string().required(t('errorMessage.required')),
						cusName: Yup.string().required(t('errorMessage.required')),
						supRate: Yup.string().required(t('errorMessage.required')),
						cusRate: Yup.string().required(t('errorMessage.required')),
					})
				),
			}),
	});

	const [addAbk, { data: AddAbkResponse, isSuccess: isAddAbkSuccess }] =
		useAddAbkMutation();

	const backPath = abkSummarySearchParams
		? `/abks?${abkSummarySearchParams}`
		: '/abks';

	const methods = useForm<Abk>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const watchCusCurrency = methods.watch('baseInfo.contactPerson.currency');
	const watchSupCurrency = methods.watch('supplier.currency');
	const watchStatus = methods.watch('baseInfo.status');

	useEffect(() => {
		if (abkSubmitted) {
			methods.trigger();
		}
	}, [watchStatus]);

	const onSubmit: SubmitHandler<Abk> = (data) => {
		if (data.supplier?.contactPoints) {
			data.supplier?.contactPoints
				.filter((contactPoint) => contactPoint.email === '')
				.map((item: AbkContactPoints, index: number) => {
					item.email = undefined;
				});
		}

		if (data.customerPayment && data.baseInfo.status === AbkStatus.ENQUIRY) {
			data.customerPayment.paymentMethod = undefined;
			data.customerPayment.paymentLink = undefined;
			data.customerPayment.payDate = undefined;
		}

		// if (data.rate?.rooms && data.baseInfo.status === AbkStatus.ENQUIRY) {
		// 	data.rate?.rooms.forEach((room, index) => {
		// 		const keys = Object.keys(room.dailyRates);

		// 		keys.forEach((key) => {
		// 			if (
		// 				data.rate?.rooms[index].dailyRates[key].cusRate == undefined ||
		// 				Number(data.rate?.rooms[index].dailyRates[key].cusRate) === 0
		// 			) {
		// 				data.rate.rooms[index].dailyRates[key].cusRate = 0;
		// 			}
		// 		});

		// 		data.rate?.rooms[index].otherMeals.forEach((otherMeal, index) => {
		// 			if (
		// 				data.rate.rooms[index].otherMeals[index].cusRate == undefined ||
		// 				Number(data.rate.rooms[index].otherMeals[index].cusRate) === 0
		// 			) {
		// 				data.rate.rooms[index].otherMeals[index].cusRate = 0;
		// 			}
		// 		});

		// 		data.rate?.rooms[index].otherSupplements.forEach(
		// 			(otherSupplement, index) => {
		// 				if (
		// 					data.rate.rooms[index].otherSupplements[index].cusRate ==
		// 						undefined ||
		// 					Number(data.rate.rooms[index].otherSupplements[index].cusRate) ===
		// 						0
		// 				) {
		// 					data.rate.rooms[index].otherSupplements[index].cusRate = 0;
		// 				}
		// 			}
		// 		);
		// 	});
		// }

		// if (
		// 	data.rate?.otherServices &&
		// 	data.baseInfo.status === AbkStatus.ENQUIRY
		// ) {
		// 	data.rate?.otherServices.forEach((otherService, index) => {
		// 		if (
		// 			data.rate.otherServices[index].cusRate == undefined ||
		// 			Number(data.rate.otherServices[index].cusRate) === 0
		// 		) {
		// 			data.rate.otherServices[index].cusRate = 0;
		// 		}
		// 	});
		// }
		addAbk(data);
	};

	useEffect(() => {
		if (isAddAbkSuccess) {
			navigate(`/abks/${AddAbkResponse?.data.baseInfo.id}`);
		}
	}, [isAddAbkSuccess]);

	return (
		<>
			<PageHeader
				showBackButton={true}
				backPath={
					abkSummarySearchParams ? `/abks?${abkSummarySearchParams}` : '/abks'
				}
				title={t('abk.CreateABKBooking')}
			/>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<Box
						sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
					>
						<Typography
							fontSize={'14px'}
							fontWeight={700}
							paddingRight={'10px'}
						>
							{t('abk.SelectEditingMode')}
						</Typography>
						<AppToggleButton
							name='baseInfo.status'
							options={AbkEditOptions}
							control={methods.control}
							formLabel={''}
						/>
					</Box>
					<Stack spacing='16px'>
						<Grid container alignItems='stretch' spacing={'10px'}>
							<Grid item xs={6} display='flex'>
								<BaseInfoCard initialType={FormCardType.create} />
							</Grid>
							<Grid
								item
								xs={6}
								display='flex'
								flexDirection={'column'}
								gap={'10px'}
							>
								<SupplierInfoCard initialType={FormCardType.create} />
								<CustomerPaymentInfoCard initialType={FormCardType.create} />
							</Grid>
							<Grid item xs={12} display='flex'>
								<RateInfoCard
									initialType={FormCardType.create}
									cusCurrency={watchCusCurrency}
									supCurrency={watchSupCurrency}
								/>
							</Grid>
						</Grid>

						<Stack spacing='16px'>
							<Grid item xs={12}>
								<Stack direction='row-reverse' spacing='12px'>
									<Button
										type='submit'
										variant='contained'
										color='success'
										onClick={() => setAbkSubmitted(true)}
									>
										{t('button.Save')}
									</Button>
									<Button
										variant='outlined'
										color='info'
										onClick={() => navigate(backPath)}
									>
										{t('button.Discard')}
									</Button>
								</Stack>
							</Grid>
						</Stack>
					</Stack>
				</form>
			</FormProvider>
		</>
	);
}

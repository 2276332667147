import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import { useGetPropertyQuery } from '../../../../api/accommodationApiSlice';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import LinkDisplayText from '../../../../common/components/form-data-display/LinkDisplayText';
import { Dispatch, SetStateAction, useContext } from 'react';
import { PropertyIdContext } from '../ViewPropertyPage';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import {
	PropertyCategory,
	propertyCategoryOptions,
} from '../../../../api/enum/propertyCategories.enum';

export default function BaseInfoView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const propertyId = useContext(PropertyIdContext);

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppInputLayout label={t('property.PropertyID')}>
					<NonEditableDisplayText
						text={getPropertyResponse?.data.baseInfo.id}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('property.PropertyName')} />

				<AppInputLayout label={t('common.English')}>
					<NewDisplayText text={getPropertyResponse?.data.baseInfo.nameEn} />
				</AppInputLayout>

				<AppInputLayout label={t('common.TraditionalChinese')}>
					<NewDisplayText text={getPropertyResponse?.data.baseInfo.name} />
				</AppInputLayout>

				<AppInputLayout label={t('common.Original')}>
					<NewDisplayText
						text={getPropertyResponse?.data.baseInfo.originalName}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout label={t('property.PropertyOwnership')}>
					<NewDisplayText
						text={
							getPropertyResponse?.data.baseInfo.owner
								? getPropertyResponse.data.baseInfo.owner.name
								: t('property.Individual')
						}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('property.LinktoPropertyPage')}>
					<LinkDisplayText
						link={`${process.env.REACT_APP_CUSTOMER_PORTAL_URL}/zh-HK/property/${getPropertyResponse?.data.baseInfo.slug}`}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('property.Category')}>
					<NewDisplayText
						text={
							propertyCategoryOptions.find(
								(i) => i.id === getPropertyResponse?.data.baseInfo.category
							)?.name
						}
					/>
				</AppInputLayout>

				{getPropertyResponse?.data.baseInfo.category ===
					PropertyCategory.HOTEL && (
					<AppInputLayout label={t('property.StarRatings')}>
						<NewDisplayText
							text={getPropertyResponse?.data.baseInfo.starRating
								?.toFixed(1)
								.toString()}
						/>
					</AppInputLayout>
				)}

				<AppInputLayout label={t('property.NumberOfRooms')}>
					<NewDisplayText
						text={getPropertyResponse?.data.baseInfo.numOfRooms?.toString()}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout label={t('property.Website')}>
					<LinkDisplayText link={getPropertyResponse?.data.baseInfo.website} />
				</AppInputLayout>

				<AppInputLayout label={t('property.PhoneNumber')}>
					<NewDisplayText text={getPropertyResponse?.data.baseInfo.phoneNum} />
				</AppInputLayout>

				<AppInputLayout
					labelAlignItemsFlexStart
					label={t('property.Description')}
				>
					<NewDisplayText
						text={getPropertyResponse?.data.baseInfo.description}
					/>
				</AppInputLayout>
			</Stack>
		</>
	);
}

import Option from '../common/types/option.type';
import {
	ApiPropertyFilter,
	ApiPropertyRatePercentage,
} from './DTO/apiProperty.interface';
import { ApiRoomFilter, ApiRoomRatePercentage } from './DTO/apiRoom.interface';
import { apiSlice } from './apiSlice';
import { ApiSupplierType } from './enum/ApiSupplier.enum';
import { SuccessResponse } from './response/shared/success.response';

const SERVICE_PATH = '/api-service';

export const ApiServiceApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getFilteredApiProperties: builder.query<
			SuccessResponse<Option[]>,
			{ apiSupplier: ApiSupplierType; body: ApiPropertyFilter }
		>({
			query: ({ apiSupplier, body }) => ({
				url: `${SERVICE_PATH}/properties/filter?apiSupplier=${apiSupplier}`,
				method: 'POST',
				body,
			}),
		}),
		getFilteredApiRooms: builder.query<
			SuccessResponse<Option[]>,
			{
				apiSupplier: ApiSupplierType;
				propertyId: string;
				body: ApiRoomFilter;
			}
		>({
			query: ({ apiSupplier, propertyId, body }) => ({
				url: `${SERVICE_PATH}/properties/${propertyId}/rooms?apiSupplier=${apiSupplier}`,
				method: 'POST',
				body,
			}),
		}),
		getApiPropertyRatePercentage: builder.query<
			SuccessResponse<ApiPropertyRatePercentage[]>,
			{
				apiPropertyId: string;
				apiSupplier: ApiSupplierType;
			}
		>({
			query: ({ apiPropertyId, apiSupplier }) => ({
				url: `${SERVICE_PATH}/properties/${apiPropertyId}/rate/percentage?apiSupplier=${apiSupplier}`,
				method: 'POST',
				body: {},
			}),
			extraOptions: { disableFullScreenLoader: true },
		}),
		getApiRoomRatePercentage: builder.query<
			SuccessResponse<ApiRoomRatePercentage[]>,
			{
				apiSupplier: ApiSupplierType;
				body: {
					rooms: string[];
				};
			}
		>({
			query: ({ apiSupplier, body }) => ({
				url: `${SERVICE_PATH}/rooms/rate/percentage?apiSupplier=${apiSupplier}`,
				method: 'POST',
				body,
			}),
			extraOptions: { disableFullScreenLoader: true },
		}),
	}),
});

export const {
	useLazyGetFilteredApiPropertiesQuery,
	useLazyGetFilteredApiRoomsQuery,
	useGetApiPropertyRatePercentageQuery,
	useGetApiRoomRatePercentageQuery,
} = ApiServiceApiSlice;

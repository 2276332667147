import { Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useUpdateBkStatusMutation } from '../../../api/bkApiSlice';
import { BkStatus } from '../../../api/enum/bkStatus.enum';
import { useAppDispatch } from '../../../redux-hooks';
import AppModal from './AppModal';
import { hideModal } from './modalSlice';

interface DeleteBkBookingModalProps {
	id: string;
	status: BkStatus;
}

const DeleteBkBookingModal = ({ id, status }: DeleteBkBookingModalProps) => {
	const dispatch = useAppDispatch();
	const [updateBkStatus, { isSuccess: isUpdateBkStatusSuccess }] =
		useUpdateBkStatusMutation();

	const deleteBkBookingButtonOnClick = () => {
		updateBkStatus({
			bkId: id ?? '',
			body: { status: status },
		});
	};

	useEffect(() => {
		if (isUpdateBkStatusSuccess) {
			dispatch(hideModal());
		}
	}, [isUpdateBkStatusSuccess]);
	return (
		<AppModal
			title={
				<Typography variant='bold' color='#DA5150'>
					Cancel Booking {id}
				</Typography>
			}
			content={
				<>
					Are you sure to cancel the booking of{' '}
					<Typography variant='bold' color='#DA5150'>
						{' '}
						{id}
					</Typography>
					, which the booking will no longer valid for all parties?
				</>
			}
			actionButtonEl={
				<Button
					variant='contained'
					color='error'
					onClick={deleteBkBookingButtonOnClick}
				>
					Delete
				</Button>
			}
		/>
	);
};

export default DeleteBkBookingModal;

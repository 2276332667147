import ListCard from '../../../../common/components/card/ListCard';
import { useTranslation } from 'react-i18next';
import { List, styled } from '@mui/material';
import MealList from './MealList';

export default function MealSupplementListCard() {
	const { t } = useTranslation();

	return (
		<ListCard
			headerTitle={t('mealSupplement.MealSupplement')}
			content={
				<StyledList>
					<MealList />
				</StyledList>
			}
		/>
	);
}

const StyledList = styled(List)({
	marginTop: '20px',
	padding: 0,
});

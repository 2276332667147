import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import LinkDisplayText from '../../../../common/components/form-data-display/LinkDisplayText';
import { Dispatch, SetStateAction, useContext } from 'react';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { PlaceIdContext } from '../ViewPlacePage';
import { useGetPlaceQuery } from '../../../../api/contentApiSlice';

export default function MediaView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const placeId = useContext(PlaceIdContext);

	const { data: getPlaceResponse } = useGetPlaceQuery(placeId);

	const placeType = getPlaceResponse?.data.type;

	return (
		<>
			<Stack position={'relative'} sx={{ paddingTop: '20px' }}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				{getPlaceResponse?.data.media.images &&
					getPlaceResponse?.data.media.images.length > 0 && (
						<>
							{getPlaceResponse?.data.media.images.map((item, index) => {
								return (
									<Box
										key={item.id}
										position={'relative'}
										marginBottom='20px'
										padding='20px'
										paddingBottom='0'
										borderRadius={'10px'}
										sx={{ background: '#f2faff' }}
									>
										<AppSubtitleLayout
											label={t('place.LocalPhoto') + ` (${index + 1})`}
										/>
										<AppInputLayout
											label={t('place.Media')}
											labelAlignItemsFlexStart={true}
										>
											<Box>
												<img
													style={{
														width: '100%',
														maxWidth: '400px',
														borderRadius: '10px',
													}}
													src={getPlaceResponse?.data.media.images[index].url}
												/>
											</Box>
										</AppInputLayout>
										<AppInputLayout label={t('place.MediaCredit')}>
											<NewDisplayText
												text={getPlaceResponse?.data.media.images[index].credit}
											/>
										</AppInputLayout>
									</Box>
								);
							})}

							<Divider sx={{ marginBottom: '15px' }} />
						</>
					)}

				<AppSubtitleLayout label={t('place.Instagram')} />
				{getPlaceResponse?.data.media.socialMedias[0].map((item, index) => {
					return (
						<Box
							key={item.id}
							position={'relative'}
							marginBottom='20px'
							padding='20px'
							paddingBottom='0'
							borderRadius={'10px'}
							sx={{ background: '#f2faff' }}
						>
							<AppSubtitleLayout
								label={t('place.Profile') + ` (${index + 1})`}
							/>
							<AppInputLayout label={t('place.ProfileName')}>
								<NewDisplayText
									text={
										getPlaceResponse?.data.media.socialMedias[0][index].name
									}
								/>
							</AppInputLayout>
							<AppInputLayout label={t('place.ProfileLink')}>
								<LinkDisplayText
									link={
										getPlaceResponse?.data.media.socialMedias[0][index].link
									}
								/>
							</AppInputLayout>
						</Box>
					);
				})}

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('place.Youtube')} />
				{getPlaceResponse?.data.media.socialMedias[1].map((item, index) => {
					return (
						<Box
							key={item.id}
							position={'relative'}
							marginBottom='20px'
							padding='20px'
							paddingBottom='0'
							borderRadius={'10px'}
							sx={{ background: '#f2faff' }}
						>
							<AppSubtitleLayout
								label={t('place.Channel') + ` (${index + 1})`}
							/>
							<AppInputLayout label={t('place.ChannelName')}>
								<NewDisplayText
									text={
										getPlaceResponse?.data.media.socialMedias[1][index].name
									}
								/>
							</AppInputLayout>
							<AppInputLayout label={t('place.ChannelLink')}>
								<LinkDisplayText
									link={
										getPlaceResponse?.data.media.socialMedias[1][index].link
									}
								/>
							</AppInputLayout>
						</Box>
					);
				})}
			</Stack>
		</>
	);
}

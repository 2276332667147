import { Button, Box, Card, Grid, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import { Dispatch, SetStateAction, useEffect } from 'react';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFieldArray,
	DefaultValues,
	useFormContext,
} from 'react-hook-form';
import { useGetCustomFaqSummaryQuery } from '../../../../api/accommodationApiSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Faq } from '../../../../api/DTO/faq.interface';
import { useUpdateCustomFaqMutation } from '../../../../api/accommodationApiSlice';
import AppFaqTextField from '../../../../common/components/form-inputs/AppFaqTextField';
import AppAddButton from '../../../../common/components/form-inputs/AppAddButton';
import { ReactComponent as AddIcon } from '../../../../assets/icon/button-add.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';

interface CustomFaqFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function CustomFaqForm({ type, setType }: CustomFaqFormProps) {
	const { t } = useTranslation();
	const { propertyId } = useParams();

	const formSchema = Yup.object().shape({
		Faq: Yup.array().of(
			Yup.object()
				.shape({
					question: Yup.string().required(t('errorMessage.required')),
					answer: Yup.string().required(t('errorMessage.required')),
				})
				.required()
		),
	});

	const { data: getFaqResponse, isLoading: isGetFaqLoading } =
		useGetCustomFaqSummaryQuery(propertyId ?? '');

	const [updateCustomFaq, { isSuccess: isUpdateCustomFaqSuccess }] =
		useUpdateCustomFaqMutation();

	let defaultValues: DefaultValues<Faq> = {
		Faq: [
			{
				question: '',
				answer: '',
			},
		],
	};

	if (getFaqResponse?.data.length ?? 0 > 0) {
		defaultValues = { Faq: getFaqResponse?.data ?? [] };
	}

	useEffect(() => {
		if (isUpdateCustomFaqSuccess) setType(FormCardType.view);
	}, [isUpdateCustomFaqSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateCustomFaq({
			propertyId: propertyId ?? '',
			body: data.Faq,
		});
	};

	const { control, handleSubmit } =
		useFormContext() ??
		useForm<Faq>({
			defaultValues,
			resolver: yupResolver(formSchema),
		});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'Faq',
	});

	const showDeleteButton = fields.length > 0;

	const formContent = (
		<Stack>
			{fields.map((item, index) => {
				return (
					<StyledCard key={item.id} sx={{ position: 'relative', padding: 3 }}>
						{showDeleteButton && (
							<>
								<Box
									sx={{
										position: 'absolute',
										top: 10,
										right: 10,
									}}
								>
									<DeleteIcon
										color='#F6403F'
										cursor={'pointer'}
										onClick={() => remove(index)}
									/>
								</Box>
							</>
						)}
						<AppFaqTextField
							control={control}
							name={`Faq.${index}.question`}
							label={`Custom Question (${index + 1})`}
							placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
						/>
						<AppFaqTextField
							control={control}
							name={`Faq.${index}.answer`}
							label={'Answer'}
							placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
						/>
					</StyledCard>
				);
			})}
			<Box margin={'1.5rem'} display={'flex'} justifyContent={'center'}>
				<AppAddButton
					onClick={() =>
						append({
							question: '',
							answer: '',
						})
					}
				>
					<AddIcon />
					{t('button.Add')}
				</AppAddButton>
			</Box>
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form = <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;

	return <>{!isGetFaqLoading && form}</>;
}

const StyledCard = styled(Card)({
	marginTop: '20px',
	background: '#F2FAFF',
	padding: 0,
	boxShadow: '0px 4px 3px 0px rgba(0, 0, 0, 0.05)',
});

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Chip, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import {
	DefaultValues,
	FieldPath,
	FieldValues,
	SubmitHandler,
	UseControllerProps,
	useController,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AddTag, Tag } from '../../../api/DTO/page.interface';
import {
	useAddPageTagMutation,
	useGetPageTagsQuery,
} from '../../../api/pageApiSlice';
import { ReactComponent as AddIcon } from '../../../assets/icon/button-add.svg';
import CustomDialog from '../../../common/components/CustomDialog';
import AppTextField from '../../../common/components/form-inputs/AppTextField';

export const defaultValues: DefaultValues<AddTag> = {
	name: '',
	nameEn: '',
};

export interface AddSectionTagDialogProps {
	open: boolean;
	onClose: (addedTag?: Tag) => void;
	tags: Tag[];
	title: string;
}

export default function AddSectionTagDialog<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	open,
	onClose,
	tags,
	title,
	...props
}: AddSectionTagDialogProps & UseControllerProps<TFieldValues, TName>) {
	const { t } = useTranslation();
	const { data: getTagsResponse } = useGetPageTagsQuery();
	const { field } = useController(props);

	const maxTags = 3;

	const selectedTags: Tag[] = field.value ?? [];
	const onChange = field.onChange;

	const [openAddForm, setOpenAddForm] = useState(false);

	const selectedTagsId: string[] = [];

	selectedTags.map((tag) => {
		selectedTagsId.push(tag.id);
	});

	useEffect(() => {
		if (!field.value) {
			onChange([]);
		}
	});

	const handleClose = () => {
		reset();
		if (addTagResponse) onClose(addTagResponse.data);
		else onClose();
	};

	const handleClick = (tag: Tag) => {
		const arr: Tag[] = selectedTags ?? [];
		const found = arr.some((el) => el.id === tag.id);
		if (!found) {
			if (arr.length >= maxTags) return;
			arr.push(tag);
			onChange(arr);
		} else {
			onChange(arr.filter((item) => item.id !== tag.id));
		}
	};

	// form

	const formSchema = Yup.object().shape({
		name: Yup.string().required(t('errorMessage.required')),
		nameEn: Yup.string().required(t('errorMessage.required')),
	});

	const { handleSubmit, control, reset } = useForm<AddTag>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [addTag, { data: addTagResponse, isSuccess }] = useAddPageTagMutation();

	const onSubmit: SubmitHandler<AddTag> = (data) => {
		addTag(data);
	};

	useEffect(() => {
		if (isSuccess) {
			handleAddFormClose();
		}
	}, [isSuccess]);

	const handleAddFormOpen = () => {
		setOpenAddForm(true);
	};

	const handleAddFormClose = () => {
		reset();
		setOpenAddForm(false);
	};

	return (
		<CustomDialog
			title={title}
			onClose={handleClose}
			open={open}
			content={
				<>
					<StyledModelSubTitle>{t('page.TagsModalMsg')}</StyledModelSubTitle>

					<Box
						sx={{
							paddingBottom: '20px',
							display: 'flex',
							flexWrap: 'wrap',
							gap: '5px 2px',
						}}
					>
						{getTagsResponse?.data.map((option: Tag) => (
							<Box key={option.id} position={'relative'}>
								<Chip
									label={option.name}
									onClick={() => handleClick(option)}
									variant={
										selectedTagsId.includes(option.id) ? 'filled' : 'outlined'
									}
									color='primary'
									sx={{
										height: '28px',
										fontSize: '12px',
										marginRight: '6px',
										marginBottom: '6px',
									}}
									clickable
									{...field}
								/>
								{selectedTagsId.findIndex((tagId) => tagId === option.id) !==
									-1 && (
									<Box
										sx={{
											position: 'absolute',
											top: '-5px',
											right: '0',
											fontSize: '10px',
											color: '#32A5F1',
											border: '1px solid #32A5F1',
											background: 'white',
											borderRadius: '50%',
											width: '15px',
											height: '15px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										{selectedTagsId.findIndex((tagId) => tagId === option.id) +
											1}
									</Box>
								)}
							</Box>
						))}

						<Chip
							label={
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<AddIcon />
									<Typography marginLeft='4px' fontSize='12px'>
										{t('button.Add')}
									</Typography>
								</Box>
							}
							variant='outlined'
							color='primary'
							sx={{
								height: '28px',
								background: 'white',
								marginRight: '6px',
								marginBottom: '6px',
							}}
							onClick={handleAddFormOpen}
						/>
					</Box>
					{openAddForm && (
						<Stack spacing='24px' sx={{ marginBottom: '15px' }}>
							<Divider sx={{ marginBottom: '15px' }} />

							<AppTextField
								name='name'
								control={control}
								label={t('room.NewTagInTraditionalChinese')}
								placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
							/>
							<AppTextField
								name='nameEn'
								control={control}
								label={t('room.NewTagInEnglish')}
								placeholder={t('placeholder.pleaseEnterEnglish')}
							/>
							<Stack direction='row-reverse' spacing='12px'>
								<Button
									type='submit'
									variant='contained'
									color='success'
									onClick={handleSubmit(onSubmit)}
								>
									{t('button.Create')}
								</Button>
								<Button
									onClick={handleAddFormClose}
									variant='outlined'
									color='info'
								>
									{t('button.Cancel')}
								</Button>
							</Stack>
							<Divider sx={{ marginBottom: '15px' }} />
						</Stack>
					)}
					<Stack direction='row-reverse' spacing='12px'>
						<Button
							type='submit'
							variant='contained'
							color='success'
							onClick={handleClose}
							sx={{
								width: '100%',
							}}
						>
							{t('button.Confirm')}
						</Button>
					</Stack>
				</>
			}
		></CustomDialog>
	);
}

const StyledModelSubTitle = styled(Typography)({
	color: '#808080',
	fontSize: '14px',
	fontWeight: 700,
	marginBottom: '15px',
});

import Option from '../../common/types/option.type';

export const AbkMealItemOptions: Option[] = [
	{
		id: true,
		name: 'For Adults',
	},
	{
		id: false,
		name: 'For Children',
	},
];

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	Box,
	InputAdornment,
	MenuItem,
	Stack,
	styled,
	TextFieldProps,
	Typography,
} from '@mui/material';
import Option from '../../../../common/types/option.type';
import { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../../assets/icon/dropdownmenu-search.svg';
import { ReactComponent as RakutenIcon } from '../../../../assets/icon/Rakuten.svg';
import { ReactComponent as MeituanIcon } from '../../../../assets/icon/Meituan.svg';
import SelectedApiMapping from '../../../../common/components/SelectedApiMapping';
import { useLazyGetFilteredApiRoomsQuery } from '../../../../api/apiServiceApiSlice';
import { StyledTextField } from '../../../../common/components/styled/StyledTextField';
import { StyledAutocomplete } from '../../../../common/components/styled/StyledAutocomplete';
import { ApiRoomFilter } from '../../../../api/DTO/apiRoom.interface';
import { ApiSupplierType } from '../../../../api/enum/ApiSupplier.enum';
import { ApiRoomReturnRate } from '../../../../common/components/form-data-display/ApiRoomLabelDisplay';

interface ApiRoomMappingSearchProps {
	apiSupplier: ApiSupplierType;
	propertyId: string;
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	fontSize: '12px',
	padding: '12px',
}));

export default function ApiRoomMappingSearch<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	apiSupplier,
	propertyId,
	...props
}: ApiRoomMappingSearchProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field } = useController(props);
	const value = field.value;
	const onChange = field.onChange;

	const [inputValue, setInputValue] = useState('');

	const [getFilteredApiRooms, { data: ApiRooms }] =
		useLazyGetFilteredApiRoomsQuery();

	const selectOnChange = (selectedOption: Option) => {
		const arr: Option[] = value ?? [];
		const found = arr.some((el) => el.id === selectedOption.id);
		if (!found) arr.push(selectedOption);
		onChange(arr);
	};

	const removeItem = (option: Option) => {
		const arr: Option[] = value ?? [];
		onChange(arr.filter((item) => item.id !== option.id));
	};

	return (
		<Stack marginBottom={'15px'}>
			{value?.map((option: Option) => (
				<SelectedApiMapping
					key={option.id}
					apiSupplier={apiSupplier}
					option={option}
					onDeleteClick={() => {
						removeItem(option);
					}}
					returnRateNode={
						<ApiRoomReturnRate
							apiSupplier={apiSupplier}
							apiRoomId={option.id}
						/>
					}
				/>
			))}
			<StyledAutocomplete
				disablePortal
				options={ApiRooms?.data ?? []}
				onChange={(_, newValue) => console.log(newValue)}
				getOptionLabel={(option: any) => option.name}
				isOptionEqualToValue={(option: any, value: any) =>
					option.id === value.id
				}
				filterSelectedOptions
				inputValue={inputValue}
				onInputChange={(_, newInputValue) => {
					setInputValue(newInputValue);
				}}
				clearOnBlur={false}
				renderInput={(params) => (
					<StyledTextField
						{...params}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<InputAdornment sx={{ marginRight: '14px' }} position='end'>
									<SearchIcon
										cursor={'pointer'}
										onClick={() => {
											getFilteredApiRooms({
												apiSupplier: apiSupplier,
												propertyId: propertyId,
												body: {
													searchKey: inputValue,
												} as ApiRoomFilter,
											});
										}}
									/>
									{/* {params.InputProps.endAdornment} */}
								</InputAdornment>
							),
						}}
					/>
				)}
				ListboxProps={{
					style: {
						maxHeight: '250px',
					},
				}}
				renderOption={(props: object, option: any) => (
					// <StyledMenuItem {...props}>{option.name}</StyledMenuItem>
					<StyledMenuItem
						key={option.id}
						value={option.id}
						onClick={() => {
							selectOnChange(option);
						}}
					>
						<Stack direction={'row'} alignItems={'center'} spacing={'10px'}>
							{apiSupplier === ApiSupplierType.RAKUTEN && <RakutenIcon />}
							{apiSupplier === ApiSupplierType.MEITUAN && <MeituanIcon />}
							<Box>
								<Typography variant='regular' sx={{ fontSize: '0.75rem' }}>
									{option.id} {option.name}
								</Typography>
								<ApiRoomReturnRate
									apiSupplier={apiSupplier}
									apiRoomId={option.id}
								/>
							</Box>
						</Stack>
					</StyledMenuItem>
				)}
			></StyledAutocomplete>
		</Stack>
	);
}

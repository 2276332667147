// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
export const PASSWORD_PATTERN = new RegExp(
	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]).{8,}$/
);
export const VERIFICATION_CODE_PATTERN = new RegExp(/^\d{6}$/);

export const COORDINATES_PATTERN = new RegExp(
	/^[-]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
);

export const NONE_INVALID_DATE_PATTERN = new RegExp(/^((?!Invalid Date).)*$/);

import { Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import RoomListCard from './components/RoomListCard';
import RoomInfoCard from './roomInfo/RoomInfoCard';
import { FormCardType } from '../../../common/components/card/FormCard';
import RoomSupplierCard from './supplier/RoomSupplierCard';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { Room } from '../../../api/DTO/room.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
	useAddRoomMutation,
	useGetPropertyQuery,
} from '../../../api/accommodationApiSlice';
import { useEffect } from 'react';

export default function CreateRoomPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { propertyId } = useParams();

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');
	const hasDirectContract = getPropertyResponse?.data.supplier.directContract;

	const defaultValues: DefaultValues<Room> = {
		supplier: {
			minPrice: '',
			mappings: {
				0: [],
				1: [],
			},
			roomClasses: {},
		},
		info: {
			originalName: '',
			nameEn: '',
			name: '',
			familyFriendly: true,
			maxCapacity: '',
			maxAdultCapacity: '',
			maxChildCapacity: '',
			view: undefined,
			size: {
				size: undefined,
				unit: 0,
			},
			allowSmoking: false,
			description: '',
			policy: '',
			allowSharedBed: true,
			infantSharedBed: true,
			toddlerSharedBed: true,
			childSharedBed: true,
			adultSharedBed: false,
			sharedBedExtraCost: false,
			tags: [],
		},
	};

	const formSchema = Yup.object().shape({
		supplier: Yup.object()
			.shape({
				minPrice: hasDirectContract
					? Yup.string().required(t('errorMessage.required'))
					: Yup.string().nullable(),
			})
			.required(),
		info: Yup.object()
			.shape({
				originalName: Yup.string().required(t('errorMessage.required')),
				nameEn: Yup.string().required(t('errorMessage.required')),
				name: Yup.string().required(t('errorMessage.required')),
				maxCapacity: Yup.string().required(t('errorMessage.required')),
				maxAdultCapacity: Yup.string().required(t('errorMessage.required')),
				maxChildCapacity: Yup.string().required(t('errorMessage.required')),
				view: Yup.number().required(t('errorMessage.pleaseSelect')),
				bedConfigs: Yup.array()
					.nullable()
					.requireBedConfigs(t('errorMessage.atLeastOneBedType'))
					.validateBedConfigs(t('errorMessage.pleaseSelect')),
				atLeastOneChecked: Yup.bool().when(
					[
						'allowSharedBed',
						'infantSharedBed',
						'toddlerSharedBed',
						'childSharedBed',
						'adultSharedBed',
					],
					{
						is: (
							allowSharedBed: boolean,
							infantSharedBed: boolean,
							toddlerSharedBed: boolean,
							childSharedBed: boolean,
							adultSharedBed: boolean
						) =>
							allowSharedBed &&
							!(
								infantSharedBed ||
								toddlerSharedBed ||
								childSharedBed ||
								adultSharedBed
							),
						then: (schema) =>
							schema.required(t('errorMessage.atLeastOneCheckbox')),
					}
				),
			})
			.required(),
	});

	const methods = useForm<Room>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [addRoom, { data: AddRoomResponse, isSuccess: isAddRoomSuccess }] =
		useAddRoomMutation();

	const onSubmit: SubmitHandler<Room> = (data) => {
		addRoom({ propertyId: propertyId ?? '', body: data });
	};

	useEffect(() => {
		if (isAddRoomSuccess) {
			navigate(`/property/${propertyId}/room/${AddRoomResponse?.data.info.id}`);
		}
	}, [isAddRoomSuccess]);

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<Grid container spacing={'20px'}>
					<Grid item style={{ width: '383px' }}>
						<Stack width={'100%'} spacing={'15px'}>
							<RoomListCard />
							<RoomSupplierCard initialType={FormCardType.create} />
						</Stack>
					</Grid>
					<Grid item xs>
						<Stack spacing={'20px'}>
							<RoomInfoCard initialType={FormCardType.create} />
							<Stack spacing='16px'>
								<Grid item xs={12}>
									<Stack direction='row-reverse' spacing='12px'>
										<Button type='submit' variant='contained' color='success'>
											{t('button.Save')}
										</Button>
										<Button
											variant='outlined'
											color='info'
											onClick={() => navigate(`/property/${propertyId}/room`)}
										>
											{t('button.Discard')}
										</Button>
									</Stack>
								</Grid>
							</Stack>
						</Stack>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
}

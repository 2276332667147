import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import SupplierInfoView from './SupplierInfoView';

interface SupplierInfoCardProps {
	initialType: FormCardType;
}

export default function SupplierInfoCard({
	initialType,
}: SupplierInfoCardProps) {
	const { t } = useTranslation();

	return (
		<FormCard type={initialType} title={t('bk.SupplierInformation')}>
			{(() => {
				switch (initialType) {
					case FormCardType.view:
						return <SupplierInfoView />;
				}
			})()}
		</FormCard>
	);
}

import { Box, Stack } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetTransportationQuery } from '../../../../api/accommodationApiSlice';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { FormCardType } from '../../../../common/components/card/FormCard';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';

export default function TransportationView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const { propertyId } = useParams();

	const { data: getTransportationResponse } = useGetTransportationQuery(
		propertyId ?? ''
	);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>
				{getTransportationResponse?.data &&
					getTransportationResponse?.data.length > 0 && (
						<>
							{getTransportationResponse?.data.map((item, index) => {
								return (
									<Box
										key={item.id}
										position={'relative'}
										marginTop='20px'
										marginBottom='20px'
										padding='20px'
										paddingBottom='0'
										borderRadius={'10px'}
										sx={{ background: '#f2faff' }}
									>
										<AppSubtitleLayout
											label={t('transportation.LocalPhoto') + ` (${index + 1})`}
										/>
										<AppInputLayout
											label={t('transportation.Media')}
											labelAlignItemsFlexStart={true}
										>
											<Box>
												<img
													style={{
														width: '100%',
														maxWidth: '400px',
														borderRadius: '10px',
													}}
													src={item.url}
												/>
											</Box>
										</AppInputLayout>
										<AppInputLayout label={t('transportation.Title')}>
											<NewDisplayText text={item.title} />
										</AppInputLayout>
										<AppInputLayout label={t('transportation.Description')}>
											<NewDisplayText text={item.description} />
										</AppInputLayout>
									</Box>
								);
							})}
						</>
					)}
			</Stack>
		</>
	);
}

import { Box, Collapse, List, ListItemText, Typography } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as ExpandMoreIcon } from '../../../../assets/icon/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from '../../../../assets/icon/expand-less.svg';
import { useGetRatePlanSummaryQuery } from '../../../../api/accommodationApiSlice';
import ListItemLinkV2 from '../../../../common/components/list/ListItemLinkV2';
import { StyledListItemButton } from '../../../../common/components/styled/StyledListItemButton';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

interface RatePlanListProps {
	roomId: string;
}

export default function RatePlanList({ roomId }: RatePlanListProps) {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { propertyId, ratePlanId } = useParams();

	const { data: getRatePlanSummaryResponse } = useGetRatePlanSummaryQuery(
		roomId ?? ''
	);

	const [open, setOpen] = useState(ratePlanId ? true : false);

	const handleClick = () => {
		setOpen(!open);
	};

	const isRatePlanSelected = pathname.includes(
		`/property/${propertyId}/translation/rm/${roomId}/rp`
	);

	return (
		<>
			<StyledListItemButton
				onClick={handleClick}
				selected={false}
				sx={{ marginLeft: '10px' }}
			>
				<ListItemText
					primary={
						<Box>
							<Typography
								fontSize='12px'
								color={isRatePlanSelected ? '#32A5F1' : 'text.grey'}
							>
								{t('translation.RatePlan')}
							</Typography>
						</Box>
					}
				/>
				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</StyledListItemButton>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List
					component='div'
					disablePadding
					sx={{
						marginLeft: '20px',
						paddingLeft: '6px',
						borderLeft: isRatePlanSelected
							? '1px solid #32A5F1'
							: '1px solid #FFFFFF',
					}}
				>
					{getRatePlanSummaryResponse?.data?.map((ratePlan) => (
						<ListItemLinkV2
							key={ratePlan.id}
							primarytext={ratePlan.nameEn}
							secondaryText={ratePlan.name}
							to={`/property/${propertyId}/translation/rm/${roomId}/rp/${ratePlan.id}`}
							selected={pathname.includes(
								`/property/${propertyId}/translation/rm/${roomId}/rp/${ratePlan.id}`
							)}
						/>
					))}
				</List>
			</Collapse>
		</>
	);
}

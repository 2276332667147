import { Button, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import { FormCardType } from '../../../../common/components/card/FormCard';
import {
	useGetPropertyQuery,
	useUpdatePropertyLocationMutation,
} from '../../../../api/accommodationApiSlice';
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { PropertyIdContext } from '../ViewPropertyPage';
import { COORDINATES_PATTERN } from '../../../../common/constants/pattern';
import {
	useGetCountriesQuery,
	useGetMultiLangCountriesQuery,
	useLazyGetAreasQuery,
	useLazyGetMajorAreasQuery,
	useLazyGetMultiLangAreaQuery,
	useLazyGetMultiLangMajorAreasQuery,
} from '../../../../api/locationApiSlice';
import Option from '../../../../common/types/option.type';
import AppTextField from '../../../../common/components/form-inputs/AppTextField';
import { ReactComponent as LinkIcon } from '../../../../assets/icon/link.svg';
import AppInputLayoutSingleCheckbox from '../../../../common/components/form-inputs/AppInputLayoutSingleCheckBox';
import { Property } from '../../../../api/DTO/property.interface';
import AppAutocomplete from '../../../../common/components/form-inputs/AppAutocomplete';
import AppAlert from '../../../../common/components/AppAlert';

interface LocationFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function LocationForm({ type, setType }: LocationFormProps) {
	const { t } = useTranslation();
	const propertyId = useContext(PropertyIdContext);

	const defaultValues: DefaultValues<Property> = {
		location: {
			address: '',
			mapLink: '',
			coordinatesInString: '',
			withinAirportArea: false,
		},
	};

	const formSchema = Yup.object().shape({
		location: Yup.object()
			.shape({
				country: Yup.object().required(t('errorMessage.pleaseSelect')),
				majorArea: Yup.object().required(t('errorMessage.pleaseSelect')),
				area: Yup.object().required(t('errorMessage.pleaseSelect')),
				address: Yup.string().required(t('errorMessage.required')),
				mapLink: Yup.string().required(t('errorMessage.required')),
				coordinatesInString: Yup.string()
					.required(t('errorMessage.required'))
					.matches(
						COORDINATES_PATTERN,
						t('errorMessage.incorrectCoordinatesFormat')
					),
			})
			.required(),
	});

	const { data: getPropertyResponse, isLoading: isGetPropertyLoading } =
		useGetPropertyQuery(propertyId, {
			skip: type === FormCardType.create,
		});

	const [isPrefilled, setIsPrefilled] = useState<boolean>(false);

	useEffect(() => {
		setIsPrefilled(false);
		if (type === FormCardType.edit) {
			reset({
				location: {
					country: getPropertyResponse?.data.location.country,
					majorArea: getPropertyResponse?.data.location.majorArea,
					area: getPropertyResponse?.data.location.area,
					address: getPropertyResponse?.data.location.address,
					mapLink: getPropertyResponse?.data.location.mapLink,
					coordinatesInString: `${getPropertyResponse?.data.location.coordinates?.latitude}, ${getPropertyResponse?.data.location.coordinates?.longitude}`,
					withinAirportArea:
						getPropertyResponse?.data.location.withinAirportArea,
					nearbyAirports: getPropertyResponse?.data.location.nearbyAirports,
					nearbyRailwayStations:
						getPropertyResponse?.data.location.nearbyRailwayStations,
				},
			});
		}

		setIsPrefilled(true);
	}, [getPropertyResponse]);

	const { handleSubmit, control, watch, reset, setValue } =
		useFormContext() ??
		useForm<Property>({
			defaultValues,
			resolver: yupResolver(formSchema),
		});

	// load page data
	const { data: getCountriesResponse } = useGetMultiLangCountriesQuery(
		undefined,
		{
			refetchOnMountOrArgChange: true,
		}
	);
	const [getMajorAreas, { data: getMajorAreasResponse }] =
		useLazyGetMultiLangMajorAreasQuery();
	const [getAreas, { data: getAreasResponse }] = useLazyGetMultiLangAreaQuery();

	const [areaOptions, setAreaOptions] = useState<Option[]>([]);

	useEffect(() => {
		if (type === FormCardType.create) {
			const japanOption = getCountriesResponse?.data?.find(
				(i) => i.iso2 === 'JP'
			);
			if (japanOption) {
				setValue('location.country', japanOption);
			}
		}
	}, [getCountriesResponse]);

	// watch

	const watchCountry = watch('location.country');

	useEffect(() => {
		if (watchCountry) getMajorAreas(watchCountry.id);
		if (isPrefilled) setValue('location.majorArea', undefined);
		setAreaOptions([]);
	}, [watchCountry]);

	const watchMajorAreas = watch('location.majorArea');
	useEffect(() => {
		if (watchMajorAreas) getAreas(watchMajorAreas.id);
		if (isPrefilled) setValue('location.area', undefined);
	}, [watchMajorAreas]);

	useEffect(() => {
		setAreaOptions(getAreasResponse?.data ?? []);
	}, [getAreasResponse]);

	const [
		updatePropertyLocation,
		{ isSuccess: isUpdatePropertyLocationSuccess },
	] = useUpdatePropertyLocationMutation();

	useEffect(() => {
		if (isUpdatePropertyLocationSuccess) setType(FormCardType.view);
	}, [isUpdatePropertyLocationSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		// convert coordinates string to object
		const coordinatesInString = data.location.coordinatesInString;
		if (coordinatesInString) {
			data.location.coordinates = {
				latitude: parseFloat(coordinatesInString.split(/,(.*)/s)[0]),
				longitude: parseFloat(coordinatesInString.split(/,(.*)/s)[1]),
			};
		}

		updatePropertyLocation({
			propertyId: propertyId ?? '',
			body: data.location,
		});
	};

	const formContent = (
		<Stack>
			<AppDropDownMenu
				control={control}
				name='location.country'
				label={t('property.CountryOrRegion')}
				options={getCountriesResponse?.data ?? []}
				passObjectValue={true}
				required
			/>
			<AppAutocomplete
				control={control}
				name='location.majorArea'
				label={t('property.MajorArea')}
				options={getMajorAreasResponse?.data ?? []}
				required
			/>
			<AppAutocomplete
				control={control}
				name='location.area'
				label={t('property.Area')}
				options={areaOptions}
				required
			/>

			<AppTextField
				control={control}
				name='location.address'
				label={t('property.AddressInDetailInChi')}
				placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
				required
			/>

			<AppAlert alertColor='#32A5F1'>
				{t('alert.FullAddressFormatReference')}
			</AppAlert>

			<Typography fontSize={'12px'} color={'#32A5F1'} marginBottom={'10px'}>
				Sample for Address in Japan
				<br /> 〒111-0033 東京都台東區花川戸1-10-13 (in Traditional Chinese)
				<br /> 〒111-0033 東京都台東区花川戸1-10-13 (in Japanese)
				<br /> 1-10-13 Hanakawado, Taito-ku, Tokyo-to 111-0033 (in English)
				<br />
				<br /> Sample for Address in Hong Kong
				<br /> 香港西貢惠民路28號 (in Traditional Chinese)
				<br /> 28 Wai Man Road, Sai Kung, New Territories, Hong Kong (in
				English)
			</Typography>

			<AppTextField
				control={control}
				name='location.mapLink'
				label={t('property.GoogleMapLink')}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<LinkIcon />
						</InputAdornment>
					),
				}}
				required
			/>

			<AppTextField
				control={control}
				name='location.coordinatesInString'
				label={t('property.LocationCoordinates')}
				placeholder='0.000000000000000, 0.00000000000000'
				required
			/>

			<AppInputLayoutSingleCheckbox
				control={control}
				name='location.withinAirportArea'
				label={t('property.WithinAirportArea')}
			/>
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetPropertyLoading && form}</>;
}

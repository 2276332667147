import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBkQuery } from '../../../../api/bkApiSlice';
import { Bed, bedOption } from '../../../../api/enum/bed.enum';
import { abkLocaleOptions } from '../../../../api/enum/locale.enum';
import { roomViewOption } from '../../../../api/enum/roomView.enum';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import ViewToggleButton from '../../../../common/components/ViewToggleButton';
import EditorDisplay from '../../../../common/components/editor/EditorDisplay';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import TickOrCrossWithLabelDisplay from '../../../../common/components/form-data-display/TickOrCrossWithLabelDisplay';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import Option from '../../../../common/types/option.type';
import { DISPLAY_CHINESE_DATE_FORMAT } from '../../../../utils/dateHelper';
import { BkIdContext } from '../ViewBkPage';

export default function PolicyAndInclusionView() {
	const { t } = useTranslation();
	const bkId = useContext(BkIdContext);

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const [policyAndInclusionView, setPolicyAndInclusionView] = useState(0);

	const { data: getBkResponse } = useGetBkQuery({
		bk_id: bkId ?? '',
	});

	const AbkEditOptions: Option[] = [
		{
			id: 0,
			name: 'Customer View',
		},
		{
			id: 1,
			name: 'Supplier View',
		},
	];
	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ display: 'flex', justifyContent: 'end' }}>
					<ViewToggleButton
						value={policyAndInclusionView}
						options={AbkEditOptions}
						handleChange={(_, newValue) => {
							if (newValue != null) setPolicyAndInclusionView(newValue);
						}}
					/>
				</Box>

				{policyAndInclusionView === 0 ? (
					<>
						<AppInputLayout label={t('bk.CustomerLanguage')}>
							<NewDisplayText
								text={
									abkLocaleOptions.find(
										(i) =>
											i.id === getBkResponse?.data.cusPolicyAndInclusion.locale
									)?.name
								}
							/>
						</AppInputLayout>

						<AppInputLayout label={t('bk.Refundable')}>
							<YesNoDisplay
								value={getBkResponse?.data.cusPolicyAndInclusion.refundable}
							/>
						</AppInputLayout>

						<AppInputLayout
							label={t('bk.CancellationPolicy')}
							labelAlignItemsFlexStart
						>
							{getBkResponse?.data.cusPolicyAndInclusion.cancellationPolicies.map(
								(policy, index) => (
									<NewDisplayText
										text={
											dayjs(policy.date).format(DISPLAY_CHINESE_DATE_FORMAT) +
											'前取消' +
											(policy.refund == 100
												? '可全額'
												: policy.refund == 0
												? '不可獲得'
												: '可獲得' + policy.refund + '%') +
											'退款'
										}
										key={index}
									/>
								)
							)}
							{getBkResponse?.data.supPolicyAndInclusion.cancellationPolicies
								.length == 0 && <NewDisplayText text={'-'} />}
						</AppInputLayout>

						<Divider sx={{ marginBottom: '15px' }} />

						<AppSubtitleLayout label={t('bk.PropertyInfo')} />

						<AppInputLayout label={t('bk.PropertyId')}>
							<NewDisplayText
								text={getBkResponse?.data.cusPolicyAndInclusion.property.id}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.PropertyName')}>
							<NewDisplayText
								text={getBkResponse?.data.cusPolicyAndInclusion.property.name}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.CountryOrRegion')}>
							<NewDisplayText
								text={
									getBkResponse?.data.cusPolicyAndInclusion.property.country
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.MajorArea')}>
							<NewDisplayText
								text={
									getBkResponse?.data.cusPolicyAndInclusion.property.majorArea
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.Area')}>
							<NewDisplayText
								text={getBkResponse?.data.cusPolicyAndInclusion.property.area}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.FullAddress')}>
							<NewDisplayText
								text={
									getBkResponse?.data.cusPolicyAndInclusion.property.address ??
									'-'
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.PropertyPhoneNumber')}>
							<NewDisplayText
								text={
									getBkResponse?.data.cusPolicyAndInclusion.property.phoneNum
								}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('bk.PropertyUniquePolicy')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getBkResponse?.data.cusPolicyAndInclusion.property
										.uniquePolicy ?? '-'
								}
							/>
						</AppInputLayout>

						<Divider sx={{ marginBottom: '15px' }} />

						<AppSubtitleLayout label={t('bk.RoomInfo')} />

						<AppInputLayout label={t('bk.RoomId')}>
							<NewDisplayText
								text={getBkResponse?.data.cusPolicyAndInclusion.room.id}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.RoomType')}>
							<NewDisplayText
								text={getBkResponse?.data.cusPolicyAndInclusion.room.name}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.RoomView')}>
							<NewDisplayText
								text={
									roomViewOption.find(
										(option) =>
											option.id ==
											getBkResponse?.data.cusPolicyAndInclusion.room.view
									)?.name ?? '-'
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.SmokingRoom')}>
							<YesNoDisplay
								value={
									getBkResponse?.data.cusPolicyAndInclusion.room.allowSmoking
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.EnableShareBed')}>
							<YesNoDisplay
								value={
									getBkResponse?.data.cusPolicyAndInclusion.room.allowSharedBed
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.EnableShareBed')}>
							<Grid container alignItems='stretch' spacing={'10px'}>
								<Grid item xs={3} display='flex'>
									<TickOrCrossWithLabelDisplay
										value={
											getBkResponse?.data.cusPolicyAndInclusion.room
												.infantSharedBed
										}
										label={t('bk.Infants')}
									/>
								</Grid>
								<Grid item xs={3} display='flex'>
									<TickOrCrossWithLabelDisplay
										value={
											getBkResponse?.data.cusPolicyAndInclusion.room
												.toddlerSharedBed
										}
										label={t('bk.Toddlers')}
									/>
								</Grid>
								<Grid item xs={3} display='flex'>
									<TickOrCrossWithLabelDisplay
										value={
											getBkResponse?.data.cusPolicyAndInclusion.room
												.childSharedBed
										}
										label={t('bk.Children')}
									/>
								</Grid>
								<Grid item xs={3} display='flex'>
									<TickOrCrossWithLabelDisplay
										value={
											getBkResponse?.data.cusPolicyAndInclusion.room
												.adultSharedBed
										}
										label={t('bk.Adults')}
									/>
								</Grid>
							</Grid>
						</AppInputLayout>
						<AppInputLayout label={t('bk.ExtraCostIsRequired')}>
							<YesNoDisplay
								value={
									getBkResponse?.data.cusPolicyAndInclusion.room
										.sharedBedExtraCost
								}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('bk.RoomUniquePolicy')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getBkResponse?.data.cusPolicyAndInclusion.room.policy ?? '-'
								}
							/>
						</AppInputLayout>
						{getBkResponse?.data.cusPolicyAndInclusion?.room?.beddings && (
							<AppInputLayout label={t('bk.BedType')} labelAlignItemsFlexStart>
								<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{Object.keys(
										(getBkResponse?.data.cusPolicyAndInclusion.room
											?.beddings as {}) || {}
									).map((bedType, bedIndex) => {
										const beddings = getBkResponse?.data.cusPolicyAndInclusion
											?.room?.beddings as Record<Bed, number>;
										return (
											<>
												<Typography
													fontSize='12px'
													color='#2C2C2C'
													whiteSpace={'break-spaces'}
												>
													{beddings[Number(bedType) as Bed]}{' '}
													{
														bedOption.find(
															(option) => option.id == Number(bedType)
														)?.shortName
													}
													{bedIndex + 1 <
														Object.keys(
															(getBkResponse?.data.cusPolicyAndInclusion.room
																?.beddings as {}) || {}
														).length && ', '}
												</Typography>
											</>
										);
									})}
								</Box>
							</AppInputLayout>
						)}

						<Divider sx={{ marginBottom: '15px' }} />

						<AppSubtitleLayout label={t('bk.RatePlanDetails')} />

						<AppInputLayout label={t('bk.RatePlanId')}>
							<NewDisplayText
								text={getBkResponse?.data.cusPolicyAndInclusion.ratePlan.id}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.RatePlanName')}>
							<NewDisplayText
								text={getBkResponse?.data.cusPolicyAndInclusion.ratePlan.name}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('bk.RatePlanUniquePolicy')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getBkResponse?.data.cusPolicyAndInclusion.ratePlan
										.uniquePolicy ?? '-'
								}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('bk.SupplierInclusion')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getBkResponse?.data.cusPolicyAndInclusion.ratePlan
										.supplierPolicy ?? '-'
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.EnableMyFamiGoInclusion')}>
							<YesNoDisplay
								value={
									getBkResponse?.data.cusPolicyAndInclusion.ratePlan
										.hasInclusion
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.myFamiGoInclusionTitle')}>
							<NewDisplayText
								text={
									getBkResponse?.data.cusPolicyAndInclusion.ratePlan
										.inclusionTitle ?? '-'
								}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('bk.myFamiGoInclusion')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getBkResponse?.data.cusPolicyAndInclusion.ratePlan
										.inclusion ?? '-'
								}
							/>
						</AppInputLayout>
					</>
				) : (
					<>
						<AppInputLayout label={t('bk.SupplierLanguage')}>
							<NewDisplayText
								text={
									abkLocaleOptions.find(
										(i) =>
											i.id === getBkResponse?.data.supPolicyAndInclusion.locale
									)?.name
								}
							/>
						</AppInputLayout>

						<AppInputLayout label={t('bk.Refundable')}>
							<YesNoDisplay
								value={getBkResponse?.data.supPolicyAndInclusion.refundable}
							/>
						</AppInputLayout>

						<AppInputLayout
							label={t('bk.CancellationPolicy')}
							labelAlignItemsFlexStart
						>
							{getBkResponse?.data.supPolicyAndInclusion.cancellationPolicies.map(
								(policy, index) => (
									<NewDisplayText
										text={
											dayjs(policy.date).format(DISPLAY_CHINESE_DATE_FORMAT) +
											'前取消' +
											(policy.refund == 100
												? '可全額'
												: policy.refund == 0
												? '不可獲得'
												: '可獲得' + policy.refund + '%') +
											'退款'
										}
										key={index}
									/>
								)
							)}
							{getBkResponse?.data.supPolicyAndInclusion.cancellationPolicies
								.length == 0 && <NewDisplayText text={'-'} />}
						</AppInputLayout>

						<Divider sx={{ marginBottom: '15px' }} />

						<AppSubtitleLayout label={t('bk.PropertyInfo')} />

						<AppInputLayout label={t('bk.PropertyId')}>
							<NewDisplayText
								text={getBkResponse?.data.supPolicyAndInclusion.property.id}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.PropertyName')}>
							<NewDisplayText
								text={getBkResponse?.data.supPolicyAndInclusion.property.name}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.CountryOrRegion')}>
							<NewDisplayText
								text={
									getBkResponse?.data.supPolicyAndInclusion.property.country
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.MajorArea')}>
							<NewDisplayText
								text={
									getBkResponse?.data.supPolicyAndInclusion.property.majorArea
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.Area')}>
							<NewDisplayText
								text={getBkResponse?.data.supPolicyAndInclusion.property.area}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.FullAddress')}>
							<NewDisplayText
								text={
									getBkResponse?.data.supPolicyAndInclusion.property.address ??
									'-'
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.PropertyPhoneNumber')}>
							<NewDisplayText
								text={
									getBkResponse?.data.supPolicyAndInclusion.property.phoneNum
								}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('bk.PropertyUniquePolicy')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getBkResponse?.data.supPolicyAndInclusion.property
										.uniquePolicy ?? '-'
								}
							/>
						</AppInputLayout>

						<Divider sx={{ marginBottom: '15px' }} />

						<AppSubtitleLayout label={t('bk.RoomInfo')} />

						<AppInputLayout label={t('bk.RoomId')}>
							<NewDisplayText
								text={getBkResponse?.data.supPolicyAndInclusion.room.id}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.RoomType')}>
							<NewDisplayText
								text={getBkResponse?.data.supPolicyAndInclusion.room.name}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.RoomView')}>
							<NewDisplayText
								text={
									roomViewOption.find(
										(option) =>
											option.id ==
											getBkResponse?.data.supPolicyAndInclusion.room.view
									)?.name ?? '-'
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.SmokingRoom')}>
							<YesNoDisplay
								value={
									getBkResponse?.data.supPolicyAndInclusion.room.allowSmoking
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.EnableShareBed')}>
							<YesNoDisplay
								value={
									getBkResponse?.data.supPolicyAndInclusion.room.allowSharedBed
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.EnableShareBed')}>
							<Grid container alignItems='stretch' spacing={'10px'}>
								<Grid item xs={3} display='flex'>
									<TickOrCrossWithLabelDisplay
										value={
											getBkResponse?.data.supPolicyAndInclusion.room
												.infantSharedBed
										}
										label={t('bk.Infants')}
									/>
								</Grid>
								<Grid item xs={3} display='flex'>
									<TickOrCrossWithLabelDisplay
										value={
											getBkResponse?.data.supPolicyAndInclusion.room
												.toddlerSharedBed
										}
										label={t('bk.Toddlers')}
									/>
								</Grid>
								<Grid item xs={3} display='flex'>
									<TickOrCrossWithLabelDisplay
										value={
											getBkResponse?.data.supPolicyAndInclusion.room
												.childSharedBed
										}
										label={t('bk.Children')}
									/>
								</Grid>
								<Grid item xs={3} display='flex'>
									<TickOrCrossWithLabelDisplay
										value={
											getBkResponse?.data.supPolicyAndInclusion.room
												.adultSharedBed
										}
										label={t('bk.Adults')}
									/>
								</Grid>
							</Grid>
						</AppInputLayout>
						<AppInputLayout label={t('bk.ExtraCostIsRequired')}>
							<YesNoDisplay
								value={
									getBkResponse?.data.supPolicyAndInclusion.room
										.sharedBedExtraCost
								}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('bk.RoomUniquePolicy')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getBkResponse?.data.supPolicyAndInclusion.room.policy ?? '-'
								}
							/>
						</AppInputLayout>
						{getBkResponse?.data.supPolicyAndInclusion?.room?.beddings && (
							<AppInputLayout label={t('bk.BedType')} labelAlignItemsFlexStart>
								<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{Object.keys(
										(getBkResponse?.data.supPolicyAndInclusion.room
											?.beddings as {}) || {}
									).map((bedType, bedIndex) => {
										const beddings = getBkResponse?.data.supPolicyAndInclusion
											?.room?.beddings as Record<Bed, number>;
										return (
											<>
												<Typography
													fontSize='12px'
													color='#2C2C2C'
													whiteSpace={'break-spaces'}
												>
													{beddings[Number(bedType) as Bed]}{' '}
													{
														bedOption.find(
															(option) => option.id == Number(bedType)
														)?.shortName
													}
													{bedIndex + 1 <
														Object.keys(
															(getBkResponse?.data.supPolicyAndInclusion.room
																?.beddings as {}) || {}
														).length && ', '}
												</Typography>
											</>
										);
									})}
								</Box>
							</AppInputLayout>
						)}

						<Divider sx={{ marginBottom: '15px' }} />

						<AppSubtitleLayout label={t('bk.RatePlanDetails')} />

						<AppInputLayout label={t('bk.RatePlanId')}>
							<NewDisplayText
								text={getBkResponse?.data.supPolicyAndInclusion.ratePlan.id}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.RatePlanName')}>
							<NewDisplayText
								text={getBkResponse?.data.supPolicyAndInclusion.ratePlan.name}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('bk.RatePlanUniquePolicy')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getBkResponse?.data.supPolicyAndInclusion.ratePlan
										.uniquePolicy ?? '-'
								}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('bk.SupplierInclusion')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getBkResponse?.data.supPolicyAndInclusion.ratePlan
										.supplierPolicy ?? '-'
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.EnableMyFamiGoInclusion')}>
							<YesNoDisplay
								value={
									getBkResponse?.data.supPolicyAndInclusion.ratePlan
										.hasInclusion
								}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('bk.myFamiGoInclusionTitle')}>
							<NewDisplayText
								text={
									getBkResponse?.data.supPolicyAndInclusion.ratePlan
										.inclusionTitle ?? '-'
								}
							/>
						</AppInputLayout>
						<AppInputLayout
							label={t('bk.myFamiGoInclusion')}
							labelAlignItemsFlexStart={true}
						>
							<EditorDisplay
								editorStateString={
									getBkResponse?.data.supPolicyAndInclusion.ratePlan
										.inclusion ?? '-'
								}
							/>
						</AppInputLayout>
					</>
				)}
			</Stack>
		</>
	);
}

import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { FormCardType } from '../../../../../common/components/card/FormCard';
import { useTranslation } from 'react-i18next';
import {
	useGetRatePlanQuery,
	useUpdateRatePlanInclusionMutation,
} from '../../../../../api/accommodationApiSlice';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { RatePlan } from '../../../../../api/DTO/ratePlan.interface';
import { Button, Grid, Stack } from '@mui/material';
import { RatePlanIdContext } from '../RatePlanDetailPage';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppInputLayout from '../../../../../common/components/form-layout/AppInputLayout';
import AppRichTextEditor from '../../../../../common/components/editor/AppRichTextEditor';
import AppSwitch from '../../../../../common/components/form-inputs/AppSwitch';
import AppTextField from '../../../../../common/components/form-inputs/AppTextField';
import UploadedThumbnailsImage from '../../../../../common/components/UploadedThumbnailsImage';

interface RatePlanInclusionFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function RatePlanPaymentAndPolicyForm({
	type,
	setType,
}: RatePlanInclusionFormProps) {
	const { t } = useTranslation();
	const ratePlanId = useContext(RatePlanIdContext);
	const { data: getRatePlanResponse, isLoading: isGetRatePlanLoading } =
		useGetRatePlanQuery(ratePlanId, {
			skip: type === FormCardType.create,
		});
	const [
		updateRatePlanInclusion,
		{ isSuccess: isUpdateRatePlanInclusionSuccess },
	] = useUpdateRatePlanInclusionMutation();

	const formSchema = Yup.object().shape({
		inclusion: Yup.object()
			.shape({
				supplierInclusion: Yup.string(),
				inclusion: Yup.string()
					.nullable()
					.when('hasInclusion', {
						is: true,
						then: (schema) => schema,
					}),
				thumbnail: Yup.object().required(t('errorMessage.imageRequired')),
			})
			.required(),
	});

	const { handleSubmit, control, watch } =
		useFormContext() ??
		useForm<RatePlan>({
			defaultValues: getRatePlanResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateRatePlanInclusion({
			ratePlanId: ratePlanId,
			body: data.inclusion,
		});
	};

	useEffect(() => {
		if (isUpdateRatePlanInclusionSuccess) {
			setType(FormCardType.view);
		}
	}, [isUpdateRatePlanInclusionSuccess]);

	const formContent = (
		<>
			<Stack>
				<UploadedThumbnailsImage
					control={control}
					name='inclusion.thumbnail'
					required
				/>
				<AppInputLayout
					label={t('room.ratePlan.SupplierInclusion')}
					labelAlignItemsFlexStart
				>
					<AppRichTextEditor
						control={control}
						name={'inclusion.supplierInclusion'}
					/>
				</AppInputLayout>

				<AppSwitch
					control={control}
					name='inclusion.hasInclusion'
					label={t('room.ratePlan.EnablemyFamiGoInclusion')}
				/>

				{watch('inclusion.hasInclusion') && (
					<>
						<AppTextField
							control={control}
							name='inclusion.inclusionTitle'
							label={t('room.ratePlan.MyFamiGoInclusionTitleInChi')}
						/>
						<AppInputLayout
							label={t('room.ratePlan.MyFamiGoInclusionDetailsInChi')}
							labelAlignItemsFlexStart
						>
							<AppRichTextEditor
								control={control}
								name={'inclusion.inclusion'}
							/>
						</AppInputLayout>
					</>
				)}

				{type === FormCardType.edit && (
					<Stack spacing='16px'>
						<Grid item xs={12}>
							<Stack direction='row-reverse' spacing='12px'>
								<Button type='submit' variant='contained' color='success'>
									{t('button.Save')}
								</Button>
								<Button
									variant='outlined'
									color='info'
									onClick={() => {
										setType(FormCardType.view);
									}}
								>
									{t('button.Discard')}
								</Button>
							</Stack>
						</Grid>
					</Stack>
				)}
			</Stack>
		</>
	);

	const form =
		type === FormCardType.edit
			? (() => {
					return <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;
			  })()
			: formContent;

	return <>{!isGetRatePlanLoading && form}</>;
}

import Option from '../../common/types/option.type';

export enum DaysOfWeek {
	MON = 0,
	TUE = 1,
	WED = 2,
	THU = 3,
	FRI = 4,
	SAT = 5,
	SUN = 6,
}

export const arrayOfWholeWeek = [
	DaysOfWeek.MON,
	DaysOfWeek.TUE,
	DaysOfWeek.WED,
	DaysOfWeek.THU,
	DaysOfWeek.FRI,
	DaysOfWeek.SAT,
	DaysOfWeek.SUN,
];

export const daysOfWeekOptions: Option[] = [
	{
		id: DaysOfWeek.MON,
		name: 'Mon 月',
	},
	{
		id: DaysOfWeek.TUE,
		name: 'Tue 火',
	},
	{
		id: DaysOfWeek.WED,
		name: 'Wed 水',
	},
	{
		id: DaysOfWeek.THU,
		name: 'Thu 木',
	},
	{
		id: DaysOfWeek.FRI,
		name: 'Fri 金',
	},
	{
		id: DaysOfWeek.SAT,
		name: 'Sat 土',
	},
	{
		id: DaysOfWeek.SUN,
		name: 'Sun 日',
	},
];

export function translateDaysOfWeekToJapanese(
	daysOfWeekInDaysjsFormat: string
): string {
	switch (daysOfWeekInDaysjsFormat) {
		case 'Sunday':
			return '日';
		case 'Monday':
			return '月';
		case 'Tuesday':
			return '火';
		case 'Wednesday':
			return '水';
		case 'Thursday':
			return '木';
		case 'Friday':
			return '金';
		case 'Saturday':
			return '土';
		default:
			return '';
	}
}

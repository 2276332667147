import {
	FieldPath,
	FieldValues,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import Option from '../../types/option.type';
import AppInputLayout from '../form-layout/AppInputLayout';
import { Box, Grid, Typography } from '@mui/material';
import { StyledFormControlLabel } from './AppMultipleCheckbox';
import AppCheckbox from './AppCheckbox';

interface AppGridMultipleCheckboxProps {
	label: string;
	options: Option[];
	required?: boolean;
	columns: number;
	showMaxAdultCapacity?: boolean;
}

export default function AppGridMultipleCheckbox<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	options,
	required,
	columns,
	showMaxAdultCapacity,
	...props
}: AppGridMultipleCheckboxProps & UseControllerProps<TFieldValues, TName>) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	function handleSelect(checkedName: any) {
		const newNames = field.value?.includes(checkedName)
			? field.value?.filter((name: any) => name !== checkedName)
			: [...(field.value ?? []), checkedName];
		field.onChange(newNames);
	}

	return (
		<AppInputLayout
			label={label}
			required={required}
			errorMessage={errorMessage}
		>
			<Grid container>
				{options.map((option) => (
					<Grid item xs={12 / columns} key={option.id}>
						<StyledFormControlLabel
							control={
								<AppCheckbox
									sx={{ padding: '0px 10px' }}
									checked={field.value?.includes(option.id)}
									onChange={() => handleSelect(option.id)}
								/>
							}
							label={
								<>
									{showMaxAdultCapacity ? (
										<Box padding={'4px 0'}>
											<Typography color={'#2C2C2C'} fontSize={'12px'}>
												{option.name}
											</Typography>
											<Typography color={'#808080'} fontSize={'12px'}>
												Max Adult Capacity: {option.maxAdultCapacity}
											</Typography>
										</Box>
									) : (
										<>{option.name}</>
									)}
								</>
							}
						/>
					</Grid>
				))}
			</Grid>
		</AppInputLayout>
	);
}

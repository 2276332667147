import { Box, Button, InputAdornment, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
	useBulkUpdateRatePlanPerPersonRatesMutation,
	useGetPropertyQuery,
} from '../../../../../api/accommodationApiSlice';
import EditPopUpContainer from './EditPopUpContainer';
import { arrayOfWholeWeek } from '../../../../../api/enum/daysOfWeek';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';
import { PerPersonRate } from '../../../../../api/DTO/propertyCalendar.interface';

interface EditRatePlanAdultRatePopUpProps {
	ratePlanId: string;
	from: string;
	to: string;
	onCloseCallBack: () => void;
	numOfAdults: number;
}

export default function EditRatePlanAdultRatePopUp({
	ratePlanId,
	from,
	to,
	onCloseCallBack,
	numOfAdults,
}: EditRatePlanAdultRatePopUpProps) {
	const { t } = useTranslation();
	const { propertyId } = useParams();
	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');

	const defaultValues: DefaultValues<FieldValues> = {
		ratePlanIds: [],
		rate: '',
	};

	const { control, handleSubmit } = useForm({
		shouldUnregister: false,
		defaultValues,
	});

	const [bulkUpdateRatePlanPerPersonRatesMutation] =
		useBulkUpdateRatePlanPerPersonRatesMutation();

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		const perPersonRates: PerPersonRate = {};
		perPersonRates[numOfAdults] = data.rate;

		bulkUpdateRatePlanPerPersonRatesMutation({
			propertyId: propertyId ?? '',
			body: {
				from: from,
				to: to,
				days: arrayOfWholeWeek,
				ratePlanIds: [ratePlanId],
				perPersonRates: perPersonRates,
			},
		})
			.unwrap()
			.then((response) => {
				if (response.success) {
					onCloseCallBack();
				}
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<EditPopUpContainer
				title={t('rateAndInventory.AdultOfficialRate')}
				from={from}
				to={to}
				onCloseCallBack={onCloseCallBack}
			>
				<Box display='flex' justifyContent={'space-between'}>
					<Label>{t('rateAndInventory.RevisedRate')}</Label>
					<Box width='148px'>
						<AppTextField
							control={control}
							name={`rate`}
							inputType={InputType.amount}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										{getPropertyResponse?.data?.supplier.currency}
									</InputAdornment>
								),
							}}
							autoFocus
						/>
					</Box>
				</Box>
				<Button
					variant={'contained'}
					onClick={handleSubmit(onSubmit)}
					fullWidth={true}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{t('common.Confirm')}
					</Typography>
				</Button>
			</EditPopUpContainer>{' '}
		</form>
	);
}

const Label = styled(Typography)({
	fontSize: '12px',
});

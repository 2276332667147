import { Box, InputAdornment } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../../assets/icon/button-add.svg';
import { ReactComponent as CopyIcon } from '../../../assets/icon/copy.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/delete.svg';
import AppAddButton from '../../../common/components/form-inputs/AppAddButton';
import AppTextField, {
	InputType,
} from '../../../common/components/form-inputs/AppTextField';
import AppSplitInputLayout from '../../../common/components/form-layout/AppSplitInputLayout';
import AppSplitSubtitleLabel from '../../../common/components/form-layout/AppSplitSubtitleLabel';
import AppSplitSubtitleLayout from '../../../common/components/form-layout/AppSplitSubtitleLayout';
import { StyledBackgroundCard } from '../overview/rateInfo/RateInfoForm';
import { AbkStatus } from '../../../api/enum/abkStatus.enum';

interface AbkSupItemFieldProps {
	index: number;
	control: any;
	cusCurrency?: string;
	supCurrency?: string;
	status: AbkStatus;
	handleCopyButton: (from: string, to: string) => void;
}

export default function AbkSupItemField({
	index,
	control,
	cusCurrency,
	supCurrency,
	status,
	handleCopyButton,
}: AbkSupItemFieldProps) {
	const { t } = useTranslation();

	const {
		fields: supItemFields,
		append: supItemAppend,
		remove: supItemRemove,
	} = useFieldArray({
		control,
		name: `rate.rooms.${index}.otherSupplements`,
	});

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					gap: '5px',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<Box width={'100%'}>
					{supItemFields.map((item, supItemIndex) => {
						return (
							<StyledBackgroundCard key={item.id} position={'relative'}>
								<Box
									sx={{
										position: 'absolute',
										top: '15px',
										right: '15px',
									}}
								>
									<DeleteIcon
										color='#F6403F'
										cursor={'pointer'}
										onClick={() => supItemRemove(supItemIndex)}
									/>
								</Box>
								<Box width={'100%'} display={'flex'} alignItems={'center'}>
									<AppSplitSubtitleLayout
										subTitle={`${t('abk.SupplementItem')} ( ${
											supItemIndex + 1
										} )`}
										children1={
											<AppSplitSubtitleLabel
												label={t('abk.SupplierRate')}
												color={'#4EA4D3'}
											/>
										}
										children2={
											<AppSplitSubtitleLabel
												label={t('abk.CustomerRate')}
												required={status !== AbkStatus.ENQUIRY}
											/>
										}
									/>
								</Box>
								<AppSplitInputLayout
									label={t('abk.Description')}
									required
									children1={
										<AppTextField
											control={control}
											name={`rate.rooms.${index}.otherSupplements.${supItemIndex}.supName`}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<CopyIcon
															color='#4EA4D3'
															onClick={() => {
																handleCopyButton(
																	`rate.rooms.${index}.otherSupplements.${supItemIndex}.supName`,
																	`rate.rooms.${index}.otherSupplements.${supItemIndex}.cusName`
																);
															}}
														/>
													</InputAdornment>
												),
											}}
										/>
									}
									children2={
										<AppTextField
											control={control}
											name={`rate.rooms.${index}.otherSupplements.${supItemIndex}.cusName`}
											// InputProps={{
											// 	endAdornment: (
											// 		<InputAdornment position='end'>
											// 			<CopyIcon
											// 				color='#4EA4D3'
											// 				onClick={() => {
											// 					handleCopyButton(
											// 						`rate.rooms.${index}.otherSupplements.${supItemIndex}.cusName`,
											// 						`rate.rooms.${index}.otherSupplements.${supItemIndex}.supName`
											// 					);
											// 				}}
											// 			/>
											// 		</InputAdornment>
											// 	),
											// }}
										/>
									}
								/>
								<AppSplitInputLayout
									label={t('abk.SupplementRate')}
									required
									children1={
										<AppTextField
											control={control}
											name={`rate.rooms.${index}.otherSupplements.${supItemIndex}.supRate`}
											inputType={InputType.amount}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														{supCurrency}
													</InputAdornment>
												),
												endAdornment: (
													<>
														<InputAdornment position='end'>
															{t('abk.PerNight')}
														</InputAdornment>
														<InputAdornment position='end'>
															<CopyIcon
																color='#4EA4D3'
																onClick={() => {
																	handleCopyButton(
																		`rate.rooms.${index}.otherSupplements.${supItemIndex}.supRate`,
																		`rate.rooms.${index}.otherSupplements.${supItemIndex}.cusRate`
																	);
																}}
															/>
														</InputAdornment>
													</>
												),
											}}
										/>
									}
									children2={
										<AppTextField
											control={control}
											name={`rate.rooms.${index}.otherSupplements.${supItemIndex}.cusRate`}
											inputType={InputType.amount}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														{cusCurrency}
													</InputAdornment>
												),
												endAdornment: (
													<>
														<InputAdornment position='end'>
															{t('abk.PerNight')}
														</InputAdornment>
														{/* <InputAdornment position='end'>
															<CopyIcon
																color='#4EA4D3'
																onClick={() => {
																	handleCopyButton(
																		`rate.rooms.${index}.otherSupplements.${supItemIndex}.cusRate`,
																		`rate.rooms.${index}.otherSupplements.${supItemIndex}.supRate`
																	);
																}}
															/>
														</InputAdornment> */}
													</>
												),
											}}
										/>
									}
								/>
							</StyledBackgroundCard>
						);
					})}
				</Box>
			</Box>
			<AppAddButton
				onClick={() =>
					supItemAppend({
						supName: undefined,
						cusName: undefined,
						supRate: undefined,
						cusRate: undefined,
					})
				}
			>
				<AddIcon />
				{t('abk.AddOtherSupplement')}
			</AppAddButton>
		</>
	);
}

import {
	Box,
	Button,
	Grid,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import FullScreenLoader from '../../../common/components/FullScreenLoader';

import styled from '@emotion/styled';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionItem } from '../../../api/DTO/page.interface';
import { propertyCategoryOptions } from '../../../api/enum/propertyCategories.enum';
import {
	useGetShortNoticeQuery,
	useGetSectionQuery,
} from '../../../api/pageApiSlice';
import { ReactComponent as BulkEditIcon } from '../../../assets/icon/bulk-edit.svg';
import { ReactComponent as AddIcon } from '../../../assets/icon/button-add.svg';
import { ReactComponent as ViewDetailIcon } from '../../../assets/icon/view-detail.svg';

import AppStatusDisplay from '../../../common/components/AppStatusDisplay';
import LocalDataGrid from '../../../common/components/dataGrid/LocalDataGrid';
import AppAddButton from '../../../common/components/form-inputs/AppAddButton';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../utils/dateHelper';
import { getPropertyStatusDisplay } from '../../../utils/propertyHelper';
import { pageIconOptions } from '../../../api/enum/pageIcon.enum';
import OrderSectionTagDialog from '../components/OrderSectionTagDialog';

export default function ViewHomePage() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const {
		data: getShortNoticeResponse,
		isLoading: isGetShortNoticeLoading,
		isSuccess: isGetShortNoticeSuccess,
		isError: isGetShortNoticeError,
	} = useGetShortNoticeQuery();

	const {
		data: getSectionResponse,
		isLoading: isGetSectionLoading,
		isSuccess: isGetSectionSuccess,
		isError: isGetSectionError,
	} = useGetSectionQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	const columns: GridColDef[] = [
		{
			field: 'order',
			headerName: t('page.#'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
		},
		{
			field: 'category',
			headerName: t('page.Category'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'propertyId',
			headerName: t('page.PropertyId'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'propertyName',
			headerName: t('page.PropertyName'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'status',
			headerName: t('page.PropertyStatus'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
			renderCell: (params) => (
				<AppStatusDisplay
					name={getPropertyStatusDisplay(params.row.status).name}
					color={getPropertyStatusDisplay(params.row.status).color}
					bgcolor={getPropertyStatusDisplay(params.row.status).backgroundColor}
				/>
			),
		},
		{
			field: 'tag1',
			headerName: `${t('page.Tag')} 1`,
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'tag2',
			headerName: `${t('page.Tag')} 2`,
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'tag3',
			headerName: `${t('page.Tag')} 3`,
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68, // 10(cell padding left) + 34(icon button width) + 24(cell padding right)
			renderCell: (params) => (
				<>
					{params.row.propertyId == '-' ? (
						<></>
					) : (
						<Link to={`/property/${params.row.propertyId}`}>
							<IconButton color='primary'>
								<ViewDetailIcon />
							</IconButton>
						</Link>
					)}
				</>
			),
			sortable: false,
		},
	];

	const transformResponse = useCallback((response?: SectionItem[]) => {
		if (response == null) return;
		if (response.length == 0)
			return [
				{
					order: '-',
					id: '-',
					category: '-',
					propertyId: '-',
					propertyName: '-',
					status: '-',
					tag1: '-',
					tag2: '-',
					tag3: '-',
				},
			];

		const newResponse: any[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				order: index + 1,
				id: item.id,
				category:
					propertyCategoryOptions.find((option) => option.id == item.category)
						?.name ?? '',
				propertyId: item.propertyId,
				propertyName: item.propertyName,
				status: item.status,
				tag1: item.tags[0]?.name ?? '-',
				tag2: item.tags[1]?.name ?? '-',
				tag3: item.tags[2]?.name ?? '-',
			});
		});
		return newResponse;
	}, []);

	let pageContent;
	if (isGetSectionLoading || isGetShortNoticeLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetSectionSuccess || isGetShortNoticeSuccess) {
		pageContent = (
			<>
				<Stack spacing='16px'>
					<Grid container alignItems='stretch' spacing={'10px'}>
						{getSectionResponse?.data.map((section) => {
							return (
								<Grid item xs={12} key={section.id}>
									<Box
										sx={{ display: 'flex' }}
										minHeight='40px'
										alignItems='center'
										alignContent='center'
										marginBottom='16px'
										gap={'15px'}
									>
										<Box sx={{ flexGrow: 1 }}>
											<Box display={'flex'} gap={'5px'}>
												<Box>
													{pageIconOptions.find(
														(option) => option.id == section.icon
													)?.startIcon || null}
												</Box>
												<Typography
													variant='bold'
													fontSize={20}
													color='text.primary'
												>
													{section.title}
												</Typography>
											</Box>
											<SubtitleWrapper>
												<Typography fontSize='12px' color='#808080'>{` ${t(
													'common.Lastupdate'
												)}: ${
													section.updatedAt
														? dayjs(section.updatedAt).format(
																DISPLAY_DATE_TIME_FORMAT
														  )
														: '-'
												}`}</Typography>
											</SubtitleWrapper>
										</Box>
										<Button
											variant='contained'
											startIcon={<BulkEditIcon />}
											onClick={() =>
												navigate(`/page/home/section/${section.id}`)
											}
										>
											{t('button.Edit')}
										</Button>
										<OrderSectionTagDialog sectionId={section.id ?? ''} />
									</Box>
									<Box width={'100%'}>
										<LocalDataGrid
											rows={transformResponse(section.items) ?? []}
											columns={columns}
											rowCount={0}
											getRowId={(row) => row.id}
											pagination={false}
										/>
									</Box>
								</Grid>
							);
						})}
					</Grid>
					<AppAddButton onClick={() => navigate(`/page/home/section/add`)}>
						<AddIcon />
						{t('button.Add')}
					</AppAddButton>
				</Stack>
			</>
		);
	} else if (isGetSectionError || isGetShortNoticeError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}

const SubtitleWrapper = styled('div')({
	marginTop: 5,
});

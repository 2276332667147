import { useParams } from 'react-router-dom';
import { useGetRoomImagesQuery } from '../../../../api/accommodationApiSlice';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { Box, Divider, Stack } from '@mui/material';
import RoomImageListSection from '../component/RoomImageListSection';
import FullScreenLoader from '../../../../common/components/FullScreenLoader';

export default function RoomImageForm() {
	const { propertyId } = useParams();

	const {
		data: getRoomImagesResponse,
		isLoading: isGetRoomImagesLoading,
		isSuccess: isGetRoomImagesSuccess,
		isError: isGetRoomImagesError,
	} = useGetRoomImagesQuery(propertyId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	let pageContent;

	if (isGetRoomImagesLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetRoomImagesSuccess) {
		pageContent = (
			<>
				<Stack>
					{getRoomImagesResponse?.data.map((roomImage, index) => {
						return (
							<Box key={roomImage.room.id} sx={{ paddingTop: '20px' }}>
								<AppSubtitleLayout
									key={roomImage.room.id}
									label={`${roomImage.room.nameEn}\n ${roomImage.room.name}`}
								/>
								<RoomImageListSection
									image={roomImage.images}
									roomId={roomImage.room.id}
								/>
								{index + 1 < getRoomImagesResponse?.data.length && (
									<Divider sx={{ marginY: '15px' }} />
								)}
							</Box>
						);
					})}
				</Stack>
			</>
		);
	} else if (isGetRoomImagesError) {
		pageContent = <div>API ERROR</div>;
	}

	return <div>{pageContent}</div>;
}

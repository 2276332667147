import { MenuItem, Stack, TextField } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

enum GenderEnum {
	female = 'female',
	male = 'male',
	other = 'other',
}

const currencies = [
	{
		value: 'USD',
		label: '$',
	},
	{
		value: 'EUR',
		label: '€',
	},
	{
		value: 'BTC',
		label: '฿',
	},
	{
		value: 'JPY',
		label: '¥',
	},
];

interface IFormInput {
	textField: string;
	currency: string;
	firstName: string;
	gender: GenderEnum;
}

export default function AddBooking() {
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<IFormInput>();
	const onSubmit: SubmitHandler<IFormInput> = (data) => {
		console.log(data);
		console.log(errors);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack alignItems='flex-start' spacing={2}>
				<TextField
					{...register('textField', {
						required: true,
						maxLength: 3,
						pattern: /^[A-Za-z]+$/i,
					})}
					id='outlined-basic'
					label='Outlined'
					variant='outlined'
				/>
				<TextField
					id='outlined-select-currency-native'
					select
					label='Native select'
					{...register('currency')}
					defaultValue='EUR'
					helperText='Please select your currency'
				>
					{currencies.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<label>First Name</label>
				<input {...register('firstName', { required: true })} />
				{errors.firstName && 'First name is required'}
				<label>Gender Selection</label>
				<select {...register('gender')}>
					<option value='female'>female</option>
					<option value='male'>male</option>
					<option value='other'>other</option>
				</select>
				<input type='submit' />
			</Stack>
		</form>
	);
}

import {
	AccordionDetails,
	AccordionDetailsProps,
	AccordionProps,
	AccordionSummary,
	AccordionSummaryProps,
	styled,
} from '@mui/material';
import { ReactComponent as ExpandMoreIcon } from '../../assets/icon/expand-more.svg';
import MuiAccordion from '@mui/material/Accordion';

export const StyledAccordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	borderRadius: 0,
	'&:before': {
		display: 'none',
	},
	boxShadow: 'none',
	paddingY: '0px',
}));

export const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
	<AccordionSummary

		expandIcon={<ExpandMoreIcon />}
		sx={{
			paddingX: '28px',
			minHeight: '38px'

		}}
		{...props}
	/>
))(() => ({}));

export const StyledAccordionDetails = styled((props: AccordionDetailsProps) => (
	<AccordionDetails
		sx={{
			paddingX: '28px',
			paddingTop: '0px',
		}}
		{...props}
	/>
))(() => ({}));
import { Grid, Stack } from '@mui/material';
import ImportLoggingCard from './ImportLoggingCard';

export default function ViewLoggingPage() {
	return (
		<Grid container spacing={'20px'}>
			<Grid item xs>
				<Stack spacing={'20px'}>
					<ImportLoggingCard />
				</Stack>
			</Grid>
		</Grid>
	);
}

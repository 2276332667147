import { useTranslation } from 'react-i18next';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/system';
import CustomDialog from '../../../common/components/CustomDialog';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useAddOwnerMutation } from '../../../api/accommodationApiSlice';
import AppTextField from '../../../common/components/form-inputs/archive/AppTextField';
import Option from '../../../common/types/option.type';
import { Owner } from '../../../api/DTO/owner.interface';

export const defaultValues: DefaultValues<Owner> = {
	name: '',
};

export interface AddOwnerDialogProps {
	open: boolean;
	onClose: (addedOption?: Option) => void;
}

export default function AddOwnerDialog(props: AddOwnerDialogProps) {
	const { t } = useTranslation();

	const { onClose, open } = props;

	const handleClose = () => {
		if (addUserResponse) onClose(addUserResponse.data);
		else onClose();
	};

	// form

	const formSchema = Yup.object().shape({
		name: Yup.string().required(t('errorMessage.required')),
	});

	const { handleSubmit, control, reset } = useForm<Owner>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [addOwner, { data: addUserResponse, isSuccess }] =
		useAddOwnerMutation();

	const onSubmit: SubmitHandler<Owner> = (data) => {
		addOwner(data);
	};

	useEffect(() => {
		if (isSuccess) {
			handleClose();
		}
	}, [isSuccess]);

	return (
		<CustomDialog
			title={t('property.CreateOwnership')}
			onClose={handleClose}
			open={open}
			content={
				<Stack spacing='24px'>
					<AppTextField
						name='name'
						control={control}
						label={t('property.OwnershipName')}
					/>
					<Stack direction='row-reverse' spacing='12px'>
						<Button
							type='submit'
							variant='contained'
							color='success'
							onClick={handleSubmit(onSubmit)}
						>
							{t('button.Save')}
						</Button>
						<Button onClick={handleClose} variant='outlined' color='info'>
							{t('button.Discard')}
						</Button>
					</Stack>
				</Stack>
			}
		></CustomDialog>
	);
}

import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { FormCardType } from '../../../../../common/components/card/FormCard';
import {
	Box,
	Button,
	Divider,
	Grid,
	InputAdornment,
	Stack,
} from '@mui/material';
import {
	FieldValues,
	SubmitHandler,
	useFieldArray,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { RatePlan } from '../../../../../api/DTO/ratePlan.interface';
import {
	useGetRatePlanQuery,
	useUpdateRatePlanPaymentAndPolicyMutation,
} from '../../../../../api/accommodationApiSlice';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';
import AppSubtitleLayout from '../../../../../common/components/form-layout/AppSubtitleLayout';
import AppSwitch from '../../../../../common/components/form-inputs/AppSwitch';
import * as Yup from 'yup';
import { RatePlanIdContext } from '../RatePlanDetailPage';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import AppInputLayout from '../../../../../common/components/form-layout/AppInputLayout';
import AppRichTextEditor from '../../../../../common/components/editor/AppRichTextEditor';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icon/delete.svg';
import Addbutton from '../../../../../common/components/buttons/AddButton';

interface RatePlanPaymentAndPolicyFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function RatePlanPaymentAndPolicyForm({
	type,
	setType,
}: RatePlanPaymentAndPolicyFormProps) {
	const { t } = useTranslation();
	const ratePlanId = useContext(RatePlanIdContext);
	const { data: getRatePlanResponse, isLoading: isGetRatePlanLoading } =
		useGetRatePlanQuery(ratePlanId, {
			skip: type === FormCardType.create,
		});
	const [
		updateRatePlanPaymentAndSupplier,
		{ isSuccess: isUpdateRatePlanPaymentAndSupplierSuccess },
	] = useUpdateRatePlanPaymentAndPolicyMutation();

	const formSchema = Yup.object().shape({
		paymentAndPolicy: Yup.object()
			.shape({
				description: Yup.string().nullable(),
				uniquePolicy: Yup.string().nullable(),
				refundable: Yup.boolean(),
				cancellationPolicies: Yup.array().of(
					Yup.object().shape({
						day: Yup.string().required(t('errorMessage.required')),
						deduction: Yup.string().required(t('errorMessage.required')),
					})
				),
			})
			.required(),
	});

	const { handleSubmit, control, watch, setValue } =
		useFormContext() ??
		useForm<RatePlan>({
			defaultValues: getRatePlanResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateRatePlanPaymentAndSupplier({
			ratePlanId: ratePlanId,
			body: data.paymentAndPolicy,
		});
	};

	const watchRefundable = watch('paymentAndPolicy.refundable');
	const watchCancellationPolicies = watch(
		'paymentAndPolicy.cancellationPolicies'
	);

	const {
		fields: cancellationPoliciesFields,
		append: cancellationPoliciesAppend,
		remove: cancellationPoliciesRemove,
	} = useFieldArray({
		control,
		name: 'paymentAndPolicy.cancellationPolicies',
	});

	useEffect(() => {
		if (!watchRefundable) {
			setValue('paymentAndPolicy.cancellationPolicies', []);
		} else {
			if (watchCancellationPolicies.length === 0) {
				setValue('paymentAndPolicy.cancellationPolicies', [
					{ day: '', deduction: '' },
				]);
			} else {
				setValue(
					'paymentAndPolicy.cancellationPolicies',
					getRatePlanResponse?.data?.paymentAndPolicy?.cancellationPolicies
				);
			}
		}
	}, [watchRefundable]);

	useEffect(() => {
		if (isUpdateRatePlanPaymentAndSupplierSuccess) setType(FormCardType.view);
	}, [isUpdateRatePlanPaymentAndSupplierSuccess]);

	const formContent = (
		<Stack>
			<AppTextField
				control={control}
				name='paymentAndPolicy.description'
				label={t('room.ratePlan.RatePlanDescriptionInTraditionalChinese')}
				placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
				inputType={InputType.normal}
				multiline
				rows={3}
			/>

			<AppInputLayout
				label={t('room.ratePlan.RatePlanUniquePolicyInTraditionalChinese')}
				labelAlignItemsFlexStart
			>
				<AppRichTextEditor
					control={control}
					name='paymentAndPolicy.uniquePolicy'
				/>
			</AppInputLayout>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppSubtitleLayout label={t('room.ratePlan.CancellationPolicy')} />

			<AppSwitch
				control={control}
				name='paymentAndPolicy.refundable'
				label={t('room.ratePlan.Refundable')}
			/>

			{watchRefundable && (
				<>
					{cancellationPoliciesFields.map((item, index) => {
						return (
							<AppInputLayout
								label={t('room.ratePlan.PolicyNumber') + (index + 1)}
								removeErrorPadding
								key={item.id}
							>
								<Stack direction={'row'} gap={2}>
									<AppTextField
										control={control}
										name={`paymentAndPolicy.cancellationPolicies.${index}.day`}
										inputType={InputType.number}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													{t('common.Days')}
												</InputAdornment>
											),
										}}
									/>
									<AppTextField
										control={control}
										name={`paymentAndPolicy.cancellationPolicies.${index}.deduction`}
										inputType={InputType.number}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													{t('room.ratePlan.PolicyPenalty')}
												</InputAdornment>
											),
										}}
									/>

									{cancellationPoliciesFields.length > 1 && (
										<Box>
											<DeleteIcon
												color='#F6403F'
												cursor={'pointer'}
												onClick={() => {
													cancellationPoliciesRemove(index);
												}}
											/>
										</Box>
									)}
								</Stack>
							</AppInputLayout>
						);
					})}

					<Addbutton
						onClick={() =>
							cancellationPoliciesAppend({
								day: '',
								deduction: '',
							})
						}
					/>
				</>
			)}

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit
			? (() => {
					return <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;
			  })()
			: formContent;

	return <>{!isGetRatePlanLoading && form}</>;
}

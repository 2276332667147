import { Box, Button, ToggleButton, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useBulkUpdateRatePlanStatusesMutation } from '../../../../../api/accommodationApiSlice';
import EditPopUpContainer from './EditPopUpContainer';
import { arrayOfWholeWeek } from '../../../../../api/enum/daysOfWeek';
import { RatePlanStatusEnum } from '../../../../../api/enum/ratePlan.enum';
import { StyledToggleButtonGroup } from '../../../../../common/components/RateModelToggleButton';
import { useState } from 'react';

interface EditRatePlanStatusPopUpProps {
	ratePlanId: string;
	from: string;
	to: string;
	onCloseCallBack: () => void;
}

export default function EditRatePlanStatusPopUp({
	ratePlanId,
	from,
	to,
	onCloseCallBack,
}: EditRatePlanStatusPopUpProps) {
	const { t } = useTranslation();
	const { propertyId } = useParams();

	const [ratePlanStatusEnum, setRatePlanStatusEnum] = useState(
		RatePlanStatusEnum.ACTIVE
	);

	const defaultValues: DefaultValues<FieldValues> = {
		ratePlanIds: [],
		status: RatePlanStatusEnum.ACTIVE,
	};

	const { control, handleSubmit } = useForm({
		shouldUnregister: false,
		defaultValues,
	});

	const [bulkUpdateRatePlanStatusesMutation] =
		useBulkUpdateRatePlanStatusesMutation();

	const onSubmit: SubmitHandler<FieldValues> = () => {
		bulkUpdateRatePlanStatusesMutation({
			propertyId: propertyId ?? '',
			body: {
				from: from,
				to: to,
				days: arrayOfWholeWeek,
				ratePlanIds: [ratePlanId],
				status: ratePlanStatusEnum,
			},
		})
			.unwrap()
			.then((response) => {
				if (response.success) {
					onCloseCallBack();
				}
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<EditPopUpContainer
				title={'Status'}
				from={from}
				to={to}
				onCloseCallBack={onCloseCallBack}
			>
				<Box display='flex' justifyContent={'space-between'}>
					<Label>{t('rateAndInventory.RatePlanStatus')}</Label>
					<Box width='148px' marginBottom={'16px'}>
						<StyledToggleButtonGroup
							fullWidth={true}
							color='primary'
							value={ratePlanStatusEnum}
							exclusive
							onChange={(_, value) => {
								if (value != null) setRatePlanStatusEnum(value);
							}}
							aria-label='Model-Selection'
						>
							<ToggleButton value={RatePlanStatusEnum.ACTIVE}>
								{t('common.Online')}
							</ToggleButton>
							<ToggleButton value={RatePlanStatusEnum.INACTIVE}>
								{t('common.Offline')}
							</ToggleButton>
						</StyledToggleButtonGroup>
					</Box>
				</Box>
				<Button
					variant={'contained'}
					onClick={handleSubmit(onSubmit)}
					fullWidth={true}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{t('common.Confirm')}
					</Typography>
				</Button>
			</EditPopUpContainer>{' '}
		</form>
	);
}

const Label = styled(Typography)({
	fontSize: '12px',
});

import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import FlagLabelDisplay from '../../../../common/components/form-data-display/FlagLabelDisplay';
import { currencies } from '../../../../common/constants/currencies';
import { useContext } from 'react';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { AbkIdContext } from '../ViewAbkPage';
import { useGetAbkQuery } from '../../../../api/abkApiSlice';
import { abkLocaleOptions } from '../../../../api/enum/locale.enum';
import EmailDisplayText from '../../../../common/components/form-data-display/EmailDisplaytext';
import { AbkVersionContext } from '../../AbkContainer';
import {
	API_DATE_FORMAT,
	DISPLAY_DATE_FORMAT,
	DISPLAY_DATE_TIME_FORMAT,
} from '../../../../utils/dateHelper';
import dayjs from 'dayjs';

export default function BaseInfoView() {
	const { t } = useTranslation();
	const abkId = useContext(AbkIdContext);
	const [AbkVersion, setAbkVersion] = useContext(AbkVersionContext);

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getAbkResponse } = useGetAbkQuery({
		abk_id: abkId ?? '',
		version: AbkVersion,
	});

	return (
		<>
			<Stack position={'relative'}>
				<AppSubtitleLayout label={t('abk.BookingDetails')} />
				<AppInputLayout label={t('abk.BookingID')}>
					<NewDisplayText text={getAbkResponse?.data.baseInfo.id} />
				</AppInputLayout>

				<AppInputLayout label={t('abk.BookingCreatedDate')}>
					<NewDisplayText
						text={dayjs(getAbkResponse?.data.baseInfo.creationDate).format(
							DISPLAY_DATE_TIME_FORMAT
						)}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('abk.StayPeriod')}>
					<NewDisplayText
						text={`${dayjs(
							getAbkResponse?.data.baseInfo.stayPeriod.start,
							API_DATE_FORMAT
						).format(DISPLAY_DATE_FORMAT)} to ${dayjs(
							getAbkResponse?.data.baseInfo.stayPeriod.end,
							API_DATE_FORMAT
						).format(DISPLAY_DATE_FORMAT)}`}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('abk.NumOfRooms')}>
					<NewDisplayText
						text={getAbkResponse?.data.baseInfo.numOfRooms?.toString()}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('abk.NumOfAdults')}>
					<NewDisplayText
						text={getAbkResponse?.data.baseInfo.numOfAdults?.toString()}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('abk.NumOfChildren')}>
					<NewDisplayText
						text={getAbkResponse?.data.baseInfo.numOfChildren?.toString()}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('abk.ContactPerson')} />
				<AppInputLayout label={t('abk.CustomerLanguage')}>
					<NewDisplayText
						text={
							abkLocaleOptions.find(
								(i) =>
									i.id === getAbkResponse?.data.baseInfo.contactPerson.locale
							)?.name
						}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('abk.CustomerCurrency')}>
					<FlagLabelDisplay
						iso2={
							currencies.find(
								(i) =>
									i.id === getAbkResponse?.data.baseInfo.contactPerson.currency
							)?.iso2
						}
						label={
							currencies.find(
								(i) =>
									i.id === getAbkResponse?.data.baseInfo.contactPerson.currency
							)?.name
						}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('abk.CustomerName')}>
					<NewDisplayText
						text={
							getAbkResponse?.data.baseInfo.contactPerson.firstName +
							' ' +
							getAbkResponse?.data.baseInfo.contactPerson.lastName
						}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('abk.CompanyName')}>
					<NewDisplayText
						text={getAbkResponse?.data.baseInfo.contactPerson.companyName}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('abk.Email')}>
					<EmailDisplayText
						email={`${
							getAbkResponse?.data.baseInfo.contactPerson.email ?? '-'
						}`}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('abk.PhoneNumber')}>
					<NewDisplayText
						text={
							getAbkResponse?.data.baseInfo.contactPerson.phoneCode
								?.phoneCode &&
							getAbkResponse?.data.baseInfo.contactPerson.phoneNum
								? `+${getAbkResponse?.data.baseInfo.contactPerson.phoneCode?.phoneCode} ${getAbkResponse?.data.baseInfo.contactPerson.phoneNum}`
								: '-'
						}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('abk.PassportIdIfAny')}>
					<NewDisplayText
						text={getAbkResponse?.data.baseInfo.contactPerson.passport}
					/>
				</AppInputLayout>
			</Stack>
		</>
	);
}

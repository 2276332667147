import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../../common/components/card/FormCard';
import { useState } from 'react';
import RatePlanInclusionView from './RatePlanInclusionView';
import RatePlanInclusionForm from './RatePlanInclusionForm';

interface RatePlanInclusionCardProps {
	initialType: FormCardType;
}

export default function RatePlanInclusionCard({
	initialType,
}: RatePlanInclusionCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<>
			<FormCard type={type} title={t('room.ratePlan.Inclusion')}>
				{(() => {
					switch (type) {
						case FormCardType.view:
							return <RatePlanInclusionView setType={setType} />;
						case FormCardType.create:
							return <RatePlanInclusionForm type={type} setType={setType} />;
						case FormCardType.edit:
							return <RatePlanInclusionForm type={type} setType={setType} />;
					}
				})()}
			</FormCard>
		</>
	);
}

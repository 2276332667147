import { ButtonBase, Divider, Stack, Typography } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';

interface UnderlinedIconButtonProps {
	icon?: ReactNode;
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function UnderlinedIconButton({
	icon,
	onClick,
	children,
}: React.PropsWithChildren<UnderlinedIconButtonProps>) {
	return (
		<ButtonBase onClick={onClick}>
			<Stack>
				<Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
					{icon}
					<Typography color={'#32A5F1'} fontSize={'12px'}>
						{children}
					</Typography>
				</Stack>
				<Divider sx={{ borderColor: '#32A5F1' }} />
			</Stack>
		</ButtonBase>
	);
}

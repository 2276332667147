import { Box, Popper, PopperProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RoomCalendar } from '../../../../api/DTO/propertyCalendar.interface';
import { useState } from 'react';
import React from 'react';
import dayjs from 'dayjs';
import EditRoomRatePopUp from './edit-pop-up/EditRoomRatePopUp';
import { RateModel } from '../../../../api/enum/rateModel.enum';
import {
	RoomRow,
	getRoomRows,
	transformApiDataToGridRowsProp,
} from './RoomRow.enum';
import NonEditableTextCell from './NonEditableTextCell';
import EditRoomToSellPopUp from './edit-pop-up/EditRoomToSellPopUp';
import { PriceMode } from '../../../../api/enum/priceMode.enum';
import { CellType } from '../CellType.enum';
import BlueNonEditableTextCell from './BlueNonEditableTextCell';
import { Grid, GridCellProps, ScrollParams } from 'react-virtualized';

interface RoomGridProps {
	gridRef: React.LegacyRef<Grid> | undefined;
	width: number;
	height: number;
	onScroll?: ((params: ScrollParams) => any) | undefined;
	rateModel: RateModel;
	priceMode?: PriceMode;
	room: RoomCalendar;
}

interface CellRange {
	field?: string;
	start?: string;
	end?: string;
}

export default function RoomGrid({
	gridRef,
	width,
	height,
	onScroll,
	rateModel,
	priceMode,
	room,
}: RoomGridProps) {
	const { t } = useTranslation();

	const Cell = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
		const rows = transformApiDataToGridRowsProp(room, priceMode);

		const id = rows[rowIndex].id;
		const cellType = rows[rowIndex].cellType;
		const date = Object.keys(room.dailyRoomConfigs)[columnIndex];
		const value = rows[rowIndex][date];

		return (
			<div
				key={key}
				style={{
					...style,
					borderLeft: columnIndex == 0 ? '1px solid #e0e0e0' : 'none',
					borderRight: '1px solid #e0e0e0',
					borderTop: rowIndex == 0 ? '1px solid #FFFFFF' : '1px solid #e0e0e0',
				}}
			>
				<>
					{cellType === CellType.NON_EDITABLE_TEXT_CELL && (
						<NonEditableTextCell field={id} date={date} value={`${value}`} />
					)}
					{cellType === CellType.BLUE_NON_EDITABLE_TEXT_CELL && (
						<BlueNonEditableTextCell
							field={id}
							date={date}
							value={`${value}`}
						/>
					)}
					{cellType === CellType.RANGE_SELECTION_TEXT_CELL && (
						<RangeSelectionTextCell
							roomId={room.id}
							field={id}
							date={date}
							value={`${value}`}
							isEditable={true}
						/>
					)}
				</>
			</div>
		);
	};

	const [currentSelectedCellRange, setCurrentSelectedRange] =
		useState<CellRange>({ field: undefined, start: undefined, end: undefined });

	const [selectedCellRange, setSelectedRange] = useState<CellRange>({
		field: undefined,
		start: undefined,
		end: undefined,
	});

	function RangeSelectionTextCell({
		roomId,
		field,
		date,
		value,
		isEditable,
	}: CellProps) {
		const handleCellMouseDown = (field: string, date: string) => {
			setSelectedRange({ field: undefined, start: undefined, end: undefined });
			setCurrentSelectedRange({
				field: field,
				start: date,
				end: date,
			});
		};

		const handleCellMouseEnter = (field: string, date: string) => {
			if (
				currentSelectedCellRange.start !== undefined &&
				currentSelectedCellRange.end !== date
			) {
				setCurrentSelectedRange((prevRange) => ({
					field: prevRange.field,
					start: prevRange.start,
					end: date,
				}));
			}
		};

		const handleCellMouseUp = () => {
			setSelectedRange({
				field: currentSelectedCellRange.field,
				start: cMinDate,
				end: cMaxDate,
			});
			setCurrentSelectedRange({
				field: undefined,
				start: undefined,
				end: undefined,
			});

			const getBoundingClientRect = () => {
				const element = document.getElementById(`${roomId}_${date}_${field}`);
				return element
					? element.getBoundingClientRect()
					: new DOMRect(0, 0, 0, 0);
			};

			setAnchorEl({ getBoundingClientRect });
		};

		const cStart = dayjs(currentSelectedCellRange.start);

		const cEnd = dayjs(currentSelectedCellRange.end);

		const sStart = dayjs(selectedCellRange.start);

		const sEnd = dayjs(selectedCellRange.end);

		const dateInDayjs = dayjs(date);

		const cMinDate = cStart.isBefore(cEnd)
			? currentSelectedCellRange.start
			: currentSelectedCellRange.end;
		const sMinDate = sStart.isBefore(sEnd)
			? selectedCellRange.start
			: selectedCellRange.end;
		const cMaxDate = cStart.isBefore(cEnd)
			? currentSelectedCellRange.end
			: currentSelectedCellRange.start;
		const sMaxDate = sStart.isBefore(sEnd)
			? selectedCellRange.end
			: selectedCellRange.start;

		const isEndCell =
			(currentSelectedCellRange.field === field && cMaxDate === date) ||
			(selectedCellRange.field === field && sMaxDate === date);

		const isBetweenCell =
			(currentSelectedCellRange.field === field &&
				dateInDayjs.isBefore(cMaxDate) &&
				dateInDayjs.isAfter(cMinDate)) ||
			(selectedCellRange.field === field &&
				dateInDayjs.isBefore(sMaxDate) &&
				dateInDayjs.isAfter(sMinDate));

		const isStartCell =
			(currentSelectedCellRange.field === field && cMinDate === date) ||
			(selectedCellRange.field === field && sMinDate === date);

		return (
			<Box
				id={`${roomId}_${date}_${field}`}
				sx={{
					':hover': !(isStartCell || isBetweenCell || isEndCell)
						? {
								backgroundColor: '#F2FAFF',
								borderTop: '1px solid #4EA4D3',
								borderRight: '1px solid #4EA4D3',
								borderBottom: '1px solid #4EA4D3',
								borderLeft: '1px solid #4EA4D3',
						  }
						: {},
					backgroundColor:
						isStartCell || isBetweenCell || isEndCell ? '#F2FAFF' : 'white',
					borderTop:
						isStartCell || isBetweenCell || isEndCell
							? '1px solid #4EA4D3'
							: 'none',
					borderRight: isEndCell ? '1px solid #4EA4D3' : 'none',
					borderBottom:
						isStartCell || isBetweenCell || isEndCell
							? '1px solid #4EA4D3'
							: 'none',
					borderLeft: isStartCell ? '1px solid #4EA4D3' : 'none',
					cursor: 'default',
				}}
				width={'100%'}
				height={'100%'}
				justifyContent={'center'}
			>
				<Box
					width={'100%'}
					height={'100%'}
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
					onMouseDown={() => {
						handleCellMouseDown(field, date);
					}}
					onMouseEnter={() => {
						handleCellMouseEnter(field, date);
					}}
					onMouseUp={() => {
						handleCellMouseUp();
					}}
				>
					<Typography
						className={'noselect'}
						sx={{
							display: 'block',
							fontSize: '12px',
							textAlign: 'center',
							lineHeight: '36px',
							color: isEditable ? '#2C2C2C' : '#808080',
						}}
						variant='regular'
					>
						{value}
					</Typography>
				</Box>
			</Box>
		);
	}

	const [anchorEl, setAnchorEl] = React.useState<PopperProps['anchorEl']>(null);

	return (
		<>
			<div>
				<Popper
					open={selectedCellRange.field !== undefined}
					anchorEl={anchorEl}
				>
					{selectedCellRange.field === RoomRow.ROOM_RATE &&
						selectedCellRange.start &&
						selectedCellRange.end && (
							<EditRoomRatePopUp
								rateModel={rateModel}
								roomId={room.id}
								from={selectedCellRange.start}
								to={selectedCellRange.end}
								onCloseCallBack={() => {
									setSelectedRange({
										field: undefined,
										start: undefined,
										end: undefined,
									});
								}}
							/>
						)}

					{selectedCellRange.field === RoomRow.ALLOTMENT &&
						selectedCellRange.start &&
						selectedCellRange.end && (
							<EditRoomToSellPopUp
								rateModel={rateModel}
								roomId={room.id}
								from={selectedCellRange.start}
								to={selectedCellRange.end}
								onCloseCallBack={() => {
									setSelectedRange({
										field: undefined,
										start: undefined,
										end: undefined,
									});
								}}
							/>
						)}
				</Popper>
			</div>
			<Grid
				ref={gridRef}
				containerStyle={{}}
				cellRenderer={Cell}
				columnCount={Object.keys(room.dailyRoomConfigs).length}
				columnWidth={74}
				height={height}
				autoHeight
				overscanColumnCount={1}
				overscanRowCount={1}
				rowCount={getRoomRows(room.roomClass, priceMode).length}
				rowHeight={37}
				width={width}
				onScroll={onScroll}
			/>
		</>
	);
}

interface CellProps {
	roomId: string;
	field: string;
	date: string;
	value: string;
	isEditable: boolean;
}

import Option from '../../common/types/option.type';
export const abkGuestTypeOptions: Option[] = [
	{
		id: true,
		name: 'Adult',
	},
	{
		id: false,
		name: 'Child',
	},
];

import {
	Box,
	Button,
	Divider,
	Grid,
	InputAdornment,
	Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import { FormCardType } from '../../../../common/components/card/FormCard';
import {
	useGetPropertyQuery,
	useUpdatePropertySupplierMutation,
} from '../../../../api/accommodationApiSlice';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { PropertyIdContext } from '../ViewPropertyPage';
import AppSwitch from '../../../../common/components/form-inputs/AppSwitch';
import { useGetDirectContractSuppliersQuery } from '../../../../api/supplierApiSlice';
import { currencies } from '../../../../common/constants/currencies';
import ApiPropertyMappingSearch from '../../components/ApiPropertyMappingSearch';
import { ApiSupplierType } from '../../../../api/enum/ApiSupplier.enum';
import { Property } from '../../../../api/DTO/property.interface';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import AppAlert from '../../../../common/components/AppAlert';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';
import AppMultipleCheckbox from '../../../../common/components/form-inputs/AppMultipleCheckbox';
import {
	RateModel,
	rateModelOptions,
} from '../../../../api/enum/rateModel.enum';
import AppSingleCheckbox from '../../../../common/components/form-inputs/AppSingleCheckbox';
import AppRadioGroup from '../../../../common/components/form-inputs/AppRadioGroup';
import {
	PriceMode,
	priceModeOption,
} from '../../../../api/enum/priceMode.enum';

interface SupplierFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function SupplierForm({ type, setType }: SupplierFormProps) {
	const { t } = useTranslation();
	const propertyId = useContext(PropertyIdContext);

	const formSchema = Yup.object().shape({
		supplier: Yup.object()
			.shape({
				directContract: Yup.boolean().required(t('errorMessage.required')),
				supplier: Yup.object()
					.nullable()
					.when('directContract', {
						is: true,
						then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
					}),
				currency: Yup.string().required(t('errorMessage.pleaseSelect')),
				rateModels: Yup.array().when('directContract', {
					is: true,
					then: (schema) =>
						schema
							.min(1, t('errorMessage.pleaseSelect'))
							.required(t('errorMessage.pleaseSelect')),
				}),
				merchantConfig: Yup.object()
					.nullable()
					.when([], {
						is: () =>
							getPropertyResponse?.data.supplier.merchantConfig == null &&
							watchRateModels?.includes(RateModel.MERCHANT),
						then: (schema) =>
							schema.shape({
								commissionLevel: Yup.string()
									.nullable()
									.required(t('errorMessage.pleaseSelect')),
							}),
					}),
			})
			.required(),
	});

	const { data: getPropertyResponse, isLoading: isGetPropertyLoading } =
		useGetPropertyQuery(propertyId, {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control, watch, setValue } =
		useFormContext() ??
		useForm<Property>({
			defaultValues: getPropertyResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const watchDirectContract = watch('supplier.directContract');
	const watchRateModels = watch('supplier.rateModels');

	useEffect(() => {
		if (type === FormCardType.create && !watchDirectContract) {
			setValue('supplier.rateModels', []);
			setValue('supplier.merchantConfig', null);
			setValue('supplier.agencyConfig', null);
			setValue('supplier.priceMode', PriceMode.PER_ROOM);
		}

		if (type === FormCardType.edit && !watchDirectContract) {
			setValue(
				'supplier.rateModels',
				getPropertyResponse?.data.supplier.rateModels
			);
			setValue('supplier.priceMode', PriceMode.PER_ROOM);
		}
	}, [watchDirectContract]);

	useEffect(() => {
		if (
			type === FormCardType.create ||
			(type === FormCardType.edit &&
				getPropertyResponse?.data.supplier.agencyConfig == null)
		) {
			if (!watchRateModels?.includes(RateModel.AGENCY)) {
				setValue('supplier.agencyConfig', null);
			}

			if (watchRateModels?.includes(RateModel.AGENCY)) {
				setValue('supplier.agencyConfig', {
					commissionLevel: '',
					sellAtOfficialRate: false,
				});
			}
		}

		if (
			type === FormCardType.create ||
			(type === FormCardType.edit &&
				getPropertyResponse?.data.supplier.merchantConfig == null)
		) {
			if (!watchRateModels?.includes(RateModel.MERCHANT)) {
				setValue('supplier.merchantConfig', null);
			}

			if (watchRateModels?.includes(RateModel.MERCHANT)) {
				setValue('supplier.merchantConfig', {
					serviceChargeIncluded: true,
					taxIncluded: true,
					commissionLevel: '',
					commissionServiceChargeIncluded: true,
					commissionTaxIncluded: true,
				});
			}
		}
	}, [watchRateModels]);

	// load page data
	const { data: getDirectContracSuppliersResponse } =
		useGetDirectContractSuppliersQuery();

	const [
		updatePropertySupplier,
		{ isSuccess: isUpdatePropertySupplierSuccess },
	] = useUpdatePropertySupplierMutation();

	useEffect(() => {
		if (isUpdatePropertySupplierSuccess) setType(FormCardType.view);
	}, [isUpdatePropertySupplierSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updatePropertySupplier({
			propertyId: propertyId ?? '',
			body: data.supplier,
		});
	};

	const formContent = (
		<Stack>
			<AppSubtitleLayout label={t('property.DirectContract')} />

			<AppSwitch
				control={control}
				name='supplier.directContract'
				label={t('property.EnableDirectContract')}
			/>

			<AppDropDownMenu
				control={control}
				name='supplier.supplier'
				label={t('property.DirectContractSupplier')}
				options={getDirectContracSuppliersResponse?.data ?? []}
				passObjectValue
				disabled={!watchDirectContract}
				required={watchDirectContract}
			/>

			<AppDropDownMenu
				control={control}
				name='supplier.currency'
				label={t('property.SupplierCurrency')}
				options={currencies}
				required
			/>

			{watchDirectContract && (
				<AppRadioGroup
					control={control}
					name={'supplier.priceMode'}
					label={t('property.PriceMode')}
					options={priceModeOption}
					required
				/>
			)}

			{watchDirectContract && (
				<AppMultipleCheckbox
					control={control}
					name='supplier.rateModels'
					label={t('common.RateModel')}
					options={rateModelOptions}
					required
				/>
			)}

			{((type === FormCardType.create &&
				watchRateModels?.includes(RateModel.AGENCY)) ||
				(type === FormCardType.edit &&
					getPropertyResponse?.data.supplier.agencyConfig == null &&
					watchRateModels?.includes(RateModel.AGENCY))) && (
				<>
					<Divider sx={{ marginBottom: '15px' }} />

					<AppSubtitleLayout
						label={t('common.AgencyModel')}
					></AppSubtitleLayout>

					<AppSwitch
						control={control}
						name='supplier.agencyConfig.sellAtOfficialRate'
						label={t('property.SellAtOfficialRate')}
					/>

					<AppInputLayout
						label={t('property.CommissionLevel')}
						removeErrorPadding
					>
						<Box display='grid' gap='60px' gridTemplateColumns='repeat(2, 1fr)'>
							<AppTextField
								control={control}
								name='supplier.agencyConfig.commissionLevel'
								inputType={InputType.number}
								inputProps={{ maxLength: 3 }}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>%</InputAdornment>
									),
								}}
							/>
						</Box>
					</AppInputLayout>
				</>
			)}

			{((type === FormCardType.create &&
				watchRateModels?.includes(RateModel.MERCHANT)) ||
				(type === FormCardType.edit &&
					getPropertyResponse?.data.supplier.merchantConfig == null &&
					watchRateModels?.includes(RateModel.MERCHANT))) && (
				<>
					<Divider sx={{ marginBottom: '15px' }} />

					<AppSubtitleLayout label={t('common.MerchantModel')}>
						<AppAlert alertColor='#32A5F1'>
							{t('alert.CreatePropertyMerchantModel')}
						</AppAlert>
					</AppSubtitleLayout>

					<AppSingleCheckbox
						control={control}
						name='supplier.merchantConfig.serviceChargeIncluded'
						label={t('property.ServiceChargeIncluded')}
						infoPopUp={t('property.ServiceChargeIncludedInfo')}
					/>

					<AppSingleCheckbox
						control={control}
						name='supplier.merchantConfig.taxIncluded'
						label={t('property.TaxIncluded')}
						infoPopUp={t('property.TaxIncludedInfo')}
					/>

					<AppInputLayout
						label={t('property.CommissionLevel')}
						removeErrorPadding
						required
					>
						<Box display='grid' gap='60px' gridTemplateColumns='repeat(2, 1fr)'>
							<AppTextField
								control={control}
								name='supplier.merchantConfig.commissionLevel'
								inputType={InputType.number}
								inputProps={{ maxLength: 3 }}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>%</InputAdornment>
									),
								}}
							/>
						</Box>
					</AppInputLayout>

					<AppSingleCheckbox
						control={control}
						name='supplier.merchantConfig.commissionServiceChargeIncluded'
						label={t('property.CommissionIncludedServiceCharge')}
					/>

					<AppSingleCheckbox
						control={control}
						name='supplier.merchantConfig.commissionTaxIncluded'
						label={t('property.CommissionIncludedTax')}
					/>
				</>
			)}

			<Divider sx={{ marginBottom: '15px' }} />

			<AppSubtitleLayout label={t('common.Mapping')} />

			<ApiPropertyMappingSearch
				apiSupplier={ApiSupplierType.RAKUTEN}
				control={control}
				name='supplier.mappings.0'
				label={t('property.RakutenIDAndName')}
				infoPopUp=' '
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<ApiPropertyMappingSearch
				apiSupplier={ApiSupplierType.MEITUAN}
				control={control}
				name='supplier.mappings.1'
				label={t('property.MeituanIDAndName')}
				infoPopUp=' '
			/>

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetPropertyLoading && form}</>;
}

import {
	Box,
	ListItemButton,
	ListItemButtonProps,
	ListItemText,
	Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface ListItemLinkV2Props extends ListItemButtonProps {
	primarytext: string;
	secondaryText?: string;
	to: string;
	selected: boolean;
}

export default function ListItemLinkV2({
	primarytext,
	secondaryText,
	to,
	selected,
	...other
}: ListItemLinkV2Props) {
	return (
		<li>
			<ListItemButton
				component={RouterLink as any}
				to={to}
				{...other}
				selected={selected}
				sx={{
					padding: '4px 10px 4px 10px',
					borderRadius: '5px',
					'&.Mui-selected': {
						color: '#32A5F1',
						backgroundColor: '#E8F5FD',
						border: '1px solid #E8F5FD',
					},
				}}
			>
				<ListItemText
					primary={
						<Box>
							<Typography
								fontSize='12px'
								color={selected ? '#32A5F1' : 'text.grey'}
							>
								{primarytext}
							</Typography>
							{secondaryText && (
								<Typography
									fontSize='12px'
									color={selected ? '#32A5F1' : 'text.grey'}
								>
									{secondaryText}
								</Typography>
							)}
						</Box>
					}
				/>
			</ListItemButton>
		</li>
	);
}

import { Grid, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetPropertyQuery } from '../../../api/accommodationApiSlice';
import FullScreenLoader from '../../../common/components/FullScreenLoader';
import SupplierCard from './supplier/SupplierCard';
import LocationCard from './location/LocationCard';
import PolicyCard from './policy/PolicyCard';
import BaseInfoCard from './baseInfo/BaseInfoCard';
import { FormCardType } from '../../../common/components/card/FormCard';
import { createContext } from 'react';

export const PropertyIdContext = createContext('');

export default function ViewPropertyPage() {
	const { propertyId } = useParams();

	const {
		isLoading: isGetPropertyLoading,
		isSuccess: isGetPropertySuccess,
		isError: isGetPropertyError,
	} = useGetPropertyQuery(propertyId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	let pageContent;
	if (isGetPropertyLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetPropertySuccess) {
		pageContent = (
			<>
				<PropertyIdContext.Provider value={propertyId ?? ''}>
					<Stack spacing='16px'>
						<Grid container alignItems='stretch' spacing={'10px'}>
							<Grid item xs={6} display='flex'>
								<BaseInfoCard initialType={FormCardType.view} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<SupplierCard initialType={FormCardType.view} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<LocationCard initialType={FormCardType.view} />
							</Grid>
							<Grid item xs={6} display='flex'>
								<PolicyCard initialType={FormCardType.view} />
							</Grid>
						</Grid>
					</Stack>
				</PropertyIdContext.Provider>
			</>
		);
	} else if (isGetPropertyError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}

import { Box, styled, Divider } from '@mui/material';
import AppSpaceSubtitleLayout from '../../../common/components/form-layout/AppSpaceSubtitleLayout';
import { useTranslation } from 'react-i18next';
import SpaceDisplayText from '../../../common/components/form-data-display/SpaceDisplayText';
import { RateModel } from '../../../api/enum/rateModel.enum';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '../../../utils/dateHelper';

interface ProfitBreakdownCardProps {
	data?: any;
	currency: string;
	label: string;
	rateModel: RateModel;
}

export default function ProfitBreakdownCard({
	data,
	currency,
	label,
	rateModel,
}: ProfitBreakdownCardProps) {
	const { t } = useTranslation();
	return (
		<>
			{data !== undefined && (
				<StyledBreakdownCard>
					<AppSpaceSubtitleLayout label={label} content={currency} />
					<Box
						sx={{
							display: 'flex',
							gap: '5px',
							flexDirection: 'column',
							marginBottom: '15px',
						}}
					>
						{data.dailyBaseProfits?.map((dataItem: any, dataIndex: any) => {
							return (
								<div key={dataIndex}>
									<SpaceDisplayText
										text1={`${
											rateModel == RateModel.AGENCY
												? t('bk.BaseProfit')
												: t('bk.BaseCommission')
										} (${dayjs(dataItem.date).format(DISPLAY_DATE_FORMAT)})`}
										text2={dataItem.baseProfit.toString()}
									/>
									<SpaceDisplayText
										text1={`${
											rateModel == RateModel.AGENCY
												? t('bk.MealProfit')
												: t('bk.MealCommission')
										} (${dayjs(dataItem.date).format(DISPLAY_DATE_FORMAT)})`}
										text2={dataItem.mealSupplementProfit?.toString()}
									/>
								</div>
							);
						})}
					</Box>
					<Divider sx={{ marginBottom: '15px' }} />

					<AppSpaceSubtitleLayout
						label={`${label}'s ${t('bk.Profit')}`}
						content={data.total.toString()}
						color={'#4EA4D3'}
					/>
				</StyledBreakdownCard>
			)}
		</>
	);
}

const StyledBreakdownCard = styled(Box)({
	backgroundColor: '#F5F5F5',
	padding: '20px ',
	paddingBottom: '0',
	borderRadius: '10px',
	marginBottom: '20px',
});

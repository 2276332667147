import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	ButtonBase,
	Box,
	Card,
	Typography,
	styled,
	Stack,
} from '@mui/material';

import {
	PropertyLogs,
	LogItem,
	LogFilter,
} from '../../../api/DTO/property.interface';
import { FormProvider, useForm } from 'react-hook-form';
import { GridColDef } from '@mui/x-data-grid';
import { useGetRoomLoggingQuery } from '../../../api/accommodationApiSlice';
import LocalDataGrid from '../../../common/components/dataGrid/LocalDataGrid';
import AppDateTimePicker from '../../../common/components/form-inputs/AppDateTimePicker';
import AppInputLabel from '../../../common/components/form-inputs/archive/AppInputLabel';
import { getLogAction } from '../../../api/enum/logRevisionType.enum';
import { Trans } from 'react-i18next';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../utils/dateHelper';

interface LoggingDataGrid {
	id: number;
	updatedAt: string;
	modifier: string;
	type: string;
	items: LogItem[];
}

export interface RateAndInventoryLoggingSummaryItem {
	updatedAt: string;
	modifier: string;
	type: string;
}
export default function RateAndInventoryLoggingCard() {
	const { t } = useTranslation();
	const { propertyId, roomId } = useParams();

	const methods = useForm<LogFilter>();

	const watchLogFrom = methods.watch('logFrom');
	const watchLogTo = methods.watch('logTo');

	// const { data: propertyLogging, isSuccess: isGetPropertyLoggingSuccess } =
	// 	useGetRoomLoggingQuery(
	// 		{
	// 			roomId: roomId ?? '',
	// 			logFrom: dayjs(watchLogFrom).toISOString(),
	// 			logTo: dayjs(watchLogTo).toISOString(),
	// 		},
	// 		{
	// 			skip: watchLogFrom === undefined || watchLogTo === undefined,
	// 		}
	// 	);

	const [logData, setLogData] = useState<LoggingDataGrid[]>();

	// useEffect(() => {
	// 	if (propertyLogging?.data) {
	// 		setLogData(transformResponse(propertyLogging?.data));
	// 	}
	// }, [propertyLogging?.data]);

	const columns: GridColDef[] = [
		{
			field: 'updatedAt',
			headerName: t('logging.ModifiedDate'),
			width: 154, // 24(cell padding left) + 120(header width) + 10(cell padding right)
		},
		{
			field: 'modifier',
			headerName: t('logging.ModifiedBy'),
			width: 100, // 10(cell padding left) + 80(header width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'type',
			headerName: t('logging.Action'),
			width: 70, // 10(cell padding left) + 50(header width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'targetDateInterval',
			headerName: t('logging.TargetDateInterval'),
			minWidth: 180,
			flex: 1,
			sortable: false,
		},
		{
			field: 'weekdays',
			headerName: t('logging.Weekdays'),
			flex: 1,
			sortable: false,
		},
		{
			field: 'value',
			headerName: t('logging.Value'),
			flex: 1,
			sortable: false,
		},
		{
			field: 'target',
			headerName: t('logging.Target'),
			flex: 1,
			sortable: false,
		},
	];

	const transformResponse = (response?: PropertyLogs[]) => {
		if (response == null) return;
		const newResponse: LoggingDataGrid[] = [];

		response.forEach((item, index) => {
			newResponse.push({
				id: index,
				updatedAt: dayjs(item.updatedAt).format(DISPLAY_DATE_TIME_FORMAT),
				modifier: item.modifier,
				type: getLogAction(item.type),
				items: item.items ?? [],
			});
		});

		return newResponse;
	};

	const isLogDirty = watchLogFrom !== undefined || watchLogTo !== undefined;

	const clearOnClick = () => {
		setLogData([]);
		methods.resetField('logFrom');
		methods.resetField('logTo');
	};

	return (
		<FormProvider {...methods}>
			<StyledCard>
				<Stack
					direction='row'
					justifyContent='space-between'
					paddingBottom={'16px'}
				>
					<Typography
						sx={{
							fontSize: '0.875rem',
							fontWeight: 700,
						}}
					>
						{t('logging.SelectDateTime')}
					</Typography>
					<ClearButton disabled={!isLogDirty} onClick={clearOnClick}>
						<Typography variant='regular'>{t('common.clear')}</Typography>
					</ClearButton>
				</Stack>
				<Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
					<AppInputLabel label={t('common.From')} />
					<AppDateTimePicker name={'logFrom'} removeErrorPadding />
					<AppInputLabel label={t('common.To')} />
					<AppDateTimePicker name={'logTo'} removeErrorPadding />
				</Box>
			</StyledCard>
			<Box marginTop={'28px'}>
				<LocalDataGrid
					rows={logData ?? []}
					columns={columns}
					rowCount={logData?.length ?? 0}
					getRowId={(row) => row.id}
					pagination={true}
					NoRowMessage={
						<Trans
							i18nKey={'logging.NoRowMessage'}
							components={{
								Highlight: (
									<Box
										color='#4EA4D3'
										paddingX={'3px'}
										fontWeight={700}
										lineHeight={'17px'}
									/>
								),
							}}
						/>
					}
				/>
			</Box>
		</FormProvider>
	);
}

const StyledCard = styled(Card)(({ theme }) => ({
	width: '100%',
	background: theme.palette.background.infoCard,
	boxShadow: '0px 4px 3px 0px rgba(0, 0, 0, 0.05)',
	borderRadius: 5,
	padding: 20,
}));

const ClearButton = styled(ButtonBase)(({ theme }) => ({
	fontSize: '0.75rem',
	lineHeight: '0.9375rem',
	height: '100%',
	color: theme.palette.primary.main,
	textDecoration: 'underline',
	'&.Mui-disabled': {
		color: theme.palette.common.silver,
		textDecoration: 'none',
	},
}));

import { Box, styled, Divider } from '@mui/material';
import AppSpaceSubtitleLayout from '../../../common/components/form-layout/AppSpaceSubtitleLayout';
import { useTranslation } from 'react-i18next';
import SpaceDisplayText from '../../../common/components/form-data-display/SpaceDisplayText';

interface GrandTotalBreakdownCardProps {
	data?: any;
	currency: string;
	label: string;
}

export default function GrandTotalBreakdownCard({
	data,
	currency,
	label,
}: GrandTotalBreakdownCardProps) {
	const { t } = useTranslation();

	return (
		<>
			{data !== undefined && (
				<StyledBreakdownCard>
					<AppSpaceSubtitleLayout
						label={label}
						content={`${currency} ${data.total}`}
					/>
					{data.items?.length > 0 ? (
						<Box
							sx={{
								display: 'flex',
								gap: '5px',
								flexDirection: 'column',
								marginBottom: '15px',
							}}
						>
							{data.items.map((dataItem: any, dataIndex: any) => {
								return (
									<SpaceDisplayText
										key={dataIndex}
										text1={dataItem.name}
										text2={dataItem.rate.toString()}
									/>
								);
							})}
						</Box>
					) : (
						<></>
					)}

					<Divider sx={{ marginBottom: '15px' }} />

					<AppSpaceSubtitleLayout
						label={`${t('abk.GrandTotal')}`}
						content={`${currency} ${data.grandTotal.toString()}`}
						color={'#4EA4D3'}
					/>
				</StyledBreakdownCard>
			)}
		</>
	);
}

const StyledBreakdownCard = styled(Box)({
	backgroundColor: '#F2FAFF',
	padding: '20px ',
	paddingBottom: '0',
	borderRadius: '10px',
	marginBottom: '20px',
});

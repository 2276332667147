import Option from '../../common/types/option.type';

export enum RoomClass {
	INDEPENDENT = 0,
	SUPPLEMENT = 1,
}

export const roomClassOption: Option[] = [
	{
		id: RoomClass.INDEPENDENT,
		name: 'Independent',
	},
	{
		id: RoomClass.SUPPLEMENT,
		name: 'Supplement',
	},
];

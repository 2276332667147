import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { FormControlLabel, styled } from '@mui/material';
import Option from '../../types/option.type';
import AppCheckbox from './AppCheckbox';

interface AppHorizontalCheckboxGroupProps {
	options: Option[];
	undefinedIfEmptyArray?: boolean;
}

export default function AppHorizontalCheckboxGroup<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	options,
	undefinedIfEmptyArray,
	...props
}: AppHorizontalCheckboxGroupProps & UseControllerProps<TFieldValues, TName>) {
	const { field } = useController(props);

	function handleSelect(checkedName: any) {
		const newNames = field.value?.includes(checkedName)
			? field.value?.filter((name: any) => name !== checkedName)
			: [...(field.value ?? []), checkedName];

		if (newNames.length == 0 && undefinedIfEmptyArray) {
			field.onChange(undefined);
		} else {
			field.onChange(newNames);
		}
	}

	return (
		<>
			{options.map((option) => (
				<StyledFormControlLabel
					key={option.id}
					control={
						<>
							<AppCheckbox
								checked={field.value?.includes(option.id) ?? false}
								onChange={() => handleSelect(option.id)}
							/>
							{option.startIcon ? <>{option.startIcon}</> : <></>}
						</>
					}
					label={option.name}
				/>
			))}
		</>
	);
}

const StyledFormControlLabel = styled(FormControlLabel)({
	margin: '0 0 0.625rem 0',
	'& .MuiFormControlLabel-label': {
		fontSize: '0.75rem',
		lineHeight: '0.9375rem',
		fontWeight: '450',
		marginLeft: '0.25rem',
	},
	'& .MuiCheckbox-root': {
		padding: '0 8px 0 0',
	},
});

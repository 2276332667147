import { Button, Typography } from '@mui/material';
import AppModal from './AppModal';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal } from './modalSlice';
import { AbkStatus } from '../../../api/enum/abkStatus.enum';
import { useUpdateAbkStatusMutation } from '../../../api/abkApiSlice';

interface DeleteAbkBookingModalProps {
	id: string;
	status: AbkStatus;
}

const DeleteAbkBookingImageModal = ({
	id,
	status,
}: DeleteAbkBookingModalProps) => {
	const dispatch = useAppDispatch();
	const [updateAbkStatus, { isSuccess: isUpdateAbkStatusSuccess }] =
		useUpdateAbkStatusMutation();

	const deleteAbkBookingButtonOnClick = () => {
		updateAbkStatus({
			abkId: id ?? '',
			body: { status: status },
		});
	};

	useEffect(() => {
		if (isUpdateAbkStatusSuccess) {
			dispatch(hideModal());
		}
	}, [isUpdateAbkStatusSuccess]);
	return (
		<AppModal
			title={
				<Typography variant='bold' color='#DA5150'>
					Cancel Booking {id}
				</Typography>
			}
			content={
				<>
					Are you sure to cancel the booking of{' '}
					<Typography variant='bold' color='#DA5150'>
						{' '}
						{id}
					</Typography>
					, which the booking will no longer valid for all parties?
				</>
			}
			actionButtonEl={
				<Button
					variant='contained'
					color='error'
					onClick={deleteAbkBookingButtonOnClick}
				>
					Delete
				</Button>
			}
		/>
	);
};

export default DeleteAbkBookingImageModal;

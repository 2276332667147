import { Box, styled, Divider } from '@mui/material';
import AppSpaceSubtitleLayout from '../../../common/components/form-layout/AppSpaceSubtitleLayout';
import { useTranslation } from 'react-i18next';
import SpaceDisplayText from '../../../common/components/form-data-display/SpaceDisplayText';
import { getMealNameByMealType } from '../../../api/enum/mealType.enum';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '../../../utils/dateHelper';

interface CusRateBreakdownCardProps {
	data?: any;
	label: string;
}

export default function CusRateBreakdownCard({
	data,
	label,
}: CusRateBreakdownCardProps) {
	const { t } = useTranslation();

	return (
		<>
			{data !== undefined && (
				<StyledBreakdownCard>
					<AppSpaceSubtitleLayout label={label} content={data.currency} />
					<Box
						sx={{
							display: 'flex',
							gap: '5px',
							flexDirection: 'column',
							marginBottom: '15px',
						}}
					>
						{data.roomRate && (
							<SpaceDisplayText
								text1={`${t('bk.BaseRate')} (${dayjs(
									data.stayPeriod.start
								).format(DISPLAY_DATE_FORMAT)} - ${dayjs(
									data.stayPeriod.end
								).format(DISPLAY_DATE_FORMAT)})`}
								text2={data.roomRate?.toString()}
							/>
						)}
						{data.adult && (
							<SpaceDisplayText
								text1={
									data.adult
										? `${t('bk.Adult')}: ${data.adult.num} (${dayjs(
												data.stayPeriod.start
										  ).format(DISPLAY_DATE_FORMAT)} - ${dayjs(
												data.stayPeriod.end
										  ).format(DISPLAY_DATE_FORMAT)})`
										: ''
								}
								text2={data.adult.rate?.toString()}
							/>
						)}
						{data.child && (
							<SpaceDisplayText
								text1={
									data.child
										? `${t('bk.Child')}: ${data.child.num} (${dayjs(
												data.stayPeriod.start
										  ).format(DISPLAY_DATE_FORMAT)} - ${dayjs(
												data.stayPeriod.end
										  ).format(DISPLAY_DATE_FORMAT)})`
										: ''
								}
								text2={data.child.rate?.toString()}
							/>
						)}
						{data.toddler && (
							<SpaceDisplayText
								text1={
									data.toddler
										? `${t('bk.Toddler')}: ${data.toddler.num} (${dayjs(
												data.stayPeriod.start
										  ).format(DISPLAY_DATE_FORMAT)} - ${dayjs(
												data.stayPeriod.end
										  ).format(DISPLAY_DATE_FORMAT)})`
										: ''
								}
								text2={data.toddler.rate?.toString()}
							/>
						)}
						{data.infant && (
							<SpaceDisplayText
								text1={
									data.infant
										? `${t('bk.Infant')}: ${data.infant.num} (${dayjs(
												data.stayPeriod.start
										  ).format(DISPLAY_DATE_FORMAT)} - ${dayjs(
												data.stayPeriod.end
										  ).format(DISPLAY_DATE_FORMAT)})`
										: ''
								}
								text2={data.infant.rate?.toString()}
							/>
						)}
					</Box>
					<Divider sx={{ marginBottom: '15px' }} />
					<Box
						sx={{
							display: 'flex',
							gap: '5px',
							flexDirection: 'column',
							marginBottom: '15px',
						}}
					>
						{data.optionalMealRates.length > 0 && (
							<>
								{data.optionalMealRates.map((mealRate: any, index: number) => (
									<SpaceDisplayText
										key={index}
										text1={`${t('bk.OptionalMeal')} - ${getMealNameByMealType(
											mealRate.mealType
										)}`}
										text2={mealRate.rate.toString()}
									/>
								))}
								<Divider sx={{ marginBottom: '15px' }} />
							</>
						)}
					</Box>
					{((data.subtotal !== undefined && data.subtotal !== null) ||
						(data.taxAndServiceChargeAmount !== undefined &&
							data.taxAndServiceChargeAmount !== null) ||
						data.promo) && (
						<>
							<Box
								sx={{
									display: 'flex',
									gap: '5px',
									flexDirection: 'column',
									marginBottom: '15px',
								}}
							>
								{data.subtotal !== undefined && data.subtotal !== null && (
									<AppSpaceSubtitleLayout
										label={`${t('bk.Subtotal')}`}
										content={data.subtotal?.toString()}
									/>
								)}
								{data.taxAndServiceChargeAmount !== undefined &&
									data.taxAndServiceChargeAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.TaxAndServiceCharge')}
											text2={data.taxAndServiceChargeAmount?.toString()}
										/>
									)}
								{data.promo && (
									<SpaceDisplayText
										text1={`${t('bk.DiscountedAmount')} (${data.promo.code})`}
										text2={`(${data.promo.rate})`}
									/>
								)}
							</Box>
							<Divider sx={{ marginBottom: '15px' }} />
						</>
					)}

					<AppSpaceSubtitleLayout
						label={`${t('bk.BaseRateTotal')}`}
						content={data.total.toString()}
						color={'#4EA4D3'}
					/>
				</StyledBreakdownCard>
			)}
		</>
	);
}

const StyledBreakdownCard = styled(Box)({
	backgroundColor: '#F5F5F5',
	padding: '20px ',
	paddingBottom: '0',
	borderRadius: '10px',
	marginBottom: '20px',
});

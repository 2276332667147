import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import TitleCard from '../../../../common/components/card/TitleCard';
import { useTranslation } from 'react-i18next';
import { useGetAutoFaqSummaryQuery } from '../../../../api/accommodationApiSlice';
import FaqDisplayText from '../../../../common/components/form-data-display/FaqDisplayText';
import BannedIconButton from '../../../../common/components/buttons/BannedIconButton';

export default function AutoFaqCard() {
	const { propertyId } = useParams();
	const { t } = useTranslation();

	const { data: getFaqSummaryResponse } = useGetAutoFaqSummaryQuery(
		propertyId ?? ''
	);

	const pageContent = (
		<>
			<TitleCard title={t('faq.AutoGenQuestions')}>
				{getFaqSummaryResponse?.data.map((item, index) => {
					return (
						<Box key={index}>
							<FaqDisplayText
								label={t('faq.AutoGenQuestion')}
								index={index}
								question={getFaqSummaryResponse?.data[index].question}
								answer={getFaqSummaryResponse?.data[index].answer}
								infoPopUp={' '}
							/>
						</Box>
					);
				})}
				<Box sx={{ position: 'absolute', top: 40, right: 40 }}>
					<BannedIconButton label={t('button.Uneditable')} />
				</Box>
			</TitleCard>
		</>
	);
	return <>{pageContent}</>;
}

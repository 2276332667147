import { Box, Divider, Stack } from '@mui/material';
import UnderlinedIconButton from '../../../../../common/components/buttons/UnderlinedIconButton';
import { ReactComponent as EditIcon } from '../../../../../assets/icon/edit.svg';
import { FormCardType } from '../../../../../common/components/card/FormCard';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import AppInputLayout from '../../../../../common/components/form-layout/AppInputLayout';
import { useTranslation } from 'react-i18next';
import { useLazyGetRatePlanQuery } from '../../../../../api/accommodationApiSlice';
import { RatePlanIdContext } from '../RatePlanDetailPage';
import NewDisplayText from '../../../../../common/components/form-data-display/NewDisplayText';
import YesNoDisplay from '../../../../../common/components/form-data-display/YesNoDisplay';
import AppSubtitleLayout from '../../../../../common/components/form-layout/AppSubtitleLayout';
import util from 'util';
import EditorDisplay from '../../../../../common/components/editor/EditorDisplay';

export default function RatePlanPaymentAndPolicyView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const ratePlanId = useContext(RatePlanIdContext);
	const [getRatePlan, { data: getRatePlanResponse }] =
		useLazyGetRatePlanQuery();

	useEffect(() => {
		getRatePlan(ratePlanId);
	}, [getRatePlanResponse]);
	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppInputLayout
					label={t('room.ratePlan.RatePlanDescription')}
					labelAlignItemsFlexStart={true}
				>
					<NewDisplayText
						text={getRatePlanResponse?.data.paymentAndPolicy.description}
					/>
				</AppInputLayout>

				<AppInputLayout
					label={t('room.ratePlan.RatePlanUniquePolicy')}
					labelAlignItemsFlexStart={true}
				>
					<EditorDisplay
						editorStateString={
							getRatePlanResponse?.data.paymentAndPolicy.uniquePolicy
						}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('room.ratePlan.CancellationPolicy')} />

				<AppInputLayout label={t('room.ratePlan.Refundable')}>
					<YesNoDisplay
						value={getRatePlanResponse?.data.paymentAndPolicy.refundable}
					/>
				</AppInputLayout>

				{(() => {
					if (
						getRatePlanResponse?.data.paymentAndPolicy.cancellationPolicies ==
							null ||
						getRatePlanResponse?.data.paymentAndPolicy.cancellationPolicies
							.length == 0
					) {
						return <></>;
					}

					const policies = [];

					for (
						let i = 0;
						i <
						getRatePlanResponse?.data.paymentAndPolicy.cancellationPolicies
							.length;
						i++
					) {
						const day =
							getRatePlanResponse?.data.paymentAndPolicy.cancellationPolicies[i]
								.day;
						const deduction =
							getRatePlanResponse?.data.paymentAndPolicy.cancellationPolicies[i]
								.deduction;
						const policy = t('room.ratePlan.Policy');

						policies.push(
							<AppInputLayout
								key={i}
								label={t('room.ratePlan.PolicyNumber') + (i + 1)}
								labelAlignItemsFlexStart={true}
							>
								<NewDisplayText
									key={i}
									text={util.format(policy, day, deduction)}
								/>
							</AppInputLayout>
						);
					}

					return <>{policies}</>;
				})()}
			</Stack>
		</>
	);
}

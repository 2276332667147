import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Stack, TextFieldProps } from '@mui/material';
import { BedConfig } from '../../../api/DTO/room.interface';
import AbkSingleBedConfig from './AbkSingleBedConfigField';

export default function AbkBedConfigsField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ ...props }: UseControllerProps<TFieldValues, TName> & TextFieldProps) {
	const { field, fieldState } = useController(props);
	const onChange = field.onChange;
	const errorMsg = fieldState.error?.message;

	const handleChange = (value: BedConfig) => {
		onChange(value);
	};
	return (
		<>
			<Stack spacing={'20px'} marginBottom={'20px'}>
				<Stack
					spacing={'15px'}
					sx={{ background: '#fff', borderRadius: '10px' }}
				>
					<AbkSingleBedConfig
						bedConfigValue={field.value ?? {}}
						onChange={(value: BedConfig) => handleChange(value)}
						errorMsg={errorMsg}
					/>
				</Stack>
			</Stack>
		</>
	);
}

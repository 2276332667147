import { useTranslation } from 'react-i18next';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/system';
import CustomDialog from '../../../../common/components/CustomDialog';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useAddRoomTagMutation } from '../../../../api/accommodationApiSlice';
import AppTextField from '../../../../common/components/form-inputs/archive/AppTextField';
import { AddTag, Tag } from '../../../../api/DTO/room.interface';

export const defaultValues: DefaultValues<AddTag> = {
	name: '',
	nameEn: '',
};

export interface AddRoomTagDialogProps {
	open: boolean;
	onClose: (addedTag?: Tag) => void;
}

export default function AddRoomTagDialog(props: AddRoomTagDialogProps) {
	const { t } = useTranslation();

	const { onClose, open } = props;

	const handleClose = () => {
		reset();
		if (addTagResponse) onClose(addTagResponse.data);
		else onClose();
	};

	// form

	const formSchema = Yup.object().shape({
		name: Yup.string().required(t('errorMessage.required')),
		nameEn: Yup.string().required(t('errorMessage.required')),
	});

	const { handleSubmit, control, reset } = useForm<AddTag>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [addTag, { data: addTagResponse, isSuccess }] = useAddRoomTagMutation();

	const onSubmit: SubmitHandler<AddTag> = (data) => {
		addTag(data);
	};

	useEffect(() => {
		if (isSuccess) {
			handleClose();
		}
	}, [isSuccess]);

	return (
		<CustomDialog
			title={t('room.CreateTag')}
			onClose={handleClose}
			open={open}
			content={
				<Stack spacing='24px'>
					<AppTextField
						name='name'
						control={control}
						label={t('room.NewTagInTraditionalChinese')}
						placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
					/>
					<AppTextField
						name='nameEn'
						control={control}
						label={t('room.NewTagInEnglish')}
						placeholder={t('placeholder.pleaseEnterEnglish')}
					/>
					<Stack direction='row-reverse' spacing='12px'>
						<Button
							type='submit'
							variant='contained'
							color='success'
							onClick={handleSubmit(onSubmit)}
						>
							{t('button.Create')}
						</Button>
						<Button onClick={handleClose} variant='outlined' color='info'>
							{t('button.Cancel')}
						</Button>
					</Stack>
				</Stack>
			}
		></CustomDialog>
	);
}

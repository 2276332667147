import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Box, Stack, TextFieldProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppAddPhotoButton from '../../../../common/components/form-inputs/AppAddPhotoButton';
import { ReactComponent as AddIcon } from '../../../../assets/icon/button-add.svg';
import { useUploadPublicMediaMutation } from '../../../../api/mediaApiSlice';
import { useEffect } from 'react';
import { Transport } from '../../../../api/DTO/transportation.interface';
import UploadedImageWithTitleAndDes from './UploadedImageWithTitleAndDes';

interface MultipleImagesWithTitleAndDesFieldProps {}

export default function MultipleImagesWithTitleAndDesField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	...props
}: UseControllerProps<TFieldValues, TName> &
	TextFieldProps &
	MultipleImagesWithTitleAndDesFieldProps) {
	const { t } = useTranslation();
	const { field, fieldState } = useController(props);
	const filesList: Transport[] = field.value ?? [];
	const onChange = field.onChange;

	const [uploadMedia, { data: uploadMediaResponse, isSuccess }] =
		useUploadPublicMediaMutation();

	useEffect(() => {
		if (isSuccess && uploadMediaResponse?.data[0]) {
			const newFileWithDescription: Transport = {
				id: uploadMediaResponse?.data[0].id,
				url: uploadMediaResponse?.data[0].url,
				title: '',
				description: '',
			};
			onChange([...filesList, newFileWithDescription]);
		}
	}, [isSuccess]);

	async function onUploadFileChange(
		event: React.ChangeEvent<HTMLInputElement>
	) {
		const target = event.target;
		const file = target.files?.[0];

		if (file) {
			uploadMedia(file);
		}
	}

	const handleFileRemove = (index: number) => {
		const list = [...filesList];
		list.splice(index, 1);
		onChange(list);
	};

	const handleFileChange = (value: Transport, index: number) => {
		const list = [...filesList];
		list[index] = value;
		onChange(list);
	};

	return (
		<>
			<Stack spacing={'15px'}>
				{filesList.map((Transport: Transport, index: number) => {
					return (
						<Box key={Transport.id}>
							<UploadedImageWithTitleAndDes
								key={index}
								index={index}
								value={Transport}
								onChange={(value: Transport) => handleFileChange(value, index)}
								deleteOnClick={handleFileRemove}
								fieldState={fieldState}
							/>
						</Box>
					);
				})}
				<Box height={'200px'}>
					<AppAddPhotoButton component='label'>
						<input
							type='file'
							name='doc'
							hidden
							onChange={onUploadFileChange}
							accept='image/jpeg, image/png, image/gif, image/webp'
						/>
						<Typography fontSize={'14px'} fontWeight={'700'}>
							<AddIcon />
							{t('button.AddPhoto')}
						</Typography>
						<Typography fontSize={'10px'}>
							{t('place.SuggestedFormat')}
							<b>{t('place.FileType')}</b>
						</Typography>
						<Typography fontSize={'10px'}>
							{t('place.SuggestedDimension')}
							<b>{t('place.Dimension')}</b>
						</Typography>
					</AppAddPhotoButton>
				</Box>
			</Stack>
		</>
	);
}

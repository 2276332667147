import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Stack, TextFieldProps } from '@mui/material';
import { BedConfig } from '../../../../api/DTO/room.interface';
import Addbutton from '../../../../common/components/buttons/AddButton';
import SingleBedConfig from './SingleBedConfigField';

export default function BedConfigsField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ ...props }: UseControllerProps<TFieldValues, TName> & TextFieldProps) {
	const { field, fieldState } = useController(props);
	const bedConfigsList = field.value ?? [{ beddings: {} }];
	const onChange = field.onChange;
	const errorMsg = fieldState.error?.message;

	const handleBedConfigAdd = () => {
		onChange([...bedConfigsList, { beddings: {} }]);
	};

	const handleBedConfigRemove = (index: number) => {
		const list = [...bedConfigsList];
		list.splice(index, 1);
		onChange(list);
	};

	const handleBedfigChange = (value: BedConfig, index: number) => {
		const list = [...bedConfigsList];
		list[index] = value;
		onChange(list);
	};

	return (
		<>
			<Stack spacing={'20px'}>
				<Stack spacing={'15px'}>
					{bedConfigsList.map((bedConfig: BedConfig, index: number) => (
						<SingleBedConfig
							key={index}
							index={index}
							bedConfigValue={bedConfig}
							onChange={(value: BedConfig) => handleBedfigChange(value, index)}
							showDeleteButton={bedConfigsList.length > 1}
							deleteOnClick={handleBedConfigRemove}
							errorMsg={errorMsg}
						/>
					))}
				</Stack>
				<Addbutton onClick={handleBedConfigAdd} />
			</Stack>
		</>
	);
}

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	Box,
	FormControl,
	ToggleButton,
	ToggleButtonGroup,
	styled,
	Typography,
} from '@mui/material';
import Option from '../../types/option.type';

interface AppToggleButtonProps {
	formLabel?: string;
	disabled?: boolean;
	options: Option[];
	defaultValue?: any;
	buttonOnChange?: (value: any) => void;
}

interface StyledToggleButtonProps {
	selectedbackgroundcolor: string;
	selectedcolor: string;
}

export default function AppToggleButton<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	formLabel,
	disabled,
	options,
	defaultValue,
	buttonOnChange,
	...props
}: AppToggleButtonProps & UseControllerProps<TFieldValues, TName>) {
	const { field, fieldState } = useController(props);

	const handleAlignment = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: any
	) => {
		if (newAlignment !== null) {
			field.onChange(newAlignment);

			if (buttonOnChange) {
				buttonOnChange(newAlignment);
			}
		}
	};

	return (
		<FormControl sx={{ display: 'flex', flexDirection: 'column' }}>
			<Typography fontSize={'14px'}>{formLabel}</Typography>
			<StyledToggleButtonGroup
				{...field}
				color='primary'
				exclusive
				onChange={(e, value) => {
					handleAlignment(e, value);
				}}
				value={field.value ?? ''}
				aria-label={formLabel}
				disabled={disabled}
			>
				{options.map((option) => (
					<StyledToggleButton
						key={option.id}
						value={option.id}
						selectedbackgroundcolor={option.selectedbackgroundcolor}
						selectedcolor={option.selectedcolor}
					>
						<StyledButtonContent>{option.name}</StyledButtonContent>
					</StyledToggleButton>
				))}
			</StyledToggleButtonGroup>
		</FormControl>
	);
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
	border: 'unset !important',
	'& .MuiToggleButtonGroup-grouped': {
		border: '0.5px solid !important',
		borderRadius: '5px',
		borderColor: '#BCBCBC',
		color: '#BCBCBC',
		textTransform: 'none',
		padding: '6px 15px',
		lineHeight: '18px',
		fontSize: '12px',
		'&.Mui-selected, &.Mui-selected:hover': {
			border: '1px solid !important',
			color: '#32A5F1',
			fontWeight: '700',
			backgroundColor: '#F0F7FF',
		},
		'&.Mui-disabled': {
			border: '0.5px solid !important',
			borderColor: 'rgba(188, 188, 188, 0.26)',
			color: 'rgba(188, 188, 188, 0.6)',
			textTransform: 'none',
			padding: '6px 15px',
			lineHeight: '18px',
			fontSize: '12px',
			backgroundColor: 'rgba(188, 188, 188, 0.08)',
		},
	},
});

export const StyledToggleButton = styled(ToggleButton)<StyledToggleButtonProps>(
	({ ...props }) => ({
		width: '100%',
		borderRadius: '5px',
		color: '#BCBCBC',
		backgroundColor: '#FFFFFF',
		padding: '5px',

		height: '45px',
		textTransform: 'none',

		'&.Mui-selected, &.Mui-selected:hover': {
			color: props.selectedcolor,
			backgroundColor: props.selectedbackgroundcolor,
		},
		'&.Mui-disabled': {
			borderColor: 'transparent',
		},
	})
);

export const StyledButtonContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	// paddingX: '20px',
	minWidth: '80px',

	alignItems: 'center',
	justifyContent: 'center',
	lineHeight: '1.5',
	fontSize: '12px',
});

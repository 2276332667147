/* eslint-disable no-mixed-spaces-and-tabs */
import { useState } from 'react';

import {
	Button,
	Box,
	Stack,
	Typography,
	Menu,
	MenuProps,
	styled,
	Divider,
} from '@mui/material';

import { StyledMenuItem } from './styled/StyledSelect';
import { ReactComponent as MenuDotIcon } from '../../assets/icon/menu-dot.svg';

interface AppDropDownButtonProps {
	passObjectValue?: boolean;
	label?: string;
	options?: any[];
	disabled?: boolean;
	disabledOption?: number;
	onChange: (value: any) => void;
}

const AppDropDownButton = ({
	passObjectValue,
	label,
	options,
	disabled,
	disabledOption,
	onChange,
}: AppDropDownButtonProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [open, setOpen] = useState(false);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen((previousOpen) => !previousOpen);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	return (
		<Box position={'relative'}>
			<Button
				variant='outlined'
				endIcon={<MenuDotIcon />}
				sx={{
					backgroundColor: '#FFF',
					borderColor: '#FFF',
					boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
				}}
				onClick={handleClick}
			>
				{label}
			</Button>
			<StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				{options &&
					options.map((option, index) => (
						<Box key={option.id}>
							<StyledMenuItem
								value={option.id}
								onClick={() => {
									setOpen(false);
									onChange(option.id);
								}}
								sx={{
									backgroundColor: option.backgroundColor,
								}}
								disabled={disabledOption === option.id && disabled}
							>
								<Stack direction={'row'} alignItems={'center'}>
									<Typography
										variant='regular'
										color={option.color}
										sx={{
											fontSize: '0.75rem',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<Box
											paddingRight={'10px'}
											height={'100%'}
											width={'30px'}
											display={'flex'}
											alignItems={'center'}
										>
											{option.startIcon}
										</Box>{' '}
										{option.name}
									</Typography>
								</Stack>
							</StyledMenuItem>
							{index + 1 < options.length && <StyledDivider />}
						</Box>
					))}
			</StyledMenu>
		</Box>
	);
};

export default AppDropDownButton;

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
	({ theme }) => ({
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 180,
			back: 0,
			color:
				theme.palette.mode === 'light'
					? 'rgb(55, 65, 81)'
					: theme.palette.grey[300],
			boxShadow:
				'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
			'& .MuiMenu-list': {
				padding: '0',
			},
			'& .MuiMenuItem-root': {
				'& .MuiSvgIcon-root': {
					fontSize: 18,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(1.5),
				},
			},
		},
	})
);

const StyledDivider = styled(Divider)({
	border: '1.5px solid #F5F5F5',
	marginTop: '0 !important',
	marginBottom: '0 !important',
});

import { apiSlice } from './apiSlice';
import { Place } from './DTO/place.interface';
import { SuccessResponse } from './response/shared/success.response';
import {
	PlaceFilter,
	PlaceBaseInfo,
	PlaceCatAndFac,
	PlaceAdmissionDetails,
	PlaceMedia,
	PlaceSummaryResponse,
	Tag,
	GetTag,
	Categories,
	AddTag,
} from './DTO/place.interface';

import {
	BlogPostFilter,
	BlogPost,
	BlogPostSummaryResponse,
	BlogCategory,
	Author,
	UpdateBlogPostStatusRequest,
} from './DTO/blogPost.interface';

const SERVICE_PATH = '/content-service';

export const ContentApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// Place

		addPlace: builder.mutation<SuccessResponse<Place>, Place>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/places`,
				method: 'POST',
				body,
			}),
		}),

		getPlace: builder.query<SuccessResponse<Place>, string>({
			query: (placeId) => ({
				url: `${SERVICE_PATH}/admin/places/${placeId}`,
			}),
			providesTags: ['Place'],
		}),

		getFilteredPlaces: builder.query<PlaceSummaryResponse, PlaceFilter>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/places/filter`,
				method: 'POST',
				body,
			}),
		}),

		updatePlaceBaseInfo: builder.mutation<
			SuccessResponse<PlaceBaseInfo>,
			{ placeId: string; body: PlaceBaseInfo }
		>({
			query: ({ placeId, body }) => ({
				url: `${SERVICE_PATH}/admin/places/${placeId}/base-info`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Place'],
		}),

		updatePlaceCatAndFac: builder.mutation<
			SuccessResponse<PlaceCatAndFac>,
			{ placeId: string; body: PlaceCatAndFac }
		>({
			query: ({ placeId, body }) => ({
				url: `${SERVICE_PATH}/admin/places/${placeId}/cat-and-fac`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Place'],
		}),

		// Categories

		getCategories: builder.query<SuccessResponse<Categories[]>, number>({
			query: (type) => ({
				url: `${SERVICE_PATH}/admin/place-categories?type=${type}`,
			}),
		}),

		// Tag (CatAndFac)

		getFacTags: builder.query<SuccessResponse<GetTag[]>, number>({
			query: (type) => ({
				url: `${SERVICE_PATH}/admin/tag-categories?type=${type}`,
			}),
			providesTags: ['FacilityTags'],
		}),

		addFacTag: builder.mutation<
			SuccessResponse<Tag>,
			{ tag_category_id: string; body: AddTag }
		>({
			query: ({ tag_category_id, body }) => ({
				url: `${SERVICE_PATH}/admin/tag-categories/${tag_category_id}/tags`,
				method: 'POST',
				body,
			}),

			invalidatesTags: ['FacilityTags'],
		}),

		updatePlaceMedia: builder.mutation<
			SuccessResponse<PlaceMedia>,
			{ placeId: string; body: PlaceMedia }
		>({
			query: ({ placeId, body }) => ({
				url: `${SERVICE_PATH}/admin/places/${placeId}/medias`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Place'],
		}),

		updatePlaceAdmissionDetails: builder.mutation<
			SuccessResponse<PlaceAdmissionDetails>,
			{ placeId: string; body: PlaceAdmissionDetails }
		>({
			query: ({ placeId, body }) => ({
				url: `${SERVICE_PATH}/admin/places/${placeId}/admission-details`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Place'],
		}),

		//Blog post

		addBlogPost: builder.mutation<SuccessResponse<BlogPost>, BlogPost>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/blogs`,
				method: 'POST',
				body,
			}),
		}),

		getBlogPost: builder.query<SuccessResponse<BlogPost>, string>({
			query: (blogId) => ({
				url: `${SERVICE_PATH}/admin/blogs/${blogId}`,
			}),
			providesTags: ['Blog'],
		}),

		getFilteredBlogPost: builder.query<BlogPostSummaryResponse, BlogPostFilter>(
			{
				query: (body) => ({
					url: `${SERVICE_PATH}/admin/blogs/filter`,
					method: 'POST',
					body,
				}),
				providesTags: ['Blogs'],
			}
		),

		updateBlogPost: builder.mutation<
			SuccessResponse<BlogPost>,
			{ blogId: string; body: BlogPost }
		>({
			query: ({ blogId, body }) => ({
				url: `${SERVICE_PATH}/admin/blogs/${blogId}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Blog'],
		}),

		updateBlogPostStatus: builder.mutation<
			SuccessResponse<BlogPost>,
			{ blogId: string; body: UpdateBlogPostStatusRequest }
		>({
			query: ({ blogId, body }) => ({
				url: `${SERVICE_PATH}/admin/blogs/${blogId}/status`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Blogs', 'Blog'],
		}),

		getBlogPostCategories: builder.query<SuccessResponse<BlogCategory[]>, void>(
			{
				query: () => `${SERVICE_PATH}/admin/blog-categories`,
			}
		),

		getBlogPostAuthors: builder.query<SuccessResponse<Author[]>, void>({
			query: () => `${SERVICE_PATH}/admin/blogs/authors`,
		}),
	}),
});

export const {
	useAddPlaceMutation,
	useGetPlaceQuery,
	useGetFilteredPlacesQuery,
	useUpdatePlaceBaseInfoMutation,
	useUpdatePlaceCatAndFacMutation,
	useGetCategoriesQuery,
	useGetFacTagsQuery,
	useAddFacTagMutation,
	useUpdatePlaceMediaMutation,
	useUpdatePlaceAdmissionDetailsMutation,
	useAddBlogPostMutation,
	useGetBlogPostQuery,
	useGetFilteredBlogPostQuery,
	useUpdateBlogPostMutation,
	useUpdateBlogPostStatusMutation,
	useGetBlogPostCategoriesQuery,
	useGetBlogPostAuthorsQuery,
} = ContentApiSlice;

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	ButtonBase,
	Divider,
	Stack,
	Typography,
	styled,
} from '@mui/material';
import { ReactComponent as FilterIcon } from '../../../assets/icon/filter.svg';
import { ReactComponent as InvertedTriangle } from '../../../assets/icon/inverted-triangle.svg';
import { useFormContext } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useTranslation } from 'react-i18next';
import Option from '../../../common/types/option.type';
import { PropertyFilter } from '../../../api/DTO/property.interface';
import AppSummarySearchBar from '../../../common/components/form-inputs/AppSummarySearchBar';
import AppFilterSectionLayout from '../../../common/components/form-layout/AppFilterSectionLayout';
import AppHorizontalCheckboxGroup from '../../../common/components/form-inputs/AppHorizontalCheckboxGroup';
import { propertyStatusOptions } from '../../../api/enum/propertyStatus.enum';
import AppDropDownMenu from '../../../common/components/form-inputs/archive/AppDropDownMenu';
import {
	useGetCountriesQuery,
	useLazyGetAreasQuery,
	useLazyGetMajorAreasQuery,
} from '../../../api/locationApiSlice';
import { useEffect, useState } from 'react';
import { propertyCategoryOptions } from '../../../api/enum/propertyCategories.enum';
import { hasOwnerOptions } from '../../../api/enum/hasOwner.enum';
import { useLazyGetOwnersQuery } from '../../../api/accommodationApiSlice';
import {
	AppFilterChip,
	FilterChipLayout,
} from '../../../common/components/chip/AppFilterChip';

interface PropertySummaryFilterSectionProps {
	isInitializedForm: boolean;
	transformedSearchParams: PropertyFilter;
}

export default function PropertySummaryFilterSection({
	isInitializedForm,
	transformedSearchParams,
}: PropertySummaryFilterSectionProps) {
	const { t } = useTranslation();
	const { control, watch, resetField, setValue } =
		useFormContext<PropertyFilter>();

	const resetFilterRHFState = () => {
		resetField('statuses');
		resetField('countryId');
		resetField('majorAreaId');
		resetField('areaId');
		resetField('categories');
		resetField('hasOwner');
		resetField('ownerId');
	};

	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetFilterRHFState();
	};

	// location data and watch

	const { data: getCountriesResponse } = useGetCountriesQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});
	const [getMajorAreas] = useLazyGetMajorAreasQuery();
	const [getAreas] = useLazyGetAreasQuery();

	const [majorAreaOptions, setMajorAreaOptions] = useState<Option[]>([]);
	const [areaOptions, setAreaOptions] = useState<Option[]>([]);

	const watchCountryId = watch('countryId');
	const watchMajorAreaId = watch('majorAreaId');
	const watchAreaId = watch('areaId');

	useEffect(() => {
		if (watchCountryId == undefined) {
			setMajorAreaOptions([]);
		}
		if (watchCountryId) {
			const fetchMajorAreas = async () => {
				const majorAreasResult = await getMajorAreas(watchCountryId);
				setMajorAreaOptions(majorAreasResult.data?.data ?? []);
			};
			fetchMajorAreas();
		}
		if (isInitializedForm) setValue('majorAreaId', undefined);
		setAreaOptions([]);
	}, [watchCountryId]);

	useEffect(() => {
		if (watchMajorAreaId) {
			const fetchAreas = async () => {
				const areasResult = await getAreas(watchMajorAreaId);
				setAreaOptions(areasResult.data?.data ?? []);
			};
			fetchAreas();
		}
		if (isInitializedForm) setValue('areaId', undefined);
	}, [watchMajorAreaId]);

	// ownership data and watch

	const [getOwners] = useLazyGetOwnersQuery();
	const [ownersResponse, setOwnersResponse] = useState<Option[]>([]);

	const watchHasOwner = watch('hasOwner');
	const watchOwnerId = watch('ownerId');

	useEffect(() => {
		if (!watchHasOwner) {
			resetField('ownerId');
			setOwnersResponse([]);
			return;
		}
		const fetchOwners = async () => {
			const ownersResult = await getOwners();
			setOwnersResponse(ownersResult.data?.data ?? []);
		};
		fetchOwners();
	}, [watchHasOwner]);

	// watch

	const watchStatuses = watch('statuses');
	const watchCategories = watch('categories');

	// clear buttons

	const clearStatus = () => {
		resetField('statuses');
	};

	const clearLocation = () => {
		resetField('countryId');
		resetField('majorAreaId');
		resetField('areaId');
	};

	const clearCategories = () => {
		resetField('categories');
	};

	const clearOwnership = () => {
		resetField('hasOwner');
		resetField('ownerId');
	};

	const isStatusDirty =
		watchStatuses === undefined ? false : watchStatuses.length >= 1;
	const isLocationDirty = watchCountryId !== undefined;
	const isCategoriesDirty =
		watchCategories === undefined ? false : watchCategories.length >= 1;
	const isPropertyOwnershipDirty = watchHasOwner !== undefined;
	const isFilterDirty =
		isStatusDirty ||
		isLocationDirty ||
		isCategoriesDirty ||
		isPropertyOwnershipDirty;

	return (
		<Stack gap={'1rem'}>
			<AppSummarySearchBar
				name='searchKey'
				control={control}
				placeholder={t('property.SearchBarPlaceholder')}
			/>
			<Accordion disableGutters>
				<AccordionSummary
					sx={{
						padding: '0 1.25rem 0 1rem',
						'.expanded': {
							minHeight: 'auto',
							margin: '0',
						},
					}}
					expandIcon={<InvertedTriangle />}
				>
					<Box display={'flex'} width={'100%'}>
						<Stack
							sx={{ marginRight: '1rem', flexShrink: 0 }}
							direction={'row'}
							alignItems={'center'}
						>
							<StyledFilterIcon />
							<FilterHeaderText variant='bold'>
								{t('common.filter')}
							</FilterHeaderText>
						</Stack>
						<Box sx={{ width: '100%' }}>
							<FilterChipLayout>
								{transformedSearchParams.statuses &&
									transformedSearchParams.statuses.length > 0 && (
										<AppFilterChip
											label={t('property.Status')}
											value={propertyStatusOptions
												.filter((option) =>
													transformedSearchParams.statuses?.includes(option.id)
												)
												.map((option) => option.name)
												.join(', ')}
										/>
									)}
								{transformedSearchParams.countryId && (
									<AppFilterChip
										label={t('property.CountryOrRegion')}
										value={
											getCountriesResponse?.data?.find(
												(i) => i.id === transformedSearchParams.countryId
											)?.name ?? ''
										}
									/>
								)}
								{transformedSearchParams.majorAreaId && (
									<AppFilterChip
										label={t('property.MajorArea')}
										value={
											majorAreaOptions?.find(
												(i) => i.id === transformedSearchParams.majorAreaId
											)?.name ?? ''
										}
									/>
								)}
								{transformedSearchParams.areaId && (
									<AppFilterChip
										label={t('property.Area')}
										value={
											areaOptions?.find(
												(i) => i.id === transformedSearchParams.areaId
											)?.name ?? ''
										}
									/>
								)}
								{transformedSearchParams.categories &&
									transformedSearchParams.categories.length > 0 && (
										<AppFilterChip
											label={t('property.CategoryType')}
											value={propertyCategoryOptions
												.filter((option) =>
													transformedSearchParams.categories?.includes(
														option.id
													)
												)
												.map((option) => option.name)
												.join(', ')}
										/>
									)}
								{transformedSearchParams.hasOwner !== undefined && (
									<AppFilterChip
										label={t('property.IndividualOrGroup')}
										value={
											hasOwnerOptions?.find(
												(i) => i.id === transformedSearchParams.hasOwner
											)?.name ?? ''
										}
									/>
								)}
								{transformedSearchParams.ownerId && (
									<AppFilterChip
										label={'Group Name'}
										value={
											ownersResponse?.find(
												(i) => i.id === transformedSearchParams.ownerId
											)?.name ?? ''
										}
									/>
								)}
							</FilterChipLayout>
						</Box>
						<ClearAllButton
							sx={{ flexShrink: 0 }}
							disabled={!isFilterDirty}
							onClick={clearAllFilter}
						>
							<Typography variant='regular'>{t('common.clearAll')}</Typography>
						</ClearAllButton>
					</Box>
				</AccordionSummary>
				<AccordionDetails
					sx={{
						padding: '0',
						borderTopWidth: '1px',
						borderTopStyle: 'solid',
						borderTopColor: 'common.whisper',
					}}
				>
					<Stack
						direction={'row'}
						flexBasis='25%'
						sx={{
							'& .MuiInputLabel-root': {
								fontSize: '0.75rem',
							},
						}} // for change drop down label style. To be improved
					>
						<AppFilterSectionLayout
							label={t('property.Status')}
							clearDisabled={!isStatusDirty}
							clearOnClick={clearStatus}
						>
							<AppHorizontalCheckboxGroup
								name='statuses'
								control={control}
								options={propertyStatusOptions}
								undefinedIfEmptyArray
							/>
						</AppFilterSectionLayout>
						<Divider orientation={'vertical'} flexItem={true} />
						<AppFilterSectionLayout
							label={t('property.Location')}
							clearDisabled={!isLocationDirty}
							clearOnClick={clearLocation}
						>
							<Stack direction={'column'} gap={'0.75rem'}>
								<AppDropDownMenu
									control={control}
									name='countryId'
									label={t('property.CountryOrRegion')}
									options={getCountriesResponse?.data ?? []}
								/>
								<AppDropDownMenu
									control={control}
									name='majorAreaId'
									label={t('property.MajorArea')}
									options={majorAreaOptions}
								/>
								<AppDropDownMenu
									control={control}
									name='areaId'
									label={t('property.Area')}
									options={areaOptions}
								/>
							</Stack>
						</AppFilterSectionLayout>

						<Divider orientation={'vertical'} flexItem={true} />
						<AppFilterSectionLayout
							label={t('property.CategoryType')}
							clearDisabled={!isCategoriesDirty}
							clearOnClick={clearCategories}
						>
							<AppHorizontalCheckboxGroup
								name='categories'
								control={control}
								options={propertyCategoryOptions}
								undefinedIfEmptyArray
							/>
						</AppFilterSectionLayout>
						<Divider orientation={'vertical'} flexItem={true} />
						<AppFilterSectionLayout
							label={t('property.PropertyOwnership')}
							clearDisabled={!isPropertyOwnershipDirty}
							clearOnClick={clearOwnership}
						>
							<Stack direction={'column'} gap={'0.75rem'}>
								<AppDropDownMenu
									control={control}
									name='hasOwner'
									label={t('property.IndividualOrGroup')}
									options={hasOwnerOptions}
								/>
								<AppDropDownMenu
									control={control}
									name='ownerId'
									label={t('property.GroupNameIfApplicable')}
									options={ownersResponse}
								/>
							</Stack>
						</AppFilterSectionLayout>
					</Stack>
				</AccordionDetails>
			</Accordion>
			<DevTool control={control} />
		</Stack>
	);
}

const StyledFilterIcon = styled(FilterIcon)`
	width: 20px;
	height: 16px;
	path {
		fill: #2c2c2c;
		stroke: #2c2c2c;
	}
`;

const ClearAllButton = styled(ButtonBase)(({ theme }) => ({
	fontSize: '0.75rem',
	lineHeight: '0.9375rem',
	marginRight: '1.5rem',
	color: theme.palette.primary.main,
	textDecoration: 'underline',
	'&.Mui-disabled': {
		display: 'none',
	},
}));

const FilterHeaderText = styled(Typography)({
	marginLeft: '0.75rem',
});

import { Box, Divider, Grid, Stack } from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';

import { FormCardType } from '../../../../common/components/card/FormCard';
import { currencies } from '../../../../common/constants/currencies';

import dayjs from 'dayjs';
import { useEffect } from 'react';
import { AbkSalutationOptions } from '../../../../api/enum/abkSalutation.enum';
import { abkLocaleOptions } from '../../../../api/enum/locale.enum';
import { useGetPhoneCodesQuery } from '../../../../api/locationApiSlice';
import AppDatePicker from '../../../../common/components/form-inputs/AppDatePicker';
import AppDateTimePicker from '../../../../common/components/form-inputs/AppDateTimePicker';
import AppPhoneCodeDropDownMenuV2 from '../../../../common/components/form-inputs/AppPhoneCodeDropDownMenuV2';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { API_DATE_FORMAT } from '../../../../utils/dateHelper';

interface BaseInfoFormProps {
	type: FormCardType;
}

export default function BaseInfoForm({ type }: BaseInfoFormProps) {
	const { t } = useTranslation();

	const { data: getPhoneCodesResponse } = useGetPhoneCodesQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	const {
		control,
		watch,
		trigger,
		setValue,
		formState: { isDirty },
	} = useFormContext();

	const watchStayPeriodStart = watch('baseInfo.stayPeriod.start');
	const watchStayPeriodEnd = watch('baseInfo.stayPeriod.end');

	const watchEmail = watch('baseInfo.contactPerson.email');
	const watchPhoneCode = watch('baseInfo.contactPerson.phoneCode');
	const watchPhoneNum = watch('baseInfo.contactPerson.phoneNum');

	useEffect(() => {
		if (isDirty) {
			trigger('baseInfo.contactPerson.email');
			trigger('baseInfo.contactPerson.phoneCode');
			trigger('baseInfo.contactPerson.phoneNum');
		}
	}, [watchEmail, watchPhoneCode, watchPhoneNum]);

	const formContent = (
		<Stack>
			<AppSubtitleLayout label={t('abk.BookingDetails')} />

			<AppInputLayout
				label={t('abk.BookingCreatedDate')}
				removeErrorPadding
				required
			>
				<AppDateTimePicker
					control={control}
					name={'baseInfo.creationDate'}
					minimumDate={dayjs().startOf('day').format()}
				/>
			</AppInputLayout>

			<AppInputLayout label={t('abk.StayPeriod')} removeErrorPadding required>
				<Box display='grid' gap='10px' gridTemplateColumns='repeat(2, 1fr)'>
					<AppDatePicker
						control={control}
						name={'baseInfo.stayPeriod.start'}
						minimumDate={dayjs().startOf('day').format()}
						maximumDate={
							watchStayPeriodEnd
								? dayjs(watchStayPeriodEnd, API_DATE_FORMAT)
										.subtract(1, 'day')
										.format()
								: undefined
						}
					/>
					<AppDatePicker
						control={control}
						name={'baseInfo.stayPeriod.end'}
						minimumDate={
							watchStayPeriodStart
								? dayjs(watchStayPeriodStart, API_DATE_FORMAT)
										.add(1, 'day')
										.format()
								: dayjs().startOf('day').format()
						}
					/>
				</Box>
			</AppInputLayout>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppSubtitleLayout label={t('abk.ContactPerson')} />

			<AppDropDownMenu
				control={control}
				name={'baseInfo.contactPerson.locale'}
				label={t('abk.CustomerLanguage')}
				options={abkLocaleOptions}
				required
			/>
			<AppDropDownMenu
				control={control}
				name='baseInfo.contactPerson.currency'
				label={t('abk.CustomerCurrency')}
				options={currencies}
			/>

			<AppInputLayout removeErrorPadding label={t('abk.CustomerName')} required>
				<Grid container columnSpacing={1}>
					<Grid item xs={3}>
						<AppDropDownMenu
							control={control}
							name='baseInfo.contactPerson.salutation'
							options={AbkSalutationOptions}
							placeholder={'-'}
						/>
					</Grid>
					<Grid item xs={4.5}>
						<AppTextField
							control={control}
							name={`baseInfo.contactPerson.firstName`}
							placeholder={t('abk.FirstName')}
							inputType={InputType.english}
							label={''}
						/>
					</Grid>
					<Grid item xs={4.5}>
						<AppTextField
							control={control}
							name={`baseInfo.contactPerson.lastName`}
							placeholder={t('abk.LastName')}
							inputType={InputType.lastName}
							label={''}
						/>
					</Grid>
				</Grid>
			</AppInputLayout>

			<AppTextField
				control={control}
				name={'baseInfo.contactPerson.companyName'}
				label={t('abk.CompanyName')}
			/>

			<AppTextField
				control={control}
				name={'baseInfo.contactPerson.email'}
				label={t('abk.Email')}
				placeholder={t('abk.EmailPlaceholder')}
			/>

			<AppInputLayout removeErrorPadding label={t('supplier.PhoneNumber')}>
				<Grid container columnSpacing={1}>
					<Grid item xs={4}>
						<AppPhoneCodeDropDownMenuV2
							control={control}
							name={`baseInfo.contactPerson.phoneCode`}
							options={getPhoneCodesResponse?.data ?? []}
							label={''}
							passObjectValue={true}
							placeholder={'-'}
							fullWidth
						/>
					</Grid>
					<Grid item xs={8}>
						<AppTextField
							control={control}
							name={`baseInfo.contactPerson.phoneNum`}
							inputType={InputType.number}
							label={''}
						/>
					</Grid>
				</Grid>
			</AppInputLayout>

			<AppTextField
				control={control}
				name={'baseInfo.contactPerson.passport'}
				label={t('abk.PassportIdIfAny')}
			/>
		</Stack>
	);

	const form = formContent;

	return <>{form}</>;
}

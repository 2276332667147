import { Box, styled, Divider } from '@mui/material';
import AppSpaceSubtitleLayout from '../../../common/components/form-layout/AppSpaceSubtitleLayout';
import { useTranslation } from 'react-i18next';
import SpaceDisplayText from '../../../common/components/form-data-display/SpaceDisplayText';

interface ProfitTotalBreakdownCardProps {
	data?: any;
	currency: string;
	label: string;
}

export default function ProfitTotalBreakdownCard({
	data,
	currency,
	label,
}: ProfitTotalBreakdownCardProps) {
	const { t } = useTranslation();
	return (
		<>
			{data !== undefined && (
				<StyledBreakdownCard>
					<AppSpaceSubtitleLayout
						label={label}
						content={`${currency} ${data.total}`}
					/>
					{data.promo && (
						<>
							<Box
								sx={{
									display: 'flex',
									gap: '5px',
									flexDirection: 'column',
									marginBottom: '15px',
								}}
							>
								<SpaceDisplayText
									text1={`${t('bk.DiscountedAmount')} (${data.promo.code})`}
									text2={`(${data.promo.rate})`}
								/>
							</Box>
						</>
					)}
					<Divider sx={{ marginBottom: '15px' }} />

					<AppSpaceSubtitleLayout
						label={`${t('bk.NetProfit')}`}
						content={`${currency} ${data.netProfit?.toString()}`}
						color={'#4EA4D3'}
					/>
					<AppSpaceSubtitleLayout
						label={`${t('bk.Profit')} %`}
						content={
							data.profitPercentage?.toString()
								? `${data.profitPercentage?.toString()}%`
								: '-'
						}
						color={'#4EA4D3'}
					/>
				</StyledBreakdownCard>
			)}
		</>
	);
}

const StyledBreakdownCard = styled(Box)({
	backgroundColor: '#F2FAFF',
	padding: '20px ',
	paddingBottom: '0',
	borderRadius: '10px',
	marginBottom: '20px',
});

import { Typography } from '@mui/material';

interface NewDisplayTextProps {
	text?: string;
}

export default function NewDisplayText({ text }: NewDisplayTextProps) {
	return (
		<Typography fontSize='12px' color='#2C2C2C' whiteSpace={'break-spaces'}>
			{text?.length ? text : '-'}
		</Typography>
	);
}

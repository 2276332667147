import { Button, Typography } from '@mui/material';
import AppModal from './AppModal';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal } from './modalSlice';

interface ApiErrorModalProps {
	title: string;
	content: string;
}

const ApiErrorModal = (props: ApiErrorModalProps) => {
	const dispatch = useAppDispatch();

	const handleClose = () => {
		dispatch(hideModal());
	};
	return (
		<AppModal
			title={
				<>
					<Typography variant='bold' color='error'>
						{props.title}
					</Typography>
				</>
			}
			content={<>{props.content}</>}
			showCancelButton={false}
			actionButtonEl={
				<>
					<Button variant='outlined' onClick={handleClose} color='error'>
						Got it
					</Button>
				</>
			}
		/>
	);
};

export default ApiErrorModal;

import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useState } from 'react';
import LocationView from './LocationView';
import LocationForm from './LocationForm';

interface LocationCardProps {
	initialType: FormCardType;
}

export default function LocationCard({ initialType }: LocationCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<FormCard type={type} title={t('property.Location')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <LocationView setType={setType} />;
					case FormCardType.create:
						return <LocationForm type={type} setType={setType} />;
					case FormCardType.edit:
						return <LocationForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack } from '@mui/material';
import { createContext } from 'react';
import * as Yup from 'yup';
import { Bk } from '../../../api/DTO/bk.interface';
import { useGetBkQuery } from '../../../api/bkApiSlice';
import FullScreenLoader from '../../../common/components/FullScreenLoader';
import { FormCardType } from '../../../common/components/card/FormCard';
import BookInfoCard from './bookInfo/BookInfoCard';
import PolicyAndInclusionCard from './policyAndInclusion/PolicyAndInclusionCard';
import RateInfoCard from './rateInfo/RateInfoCard';
import SupplierInfoCard from './supplierInfo/SupplierInfoCard';

export const BkIdContext = createContext('');

export default function ViewBkPage() {
	const { t } = useTranslation();
	const { bkId } = useParams();

	const {
		data: getBkResponse,
		isLoading: isGetBkLoading,
		isSuccess: isGetBkSuccess,
		isError: isGetBkError,
	} = useGetBkQuery(
		{
			bk_id: bkId ?? '',
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const formSchema = Yup.object().shape({});

	const methods = useForm<Bk>({
		defaultValues: getBkResponse?.data,
		resolver: yupResolver(formSchema),
		mode: 'onChange',
	});

	let pageContent;
	if (isGetBkLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetBkSuccess) {
		pageContent = (
			<>
				<BkIdContext.Provider value={bkId ?? ''}>
					<FormProvider {...methods}>
						<form
						// onSubmit={methods.handleSubmit(() => {
						// })}
						>
							<Stack spacing='16px'>
								<Grid container alignItems='stretch' spacing={'10px'}>
									<Grid item xs={6} display='flex'>
										<BookInfoCard initialType={FormCardType.view} />
									</Grid>
									<Grid item xs={6} display='flex'>
										<SupplierInfoCard initialType={FormCardType.view} />
									</Grid>
									<Grid item xs={6} display='flex'>
										<PolicyAndInclusionCard initialType={FormCardType.view} />
									</Grid>
									<Grid item xs={6} display='flex'>
										<RateInfoCard initialType={FormCardType.view} />
									</Grid>
								</Grid>
							</Stack>
						</form>
					</FormProvider>
				</BkIdContext.Provider>
			</>
		);
	} else if (isGetBkError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}

import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { useTranslation } from 'react-i18next';
import { Link, Outlet, matchPath, useLocation } from 'react-router-dom';
import { useGetShortNoticeQuery } from '../../api/pageApiSlice';
import FullScreenLoader from '../../common/components/FullScreenLoader';
import PageHeader from '../../common/components/PageHeader';
import { FormCardType } from '../../common/components/card/FormCard';
import ShortNoticeCard from './short-notice/ShortNoticeCard';

function useRouteMatch(patterns: readonly string[]) {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}

	return null;
}

function PageTabs({ currentTab }: { currentTab?: string }) {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				backgroundColor: '#FFFFFF',
				borderRadius: '5px',
				boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
				padding: '0px 20px',
				marginBottom: '15px',
			}}
		>
			{currentTab && (
				<Tabs value={currentTab}>
					<Tab
						label={t('tabs.Home')}
						value='/page/home'
						to={`/page/home`}
						component={Link}
					/>
				</Tabs>
			)}
		</Box>
	);
}

export default function PageContainer() {
	const { t } = useTranslation();

	// You need to provide the routes in descendant order.
	// This means that if you have nested routes like:
	// users, users/new, users/edit.
	// Then the order should be ['users/add', 'users/edit', 'users'].
	const routeMatch = useRouteMatch(['/page/home']);

	const currentTab = routeMatch?.pattern?.path;

	const {
		isLoading: isGetShortNoticeLoading,
		isSuccess: isGetShortNoticeSuccess,
		isError: isGetShortNoticeError,
	} = useGetShortNoticeQuery();

	let pageContent;
	if (isGetShortNoticeLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetShortNoticeSuccess) {
		pageContent = (
			<Box sx={{ width: '100%' }}>
				<PageHeader title={t('page.HomeAndListingPage')} />
				<ShortNoticeCard initialType={FormCardType.view} />

				<PageTabs currentTab={currentTab} />
				<Outlet />
			</Box>
		);
	} else if (isGetShortNoticeError) {
		pageContent = <div>API Error</div>;
	}
	return <div>{pageContent}</div>;
}

import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { ReactComponent as CircleInfoIcon } from '../../../assets/icon/circleInfo.svg';
import { ReactNode } from 'react';

interface DisplayTextProps {
	label: string;
	index: number;
	question: string;
	answer: string;
	infoPopUp?: ReactNode;
}

export default function FaqDisplayText(props: DisplayTextProps) {
	return (
		<Grid container columnSpacing='20px' marginTop='20px' padding='0px'>
			<Grid item xs={2.5}>
				<Typography
					fontSize={12}
					color='text.grey'
					display='flex'
					alignItems='center'
				>
					{props.label} ({props.index + 1})
					{!!props.infoPopUp && (
						<Box component={'span'} sx={{ marginLeft: '5px', display: 'flex' }}>
							<Tooltip title={props.infoPopUp} placement='bottom-start'>
								<CircleInfoIcon />
							</Tooltip>
						</Box>
					)}
				</Typography>
			</Grid>
			<Grid item xs={9.5}>
				<Typography fontSize={12} fontWeight={700} paddingBottom={'5px'}>
					{props.question}
				</Typography>
				<Typography fontSize={12}>{props.answer}</Typography>
			</Grid>
		</Grid>
	);
}

import { Box, Divider, Stack } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBkQuery } from '../../../../api/bkApiSlice';
import { departmentOption } from '../../../../api/enum/department.enum';
import { RateModel } from '../../../../api/enum/rateModel.enum';
import { getRatePlanChannel } from '../../../../api/enum/ratePlanChannel.enum';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import TextWithUnit from '../../../../common/components/form-data-display/TextWithUnit';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { getRateModel } from '../../../../utils/accomodationHelper';
import { BkIdContext } from '../ViewBkPage';

export default function SupplierInfoView() {
	const { t } = useTranslation();
	const bkId = useContext(BkIdContext);

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getBkResponse } = useGetBkQuery({
		bk_id: bkId ?? '',
	});

	return (
		<>
			<Stack position={'relative'}>
				<AppInputLayout label={t('bk.SupplierId')}>
					<NewDisplayText text={getBkResponse?.data.supplier.id} />
				</AppInputLayout>
				<AppInputLayout label={t('bk.RatePlanChannel')}>
					<NewDisplayText
						text={getRatePlanChannel(getBkResponse?.data.supplier.channel)}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('bk.DirectContractSupplier')}>
					<NewDisplayText text={getBkResponse?.data.supplier.name} />
				</AppInputLayout>
				<AppInputLayout label={t('bk.PriceMode')}>
					<NewDisplayText
						text={
							getBkResponse?.data.rate.supplierBreakdown.dailyBreakdowns[0]
								.perRoom !== null
								? 'Per Room'
								: 'Per Person'
						}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('bk.RateModel')}>
					<NewDisplayText
						text={getRateModel(getBkResponse?.data.supplier.rateModel)}
					/>
				</AppInputLayout>
				{getBkResponse?.data.supplier.rateModel === RateModel.AGENCY &&
					getBkResponse?.data.supplier.agencyConfig && (
						<Box
							sx={{
								paddingLeft: '20px',
								borderLeft: '5px solid #4EA4D3',
								marginBottom: '15px',
							}}
						>
							<AppInputLayout label={t('bk.SellAtOfficialRate')}>
								<YesNoDisplay
									value={
										getBkResponse?.data.supplier.agencyConfig
											?.sellAtOfficialRate
									}
								/>
							</AppInputLayout>
							<AppInputLayout label={t('property.CommissionLevel')}>
								<TextWithUnit
									text={`${
										getBkResponse?.data.supplier.agencyConfig
											?.commissionLevel ?? '-'
									}`}
									unit='%'
								/>
							</AppInputLayout>
						</Box>
					)}
				{getBkResponse?.data.supplier.rateModel === RateModel.MERCHANT &&
					getBkResponse?.data.supplier.merchantConfig && (
						<Box
							sx={{
								paddingLeft: '20px',
								borderLeft: '5px solid #4EA4D3',
								marginBottom: '15px',
							}}
						>
							<AppInputLayout label={t('property.ServiceChargeIncluded')}>
								<YesNoDisplay
									value={
										getBkResponse?.data.supplier.merchantConfig
											?.serviceChargeIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('property.TaxIncluded')}>
								<YesNoDisplay
									value={
										getBkResponse?.data.supplier.merchantConfig?.taxIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('property.CommissionLevel')}>
								<TextWithUnit
									text={`${
										getBkResponse?.data.supplier.merchantConfig
											?.commissionLevel ?? '-'
									}`}
									unit='%'
								/>
							</AppInputLayout>

							<AppInputLayout
								label={t('property.CommissionIncludedServiceCharge')}
							>
								<YesNoDisplay
									value={
										getBkResponse?.data.supplier.merchantConfig
											?.commissionServiceChargeIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('property.CommissionIncludedTax')}>
								<YesNoDisplay
									value={
										getBkResponse?.data.supplier.merchantConfig
											?.commissionTaxIncluded
									}
								/>
							</AppInputLayout>
						</Box>
					)}

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('bk.SupplierContactPoint')} />
				{getBkResponse?.data.supplier.contactPoints?.map(
					(contactPoint, index) => (
						<Box key={index}>
							<AppInputLayout label={t('bk.Department')}>
								<NewDisplayText
									text={
										departmentOption.find(
											(i) => i.id === contactPoint.department
										)?.name
									}
								/>
							</AppInputLayout>
							<AppInputLayout label={t('bk.ContactName')}>
								<NewDisplayText text={contactPoint.name} />
							</AppInputLayout>
							<AppInputLayout label={t('common.Email')}>
								<NewDisplayText text={contactPoint.email} />
							</AppInputLayout>
							<AppInputLayout label={t('common.PhoneNumber')}>
								<NewDisplayText
									text={
										contactPoint.phoneCode && contactPoint.phoneNum
											? `+${contactPoint.phoneCode} ${contactPoint.phoneNum}`
											: '-'
									}
								/>
							</AppInputLayout>
							<Divider sx={{ marginBottom: '15px' }} />
						</Box>
					)
				)}

				<AppSubtitleLayout label={t('bk.SupplierPaymentInformation')} />

				<AppInputLayout label={t('bk.SupplierCurrency')}>
					<NewDisplayText text={getBkResponse?.data.supplier.currency} />
				</AppInputLayout>
			</Stack>
		</>
	);
}

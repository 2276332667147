import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RateModel, rateModelList } from '../../../api/enum/rateModel.enum';
import RateModelToggleButton from '../../../common/components/RateModelToggleButton';
import BulkEditDrawer from './BulkEditDrawer';
import { SubmitHandler, useForm } from 'react-hook-form';
import { GetPropertyCalendarParams } from '../../../api/DTO/propertyCalendar.interface';
import AppDatePicker from '../../../common/components/form-inputs/AppDatePicker';
import {
	useGetPropertyCalendarRateModelsQuery,
	useGetPropertyQuery,
	useLazyGetPropertyCalendarQuery,
} from '../../../api/accommodationApiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultCurrencyDisplay from './components/DefaultCurrencyDisplay';
import HorizontalCalendar, {
	DatesContainer,
} from './components/HorizontalCalendar';
import EditRoomMinimumSellingRateDialog from './components/edit-dialog/EditRoomMinimumSellingRateDialog';
import EditRoomSupplementRateDialog from './components/edit-dialog/EditRoomSupplementRateDialog';
import { ReactComponent as BulkEditIcon } from '../../../assets/icon/bulk-edit.svg';
import { API_DATE_FORMAT, INVALID_DATE } from '../../../utils/dateHelper';
import dayjs from 'dayjs';
import RatePlanAccordion from './components/RatePlanAccordion';
import RoomAccordion from './components/RoomAccordion';
import { ReactComponent as AddIcon } from '../../../assets/icon/button-add.svg';
import AppAddButton from '../../../common/components/form-inputs/AppAddButton';
import FullScreenLoader from '../../../common/components/FullScreenLoader';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { NONE_INVALID_DATE_PATTERN } from '../../../common/constants/pattern';
import { Grid } from 'react-virtualized';

export default function ViewRateAndInventoryPage() {
	const { propertyId } = useParams();

	const {
		data: getPropertyCalendarRateModels,
		isLoading: isGetPropertyCalendarRateModelsLoading,
		isSuccess: isGetPropertyCalendarRateModelsSuccess,
		isError: isGetPropertyCalendarRateModelsError,
	} = useGetPropertyCalendarRateModelsQuery(propertyId ?? '');
	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');

	let pageContent;
	if (isGetPropertyCalendarRateModelsLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetPropertyCalendarRateModelsSuccess) {
		pageContent = (
			<PageContent
				propertyCalendarRateModels={getPropertyCalendarRateModels?.data ?? []}
				propertyDirectSupplierCurrency={
					getPropertyResponse?.data?.supplier.currency ?? ''
				}
			/>
		);
	} else if (isGetPropertyCalendarRateModelsError) {
		pageContent = <div>API Error</div>;
	}
	return <div>{pageContent}</div>;
}

function PageContent({
	propertyCalendarRateModels,
	propertyDirectSupplierCurrency,
}: {
	propertyCalendarRateModels: RateModel[];
	propertyDirectSupplierCurrency: string;
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { propertyId } = useParams();

	const [rateModel, setRateModel] = useState<RateModel>(
		propertyCalendarRateModels.includes(RateModel.AGENCY)
			? RateModel.AGENCY
			: propertyCalendarRateModels[0]
	);

	const [bulkEditOpen, setBulkEditOpen] = useState<boolean>(false);
	const toggleDrawer = (open: boolean) => () => {
		setBulkEditOpen(open);
	};

	const formSchema = Yup.object().shape({
		from: Yup.string()
			.required(t('errorMessage.required'))
			.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
		to: Yup.string()
			.required(t('errorMessage.required'))
			.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
			.when('from', (from: any, schema) => {
				return schema.test(
					'shouldSameOrGreaterThanFrom',
					() => t('errorMessage.incorrectPeriodTime'),
					(value) => {
						return !!from && dayjs(value).isSameOrAfter(dayjs(from), 'day');
					}
				);
			}),
	});

	// form

	const { control, watch, handleSubmit } = useForm<GetPropertyCalendarParams>({
		defaultValues: {
			from: dayjs().format(API_DATE_FORMAT),
			to: dayjs().add(3, 'month').format(API_DATE_FORMAT),
		},
		resolver: yupResolver(formSchema),
	});

	const watchFrom = watch('from');
	const watchTo = watch('to');

	/**
	 *  Code if listen Date on change to update calendar
	 *  might cause long loading time / api error when typing date
	 */
	// const { data: propertyCalendar, isSuccess: isGetPropertyCalendarSuccess } =
	// 	useGetPropertyCalendarQuery(
	// 		{
	// 			propertyId: propertyId ?? '',
	// 			rateModel: rateModel,
	// 			from: watchFrom ?? '',
	// 			to: watchTo ?? '',
	// 		},
	// 		{
	// 			skip:
	// 				watchFrom === undefined ||
	// 				watchTo === undefined ||
	// 				watchFrom === undefined ||
	// 				watchTo === undefined,
	// 		}
	// 	);

	const [getPropertyCalendar, { data: getPropertyCalendarResponse }] =
		useLazyGetPropertyCalendarQuery();

	const currentDisplayCurrency =
		getPropertyCalendarResponse?.data.displayCurrency ??
		propertyDirectSupplierCurrency;

	useEffect(() => {
		handleSubmit(onSubmit)();
	}, [rateModel]);

	const onSubmit: SubmitHandler<GetPropertyCalendarParams> = (data) => {
		if (
			watchFrom === undefined ||
			watchTo === undefined ||
			watchFrom === INVALID_DATE ||
			watchTo === INVALID_DATE
		)
			return;
		else
			setSelectedDateRange({
				from: watchFrom,
				to: watchTo,
			});

		getPropertyCalendar({
			propertyId: propertyId ?? '',
			rateModel: rateModel,
			from: watchFrom,
			to: watchTo,
			displayCurrency: currentDisplayCurrency,
		});
	};

	const [selectedDateRange, setSelectedDateRange] = useState<
		{ from: string; to: string } | undefined
	>(undefined);

	const dateCalendarRef = useRef<HTMLDivElement>(null);
	const roomGrid: (Grid | null)[] = [];
	const ratePlanGrid: (Grid | null)[][] = [];

	// reset all scroll left if changed Rate Model
	useEffect(() => {
		if (dateCalendarRef.current) dateCalendarRef.current.scrollLeft = 0;

		roomGrid.forEach((grid) => {
			grid?.scrollToPosition({
				scrollLeft: 0,
				scrollTop: grid.state.scrollTop,
			});
		});

		ratePlanGrid.forEach((array) => {
			array.forEach((grid) => {
				grid?.scrollToPosition({
					scrollLeft: 0,
					scrollTop: grid.state.scrollTop,
				});
			});
		});
	}, [rateModel]);

	// edit room minimum selling rate dialog

	const [roomMinimumSellingRateDialog, setRoomMinimumSellingRateDialog] =
		useState<{
			open: boolean;
			roomId?: string;
		}>({
			open: false,
			roomId: undefined,
		});

	// edit room supplement rate dialog

	const [roomSupplementRateDialog, setRoomSupplementRateDialog] = useState<{
		open: boolean;
		roomId?: string;
		roomName?: string;
	}>({
		open: false,
		roomId: undefined,
		roomName: undefined,
	});

	return (
		<>
			<Box display={'flex'} justifyContent={'space-between'}>
				<Stack direction={'row'} gap={'8px'} alignItems={'center'}>
					<Typography fontSize={'14px'} fontWeight={700} marginRight={'6px'}>
						{t('rateAndInventory.ViewDate')}
					</Typography>
					<AppDatePicker
						name={'from'}
						control={control}
						removeErrorPadding
						large
					/>
					<AppDatePicker
						name={'to'}
						control={control}
						minimumDate={watchFrom}
						removeErrorPadding
						large
					/>
					<Button
						type='submit'
						variant='contained'
						color='success'
						onClick={handleSubmit(onSubmit)}
					>
						Apply
					</Button>
				</Stack>

				<Stack direction={'row'} gap={'10px'} height={'38px'}>
					<RateModelToggleButton
						value={rateModel}
						handleChange={(_, newRateModel) => {
							if (newRateModel != null) setRateModel(newRateModel);
						}}
						disableRateModel={rateModelList.filter(
							(rateModel) => !propertyCalendarRateModels.includes(rateModel)
						)}
					/>
					<Button
						variant='contained'
						startIcon={<BulkEditIcon />}
						onClick={toggleDrawer(true)}
					>
						{t('rateAndInventory.BulkEdit')}
					</Button>

					<BulkEditDrawer
						rateModel={rateModel}
						priceMode={getPropertyCalendarResponse?.data.priceMode}
						sellAtOfficialRate={
							getPropertyCalendarResponse?.data.sellAtOfficialRate
						}
						open={bulkEditOpen}
						onClose={toggleDrawer(false)}
					/>
				</Stack>
			</Box>

			<Box
				display={'flex'}
				marginTop={'12px'}
				position={'sticky'}
				top={'0'}
				zIndex={1000}
			>
				<DefaultCurrencyDisplay
					propertySupplierCurrency={getPropertyCalendarResponse?.data.currency}
					currency={currentDisplayCurrency}
					exchangeRate={getPropertyCalendarResponse?.data.exchangeRate}
					taxPercentage={getPropertyCalendarResponse?.data.taxPercentage}
					serviceChargePercentage={
						getPropertyCalendarResponse?.data.serviceChargePercentage
					}
					onChangeCallback={(newValue) => {
						getPropertyCalendar({
							propertyId: propertyId ?? '',
							rateModel: rateModel,
							from: watchFrom,
							to: watchTo,
							displayCurrency: newValue,
						});
					}}
				/>
				<DatesContainer
					ref={dateCalendarRef}
					sx={{ marginLeft: '12px' }}
					onScroll={() => {
						roomGrid.forEach((grid) => {
							grid?.scrollToPosition({
								scrollLeft: dateCalendarRef.current?.scrollLeft || 0,
								scrollTop: grid.state.scrollTop,
							});
						});

						ratePlanGrid.forEach((array) => {
							array.forEach((grid) => {
								grid?.scrollToPosition({
									scrollLeft: dateCalendarRef.current?.scrollLeft || 0,
									scrollTop: grid.state.scrollTop,
								});
							});
						});
					}}
				>
					<HorizontalCalendar
						from={selectedDateRange?.from}
						to={selectedDateRange?.to}
					/>
				</DatesContainer>
			</Box>

			<Stack marginTop={'16px'} gap={'32px'}>
				{getPropertyCalendarResponse?.data.roomCalendars.map(
					(room, roomIndex) => {
						return (
							<Stack key={room.id} gap={'16px'}>
								<RoomAccordion
									room={room}
									roomIndex={roomIndex}
									basicRoomId={getPropertyCalendarResponse?.data.basicRoomId}
									propertyId={propertyId ?? ''}
									priceMode={getPropertyCalendarResponse?.data.priceMode}
									minPriceOnClick={() => {
										setRoomMinimumSellingRateDialog({
											open: true,
											roomId: room.id,
										});
									}}
									roomSupplementRateOnClick={() => {
										setRoomSupplementRateDialog({
											open: true,
											roomId: room.id,
											roomName: room.name,
										});
									}}
									dateCalendarRef={dateCalendarRef}
									roomGrid={roomGrid}
									ratePlanGrid={ratePlanGrid}
									rateModel={rateModel}
								/>

								{room.ratePlanCalendars.map((ratePlan, ratePlanIndex) => (
									<RatePlanAccordion
										key={ratePlan.id}
										roomIndex={roomIndex}
										room={room}
										ratePlanIndex={ratePlanIndex}
										ratePlan={ratePlan}
										rateModel={rateModel}
										priceMode={getPropertyCalendarResponse?.data.priceMode}
										sellAtOfficialRate={
											getPropertyCalendarResponse?.data.sellAtOfficialRate
										}
										maxAdultCapacity={room.maxAdultCapacity}
										dateCalendarRef={dateCalendarRef}
										roomGrid={roomGrid}
										ratePlanGrid={ratePlanGrid}
										propertyId={propertyId ?? ''}
										roomId={room.id}
									/>
								))}

								<Box width={'100%'} display={'flex'} justifyContent={'center'}>
									<AppAddButton
										onClick={() =>
											navigate(
												`/property/${propertyId}/room/${room.id}/rate-plan/create`
											)
										}
									>
										<AddIcon />
										{t('button.Add')}
									</AppAddButton>
								</Box>
							</Stack>
						);
					}
				)}
			</Stack>
			<EditRoomMinimumSellingRateDialog
				roomId={roomMinimumSellingRateDialog.roomId ?? ''}
				open={roomMinimumSellingRateDialog.open}
				onClose={() =>
					setRoomMinimumSellingRateDialog({
						open: false,
						roomId: undefined,
					})
				}
				currency={propertyDirectSupplierCurrency ?? ''}
			/>
			<EditRoomSupplementRateDialog
				roomId={roomSupplementRateDialog.roomId ?? ''}
				roomName={roomSupplementRateDialog.roomName ?? ''}
				rateModel={rateModel}
				open={roomSupplementRateDialog.open}
				onClose={() =>
					setRoomSupplementRateDialog({
						open: false,
						roomId: undefined,
					})
				}
				currency={propertyDirectSupplierCurrency ?? ''}
			/>
		</>
	);
}

import { Box, Button, InputAdornment, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
	useBulkUpdateRatePlanMarkupsMutation,
	useGetPropertyQuery,
} from '../../../../../api/accommodationApiSlice';
import EditPopUpContainer from './EditPopUpContainer';
import { arrayOfWholeWeek } from '../../../../../api/enum/daysOfWeek';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';
import { MarkupType } from '../../../../../api/enum/markupType.enum';

interface EditRatePlanMarkupPercentagePopUpProps {
	ratePlanId: string;
	from: string;
	to: string;
	onCloseCallBack: () => void;
	markupType: MarkupType;
}

export default function EditRatePlanMarkupPercentagePopUp({
	ratePlanId,
	from,
	to,
	onCloseCallBack,
	markupType,
}: EditRatePlanMarkupPercentagePopUpProps) {
	const { t } = useTranslation();
	const { propertyId } = useParams();
	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');

	const defaultValues: DefaultValues<FieldValues> = {
		ratePlanIds: [],
		markup: {
			amount: '',
			type: markupType,
		},
	};

	const formSchema = Yup.object().shape({
		markup: Yup.object().shape({
			amount: Yup.string().required(t('errorMessage.required')),
		}),
	});

	const { control, handleSubmit } = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [bulkUpdateRatePlanMarkupsMutation] =
		useBulkUpdateRatePlanMarkupsMutation();

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		bulkUpdateRatePlanMarkupsMutation({
			propertyId: propertyId ?? '',
			body: {
				from: from,
				to: to,
				days: arrayOfWholeWeek,
				ratePlanIds: [ratePlanId],
				markup: data.markup,
			},
		})
			.unwrap()
			.then((response) => {
				if (response.success) {
					onCloseCallBack();
				}
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<EditPopUpContainer
				title={
					markupType === MarkupType.PERCENTAGE
						? 'Markup %'
						: markupType === MarkupType.FIXED
						? 'Markup Amount'
						: ''
				}
				from={from}
				to={to}
				onCloseCallBack={onCloseCallBack}
			>
				<Box display='flex' justifyContent={'space-between'}>
					<Label>Revised Markup</Label>
					<Box width='148px'>
						{markupType === MarkupType.PERCENTAGE && (
							<AppTextField
								inputType={InputType.number}
								name={'markup.amount'}
								label={''}
								control={control}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>%</InputAdornment>
									),
								}}
								autoFocus
							/>
						)}
						{markupType === MarkupType.FIXED && (
							<AppTextField
								inputType={InputType.amount}
								name={'markup.amount'}
								label={''}
								control={control}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											{getPropertyResponse?.data?.supplier.currency}
										</InputAdornment>
									),
								}}
								autoFocus
							/>
						)}
					</Box>
				</Box>
				<Button
					variant={'contained'}
					onClick={handleSubmit(onSubmit)}
					fullWidth={true}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{t('common.Confirm')}
					</Typography>
				</Button>
			</EditPopUpContainer>{' '}
		</form>
	);
}

const Label = styled(Typography)({
	fontSize: '12px',
});

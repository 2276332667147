import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import FlagLabelDisplay from '../../../../common/components/form-data-display/FlagLabelDisplay';
import { currencies } from '../../../../common/constants/currencies';

import { useContext } from 'react';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';

import { AbkIdContext } from '../ViewAbkPage';
import { useGetAbkQuery } from '../../../../api/abkApiSlice';
import { departmentOption } from '../../../../api/enum/department.enum';
import EmailDisplayText from '../../../../common/components/form-data-display/EmailDisplaytext';
import { abkLocaleOptions } from '../../../../api/enum/locale.enum';
import { AbkVersionContext } from '../../AbkContainer';

export default function SupplierInfoView() {
	const { t } = useTranslation();
	const abkId = useContext(AbkIdContext);
	const [AbkVersion, setAbkVersion] = useContext(AbkVersionContext);

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getAbkResponse } = useGetAbkQuery({
		abk_id: abkId ?? '',
		version: AbkVersion,
	});

	return (
		<>
			<Stack position={'relative'}>
				<AppInputLayout label={t('abk.SupplierCompanyName')}>
					<NewDisplayText text={getAbkResponse?.data.supplier.name} />
				</AppInputLayout>

				<AppInputLayout label={t('abk.SupplierLanguage')}>
					<NewDisplayText
						text={
							abkLocaleOptions.find(
								(i) => i.id === getAbkResponse?.data.supplier.locale
							)?.name
						}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('abk.SupplierCurrency')}>
					<FlagLabelDisplay
						iso2={
							currencies.find(
								(i) => i.id === getAbkResponse?.data.supplier.currency
							)?.iso2
						}
						label={
							currencies.find(
								(i) => i.id === getAbkResponse?.data.supplier.currency
							)?.name
						}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('abk.SupplierContactPoint')} />
				{getAbkResponse?.data.supplier.contactPoints.map((item, index) => {
					return (
						<Box key={item.id}>
							<AppInputLayout label={t('abk.Department')}>
								<NewDisplayText
									text={
										departmentOption.find(
											(i) =>
												i.id ===
												getAbkResponse?.data.supplier.contactPoints[index]
													.department
										)?.name
									}
								/>
							</AppInputLayout>
							<AppInputLayout label={t('abk.ContactName')}>
								<NewDisplayText
									text={getAbkResponse?.data.supplier.contactPoints[index].name}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('abk.PhoneNumber')}>
								<NewDisplayText
									text={
										getAbkResponse?.data.supplier.contactPoints[index]
											?.phoneCode?.phoneCode &&
										getAbkResponse?.data.supplier.contactPoints[index].phoneNum
											? `+${
													getAbkResponse?.data.supplier.contactPoints[index]
														?.phoneCode?.phoneCode ?? ''
											  } ${
													getAbkResponse?.data.supplier.contactPoints[index]
														.phoneNum ?? ''
											  }`
											: '-'
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('abk.SupplierEmail')}>
								<EmailDisplayText
									email={`${
										getAbkResponse?.data.supplier.contactPoints[index].email ??
										'-'
									}`}
								/>
							</AppInputLayout>
						</Box>
					);
				})}
			</Stack>
		</>
	);
}

import { Box, styled, Divider } from '@mui/material';
import AppSpaceSubtitleLayout from '../../../common/components/form-layout/AppSpaceSubtitleLayout';
import { useTranslation } from 'react-i18next';
import SpaceDisplayText from '../../../common/components/form-data-display/SpaceDisplayText';

interface PromoBreakdownCardProps {
	data?: any;
	currency: string;
	label: string;
}

export default function PromoBreakdownCard({
	data,
	currency,
	label,
}: PromoBreakdownCardProps) {
	const { t } = useTranslation();

	return (
		<>
			{data !== undefined && (
				<StyledBreakdownCard>
					<AppSpaceSubtitleLayout label={label} content={currency} />
					{data.perRoom && (
						<Box
							sx={{
								display: 'flex',
								gap: '5px',
								flexDirection: 'column',
								marginBottom: '15px',
							}}
						>
							<SpaceDisplayText
								text1={t('bk.BaseRate')}
								text2={data.perRoom.basicRate?.toString()}
							/>
							<SpaceDisplayText
								text1={t('bk.RoomSupplement')}
								text2={data.perRoom.roomSupplement?.toString()}
							/>
							<SpaceDisplayText
								text1={t('bk.RatePlanSupplement')}
								text2={data.perRoom.ratePlanSupplement?.toString()}
							/>
							<Divider sx={{ marginBottom: '15px' }} />
							<AppSpaceSubtitleLayout
								label={`${t('bk.Subtotal')}`}
								content={data.perRoom.roomRateAndSupplement?.toString()}
							/>
							<SpaceDisplayText
								text1={t('bk.Tax')}
								text2={data.perRoom.taxAmount?.toString()}
							/>
							<SpaceDisplayText
								text1={t('bk.ServiceCharge')}
								text2={data.perRoom.serviceChargeAmount?.toString()}
							/>
							<SpaceDisplayText
								text1={t('bk.Markup')}
								text2={data.perRoom.markupAmount?.toString()}
							/>
							<SpaceDisplayText
								text1={t('bk.Commission')}
								text2={`(${data.perRoom.agencyCommissionAmount?.toString()})`}
							/>
						</Box>
					)}
					{data.code && (
						<Box
							sx={{
								display: 'flex',
								gap: '5px',
								flexDirection: 'column',
								marginBottom: '15px',
							}}
						>
							<SpaceDisplayText
								text1={`${t('bk.DiscountedAmount')} (${data.code})`}
								text2={`(${data.rate})`}
							/>
						</Box>
					)}

					<Divider sx={{ marginBottom: '15px' }} />
					<AppSpaceSubtitleLayout
						label={`${t('bk.DiscountedTotal')}`}
						content={data.rate?.toString()}
						color={'#4EA4D3'}
					/>
				</StyledBreakdownCard>
			)}
		</>
	);
}

const StyledBreakdownCard = styled(Box)({
	backgroundColor: '#F5F5F5',
	padding: '20px ',
	paddingBottom: '0',
	borderRadius: '10px',
	marginBottom: '20px',
});

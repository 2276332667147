import { Button, Divider, Grid, Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';

import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';

import AppTextField from '../../../../common/components/form-inputs/AppTextField';

import { FormCardType } from '../../../../common/components/card/FormCard';

import { Dispatch, SetStateAction, useContext, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SupplierIdContext } from '../ViewSupplierPage';

import { Supplier } from '../../../../api/DTO/supplier.interface';
import { useGetSupplierQuery } from '../../../../api/supplierApiSlice';
import { useUpdateSupplierBankDetailMutation } from '../../../../api/supplierApiSlice';

interface bankDetailFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function bankDetailForm({ type, setType }: bankDetailFormProps) {
	const { t } = useTranslation();
	const supplierId = useContext(SupplierIdContext);

	const formSchema = Yup.object().shape({});

	const { data: getSupplierResponse, isLoading: isGetSupplierLoading } =
		useGetSupplierQuery(supplierId, {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control } =
		useFormContext() ??
		useForm<Supplier>({
			defaultValues: getSupplierResponse?.data,
			resolver: yupResolver(formSchema),
		});


	const [
		updateSupplierBankDetail,
		{ isSuccess: isUpdateSupplierBankDetailSuccess },
	] = useUpdateSupplierBankDetailMutation();

	useEffect(() => {
		if (isUpdateSupplierBankDetailSuccess) setType(FormCardType.view);
	}, [isUpdateSupplierBankDetailSuccess]);



	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateSupplierBankDetail({
			supplierId: supplierId ?? '',
			body: data.bankDetail,
		});
	};
	

	const formContent = (
		<Stack>
			<AppTextField
				control={control}
				name='bankDetail.recipientName'
				label={t('supplier.RecipientName')}
				placeholder={t('placeholder.pleaseEnterEnglish')}
			/>

			<AppTextField
				control={control}
				name='bankDetail.recipientAddress'
				label={t('supplier.RecipientAddress')}
				placeholder={t('placeholder.pleaseEnterEnglish')}
				multiline
				rows={3}
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppTextField
				control={control}
				name='bankDetail.name'
				label={t('supplier.BankName')}
				placeholder={t('placeholder.pleaseEnterEnglish')}
			/>

			<AppTextField
				control={control}
				name='bankDetail.code'
				label={t('supplier.BankCode')}
			/>

			<AppTextField
				control={control}
				name='bankDetail.accountNumber'
				label={t('supplier.AccountNumber')}
			/>

			<AppTextField
				control={control}
				name='bankDetail.swiftCode'
				label={t('supplier.SwiftCode')}
			/>

			<AppTextField
				control={control}
				name='bankDetail.address'
				label={t('supplier.BankAddress')}
				placeholder={t('placeholder.pleaseEnterEnglish')}
				multiline
				rows={3}
			/>

			<AppTextField
				control={control}
				name='bankDetail.remarks'
				label={t('supplier.Remarks')}
				multiline
				rows={5}
			/>
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
	type === FormCardType.edit ? (
		<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
	) : (
		formContent
	);

	return <>{!isGetSupplierLoading && form}</>;
}

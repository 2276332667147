import { Box, styled, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';
import { ReactComponent as CircleInfoIcon } from '../../../assets/icon/circleInfo.svg';

interface AppFormLabelProps {
	required?: boolean;
	label?: string;
	infoPopUp?: ReactNode;
}

const Label = styled(Typography)({
	fontSize: '12px',
	color: '#808080',
	whiteSpace: 'pre-line',
});

export default function AppFormLabel({
	required,
	label,
	infoPopUp,
}: React.PropsWithChildren<AppFormLabelProps>) {
	return (
		<Box>
			<Stack
				width={'160px'}
				direction={'row'}
				alignItems={'center'}
				spacing='5px'
				flexWrap={'wrap'}
				marginBottom='15px'
			>
				<Label>
					{label}
					{required ? '*' : ''}
					{!!infoPopUp && (
						<Box component={'span'} sx={{ marginLeft: '5px' }}>
							<Tooltip title={infoPopUp} placement='bottom-start'>
								<CircleInfoIcon />
							</Tooltip>
						</Box>
					)}
				</Label>
			</Stack>
		</Box>
	);
}

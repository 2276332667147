import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';
import { Bed, bedOption } from '../../../../api/enum/bed.enum';
import { BedConfig } from '../../../../api/DTO/room.interface';
import { useTranslation } from 'react-i18next';
import AppAlert from '../../../../common/components/AppAlert';
import BedTypeCounter from '../../../../common/components/counter/BedTypeCounter';

interface SingleBedConfigProps {
	index: number;
	bedConfigValue: BedConfig;
	onChange: (value: BedConfig) => void;
	showDeleteButton: boolean;
	deleteOnClick: (index: number) => void;
	errorMsg?: string;
}

export default function SingleBedConfig({
	index,
	bedConfigValue,
	onChange,
	showDeleteButton,
	deleteOnClick,
	errorMsg,
}: SingleBedConfigProps) {
	const { t } = useTranslation();

	const hangleChange = (bedType: Bed, value: number) => {
		const onChangeBeddings = bedConfigValue.beddings;
		onChangeBeddings[bedType] = value === 0 ? undefined : value;
		onChange({ id: bedConfigValue?.id, beddings: onChangeBeddings });
	};

	function isValidBeddings(): boolean {
		let isValid = false;
		bedOption.forEach((option) => {
			const count = bedConfigValue.beddings[option.id as Bed];
			if (count && !isNaN(Number(count)) && Number(count) > 0) {
				isValid = true;
				return;
			}
		});
		return isValid;
	}

	return (
		<>
			<BedOptionContainer key={index}>
				{showDeleteButton && (
					<Box
						sx={{
							position: 'absolute',
							top: 10,
							right: 20,
						}}
					>
						<DeleteIcon
							color='#F6403F'
							cursor={'pointer'}
							onClick={() => deleteOnClick(index)}
						/>
					</Box>
				)}

				<Stack direction={'row'} alignItems={'center'} marginBottom={'15px'}>
					<OptionLabel>
						{t('common.Option')} {index + 1}
					</OptionLabel>

					{errorMsg && !isValidBeddings() && (
						<Box>
							<AppAlert alertColor='#F6403F'>{errorMsg}</AppAlert>
						</Box>
					)}
				</Stack>

				<Grid
					container
					flexGrow={1}
					alignItems='stretch'
					columnSpacing={'30px'}
					rowSpacing={'5px'}
				>
					{bedOption.map((item) => (
						<Grid key={item.id} item xs={6}>
							<Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
								<Typography minWidth={'215px'} fontSize={'12px'}>
									{item.name}
								</Typography>
								<BedTypeCounter
									errorBorder={!!(errorMsg && !isValidBeddings())}
									value={bedConfigValue.beddings[item.id as Bed]}
									onChange={(value: number) => hangleChange(item.id, value)}
								/>
							</Box>
						</Grid>
					))}
				</Grid>
			</BedOptionContainer>
		</>
	);
}

const OptionLabel = styled(Typography)(({ theme }) => ({
	marginRight: '20px',
	padding: '5px 10px',
	color: '#32A5F1',
	fontSize: '12px',
	borderRadius: '5px',
	border: '1px solid #32A5F1',
	background: '#F2FAFF',
}));

const BedOptionContainer = styled(Box)(({ theme }) => ({
	position: 'relative',
	gap: '20px',
	padding: '15px 20px 20px 20px',
	borderRadius: '5px',
	border: '1px solid #32A5F1',
}));

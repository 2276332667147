import { Box, Typography } from '@mui/material';

interface BlueNonEditableTextCellProps {
	field: string;
	date: string;
	value: string;
}

export default function BlueNonEditableTextCell({
	field,
	date,
	value,
}: BlueNonEditableTextCellProps) {
	return (
		<Box
			id={`${date}_${field}`}
			sx={{
				backgroundColor: 'white',
				cursor: 'default',
			}}
			width={'100%'}
			height={'100%'}
			justifyContent={'center'}
		>
			<Box
				width={'100%'}
				height={'100%'}
				style={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				<Typography
					className={'noselect'}
					sx={{
						display: 'block',
						fontSize: '12px',
						textAlign: 'center',
						lineHeight: '36px',
						color: '#32A5F1',
					}}
					variant='regular'
				>
					{value}
				</Typography>
			</Box>
		</Box>
	);
}

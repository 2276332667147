import { Box, styled, Typography } from "@mui/material";

interface TitleColorDisplayProps {
    name: string;
}

export default function TitleColorDisplay({ name }:TitleColorDisplayProps) {
    return (
        <StyledTitleBox>
            <Typography fontSize={'14px'} fontWeight={700} padding={'15px'} textAlign={'center'}>
                {name}
            </Typography>
        </StyledTitleBox>
    )
}

const StyledTitleBox = styled(Box)(({ theme }) => ({
    minWidth: "17rem",
    marginLeft: '1rem',
    marginRight: '1rem',
    '&:nth-of-type(odd)': {
        backgroundColor: "#d1eafa",
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#f2faff',
    },
    
}));
import './styles.css';
import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useState } from 'react';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import InlineImagePlugin from './plugins/InlineImagePlugin';
import { InlineImageNode } from './nodes/InlineImageNode';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import TableCellResizer from './plugins/TableCellResizer';
import TableCellActionMenuPlugin from './plugins/TableCellActionMenuPlugin';
import Placeholder from './ui/Placeholder';
import { TableContext } from './plugins/TablePlugin';
import ContentEditable from './ui/ContentEditable';
import YouTubePlugin from './plugins/YouTubePlugin';
import { YouTubeNode } from './nodes/YouTubeNode';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import { BlogPostImage } from '../../../api/DTO/blogPost.interface';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getNodeByKey } from 'lexical';
import { isJson } from '../../../utils/helper';

export default function AppBlogPostEditor<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ ...props }: UseControllerProps<TFieldValues, TName>) {
	// editor state sting from react hook form
	const { field: editorStateStringField } = useController(props);
	const formEditorStateString = editorStateStringField.value;
	const editorStateStringFieldOnChange = editorStateStringField.onChange;

	// images array from react hook form
	const { field: imagesField } = useController({
		control: props.control,
		name: 'images' as TName,
	});
	const [serverRefactoredImages] = useState<BlogPostImage[]>(imagesField.value);

	function CheckAndUpdateImagesUrlPlugIn() {
		const [editor] = useLexicalComposerContext();

		useEffect(() => {
			const removeMutationListener = editor.registerMutationListener(
				InlineImageNode,
				(mutatedNodes) => {
					for (const [nodeKey] of mutatedNodes) {
						editor.update(() => {
							const inlineImageNode = $getNodeByKey<InlineImageNode>(nodeKey);

							if (inlineImageNode) {
								const lastestFileObject = serverRefactoredImages?.find(
									(image) => image.id == inlineImageNode.__fileObject.id
								);

								if (
									lastestFileObject &&
									lastestFileObject?.url !== inlineImageNode.__fileObject.url
								) {
									inlineImageNode?.setFileObject(lastestFileObject);
								}
							}
						});
					}
				}
			);
			return () => {
				removeMutationListener();
			};
		}, [editor]);
		return null;
	}

	const placeholder = <Placeholder> </Placeholder>;

	const [floatingAnchorElem, setFloatingAnchorElem] =
		useState<HTMLDivElement | null>(null);

	const onRef = (_floatingAnchorElem: HTMLDivElement) => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	const editorConfig = {
		theme: PlaygroundEditorTheme,
		...(formEditorStateString &&
			(isJson(formEditorStateString)
				? {
						editorState: formEditorStateString,
				  }
				: {
						editorState: null,
				  })),
		onError(error: Error) {
			throw error;
		},
		nodes: [
			HeadingNode,
			ListNode,
			ListItemNode,
			TableNode,
			TableCellNode,
			TableRowNode,
			AutoLinkNode,
			LinkNode,
			InlineImageNode,
			HorizontalRuleNode,
			YouTubeNode,
		],
		namespace: 'Playground',
	};

	return (
		<LexicalComposer initialConfig={editorConfig}>
			<TableContext>
				<div className='editor-shell'>
					<ToolbarPlugin isBlogPostEditor={true} />
					<div className='editor-container tree-view'>
						<RichTextPlugin
							contentEditable={
								<div className='editor-scroller'>
									<div className='editor' ref={onRef}>
										<ContentEditable />
									</div>
								</div>
							}
							placeholder={placeholder}
							ErrorBoundary={LexicalErrorBoundary}
						/>
						<HistoryPlugin />
						<AutoFocusPlugin />
						<ListPlugin />
						<LinkPlugin />
						<AutoLinkPlugin />
						<ListMaxIndentLevelPlugin maxDepth={7} />
						<FloatingLinkEditorPlugin />
						<HorizontalRulePlugin />
						<InlineImagePlugin />
						<TablePlugin hasCellMerge={true} hasCellBackgroundColor={true} />
						<TableCellResizer />
						{floatingAnchorElem && (
							<>
								<TableCellActionMenuPlugin
									anchorElem={floatingAnchorElem}
									cellMerge={true}
								/>
							</>
						)}
						<AutoEmbedPlugin />
						<YouTubePlugin />
						<CheckAndUpdateImagesUrlPlugIn />
						<OnChangePlugin
							ignoreSelectionChange
							onChange={(editorState) => {
								// update react hook form editor state string
								editorStateStringFieldOnChange(JSON.stringify(editorState));

								// update react hook form images array
								const images: BlogPostImage[] = [];
								editorState._nodeMap.forEach((node) => {
									if (node.__type === 'inline-image') {
										images.push(node.__fileObject);
									}
								});
								imagesField.onChange(images);
							}}
						/>
					</div>
				</div>
			</TableContext>
		</LexicalComposer>
	);
}

import { Button, Grid, Stack, Box, Typography } from '@mui/material';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	DefaultValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import PageHeader from '../../common/components/PageHeader';
import { FormCardType } from '../../common/components/card/FormCard';
import { Place } from '../../api/DTO/place.interface';
import BaseInfoCard from './overview/baseInfo/BaseInfoCard';
import CatAndFacCard from './overview/catAndFac/CatAndFacCard';
import MediaCard from './overview/media/MediaCard';
import AdmissionDetailsCard from './overview/admissionDetails/AdmissionDetailsCard';
import { placeTypeOption } from '../../api/enum/placeType.enum';
import { useState } from 'react';
import SetPlaceTypeDropDownMenu from './components/SetPlaceTypeDropDownMenu';
import { useAddPlaceMutation } from '../../api/contentApiSlice';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { COORDINATES_PATTERN } from '../../common/constants/pattern';

export default function CreatePlacePage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [placeType, setPlaceType] = useState(undefined);

	const { state } = useLocation();

	const [placeSummarySearchParams, setPlaceSummarySearchParams] = useState<
		string | undefined
	>(undefined);

	useEffect(() => {
		if (state?.placeSummarySearchParams) {
			setPlaceSummarySearchParams(state?.placeSummarySearchParams);
		}
	}, []);

	const backPath = placeSummarySearchParams
		? `/places?${placeSummarySearchParams}`
		: '/places';

	const defaultValues: DefaultValues<Place> = {
		type: undefined,
		baseInfo: {
			name: '',
			nameEn: '',
			nameOg: '',
			website: '',
			phoneNum: '',
			address: '',
			mapLink: '',
			coordinatesInString: '',
			nearbyRailwayStations: [],
			description: '',
			remarks: '',
			reviewers: [],
		},
		catAndFac: {
			category: undefined,
			tagCategories: undefined,
			familyFriendly: false,
			kolRecommended: false,
			hiddenGem: false,
			restaurantSize: undefined,
		},
		media: {
			images: [],
			socialMedias: {
				'0': [],
				'1': [],
			},
		},
		admissionDetails: {
			ageGroups: [],
			resInAdvance: false,
			csButton: false,
			ticketRequired: false,
		},
	};

	const formSchema = Yup.object().shape({
		baseInfo: Yup.object()
			.shape({
				nameOg: Yup.string().required(t('errorMessage.required')),
				country: Yup.object().required(t('errorMessage.pleaseSelect')),
				majorArea: Yup.object().required(t('errorMessage.pleaseSelect')),
				area: Yup.object().required(t('errorMessage.pleaseSelect')),
				address: Yup.string().required(t('errorMessage.required')),
				mapLink: Yup.string().required(t('errorMessage.required')),
				coordinatesInString: Yup.string()
					.required(t('errorMessage.required'))
					.matches(
						COORDINATES_PATTERN,
						t('errorMessage.incorrectCoordinatesFormat')
					),
			})
			.required(),
		catAndFac: Yup.object()
			.shape({
				category: Yup.object().required(t('errorMessage.pleaseSelect')),
			})
			.required(),
		media: Yup.object()
			.shape({
				socialMedias: Yup.object().shape({
					0: Yup.array().of(
						Yup.object().shape({
							link: Yup.string().required(t('errorMessage.required')),
						})
					),
					1: Yup.array().of(
						Yup.object().shape({
							link: Yup.string().required(t('errorMessage.required')),
						})
					),
				}),
			})
			.required(),
	});

	const [addPlace, { data: AddPlaceResponse, isSuccess: isAddPlaceSuccess }] =
		useAddPlaceMutation();

	const methods = useForm<Place>({
		defaultValues,
		resolver: yupResolver(formSchema),

		context: { placeType },
	});

	const onSubmit: SubmitHandler<Place> = (data) => {
		// convert coordinates string to object
		const coordinatesInString = data.baseInfo.coordinatesInString;
		if (coordinatesInString) {
			data.baseInfo.coordinates = {
				latitude: parseFloat(coordinatesInString.split(/,(.*)/s)[0]),
				longitude: parseFloat(coordinatesInString.split(/,(.*)/s)[1]),
			};
		}
		addPlace(data);
	};

	useEffect(() => {
		if (isAddPlaceSuccess) {
			navigate(`/places/${AddPlaceResponse?.data.baseInfo.id}`);
		}
	}, [isAddPlaceSuccess]);

	return (
		<>
			<PageHeader
				showBackButton={true}
				backPath={backPath}
				title={t('place.CreatePlace')}
			/>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<SetPlaceTypeDropDownMenu
						name='type'
						label={t('place.WhatAreYouGoingToCreate')}
						options={placeTypeOption}
						onChangeCallback={(e) => {
							methods.resetField('baseInfo.reviewers');
							methods.resetField('catAndFac');
							methods.resetField('admissionDetails');
							setPlaceType(e);
						}}
						required
					/>
					<Stack spacing='16px'>
						{placeType !== undefined ? (
							<Grid container alignItems='stretch' spacing={'10px'}>
								<Grid item xs={6} display='flex'>
									<BaseInfoCard
										initialType={FormCardType.create}
										placeType={placeType}
									/>
								</Grid>
								<Grid item xs={6} display='flex'>
									<CatAndFacCard
										initialType={FormCardType.create}
										placeType={placeType}
									/>
								</Grid>
								<Grid item xs={6} display='flex'>
									<MediaCard
										initialType={FormCardType.create}
										placeType={placeType}
									/>
								</Grid>
								<Grid item xs={6} display='flex'>
									<AdmissionDetailsCard
										initialType={FormCardType.create}
										placeType={placeType}
									/>
								</Grid>
							</Grid>
						) : (
							<Box
								width={'100%'}
								textAlign={'center'}
								sx={{
									padding: '7em',
									border: '2px dashed transparent',
									borderRadius: '10px',
									background:
										'linear-gradient(#F5F5F5,#F5F5F5) padding-box, repeating-linear-gradient(-45deg,#BCBCBC 0, #BCBCBC 0.95em,#F5F5F5 0,#F5F5F5 1.50em)',
								}}
							>
								<Typography
									color={'#808080'}
									fontSize={'1rem'}
									fontWeight={700}
								>
									{t('place.FieldsWillBeCreate')}
								</Typography>
							</Box>
						)}
						<Stack spacing='16px'>
							<Grid item xs={12}>
								<Stack direction='row-reverse' spacing='12px'>
									<Button
										type='submit'
										variant='contained'
										color='success'
										disabled={placeType == undefined}
									>
										{t('button.Save')}
									</Button>
									<Button
										variant='outlined'
										color='info'
										onClick={() => navigate(backPath)}
									>
										{t('button.Discard')}
									</Button>
								</Stack>
							</Grid>
						</Stack>
					</Stack>
				</form>
			</FormProvider>
		</>
	);
}

import Option from '../../common/types/option.type';

export enum SupplierPaymentMethod {
	CREDIT_CARD = 0,
	BANK_TRANSFER = 1,
}

export const supplierPaymentMethodOption: Option[] = [
	{
		id: SupplierPaymentMethod.CREDIT_CARD,
		name: 'Credit Card',
	},
	{
		id: SupplierPaymentMethod.BANK_TRANSFER,
		name: 'Bank Transfer',
	},
];

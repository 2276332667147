import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useState } from 'react';

import PolicyView from './PolicyView';
import PolicyForm from './PolicyForm';

interface PolicyCardProps {
	initialType: FormCardType;
}

export default function PolicyCard({ initialType }: PolicyCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<FormCard type={type} title={t('property.Policy')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <PolicyView setType={setType} />;
					case FormCardType.create:
						return <PolicyForm type={type} setType={setType} />;
					case FormCardType.edit:
						return <PolicyForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

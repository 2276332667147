import { Chip, Grid } from '@mui/material';
import { Tag } from '../../../api/DTO/room.interface';

interface TagsDisplayProps {
	tags?: Tag[];
}

export default function TagsDisplay({ tags }: TagsDisplayProps) {
	return (
		<>
			<Grid container spacing={'8px'}>
				{tags?.map((tag: Tag) => (
					<Grid key={tag.id} item>
						<Chip
							key={tag.id}
							label={tag.name}
							color='primary'
							variant='outlined'
							sx={{
								height: '28px',
								fontSize: '12px',
								background: 'white',
								'& .MuiChip-label': {
									padding: '5px 16px 5px 16px',
								},
							}}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
}

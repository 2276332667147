import { Box, Collapse, List, ListItemText, Typography } from '@mui/material';
import { StyledListItemButton } from '../../../../common/components/styled/StyledListItemButton';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExpandMoreIcon } from '../../../../assets/icon/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from '../../../../assets/icon/expand-less.svg';
import { useEffect, useState } from 'react';
import { otherSupplementMealTypeOptions } from '../../../../api/enum/mealType.enum';
import ListItemLinkV2 from '../../../../common/components/list/ListItemLinkV2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function MealList() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { propertyId, mealTypeId } = useParams();
	const { pathname } = useLocation();

	const [open, setOpen] = useState(true);
	const handleClick = () => {
		setOpen(!open);
	};

	// default view meal type of the list
	useEffect(() => {
		if (!mealTypeId) {
			const firstMealType = otherSupplementMealTypeOptions[0];
			if (firstMealType) {
				navigate(
					`/property/${propertyId}/meal-supplement/meal-type/${firstMealType.id}`
				);
			}
		}
	}, [mealTypeId]);

	return (
		<>
			{/* <StyledListItemButton onClick={handleClick} selected={false}>
				<ListItemText
					primary={
						<Box>
							<Typography
								fontSize='12px'
								color={'text.grey'}
								style={{ whiteSpace: 'pre-line' }}
							>
								{t('mealSupplement.MealSupplementPerPerson')}
							</Typography>
						</Box>
					}
				/>
				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</StyledListItemButton>
			<Collapse in={open} timeout='auto' unmountOnExit> */}
			<List component='div' disablePadding>
				{otherSupplementMealTypeOptions.map((mealType) => (
					<div key={mealType.id}>
						<ListItemLinkV2
							primarytext={mealType.nameEn}
							secondaryText={mealType.name}
							to={`/property/${propertyId}/meal-supplement/meal-type/${mealType.id}`}
							selected={pathname.includes(`/meal-type/${mealType.id}`)}
						/>
					</div>
				))}
			</List>
			{/* </Collapse> */}
		</>
	);
}

import { Box, Button, InputAdornment, Stack, Typography } from '@mui/material';
import { ReactComponent as ExpandMoreIcon } from '../../../../../assets/icon/expand-more.svg';
import {
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
} from '../../../../../common/components/AppAccordian';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
	useBulkUpdateRoomRatesMutation,
	useGetPropertyQuery,
	useGetRoomSummaryQuery,
} from '../../../../../api/accommodationApiSlice';
import { useEffect, useState } from 'react';
import AppGridMultipleCheckbox from '../../../../../common/components/form-inputs/AppGridMultipleCheckbox';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RoomClass } from '../../../../../api/enum/roomClass.enum';

export default function RoomRate() {
	const { t } = useTranslation();
	const method = useFormContext();
	const { propertyId } = useParams();

	const [submitCount, setSubmitCount] = useState(0);
	const [submitted, setSubmitted] = useState(false);

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');
	const { data: getRoomSummaryResponse } = useGetRoomSummaryQuery({
		propertyId: propertyId ?? '',
		rateModel: method.getValues('rateModel'),
		roomClass: RoomClass.INDEPENDENT,
	});
	const [bulkUpdateRoomRatesMutation] = useBulkUpdateRoomRatesMutation();

	const defaultValues: DefaultValues<FieldValues> = {
		roomIds: [],
		rate: 0,
	};

	const formSchema = Yup.object().shape({
		roomIds: Yup.array().min(1, t('errorMessage.atLeastOneCheckbox')),
		rate: Yup.string().required(t('errorMessage.required')),
	});

	const roomRateMethod = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const watchParentFrom = method.watch('from');
	const watchParentTo = method.watch('to');
	const watchParentDays = method.watch('days');
	const watchRate = method.watch('rate');

	useEffect(() => {
		if (submitCount === 0) return;
		method.trigger().then((_r) => roomRateMethod.trigger().then());
	}, [watchParentFrom, watchParentTo, watchParentDays, watchRate, submitCount]);

	useEffect(() => {
		if (submitted) {
			setTimeout(() => {
				setSubmitted(false);
			}, 3000);
		}
	}, [submitted]);

	const onSubmit: SubmitHandler<FieldValues> = () => {
		setSubmitCount((prev) => prev + 1);
		method.trigger().then((methodValidated) =>
			roomRateMethod.trigger().then((roomRateMethodValidated) => {
				if (methodValidated && roomRateMethodValidated) {
					roomRateMethod.setValue('days', method.getValues('days'));
					roomRateMethod.setValue('from', method.getValues('from'));
					roomRateMethod.setValue('to', method.getValues('to'));
					roomRateMethod.setValue('rateModel', method.getValues('rateModel'));

					bulkUpdateRoomRatesMutation({
						propertyId: propertyId ?? '',
						body: {
							rateModel: roomRateMethod.getValues('rateModel'),
							from: roomRateMethod.getValues('from'),
							to: roomRateMethod.getValues('to'),
							days: roomRateMethod.getValues('days'),
							roomIds: roomRateMethod.getValues('roomIds'),
							rate: roomRateMethod.getValues('rate'),
						},
					})
						.unwrap()
						.then((response) => {
							if (response.success) {
								setSubmitCount(0);
								setSubmitted(true);
							}
						});
				}
			})
		);
	};

	return (
		<StyledAccordion>
			<StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography
					color={'gray'}
					sx={{ flexGrow: 1 }}
					fontWeight={'bold'}
					fontSize={'14px'}
				>
					{t('rateAndInventory.EditBaseRate')}
				</Typography>
			</StyledAccordionSummary>
			<StyledAccordionDetails>
				<AppTextField
					control={roomRateMethod.control}
					name={`rate`}
					inputType={InputType.amount}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								{getPropertyResponse?.data?.supplier.currency}
							</InputAdornment>
						),
					}}
				/>

				<Box>
					<Typography
						color={'gray'}
						sx={{ flexGrow: 1 }}
						fontWeight={'bold'}
						fontSize={'14px'}
					>
						{t('rateAndInventory.ApplyToTheFollowingRooms')}
					</Typography>
					<Typography color={'gray'} sx={{ flexGrow: 1 }} fontSize={'10px'}>
						{t('rateAndInventory.BasicRoomAndIndependentRoom')}
					</Typography>
					<Stack
						display={'flex'}
						direction={'column'}
						alignItems={'flex-start'}
					>
						<AppGridMultipleCheckbox
							control={roomRateMethod.control}
							label={''}
							options={
								getRoomSummaryResponse?.data.map((room) => {
									return { id: room.id, name: room.nameEn };
								}) ?? []
							}
							name={'roomIds'}
							columns={1}
						/>
					</Stack>
				</Box>

				<Button
					variant={'contained'}
					onClick={roomRateMethod.handleSubmit(onSubmit)}
					fullWidth={true}
					disabled={submitted}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{submitted ? 'Applied' : 'Confirm'}
					</Typography>
				</Button>
			</StyledAccordionDetails>
		</StyledAccordion>
	);
}

import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/delete.svg';
import { StyledTextField } from '../../../common/components/styled/StyledTextField';
import { Images } from '../../../api/DTO/place.interface';
import { useTranslation } from 'react-i18next';
import AppSubtitleLayout from '../../../common/components/form-layout/AppSubtitleLayout';

interface UploadedImageWithCreditProps {
	index: number;
	value: Images;
	onChange: (value: Images) => void;
	deleteOnClick: (index: number) => void;
}

export default function UploadedImageWithCredit({
	index,
	value,
	onChange,
	deleteOnClick,
}: UploadedImageWithCreditProps) {
	const handleCreditChange = (newCredit: string) => {
		const onChangeValue = value;
		onChangeValue.credit = newCredit;
		onChange(onChangeValue);
	};
	const { t } = useTranslation();

	return (
		<>
			<Box
				position={'relative'}
				marginBottom='20px'
				padding='20px'
				borderRadius={'10px'}
				sx={{ background: '#f2faff' }}
			>
				<AppSubtitleLayout label={t('place.LocalPhoto') + ` (${index + 1})`} />
				<Box sx={{ display: 'flex', gap: '20px' }}>
					<Box>
						<Typography width={'160px'} fontSize={'12px'} color='#808080'>
							{t('place.Media')}
						</Typography>
					</Box>

					<Grid container columnSpacing={'20px'}>
						<Grid item xs sx={{ maxWidth: '100%' }}>
							<img
								style={{
									width: '100%',
									maxWidth: '400px',
									borderRadius: '10px',
								}}
								src={value.url}
							/>
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ display: 'flex', gap: '20px' }}>
					<Box>
						<Typography width={'160px'} fontSize={'12px'} color='#808080'>
							{t('place.MediaCredit')}
						</Typography>
					</Box>

					<Grid container columnSpacing={'20px'}>
						<Grid item xs>
							<StyledTextField
								value={value.credit ?? ''}
								onChange={(e) => {
									handleCreditChange(e.target.value);
								}}
							/>
						</Grid>
					</Grid>
				</Box>

				<Box
					sx={{
						position: 'absolute',
						top: 10,
						right: 20,
					}}
				>
					<DeleteIcon color='#F6403F' cursor={'pointer'} onClick={() => deleteOnClick(index)} />
				</Box>
			</Box>
		</>
	);
}

import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useState } from 'react';

import SupplierView from './SupplierView';
import SupplierForm from './SupplierForm';

interface SupplierCardProps {
	initialType: FormCardType;
}

export default function SupplierCard({ initialType }: SupplierCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<FormCard type={type} title={t('property.SupplierMapping')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <SupplierView setType={setType} />;
					case FormCardType.create:
						return <SupplierForm type={type} setType={setType} />;
					case FormCardType.edit:
						return <SupplierForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

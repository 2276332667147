import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import { useGetPropertyQuery } from '../../../../api/accommodationApiSlice';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import { Dispatch, SetStateAction, useContext } from 'react';
import { PropertyIdContext } from '../ViewPropertyPage';
import { YearsRangeDisplay } from '../../../../common/components/form-data-display/YearsRangeDisplay';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';
import TickOrCrossWithLabelDisplay from '../../../../common/components/form-data-display/TickOrCrossWithLabelDisplay';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import EditorDisplay from '../../../../common/components/editor/EditorDisplay';
import dayjs from 'dayjs';
import {
	API_TIME_FORMAT,
	DISPLAY_TIME_FORMAT,
} from '../../../../utils/dateHelper';

export default function PolicyView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const propertyId = useContext(PropertyIdContext);

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppInputLayout label={t('property.CheckInTime')}>
					<Stack direction={'row'} spacing={'52px'}>
						<NewDisplayText
							text={`${t('common.From')} ${dayjs(
								getPropertyResponse?.data.policy.minCheckInTime,
								API_TIME_FORMAT
							).format(DISPLAY_TIME_FORMAT)}`}
						/>
						<NewDisplayText
							text={`${t('common.To')} ${dayjs(
								getPropertyResponse?.data.policy.maxCheckInTime,
								API_TIME_FORMAT
							).format(DISPLAY_TIME_FORMAT)}`}
						/>
					</Stack>
				</AppInputLayout>
				<AppInputLayout label={t('property.CheckOutTime')}>
					<NewDisplayText
						text={`${t('common.Until')} ${dayjs(
							getPropertyResponse?.data.policy.checkOutTime,
							API_TIME_FORMAT
						).format(DISPLAY_TIME_FORMAT)}`}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('property.MinCheckinAge')}>
					<NewDisplayText
						text={`${getPropertyResponse?.data.policy.minCheckInAge} ${t(
							'common.YearsOld'
						)}`}
					/>
				</AppInputLayout>
				<Divider sx={{ marginBottom: '15px' }} />
				<AppInputLayout
					label={t('property.UniquePolicy')}
					labelAlignItemsFlexStart={true}
				>
					<EditorDisplay
						editorStateString={getPropertyResponse?.data.policy.uniquePolicy}
					/>
				</AppInputLayout>
				<Divider sx={{ marginBottom: '15px' }} />
				<AppInputLayout label={t('property.WhoAreAllowedToStay')} infoPopUp=' '>
					<Stack direction={'row'} spacing={'18px'} gap={'10px'}>
						<TickOrCrossWithLabelDisplay
							value={getPropertyResponse?.data.policy.allowInfant}
							label={t('common.Infants')}
						/>
						<TickOrCrossWithLabelDisplay
							value={getPropertyResponse?.data.policy.allowToddler}
							label={t('common.Toddlers')}
						/>
						<TickOrCrossWithLabelDisplay
							value={getPropertyResponse?.data.policy.allowChildren}
							label={t('common.Children')}
						/>
					</Stack>
				</AppInputLayout>
				<AppInputLayout label={t('property.AgeofInfants')}>
					<YearsRangeDisplay
						from={getPropertyResponse?.data.policy.infantAge?.min?.toString()}
						to={getPropertyResponse?.data.policy.infantAge?.max?.toString()}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('property.AgeofToddlers')}>
					<YearsRangeDisplay
						from={getPropertyResponse?.data.policy.toddlerAge?.min?.toString()}
						to={getPropertyResponse?.data.policy.toddlerAge?.max?.toString()}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('property.AgeofChildren')}>
					<YearsRangeDisplay
						from={getPropertyResponse?.data.policy.childAge?.min?.toString()}
						to={getPropertyResponse?.data.policy.childAge?.max?.toString()}
					/>
				</AppInputLayout>
				<Divider sx={{ marginBottom: '15px' }} />
				<AppInputLayout label={t('property.SmokingIsAllowedInPublicArea')}>
					<YesNoDisplay value={getPropertyResponse?.data.policy.allowSmoking} />
				</AppInputLayout>
			</Stack>
		</>
	);
}

import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import { Dispatch, SetStateAction, useContext } from 'react';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { PlaceIdContext } from '../ViewPlacePage';
import { useGetPlaceQuery } from '../../../../api/contentApiSlice';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';
import TickOrCrossWithLabelDisplay from '../../../../common/components/form-data-display/TickOrCrossWithLabelDisplay';
import {
	PlaceAgeGroup,
	placeAgeGroupOption,
} from '../../../../api/enum/placeAgeGroup.enum';

export default function AdmissionDetailsView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const placeId = useContext(PlaceIdContext);

	const { data: getPlaceResponse } = useGetPlaceQuery(placeId);

	const placeType = getPlaceResponse?.data.type;

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				{getPlaceResponse?.data.admissionDetails.ageGroups &&
					placeType == 0 && (
						<>
							<AppInputLayout label={t('place.AgeGroup')}>
								<Stack
									direction={'row'}
									spacing={'18px'}
									sx={{
										paddingRight: '60px',
										flexFlow: 'row wrap',
										gap: '10px',
									}}
								>
									<TickOrCrossWithLabelDisplay
										value={getPlaceResponse?.data.admissionDetails.ageGroups.includes(
											PlaceAgeGroup.INFANT
										)}
										label={
											placeAgeGroupOption.find(
												(i) => i.id === PlaceAgeGroup.INFANT
											)?.name ?? ''
										}
									/>
									<TickOrCrossWithLabelDisplay
										value={getPlaceResponse?.data.admissionDetails.ageGroups.includes(
											PlaceAgeGroup.TODDLER
										)}
										label={
											placeAgeGroupOption.find(
												(i) => i.id === PlaceAgeGroup.TODDLER
											)?.name ?? ''
										}
									/>
									<TickOrCrossWithLabelDisplay
										value={getPlaceResponse?.data.admissionDetails.ageGroups.includes(
											PlaceAgeGroup.CHILD
										)}
										label={
											placeAgeGroupOption.find(
												(i) => i.id === PlaceAgeGroup.CHILD
											)?.name ?? ''
										}
									/>
									<TickOrCrossWithLabelDisplay
										value={getPlaceResponse?.data.admissionDetails.ageGroups.includes(
											PlaceAgeGroup.YOUNG_TEEN
										)}
										label={
											placeAgeGroupOption.find(
												(i) => i.id === PlaceAgeGroup.YOUNG_TEEN
											)?.name ?? ''
										}
									/>
									<TickOrCrossWithLabelDisplay
										value={getPlaceResponse?.data.admissionDetails.ageGroups.includes(
											PlaceAgeGroup.TEEN
										)}
										label={
											placeAgeGroupOption.find(
												(i) => i.id === PlaceAgeGroup.TEEN
											)?.name ?? ''
										}
									/>
								</Stack>
							</AppInputLayout>
							<Divider sx={{ marginBottom: '15px' }} />
							<AppInputLayout label={t('place.EntranceTicketRequired')}>
								<YesNoDisplay
									value={getPlaceResponse?.data.admissionDetails.ticketRequired}
								/>
							</AppInputLayout>
						</>
					)}

				<AppInputLayout label={t('place.ReservationInAdvance')} infoPopUp=' '>
					<YesNoDisplay
						value={getPlaceResponse?.data.admissionDetails.resInAdvance}
					/>
				</AppInputLayout>

				<AppInputLayout
					label={t('place.ActivateCustomerService')}
					infoPopUp=' '
				>
					<YesNoDisplay
						value={getPlaceResponse?.data.admissionDetails.csButton}
					/>
				</AppInputLayout>
			</Stack>
		</>
	);
}

import {
	Button,
	Box,
	Divider,
	Grid,
	InputAdornment,
	Stack,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFieldArray,
	Controller,
	useFormContext,
} from 'react-hook-form';

import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import AppPhoneCodeDropDownMenu from '../../../../common/components/form-inputs/AppPhoneCodeDropDownMenu';
import AppCheckbox from '../../../../common/components/form-inputs/AppCheckbox';
import AppAddButton from '../../../../common/components/form-inputs/AppAddButton';
import { StyledFormControlLabel } from '../../../../common/components/form-inputs/AppMultipleCheckbox';

import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';
import { ReactComponent as EmailArrow } from '../../../../assets/icon/emailArrow.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icon/button-add.svg';

import { FormCardType } from '../../../../common/components/card/FormCard';
import { useGetSupplierQuery } from '../../../../api/supplierApiSlice';

import { Dispatch, SetStateAction, useContext, useEffect } from 'react';

import { departmentOption } from '../../../../api/enum/department.enum';
import { useGetCountriesQuery } from '../../../../api/locationApiSlice';
import { Supplier } from '../../../../api/DTO/supplier.interface';

import { useUpdateSupplierContactPointMutation } from '../../../../api/supplierApiSlice';
import { SupplierIdContext } from '../ViewSupplierPage';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

interface ContactPointFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}
// control name
export default function ContactPointForm({
	type,
	setType,
}: ContactPointFormProps) {
	const { t } = useTranslation();
	const supplierId = useContext(SupplierIdContext);

	const formSchema = Yup.object().shape({
		contactPoints: Yup.array().of(
			Yup.object()
				.shape({
					department: Yup.string().required(t('errorMessage.pleaseSelect')),
					name: Yup.string().required(t('errorMessage.required')),
					email: Yup.string()
						.required(t('errorMessage.required'))
						.email(t('errorMessage.email')),
				})
				.required()
		),
	});

	const { data: getSupplierResponse, isLoading: isGetSupplierLoading } =
		useGetSupplierQuery(supplierId, {
			skip: type === FormCardType.create,
		});

	const { control, handleSubmit } =
		useFormContext() ??
		useForm<Supplier>({
			defaultValues: getSupplierResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'contactPoints',
	});

	const { data: getCountriesResponse } = useGetCountriesQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	const [
		updateSupplierContactPoint,
		{ isSuccess: isUpdateSupplierContactPointSuccess },
	] = useUpdateSupplierContactPointMutation();

	useEffect(() => {
		if (isUpdateSupplierContactPointSuccess) setType(FormCardType.view);
	}, [isUpdateSupplierContactPointSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateSupplierContactPoint({
			supplierId: supplierId ?? '',
			body: data.contactPoints,
		});
	};

	const showDeleteButton = fields.length > 1;

	const formContent = (
		<Stack>
			{fields.map((item, index) => {
				return (
					<Box key={item.id} sx={{ position: 'relative' }}>
						{showDeleteButton && (
							<>
								{index > 0 && (
									<>
										<Divider sx={{ marginBottom: '15px' }} />
										<Box
											sx={{
												position: 'absolute',
												top: 0,
												right: 0,
											}}
										>
											<DeleteIcon
												color='#F6403F'
												cursor={'pointer'}
												onClick={() => remove(index)}
											/>
										</Box>
									</>
								)}
								{index == 0 && (
									<Box
										sx={{
											position: 'absolute',
											top: '-15px',
											right: 0,
										}}
									>
										<DeleteIcon
											color='#F6403F'
											cursor={'pointer'}
											onClick={() => remove(index)}
										/>
									</Box>
								)}
							</>
						)}
						<AppInputLayout
							removeErrorPadding
							label={t('supplier.Department')}
							required
						>
							<Box
								display='grid'
								gap='10px'
								gridTemplateColumns='repeat(2, 1fr)'
								alignItems='start'
							>
								<AppDropDownMenu
									control={control}
									name={`contactPoints.${index}.department`}
									options={departmentOption}
									label={''}
									required
									fullWidth
								/>

								<Controller
									name={`contactPoints.${index}.selected`}
									control={control}
									render={({ field }) => (
										<StyledFormControlLabel
											control={
												<AppCheckbox
													checked={field.value}
													{...field}
													ref={null}
												/>
											}
											label={t('supplier.DefaultEmailContact')}
										/>
									)}
								/>
							</Box>
						</AppInputLayout>

						<AppTextField
							control={control}
							name={`contactPoints.${index}.name`}
							label={t('supplier.ContactName')}
							required
						/>

						<AppInputLayout
							removeErrorPadding
							label={t('supplier.PhoneNumber')}
						>
							<Grid container columnSpacing={2}>
								<Grid item xs={4}>
									<AppPhoneCodeDropDownMenu
										control={control}
										name={`contactPoints.${index}.country`}
										options={getCountriesResponse?.data ?? []}
										label={''}
										passObjectValue={true}
										fullWidth
									/>
								</Grid>
								<Grid item xs={8}>
									<AppTextField
										control={control}
										name={`contactPoints.${index}.phoneNum`}
										inputType={InputType.number}
										label={''}
									/>
								</Grid>
							</Grid>
						</AppInputLayout>

						<AppTextField
							control={control}
							name={`contactPoints.${index}.email`}
							label={t('supplier.Email')}
							required
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<EmailArrow />
									</InputAdornment>
								),
							}}
						/>
					</Box>
				);
			})}

			<AppAddButton
				onClick={() =>
					append({
						department: undefined,
						name: '',
						phoneCode: '',
						phoneNum: '',
						email: '',
						selected: false,
					})
				}
			>
				<AddIcon />
				{t('button.Add')}
			</AppAddButton>

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetSupplierLoading && form}</>;
}

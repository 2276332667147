import { ButtonBase, Stack, styled, Typography } from '@mui/material';
import { ReactComponent as InvertedTriangle } from '../../../assets/icon/inverted-triangle.svg';
import { ReactComponent as UnsortedIcon } from '../../../assets/icon/unsorted.svg';
import { useController, useFormContext } from 'react-hook-form';
import { SortDirection } from '../../../api/enum/sortDirection.enum';
import { Sorting } from '../../../api/DTO/common.interface';

interface AppDataGridHeaderWithSortingProps {
	headerName?: string;
	sortingsPropertyName: string;
}

/**
 * Custom Header with React Hook Form controlled sortings
 */
const AppDataGridHeaderWithSorting = ({
	headerName,
	sortingsPropertyName,
}: AppDataGridHeaderWithSortingProps) => {
	const { control } = useFormContext();
	const { field } = useController({ control: control, name: 'sortings' });
	const sortings = field.value as Sorting[];
	const sortingsOnChange = field.onChange;

	const sortingDirection = sortings?.find(
		(obj: Sorting) => obj.property == sortingsPropertyName
	)?.direction;

	function sortingButtonOnClick() {
		if (sortingDirection === SortDirection.ASCENDING) {
			const newSortings = [...sortings];
			const targetIndex = sortings?.findIndex(
				(obj: Sorting) => obj.property == sortingsPropertyName
			);
			newSortings[targetIndex].direction = SortDirection.DESCENDING;
			sortingsOnChange(newSortings);
			return;
		}
		if (sortingDirection === SortDirection.DESCENDING) {
			const newSortings = [...sortings];
			const targetIndex = sortings?.findIndex(
				(obj: Sorting) => obj.property == sortingsPropertyName
			);
			newSortings.splice(targetIndex, 1);
			sortingsOnChange(newSortings);
			return;
		}
		if (sortingDirection === undefined) {
			sortingsOnChange([
				...sortings,
				{ direction: SortDirection.ASCENDING, property: sortingsPropertyName },
			]);
		}
	}

	return (
		<HeaderContainer
			direction={'row'}
			justifyContent={'space-between'}
			onClick={sortingButtonOnClick}
		>
			<HeaderName variant='bold'>{headerName ?? ''}</HeaderName>

			<ButtonBase sx={{ padding: '0.5rem' }}>
				{(() => {
					switch (sortingDirection) {
						case SortDirection.ASCENDING:
							return <Triangle />;
						case SortDirection.DESCENDING:
							return <StyledInvertedTriangle />;
						case undefined:
							return <StyledUnsortedIcon />;
					}
				})()}
			</ButtonBase>
		</HeaderContainer>
	);
};

const HeaderContainer = styled(Stack)({
	width: '100%',
});

const Triangle = styled(InvertedTriangle)({
	width: '8px',
	height: '12px',
	transform: 'rotate(180deg)',
});

const StyledInvertedTriangle = styled(InvertedTriangle)({
	width: '8px',
	height: '12px',
});

const StyledUnsortedIcon = styled(UnsortedIcon)({
	width: '8px',
	height: '12px',
});

const HeaderName = styled(Typography)({
	fontSize: '0.875rem',
	paddingTop: '0.5rem',
	paddingBottom: '0.5rem',
	paddingRight: '0.1rem',
	whiteSpace: 'break-spaces',
	lineHeight: '1.25rem',
});

export default AppDataGridHeaderWithSorting;

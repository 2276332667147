import { useParams } from 'react-router-dom';
import { useGetPlaceQuery } from '../../../api/contentApiSlice';
import FullScreenLoader from '../../../common/components/FullScreenLoader';
import { createContext } from 'react';
import { Stack, Grid } from '@mui/material';
import BaseInfoCard from './baseInfo/BaseInfoCard';
import CatAndFacCard from './catAndFac/CatAndFacCard';
import MediaCard from './media/MediaCard';
import AdmissionDetailsCard from './admissionDetails/AdmissionDetailsCard';
import { FormCardType } from '../../../common/components/card/FormCard';

export const PlaceIdContext = createContext('');

export default function ViewPlacePage() {
	const { placeId } = useParams();

	const {
		data: getPlaceResponse,
		isLoading: isGetPlaceLoading,
		isSuccess: itGetPlaceSuccess,
		isError: isGetPlaceError,
	} = useGetPlaceQuery(placeId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	let pageContent;
	if (isGetPlaceLoading) {
		pageContent = <FullScreenLoader />;
	} else if (itGetPlaceSuccess) {
		pageContent = (
			<>
				<PlaceIdContext.Provider value={placeId ?? ''}>
					<Stack spacing='16px'>
						<Grid container alignItems='stretch' spacing={'10px'}>
							<Grid item xs={6} display='flex'>
								<BaseInfoCard
									initialType={FormCardType.view}
									placeType={getPlaceResponse?.data.type}
								/>
							</Grid>
							<Grid item xs={6} display='flex'>
								<CatAndFacCard
									initialType={FormCardType.view}
									placeType={getPlaceResponse?.data.type}
								/>
							</Grid>
							<Grid item xs={6} display='flex'>
								<MediaCard
									initialType={FormCardType.view}
									placeType={getPlaceResponse?.data.type}
								/>
							</Grid>
							<Grid item xs={6} display='flex'>
								<AdmissionDetailsCard
									initialType={FormCardType.view}
									placeType={getPlaceResponse?.data.type}
								/>
							</Grid>
						</Grid>
					</Stack>
				</PlaceIdContext.Provider>
			</>
		);
	} else if (isGetPlaceError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}

/* eslint-disable no-mixed-spaces-and-tabs */
import { ReactNode } from 'react';
import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	Box,
	Checkbox,
	FormControl,
	Stack,
	TextFieldProps,
	styled,
	Typography,
} from '@mui/material';
import Option from '../../types/option.type';
import AppInputLabel from './archive/AppInputLabel';
import CountryFlag from '../form-data-display/CountryFlag';
import {
	StyledMenuItem,
	StyledSelect,
} from '../../../common/components/styled/StyledSelect';

import { ReactComponent as CheckedIcon } from '../../../assets/icon/checkbox-checked.svg';
import { ReactComponent as UnCheckedIcon } from '../../../assets/icon/checkbox-unchecked.svg';

interface AppMultipleDropDownMenuProps {
	label?: string;
	options: Option[];
	infoPopUp?: ReactNode;
	undefinedIfEmptyArray?: boolean;
}

export default function AppMultipleDropDownMenu<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	options,
	infoPopUp,
	disabled,
	placeholder,
	required,
	undefinedIfEmptyArray,
	...props
}: AppMultipleDropDownMenuProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	function handleSelect(checkedName: any) {
		if (checkedName.length == 0) {
			field.onChange(undefined);
		} else {
			field.onChange(checkedName);
		}
	}

	return (
		<FormControl error={!!fieldState.error?.message}>
			{label && (
				<AppInputLabel
					label={label}
					infoPopUp={infoPopUp}
					errorMessage={fieldState.error?.message}
				/>
			)}
			<StyledSelect
				variant='outlined'
				multiple
				{...field}
				sx={{
					// placeholder for Select
					'& .MuiSelect-select .notranslate::after': placeholder
						? {
								content: `"${placeholder}"`,
								color: '#808080',
								opacity: 1,
						  }
						: {},
				}}
				onChange={(e) => {
					handleSelect(e.target.value);
				}}
				value={field.value == null ? [] : field.value} // to fix `value` prop on `input` should not be null
				disabled={disabled}
				renderValue={(selected: any) =>
					selected.map((value: any, index: number) => (
						<Box component='span' key={value}>
							{options.find((i) => i.id === value)?.countryCode && (
								<CountryFlag
									iso2={options.find((i) => i.id === value)?.countryCode}
									size='small'
									style={{ marginRight: '5px', verticalAlign: 'middle' }}
								/>
							)}
							{options.find((i) => i.id === value)?.color && (
								<ColorDot
									display={'inline-block'}
									color={options.find((i) => i.id === value)?.color}
								/>
							)}
							<Typography
								variant='regular'
								sx={{
									fontSize: '0.75rem',
								}}
								color={
									options.find((i) => i.id === value)?.color
										? options.find((i) => i.id === value)?.color
										: 'black'
								}
							>
								{options.find((i) => i.id === value)?.name}
							</Typography>
							{index + 1 < selected.length && ', '}
						</Box>
					))
				}
				MenuProps={{
					PaperProps: {
						sx: {
							borderRadius: '5px',
							boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
							'& .MuiMenuItem-root': {
								padding: '12px',
							},
							'& .Mui-selected': {
								backgroundColor: '#FFF !important',
							},
							maxHeight: '250px',
							maxWidth: '100px',
						},
					},
				}}
			>
				{options.map((option) => (
					<StyledMenuItem key={option.id} value={option.id}>
						<Stack direction={'row'} alignItems={'center'}>
							<Checkbox
								icon={<UnCheckedIcon />}
								checkedIcon={<CheckedIcon />}
								checked={field.value?.indexOf(option.id) > -1}
								sx={{ padding: 0, paddingRight: '0.5rem' }}
							/>
							{option.countryCode && (
								<CountryFlag
									iso2={option.countryCode}
									size='small'
									style={{ marginRight: '12px' }}
								/>
							)}
							{option.color && (
								<ColorDot display={'inline-block'} color={option.color} />
							)}
							<Typography
								variant='regular'
								sx={{
									fontSize: '0.75rem',
								}}
								color={option.color ? option.color : 'black'}
							>
								{option.name}
							</Typography>
						</Stack>
					</StyledMenuItem>
				))}
			</StyledSelect>
		</FormControl>
	);
}

const ColorDot = styled(Box, {
	shouldForwardProp: (prop) => !(prop === 'color'),
})<{
	color: string;
}>(({ color }) => ({
	height: '10px',
	width: '10px',
	backgroundColor: color,
	borderRadius: '50%',
	marginRight: '5px',
}));

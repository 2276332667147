import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface IAuth {
	accessToken: string | null;
}

type AuthState = {
	accessToken: string | null;
	resetSessionId: string | null;
	resetPasswordToken: string | null;
};

const authSlice = createSlice({
	name: 'auth',
	initialState: { accessToken: null } as AuthState,
	reducers: {
		setCredentials: (state, action: PayloadAction<IAuth>) => {
			const { accessToken } = action.payload;
			state.accessToken = accessToken;
		},
		logOut: (state) => {
			state.accessToken = null;
		},
		setResetSessionId: (state, action: PayloadAction<string>) => {
			state.resetSessionId = action.payload;
		},
		setResetPasswordToken: (state, action: PayloadAction<string>) => {
			state.resetPasswordToken = action.payload;
		},
		clearResetPasswordData: (state) => {
			state.resetSessionId = null;
			state.resetPasswordToken = null;
		},
	},
});

export const {
	setCredentials,
	logOut,
	setResetSessionId,
	setResetPasswordToken,
	clearResetPasswordData,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state: RootState) => state.auth.accessToken;

export const selectResetSessionId = (state: RootState) =>
	state.auth.resetSessionId;

export const selectResetPasswordToken = (state: RootState) =>
	state.auth.resetPasswordToken;

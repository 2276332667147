import { ButtonBase, Box, styled } from '@mui/material';
import { MouseEventHandler } from 'react';

interface AppAddPhotoButtonProps {
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
	component?: string;
}

export default function AppAddPhotoButton({
	onClick,
	children,
	component,
}: React.PropsWithChildren<AppAddPhotoButtonProps>) {
	return (
		<>
			{component && (
				<ButtonBase
					component='label'
					sx={{
						margin: 'auto',
						marginBottom: '1.5rem',
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						height: '100%',
					}}
				>
					<StyledAddButton>{children}</StyledAddButton>
				</ButtonBase>
			)}

			{onClick && (
				<ButtonBase onClick={onClick}>
					<StyledAddButton>{children}</StyledAddButton>
				</ButtonBase>
			)}
		</>
	);
}

const StyledAddButton = styled(Box)(({ theme }) => ({
	color: '#32A5F1',
	fontSize: '12px',
	display: 'flex',
	alignItems: 'center',
	gap: '5px',
	backgroundColor: '#f2faff',
	minWidth: '100%',
	justifyContent: 'center',
	border: '1px solid',
	flexDirection: 'column',
	borderRadius: '10px',
	height: '100%',
}));

import { FormCardType } from '../../../common/components/card/FormCard';
import RoomListCard from './components/RoomListCard';
import { Grid, Stack } from '@mui/material';
import RoomSupplierCard from './supplier/RoomSupplierCard';
import RoomInfoCard from './roomInfo/RoomInfoCard';
import { useParams } from 'react-router-dom';

export default function ViewRoomPage() {
	const { roomId } = useParams();

	return (
		<Grid container spacing={'20px'}>
			<Grid item style={{ width: '383px' }}>
				<Stack width={'100%'} spacing={'15px'}>
					<RoomListCard isViewRoom />
					{roomId && <RoomSupplierCard initialType={FormCardType.view} />}
				</Stack>
			</Grid>
			<Grid item xs zeroMinWidth>
				<Stack spacing={'20px'}>
					{roomId && <RoomInfoCard initialType={FormCardType.view} />}
				</Stack>
			</Grid>
		</Grid>
	);
}

import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useState } from 'react';

import ContactPointView from './ContactPointView';
import ContactPointForm from './ContactPointForm';

interface ContactPointCardProps {
	initialType: FormCardType;
}

export default function ContactPointCard({ initialType }: ContactPointCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<FormCard type={type} title={t('supplier.ContactPoint')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <ContactPointView setType={setType} />;
					case FormCardType.create:
						return <ContactPointForm type={type} setType={setType} />;
					case FormCardType.edit:
						return <ContactPointForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

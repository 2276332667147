import {
	Button,
	Divider,
	Grid,
	InputAdornment,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import AppAutocomplete from '../../../../common/components/form-inputs/AppAutocomplete';
import { ReactComponent as LinkIcon } from '../../../../assets/icon/link.svg';
import { FormCardType } from '../../../../common/components/card/FormCard';
import {
	useGetMultiLangCountriesQuery,
	useLazyGetMultiLangAreaQuery,
	useLazyGetMultiLangMajorAreasQuery,
} from '../../../../api/locationApiSlice';
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import Option from '../../../../common/types/option.type';
import { PlaceIdContext } from '../ViewPlacePage';
import {
	useGetPlaceQuery,
	useUpdatePlaceBaseInfoMutation,
} from '../../../../api/contentApiSlice';
import { Place } from '../../../../api/DTO/place.interface';
import { COORDINATES_PATTERN } from '../../../../common/constants/pattern';
import AppMultipleCheckbox from '../../../../common/components/form-inputs/AppMultipleCheckbox';
import { ReviewerOption } from '../../../../api/enum/reviewer.enum';
import AppRichTextEditor from '../../../../common/components/editor/AppRichTextEditor';

interface BaseInfoFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
	placeType: number;
}

export default function BaseInfoForm({
	type,
	setType,
	placeType,
}: BaseInfoFormProps) {
	const { t } = useTranslation();
	const placeId = useContext(PlaceIdContext);

	const formSchema = Yup.object().shape({
		baseInfo: Yup.object()
			.shape({
				nameOg: Yup.string().required(t('errorMessage.required')),
				country: Yup.object().required(t('errorMessage.pleaseSelect')),
				majorArea: Yup.object().required(t('errorMessage.pleaseSelect')),
				area: Yup.object().required(t('errorMessage.pleaseSelect')),
				address: Yup.string().required(t('errorMessage.required')),
				mapLink: Yup.string().required(t('errorMessage.required')),
				coordinatesInString: Yup.string()
					.required(t('errorMessage.required'))
					.matches(
						COORDINATES_PATTERN,
						t('errorMessage.incorrectCoordinatesFormat')
					),
			})
			.required(),
	});

	const { data: getPlaceResponse, isLoading: isGetPlaceLoading } =
		useGetPlaceQuery(placeId, {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control, watch, setValue, reset } =
		useFormContext() ??
		useForm<Place>({
			defaultValues: getPlaceResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const [updatePlaceBaseInfo, { isSuccess: isUpdatePlaceBaseInfoSuccess }] =
		useUpdatePlaceBaseInfoMutation();

	useEffect(() => {
		if (isUpdatePlaceBaseInfoSuccess) setType(FormCardType.view);
	}, [isUpdatePlaceBaseInfoSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		// convert coordinates string to object
		const coordinatesInString = data.baseInfo.coordinatesInString;
		if (coordinatesInString) {
			data.baseInfo.coordinates = {
				latitude: parseFloat(coordinatesInString.split(/,(.*)/s)[0]),
				longitude: parseFloat(coordinatesInString.split(/,(.*)/s)[1]),
			};
		}

		updatePlaceBaseInfo({
			placeId: placeId ?? '',
			body: data.baseInfo,
		});
	};

	// load page data
	const { data: getCountriesResponse } = useGetMultiLangCountriesQuery(
		undefined,
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const [getMajorAreas, { data: getMajorAreasResponse }] =
		useLazyGetMultiLangMajorAreasQuery();
	const [getAreas, { data: getAreasResponse }] = useLazyGetMultiLangAreaQuery();

	const [areaOptions, setAreaOptions] = useState<Option[]>([]);

	const [isPrefilled, setIsPrefilled] = useState<boolean>(false);

	useEffect(() => {
		setIsPrefilled(false);
		if (type === FormCardType.edit) {
			reset({
				baseInfo: {
					country: getPlaceResponse?.data.baseInfo.country,
					majorArea: getPlaceResponse?.data.baseInfo.majorArea,
					area: getPlaceResponse?.data.baseInfo.area,
					address: getPlaceResponse?.data.baseInfo.address,
					mapLink: getPlaceResponse?.data.baseInfo.mapLink,
					coordinatesInString: `${getPlaceResponse?.data.baseInfo.coordinates?.latitude}, ${getPlaceResponse?.data.baseInfo.coordinates?.longitude}`,
					nearbyRailwayStations:
						getPlaceResponse?.data.baseInfo.nearbyRailwayStations,
					reviewers: getPlaceResponse?.data.baseInfo.reviewers,
				},
			});
		}

		setIsPrefilled(true);
	}, [getPlaceResponse]);

	useEffect(() => {
		if (type === FormCardType.create) {
			const japanOption = getCountriesResponse?.data?.find(
				(i) => i.iso2 === 'JP'
			);
			if (japanOption) {
				setValue('baseInfo.country', japanOption);
			}
		}
	}, [getCountriesResponse]);

	// watch

	const watchCountry = watch('baseInfo.country');

	useEffect(() => {
		if (watchCountry) getMajorAreas(watchCountry.id);
		if (isPrefilled) setValue('baseInfo.majorArea', undefined);
		setAreaOptions([]);
	}, [watchCountry]);

	const watchMajorAreas = watch('baseInfo.majorArea');
	useEffect(() => {
		if (watchMajorAreas) getAreas(watchMajorAreas.id);
		if (isPrefilled) setValue('baseInfo.area', undefined);
	}, [watchMajorAreas]);

	useEffect(() => {
		setAreaOptions(getAreasResponse?.data ?? []);
	}, [getAreasResponse]);

	const formContent = (
		<Stack>
			{type === FormCardType.edit && (
				<>
					<AppInputLayout label={t('place.AttractionID')}>
						<NonEditableDisplayText text={getPlaceResponse?.data.baseInfo.id} />
					</AppInputLayout>

					<Divider sx={{ marginBottom: '15px' }} />
				</>
			)}

			{placeType == 0 && (
				<AppSubtitleLayout label={t('place.AttractionName')} />
			)}
			{placeType == 1 && (
				<AppSubtitleLayout label={t('place.RestaurantName')} />
			)}
			<Typography color='#4EA4D3' fontSize={'10px'} paddingBottom={'15px'}>
				{t('place.TheLanguageRemark')}
			</Typography>
			<AppTextField
				control={control}
				name='baseInfo.nameEn'
				label={t('common.English')}
				placeholder={t('placeholder.pleaseEnterEnglish')}
			/>
			<AppTextField
				control={control}
				name='baseInfo.name'
				label={t('common.TraditionalChinese')}
				placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
			/>

			<AppTextField
				control={control}
				name='baseInfo.nameOg'
				label={t('place.OriginalName')}
				required
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppTextField
				control={control}
				name='baseInfo.website'
				label={t('place.Website')}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<LinkIcon />
						</InputAdornment>
					),
				}}
			/>

			<AppTextField
				control={control}
				name='baseInfo.phoneNum'
				inputType={InputType.number}
				label={t('place.PhoneNumber')}
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppDropDownMenu
				control={control}
				name='baseInfo.country'
				label={t('place.CountryOrRegion')}
				options={getCountriesResponse?.data ?? []}
				passObjectValue={true}
				required
			/>
			<AppAutocomplete
				control={control}
				name='baseInfo.majorArea'
				label={t('place.MajorArea')}
				options={getMajorAreasResponse?.data ?? []}
				required
			/>
			<AppAutocomplete
				control={control}
				name='baseInfo.area'
				label={t('place.Area')}
				options={areaOptions}
				required
			/>

			<AppTextField
				control={control}
				name='baseInfo.address'
				label={t('place.AddressInDetailInChi')}
				placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
				required
			/>

			<AppTextField
				control={control}
				name='baseInfo.mapLink'
				label={t('place.GoogleMapLink')}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<LinkIcon />
						</InputAdornment>
					),
				}}
				required
			/>

			<AppTextField
				control={control}
				name='baseInfo.coordinatesInString'
				label={t('place.LocationCoordinates')}
				placeholder='0.000000000000000, 0.00000000000000'
				required
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppTextField
				control={control}
				name='baseInfo.description'
				label={t('place.DescriptionInChi')}
				placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
				multiline
				rows={5}
			/>

			<AppInputLayout label={t('place.RemarksIfAny')} labelAlignItemsFlexStart>
				<AppRichTextEditor control={control} name='baseInfo.remarks' />
			</AppInputLayout>

			{placeType == 1 && (
				<>
					<AppMultipleCheckbox
						control={control}
						name='baseInfo.reviewers'
						label={t('place.RecommendedBy')}
						options={ReviewerOption}
					/>
				</>
			)}

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetPlaceLoading && form}</>;
}

import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import { useGetSupplierQuery } from '../../../../api/supplierApiSlice';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import { supplierPaymentMethodOption } from '../../../../api/enum/supplierPaymentMethod.enum';
import { transactionTypeOption } from '../../../../api/enum/transactionType.enum';
import { billingCycleOption } from '../../../../api/enum/BillingCycle.enum';
import FlagLabelDisplay from '../../../../common/components/form-data-display/FlagLabelDisplay';
import { currencies } from '../../../../common/constants/currencies';

import LinkDisplayText from '../../../../common/components/form-data-display/LinkDisplayText';
import { Dispatch, SetStateAction, useContext } from 'react';
import { SupplierIdContext } from '../ViewSupplierPage';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';

export default function BaseInfoView({
    setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {

    const { t } = useTranslation();
	const supplierId = useContext(SupplierIdContext);

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getSupplierResponse } = useGetSupplierQuery(supplierId);

    return (
        <>
            <Stack position={'relative'}>

				{ability.can('create', 'Property') && (
					<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
						<UnderlinedIconButton
							icon={<EditIcon color='#32A5F1' />}
							onClick={() => {
								setType(FormCardType.edit);
							}}
						>
							{t('button.Edit')}
						</UnderlinedIconButton>
					</Box>
				)}

                <AppInputLayout label={t('supplier.SupplierID')}>
					<NonEditableDisplayText
						text={getSupplierResponse?.data.baseInfo.id}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

                <AppSubtitleLayout label={t('supplier.CompanyName')} />

				<AppInputLayout label={t('common.English')}>
					<NewDisplayText text={getSupplierResponse?.data.baseInfo.nameEn} />
				</AppInputLayout>

                <AppInputLayout label={t('common.TraditionalChinese')}>
					<NewDisplayText text={getSupplierResponse?.data.baseInfo.name} />
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

                <AppInputLayout label={t('supplier.BRNumber')}>
					<NewDisplayText text={getSupplierResponse?.data.baseInfo.brNumber} />
				</AppInputLayout>

                <AppInputLayout label={t('supplier.CompanyWebsite')}>
					<LinkDisplayText
						link={`${getSupplierResponse?.data.baseInfo.website}`}
					/>
				</AppInputLayout>

                <AppInputLayout label={t('supplier.Currency')}>
					<FlagLabelDisplay
						iso2={
							currencies.find(
								(i) => i.id === getSupplierResponse?.data.baseInfo.currency
							)?.iso2
						}
						label={
							currencies.find(
								(i) => i.id === getSupplierResponse?.data.baseInfo.currency
							)?.name
						}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

                <AppSubtitleLayout label={t('supplier.SupplierPaymentMethod')} />

                <AppInputLayout label={t('supplier.PaymentMethod')}>
                    <NewDisplayText
						text={
							supplierPaymentMethodOption.find(
								(i) => i.id === getSupplierResponse?.data.baseInfo.paymentMethod
							)?.name
						}
					/>
				</AppInputLayout>

                <AppInputLayout label={t('supplier.TransactionType')}>
                    <NewDisplayText
						text={
							transactionTypeOption.find(
								(i) => i.id === getSupplierResponse?.data.baseInfo.transactionType
							)?.name
						}
					/>
				</AppInputLayout>

                <AppInputLayout label={t('supplier.BillingCycle')}>
                    <NewDisplayText
						text={
							billingCycleOption.find(
								(i) => i.id === getSupplierResponse?.data.baseInfo.billingCycle
							)?.name
						}
					/>
				</AppInputLayout>

            </Stack>
        </>
    )
}
export enum ApiSupplierType {
	RAKUTEN = 0,
	MEITUAN = 1,
}

export const apiSupplierOptions = [
	{
		id: ApiSupplierType.RAKUTEN,
		name: 'Rakuten',
		apiSupplierIcon: ApiSupplierType.RAKUTEN,
	},
	{
		id: ApiSupplierType.MEITUAN,
		name: 'Meituan',
		apiSupplierIcon: ApiSupplierType.MEITUAN,
	},
];

import {
	Button,
	Dialog,
	Divider,
	Stack,
	styled,
	Typography,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/icon/close.svg';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal } from './modalSlice';
import { useTranslation } from 'react-i18next';

interface AppModalProps {
	title: React.ReactNode;
	content: React.ReactNode;
	actionButtonEl: React.ReactNode;
	showCancelButton?: boolean;
}

const AppModal = ({
	title,
	content,
	actionButtonEl,
	showCancelButton = true,
}: AppModalProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const handleClose = () => {
		dispatch(hideModal());
	};

	return (
		<Dialog
			sx={{
				'& .MuiDialog-paper': {
					padding: '1rem 1.75rem 1.5rem 1.75rem',
				},
			}}
			open={title != null}
		>
			<Stack direction={'row'} alignItems={'center'}>
				<DialogTitle variant='bold' sx={{ flexGrow: 1 }}>
					{title}
				</DialogTitle>
				<CloseIcon onClick={handleClose} cursor='pointer' />
			</Stack>
			<StyledDivider />
			<DialogContent variant='regular'>{content}</DialogContent>
			<Stack
				direction={'row'}
				gap={'0.75rem'}
				sx={{ marginTop: '1.5rem', marginLeft: 'auto' }}
			>
				{showCancelButton && (
					<Button variant='outlined' color='info' onClick={handleClose}>
						{t('button.Cancel')}
					</Button>
				)}

				{actionButtonEl}
			</Stack>
		</Dialog>
	);
};

const DialogTitle = styled(Typography)({
	fontSize: '1rem',
});

const DialogContent = styled(Typography)({
	fontSize: '0.875rem',
});

const StyledDivider = styled(Divider)({
	border: '1.5px solid #E4E8EA',
	marginTop: '1rem',
	marginBottom: '1.5rem',
});

export default AppModal;

import { useEffect, useRef } from 'react';
import videojs from 'video.js';

import 'video.js/dist/video-js.css';

interface IVideoPlayerProps {
	options?: {
		sources: string | { src: string; type: string }[];
		autoplay?: boolean;
		controls?: boolean;
	};
}

const initialOptions: {
	autoplay?: boolean;
	fluid?: boolean;
	controls?: boolean;
	controlBar?: boolean;
} = {
	controls: true,
	fluid: true,
	controlBar: false,
};

const VideoPlayer = ({ options }: IVideoPlayerProps) => {
	const videoNode = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		const player = videojs(videoNode.current ?? '', {
			...initialOptions,
			...options,
		}).ready(function () {
			// console.log('onPlayerReady', this);
		});
		return () => {
			if (player) {
				player.dispose();
			}
		};
	}, [options]);

	return (
		<video
			ref={videoNode}
			className='video-js vjs-show-big-play-button-on-pause'
			style={{ borderRadius: '10px' }}
		/>
	);
};

export default VideoPlayer;

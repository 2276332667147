import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	ButtonBase,
	Divider,
	Grid,
	Stack,
	Typography,
	styled,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BkFilter } from '../../../api/DTO/bk.interface';
import {
	useGetCountriesQuery,
	useLazyGetAreasQuery,
	useLazyGetMajorAreasQuery,
} from '../../../api/locationApiSlice';
import { ReactComponent as FilterIcon } from '../../../assets/icon/filter.svg';
import { ReactComponent as InvertedTriangle } from '../../../assets/icon/inverted-triangle.svg';
import {
	AppFilterChip,
	FilterChipLayout,
} from '../../../common/components/chip/AppFilterChip';
import AppDatePicker from '../../../common/components/form-inputs/AppDatePicker';
import AppISO8601DatePicker from '../../../common/components/form-inputs/AppISO8601DatePicker';
import AppSummarySearchBar from '../../../common/components/form-inputs/AppSummarySearchBar';
import AppDropDownMenu from '../../../common/components/form-inputs/archive/AppDropDownMenu';
import AppInputLabel from '../../../common/components/form-inputs/archive/AppInputLabel';
import AppFilterSectionLayout from '../../../common/components/form-layout/AppFilterSectionLayout';
import Option from '../../../common/types/option.type';
import {
	API_DATE_FORMAT,
	DISPLAY_DATE_FORMAT,
} from '../../../utils/dateHelper';

interface BkSummaryFilterSectionProps {
	isInitializedForm: boolean;
	transformedSearchParams: BkFilter;
}

export default function BkSummaryFilterSection({
	isInitializedForm,
	transformedSearchParams,
}: BkSummaryFilterSectionProps) {
	const { t } = useTranslation();
	const { control, watch, resetField, setValue } = useFormContext<BkFilter>();

	const resetFilterRHFState = () => {
		resetField('countryId');
		resetField('majorAreaId');
		resetField('areaId');
		resetField('stayPeriodStart');
		resetField('stayPeriodEnd');
		resetField('createdAtFrom');
		resetField('createdAtTo');
	};
	const clearAllFilter = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();
		resetFilterRHFState();
	};

	// location data and watch

	const { data: getCountriesResponse } = useGetCountriesQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});
	const [getMajorAreas] = useLazyGetMajorAreasQuery();
	const [getAreas] = useLazyGetAreasQuery();

	const [majorAreaOptions, setMajorAreaOptions] = useState<Option[]>([]);
	const [areaOptions, setAreaOptions] = useState<Option[]>([]);

	const watchCountryId = watch('countryId');
	const watchMajorAreaId = watch('majorAreaId');
	const watchAreaId = watch('areaId');

	const watchCreatedAtFrom = watch('createdAtFrom');
	const watchCreatedAtTo = watch('createdAtTo');

	const watchStayPeriodStart = watch('stayPeriodStart');
	const watchStayPeriodEnd = watch('stayPeriodEnd');

	useEffect(() => {
		if (watchCountryId == undefined) {
			setMajorAreaOptions([]);
		}
		if (watchCountryId) {
			const fetchMajorAreas = async () => {
				const majorAreasResult = await getMajorAreas(watchCountryId);
				setMajorAreaOptions(majorAreasResult.data?.data ?? []);
			};
			fetchMajorAreas();
		}
		if (isInitializedForm) setValue('majorAreaId', undefined);
		setAreaOptions([]);
	}, [watchCountryId]);

	useEffect(() => {
		if (watchMajorAreaId) {
			const fetchAreas = async () => {
				const areasResult = await getAreas(watchMajorAreaId);
				setAreaOptions(areasResult.data?.data ?? []);
			};
			fetchAreas();
		}
		if (isInitializedForm) setValue('areaId', undefined);
	}, [watchMajorAreaId]);

	useEffect(() => {
		if (watchCreatedAtFrom) {
			if (isInitializedForm) {
				console.log(dayjs(watchCreatedAtFrom).startOf('date').toISOString());

				setValue(
					'createdAtFrom',
					dayjs(watchCreatedAtFrom).startOf('date').toISOString()
				);
			}
		}
	}, [watchCreatedAtFrom]);

	useEffect(() => {
		if (watchCreatedAtTo) {
			if (isInitializedForm) {
				console.log(dayjs(watchCreatedAtTo).endOf('date').toISOString());
				setValue(
					'createdAtTo',
					dayjs(watchCreatedAtTo).endOf('date').toISOString()
				);
			}
		}
	}, [watchCreatedAtTo]);

	// clear buttons

	const clearLocation = () => {
		resetField('countryId');
		resetField('majorAreaId');
		resetField('areaId');
	};

	const clearCreateDate = () => {
		resetField('createdAtFrom');
		resetField('createdAtTo');
	};

	const clearStayPeriod = () => {
		resetField('stayPeriodStart');
		resetField('stayPeriodEnd');
	};

	const isLocationDirty = watchCountryId !== undefined;
	const isCreatedAtDirty = watchCreatedAtTo || watchCreatedAtFrom;
	const isStayPeriodStartDirty = watchStayPeriodStart || watchStayPeriodEnd;

	const isFilterDirty =
		isLocationDirty || isCreatedAtDirty || isStayPeriodStartDirty;

	return (
		<Stack gap={'1rem'}>
			<AppSummarySearchBar
				name='searchKey'
				control={control}
				placeholder={t('bk.SearchBarPlaceholder')}
			/>
			<Accordion disableGutters>
				<AccordionSummary
					sx={{
						padding: '0 1.25rem 0 1rem',
						'.expanded': {
							minHeight: 'auto',
							margin: '0',
						},
					}}
					expandIcon={<InvertedTriangle />}
				>
					<Box display={'flex'} width={'100%'}>
						<Stack
							sx={{ marginRight: '1rem', flexShrink: 0 }}
							direction={'row'}
							alignItems={'center'}
						>
							<StyledFilterIcon />
							<FilterHeaderText variant='bold'>
								{t('common.filter')}
							</FilterHeaderText>
						</Stack>
						<Box sx={{ width: '100%' }}>
							<FilterChipLayout>
								{transformedSearchParams.countryId && (
									<AppFilterChip
										label={t('property.CountryOrRegion')}
										value={
											getCountriesResponse?.data?.find(
												(i) => i.id === transformedSearchParams.countryId
											)?.name ?? ''
										}
									/>
								)}
								{transformedSearchParams.majorAreaId && (
									<AppFilterChip
										label={t('property.MajorArea')}
										value={
											majorAreaOptions?.find(
												(i) => i.id === transformedSearchParams.majorAreaId
											)?.name ?? ''
										}
									/>
								)}
								{transformedSearchParams.areaId && (
									<AppFilterChip
										label={t('property.Area')}
										value={
											areaOptions?.find(
												(i) => i.id === transformedSearchParams.areaId
											)?.name ?? ''
										}
									/>
								)}

								{(transformedSearchParams.stayPeriodStart ||
									transformedSearchParams.stayPeriodEnd) && (
									<AppFilterChip
										label='Stay Period'
										value={`From ${
											transformedSearchParams.stayPeriodStart
												? dayjs(
														transformedSearchParams.stayPeriodStart,
														API_DATE_FORMAT
												  ).format(DISPLAY_DATE_FORMAT)
												: '-'
										} to ${
											transformedSearchParams.stayPeriodEnd
												? dayjs(
														transformedSearchParams.stayPeriodEnd,
														API_DATE_FORMAT
												  ).format(DISPLAY_DATE_FORMAT)
												: '-'
										}`}
									/>
								)}

								{(transformedSearchParams.createdAtFrom ||
									transformedSearchParams.createdAtTo) && (
									<AppFilterChip
										label='Created Date'
										value={`From ${
											transformedSearchParams.createdAtFrom
												? dayjs(transformedSearchParams.createdAtFrom).format(
														DISPLAY_DATE_FORMAT
												  )
												: '-'
										} to ${
											transformedSearchParams.createdAtTo
												? dayjs(transformedSearchParams.createdAtTo).format(
														DISPLAY_DATE_FORMAT
												  )
												: '-'
										}`}
									/>
								)}
							</FilterChipLayout>
						</Box>
						<ClearAllButton
							sx={{ flexShrink: 0 }}
							disabled={!isFilterDirty}
							onClick={clearAllFilter}
						>
							<Typography variant='regular'>{t('common.clearAll')}</Typography>
						</ClearAllButton>
					</Box>
				</AccordionSummary>
				<AccordionDetails
					sx={{
						padding: '0',
						borderTopWidth: '1px',
						borderTopStyle: 'solid',
						borderTopColor: 'common.whisper',
						display: 'flex',
					}}
				>
					<Stack direction={'row'} flexBasis='100%'>
						<Grid container>
							<Grid item xs={3} sx={{ position: 'relative' }}>
								<AppFilterSectionLayout
									label={t('bk.Location')}
									clearDisabled={!isLocationDirty}
									clearOnClick={clearLocation}
								>
									<Stack direction={'column'} gap={'0.75rem'}>
										<AppDropDownMenu
											control={control}
											name='countryId'
											label={t('bk.CountryOrRegion')}
											options={getCountriesResponse?.data ?? []}
										/>
										<AppDropDownMenu
											control={control}
											name='majorAreaId'
											label={t('bk.MajorArea')}
											options={majorAreaOptions}
										/>
										<AppDropDownMenu
											control={control}
											name='areaId'
											label={t('bk.Area')}
											options={areaOptions}
										/>
									</Stack>
								</AppFilterSectionLayout>
								<FullHeightDivider
									orientation={'vertical'}
									flexItem={true}
									sx={{
										margin: '0',
										position: 'absolute',
										right: '0',
										top: '0',
										height: '100%',
									}}
								/>
							</Grid>
							<Grid item xs={3} sx={{ position: 'relative' }}>
								<AppFilterSectionLayout
									label={t('bk.StayPeriod')}
									clearDisabled={!isStayPeriodStartDirty}
									clearOnClick={clearStayPeriod}
								>
									<AppInputLabel label={t('common.From')} />
									<AppDatePicker
										control={control}
										name={'stayPeriodStart'}
										placeholder={t('common.StartingDate')}
									/>
									<AppInputLabel label={t('common.To')} />
									<AppDatePicker
										control={control}
										name={'stayPeriodEnd'}
										placeholder={t('common.EndingDate')}
										minimumDate={watchStayPeriodStart}
									/>
								</AppFilterSectionLayout>
								<FullHeightDivider
									orientation={'vertical'}
									flexItem={true}
									sx={{
										margin: '0',
										position: 'absolute',
										right: '0',
										top: '0',
										height: '100%',
									}}
								/>
							</Grid>
							<Grid item xs={3} sx={{ position: 'relative' }}>
								<AppFilterSectionLayout
									label={t('bk.CreatedDate')}
									clearDisabled={!isCreatedAtDirty}
									clearOnClick={clearCreateDate}
								>
									<AppInputLabel label={t('common.From')} />
									<AppISO8601DatePicker
										control={control}
										name={'createdAtFrom'}
										placeholder={t('common.StartingDate')}
									/>
									<AppInputLabel label={t('common.To')} />
									<AppISO8601DatePicker
										control={control}
										name={'createdAtTo'}
										placeholder={t('common.EndingDate')}
										minimumDate={watchCreatedAtFrom}
									/>
								</AppFilterSectionLayout>
								<FullHeightDivider
									orientation={'vertical'}
									flexItem={true}
									sx={{
										margin: '0',
										position: 'absolute',
										right: '0',
										top: '0',
										height: '100%',
									}}
								/>
							</Grid>
						</Grid>
					</Stack>
				</AccordionDetails>
			</Accordion>
			{/* <DevTool control={control} /> */}
		</Stack>
	);
}

const StyledFilterIcon = styled(FilterIcon)`
	width: 20px;
	height: 16px;
	path {
		fill: #2c2c2c;
		stroke: #2c2c2c;
	}
`;

const ClearAllButton = styled(ButtonBase)(({ theme }) => ({
	fontSize: '0.75rem',
	lineHeight: '0.9375rem',
	marginRight: '1.5rem',
	color: theme.palette.primary.main,
	textDecoration: 'underline',
	'&.Mui-disabled': {
		display: 'none',
	},
}));

const FilterHeaderText = styled(Typography)({
	marginLeft: '0.75rem',
});

const FullHeightDivider = styled(Divider)({
	margin: '-1rem 0 -1.5rem 0',
});

import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	SubmitHandler,
	useFieldArray,
	useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/system';
import CustomDialog from '../../../../../common/components/CustomDialog';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import { useEffect } from 'react';
import {
	useGetRoomSupplementsQuery,
	useUpdateRoomSupplementsMutation,
} from '../../../../../api/accommodationApiSlice';
import Option from '../../../../../common/types/option.type';
import {
	RoomSupplement,
	RoomSupplements,
} from '../../../../../api/DTO/room.interface';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';
import { RateModel } from '../../../../../api/enum/rateModel.enum';
import AppAddButton from '../../../../../common/components/form-inputs/AppAddButton';
import { ReactComponent as AddIcon } from '../../../../../assets/icon/button-add.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icon/delete.svg';
import { NONE_INVALID_DATE_PATTERN } from '../../../../../common/constants/pattern';
import AppDatePicker from '../../../../../common/components/form-inputs/AppDatePicker';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

export interface EditRoomSupplementRateDialogProps {
	roomId: string;
	roomName: string;
	rateModel: RateModel;
	open: boolean;
	onClose: (addedOption?: Option) => void;
	currency: string;
}

export default function EditRoomSupplementRateDialog({
	roomId,
	roomName,
	rateModel,
	currency,
	...props
}: EditRoomSupplementRateDialogProps) {
	const { t } = useTranslation();

	const { onClose, open } = props;

	const handleClose = () => {
		onClose();
	};

	// form

	const formSchema = Yup.object().shape({
		roomSupplements: Yup.array().of(
			Yup.object().shape({
				supplement: Yup.string().required(
					t('errorMessage.fillInZeroIfNoSupplement')
				),
				validPeriod: Yup.object().shape({
					start: Yup.string()
						.required(t('errorMessage.required'))
						.matches(
							NONE_INVALID_DATE_PATTERN,
							t('errorMessage.incorrectTime')
						),
					end: Yup.string()
						.required(t('errorMessage.required'))
						.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
						.when('start', (start: any, schema) => {
							return schema.test(
								'shouldGreaterThanStart',
								() => t('errorMessage.incorrectPeriodTime'),
								(value) =>
									!!start && dayjs(value).isSameOrAfter(dayjs(start), 'day')
							);
						}),
				}),
			})
		),
	});

	const { data: getRoomSupplementsResponse } = useGetRoomSupplementsQuery(
		{
			roomId: roomId ?? '',
			rateModel: rateModel,
		},
		{
			skip: roomId === '',
		}
	);

	const defaultValues: DefaultValues<RoomSupplements> = {
		roomSupplements: getRoomSupplementsResponse?.data ?? [],
	};

	useEffect(() => {
		setValue('roomSupplements', getRoomSupplementsResponse?.data ?? []);
	}, [rateModel, getRoomSupplementsResponse]);

	const { handleSubmit, control, setValue, reset, watch } =
		useForm<RoomSupplements>({
			defaultValues,
			resolver: yupResolver(formSchema),
		});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'roomSupplements',
	});

	const watchRoomSupplements = watch('roomSupplements');

	useEffect(() => {
		reset(defaultValues);
	}, [getRoomSupplementsResponse]);

	const [updateRoomSupplements, { isSuccess: isUpdateSuccess }] =
		useUpdateRoomSupplementsMutation();

	const onSubmit: SubmitHandler<RoomSupplements> = (data) => {
		for (let i = 0; i < data.roomSupplements.length; i++) {
			data.roomSupplements[i].rateModel = rateModel;
		}

		updateRoomSupplements({
			roomId: roomId ?? '',
			rateModel: rateModel,
			body: data.roomSupplements,
		});
	};

	useEffect(() => {
		if (isUpdateSuccess) {
			onClose();
		}
	}, [isUpdateSuccess]);

	return (
		<CustomDialog
			width={'828px'}
			title={`${roomName} - Room Supplement`}
			onClose={handleClose}
			open={open}
			content={
				<Stack>
					{watchRoomSupplements.length !== 0 && (
						<>
							<Stack spacing={'16px'}>
								{fields.map((item, index) => {
									return (
										<Box key={item.id} display={'flex'} alignItems={'center'}>
											<Typography
												fontSize={'14px'}
												fontWeight={700}
												color={'#808080'}
												width={'92px'}
												marginRight={'16px'}
												marginBottom={'15px'}
											>
												{`Supplement ${index + 1}`}
											</Typography>
											<Box width={'160px'}>
												<AppTextField
													control={control}
													name={`roomSupplements.${index}.supplement`}
													inputType={InputType.amount}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																{currency}
															</InputAdornment>
														),
													}}
												/>
											</Box>
											<Typography
												fontSize={'14px'}
												fontWeight={700}
												color={'#808080'}
												width={'86px'}
												marginLeft={'16px'}
												marginRight={'20px'}
												marginBottom={'15px'}
											>
												{`${t('mealSupplement.ValidPeriod')}*`}
											</Typography>

											<Box
												width={'328px'}
												display='grid'
												gap='8px'
												gridTemplateColumns='repeat(2, 1fr)'
											>
												<AppDatePicker
													control={control}
													name={`roomSupplements.${index}.validPeriod.start`}
												/>
												<AppDatePicker
													control={control}
													name={`roomSupplements.${index}.validPeriod.end`}
													minimumDate={watch(
														`roomSupplements.${index}.validPeriod.start`
													)}
												/>
											</Box>
											<DeleteIcon
												style={{ marginLeft: '32px', marginBottom: '15px' }}
												color='#F6403F'
												cursor={'pointer'}
												onClick={() => remove(index)}
											/>
										</Box>
									);
								})}
							</Stack>
						</>
					)}

					<Box display={'flex'} justifyContent={'center'}>
						<AppAddButton
							onClick={() => {
								append({
									supplement: '',
									validPeriod: {
										start: '',
										end: '',
									},
								});
							}}
						>
							<AddIcon />
							{t('button.Add')}
						</AppAddButton>
					</Box>
					<Button
						variant={'contained'}
						onClick={handleSubmit(onSubmit)}
						fullWidth={true}
					>
						<Typography fontSize={'10px'} fontWeight={'bolder'}>
							{t('common.Confirm')}
						</Typography>
					</Button>
				</Stack>
			}
		></CustomDialog>
	);
}

import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as ExpandMoreIcon } from '../../../../../assets/icon/expand-more.svg';
import {
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
} from '../../../../../common/components/AppAccordian';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
	useBulkUpdateRatePlanOnRequestAllotmentsMutation,
	useGetRatePlanListItemsQuery,
} from '../../../../../api/accommodationApiSlice';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppGridMultipleCheckbox from '../../../../../common/components/form-inputs/AppGridMultipleCheckbox';
import AppCounter from '../../../../../common/components/form-inputs/AppCounter';
import { RatePlanChannel } from '../../../../../api/enum/ratePlanChannel.enum';

export default function AdjustmentOnRequestAllotment() {
	const { t } = useTranslation();
	const method = useFormContext();
	const { propertyId } = useParams();

	const [submitCount, setSubmitCount] = useState(0);
	const [submitted, setSubmitted] = useState(false);

	const { data: getRatePlanListItemsResponse } = useGetRatePlanListItemsQuery({
		propertyId: propertyId ?? '',
		rateModel: method.getValues('rateModel'),
		channel: RatePlanChannel.DIRECT,
	});

	const [bulkUpdateRatePlanOnRequestAllotmentsMutation] =
		useBulkUpdateRatePlanOnRequestAllotmentsMutation();

	const defaultValues: DefaultValues<FieldValues> = {
		ratePlanIds: [],
		adjustment: 0,
	};

	const formSchema = Yup.object().shape({
		ratePlanIds: Yup.array().min(1, t('errorMessage.atLeastOneCheckbox')),
		adjustment: Yup.number().required(t('errorMessage.required')),
	});

	const adjustmentOnRequestAllotmentMethod = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const watchParentFrom = method.watch('from');
	const watchParentTo = method.watch('to');
	const watchParentDays = method.watch('days');
	const watchAdjustment =
		adjustmentOnRequestAllotmentMethod.watch('adjustment');
	const watchRatePlanIds =
		adjustmentOnRequestAllotmentMethod.watch('ratePlanIds');

	useEffect(() => {
		if (submitCount === 0) return;
		method
			.trigger()
			.then((r) => adjustmentOnRequestAllotmentMethod.trigger().then());
	}, [
		watchParentFrom,
		watchParentTo,
		watchParentDays,
		watchAdjustment,
		watchRatePlanIds,
		submitCount,
	]);

	useEffect(() => {
		if (submitted) {
			setTimeout(() => {
				setSubmitted(false);
			}, 3000);
		}
	}, [submitted]);

	const onSubmit: SubmitHandler<FieldValues> = () => {
		setSubmitCount((prev) => prev + 1);
		method.trigger().then((methodValidated) => {
			adjustmentOnRequestAllotmentMethod
				.trigger()
				.then((adjustmentOnRequestAllotmentMethodValidated) => {
					if (methodValidated && adjustmentOnRequestAllotmentMethodValidated) {
						adjustmentOnRequestAllotmentMethod.setValue(
							'days',
							method.getValues('days')
						);
						adjustmentOnRequestAllotmentMethod.setValue(
							'from',
							method.getValues('from')
						);
						adjustmentOnRequestAllotmentMethod.setValue(
							'to',
							method.getValues('to')
						);
						adjustmentOnRequestAllotmentMethod.setValue(
							'rateModel',
							method.getValues('rateModel')
						);

						bulkUpdateRatePlanOnRequestAllotmentsMutation({
							propertyId: propertyId ?? '',
							body: {
								from: adjustmentOnRequestAllotmentMethod.getValues('from'),
								to: adjustmentOnRequestAllotmentMethod.getValues('to'),
								days: adjustmentOnRequestAllotmentMethod.getValues('days'),
								ratePlanIds:
									adjustmentOnRequestAllotmentMethod.getValues('ratePlanIds'),
								adjustment:
									adjustmentOnRequestAllotmentMethod.getValues('adjustment'),
							},
						})
							.unwrap()
							.then((response) => {
								if (response.success) {
									setSubmitCount(0);
									setSubmitted(true);
								}
							});
					}
				});
		});
	};

	return (
		<StyledAccordion>
			<StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography
					color={'gray'}
					sx={{ flexGrow: 1 }}
					fontWeight={'bold'}
					fontSize={'14px'}
				>
					{t('rateAndInventory.AdjustOnRequestAllotment')}
				</Typography>
			</StyledAccordionSummary>
			<StyledAccordionDetails>
				<AppCounter
					control={adjustmentOnRequestAllotmentMethod.control}
					name={'adjustment'}
					allowNegative
				/>

				<Box>
					<Typography
						color={'gray'}
						sx={{ flexGrow: 1 }}
						fontWeight={'bold'}
						fontSize={'14px'}
					>
						{t('rateAndInventory.ApplyToFollowingRatePlans')}
					</Typography>
					<Stack
						display={'flex'}
						direction={'column'}
						alignItems={'flex-start'}
					>
						<AppGridMultipleCheckbox
							control={adjustmentOnRequestAllotmentMethod.control}
							label={''}
							options={getRatePlanListItemsResponse?.data ?? []}
							name={'ratePlanIds'}
							columns={1}
						/>
					</Stack>
				</Box>

				<Button
					variant={'contained'}
					onClick={adjustmentOnRequestAllotmentMethod.handleSubmit(onSubmit)}
					fullWidth={true}
					disabled={submitted}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{submitted ? 'Applied' : 'Confirm'}
					</Typography>
				</Button>
			</StyledAccordionDetails>
		</StyledAccordion>
	);
}

import {
	FieldValues,
	FieldPath,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import { ButtonBase, InputAdornment, InputBase, styled } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../assets/icon/search.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';

interface AppSummarySearchBarProps {
	placeholder?: string;
}

export default function AppSummarySearchBar<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	placeholder,
	...props
}: AppSummarySearchBarProps & UseControllerProps<TFieldValues, TName>) {
	const { field } = useController(props);
	const searchKey = field.value;
	const searchKeyOnChange = field.onChange;

	const [text, setText] = useState<string>(field.value);

	useEffect(() => {
		setText(searchKey);
	}, [searchKey]);

	const clearButtonOnClick = () => {
		setText('');
		searchKeyOnChange(undefined);
	};

	const searchButtonOnClick = () => {
		searchKeyOnChange(text);
	};
	return (
		<StyledOutlinedInput
			onChange={(e) => {
				setText(e.target.value);
			}}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					searchButtonOnClick();
				}
			}}
			value={text ?? ''}
			placeholder={placeholder}
			endAdornment={
				<InputAdornment position='end'>
					{searchKey && (
						<ClearButton onClick={clearButtonOnClick}>
							<CloseIcon />
						</ClearButton>
					)}
					<SearchIconButton onClick={searchButtonOnClick}>
						<SearchIcon />
					</SearchIconButton>
				</InputAdornment>
			}
		/>
	);
}

const StyledOutlinedInput = styled(InputBase)({
	height: '48px',
	padding: '0 0 0 1rem',
	width: '100%',
	fontSize: '14px',
	borderRadius: '30px',
	backgroundColor: '#FFFFFF',
	border: '1px solid #EAEAEA',
});

const ClearButton = styled(ButtonBase)({
	marginRight: '0.5rem',
	'& .MuiSvgIcon-root': {
		fontSize: '1.125rem',
	},
});

const SearchIconButton = styled(ButtonBase)({
	width: '64px',
	height: '48px',
	padding: '0',
	borderRadius: '0px 30px 30px 30px',
	background: '#4EA4D3',
});

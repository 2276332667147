import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { ReactComponent as ExpandMoreIcon } from '../../../../../assets/icon/expand-more.svg';
import {
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
} from '../../../../../common/components/AppAccordian';
import AppGridMultipleCheckbox from '../../../../../common/components/form-inputs/AppGridMultipleCheckbox';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
	useBulkUpdateRatePlanMarkupsMutation,
	useGetRatePlanListItemsQuery,
} from '../../../../../api/accommodationApiSlice';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	MarkupType,
	markupTypeOptions,
} from '../../../../../api/enum/markupType.enum';
import { useEffect, useState } from 'react';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';
import AppDropDownMenu from '../../../../../common/components/form-inputs/AppDropDownMenu';
import AppInputLayout from '../../../../../common/components/form-layout/AppInputLayout';
import { RatePlanChannel } from '../../../../../api/enum/ratePlanChannel.enum';

export default function MarkupAdjustment({sellAtOfficialRate}: {
	sellAtOfficialRate?: boolean;
}) {
	const { t } = useTranslation();
	const method = useFormContext();
	const { propertyId } = useParams();

	const [submitCount, setSubmitCount] = useState(0);
	const [submitted, setSubmitted] = useState(false);

	const { data: getRatePlanListItemsResponse } = useGetRatePlanListItemsQuery({
		propertyId: propertyId ?? '',
		rateModel: method.getValues('rateModel'),
		channel: sellAtOfficialRate ? RatePlanChannel.APISUPPLIER : undefined,
	});

	const [bulkUpdateRatePlanMarkupsMutation] =
		useBulkUpdateRatePlanMarkupsMutation();
	const defaultValues: DefaultValues<FieldValues> = {
		ratePlanIds: [],
		markup: {
			amount: '',
			type: MarkupType.PERCENTAGE,
		},
	};

	const formSchema = Yup.object().shape({
		ratePlanIds: Yup.array().min(1, t('errorMessage.atLeastOneCheckbox')),
		markup: Yup.object().shape({
			amount: Yup.string().required(t('errorMessage.required')),
		}),
	});

	const markupAdjustmentMethod = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const watchParentFrom = method.watch('from');
	const watchParentTo = method.watch('to');
	const watchParentDays = method.watch('days');
	const watchRatePlanIds = markupAdjustmentMethod.watch('ratePlanIds');
	const watchGlobalMarkup = markupAdjustmentMethod.watch('globalMarkup');
	const watchMarkup = markupAdjustmentMethod.watch('markup');

	useEffect(() => {
		if (submitCount === 0) return;
		method.trigger().then((r) => markupAdjustmentMethod.trigger().then());
	}, [
		watchParentFrom,
		watchParentTo,
		watchParentDays,
		watchRatePlanIds,
		watchGlobalMarkup,
		watchMarkup,
		submitCount,
	]);

	useEffect(() => {
		if (submitted) {
			setTimeout(() => {
				setSubmitted(false);
			}, 3000);
		}
	}, [submitted]);

	const onSubmit: SubmitHandler<FieldValues> = () => {
		setSubmitCount((prev) => prev + 1);
		method.trigger().then((methodValidated) => {
			markupAdjustmentMethod
				.trigger()
				.then((markupAdjustmentMethodValidated) => {
					if (methodValidated && markupAdjustmentMethodValidated) {
						markupAdjustmentMethod.setValue('days', method.getValues('days'));
						markupAdjustmentMethod.setValue('from', method.getValues('from'));
						markupAdjustmentMethod.setValue('to', method.getValues('to'));
						markupAdjustmentMethod.setValue(
							'rateModel',
							method.getValues('rateModel')
						);

						bulkUpdateRatePlanMarkupsMutation({
							propertyId: propertyId ?? '',
							body: {
								from: markupAdjustmentMethod.getValues('from'),
								to: markupAdjustmentMethod.getValues('to'),
								days: markupAdjustmentMethod.getValues('days'),
								ratePlanIds: markupAdjustmentMethod.getValues('ratePlanIds'),
								markup: markupAdjustmentMethod.getValues('markup'),
							},
						})
							.unwrap()
							.then((response) => {
								if (response.success) {
									setSubmitCount(0);
									setSubmitted(true);
								}
							});
					}
				});
		});
	};

	return (
		<StyledAccordion>
			<StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography
					color={'gray'}
					sx={{ flexGrow: 1 }}
					fontWeight={'bold'}
					fontSize={'14px'}
				>
					{t('rateAndInventory.AdjustMarkup')}
				</Typography>
			</StyledAccordionSummary>
			<StyledAccordionDetails>
				<AppInputLayout label={''} removeErrorPadding>
					<Grid container columnSpacing={2}>
						<Grid item xs={8}>
							<AppTextField
								inputType={InputType.amount}
								name={'markup.amount'}
								label={''}
								control={markupAdjustmentMethod.control}
							/>
						</Grid>
						<Grid item xs={4}>
							<AppDropDownMenu
								control={markupAdjustmentMethod.control}
								name={'markup.type'}
								label={''}
								options={markupTypeOptions}
								required
							/>
						</Grid>
					</Grid>
				</AppInputLayout>

				<Box sx={{ paddingTop: '5px' }}>
					<Typography
						color={'gray'}
						sx={{ flexGrow: 1 }}
						fontWeight={'bold'}
						fontSize={'14px'}
					>
						{t('rateAndInventory.ApplyToFollowingRatePlans')}
					</Typography>
					<Stack
						display={'flex'}
						direction={'column'}
						alignItems={'flex-start'}
					>
						<AppGridMultipleCheckbox
							control={markupAdjustmentMethod.control}
							label={''}
							options={getRatePlanListItemsResponse?.data ?? []}
							name={'ratePlanIds'}
							columns={1}
						/>
					</Stack>
				</Box>

				<Button
					variant={'contained'}
					onClick={markupAdjustmentMethod.handleSubmit(onSubmit)}
					fullWidth={true}
					disabled={submitted}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{submitted ? 'Applied' : 'Confirm'}
					</Typography>
				</Button>
			</StyledAccordionDetails>
		</StyledAccordion>
	);
}

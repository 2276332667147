import Option from '../../common/types/option.type';

export const hasOwnerOptions: Option[] = [
	{
		id: false,
		name: 'Individual',
	},
	{
		id: true,
		name: 'Group',
	},
];

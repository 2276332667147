import {
	Box,
	Button,
	Divider,
	Grid,
	InputAdornment,
	Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
	useFieldArray,
} from 'react-hook-form';
import AppTextField from '../../../../common/components/form-inputs/AppTextField';
import { ReactComponent as LinkIcon } from '../../../../assets/icon/link.svg';
import { FormCardType } from '../../../../common/components/card/FormCard';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import AppAddButton from '../../../../common/components/form-inputs/AppAddButton';
import { ReactComponent as AddIcon } from '../../../../assets/icon/button-add.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';

import { Place } from '../../../../api/DTO/place.interface';
import MultipleImagesWithCreditField from '../../components/MultipleImagesWithCreditField';

import {
	useGetPlaceQuery,
	useUpdatePlaceMediaMutation,
} from '../../../../api/contentApiSlice';
import { PlaceIdContext } from '../ViewPlacePage';

interface MediaFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
	placeType: number;
}

export default function MediaForm({
	type,
	setType,
	placeType,
}: MediaFormProps) {
	const { t } = useTranslation();
	const placeId = useContext(PlaceIdContext);

	const formSchema = Yup.object().shape({
		media: Yup.object()
			.shape({
				socialMedias: Yup.object().shape({
					0: Yup.array().of(
						Yup.object().shape({
							link: Yup.string().required(t('errorMessage.required')),
						})
					),
					1: Yup.array().of(
						Yup.object().shape({
							link: Yup.string().required(t('errorMessage.required')),
						})
					),
				}),
			})
			.required(),
	});

	const { data: getPlaceResponse, isLoading: isGetPlaceLoading } =
		useGetPlaceQuery(placeId, {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control } =
		useFormContext() ??
		useForm<Place>({
			defaultValues: getPlaceResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const {
		fields: InstagramFields,
		append: InstagramAppend,
		remove: InstagramRemove,
	} = useFieldArray({
		control,
		name: 'media.socialMedias.0',
	});

	const {
		fields: YoutubeFields,
		append: YoutubeAppend,
		remove: YoutubeRemove,
	} = useFieldArray({
		control,
		name: 'media.socialMedias.1',
	});

	const [updatePlaceMedia, { isSuccess: isUpdatePlaceMediaSuccess }] =
		useUpdatePlaceMediaMutation();

	useEffect(() => {
		if (isUpdatePlaceMediaSuccess) setType(FormCardType.view);
	}, [isUpdatePlaceMediaSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updatePlaceMedia({
			placeId: placeId ?? '',
			body: data.media,
		});
	};

	const formContent = (
		<Stack>
			<MultipleImagesWithCreditField control={control} name={`media.images`} />

			<Divider sx={{ marginY: '15px' }} />

			<AppSubtitleLayout label={t('place.Instagram')} />
			{InstagramFields.map((item, index) => {
				return (
					<Box
						key={item.id}
						position={'relative'}
						marginBottom='20px'
						padding='20px'
						borderRadius={'10px'}
						sx={{ background: '#f2faff' }}
					>
						<Box
							sx={{
								position: 'absolute',
								top: 10,
								right: 20,
							}}
						>
							<DeleteIcon
								color='#F6403F'
								cursor={'pointer'}
								onClick={() => InstagramRemove(index)}
							/>
						</Box>

						<AppSubtitleLayout label={t('place.Profile') + ` (${index + 1})`} />

						<AppTextField
							control={control}
							name={`media.socialMedias.0.${index}.name`}
							label={t('place.ProfileName')}
						/>

						<AppTextField
							control={control}
							name={`media.socialMedias.0.${index}.link`}
							label={t('place.ProfileLink')}
							required
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<LinkIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>
				);
			})}
			<AppAddButton
				onClick={() =>
					InstagramAppend({
						socialMedia: 0,
						name: '',
						link: '',
					})
				}
			>
				<AddIcon />
				{t('button.Add')}
			</AppAddButton>

			<Divider sx={{ marginBottom: '15px' }} />

			<AppSubtitleLayout label={t('place.Youtube')} />
			{YoutubeFields.map((item, index) => {
				return (
					<Box
						key={item.id}
						position={'relative'}
						marginBottom='20px'
						padding='20px'
						borderRadius={'10px'}
						sx={{ background: '#f2faff' }}
					>
						<Box
							sx={{
								position: 'absolute',
								top: 10,
								right: 20,
							}}
						>
							<DeleteIcon
								color='#F6403F'
								cursor={'pointer'}
								onClick={() => YoutubeRemove(index)}
							/>
						</Box>

						<AppSubtitleLayout label={t('place.Channel') + ` (${index + 1})`} />

						<AppTextField
							control={control}
							name={`media.socialMedias.1.${index}.name`}
							label={t('place.ChannelName')}
						/>

						<AppTextField
							control={control}
							name={`media.socialMedias.1.${index}.link`}
							label={t('place.ChannelLink')}
							required
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<LinkIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>
				);
			})}
			<AppAddButton
				onClick={() =>
					YoutubeAppend({
						socialMedia: 1,
						name: '',
						link: '',
					})
				}
			>
				<AddIcon />
				{t('button.Add')}
			</AppAddButton>
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetPlaceLoading && form}</>;
}

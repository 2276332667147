import { Card, CardContent, Typography, styled } from '@mui/material';

export enum FormCardType {
	create = 'create',
	edit = 'edit',
	view = 'view',
	specialEdit = 'specialEdit',
}

interface FormCardProps {
	title: string;
	type: FormCardType;
	disableContentPadding?: boolean;
}

const StyledCard = styled(Card)<{ type: FormCardType }>(({ theme, type }) => ({
	width: '100%',
	background: theme.palette.background.infoCard,
	boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
	borderRadius: 5,
	border: type === FormCardType.view ? '0px' : '1px solid #BDE5FF',
}));

const Header = styled('div')<{ type: FormCardType }>(({ type }) => ({
	width: '100%',
	background: type === FormCardType.view ? '#E4E4E4' : '#BDE5FF',
	padding: '5px 20px 5px 20px',
}));

const StyledCardContent = styled(CardContent, {
	shouldForwardProp: (prop) => prop !== 'disableContentPadding',
})<{
	disableContentPadding?: boolean;
}>(({ disableContentPadding }) => ({
	padding: disableContentPadding ? '0px !important' : '20px',
}));

export default function FormCard(
	props: React.PropsWithChildren<FormCardProps>
) {
	return (
		<StyledCard type={props.type}>
			<Header type={props.type}>
				<Typography fontSize={20} fontWeight={700}>
					{props.title}
				</Typography>
			</Header>
			<StyledCardContent disableContentPadding={props.disableContentPadding}>
				{props.children}
			</StyledCardContent>
		</StyledCard>
	);
}

import { Box, Button, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useBulkUpdateRatePlanAllotmentsMutation } from '../../../../../api/accommodationApiSlice';
import EditPopUpContainer from './EditPopUpContainer';
import { arrayOfWholeWeek } from '../../../../../api/enum/daysOfWeek';
import AppCounter from '../../../../../common/components/form-inputs/AppCounter';

interface EditRatePlanAllotmentPopUpProps {
	ratePlanId: string;
	from: string;
	to: string;
	onCloseCallBack: () => void;
}

export default function EditRatePlanAllotmentPopUp({
	ratePlanId,
	from,
	to,
	onCloseCallBack,
}: EditRatePlanAllotmentPopUpProps) {
	const { t } = useTranslation();
	const { propertyId } = useParams();

	const defaultValues: DefaultValues<FieldValues> = {
		rratePlanIds: [ratePlanId],
		adjustment: 0,
	};

	const formSchema = Yup.object().shape({
		roomIds: Yup.array().min(1, t('errorMessage.atLeastOneCheckbox')),
		adjustment: Yup.number().required(t('errorMessage.required')),
	});

	const { control, handleSubmit } = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [bulkUpdateRatePlanAllotmentsMutation] =
		useBulkUpdateRatePlanAllotmentsMutation();

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		bulkUpdateRatePlanAllotmentsMutation({
			propertyId: propertyId ?? '',
			body: {
				from: from,
				to: to,
				days: arrayOfWholeWeek,
				ratePlanIds: [ratePlanId],
				adjustment: data.adjustment,
			},
		})
			.unwrap()
			.then((response) => {
				if (response.success) {
					onCloseCallBack();
				}
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<EditPopUpContainer
				title={t('rateAndInventory.SoldorAllotment')}
				from={from}
				to={to}
				onCloseCallBack={onCloseCallBack}
			>
				<Box display='flex' justifyContent={'space-between'}>
					<Label>{t('rateAndInventory.AdjustAllotment')}</Label>
					<Box width='148px'>
						<AppCounter control={control} name={'adjustment'} allowNegative />
					</Box>
				</Box>
				<Button
					variant={'contained'}
					onClick={handleSubmit(onSubmit)}
					fullWidth={true}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{t('common.Confirm')}
					</Typography>
				</Button>
			</EditPopUpContainer>{' '}
		</form>
	);
}

const Label = styled(Typography)({
	fontSize: '12px',
});

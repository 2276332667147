import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import { Dispatch, SetStateAction, useContext } from 'react';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { useGetBlogPostQuery } from '../../../../api/contentApiSlice';
import { BlogIdContext } from '../ViewBlogPostPage';
import EditorDisplay from '../../../../common/components/editor/EditorDisplay';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../../utils/dateHelper';

export default function BlogPostView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const blogId = useContext(BlogIdContext);

	const { data: getBlogPostResponse } = useGetBlogPostQuery(blogId);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>
				<AppInputLayout label={t('blogPost.BlogPostId')}>
					<NonEditableDisplayText text={getBlogPostResponse?.data.id} />
				</AppInputLayout>
				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout label={t('blogPost.Title')}>
					<NewDisplayText text={getBlogPostResponse?.data.title} />
				</AppInputLayout>

				<AppInputLayout label={t('blogPost.Category')}>
					<NewDisplayText text={getBlogPostResponse?.data.category.name} />
				</AppInputLayout>

				<AppInputLayout label={t('blogPost.CreatedDate')}>
					<NonEditableDisplayText
						text={dayjs(getBlogPostResponse?.data.createdAt).format(
							DISPLAY_DATE_TIME_FORMAT
						)}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('blogPost.Author')}>
					<NonEditableDisplayText text={getBlogPostResponse?.data.createdBy} />
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout label={t('blogPost.ShortDescription')}>
					<NewDisplayText text={getBlogPostResponse?.data.description} />
				</AppInputLayout>

				<AppInputLayout label={t('blogPost.Content')} labelAlignItemsFlexStart>
					<EditorDisplay
						editorStateString={getBlogPostResponse?.data.content}
						images={getBlogPostResponse?.data.images}
					/>
				</AppInputLayout>
			</Stack>
		</>
	);
}

import { ButtonBase, styled, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';

interface AppAddButtonProps {
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
	component?: string;
	justifyContent?: string;
}

export default function AppAddButton({
	onClick,
	children,
	component,
	justifyContent,
}: React.PropsWithChildren<AppAddButtonProps>) {
	const customJustifyContent = justifyContent ? justifyContent : 'center';

	return (
		<>
			{component && (
				<ButtonBase
					component='label'
					sx={{
						// margin: 'auto',
						marginBottom: '1.5rem',
						display: 'flex',
						alignItems: 'center',
						justifyContent: customJustifyContent,
						width: '100%',
					}}
				>
					<StyledAddButton>{children}</StyledAddButton>
				</ButtonBase>
			)}

			{onClick && (
				<ButtonBase onClick={onClick}>
					<StyledAddButton>{children}</StyledAddButton>
				</ButtonBase>
			)}
		</>
	);
}

const StyledAddButton = styled(Typography)(({ theme }) => ({
	color: '#32A5F1',
	fontSize: '12px',
	display: 'flex',
	alignItems: 'center',
	gap: '5px',
	marginBottom: '15px',
}));

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {
	Link,
	matchPath,
	useLocation,
	useParams,
	Outlet,
} from 'react-router-dom';
import PageHeader from '../../common/components/PageHeader';
import {
	useGetPropertyQuery,
	useUpdatePropertyStatusMutation,
} from '../../api/accommodationApiSlice';
import FullScreenLoader from '../../common/components/FullScreenLoader';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '../../utils/dateHelper';
import AppStatusToggle from '../../common/components/AppStatusToggle';
import { PropertyStatus } from '../../api/enum/propertyStatus.enum';
import { PriceMode } from '../../api/enum/priceMode.enum';

function useRouteMatch(patterns: readonly string[]) {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}

	return null;
}

function PropertyTabs({
	currentTab,
	priceMode,
}: {
	currentTab?: string;
	priceMode?: PriceMode;
}) {
	const { t } = useTranslation();
	const { propertyId } = useParams();

	return (
		<Box
			sx={{
				backgroundColor: '#FFFFFF',
				borderRadius: '5px',
				boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
				padding: '0px 20px',
				marginBottom: '15px',
			}}
		>
			{currentTab && (
				<Tabs value={currentTab}>
					<Tab
						label={t('tabs.Overview')}
						value='/property/:propertyId/overview'
						to={`/property/${propertyId}/overview`}
						component={Link}
					/>
					<Tab
						label={t('tabs.RoomsAndRatePlans')}
						value='/property/:propertyId/room/*'
						to={`/property/${propertyId}/room`}
						component={Link}
					/>
					{priceMode === PriceMode.PER_PERSON && (
						<Tab
							label={t('tabs.KidsSupplement')}
							value='/property/:propertyId/kids-supplement'
							to={`/property/${propertyId}/kids-supplement`}
							component={Link}
						/>
					)}
					<Tab
						label={t('tabs.MealSupplement')}
						value='/property/:propertyId/meal-supplement/*'
						to={`/property/${propertyId}/meal-supplement`}
						component={Link}
					/>
					<Tab
						label={t('tabs.RateAndInventory')}
						value='/property/:propertyId/rate-and-inventory'
						to={`/property/${propertyId}/rate-and-inventory`}
						component={Link}
					/>
					<Tab
						label={t('tabs.Facilities')}
						value='/property/:propertyId/facility/*'
						to={`/property/${propertyId}/facility`}
						component={Link}
					/>
					<Tab
						label={t('tabs.Transportation')}
						value='/property/:propertyId/transportation'
						to={`/property/${propertyId}/transportation`}
						component={Link}
					/>
					<Tab
						label={t('tabs.Gallery')}
						value='/property/:propertyId/gallery'
						to={`/property/${propertyId}/gallery`}
						component={Link}
					/>
					<Tab
						label={t('tabs.Faq')}
						value='/property/:propertyId/faq'
						to={`/property/${propertyId}/faq`}
						component={Link}
					/>
					<Tab
						label={t('tabs.Translation')}
						value='/property/:propertyId/translation/*'
						to={`/property/${propertyId}/translation`}
						component={Link}
					/>
					<Tab
						label={t('tabs.Logging')}
						value='/property/:propertyId/logging/*'
						to={`/property/${propertyId}/logging`}
						component={Link}
					/>
				</Tabs>
			)}
		</Box>
	);
}

export default function PropertyContainer() {
	const { t } = useTranslation();
	const { propertyId } = useParams();
	const { state } = useLocation();

	const [propertySummarySearchParams, setPropertySummarySearchParams] =
		useState<string | undefined>(undefined);

	// You need to provide the routes in descendant order.
	// This means that if you have nested routes like:
	// users, users/new, users/edit.
	// Then the order should be ['users/add', 'users/edit', 'users'].
	const routeMatch = useRouteMatch([
		'/property/:propertyId/overview',
		'/property/:propertyId/room/*',
		'/property/:propertyId/faq',
		'/property/:propertyId/facility/*',
		'/property/:propertyId/translation/*',
		'/property/:propertyId/kids-supplement',
		'/property/:propertyId/meal-supplement/*',
		'/property/:propertyId/transportation',
		'/property/:propertyId/gallery',
		'/property/:propertyId/logging/*',
		'/property/:propertyId/rate-and-inventory',
	]);

	const currentTab = routeMatch?.pattern?.path;

	useEffect(() => {
		if (state?.propertySummarySearchParams) {
			setPropertySummarySearchParams(state?.propertySummarySearchParams);
		}
	}, []);

	const backPath = propertySummarySearchParams
		? `/property?${propertySummarySearchParams}`
		: '/property';

	const {
		data: getPropertyResponse,
		isLoading: isGetPropertyLoading,
		isSuccess: isGetPropertySuccess,
		isError: isGetPropertyError,
	} = useGetPropertyQuery(propertyId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	const [updatePropertyStatusMutation] = useUpdatePropertyStatusMutation();

	let pageContent;
	if (isGetPropertyLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetPropertySuccess) {
		pageContent = (
			<Box sx={{ width: '100%' }}>
				<PageHeader
					showBackButton={currentTab == '/property/:propertyId/overview'}
					backPath={backPath}
					title={getPropertyResponse.data.baseInfo.nameEn}
					subtitleJsx={
						<Typography
							fontSize='12px'
							color='#808080'
						>{`ID: ${propertyId} · ${t('common.Lastupdate')}: ${
							getPropertyResponse?.data.baseInfo.updatedAt
								? dayjs(getPropertyResponse?.data.baseInfo.updatedAt).format(
										DISPLAY_DATE_TIME_FORMAT
								  )
								: '-'
						}`}</Typography>
					}
					actionJsx={
						<AppStatusToggle
							enableI18nLabel={t('common.Online')}
							disableI18nLabel={t('common.Offline')}
							initialValue={
								getPropertyResponse?.data.baseInfo.status ===
								PropertyStatus.ACTIVE
							}
							statusOnChange={() => {
								updatePropertyStatusMutation({
									propertyId: propertyId ?? '',
									body: {
										status:
											getPropertyResponse?.data.baseInfo.status ===
											PropertyStatus.ACTIVE
												? PropertyStatus.INACTIVE
												: PropertyStatus.ACTIVE,
									},
								});
							}}
						/>
					}
				/>
				<PropertyTabs
					currentTab={currentTab}
					priceMode={getPropertyResponse?.data.supplier.priceMode}
				/>
				<Outlet />
			</Box>
		);
	} else if (isGetPropertyError) {
		pageContent = <div>API Error</div>;
	}
	return <div>{pageContent}</div>;
}

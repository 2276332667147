import Option from '../../common/types/option.type';

export enum AbkGender {
	MALE = 0,
	FEMALE = 1,
}

export const AbkGenderOptions: Option[] = [
	{
		id: AbkGender.MALE,
		name: 'Male',
	},
	{
		id: AbkGender.FEMALE,
		name: 'Female',
	},
];

import { ReactNode } from 'react';

import { Box, Stack } from '@mui/material';

interface TranslationRowProps {
	leftNode: ReactNode;
	rightNode: ReactNode;
}

export default function TranslationRow({
	leftNode,
	rightNode,
}: TranslationRowProps) {
	return (
		<Stack direction={'row'} alignItems='stretch'>
			<Box sx={{ width: '60%', padding: '0px 20px' }}>{leftNode}</Box>
			<Box
				sx={{
					width: '40%',
					padding: '0px 20px',
					backgroundColor: '#F2FAFF',
				}}
			>
				<Box>{rightNode}</Box>
			</Box>
		</Stack>
	);
}

import { Box, Divider, Grid, Stack, Typography, styled } from '@mui/material';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../common/components/buttons/UnderlinedIconButton';
import { FormCardType } from '../../../common/components/card/FormCard';
import NewDisplayText from '../../../common/components/form-data-display/NewDisplayText';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import { DISPLAY_DATE_FORMAT } from '../../../utils/dateHelper';

import { useParams } from 'react-router-dom';
import {
	useGetMealPlanSupplementQuery,
	useGetPropertyQuery,
} from '../../../api/accommodationApiSlice';
import { MarkupType } from '../../../api/enum/markupType.enum';
import { getMealTypeNameByMealType } from '../../../api/enum/mealType.enum';
import { RateModel } from '../../../api/enum/rateModel.enum';
import TextWithUnit from '../../../common/components/form-data-display/TextWithUnit';
import YesNoDisplay from '../../../common/components/form-data-display/YesNoDisplay';
import AppSubtitleLayout from '../../../common/components/form-layout/AppSubtitleLayout';

export default function MealView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const { propertyId, mealTypeId } = useParams();

	const { data: getPropertyResponse, isSuccess: isGetPropertySuccess } =
		useGetPropertyQuery(propertyId ?? '');

	const { data: getMealPlanNETTSupplementResponse, refetch: NETTRefetch } =
		useGetMealPlanSupplementQuery(
			{
				propertyId: propertyId ?? '',
				mealTypeId: mealTypeId ?? '',
				rateModelId: '0',
			},
			{
				skip: !getPropertyResponse?.data?.supplier.rateModels?.includes(
					RateModel.AGENCY
				),
			}
		);

	const {
		data: getMealPlanCommissionableSupplementResponse,
		refetch: CommissionableRefetch,
	} = useGetMealPlanSupplementQuery(
		{
			propertyId: propertyId ?? '',
			mealTypeId: mealTypeId ?? '',
			rateModelId: '1',
		},
		{
			skip: !getPropertyResponse?.data?.supplier.rateModels?.includes(
				RateModel.MERCHANT
			),
		}
	);

	useEffect(() => {
		if (
			isGetPropertySuccess &&
			getPropertyResponse?.data?.supplier.rateModels?.includes(RateModel.AGENCY)
		)
			NETTRefetch();
	}, [mealTypeId, getMealPlanNETTSupplementResponse, getPropertyResponse]);

	useEffect(() => {
		if (
			isGetPropertySuccess &&
			getPropertyResponse?.data?.supplier.rateModels?.includes(
				RateModel.MERCHANT
			)
		)
			CommissionableRefetch();
	}, [
		mealTypeId,
		getMealPlanCommissionableSupplementResponse,
		getPropertyResponse,
	]);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>
				<Grid container alignItems='stretch' spacing={''}>
					{getMealPlanNETTSupplementResponse?.data.rateModel ===
						RateModel.AGENCY && (
						<Grid
							item
							xs={
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.AGENCY
								) &&
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.MERCHANT
								)
									? 6
									: 12
							}
							display='flex'
							flexDirection={'column'}
							paddingX={'10px'}
							paddingY={'3px'}
							marginY={'-3px'}
							borderRight={
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.AGENCY
								) &&
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.MERCHANT
								)
									? '1px solid #E4E8EA'
									: 'none'
							}
						>
							<StyledRateTitle>{t('mealSupplement.NETTModel')}</StyledRateTitle>
						</Grid>
					)}

					{getMealPlanCommissionableSupplementResponse?.data.rateModel ===
						RateModel.MERCHANT && (
						<Grid
							item
							xs={
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.AGENCY
								) &&
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.MERCHANT
								)
									? 6
									: 12
							}
							display='flex'
							flexDirection={'column'}
							paddingX={'10px'}
							paddingY={'3px'}
							marginY={'-3px'}
						>
							<StyledRateTitle>
								{t('mealSupplement.CommissionableModel')}
							</StyledRateTitle>
							<AppInputLayout label={t('mealSupplement.ServiceChargeIncluded')}>
								<YesNoDisplay
									value={
										getMealPlanCommissionableSupplementResponse?.data
											.merchantConfig?.serviceChargeIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('mealSupplement.TaxIncluded')}>
								<YesNoDisplay
									value={
										getMealPlanCommissionableSupplementResponse?.data
											.merchantConfig?.taxIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('mealSupplement.CommissionLevel')}>
								<TextWithUnit
									text={`${
										getMealPlanCommissionableSupplementResponse?.data
											.merchantConfig?.commissionLevel ?? '-'
									}`}
									unit='%'
								/>
							</AppInputLayout>

							<AppInputLayout
								label={t('mealSupplement.CommissionIncludedServiceCharge')}
							>
								<YesNoDisplay
									value={
										getMealPlanCommissionableSupplementResponse?.data
											.merchantConfig?.commissionServiceChargeIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('mealSupplement.CommissionIncludedTax')}>
								<YesNoDisplay
									value={
										getMealPlanCommissionableSupplementResponse?.data
											.merchantConfig?.commissionTaxIncluded
									}
								/>
							</AppInputLayout>
						</Grid>
					)}

					{getMealPlanNETTSupplementResponse?.data.rateModel ===
						RateModel.AGENCY &&
						getMealPlanCommissionableSupplementResponse?.data.rateModel ===
							RateModel.MERCHANT && (
							<Grid
								item
								xs={
									getPropertyResponse?.data?.supplier.rateModels?.includes(
										RateModel.AGENCY
									) &&
									getPropertyResponse?.data?.supplier.rateModels?.includes(
										RateModel.MERCHANT
									)
										? 6
										: 12
								}
								display='flex'
								flexDirection={'column'}
								paddingX={'10px'}
								paddingY={'3px'}
								marginY={'-3px'}
								borderRight={
									getPropertyResponse?.data?.supplier.rateModels?.includes(
										RateModel.AGENCY
									) &&
									getPropertyResponse?.data?.supplier.rateModels?.includes(
										RateModel.MERCHANT
									)
										? '1px solid #E4E8EA'
										: 'none'
								}
							>
								<Divider sx={{ marginBottom: '15px' }} />
							</Grid>
						)}

					{getMealPlanCommissionableSupplementResponse?.data.rateModel ===
						RateModel.MERCHANT && (
						<Grid
							item
							xs={
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.AGENCY
								) &&
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.MERCHANT
								)
									? 6
									: 12
							}
							display='flex'
							flexDirection={'column'}
							paddingX={'10px'}
							paddingY={'3px'}
							marginY={'-3px'}
						>
							<Divider sx={{ marginBottom: '15px' }} />
						</Grid>
					)}

					{getMealPlanNETTSupplementResponse?.data.rateModel ===
						RateModel.AGENCY && (
						<Grid
							item
							xs={
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.AGENCY
								) &&
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.MERCHANT
								)
									? 6
									: 12
							}
							display='flex'
							flexDirection={'column'}
							paddingX={'10px'}
							paddingY={'3px'}
							marginY={'-3px'}
							borderRight={
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.AGENCY
								) &&
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.MERCHANT
								)
									? '1px solid #E4E8EA'
									: 'none'
							}
						>
							{getMealPlanNETTSupplementResponse?.data.mealSupplements.map(
								(mealItem, index) => (
									<Box key={mealItem.id}>
										<AppSubtitleLayout
											label={`${getMealTypeNameByMealType(
												Number(mealTypeId)
											)} (${index + 1})`}
										/>
										<AppInputLayout label={t('mealSupplement.Adult')}>
											<NewDisplayText
												text={
													mealItem.rateModel === RateModel.AGENCY
														? `${getMealPlanNETTSupplementResponse?.data.currency} ${mealItem.adultRate} /person`
														: '-'
												}
											/>
										</AppInputLayout>
										<AppInputLayout label={t('mealSupplement.Child')}>
											<NewDisplayText
												text={
													mealItem.rateModel === RateModel.AGENCY
														? `${getMealPlanNETTSupplementResponse?.data.currency} ${mealItem.childRate} /person`
														: '-'
												}
											/>
										</AppInputLayout>
										<AppInputLayout label={t('mealSupplement.Toddler')}>
											<NewDisplayText
												text={
													mealItem.rateModel === RateModel.AGENCY
														? `${getMealPlanNETTSupplementResponse?.data.currency} ${mealItem.toddlerRate} /person`
														: '-'
												}
											/>
										</AppInputLayout>
										<AppInputLayout label={t('mealSupplement.Infant')}>
											<NewDisplayText
												text={
													mealItem.rateModel === RateModel.AGENCY
														? `${getMealPlanNETTSupplementResponse?.data.currency} ${mealItem.infantRate} /person`
														: '-'
												}
											/>
										</AppInputLayout>
										{getMealPlanNETTSupplementResponse?.data
											.sellAtOfficialRate === false && (
											<AppInputLayout label={t('mealSupplement.MarkUp')}>
												<NewDisplayText
													text={`${
														mealItem.markup?.type === MarkupType.FIXED
															? getMealPlanNETTSupplementResponse?.data.currency
															: ''
													} ${mealItem.markup?.amount.toString() ?? ''} ${
														mealItem.markup?.type === MarkupType.PERCENTAGE
															? '%'
															: ''
													}`}
												/>
											</AppInputLayout>
										)}
										<AppInputLayout label={t('mealSupplement.ValidPeriod')}>
											<NewDisplayText
												text={
													mealItem.rateModel === RateModel.AGENCY
														? `${
																mealItem.validPeriod.start
																	? dayjs(mealItem.validPeriod.start).format(
																			DISPLAY_DATE_FORMAT
																	  )
																	: ''
														  } - ${
																mealItem.validPeriod.start
																	? dayjs(mealItem.validPeriod.end).format(
																			DISPLAY_DATE_FORMAT
																	  )
																	: ''
														  }`
														: '-'
												}
											/>
										</AppInputLayout>

										{index + 1 <
											getMealPlanNETTSupplementResponse?.data.mealSupplements
												.length && <Divider sx={{ marginBottom: '15px' }} />}
									</Box>
								)
							)}

							{getMealPlanNETTSupplementResponse?.data &&
								getMealPlanCommissionableSupplementResponse?.data &&
								getMealPlanNETTSupplementResponse?.data.mealSupplements.length <
									getMealPlanCommissionableSupplementResponse?.data
										.mealSupplements.length && (
									<>
										{getMealPlanNETTSupplementResponse?.data.mealSupplements
											.length > 0 && <Divider sx={{ marginBottom: '15px' }} />}

										{[
											...Array(
												getMealPlanCommissionableSupplementResponse?.data
													.mealSupplements.length -
													getMealPlanNETTSupplementResponse?.data
														.mealSupplements.length
											),
										].map((item, subIndex) => (
											<Box key={subIndex}>
												<AppSubtitleLayout
													label={`${getMealTypeNameByMealType(
														Number(mealTypeId)
													)} (${
														getMealPlanNETTSupplementResponse?.data
															.mealSupplements.length +
														subIndex +
														1
													})`}
												/>
												<AppInputLayout label={t('mealSupplement.Adult')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												<AppInputLayout label={t('mealSupplement.Child')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												<AppInputLayout label={t('mealSupplement.Toddler')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												<AppInputLayout label={t('mealSupplement.Infant')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												<AppInputLayout label={t('mealSupplement.MarkUp')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												<AppInputLayout label={t('mealSupplement.ValidPeriod')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												{subIndex + 1 <
													getMealPlanCommissionableSupplementResponse?.data
														.mealSupplements.length -
														getMealPlanNETTSupplementResponse?.data
															.mealSupplements.length && (
													<Divider sx={{ marginBottom: '15px' }} />
												)}
											</Box>
										))}
									</>
								)}
						</Grid>
					)}

					{getMealPlanCommissionableSupplementResponse?.data.rateModel ===
						RateModel.MERCHANT && (
						<Grid
							item
							xs={
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.AGENCY
								) &&
								getPropertyResponse?.data?.supplier.rateModels?.includes(
									RateModel.MERCHANT
								)
									? 6
									: 12
							}
							display='flex'
							flexDirection={'column'}
							paddingX={'10px'}
							paddingY={'3px'}
							marginY={'-3px'}
						>
							{getMealPlanCommissionableSupplementResponse?.data.mealSupplements.map(
								(mealItem, index) => (
									<Box key={mealItem.id}>
										{getPropertyResponse?.data?.supplier.rateModels?.includes(
											RateModel.AGENCY
										) &&
										getPropertyResponse?.data?.supplier.rateModels?.includes(
											RateModel.MERCHANT
										) ? (
											<Box height={'33px'}></Box>
										) : (
											<AppSubtitleLayout
												label={`${getMealTypeNameByMealType(
													Number(mealTypeId)
												)} (${index + 1})`}
											/>
										)}
										<AppInputLayout label={t('mealSupplement.Adult')}>
											<NewDisplayText
												text={
													mealItem.rateModel === RateModel.MERCHANT
														? `${getMealPlanCommissionableSupplementResponse?.data.currency} ${mealItem.adultRate} /person`
														: '-'
												}
											/>
										</AppInputLayout>
										<AppInputLayout label={t('mealSupplement.Child')}>
											<NewDisplayText
												text={
													mealItem.rateModel === RateModel.MERCHANT
														? `${getMealPlanCommissionableSupplementResponse?.data.currency} ${mealItem.childRate} /person`
														: '-'
												}
											/>
										</AppInputLayout>
										<AppInputLayout label={t('mealSupplement.Toddler')}>
											<NewDisplayText
												text={
													mealItem.rateModel === RateModel.MERCHANT
														? `${getMealPlanCommissionableSupplementResponse?.data.currency} ${mealItem.toddlerRate} /person`
														: '-'
												}
											/>
										</AppInputLayout>
										<AppInputLayout label={t('mealSupplement.Infant')}>
											<NewDisplayText
												text={
													mealItem.rateModel === RateModel.MERCHANT
														? `${getMealPlanCommissionableSupplementResponse?.data.currency} ${mealItem.infantRate} /person`
														: '-'
												}
											/>
										</AppInputLayout>
										<AppInputLayout label={t('mealSupplement.ValidPeriod')}>
											<NewDisplayText
												text={
													mealItem.rateModel === RateModel.MERCHANT
														? `${
																mealItem.validPeriod.start
																	? dayjs(mealItem.validPeriod.start).format(
																			DISPLAY_DATE_FORMAT
																	  )
																	: ''
														  } - ${
																mealItem.validPeriod.start
																	? dayjs(mealItem.validPeriod.end).format(
																			DISPLAY_DATE_FORMAT
																	  )
																	: ''
														  }`
														: '-'
												}
											/>
										</AppInputLayout>
										<Box height={'33px'}></Box>
										{index + 1 <
											getMealPlanCommissionableSupplementResponse?.data
												.mealSupplements.length && (
											<Divider sx={{ marginBottom: '15px' }} />
										)}
									</Box>
								)
							)}
							{getMealPlanNETTSupplementResponse?.data &&
								getMealPlanCommissionableSupplementResponse?.data &&
								getMealPlanNETTSupplementResponse?.data.mealSupplements.length >
									getMealPlanCommissionableSupplementResponse?.data
										.mealSupplements.length && (
									<>
										{getMealPlanCommissionableSupplementResponse?.data
											.mealSupplements.length > 0 && (
											<Divider sx={{ marginBottom: '15px' }} />
										)}

										{[
											...Array(
												getMealPlanNETTSupplementResponse?.data.mealSupplements
													.length -
													getMealPlanCommissionableSupplementResponse?.data
														.mealSupplements.length
											),
										].map((item, subIndex) => (
											<Box key={subIndex}>
												<Box height={'33px'}></Box>
												<AppInputLayout label={t('mealSupplement.Adult')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												<AppInputLayout label={t('mealSupplement.Child')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												<AppInputLayout label={t('mealSupplement.Toddler')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												<AppInputLayout label={t('mealSupplement.Infant')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												<AppInputLayout label={t('mealSupplement.ValidPeriod')}>
													<NewDisplayText text={'-'} />
												</AppInputLayout>
												<Box height={'33px'}></Box>
												{subIndex + 1 <
													getMealPlanNETTSupplementResponse?.data
														.mealSupplements.length -
														getMealPlanCommissionableSupplementResponse?.data
															.mealSupplements.length && (
													<Divider sx={{ marginBottom: '15px' }} />
												)}
											</Box>
										))}
									</>
								)}
						</Grid>
					)}
				</Grid>
			</Stack>
		</>
	);
}

const StyledRateTitle = styled(Typography)({
	color: '#4EA4D3',
	fontWeight: 700,
	fontSize: '16px',
	marginBottom: '20px',
});

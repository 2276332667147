import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import Counter from '../counter/Counter';
import AppInputLayout from '../form-layout/AppInputLayout';

interface AppCounterProps {
	label?: string;
	allowNegative?: boolean;
}

export default function AppCounter<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	allowNegative = false,
	required,
	...props
}: AppCounterProps & UseControllerProps<TFieldValues, TName> & TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<AppInputLayout
			label={label}
			required={required}
			errorMessage={errorMessage}
		>
			<Counter
				errorBorder={!!errorMessage}
				value={field.value}
				onChange={(value: number | undefined) => field.onChange(value)}
				allowNegative={allowNegative}
			/>
		</AppInputLayout>
	);
}

import { Chip, ChipProps, Grid, Typography } from '@mui/material';

export function FilterChipLayout({ children }: React.PropsWithChildren) {
	return (
		<>
			<Grid container rowSpacing={'8px'} columnSpacing={'8px'}>
				{children}
			</Grid>
		</>
	);
}

interface AppFilterChipProps {
	label: string;
	value: string;
}

export const AppFilterChip = ({
	label,
	value,
	...props
}: AppFilterChipProps & ChipProps) => {
	return (
		<Grid item>
			<Chip
				label={
					<>
						<Typography component={'span'}>{label}: </Typography>
						<Typography component={'span'} variant='bold'>
							{value}
						</Typography>
					</>
				}
				{...props}
			/>
		</Grid>
	);
};

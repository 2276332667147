import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	FormControlLabel,
	FormControl,
	Radio,
	RadioGroup,
	styled,
} from '@mui/material';
import AppInputLayout from '../form-layout/AppInputLayout';
import { ReactNode } from 'react';
import Option from '../../types/option.type';

interface AppRadioGroupProps {
	required?: boolean;
	label: string;
	options: Option[];
	disabledOptions?: any[];
	infoPopUp?: ReactNode;
}

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(2, 1fr)',
	row: 'row',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	'& .MuiFormControlLabel-label': {
		fontSize: '0.75rem',
		lineHeight: '0.9375rem',
		fontWeight: '450',
		marginLeft: '0.25rem',
	},
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
	color: '#BCBCBC',

	'& .MuiSvgIcon-root': {
		fontSize: 20,
	},
	'&::before': {
		content: '""',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '10px',
		height: '10px',
		borderRadius: '50%',
		backgroundColor: 'white',
	},
	'&.Mui-disabled': {
		'& .MuiSvgIcon-root': {
			backgroundColor: '#EAEAEA',
			clipPath: 'circle(40% at 50% 50%)',
			color: '#EAEAEA',
		},
	},
}));

export default function AppRadioGroup<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	options,
	required,
	disabledOptions,
	infoPopUp,
	...props
}: AppRadioGroupProps & UseControllerProps<TFieldValues, TName>) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<>
			<AppInputLayout
				label={label}
				required={required}
				errorMessage={errorMessage}
				infoPopUp={infoPopUp}
			>
				<FormControl
					sx={{ padding: '0 1rem' }}
					fullWidth
					variant='standard'
					error={!!fieldState.error?.message}
				>
					<StyledRadioGroup {...field}>
						{options.map((option) => (
							<StyledFormControlLabel
								key={option.id}
								value={option.id}
								control={<StyledRadio />}
								label={option.name}
								disabled={disabledOptions?.includes(option.id)}
							/>
						))}
					</StyledRadioGroup>
				</FormControl>
			</AppInputLayout>
		</>
	);
}

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../common/components/card/FormCard';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { LocalizedRatePlan } from '../../../api/DTO/ratePlan.interface';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import AppTextField from '../../../common/components/form-inputs/AppTextField';
import {
	useLazyGetRatePlanLocalizationQuery,
	useUpdateRatePlanLocalizationMutation,
} from '../../../api/accommodationApiSlice';
import { Locale } from '../../../api/enum/locale.enum';
import LocaleDropDownMenu from './components/LocaleDropDownMenu';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../redux-hooks';
import {
	SnackbarType,
	showSnackbar,
} from '../../../common/components/snackbar/snackbarSlice';
import TranslationRow from './components/TranslationRow';
import { RatePlanNameType } from '../../../api/enum/ratePlanNameType.enum';
import RichTextDisplay from './components/RichTextDisplay';
import AppRichTextEditor from '../../../common/components/editor/AppRichTextEditor';

export default function RatePlanTranslationCard() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { propertyId, ratePlanId } = useParams();

	const [targetLocale, setTargetLocale] = useState<Locale>(Locale.ENGLISH);

	// load and display Chinese data
	const {
		control: chiControl,
		watch: watchChi,
		reset: resetChi,
	} = useForm<LocalizedRatePlan>();
	const [getRatePlanLocalization] = useLazyGetRatePlanLocalizationQuery();

	useEffect(() => {
		const fetchChiData = async () => {
			const chiResponse = await getRatePlanLocalization({
				ratePlanId: ratePlanId ?? '',
				locale: Locale.CHINESE,
			});
			resetChi(chiResponse.data?.data);
		};
		fetchChiData();
	}, [ratePlanId]);

	const isSystemGenRatePlanName =
		watchChi('nameType') === RatePlanNameType.SYS_GEN;

	// load target locale data and set form
	const [isPrefilled, setIsPrefilled] = useState<boolean>(false);
	useEffect(() => {
		const fetchTargetLocaleData = async () => {
			setIsPrefilled(false);
			const targetLocaleResponse = await getRatePlanLocalization({
				ratePlanId: ratePlanId ?? '',
				locale: targetLocale,
			});
			reset(targetLocaleResponse.data?.data);
			setIsPrefilled(true);
		};
		fetchTargetLocaleData();
	}, [ratePlanId, targetLocale]);

	const formSchema = Yup.object().shape({
		name: Yup.string().required(t('errorMessage.required')),
	});

	const { handleSubmit, control, reset } = useForm<LocalizedRatePlan>({
		resolver: yupResolver(formSchema),
	});

	// submit form

	const [updateRatePlanLocalization, { isSuccess: isUpdateSuccess }] =
		useUpdateRatePlanLocalizationMutation();

	const onSubmit: SubmitHandler<LocalizedRatePlan> = (data) => {
		const update = async () => {
			await updateRatePlanLocalization({
				ratePlanId: ratePlanId ?? '',
				locale: targetLocale,
				body: data,
			});
		};
		update();
	};

	useEffect(() => {
		if (isUpdateSuccess) {
			dispatch(
				showSnackbar({
					snackbarType: SnackbarType.SAVE_SUCCESS,
					snackbarProps: {
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'center',
						},
						message: t('snackbar.SaveSuccessMessage'),
					},
				})
			);
		}
	}, [isUpdateSuccess]);

	return (
		<FormCard
			type={FormCardType.edit}
			title={t('translation.RatePlan')}
			disableContentPadding={true}
		>
			<TranslationRow
				leftNode={<Box sx={{ height: '20px' }} />}
				rightNode={<Box sx={{ height: '20px' }} />}
			/>
			<TranslationRow
				leftNode={
					<Box
						display='flex'
						justifyContent={'center'}
						marginLeft={'180px'}
						marginBottom={'20px'}
					>
						<Typography fontSize={'16px'}>繁體中文</Typography>
					</Box>
				}
				rightNode={
					<Box display='flex' justifyContent={'center'} marginBottom={'20px'}>
						<LocaleDropDownMenu
							value={targetLocale}
							onChangeCallback={(newValue) => {
								setTargetLocale(newValue);
							}}
						/>
					</Box>
				}
			/>
			<TranslationRow
				leftNode={
					<AppTextField
						multiline
						control={chiControl}
						name='name'
						label={
							isSystemGenRatePlanName
								? t('room.ratePlan.GeneratedRatePlanName')
								: t('room.ratePlan.CustomRatePlanName')
						}
						required={!isSystemGenRatePlanName}
						disabled
					/>
				}
				rightNode={
					<AppTextField
						multiline
						control={control}
						name='name'
						required
						disabled={isSystemGenRatePlanName}
					/>
				}
			/>
			<TranslationRow
				leftNode={<Divider sx={{ marginBottom: '15px' }} />}
				rightNode={<Divider sx={{ marginBottom: '15px' }} />}
			/>

			<TranslationRow
				leftNode={
					<AppTextField
						multiline
						control={chiControl}
						name='description'
						label={t('room.ratePlan.RatePlanDescription')}
						disabled
					/>
				}
				rightNode={
					<AppTextField
						multiline
						control={control}
						name='description'
						required
					/>
				}
			/>
			<TranslationRow
				leftNode={
					<AppInputLayout
						label={t('room.ratePlan.RatePlanUniquePolicy')}
						labelAlignItemsFlexStart
					>
						<RichTextDisplay editorStateString={watchChi('uniquePolicy')} />
					</AppInputLayout>
				}
				rightNode={
					isPrefilled && (
						<AppRichTextEditor control={control} name='uniquePolicy' />
					)
				}
			/>

			<TranslationRow
				leftNode={<Divider sx={{ marginBottom: '15px' }} />}
				rightNode={<Divider sx={{ marginBottom: '15px' }} />}
			/>

			<TranslationRow
				leftNode={
					<AppInputLayout
						label={t('room.ratePlan.SupplierInclusion')}
						labelAlignItemsFlexStart
					>
						<RichTextDisplay
							editorStateString={watchChi('supplierInclusion')}
						/>
					</AppInputLayout>
				}
				rightNode={
					isPrefilled && (
						<AppRichTextEditor control={control} name='supplierInclusion' />
					)
				}
			/>

			<TranslationRow
				leftNode={
					<AppInputLayout
						label={t('room.ratePlan.myFamiGoInclusion')}
						labelAlignItemsFlexStart
					>
						<RichTextDisplay editorStateString={watchChi('inclusion')} />
					</AppInputLayout>
				}
				rightNode={
					isPrefilled && (
						<AppRichTextEditor control={control} name='inclusion' />
					)
				}
			/>

			<TranslationRow
				leftNode={<></>}
				rightNode={
					<Stack spacing='16px' marginBottom={'25px'}>
						<Grid item xs={12}>
							<Stack direction='row-reverse' spacing='12px'>
								<Button
									type='submit'
									variant='contained'
									color='success'
									onClick={handleSubmit(onSubmit)}
								>
									{t('button.Save')}
								</Button>
								<Button
									variant='outlined'
									color='info'
									onClick={() => {
										navigate(`/property/${propertyId}/translation`);
									}}
								>
									{t('button.Discard')}
								</Button>
							</Stack>
						</Grid>
					</Stack>
				}
			/>
		</FormCard>
	);
}

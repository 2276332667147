import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../../common/components/card/FormCard';
import RatePlanBaseInfoView from './RatePlanBaseInfoView';
import RatePlanBaseInfoForm from './RatePlanBaseInfoForm';

interface RatePlanBaseInfoCardProps {
	initialType: FormCardType;
}

export default function RatePlanBaseInfoCard({
	initialType,
}: RatePlanBaseInfoCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);
	return (
		<>
			<FormCard type={type} title={t('room.ratePlan.BasicInfo')}>
				{(() => {
					switch (type) {
						case FormCardType.view:
							return <RatePlanBaseInfoView setType={setType} />;
						case FormCardType.create:
							return <RatePlanBaseInfoForm type={type} setType={setType} />;
						case FormCardType.edit:
							return <RatePlanBaseInfoForm type={type} setType={setType} />;
					}
				})()}
			</FormCard>
		</>
	);
}

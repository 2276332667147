import { Select, Stack, Typography, styled } from '@mui/material';
import { StyledMenuItem } from '../../../../common/components/styled/StyledSelect';
import { currencies } from '../../../../common/constants/currencies';
import CountryFlag from '../../../../common/components/form-data-display/CountryFlag';

interface CurrencyDropDownMenuProps {
	value: string;
	onChangeCallback: (optionId: any) => void;
}

export default function CurrencyDropDownMenu({
	value,
	onChangeCallback,
}: CurrencyDropDownMenuProps) {
	return (
		<LocaleSelect
			autoWidth
			variant='outlined'
			onChange={(e) => {
				onChangeCallback(e.target.value);
			}}
			value={value}
			MenuProps={{
				PaperProps: {
					sx: {
						borderRadius: '5px',
						boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
						'& .MuiMenuItem-root': {
							padding: '12px',
						},
						maxHeight: '250px',
					},
				},
			}}
		>
			{currencies.map((option) => (
				<StyledMenuItem key={option.id} value={option.id}>
					<Stack direction={'row'} alignItems={'center'}>
						{option.iso2 && (
							<CountryFlag
								iso2={option.iso2}
								size='small'
								style={{ marginRight: '4px' }}
							/>
						)}
						<Typography
							variant='regular'
							sx={{ fontSize: '14px', fontWeight: '700' }}
						>
							{option.id}
						</Typography>
					</Stack>
				</StyledMenuItem>
			))}
		</LocaleSelect>
	);
}

const LocaleSelect = styled(Select)(({ theme }) => ({
	display: 'flex',
	fontSize: '16px',
	height: '18px',
	color: '#2C2C2C',

	'label + &': {
		marginTop: '28px',
	},

	'& fieldset': {
		borderWidth: '0px',
	},

	':hover ': {
		borderWidth: '0px',
	},

	'&.Mui-focused fieldset': {
		borderWidth: '0px !important',
	},

	'&.Mui-error fieldset': {
		borderWidth: '0px !important',
	},
	'&.Mui-disabled': {
		background: '#F5F5F5',
	},
	'.Mui-disabled': {
		WebkitTextFillColor: '#808080',
	},

	'.MuiSelect-icon': {
		color: '#2C2C2C',
	},
}));

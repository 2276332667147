import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import RateInfoForm from './RateInfoForm';
import RateInfoView from './RateInfoView';

interface RateInfoCardProps {
	initialType: FormCardType;
	cusCurrency?: string;
	supCurrency?: string;
}

export default function RateInfoCard({
	initialType,
	cusCurrency,
	supCurrency,
}: RateInfoCardProps) {
	const { t } = useTranslation();

	return (
		<FormCard type={initialType} title={t('abk.RateInfo')}>
			{(() => {
				switch (initialType) {
					case FormCardType.view:
						return <RateInfoView />;
					case FormCardType.create:
						return (
							<RateInfoForm
								type={initialType}
								cusCurrency={cusCurrency}
								supCurrency={supCurrency}
							/>
						);
					case FormCardType.edit:
						return (
							<RateInfoForm
								type={initialType}
								cusCurrency={cusCurrency}
								supCurrency={supCurrency}
							/>
						);
					case FormCardType.specialEdit:
						return (
							<RateInfoForm
								type={initialType}
								cusCurrency={cusCurrency}
								supCurrency={supCurrency}
							/>
						);
				}
			})()}
		</FormCard>
	);
}

import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import { useGetSupplierQuery } from '../../../../api/supplierApiSlice';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';

import { Dispatch, SetStateAction, useContext } from 'react';
import { SupplierIdContext } from '../ViewSupplierPage';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';

export default function BankDetailView({
    setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {

    const { t } = useTranslation();
	const supplierId = useContext(SupplierIdContext);

	const { data: getSupplierResponse } = useGetSupplierQuery(supplierId);

    return (
        <>
            <Stack position={'relative'}>
                <Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppInputLayout label={t('supplier.RecipientName')}>
					<Box sx={{marginRight:'50px'}}>
						<NewDisplayText text={getSupplierResponse?.data.bankDetail.recipientName} />
					</Box>
				</AppInputLayout>

                <AppInputLayout label={t('supplier.RecipientAddress')}>
					<NewDisplayText text={getSupplierResponse?.data.bankDetail.recipientAddress} />
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

                <AppInputLayout label={t('supplier.BankName')}>
					<NewDisplayText text={getSupplierResponse?.data.bankDetail.name} />
				</AppInputLayout>

                <AppInputLayout label={t('supplier.BankCode')}>
					<NewDisplayText text={getSupplierResponse?.data.bankDetail.code} />
				</AppInputLayout>

                <AppInputLayout label={t('supplier.AccountNumber')}>
					<NewDisplayText text={getSupplierResponse?.data.bankDetail.accountNumber} />
				</AppInputLayout>

                <AppInputLayout label={t('supplier.SwiftCode')}>
					<NewDisplayText text={getSupplierResponse?.data.bankDetail.swiftCode} />
				</AppInputLayout>

                <AppInputLayout label={t('supplier.BankAddress')}>
					<NewDisplayText text={getSupplierResponse?.data.bankDetail.address} />
				</AppInputLayout>

                <AppInputLayout label={t('supplier.Remarks')}>
					<NewDisplayText text={getSupplierResponse?.data.bankDetail.remarks} />
				</AppInputLayout>

            </Stack>
        </>
    )
}
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableRow } from '@mui/material';
import { HTMLAttributes, useState } from 'react';
import { SectionItem } from '../../../api/DTO/page.interface';
import { propertyCategoryOptions } from '../../../api/enum/propertyCategories.enum';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/delete.svg';
import { ReactComponent as DragAndDropIcon } from '../../../assets/icon/drag-and-drop.svg';
import { ReactComponent as TagIcon } from '../../../assets/icon/tag.svg';
import AppStatusDisplay from '../../../common/components/AppStatusDisplay';
import NonEditableDisplayText from '../../../common/components/form-data-display/NonEditableDisplayText';
import { StyledTableCell } from '../../../common/components/styled/StyledLightBlueTable';
import { getPropertyStatusDisplay } from '../../../utils/propertyHelper';
import AddSectionTagDialog from './AddSectionTagDialog';

type Props = {
	control: any;
	item: SectionItem;
	index: number;
	DeleteCallBack: (index: number) => void;
} & HTMLAttributes<HTMLDivElement>;

const SortablePropertyItem = ({
	control,
	item,
	index,
	DeleteCallBack,
}: Props) => {
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({
		id: item.propertyId,
	});

	const styles = {
		height: '74px',
		transform: CSS.Transform.toString(transform),
		transition: transition || undefined,
		opacity: isDragging ? '0.4' : '1',
		backgroundColor: isDragging ? '#F5F5F5' : '',
	};

	// add tag dialog

	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const TagButtonOnClick = () => {
		setOpen(true);
	};

	return (
		<>
			<TableRow ref={setNodeRef} style={styles}>
				<StyledTableCell component='th' scope='row'>
					<DragAndDropIcon cursor={'pointer'} {...listeners} {...attributes} />
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<NonEditableDisplayText
						text={
							propertyCategoryOptions.find(
								(option) => option.id == item.category
							)?.name ?? ''
						}
					/>
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<NonEditableDisplayText text={`${item.propertyId}`} />
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<NonEditableDisplayText text={`${item.propertyName}`} />
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<AppStatusDisplay
						name={getPropertyStatusDisplay(item.status).name}
						color={getPropertyStatusDisplay(item.status).color}
						bgcolor={getPropertyStatusDisplay(item.status).backgroundColor}
					/>
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<NonEditableDisplayText text={item.tags[0]?.name ?? '-'} />
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<NonEditableDisplayText text={item.tags[1]?.name ?? '-'} />
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<NonEditableDisplayText text={item.tags[2]?.name ?? '-'} />
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<TagIcon cursor={'pointer'} onClick={() => TagButtonOnClick()} />
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<DeleteIcon
						color='#F6403F'
						cursor={'pointer'}
						onClick={() => DeleteCallBack(index)}
					/>
				</StyledTableCell>
			</TableRow>
			<AddSectionTagDialog
				open={open}
				onClose={handleClose}
				name={`items.[${index}].tags`}
				control={control}
				tags={item.tags}
				title={item.propertyName}
			/>
		</>
	);
};

export default SortablePropertyItem;

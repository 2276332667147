import {
	Alert,
	AlertTitle,
	Snackbar,
	SnackbarProps,
	styled,
} from '@mui/material';
import { useAppDispatch } from '../../../redux-hooks';
import { hideSnackbar } from './snackbarSlice';
import { ReactComponent as SaveSuccessIcon } from '../../../assets/icon/save-success.svg';
import { useTranslation } from 'react-i18next';

const SaveSuccessSnackbar = ({ sx, anchorOrigin, message }: SnackbarProps) => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const handleClose = () => {
		dispatch(hideSnackbar());
	};

	return (
		<Snackbar
			sx={sx}
			anchorOrigin={anchorOrigin}
			open={message != null}
			autoHideDuration={6000}
			message={message}
			onClose={handleClose}
		>
			<StyledAlert icon={<SaveSuccessIcon />}>
				<AlertTitle>{t('snackbar.SaveSuccess')}</AlertTitle>
				{message}
			</StyledAlert>
		</Snackbar>
	);
};

const StyledAlert = styled(Alert)({
	width: '430px',
	fontSize: '12px',
	padding: '8px 10px',
	backgroundColor: '#E3F1DC',
	borderRadius: 5,
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)',

	'& .MuiAlertTitle-root': {
		fontSize: '14px',
		lineHeight: '14px',
		color: '#4B9831',
		paddingTop: '8px',
		marginBottom: '2px',
	},

	'& .MuiAlert-icon': {
		alignItems: 'center',
		marginRight: '10px',
	},

	'& .MuiAlert-message': {
		padding: 0,
		color: '#2C2C2C',
	},
});

export default SaveSuccessSnackbar;

import Option from '../types/option.type';

export const starRatings: Option[] = [
	{
		id: 3,
		name: '3.0',
	},
	{
		id: 3.5,
		name: '3.5',
	},
	{
		id: 4,
		name: '4.0',
	},
	{
		id: 4.5,
		name: '4.5',
	},
	{
		id: 5,
		name: '5.0',
	},
	{
		id: 5.5,
		name: '5.5',
	},
];

import { Box, styled, Divider } from '@mui/material';
import AppSpaceSubtitleLayout from '../../../common/components/form-layout/AppSpaceSubtitleLayout';
import { useTranslation } from 'react-i18next';
import SpaceDisplayText from '../../../common/components/form-data-display/SpaceDisplayText';
import { getMealNameByMealType } from '../../../api/enum/mealType.enum';

interface ProfitOptionalMealBreakdownCardProps {
	data?: any;
	currency: string;
	label: string;
}

export default function ProfitOptionalMealBreakdownCard({
	data,
	currency,
	label,
}: ProfitOptionalMealBreakdownCardProps) {
	const { t } = useTranslation();
	return (
		<>
			{data !== undefined && (
				<StyledBreakdownCard>
					<AppSpaceSubtitleLayout label={label} content={currency} />
					<Box
						sx={{
							display: 'flex',
							gap: '5px',
							flexDirection: 'column',
							marginBottom: '15px',
						}}
					>
						{data.optionalMeals?.map((dataItem: any, dataIndex: any) => {
							return (
								<SpaceDisplayText
									key={dataIndex}
									text1={`${t('bk.Meal')} (${getMealNameByMealType(
										dataItem.mealType
									)})`}
									text2={dataItem.profit.toString()}
								/>
							);
						})}
					</Box>
					<Divider sx={{ marginBottom: '15px' }} />

					<AppSpaceSubtitleLayout
						label={`${label}'s ${t('bk.Profit')}`}
						content={data.total.toString()}
						color={'#4EA4D3'}
					/>
				</StyledBreakdownCard>
			)}
		</>
	);
}

const StyledBreakdownCard = styled(Box)({
	backgroundColor: '#F5F5F5',
	padding: '20px ',
	paddingBottom: '0',
	borderRadius: '10px',
	marginBottom: '20px',
});

import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetCustomFaqSummaryQuery } from '../../../../api/accommodationApiSlice';

import FaqDisplayText from '../../../../common/components/form-data-display/FaqDisplayText';
import { FormCardType } from '../../../../common/components/card/FormCard';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icon/button-add.svg';
import AppAddButton from '../../../../common/components/form-inputs/AppAddButton';
import { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

export const FaqIdContext = createContext('');

export default function CustomFaqView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { propertyId } = useParams();
	const { t } = useTranslation();

	const { data: getFaqSummaryResponse } = useGetCustomFaqSummaryQuery(
		propertyId ?? ''
	);

	return (
		<>
			<Stack position={'relative'}>
				{getFaqSummaryResponse?.data.map((item, index) => {
					return (
						<Box key={item.id}>
							<FaqDisplayText
								label={t('faq.CustomQuestion')}
								index={index}
								question={getFaqSummaryResponse?.data[index].question}
								answer={getFaqSummaryResponse?.data[index].answer}
							/>
						</Box>
					);
				})}

				{getFaqSummaryResponse?.data.length ? (
					<Box sx={{ position: 'absolute', top: -30, right: 0 }}>
						<UnderlinedIconButton
							icon={<EditIcon color='#32A5F1' />}
							onClick={() => {
								setType(FormCardType.edit);
							}}
						>
							{t('button.Edit')}
						</UnderlinedIconButton>
					</Box>
				) : (
					<Box sx={{ position: 'absolute', top: -30, right: 0 }}>
						<AppAddButton
							onClick={() => {
								setType(FormCardType.edit);
							}}
						>
							<AddIcon />
							{t('button.Add')}
						</AppAddButton>
					</Box>
				)}
			</Stack>
		</>
	);
}

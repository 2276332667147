import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Link,
	Outlet,
	matchPath,
	useLocation,
	useParams,
} from 'react-router-dom';
import {
	useGetPromotionQuery,
	useUpdatePromotionStatusMutation,
} from '../../api/promotionApiSlice';
import FullScreenLoader from '../../common/components/FullScreenLoader';
import PageHeader from '../../common/components/PageHeader';
import { DISPLAY_DATE_TIME_FORMAT } from '../../utils/dateHelper';
import AppStatusToggle from '../../common/components/AppStatusToggle';
import { PromotionStatus } from '../../api/enum/promotionStatus.enum';

function useRouteMatch(patterns: readonly string[]) {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}

	return null;
}

function PromotionTabs({ currentTab }: { currentTab?: string }) {
	const { t } = useTranslation();
	const { promotionId } = useParams();

	return (
		<Box
			sx={{
				backgroundColor: '#FFFFFF',
				borderRadius: '5px',
				boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
				padding: '0px 20px',
				marginBottom: '15px',
			}}
		>
			{currentTab && (
				<Tabs value={currentTab}>
					<Tab
						label={t('tabs.Overview')}
						value='/promotion/:promotionId/overview'
						to={`/promotion/${promotionId}/overview`}
						component={Link}
					/>
				</Tabs>
			)}
		</Box>
	);
}

export default function PromotionContainer() {
	const { t } = useTranslation();
	const { promotionId } = useParams();
	const { state } = useLocation();

	const [promotionSummarySearchParams, setPromotionSummarySearchParams] =
		useState<string | undefined>(undefined);

	// You need to provide the routes in descendant order.
	// This means that if you have nested routes like:
	// users, users/new, users/edit.
	// Then the order should be ['users/add', 'users/edit', 'users'].
	const routeMatch = useRouteMatch(['/promotion/:promotionId/overview']);

	const currentTab = routeMatch?.pattern?.path;

	useEffect(() => {
		if (state?.promotionSummarySearchParams) {
			setPromotionSummarySearchParams(state?.promotionSummarySearchParams);
		}
	}, []);

	const backPath = promotionSummarySearchParams
		? `/promotion?${promotionSummarySearchParams}`
		: '/promotion';

	const {
		data: getPromotionResponse,
		isLoading: isGetPromotionLoading,
		isSuccess: isGetPromotionSuccess,
		isError: isGetPromotionError,
	} = useGetPromotionQuery(promotionId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	const [updatePromotionStatusMutation] = useUpdatePromotionStatusMutation();

	let pageContent;
	if (isGetPromotionLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetPromotionSuccess) {
		pageContent = (
			<Box sx={{ width: '100%' }}>
				<PageHeader
					showBackButton={currentTab == '/promotion/:promotionId/overview'}
					backPath={backPath}
					title={getPromotionResponse.data.id ?? '-'}
					subtitleJsx={
						<Typography fontSize='12px' color='#808080'>{`${t(
							'common.Lastupdate'
						)}: ${
							getPromotionResponse?.data.updatedAt
								? dayjs(getPromotionResponse?.data.updatedAt).format(
										DISPLAY_DATE_TIME_FORMAT
								  )
								: '-'
						}`}</Typography>
					}
					actionJsx={
						<AppStatusToggle
							enableI18nLabel={t('common.Online')}
							disableI18nLabel={t('common.Offline')}
							initialValue={
								getPromotionResponse?.data.status === PromotionStatus.ACTIVE
							}
							statusOnChange={() => {
								updatePromotionStatusMutation({
									promotionId: promotionId ?? '',
									body: {
										status:
											getPromotionResponse?.data.status ===
											PromotionStatus.ACTIVE
												? PromotionStatus.INACTIVE
												: PromotionStatus.ACTIVE,
									},
								});
							}}
						/>
					}
				/>
				<PromotionTabs currentTab={currentTab} />
				<Outlet />
			</Box>
		);
	} else if (isGetPromotionError) {
		pageContent = <div>API Error</div>;
	}
	return <div>{pageContent}</div>;
}

import { useTranslation } from 'react-i18next';

export enum RatePlanNameType {
	SYS_GEN = 0,
	CUSTOM = 1,
}

export function getRatePlanNameTypeLabel(type?: RatePlanNameType): string {
	const { t } = useTranslation();

	switch (type) {
		case RatePlanNameType.SYS_GEN:
			return t('room.ratePlan.SetByDefault');
		case RatePlanNameType.CUSTOM:
			return t('room.ratePlan.Customize');
		default:
			return '';
	}
}

export const ratePlanTypeOptions = [
	{
		id: RatePlanNameType.SYS_GEN,
		name: 'Set by Default',
	},
	{
		id: RatePlanNameType.CUSTOM,
		name: 'Customize',
	},
];

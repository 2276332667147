import { Table, TableBody, TableCell, TableHead, styled } from '@mui/material';

export const StyledTableCell = styled(TableCell)({
	borderBottom: 'none',
});

export const StyledTable = styled(Table)({
	overflow: 'hidden',
	color: 'red',
	'& .MuiTable-root': {
		borderColor: 'red',
		color: 'red',
	},
});

export const StyledTableHead = styled(TableHead)({
	background: '#F2FAFF',
	'.MuiTableCell-root': {
		padding: '9px 15px',
		lineHeight: '12px',
		color: '#32A5F1',
		fontSize: '12px',
		borderColor: '#32A5F1',
	},
});

export const StyledTableBody = styled(TableBody)({
	'.MuiTableCell-root': {
		padding: '10px 15px',
	},
});

import Option from '../../common/types/option.type';
import { ReactComponent as FireIcon } from '../../assets/icon/fire.svg';
import { ReactComponent as MountainIcon } from '../../assets/icon/mountain.svg';
import { ReactComponent as GarageIcon } from '../../assets/icon/garage.svg';
import { ReactComponent as FujiIcon } from '../../assets/icon/fuji.svg';
import { ReactComponent as SightseeingIcon } from '../../assets/icon/sightseeing.svg';
import { ReactComponent as SimpleHouseIcon } from '../../assets/icon/simpleHouse.svg';
import { ReactComponent as LuxuryHouseIcon } from '../../assets/icon/luxuryHouse.svg';
import { ReactComponent as ShoppingBagIcon } from '../../assets/icon/shoppingBag.svg';
import { ReactComponent as MultiplePeopleIcon } from '../../assets/icon/multPeople.svg';
import { ReactComponent as KominkaIcon } from '../../assets/icon/kominka.svg';
import { ReactComponent as LazyChoiceIcon } from '../../assets/icon/lazyChoice.svg';
import { ReactComponent as RoadTripIcon } from '../../assets/icon/roadtrip.svg';
import { ReactComponent as TourIcon } from '../../assets/icon/tour.svg';
import { ReactComponent as StudyTripIcon } from '../../assets/icon/studyTrip.svg';

export enum PageIcon {
	FIRE = 75,
	MOUNTAINS = 76,
	GARAGE = 77,
	GLAMPING = 78,
	FUJI = 79,
	SIGHTSEEING = 80,
	SIMPLE_HOUSE = 81,
	LUXURY_HOUSE = 82,
	HOT_SPRING = 83,
	SHOPPING_BAG = 84,
	MULT_PEOPLE = 85,
	KOMINKA = 86,
	LAZY_CHOICE = 87,
	ROAD_TRIP = 88,
	TOUR = 89,
	STUDY_TRIP = 90,
}

export const pageIconOptions: Option[] = [
	{
		id: PageIcon.FIRE,
		name: 'Fire',
		startIcon: <FireIcon height={'100%'} width={'16px'} color='#4D4D4D' />,
	},
	{
		id: PageIcon.MOUNTAINS,
		name: 'Mountain',
		startIcon: <MountainIcon height={'100%'} width={'16px'} color='#4D4D4D' />,
	},
	{
		id: PageIcon.GARAGE,
		name: 'Garage',
		startIcon: <GarageIcon height={'100%'} width={'16px'} color='#4D4D4D' />,
	},
	{
		id: PageIcon.FUJI,
		name: 'Fuji',
		startIcon: <FujiIcon height={'100%'} width={'16px'} color='#4D4D4D' />,
	},
	{
		id: PageIcon.SIGHTSEEING,
		name: 'Sightseeing',
		startIcon: (
			<SightseeingIcon height={'100%'} width={'16px'} color='#4D4D4D' />
		),
	},
	{
		id: PageIcon.SIMPLE_HOUSE,
		name: 'Simple House',
		startIcon: (
			<SimpleHouseIcon height={'100%'} width={'16px'} color='#4D4D4D' />
		),
	},
	{
		id: PageIcon.LUXURY_HOUSE,
		name: 'Luxury House',
		startIcon: (
			<LuxuryHouseIcon height={'100%'} width={'16px'} color='#4D4D4D' />
		),
	},
	{
		id: PageIcon.SHOPPING_BAG,
		name: 'Shopping Bag',
		startIcon: (
			<ShoppingBagIcon height={'100%'} width={'16px'} color='#4D4D4D' />
		),
	},
	{
		id: PageIcon.MULT_PEOPLE,
		name: 'Multiple People',
		startIcon: (
			<MultiplePeopleIcon height={'100%'} width={'16px'} color='#4D4D4D' />
		),
	},
	{
		id: PageIcon.KOMINKA,
		name: 'Kominka',
		startIcon: <KominkaIcon height={'100%'} width={'16px'} color='#4D4D4D' />,
	},
	{
		id: PageIcon.LAZY_CHOICE,
		name: 'Lazy Choice',
		startIcon: (
			<LazyChoiceIcon height={'100%'} width={'16px'} color='#4D4D4D' />
		),
	},
	{
		id: PageIcon.ROAD_TRIP,
		name: 'Road Trip',
		startIcon: <RoadTripIcon height={'100%'} width={'16px'} color='#4D4D4D' />,
	},
	{
		id: PageIcon.TOUR,
		name: 'Tour',
		startIcon: <TourIcon height={'100%'} width={'16px'} color='#4D4D4D' />,
	},
	{
		id: PageIcon.STUDY_TRIP,
		name: 'Study Trip',
		startIcon: <StudyTripIcon height={'100%'} width={'16px'} color='#4D4D4D' />,
	},
];

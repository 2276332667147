import { useTranslation } from 'react-i18next';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/system';
import CustomDialog from '../../../../../common/components/CustomDialog';
import {
	Box,
	Button,
	InputAdornment,
	Tooltip,
	Typography,
} from '@mui/material';
import { useEffect } from 'react';
import {
	useGetRoomQuery,
	useUpdateRoomSupplierMutation,
} from '../../../../../api/accommodationApiSlice';
import Option from '../../../../../common/types/option.type';
import { Room } from '../../../../../api/DTO/room.interface';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';
import { ReactComponent as CircleInfoIcon } from '../../../../../assets/icon/circleInfo.svg';

export interface EditRoomMinimumSellingRateDialogProps {
	roomId: string;
	open: boolean;
	onClose: (addedOption?: Option) => void;
	currency: string;
}

export default function EditRoomMinimumSellingRateDialog({
	roomId,
	currency,
	...props
}: EditRoomMinimumSellingRateDialogProps) {
	const { t } = useTranslation();

	const { onClose, open } = props;

	const handleClose = () => {
		onClose();
	};

	// form

	const formSchema = Yup.object().shape({
		supplier: Yup.object()
			.shape({
				minPrice: Yup.string().required(t('errorMessage.required')),
			})
			.required(),
	});

	const { data: getRoomResponse } = useGetRoomQuery(roomId ?? '', {
		skip: roomId === '',
	});

	const { handleSubmit, control, reset } = useForm<Room>({
		defaultValues: getRoomResponse?.data,
		resolver: yupResolver(formSchema),
	});

	useEffect(() => {
		reset(getRoomResponse?.data);
	}, [getRoomResponse]);

	const [updateRoomSupplier, { isSuccess: isUpdateRoomSupplierSuccess }] =
		useUpdateRoomSupplierMutation();

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updateRoomSupplier({
			roomId: roomId ?? '',
			body: data.supplier,
		});
	};

	useEffect(() => {
		if (isUpdateRoomSupplierSuccess) {
			onClose();
		}
	}, [isUpdateRoomSupplierSuccess]);

	return (
		<CustomDialog
			title={`${getRoomResponse?.data.info.nameEn} - ${t(
				'room.MinimumSellingRate'
			)}`}
			onClose={handleClose}
			open={open}
			content={
				<Stack>
					<Box>
						<Stack
							direction={'row'}
							alignItems={'center'}
							spacing='8px'
							marginBottom={'12px'}
						>
							<Typography fontSize={'14px'} color={'#808080'}>
								{t('room.MinimumSellingRate')}
								<Box component={'span'} sx={{ marginLeft: '5px' }}>
									<Tooltip title={' '} placement='bottom-start'>
										<CircleInfoIcon />
									</Tooltip>
								</Box>
							</Typography>
						</Stack>
					</Box>

					<AppTextField
						control={control}
						name='supplier.minPrice'
						inputType={InputType.amount}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>{currency}</InputAdornment>
							),
						}}
						required
					/>
					<Button
						variant={'contained'}
						onClick={handleSubmit(onSubmit)}
						fullWidth={true}
					>
						<Typography fontSize={'10px'} fontWeight={'bolder'}>
							{t('common.Confirm')}
						</Typography>
					</Button>
				</Stack>
			}
		></CustomDialog>
	);
}

import { useParams } from 'react-router-dom';
import {
	useDeletePropertyVideoMutation,
	useGetPropertyImagesQuery,
	useGetPropertyVideoQuery,
	useUploadPropertyVideoMutation,
} from '../../../../api/accommodationApiSlice';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { Box, Divider, Stack, Typography } from '@mui/material';
import PropertyImageListSection from '../component/PropertyImageListSection';
import FullScreenLoader from '../../../../common/components/FullScreenLoader';
import { t } from 'i18next';
import AppAddPhotoButton from '../../../../common/components/form-inputs/AppAddPhotoButton';
import { ReactComponent as AddIcon } from '../../../../assets/icon/button-add.svg';
import { UploadVideo } from '../../../../api/DTO/gallery.interface';
import styled from '@emotion/styled';
import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';
import VideoPlayer from '../component/VideoJs';
import AppAlert from '../../../../common/components/AppAlert';
import { useAppDispatch } from '../../../../redux-hooks';
import {
	showModal,
	hideModal,
} from '../../../../common/components/modal/modalSlice';
export default function PropertyFixedForm() {
	const { propertyId } = useParams();
	const dispatch = useAppDispatch();

	const {
		data: getPropertyImagesResponse,
		isLoading: isGetPropertyImagesLoading,
		isSuccess: isGetPropertyImagesSuccess,
		isError: isGetPropertyImagesError,
	} = useGetPropertyImagesQuery(propertyId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	const { data: getPropertyVideoResponse } = useGetPropertyVideoQuery(
		propertyId ?? '',
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const [useUploadPropertyVideo] = useUploadPropertyVideoMutation();

	const [deletePropertyVideo] = useDeletePropertyVideoMutation();

	function onUploadFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		const target = event.target;
		const file = target.files?.[0];

		if (file) {
			const videoBody: UploadVideo = {
				file: file,
			};
			useUploadPropertyVideo({
				propertyId: propertyId ?? '',
				body: videoBody,
			});
		}
	}

	function handleDeleteClick(id: string) {
		deletePropertyVideo(id).then((res: any) => {
			if (res.data.success == true) {
				dispatch(hideModal());
			}
		});
	}

	function handleDeleteVideo(id: string) {
		dispatch(
			showModal({
				modalType: 'DELETE_GALLERY_VIDEO',
				modalProps: {
					id,
					handleDeleteClick,
				},
			})
		);
	}

	let pageContent;

	if (isGetPropertyImagesLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetPropertyImagesSuccess) {
		pageContent = (
			<>
				<Stack>
					<Box sx={{ paddingTop: '20px' }}>
						<AppSubtitleLayout label={t('gallery.CoverVideo')} />
						<Box position={'relative'} width={'240px'}>
							{getPropertyVideoResponse?.data?.url && (
								<StyledVideoHoverOverlay>
									<VideoPlayer
										options={{
											sources: [
												{
													src: getPropertyVideoResponse?.data?.url
														? getPropertyVideoResponse?.data?.url
														: '',
													type: 'video/mp4',
												},
											],
										}}
									/>
									<StyledDeleteButton
										onClick={() => handleDeleteVideo(propertyId ?? '')}
									>
										<DeleteIcon color='white' cursor={'pointer'} />
										<Typography
											sx={{
												color: 'white',
												fontSize: '12px',
												userSelect: 'none',
												boxShadow:
													'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
											}}
										>
											{t('button.Delete')}
										</Typography>
									</StyledDeleteButton>
								</StyledVideoHoverOverlay>
							)}
						</Box>
						{!getPropertyVideoResponse?.data && (
							<Box
								sx={{
									width: '240px',
									height: '135px',
									minHeight: '135px',
								}}
							>
								<AppAddPhotoButton component='label'>
									<input
										type='file'
										name='doc'
										hidden
										onChange={onUploadFileChange}
										accept='video/*'
									/>
									<Typography fontSize={'14px'} fontWeight={'700'}>
										<AddIcon />
										{t('button.AddVideo')}
									</Typography>
									<Typography fontSize={'10px'}>
										{t('place.SuggestedFormat')}
										<b>{t('gallery.VideoFileType')}</b>
									</Typography>
								</AppAddPhotoButton>
							</Box>
						)}
						<Box marginTop={'15px'}>
							<AppAlert alertColor='#32A5F1'>
								{t('gallery.VideoAlert')}
							</AppAlert>
						</Box>
						<Divider sx={{ marginY: '15px' }} />
					</Box>
					{getPropertyImagesResponse.data
						.slice(0, 2)
						.map((propertyImage, index) => {
							return (
								<Box key={index} sx={{ paddingTop: '20px' }}>
									<AppSubtitleLayout label={propertyImage.category.name} />
									<PropertyImageListSection
										image={propertyImage.images}
										section={propertyImage.section}
									/>
									{index + 1 <
										getPropertyImagesResponse.data.slice(0, 2).length && (
										<Divider sx={{ marginY: '15px' }} />
									)}
								</Box>
							);
						})}
				</Stack>
			</>
		);
	} else if (isGetPropertyImagesError) {
		pageContent = <div>API ERROR</div>;
	}

	return <div>{pageContent}</div>;
}

const StyledVideoHoverOverlay = styled(Box)(() => ({
	position: 'relative',
	userSelect: 'none',
	'&:hover': {
		'.MuiBox-root': {
			transitionDuration: '0.5s',
			opacity: 1,
		},
	},
}));

const StyledDeleteButton = styled(Box)(() => ({
	opacity: 0,

	position: 'absolute',
	bottom: '10px',
	left: '10px',
	color: 'white',
	fontSize: '14px',
	gap: '5px',
	background: '#DA5150',
	padding: '3px 10px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '5px',
	cursor: 'pointer',
}));

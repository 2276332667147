import { Button, Card, Divider, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppTextField from '../../common/components/form-inputs/archive/AppTextField';
import PageHeader from '../../common/components/PageHeader';
import { Stack } from '@mui/system';
import { SubmitHandler, DefaultValues, useForm } from 'react-hook-form';
import AppDropDownMenu from '../../common/components/form-inputs/archive/AppDropDownMenu';
import AppEmailTextField from '../../common/components/form-inputs/AppEmailTextField';
import AppPasswordTextField from '../../common/components/form-inputs/AppPasswordTextField';
import { PASSWORD_PATTERN } from '../../common/constants/pattern';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddUserMutation } from '../../api/userApiSlice';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userRoleOptions } from '../../api/enum/userRole.enum';

export type FormValues = {
	nickname: string;
	role: number;
	email: string;
	password: string;
	confirmPassword: string;
};

export const defaultValues: DefaultValues<FormValues> = {
	nickname: '',
	// role: null,
	email: '',
	password: '',
	confirmPassword: '',
};

const StyledCard = styled(Card)(({ theme }) => ({
	width: 884,
	background: theme.palette.background.infoCard,
	boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
	borderRadius: 5,
	paddingTop: 40,
	paddingLeft: 40,
	paddingRight: 40,
	paddingBottom: 23,
}));

export default function CreateUserPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();

	const [systemUserSummarySearchParams, setSystemUserSummarySearchParams] =
		useState<string | undefined>(undefined);

	useEffect(() => {
		if (state?.systemUserSummarySearchParams) {
			setSystemUserSummarySearchParams(state?.systemUserSummarySearchParams);
		}
	}, []);

	const backPath = systemUserSummarySearchParams
		? `/sys-user?${systemUserSummarySearchParams}`
		: '/sys-user';

	const formSchema = Yup.object().shape({
		nickname: Yup.string().required(t('errorMessage.required')),
		role: Yup.string().required(t('errorMessage.required')),
		email: Yup.string()
			.required(t('errorMessage.required'))
			.email(t('errorMessage.email')),
		password: Yup.string()
			.required(t('errorMessage.required'))
			.matches(PASSWORD_PATTERN, t('errorMessage.password')),
		confirmPassword: Yup.string()
			.required(t('errorMessage.required'))
			.oneOf([Yup.ref('password')], t('errorMessage.password')),
	});

	const { handleSubmit, control } = useForm<FormValues>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [addUser, { data: addUserResponse, isSuccess: isAddUserSuccess }] =
		useAddUserMutation();

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		addUser(data);
	};

	useEffect(() => {
		if (isAddUserSuccess) {
			navigate(`/sys-user/detail/${addUserResponse?.data.id}`);
		}
	}, [isAddUserSuccess]);

	return (
		<>
			<PageHeader
				showBackButton={true}
				backPath={backPath}
				title={t('systemUser.CreateUser')}
			/>
			<StyledCard>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container columnSpacing='40px' rowSpacing='24px'>
						<Grid item xs={6}>
							<AppTextField
								control={control}
								name='nickname'
								label={t('systemUser.UserName')}
								placeholder={t('systemUser.UserNamePlaceholder')}
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<AppDropDownMenu
								control={control}
								name='role'
								label={t('systemUser.Role')}
								placeholder={t('systemUser.RolePlaceholder')}
								options={userRoleOptions}
								required
								infoPopUp={
									<>
										<b>System Admin</b>
										<br />
										This role has full access and control over the admin portal.
										<br />
										<br />
										<b>Admin</b>
										<br />
										This role has full access to the admin portal, but no
										control over <b>System User</b>.
										<br />
										<br />
										<b>Customer Service Officer</b>
										<br />
										This role only has limited access to the admin portal,
										except System User. It also has the permission to view,
										create, and update in <b>Bookings</b>.
										<br />
										<br />
										<b>Data Entry Officer</b>
										<br />
										This role only has few access to the admin portal, include{' '}
										<b>Property</b>, <b>Rate & Inventory</b>, and <b>Product</b>
										. It has permission to view, create, and update details.
										<br />
										<br />
										<b>Accountant</b>
										<br />
										This role only has access to <b>Bookings</b> to view the
										details.
									</>
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={6}>
							<AppEmailTextField
								control={control}
								name='email'
								label={t('systemUser.UsersEmailAddress')}
								placeholder={t('common.TypeHere')}
								required
							/>
						</Grid>
						<Grid item xs={6}></Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={6}>
							<AppPasswordTextField
								control={control}
								name='password'
								label={t('systemUser.NewPassword')}
								placeholder={t('common.TypeHere')}
								helperText={t('systemUser.NewPasswordHelperText')}
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<AppPasswordTextField
								control={control}
								name='confirmPassword'
								label={t('systemUser.ConfirmPassword')}
								placeholder={t('common.TypeHere')}
								showCopyButton
								required
							/>
						</Grid>

						<Grid item xs={12}>
							<Stack direction='row-reverse' spacing='12px'>
								<Button type='submit' variant='contained' color='success'>
									{t('button.Create')}
								</Button>
								<Button
									variant='outlined'
									color='info'
									onClick={() => navigate(backPath)}
								>
									{t('button.Cancel')}
								</Button>
							</Stack>
						</Grid>
					</Grid>
				</form>
			</StyledCard>
		</>
	);
}

import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import { FormCardType } from '../../../../common/components/card/FormCard';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';

import { Dispatch, SetStateAction, useContext } from 'react';
import dayjs from 'dayjs';
import { DiscountType } from '../../../../api/enum/discountType.enum';
import { useGetPromotionQuery } from '../../../../api/promotionApiSlice';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { DISPLAY_DATE_FORMAT } from '../../../../utils/dateHelper';
import { PromotionIdContext } from '../ViewPromotionPage';
import SelectPropertiesDisplay from '../../../../common/components/form-data-display/SelectPropertiesDisplay';

export default function PromotionView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const promotionId = useContext(PromotionIdContext);

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getPromotionResponse } = useGetPromotionQuery(promotionId);

	return (
		<>
			<Stack position={'relative'}>
				{ability.can('create', 'Promotion') && (
					<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
						<UnderlinedIconButton
							icon={<EditIcon color='#32A5F1' />}
							onClick={() => {
								setType(FormCardType.edit);
							}}
						>
							{t('button.Edit')}
						</UnderlinedIconButton>
					</Box>
				)}

				<AppInputLayout label={t('promotion.PromotionID')}>
					<NonEditableDisplayText text={getPromotionResponse?.data.id} />
				</AppInputLayout>

				<AppInputLayout label={t('promotion.Code')}>
					<NewDisplayText text={getPromotionResponse?.data.code} />
				</AppInputLayout>
				<AppInputLayout label={t('promotion.Description')}>
					<NewDisplayText text={getPromotionResponse?.data.description} />
				</AppInputLayout>
				<AppInputLayout label={t('promotion.ValidPeriod')}>
					<NewDisplayText
						text={
							getPromotionResponse?.data.validPeriod.start &&
							getPromotionResponse?.data.validPeriod.end &&
							`${dayjs(getPromotionResponse?.data.validPeriod.start).format(
								DISPLAY_DATE_FORMAT
							)} - ${dayjs(getPromotionResponse?.data.validPeriod.end).format(
								DISPLAY_DATE_FORMAT
							)}`
						}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('promotion.Discount')}>
					<NewDisplayText
						text={`${
							getPromotionResponse?.data.discount?.type === DiscountType.FIXED
								? getPromotionResponse?.data.discount?.currency + ' '
								: ''
						}${getPromotionResponse?.data.discount?.amount.toString() ?? '-'} ${
							getPromotionResponse?.data.discount?.type ===
							DiscountType.PERCENTAGE
								? '%'
								: ''
						}`}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('promotion.Quota')}>
					<NewDisplayText text={getPromotionResponse?.data.quota.toString()} />
				</AppInputLayout>
				<SelectPropertiesDisplay
					label={t('promotion.Properties')}
					data={getPromotionResponse?.data.properties}
				/>
			</Stack>
		</>
	);
}

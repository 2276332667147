import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	Box,
	FormControl,
	ToggleButton,
	ToggleButtonGroup,
	styled,
	Typography,
} from '@mui/material';
import Option from '../../types/option.type';
import FacilityIcon from '../form-data-display/FacilityIcon';

interface AppToggleButtonGroupProps {
	formLabel?: string;
	disabled?: boolean;
	options: Option[];
	color?: string;
}

interface StyledToggleButtonProps {
	selectedbackgroundcolor: string;
	selectedcolor: string;
}

export default function AppToggleButtonGroup<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	formLabel,
	disabled,
	options,
	color,
	...props
}: AppToggleButtonGroupProps & UseControllerProps<TFieldValues, TName>) {
	const { field } = useController(props);

	return (
		<FormControl
			sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
		>
			{formLabel && (
				<Box minWidth={'17rem'} maxWidth={'270px'}>
					<Typography fontSize={14} color={color ? color : '#808080'}>
						{formLabel}
					</Typography>
				</Box>
			)}
			<Box minWidth={'17rem'} maxWidth={'270px'}>
				<StyledToggleButtonGroup
					{...field}
					color='primary'
					exclusive
					onChange={(e, value) => {
						field.onChange(value);
					}}
					value={field.value ?? ''}
					aria-label={formLabel}
					disabled={disabled}
				>
					{options.map((option) => (
						<StyledToggleButton
							key={option.id}
							value={option.id}
							selectedbackgroundcolor={option.selectedbackgroundcolor}
							selectedcolor={option.selectedcolor}
						>
							<StyledButtonContent>
								<FacilityIcon
									icon={option.icon}
									height={'20px'}
									width={'20px'}
								/>
								{option.name}
							</StyledButtonContent>
						</StyledToggleButton>
					))}
				</StyledToggleButtonGroup>
			</Box>
		</FormControl>
	);
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
	width: '100%',
	borderRadius: '10px',
	backgroundColor: '#F5F5F5',
	marginBottom: '5px',

	'& .MuiToggleButtonGroup-grouped': {
		border: '2px solid',
		borderColor: '#BCBCBC',

		'&:first-of-type': {
			borderRight: '0',
		},

		'&:last-of-type': {
			borderLeft: '0',
		},

		'&:not(:first-of-type):not(:last-of-type)': {
			borderRight: '0',
			borderLeft: '0',
		},

		'&.Mui-disabled': {
			borderColor: 'transparent',
		},
	},
});

export const StyledToggleButton = styled(ToggleButton)<StyledToggleButtonProps>(
	({ ...props }) => ({
		width: '100%',
		borderRadius: '10px',
		color: '#BCBCBC',
		backgroundColor: '#F5F5F5',
		padding: '5px',
		paddingTop: '9px',
		paddingBottom: '5px',

		height: '50px',
		textTransform: 'none',

		'&.Mui-selected, &.Mui-selected:hover': {
			color: props.selectedcolor,
			backgroundColor: props.selectedbackgroundcolor,
		},
		'&.Mui-disabled': {
			borderColor: 'transparent',
		},
	})
);

export const StyledButtonContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	lineHeight: '1.5',
	fontSize: '12px',
});

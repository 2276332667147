import { Outlet } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import mainTheme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ModalRoot from './common/components/modal/ModalRoot';
import SnackbarRoot from './common/components/snackbar/SnackbarRoot';
import FullScreenLoaderRoot from './common/components/fullScreenLoader/FullScreenLoaderRoot';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import VersionUpdateAlertSnackbarRoot from './common/components/snackbar/UpdateAlertSnackbarRoot';

function App() {
	return (
		<ThemeProvider theme={mainTheme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<CssBaseline />
				<Outlet />
				<ModalRoot />
				<SnackbarRoot />
				<FullScreenLoaderRoot />
				<VersionUpdateAlertSnackbarRoot />
			</LocalizationProvider>
		</ThemeProvider>
	);
}

export default App;

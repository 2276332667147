import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Box, FormControlLabel, styled } from '@mui/material';
import Option from '../../types/option.type';
import AppCheckbox from './AppCheckbox';
import AppInputLayout from '../form-layout/AppInputLayout';

interface AppMultipleCheckboxProps {
	label: string;
	options: Option[];
	required?: boolean;
}

export default function AppMultipleCheckbox<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	options,
	required,
	...props
}: AppMultipleCheckboxProps & UseControllerProps<TFieldValues, TName>) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	function handleSelect(checkedName: any) {
		const newNames = field.value?.includes(checkedName)
			? field.value?.filter((name: any) => name !== checkedName)
			: [...(field.value ?? []), checkedName];
		field.onChange(newNames);
	}

	return (
		<AppInputLayout
			label={label}
			required={required}
			errorMessage={errorMessage}
		>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
				}}
			>
				{options.map((option) => (
					<StyledFormControlLabel
						key={option.id}
						control={
							<AppCheckbox
								sx={{ padding: '0px 10px' }}
								checked={field.value?.includes(option.id)}
								onChange={() => handleSelect(option.id)}
							/>
						}
						label={option.name}
					/>
				))}
			</Box>
		</AppInputLayout>
	);
}

export const StyledFormControlLabel = styled(FormControlLabel)({
	'& .MuiFormControlLabel-label': {
		fontSize: 12,
	},
});

import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import { useGetPlaceQuery } from '../../../../api/contentApiSlice';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import LinkDisplayText from '../../../../common/components/form-data-display/LinkDisplayText';
import { Dispatch, SetStateAction, useContext } from 'react';
import { PlaceIdContext } from '../ViewPlacePage';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import FlagLabelDisplay from '../../../../common/components/form-data-display/FlagLabelDisplay';
import TickOrCrossWithLabelDisplay from '../../../../common/components/form-data-display/TickOrCrossWithLabelDisplay';
import { Reviewer, ReviewerOption } from '../../../../api/enum/reviewer.enum';
import EditorDisplay from '../../../../common/components/editor/EditorDisplay';

export default function BaseInfoView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const placeId = useContext(PlaceIdContext);

	const { data: getPlaceResponse } = useGetPlaceQuery(placeId);

	const placeType = getPlaceResponse?.data.type;

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppInputLayout
					label={
						placeType == 0 ? t('place.AttractionID') : t('place.RestaurantID')
					}
				>
					<NonEditableDisplayText text={getPlaceResponse?.data.baseInfo.id} />
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout
					label={
						placeType == 0
							? t('place.AttractionName')
							: t('place.RestaurantName')
					}
				/>

				<AppInputLayout label={t('common.English')}>
					<NewDisplayText text={getPlaceResponse?.data.baseInfo.nameEn} />
				</AppInputLayout>

				<AppInputLayout label={t('common.TraditionalChinese')}>
					<NewDisplayText text={getPlaceResponse?.data.baseInfo.name} />
				</AppInputLayout>

				<AppInputLayout label={t('place.OriginalName')}>
					<NewDisplayText text={getPlaceResponse?.data.baseInfo.nameOg} />
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout label={t('place.Website')}>
					<LinkDisplayText
						link={
							getPlaceResponse?.data.baseInfo.website
								? getPlaceResponse?.data.baseInfo.website
								: '-'
						}
					/>
				</AppInputLayout>

				<AppInputLayout label={t('place.PhoneNumber')}>
					<NewDisplayText text={getPlaceResponse?.data.baseInfo.phoneNum} />
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout label={t('place.CountryOrRegion')}>
					<FlagLabelDisplay
						iso2={getPlaceResponse?.data.baseInfo.country.iso2}
						label={getPlaceResponse?.data.baseInfo.country.name}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('place.MajorArea')}>
					<NewDisplayText
						text={getPlaceResponse?.data.baseInfo.majorArea.name}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('place.Area')}>
					<NewDisplayText text={getPlaceResponse?.data.baseInfo.area.name} />
				</AppInputLayout>

				<AppInputLayout label={t('place.AddressInDetail')}>
					<NewDisplayText text={getPlaceResponse?.data.baseInfo.address} />
				</AppInputLayout>
				<AppInputLayout label={t('place.GoogleMapLink')}>
					<LinkDisplayText link={getPlaceResponse?.data.baseInfo.mapLink} />
				</AppInputLayout>
				<AppInputLayout label={t('place.LocationCoordinates')}>
					<NewDisplayText
						text={`${getPlaceResponse?.data.baseInfo.coordinates?.latitude.toString()}, ${getPlaceResponse?.data.baseInfo.coordinates?.longitude.toString()}`}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('place.NearestStation')}>
					<NewDisplayText
						text={getPlaceResponse?.data.baseInfo.nearestStation?.[0].name}
					/>
				</AppInputLayout>
				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout labelAlignItemsFlexStart label={t('place.Description')}>
					<NewDisplayText text={getPlaceResponse?.data.baseInfo.description} />
				</AppInputLayout>

				<AppInputLayout
					label={t('place.RemarksIfAny')}
					labelAlignItemsFlexStart={true}
				>
					<EditorDisplay
						editorStateString={getPlaceResponse?.data.baseInfo.remarks}
					/>
				</AppInputLayout>

				{getPlaceResponse?.data.baseInfo.reviewers && placeType == 1 && (
					<>
						<AppInputLayout label={t('place.RecommendedBy')}>
							<Stack direction={'row'} spacing={'18px'} gap={'10px'}>
								<TickOrCrossWithLabelDisplay
									value={getPlaceResponse?.data.baseInfo.reviewers.includes(
										Reviewer.TABELOG
									)}
									label={
										ReviewerOption.find((i) => i.id === Reviewer.TABELOG)
											?.name ?? ''
									}
								/>
								<TickOrCrossWithLabelDisplay
									value={getPlaceResponse?.data.baseInfo.reviewers.includes(
										Reviewer.RURUBU
									)}
									label={
										ReviewerOption.find((i) => i.id === Reviewer.RURUBU)
											?.name ?? ''
									}
								/>
								<TickOrCrossWithLabelDisplay
									value={getPlaceResponse?.data.baseInfo.reviewers.includes(
										Reviewer.TRIPADVISOR
									)}
									label={
										ReviewerOption.find((i) => i.id === Reviewer.TRIPADVISOR)
											?.name ?? ''
									}
								/>
								<TickOrCrossWithLabelDisplay
									value={getPlaceResponse?.data.baseInfo.reviewers.includes(
										Reviewer.MICHELIN
									)}
									label={
										ReviewerOption.find((i) => i.id === Reviewer.MICHELIN)
											?.name ?? ''
									}
								/>
							</Stack>
						</AppInputLayout>
					</>
				)}
			</Stack>
		</>
	);
}

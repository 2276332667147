import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, matchPath, useLocation } from 'react-router-dom';
import PageHeader from '../../common/components/PageHeader';

function useRouteMatch(patterns: readonly string[]) {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}

	return null;
}

function ImportPropertyTabs({ currentTab }: { currentTab?: string }) {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				backgroundColor: '#FFFFFF',
				borderRadius: '5px',
				boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
				padding: '0px 20px',
				marginBottom: '15px',
			}}
		>
			{currentTab && (
				<Tabs value={currentTab}>
					<Tab
						label={t('tabs.Import')}
						value='/property/import/add'
						to={`/property/import/add`}
						component={Link}
					/>
					<Tab
						label={t('tabs.Logging')}
						value='/property/import/logging'
						to={`/property/import/logging`}
						component={Link}
					/>
				</Tabs>
			)}
		</Box>
	);
}

export default function ImportPropertyContainer() {
	const { t } = useTranslation();
	const { state } = useLocation();

	const [propertySummarySearchParams, setPropertySummarySearchParams] =
		useState<string | undefined>(undefined);

	// You need to provide the routes in descendant order.
	// This means that if you have nested routes like:
	// users, users/new, users/edit.
	// Then the order should be ['users/add', 'users/edit', 'users'].
	const routeMatch = useRouteMatch([
		'/property/import/add',
		'/property/import/logging',
	]);

	const currentTab = routeMatch?.pattern?.path;

	useEffect(() => {
		if (state?.propertySummarySearchParams) {
			setPropertySummarySearchParams(state?.propertySummarySearchParams);
		}
	}, []);

	const backPath = propertySummarySearchParams
		? `/property?${propertySummarySearchParams}`
		: '/property';

	const pageContent = (
		<Box sx={{ width: '100%' }}>
			<PageHeader
				showBackButton={true}
				backPath={backPath}
				title={t('property.propertyImport.ImportProperty')}
			/>
			<ImportPropertyTabs currentTab={currentTab} />
			<Outlet />
		</Box>
	);

	return <div>{pageContent}</div>;
}

import { Box, Button, Divider, Grid, Stack } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Promotion } from '../../../../api/DTO/promotion.interface';
import {
	DiscountType,
	discountTypeOptions,
} from '../../../../api/enum/discountType.enum';
import {
	useGetPromotionQuery,
	useUpdatePromotionMutation,
} from '../../../../api/promotionApiSlice';
import { FormCardType } from '../../../../common/components/card/FormCard';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import AppDatePicker from '../../../../common/components/form-inputs/AppDatePicker';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import { currencies } from '../../../../common/constants/currencies';
import { NONE_INVALID_DATE_PATTERN } from '../../../../common/constants/pattern';
import { API_DATE_FORMAT } from '../../../../utils/dateHelper';
import { PromotionIdContext } from '../ViewPromotionPage';
import AppSelectProperties from '../../../../common/components/form-inputs/AppSelectProperties';

interface PromotionFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function PromotionForm({ type, setType }: PromotionFormProps) {
	const { t } = useTranslation();
	const promotionId = useContext(PromotionIdContext);

	const formSchema = Yup.object().shape({
		code: Yup.string().required(t('errorMessage.required')),
		discount: Yup.object()
			.shape({
				amount: Yup.string().required(t('errorMessage.required')),
				type: Yup.string().required(t('errorMessage.pleaseSelect')),
				currency: Yup.string()
					.nullable()
					.when('type', {
						is: DiscountType.FIXED,
						then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
					}),
			})
			.required(),
		quota: Yup.string().required(t('errorMessage.required')),
		validPeriod: Yup.object().shape({
			start: Yup.string()
				.required(t('errorMessage.required'))
				.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
			end: Yup.string()
				.required(t('errorMessage.required'))
				.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
				.when('start', (start: any, schema) => {
					return schema.test(
						'shouldGreaterThanStart',
						() => t('errorMessage.incorrectPeriodTime'),
						(value) => !!start && dayjs(value).isAfter(dayjs(start))
					);
				}),
		}),
	});

	const { data: getPromotionResponse, isLoading: isGetPromotionLoading } =
		useGetPromotionQuery(promotionId, {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control, watch } =
		useFormContext() ??
		useForm<Promotion>({
			defaultValues: getPromotionResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const [updatePromotion, { isSuccess: isUpdatePromotionSuccess }] =
		useUpdatePromotionMutation();

	useEffect(() => {
		if (isUpdatePromotionSuccess) setType(FormCardType.view);
	}, [isUpdatePromotionSuccess]);

	const watchDiscountType = watch('discount.type');
	const watchValidPeriodStart = watch('validPeriod.start');
	const watchValidPeriodEnd = watch('validPeriod.end');

	const onSubmit: SubmitHandler<any> = (data) => {
		if (data.discount.type === DiscountType.PERCENTAGE) {
			data.discount.currency = undefined;
		}
		if (data.description == '') {
			data.description = undefined;
		}
		updatePromotion({
			promotionId: promotionId ?? '',
			body: data,
		});
	};

	const formContent = (
		<Stack>
			{type === FormCardType.edit && (
				<>
					<AppInputLayout label={t('promotion.PromotionID')}>
						<NonEditableDisplayText text={getPromotionResponse?.data.id} />
					</AppInputLayout>

					<Divider sx={{ marginBottom: '15px' }} />
				</>
			)}
			<Grid container columnSpacing={5}>
				<Grid item xs={6}>
					<AppTextField
						control={control}
						name='code'
						label={t('promotion.Code')}
						required
					/>
					<AppTextField
						control={control}
						name='description'
						label={t('promotion.Description')}
					/>

					<AppInputLayout
						label={t('promotion.ValidPeriod')}
						removeErrorPadding
						required
					>
						<Box display='grid' gap='10px' gridTemplateColumns='repeat(2, 1fr)'>
							<AppDatePicker
								control={control}
								name={'validPeriod.start'}
								minimumDate={dayjs().startOf('day').format()}
								maximumDate={
									watchValidPeriodEnd
										? dayjs(watchValidPeriodEnd, API_DATE_FORMAT)
												.subtract(1, 'day')
												.format()
										: undefined
								}
							/>
							<AppDatePicker
								control={control}
								name={'validPeriod.end'}
								minimumDate={
									watchValidPeriodStart
										? dayjs(watchValidPeriodStart, API_DATE_FORMAT)
												.add(1, 'day')
												.format()
										: dayjs().startOf('day').format()
								}
							/>
						</Box>
					</AppInputLayout>

					<AppInputLayout
						label={t('promotion.Discount')}
						removeErrorPadding
						required
					>
						<Box display='grid' gap='10px' gridTemplateColumns='repeat(2, 1fr)'>
							<AppTextField
								inputType={InputType.number}
								name={'discount.amount'}
								label={''}
								control={control}
							/>
							<AppDropDownMenu
								control={control}
								name={`discount.type`}
								label={''}
								options={discountTypeOptions}
							/>
						</Box>
					</AppInputLayout>
					{watchDiscountType === 1 && (
						<AppDropDownMenu
							control={control}
							name='discount.currency'
							label={t('promotion.Currency')}
							options={currencies}
							required
						/>
					)}

					<AppTextField
						control={control}
						name='quota'
						label={t('promotion.Quota')}
						inputType={InputType.number}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<AppSelectProperties
						control={control}
						name='properties'
						label={t('promotion.Properties')}
					/>
				</Grid>
			</Grid>

			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetPromotionLoading && form}</>;
}

import { Grid, Typography } from '@mui/material';

interface DisplayTextProps {
	label: string;
	value: string;
}

export default function DisplayText(props: DisplayTextProps) {
	return (
		<Grid container columnSpacing='20px' marginTop='20px' padding='0px'>
			<Grid item xs={3}>
				<Typography fontSize={12} color='text.grey'>
					{props.label}
				</Typography>
			</Grid>
			<Grid item xs={9}>
				<Typography fontSize={12}>{props.value}</Typography>
			</Grid>
		</Grid>
	);
}

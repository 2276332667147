export enum LogRevisionType {
	UNKNOWN = 0,
	INSERT = 1,
	UPDATE = 2,
	DELETE = 3,
}

export function getLogAction(logAction: LogRevisionType): string {
	switch (logAction) {
		case LogRevisionType.UNKNOWN:
			return 'Unknown';
		case LogRevisionType.INSERT:
			return 'Create';
		case LogRevisionType.UPDATE:
			return 'Edit';
		case LogRevisionType.DELETE:
			return 'Delete';
	}
}

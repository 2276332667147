import { Box, styled, Divider } from '@mui/material';
import AppSpaceSubtitleLayout from '../../../common/components/form-layout/AppSpaceSubtitleLayout';
import { useTranslation } from 'react-i18next';
import SpaceDisplayText from '../../../common/components/form-data-display/SpaceDisplayText';

interface MealBreakdownCardProps {
	data?: any;
	currency: string;
	label: string;
}

export default function MealBreakdownCard({
	data,
	currency,
	label,
}: MealBreakdownCardProps) {
	const { t } = useTranslation();

	return (
		<>
			{data !== undefined && (
				<StyledBreakdownCard>
					<AppSpaceSubtitleLayout label={label} content={currency} />
					<Box
						sx={{
							display: 'flex',
							gap: '5px',
							flexDirection: 'column',
							marginBottom: '15px',
						}}
					>
						{data.adult && (
							<SpaceDisplayText
								text1={data.adult ? `${t('bk.Adult')}: ${data.adult.num}` : ''}
								text2={data.adult.rate?.toString()}
							/>
						)}
						{data.child && (
							<SpaceDisplayText
								text1={data.child ? `${t('bk.Child')}: ${data.child.num}` : ''}
								text2={data.child.rate?.toString()}
							/>
						)}
						{data.toddler && (
							<SpaceDisplayText
								text1={
									data.toddler ? `${t('bk.Toddler')}: ${data.toddler.num}` : ''
								}
								text2={data.toddler.rate?.toString()}
							/>
						)}
						{data.infant && (
							<SpaceDisplayText
								text1={
									data.infant ? `${t('bk.Infant')}: ${data.infant.num}` : ''
								}
								text2={data.infant.rate?.toString()}
							/>
						)}
					</Box>
					<Divider sx={{ marginBottom: '15px' }} />
					<Box
						sx={{
							display: 'flex',
							gap: '5px',
							flexDirection: 'column',
							marginBottom: '15px',
						}}
					>
						<AppSpaceSubtitleLayout
							label={`${t('bk.Subtotal')}`}
							content={data.subtotal?.toString()}
						/>
						{data.markupAmount !== undefined && data.markupAmount !== null && (
							<SpaceDisplayText
								text1={t('bk.Markup')}
								text2={data.markupAmount?.toString()}
							/>
						)}
						{data.agencyCommissionAmount !== undefined &&
							data.agencyCommissionAmount !== null && (
								<SpaceDisplayText
									text1={t('bk.Commission')}
									text2={`(${data.agencyCommissionAmount?.toString()})`}
								/>
							)}
						{data.taxAmount !== undefined && data.taxAmount !== null && (
							<SpaceDisplayText
								text1={t('bk.Tax')}
								text2={data.taxAmount?.toString()}
							/>
						)}
						{data.serviceChargeAmount !== undefined &&
							data.serviceChargeAmount !== null && (
								<SpaceDisplayText
									text1={t('bk.ServiceCharge')}
									text2={data.serviceChargeAmount?.toString()}
								/>
							)}
					</Box>
					<Divider sx={{ marginBottom: '15px' }} />
					<AppSpaceSubtitleLayout
						label={`${t('bk.OptionalMealTotal')}`}
						content={data.total.toString()}
						color={'#4EA4D3'}
					/>
				</StyledBreakdownCard>
			)}
		</>
	);
}

const StyledBreakdownCard = styled(Box)({
	backgroundColor: '#F5F5F5',
	padding: '20px ',
	paddingBottom: '0',
	borderRadius: '10px',
	marginBottom: '20px',
});

import './Button.css';

import { ReactNode } from 'react';

import joinClasses from '../utils/joinClasses';

export default function Button({
	'data-test-id': dataTestId,
	children,
	className,
	onClick,
	disabled,
	small,
	title,
}: {
	'data-test-id'?: string;
	children: ReactNode;
	className?: string;
	disabled?: boolean;
	onClick: () => void;
	small?: boolean;
	title?: string;
}): JSX.Element {
	return (
		<button
			type='button'
			disabled={disabled}
			className={joinClasses(
				'Button__root',
				disabled && 'Button__disabled',
				small && 'Button__small',
				className
			)}
			onClick={onClick}
			title={title}
			aria-label={title}
			{...(dataTestId && { 'data-test-id': dataTestId })}
		>
			{children}
		</button>
	);
}

import { Button, Divider, Grid, InputAdornment, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';
import AppDropDownMenu from '../../../../common/components/form-inputs/AppDropDownMenu';
import { ReactComponent as LinkIcon } from '../../../../assets/icon/link.svg';
import { FormCardType } from '../../../../common/components/card/FormCard';
import { starRatings } from '../../../../common/constants/starRatings';
import {
	useGetPropertyQuery,
	useUpdatePropertyBaseInfoMutation,
} from '../../../../api/accommodationApiSlice';
import OwnershipDropDownMenu from '../../components/OwnershipDropDownMenu';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { PropertyIdContext } from '../ViewPropertyPage';
import { Property } from '../../../../api/DTO/property.interface';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import {
	PropertyCategory,
	propertyCategoryOptions,
} from '../../../../api/enum/propertyCategories.enum';

interface BaseInfoFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function BaseInfoForm({ type, setType }: BaseInfoFormProps) {
	const { t } = useTranslation();
	const propertyId = useContext(PropertyIdContext);

	const formSchema = Yup.object().shape({
		baseInfo: Yup.object()
			.shape({
				originalName: Yup.string().required(t('errorMessage.required')),
				name: Yup.string().required(t('errorMessage.required')),
				nameEn: Yup.string().required(t('errorMessage.required')),
				category: Yup.number().required(t('errorMessage.pleaseSelect')),
				starRating: Yup.number().when('category', {
					is: PropertyCategory.HOTEL,
					then: (schema) => schema.required(t('errorMessage.pleaseSelect')),
				}),
			})
			.required(),
	});

	const { data: getPropertyResponse, isLoading: isGetPropertyLoading } =
		useGetPropertyQuery(propertyId, {
			skip: type === FormCardType.create,
		});

	const { handleSubmit, control, watch, unregister } =
		useFormContext() ??
		useForm<Property>({
			defaultValues: getPropertyResponse?.data,
			resolver: yupResolver(formSchema),
		});

	const watchCategory = watch('baseInfo.category');
	useEffect(() => {
		if (watchCategory !== PropertyCategory.HOTEL) {
			unregister('baseInfo.starRating');
		}
	}, [watchCategory]);

	const [
		updatePropertyBaseInfo,
		{ isSuccess: isUpdatePropertyBaseInfoSuccess },
	] = useUpdatePropertyBaseInfoMutation();

	useEffect(() => {
		if (isUpdatePropertyBaseInfoSuccess) setType(FormCardType.view);
	}, [isUpdatePropertyBaseInfoSuccess]);

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updatePropertyBaseInfo({
			propertyId: propertyId ?? '',
			body: data.baseInfo,
		});
	};

	const formContent = (
		<Stack>
			{type === FormCardType.edit && (
				<>
					<AppInputLayout label={t('property.PropertyID')}>
						<NonEditableDisplayText
							text={getPropertyResponse?.data.baseInfo.id}
						/>
					</AppInputLayout>

					<Divider sx={{ marginBottom: '15px' }} />
				</>
			)}

			<AppSubtitleLayout label={t('property.PropertyName')} />

			<AppTextField
				control={control}
				name='baseInfo.nameEn'
				label={t('common.English')}
				placeholder={t('placeholder.pleaseEnterEnglish')}
				required
			/>
			<AppTextField
				control={control}
				name='baseInfo.name'
				label={t('common.TraditionalChinese')}
				placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
				required
			/>

			<AppTextField
				control={control}
				name='baseInfo.originalName'
				label={t('common.Original')}
				required
			/>

			<Divider sx={{ marginBottom: '15px' }} />

			<OwnershipDropDownMenu
				control={control}
				name='baseInfo.owner'
				label={t('property.PropertyOwnership')}
				required
			/>
			<AppDropDownMenu
				control={control}
				name='baseInfo.category'
				label={t('property.Category')}
				options={propertyCategoryOptions}
				required
			/>
			{watchCategory === PropertyCategory.HOTEL && (
				<AppDropDownMenu
					control={control}
					name='baseInfo.starRating'
					label={t('property.StarRatings')}
					infoPopUp={' '}
					options={starRatings}
				/>
			)}

			<AppTextField
				control={control}
				name='baseInfo.numOfRooms'
				label={t('property.NumberOfRooms')}
				inputType={InputType.number}
			/>
			<AppTextField
				control={control}
				name='baseInfo.website'
				label={t('property.Website')}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<LinkIcon />
						</InputAdornment>
					),
				}}
			/>
			<AppTextField
				control={control}
				name='baseInfo.phoneNum'
				inputType={InputType.number}
				label={t('property.PhoneNumber')}
			/>
			<AppTextField
				control={control}
				name='baseInfo.description'
				label={t('property.DescriptionInChi')}
				placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
				multiline
				rows={5}
			/>
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</Stack>
	);

	const form =
		type === FormCardType.edit ? (
			<form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>
		) : (
			formContent
		);

	return <>{!isGetPropertyLoading && form}</>;
}

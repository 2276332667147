import { Box, Divider, Grid, Stack, Typography, styled } from '@mui/material';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBkQuery } from '../../../../api/bkApiSlice';
import { getRatePlanConfirmType } from '../../../../api/enum/confirmType.enum';
import { getMealNameByMealType } from '../../../../api/enum/mealType.enum';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import ViewToggleButton from '../../../../common/components/ViewToggleButton';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import TextWithUnit from '../../../../common/components/form-data-display/TextWithUnit';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import Option from '../../../../common/types/option.type';
import { DISPLAY_DATE_FORMAT } from '../../../../utils/dateHelper';
import CusRateBreakdownCard from '../../components/CusRateBreakdownCard';
import GrandTotalBreakdownCard from '../../components/GrandTotalBreakdownCard';
import MealBreakdownCard from '../../components/MealBreakdownCard';
import ProfitBreakdownCard from '../../components/ProfitBreakdownCard';
import ProfitOptionalMealBreakdownCard from '../../components/ProfitOptionalMealBreakdownCard';
import ProfitTotalBreakdownCard from '../../components/ProfitTotalBreakdownCard';
import PromoBreakdownCard from '../../components/PromoBreakdownCard';
import RateBreakdownCard from '../../components/RateBreakdownCard';
import SupRateBreakdownCard from '../../components/SupRateBreakdownCard';
import { BkIdContext } from '../ViewBkPage';

export default function RateInfoView() {
	const { t } = useTranslation();
	const bkId = useContext(BkIdContext);

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getBkResponse } = useGetBkQuery({
		bk_id: bkId ?? '',
	});

	const BkEditOptions: Option[] = [
		{
			id: 0,
			name: 'Customer View',
		},
		{
			id: 1,
			name: 'All',
		},
		{
			id: 2,
			name: 'Supplier View',
		},
	];

	const [rateInfoView, setRateInfoView] = useState(1);

	return (
		<>
			<Stack position={'relative'}>
				<AppSubtitleLayout label={t('bk.StayInfo')} />

				<AppInputLayout label={t('bk.StayPeriod')}>
					<NewDisplayText
						text={`${dayjs(
							getBkResponse?.data.rate.stay.stayPeriod.start
						).format(DISPLAY_DATE_FORMAT)} to ${dayjs(
							getBkResponse?.data.rate.stay.stayPeriod.end
						).format(DISPLAY_DATE_FORMAT)}`}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('bk.NumOfAdults')}>
					<NewDisplayText
						text={`${getBkResponse?.data.rate.stay.numOfAdults}`}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('bk.NumOfChildren')}>
					<NewDisplayText
						text={`${getBkResponse?.data.rate.stay.numOfChildren}`}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('bk.NumOfToddlers')}>
					<NewDisplayText
						text={`${getBkResponse?.data.rate.stay.numOfToddlers}`}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('bk.NumOfInfants')}>
					<NewDisplayText
						text={`${getBkResponse?.data.rate.stay.numOfInfants}`}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('bk.CustomerPaymentInformation')} />
				<AppInputLayout label={t('bk.ConfirmType')}>
					<NewDisplayText
						text={getRatePlanConfirmType(
							getBkResponse?.data.rate.customerPayment.confirmType
						)}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('bk.Currency')}>
					<NewDisplayText
						text={getBkResponse?.data.rate.customerPayment.currency}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('bk.MealDetails')} />
				<AppInputLayout label={t('bk.MealType')}>
					<NewDisplayText
						text={getMealNameByMealType(getBkResponse?.data.rate.meal.mealType)}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('bk.MealRateCalculation')}>
					<NewDisplayText
						text={
							getBkResponse?.data.rate.meal.withMealSupplement
								? 'Use Meal Supplement'
								: 'included in Rate Plan Supplement'
						}
					/>
				</AppInputLayout>
				{getBkResponse?.data.rate.meal.withMealSupplement &&
					getBkResponse?.data.rate.meal.mealSupplementMerchantConfig && (
						<Box
							sx={{
								paddingLeft: '20px',
								borderLeft: '5px solid #4EA4D3',
								marginBottom: '15px',
							}}
						>
							<AppInputLayout label={t('property.ServiceChargeIncluded')}>
								<YesNoDisplay
									value={
										getBkResponse?.data.rate.meal.mealSupplementMerchantConfig
											?.serviceChargeIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('property.TaxIncluded')}>
								<YesNoDisplay
									value={
										getBkResponse?.data.rate.meal.mealSupplementMerchantConfig
											?.taxIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('property.CommissionLevel')}>
								<TextWithUnit
									text={`${getBkResponse?.data.rate.meal.mealSupplementMerchantConfig?.commissionLevel}`}
									unit='%'
								/>
							</AppInputLayout>

							<AppInputLayout
								label={t('property.CommissionIncludedServiceCharge')}
							>
								<YesNoDisplay
									value={
										getBkResponse?.data.rate.meal.mealSupplementMerchantConfig
											?.commissionServiceChargeIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('property.CommissionIncludedTax')}>
								<YesNoDisplay
									value={
										getBkResponse?.data.rate.meal.mealSupplementMerchantConfig
											?.commissionTaxIncluded
									}
								/>
							</AppInputLayout>
						</Box>
					)}

				<Divider sx={{ marginBottom: '15px' }} />

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<AppSubtitleLayout label={t('bk.Breakdown')} />
					<Box paddingBottom={'15px'}>
						<ViewToggleButton
							value={rateInfoView}
							options={BkEditOptions}
							handleChange={(_, newValue) => {
								if (newValue != null) setRateInfoView(newValue);
							}}
						/>
					</Box>
				</Box>
				{rateInfoView === 0 && (
					<>
						{getBkResponse?.data.rate.customerBreakdown && (
							<>
								<CusRateBreakdownCard
									data={getBkResponse?.data.rate.customerBreakdown}
									label={t('bk.BaseRate')}
								/>
							</>
						)}

						<GrandTotalBreakdownCard
							data={getBkResponse?.data.rate.customerBreakdown}
							label={`${t('bk.Total')} `}
							currency={
								getBkResponse?.data.rate.customerBreakdown?.currency ?? '-'
							}
						/>
					</>
				)}
				{rateInfoView === 1 && (
					<>
						{getBkResponse?.data.rate.generalBreakdown?.dailyBreakdowns &&
							getBkResponse?.data.rate.generalBreakdown?.dailyBreakdowns?.map(
								(breakdownItem: any, breakdownIndex) => {
									return (
										<RateBreakdownCard
											key={breakdownIndex}
											data={breakdownItem}
											label={`${t('bk.BaseRate')} (${dayjs(
												breakdownItem.date
											).format(DISPLAY_DATE_FORMAT)})`}
											mealType={getMealNameByMealType(
												getBkResponse?.data.rate.meal.mealType
											)}
											currency={
												getBkResponse?.data.rate.generalBreakdown?.currency ??
												'-'
											}
										/>
									);
								}
							)}
						{getBkResponse?.data.rate.generalBreakdown?.optionalMeals &&
							getBkResponse?.data.rate.generalBreakdown?.optionalMeals?.map(
								(breakdownItem: any, breakdownIndex) => {
									return (
										<MealBreakdownCard
											key={breakdownIndex}
											data={breakdownItem}
											label={`${t('bk.OptionalMeal')} (${getMealNameByMealType(
												breakdownItem.mealType
											)})`}
											currency={
												getBkResponse?.data.rate.generalBreakdown?.currency ??
												'-'
											}
										/>
									);
								}
							)}

						{getBkResponse?.data.rate.generalBreakdown?.promo && (
							<PromoBreakdownCard
								data={getBkResponse?.data.rate.generalBreakdown?.promo}
								label={t('bk.PromoCode')}
								currency={
									getBkResponse?.data.rate.generalBreakdown?.currency ?? '-'
								}
							/>
						)}

						<GrandTotalBreakdownCard
							data={getBkResponse?.data.rate.generalBreakdown}
							label={`${t('bk.Total')} `}
							currency={
								getBkResponse?.data.rate.generalBreakdown?.currency ?? '-'
							}
						/>
					</>
				)}
				{rateInfoView === 2 && (
					<>
						{getBkResponse?.data.rate.supplierBreakdown?.dailyBreakdowns &&
							getBkResponse?.data.rate.supplierBreakdown?.dailyBreakdowns?.map(
								(breakdownItem: any, breakdownIndex) => {
									return (
										<SupRateBreakdownCard
											key={breakdownIndex}
											data={breakdownItem}
											label={`${t('bk.BaseRate')} (${dayjs(
												breakdownItem.date
											).format(DISPLAY_DATE_FORMAT)})`}
											mealType={getMealNameByMealType(
												getBkResponse?.data.rate.meal.mealType
											)}
											currency={
												getBkResponse?.data.rate.supplierBreakdown?.currency ??
												'-'
											}
										/>
									);
								}
							)}
						{getBkResponse?.data.rate.supplierBreakdown?.optionalMeals &&
							getBkResponse?.data.rate.supplierBreakdown?.optionalMeals?.map(
								(breakdownItem: any, breakdownIndex) => {
									return (
										<MealBreakdownCard
											key={breakdownIndex}
											data={breakdownItem}
											label={`${t('bk.OptionalMeal')} (${getMealNameByMealType(
												breakdownItem.mealType
											)})`}
											currency={
												getBkResponse?.data.rate.supplierBreakdown?.currency ??
												'-'
											}
										/>
									);
								}
							)}

						<GrandTotalBreakdownCard
							data={getBkResponse?.data.rate.supplierBreakdown}
							label={`${t('bk.Total')} `}
							currency={
								getBkResponse?.data.rate.supplierBreakdown?.currency ?? '-'
							}
						/>
					</>
				)}

				{getBkResponse?.data.profit && (
					<>
						<Divider sx={{ marginBottom: '15px' }} />

						<Grid container alignItems='stretch' spacing={''}>
							<Grid
								item
								xs={12}
								display='flex'
								flexDirection={'column'}
								paddingX={'10px'}
								paddingY={'3px'}
								marginY={'-3px'}
							>
								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<StyledRateTitle>{t('bk.ProfitEstimation')}</StyledRateTitle>
								</Box>
								{getBkResponse?.data.profit.base && (
									<ProfitBreakdownCard
										data={getBkResponse?.data.profit.base}
										label={`${t('bk.Base')}`}
										currency={getBkResponse?.data.profit.currency ?? '-'}
										rateModel={getBkResponse?.data.supplier.rateModel}
									/>
								)}

								{getBkResponse?.data.profit.optionalMeal && (
									<ProfitOptionalMealBreakdownCard
										data={getBkResponse?.data.profit.optionalMeal}
										label={`${t('bk.Meal')}`}
										currency={getBkResponse?.data.profit.currency ?? '-'}
									/>
								)}

								{getBkResponse?.data.profit.profit && (
									<ProfitTotalBreakdownCard
										data={getBkResponse?.data.profit.profit}
										label={`${t('abk.ProfitTotal')} `}
										currency={getBkResponse?.data.profit.currency ?? '-'}
									/>
								)}
							</Grid>
						</Grid>
					</>
				)}
			</Stack>
		</>
	);
}

const StyledRateTitle = styled(Typography)({
	color: '#4EA4D3',
	fontWeight: 700,
	fontSize: '16px',
	marginBottom: '20px',
});

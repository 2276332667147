import { Box, Divider, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '../../../../../assets/icon/close.svg';
import dayjs from 'dayjs';
import {
	API_DATE_FORMAT,
	DISPLAY_DATE_FORMAT,
} from '../../../../../utils/dateHelper';

interface EditPopUpContainerProps {
	title: string;
	from: string;
	to: string;
	onCloseCallBack: () => void;
}

export default function EditPopUpContainer({
	title,
	from,
	to,
	onCloseCallBack,
	children,
}: React.PropsWithChildren<EditPopUpContainerProps>) {
	const { t } = useTranslation();

	return (
		<StyledStack gap='16px'>
			<Box display='flex' justifyContent={'space-between'}>
				<Title>{title}</Title>
				<CloseIcon
					style={{ cursor: 'pointer' }}
					onClick={() => {
						onCloseCallBack();
					}}
				/>
			</Box>
			<Box display='flex' justifyContent={'space-between'}>
				<Label>{from === to ? 'Edit Date' : 'Edit Period'}</Label>
				<Label>
					{from === to
						? `${dayjs(from, API_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)}`
						: `${dayjs(from, API_DATE_FORMAT).format(
								DISPLAY_DATE_FORMAT
						  )}-${dayjs(to, API_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)}`}
				</Label>
			</Box>
			<Divider />
			<div>{children}</div>
		</StyledStack>
	);
}

const Title = styled(Typography)({
	fontSize: '12px',
	fontWeight: 700,
});

const Label = styled(Typography)({
	fontSize: '12px',
});

const StyledStack = styled(Stack)({
	width: '333px',
	padding: '16px',
	backgroundColor: '#FFF',
	borderRadius: '5px',
	filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
});

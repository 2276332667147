import { SnackbarProps } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export enum SnackbarType {
	NULL = -1,
	MESSAGE = 0,
	SAVE_SUCCESS = 1,
}

interface SnackbarState {
	snackbarType: SnackbarType;
	snackbarProps: SnackbarProps;
}

const initialState: SnackbarState = {
	snackbarType: SnackbarType.NULL,
	snackbarProps: {},
};

export const snackbarSlice = createSlice({
	name: 'snackbar',
	initialState,
	reducers: {
		showSnackbar: (state, action: PayloadAction<SnackbarState>) => {
			const { snackbarType, snackbarProps } = action.payload;
			return { ...state, snackbarType, snackbarProps };
		},
		hideSnackbar: () => {
			return initialState;
		},
	},
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export const selectSnackbarState = (state: RootState) => state.snackbar;

export default snackbarSlice;

interface BlogPostStatus {
	isOnline: boolean;
	i18nLabel: string;
}

export function getBlogPostStatus(status: number): BlogPostStatus {
	switch (status) {
		case 0:
			return {
				isOnline: true,
				i18nLabel: 'blogPost.Online',
			};
		case 1:
			return {
				isOnline: false,
				i18nLabel: 'blogPost.Offline',
			};
		default:
			return {
				isOnline: false,
				i18nLabel: 'common.Error',
			};
	}
}

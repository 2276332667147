import { Box, Button, Stack, Typography, InputAdornment } from '@mui/material';
import { ReactComponent as ExpandMoreIcon } from '../../../../../assets/icon/expand-more.svg';
import {
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
} from '../../../../../common/components/AppAccordian';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
	useGetRatePlanListItemsQuery,
	useGetPropertyQuery,
	useBulkUpdateRatePlanPerPersonRatesMutation,
} from '../../../../../api/accommodationApiSlice';
import AppGridMultipleCheckbox from '../../../../../common/components/form-inputs/AppGridMultipleCheckbox';
import Yup from '../../../../../utils/yup-extended';
import { yupResolver } from '@hookform/resolvers/yup';
import AppCounter from '../../../../../common/components/form-inputs/AppCounter';
import AppAddButton from '../../../../../common/components/form-inputs/AppAddButton';
import { ReactComponent as AddIcon } from '../../../../../assets/icon/button-add.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icon/delete.svg';

import { useFieldArray } from 'react-hook-form';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';
import { PerPersonRate } from '../../../../../api/DTO/propertyCalendar.interface';
import { RatePlanChannel } from '../../../../../api/enum/ratePlanChannel.enum';

// frontend usage
export interface PerPersonRateObject {
	numOfAdults: number;
	rate: string;
}

export default function AdultRate() {
	const { t } = useTranslation();
	const method = useFormContext();
	const { propertyId } = useParams();

	const [submitCount, setSubmitCount] = useState(0);
	const [submitted, setSubmitted] = useState(false);

	const { data: getRatePlanListItemsResponse } = useGetRatePlanListItemsQuery({
		propertyId: propertyId ?? '',
		rateModel: method.getValues('rateModel'),
		channel: RatePlanChannel.DIRECT,
	});
	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');

	const [bulkUpdateRatePlanPerPersonRatesMutation] =
		useBulkUpdateRatePlanPerPersonRatesMutation();

	const defaultValues: DefaultValues<FieldValues> = {
		ratePlanIds: [],
		fPerPersonRates: [],
	};

	const formSchema = Yup.object().shape({
		ratePlanIds: Yup.array().min(1, t('errorMessage.atLeastOneCheckbox')),
		fPerPersonRates: Yup.array()
			.nullable()
			.perPersonRateUnique('numOfAdults', t('errorMessage.numberOfAdults')),
	});

	const adultRateMethod = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const watchParentFrom = method.watch('from');
	const watchParentTo = method.watch('to');
	const watchParentDays = method.watch('days');
	const watchRatePlanIds = adultRateMethod.watch('ratePlanIds');
	const watchfPerPersonRates = adultRateMethod.watch('fPerPersonRates');

	useEffect(() => {
		if (submitCount === 0) return;
		method.trigger().then((r) => adultRateMethod.trigger().then());
	}, [
		watchParentFrom,
		watchParentTo,
		watchParentDays,
		watchRatePlanIds,
		watchfPerPersonRates,
		submitCount,
	]);

	// ensure all errors are shown / cleared
	useEffect(() => {
		if (adultRateMethod.formState.isSubmitted) {
			adultRateMethod.trigger();
		}
	}, [adultRateMethod.watch()]);

	useEffect(() => {
		if (submitted) {
			setTimeout(() => {
				setSubmitted(false);
			}, 3000);
		}
	}, [submitted]);

	const { fields, append, remove } = useFieldArray({
		control: adultRateMethod.control,
		name: 'fPerPersonRates',
	});

	const onSubmit: SubmitHandler<FieldValues> = () => {
		setSubmitCount((prev) => prev + 1);
		method.trigger().then((methodValidated) => {
			adultRateMethod.trigger().then((adultRateMethodValidated) => {
				if (methodValidated && adultRateMethodValidated) {
					adultRateMethod.setValue('days', method.getValues('days'));
					adultRateMethod.setValue('from', method.getValues('from'));
					adultRateMethod.setValue('to', method.getValues('to'));
					adultRateMethod.setValue('rateModel', method.getValues('rateModel'));

					const fPerPersonRates: PerPersonRateObject[] =
						adultRateMethod.getValues('fPerPersonRates');
					const perPersonRates: PerPersonRate = {};
					fPerPersonRates.forEach((item) => {
						perPersonRates[item.numOfAdults] = item.rate;
					});

					bulkUpdateRatePlanPerPersonRatesMutation({
						propertyId: propertyId ?? '',
						body: {
							from: adultRateMethod.getValues('from'),
							to: adultRateMethod.getValues('to'),
							days: adultRateMethod.getValues('days'),
							ratePlanIds: adultRateMethod.getValues('ratePlanIds'),
							perPersonRates: perPersonRates,
						},
					})
						.unwrap()
						.then((response) => {
							if (response.success) {
								setSubmitCount(0);
								setSubmitted(true);
							}
						});
				}
			});
		});
	};

	const showDeleteButton = fields.length > 0;

	return (
		<StyledAccordion>
			<StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography
					color={'gray'}
					sx={{ flexGrow: 1 }}
					fontWeight={'bold'}
					fontSize={'14px'}
				>
					{t('rateAndInventory.AdultOfficialRate')}
				</Typography>
			</StyledAccordionSummary>
			<StyledAccordionDetails>
				<Typography
					color={'#4EA4D3'}
					sx={{ flexGrow: 1 }}
					// fontWeight={'bold'}
					fontSize={'12px'}
					paddingBottom={'5px'}
				>
					{t('rateAndInventory.EmptyPricesMsg')}
				</Typography>
				{fields.map((item, index) => {
					return (
						<Box
							key={item.id}
							sx={{
								background: '#F2FAFF',
								padding: '15px 30px',
								marginBottom: '15px',
								marginX: '-28px',
								position: 'relative',
							}}
						>
							{showDeleteButton && (
								<Box
									sx={{
										position: 'absolute',
										top: '10px',
										right: '30px',
									}}
								>
									<DeleteIcon
										color='#F6403F'
										cursor={'pointer'}
										onClick={() => remove(index)}
									/>
								</Box>
							)}
							<Typography
								color={'gray'}
								sx={{ flexGrow: 1 }}
								fontWeight={'bold'}
								fontSize={'14px'}
								marginBottom={'5px'}
							>
								{t('rateAndInventory.NumOfAdults')}
							</Typography>
							<AppCounter
								control={adultRateMethod.control}
								name={`fPerPersonRates.${index}.numOfAdults`}
							/>
							<Typography
								color={'gray'}
								sx={{ flexGrow: 1 }}
								fontWeight={'bold'}
								fontSize={'14px'}
								marginBottom={'5px'}
							>
								{t('rateAndInventory.RatePerPerson')}
							</Typography>
							<AppTextField
								control={adultRateMethod.control}
								name={`fPerPersonRates.${index}.rate`}
								inputType={InputType.amount}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											{getPropertyResponse?.data?.supplier.currency}
										</InputAdornment>
									),
								}}
							/>
						</Box>
					);
				})}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<AppAddButton
						onClick={() =>
							append({
								numOfAdults: 1,
								rate: '',
							})
						}
					>
						<AddIcon />
						{t('button.Add')}
					</AppAddButton>
				</Box>
				<Box>
					<Typography
						color={'gray'}
						sx={{ flexGrow: 1 }}
						fontWeight={'bold'}
						fontSize={'14px'}
					>
						{t('rateAndInventory.ApplyToFollowingRatePlans')}
					</Typography>
					<Stack
						display={'flex'}
						direction={'column'}
						alignItems={'flex-start'}
					>
						<AppGridMultipleCheckbox
							control={adultRateMethod.control}
							label={''}
							options={getRatePlanListItemsResponse?.data ?? []}
							name={'ratePlanIds'}
							columns={1}
							showMaxAdultCapacity
						/>
					</Stack>
				</Box>

				<Button
					variant={'contained'}
					onClick={adultRateMethod.handleSubmit(onSubmit)}
					fullWidth={true}
					disabled={submitted}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{submitted ? 'Applied' : 'Confirm'}
					</Typography>
				</Button>
			</StyledAccordionDetails>
		</StyledAccordion>
	);
}

import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import CustomerPaymentInfoForm from './CustomerPaymentInfoForm';
import CustomerPaymentInfoView from './CustomerPaymentInfoView';

interface CustomerPaymentInfoCardProps {
	initialType: FormCardType;
	isAmendment?: boolean;
}

export default function CustomerPaymentInfoCard({
	initialType,
	isAmendment,
}: CustomerPaymentInfoCardProps) {
	const { t } = useTranslation();

	return (
		<FormCard type={initialType} title={t('abk.CustomerPaymentInfo')}>
			{(() => {
				switch (initialType) {
					case FormCardType.view:
						return <CustomerPaymentInfoView />;
					case FormCardType.create:
						return <CustomerPaymentInfoForm type={initialType} />;
					case FormCardType.edit:
						return (
							<CustomerPaymentInfoForm
								type={initialType}
								isAmendment={isAmendment}
							/>
						);
					case FormCardType.specialEdit:
						return (
							<CustomerPaymentInfoForm
								type={initialType}
								isAmendment={isAmendment}
							/>
						);
				}
			})()}
		</FormCard>
	);
}

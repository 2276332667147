import React from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import { ReactComponent as CheckedIcon } from '../../../assets/icon/checkbox-checked.svg';
import { ReactComponent as CheckedAndDisabledIcon } from '../../../assets/icon/checkbox-checked-disabled.svg';
import { ReactComponent as UnCheckedIcon } from '../../../assets/icon/checkbox-unchecked.svg';
import { ReactComponent as UnCheckedAndDisabledIcon } from '../../../assets/icon/checkbox-unchecked-disabled.svg';
import { ReactComponent as IndeterminateIcon } from '../../../assets/icon/checkbox-indeterminate.svg';

const AppCheckbox: React.FC<CheckboxProps> = (props) => {
	const { ...checkboxProps } = props;

	return (
		<Checkbox
			{...checkboxProps}
			icon={props.disabled ? <UnCheckedAndDisabledIcon /> : <UnCheckedIcon />}
			indeterminateIcon={<IndeterminateIcon />}
			checkedIcon={
				props.disabled ? <CheckedAndDisabledIcon /> : <CheckedIcon />
			}
		/>
	);
};

export default AppCheckbox;

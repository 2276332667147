import { Image } from '../../../../api/DTO/gallery.interface';
import { useState } from 'react';
import {
	DndContext,
	DragOverlay,
	PointerSensor,
	useSensor,
	useSensors,
	DragStartEvent,
	DragEndEvent,
	TouchSensor,
	closestCenter,
} from '@dnd-kit/core';

import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { styled } from '@mui/material';
import ImageList from './ImageList';

interface DndImageListSectionProps {
	imageArray: Image[];
	handleDeleteImage: (id: string, index: number) => void;
	handleSelectImage: (id: string) => void;
	onUploadFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleRepositionImage: (
		activeIndex: number,
		overIndex: number,
		activeItem: Image
	) => void;
}

export default function DndImageListSection({
	imageArray,
	handleDeleteImage,
	handleSelectImage,
	onUploadFileChange,
	handleRepositionImage,
}: DndImageListSectionProps) {
	// for drag overlay
	const [activeItem, setActiveItem] = useState<Image>();

	// for input methods detection
	const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

	// triggered when dragging starts
	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event;
		setActiveItem(imageArray.find((item: Image) => item.id === active.id));
	};

	// triggered when dragging ends
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		if (!over) return;

		const activeItem = imageArray.find((item: Image) => item.id === active.id);
		const overItem = imageArray.find((item: Image) => item.id === over.id);

		if (!activeItem || !overItem) {
			return;
		}

		const activeIndex = imageArray.findIndex(
			(item: Image) => item.id === active.id
		);
		const overIndex = imageArray.findIndex(
			(item: Image) => item.id === over.id
		);

		if (activeIndex !== overIndex) {
			handleRepositionImage(activeIndex, overIndex, activeItem);
		}
		setActiveItem(undefined);
	};

	const handleDragCancel = () => {
		setActiveItem(undefined);
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
			onDragCancel={handleDragCancel}
		>
			<SortableContext items={imageArray} strategy={rectSortingStrategy}>
				<ImageList
					imageArray={imageArray}
					handleDeleteImage={handleDeleteImage}
					onUploadFileChange={onUploadFileChange}
					handleSelectImage={handleSelectImage}
				/>
			</SortableContext>
			<StyledDragOverlayContainer adjustScale>
				{activeItem ? (
					<LazyLoadImage
						src={activeItem.url}
						style={{
							width: '100%',
							height: 'auto',
						}}
					/>
				) : null}
			</StyledDragOverlayContainer>
		</DndContext>
	);
}

const StyledDragOverlayContainer = styled(DragOverlay)(() => ({
	transformOrigin: '0 0 ',
	width: '240px',
	height: '135px',
	background: 'black',
	borderRadius: '10px',
	display: 'flex',
	justifyContent: 'center',
	overflow: 'hidden',
	'& img': {
		objectFit: 'contain',
	},
}));

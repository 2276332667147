import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import HomeSectionForm from './HomeSectionForm';
import HomeSectionView from './HomeSectionView';
import { useGetSectionQuery } from '../../../../api/pageApiSlice';
import { useParams } from 'react-router-dom';

interface HomeSectionCardProps {
	initialType: FormCardType;
}

export default function HomeSectionCard({ initialType }: HomeSectionCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);
	const { sectionId } = useParams();

	const { data: getSectionResponse, isSuccess: isGetSectionSuccess } =
		useGetSectionQuery(undefined, {
			refetchOnMountOrArgChange: true,
		});

	let sectionNum;

	if (type === FormCardType.create && isGetSectionSuccess) {
		sectionNum = getSectionResponse?.data.length + 1;
	} else if (type === FormCardType.edit && isGetSectionSuccess) {
		sectionNum =
			getSectionResponse?.data.findIndex((item) => item.id === sectionId) + 1;
	}

	return (
		<FormCard type={type} title={`${t('page.HomeSection')} ${sectionNum}`}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <HomeSectionView setType={setType} />;
					case FormCardType.create:
						return <HomeSectionForm type={type} setType={setType} />;
					case FormCardType.edit:
						return <HomeSectionForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

import { defineAbility } from '@casl/ability';
import { UserRole } from '../api/enum/userRole.enum';

export default (role: number) =>
	defineAbility((can, cannot) => {
		// System User Managemant
		switch (role) {
			case UserRole.SYSTEM_ADMIN: {
				can('read', 'User');
				can('create', 'User');
				can('update', 'User');
				can('delete', 'User');
				break;
			}
			case UserRole.ADMIN: {
				can('read', 'User');
				break;
			}
		}
		cannot('update', 'User', { role: UserRole.SYSTEM_ADMIN });
		cannot('delete', 'User', { role: UserRole.SYSTEM_ADMIN });

		// Property Managemant
		switch (role) {
			case UserRole.SYSTEM_ADMIN: {
				can('read', 'Property');
				can('create', 'Property');
				break;
			}
			case UserRole.ADMIN: {
				can('read', 'Property');
				can('create', 'Property');
				break;
			}
			case UserRole.CS: {
				can('read', 'Property');
				break;
			}
			case UserRole.DE: {
				can('read', 'Property');
				can('create', 'Property');
				break;
			}
		}

		// Supplier Managemant
		switch (role) {
			case UserRole.SYSTEM_ADMIN: {
				can('read', 'Supplier');
				can('create', 'Supplier');
				can('update', 'Supplier');
				can('delete', 'Supplier');
				can('document', 'Supplier');
				break;
			}
			case UserRole.ADMIN: {
				can('create', 'Supplier');
				can('update', 'Supplier');
				can('document', 'Supplier');
				break;
			}
			case UserRole.CS: {
				break;
			}
			case UserRole.DE: {
				break;
			}
			case UserRole.ACC: {
				can('read', 'Supplier');
				can('document', 'Supplier');
				break;
			}
		}

		// Place Managemant
		switch (role) {
			case UserRole.SYSTEM_ADMIN: {
				can('read', 'Place');
				can('create', 'Place');
				can('update', 'Place');
				can('delete', 'Place');
				can('document', 'Place');
				break;
			}
			case UserRole.ADMIN: {
				can('create', 'Place');
				can('update', 'Place');
				can('document', 'Place');
				break;
			}
			case UserRole.CS: {
				break;
			}
			case UserRole.DE: {
				break;
			}
			case UserRole.ACC: {
				can('read', 'Place');
				can('document', 'Place');
				break;
			}
		}

		// Blog Managemant
		switch (role) {
			case UserRole.SYSTEM_ADMIN: {
				can('read', 'Blog');
				can('create', 'Blog');
				can('update', 'Blog');
				can('delete', 'Blog');
				can('document', 'Blog');
				break;
			}
			case UserRole.ADMIN: {
				can('create', 'Blog');
				can('update', 'Blog');
				can('document', 'Blog');
				break;
			}
			case UserRole.CS: {
				break;
			}
			case UserRole.DE: {
				break;
			}
			case UserRole.ACC: {
				can('read', 'Blog');
				can('document', 'Blog');
				break;
			}
		}

		// Abk Managemant
		switch (role) {
			case UserRole.SYSTEM_ADMIN: {
				can('read', 'Abk');
				can('create', 'Abk');
				can('update', 'Abk');
				can('delete', 'Abk');
				can('document', 'Abk');
				break;
			}
			case UserRole.ADMIN: {
				can('create', 'Abk');
				can('update', 'Abk');
				can('document', 'Abk');
				break;
			}
			case UserRole.CS: {
				break;
			}
			case UserRole.DE: {
				break;
			}
			case UserRole.ACC: {
				can('read', 'Abk');
				can('document', 'Abk');
				break;
			}
		}

		// Promotion Managemant
		switch (role) {
			case UserRole.SYSTEM_ADMIN: {
				can('read', 'Promotion');
				can('create', 'Promotion');
				can('update', 'Promotion');
				can('delete', 'Promotion');
				can('document', 'Promotion');
				break;
			}
			case UserRole.ADMIN: {
				can('create', 'Promotion');
				can('update', 'Promotion');
				can('document', 'Promotion');
				break;
			}
			case UserRole.CS: {
				break;
			}
			case UserRole.DE: {
				break;
			}
			case UserRole.ACC: {
				can('read', 'Promotion');
				can('document', 'Promotion');
				break;
			}
		}
		// Section Managemant
		switch (role) {
			case UserRole.SYSTEM_ADMIN: {
				can('read', 'Section');
				can('create', 'Section');
				can('update', 'Section');
				can('delete', 'Section');
				can('document', 'Section');
				break;
			}
			case UserRole.ADMIN: {
				can('create', 'Section');
				can('update', 'Section');
				can('document', 'Section');
				break;
			}
			case UserRole.CS: {
				break;
			}
			case UserRole.DE: {
				break;
			}
			case UserRole.ACC: {
				can('read', 'Section');
				can('document', 'Section');
				break;
			}
		}

		// Bk Managemant
		switch (role) {
			case UserRole.SYSTEM_ADMIN: {
				can('read', 'Bk');
				can('create', 'Bk');
				can('update', 'Bk');
				can('delete', 'Bk');
				can('document', 'Bk');
				break;
			}
			case UserRole.ADMIN: {
				can('create', 'Bk');
				can('update', 'Bk');
				can('document', 'Bk');
				break;
			}
			case UserRole.CS: {
				break;
			}
			case UserRole.DE: {
				break;
			}
			case UserRole.ACC: {
				can('read', 'Bk');
				can('document', 'Bk');
				break;
			}
		}
	});

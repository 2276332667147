import AppDropDownMenuV2 from '../../../../common/components/form-inputs/AppDropDownMenuV2';
import AppCheckboxGroupV2 from '../../../../common/components/form-inputs/AppCheckboxGroupV2';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { Stack } from '@mui/system';
import CustomDialog from '../../../../common/components/CustomDialog';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import Option from '../../../../common/types/option.type';

export type FormValues = {
	baseRoomId: string;
	applyRoomId?: string[];
};

export const defaultValues: DefaultValues<FormValues> = {
	baseRoomId: '',
	applyRoomId: [],
};

export interface ChangePasswordDialogProps {
	open: boolean;
	onClose: () => void;
	onQuickActionSubmit: (data: FormValues) => void;
	rooms: Option[];
}

export default function QuickActionDialog(props: ChangePasswordDialogProps) {
	const { t } = useTranslation();

	const { onClose, open, rooms, onQuickActionSubmit } = props;

	const handleClose = () => {
		onClose();
	};

	// form

	const { handleSubmit, control, watch } = useForm<FormValues>({
		mode: 'all',
		defaultValues,
	});

	const watchBaseRoomId = watch('baseRoomId');

	const [applyRoom, setApplyRoom] = useState('');

	useEffect(() => {
		if (watchBaseRoomId) {
			setApplyRoom(watchBaseRoomId);
		}
	});

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		onQuickActionSubmit(data);
		handleClose();
	};

	return (
		<CustomDialog
			title={t('facility.RoomFacilitiesQuickAction')}
			onClose={handleClose}
			open={open}
			content={
				<form onSubmit={handleSubmit(onSubmit)}>
					<Stack spacing='24px'>
						<AppDropDownMenuV2
							control={control}
							name='baseRoomId'
							label={t('facility.BaseSettingMessage')}
							options={rooms}
							required
						/>

						<AppCheckboxGroupV2
							name='applyRoomId'
							control={control}
							formLabel={t('facility.ApplySettingMessage')}
							options={rooms}
							disableOption={applyRoom}
						/>

						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.SaveChanges')}
							</Button>
							<Button onClick={handleClose} variant='outlined' color='info'>
								{t('button.Cancel')}
							</Button>
						</Stack>
					</Stack>
				</form>
			}
		></CustomDialog>
	);
}

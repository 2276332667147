import { Box, styled, Divider } from '@mui/material';
import AppSpaceSubtitleLayout from '../../../common/components/form-layout/AppSpaceSubtitleLayout';
import { useTranslation } from 'react-i18next';
import SpaceDisplayText from '../../../common/components/form-data-display/SpaceDisplayText';

interface RateBreakdownCardProps {
	data?: any;
	currency: string;
	mealType?: string;
	label: string;
}

export default function RateBreakdownCard({
	data,
	currency,
	mealType,
	label,
}: RateBreakdownCardProps) {
	const { t } = useTranslation();

	return (
		<>
			{data !== undefined && (
				<StyledBreakdownCard>
					<AppSpaceSubtitleLayout label={label} content={currency} />
					{data.perRoom && (
						<>
							<Box
								sx={{
									display: 'flex',
									gap: '5px',
									flexDirection: 'column',
									marginBottom: '15px',
								}}
							>
								{data.perRoom.basicRate !== undefined &&
									data.perRoom.basicRate !== null && (
										<SpaceDisplayText
											text1={t('bk.BaseRate')}
											text2={data.perRoom.basicRate?.toString()}
										/>
									)}
								{data.perRoom.roomSupplement !== undefined &&
									data.perRoom.roomSupplement !== null && (
										<SpaceDisplayText
											text1={t('bk.RoomSupplement')}
											text2={data.perRoom.roomSupplement?.toString()}
										/>
									)}
								{data.perRoom.ratePlanSupplement !== undefined &&
									data.perRoom.ratePlanSupplement !== null && (
										<SpaceDisplayText
											text1={t('bk.RatePlanSupplement')}
											text2={data.perRoom.ratePlanSupplement?.toString()}
										/>
									)}
							</Box>
							<Divider sx={{ marginBottom: '15px' }} />
							<Box
								sx={{
									display: 'flex',
									gap: '5px',
									flexDirection: 'column',
									marginBottom: '15px',
								}}
							>
								<AppSpaceSubtitleLayout
									label={`${t('bk.SubtotalOrRoomRate')}`}
									content={data.perRoom.roomRateAndSupplement?.toString()}
								/>
								{data.perRoom.taxAmount !== undefined &&
									data.perRoom.taxAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.Tax')}
											text2={data.perRoom.taxAmount?.toString()}
										/>
									)}
								{data.perRoom.serviceChargeAmount !== undefined &&
									data.perRoom.serviceChargeAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.ServiceCharge')}
											text2={data.perRoom.serviceChargeAmount?.toString()}
										/>
									)}
								{data.perRoom.markupAmount !== undefined &&
									data.perRoom.markupAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.Markup')}
											text2={data.perRoom.markupAmount?.toString()}
										/>
									)}
								{data.perRoom.agencyCommissionAmount !== undefined &&
									data.perRoom.agencyCommissionAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.Commission')}
											text2={`(${data.perRoom.agencyCommissionAmount?.toString()})`}
										/>
									)}
							</Box>
						</>
					)}
					{data.perPerson && (
						<>
							<Box
								sx={{
									display: 'flex',
									gap: '5px',
									flexDirection: 'column',
									marginBottom: '15px',
								}}
							>
								{data.perPerson.adult && (
									<SpaceDisplayText
										text1={
											data.perPerson.adult
												? `${t('bk.Adult')}: ${data.perPerson.adult.num}`
												: ''
										}
										text2={data.perPerson.adult.rate?.toString()}
									/>
								)}
								{data.perPerson.child && (
									<SpaceDisplayText
										text1={
											data.perPerson.child
												? `${t('bk.Child')}: ${data.perPerson.child.num}`
												: ''
										}
										text2={data.perPerson.child.rate?.toString()}
									/>
								)}
								{data.perPerson.toddler && (
									<SpaceDisplayText
										text1={
											data.perPerson.toddler
												? `${t('bk.Toddler')}: ${data.perPerson.toddler.num}`
												: ''
										}
										text2={data.perPerson.toddler.rate?.toString()}
									/>
								)}
								{data.perPerson.infant && (
									<SpaceDisplayText
										text1={
											data.perPerson.infant
												? `${t('bk.Infant')}: ${data.perPerson.infant.num}`
												: ''
										}
										text2={data.perPerson.infant.rate?.toString()}
									/>
								)}
							</Box>
							<Divider sx={{ marginBottom: '15px' }} />
							<Box
								sx={{
									display: 'flex',
									gap: '5px',
									flexDirection: 'column',
									marginBottom: '15px',
								}}
							>
								<AppSpaceSubtitleLayout
									label={`${t('bk.SubtotalOrRoomRate')}`}
									content={data.perPerson.subtotal?.toString()}
								/>
								{data.perPerson.taxAmount !== undefined &&
									data.perPerson.taxAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.Tax')}
											text2={data.perPerson.taxAmount?.toString()}
										/>
									)}
								{data.perPerson.serviceChargeAmount !== undefined &&
									data.perPerson.serviceChargeAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.ServiceCharge')}
											text2={data.perPerson.serviceChargeAmount?.toString()}
										/>
									)}
								{data.perPerson.markupAmount !== undefined &&
									data.perPerson.markupAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.Markup')}
											text2={data.perPerson.markupAmount?.toString()}
										/>
									)}
								{data.perPerson.agencyCommissionAmount !== undefined &&
									data.perPerson.agencyCommissionAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.Commission')}
											text2={`(${data.perPerson.agencyCommissionAmount?.toString()})`}
										/>
									)}
							</Box>
						</>
					)}

					{data.mealSupplement && (
						<>
							<Divider sx={{ marginBottom: '15px' }} />
							<Box
								sx={{
									display: 'flex',
									gap: '5px',
									flexDirection: 'column',
									marginBottom: '15px',
								}}
							>
								<AppSpaceSubtitleLayout
									label={`${t('bk.MealRate')} (${mealType})`}
									content={''}
								/>
								{data.mealSupplement.adult && (
									<SpaceDisplayText
										text1={
											data.mealSupplement.adult
												? `${t('bk.Adult')}: ${data.mealSupplement.adult.num}`
												: ''
										}
										text2={data.mealSupplement.adult.rate?.toString()}
									/>
								)}
								{data.mealSupplement.child && (
									<SpaceDisplayText
										text1={
											data.mealSupplement.child
												? `${t('bk.Child')}: ${data.mealSupplement.child.num}`
												: ''
										}
										text2={data.mealSupplement.child.rate?.toString()}
									/>
								)}
								{data.mealSupplement.toddler && (
									<SpaceDisplayText
										text1={
											data.mealSupplement.toddler
												? `${t('bk.Toddler')}: ${
														data.mealSupplement.toddler.num
												  }`
												: ''
										}
										text2={data.mealSupplement.toddler.rate?.toString()}
									/>
								)}
								{data.mealSupplement.infant && (
									<SpaceDisplayText
										text1={
											data.mealSupplement.infant
												? `${t('bk.Infant')}: ${data.mealSupplement.infant.num}`
												: ''
										}
										text2={data.mealSupplement.infant.rate?.toString()}
									/>
								)}
								{data.mealSupplement.markupAmount !== undefined &&
									data.mealSupplement.markupAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.Markup')}
											text2={data.mealSupplement.markupAmount?.toString()}
										/>
									)}
								{data.mealSupplement.agencyCommissionAmount !== undefined &&
									data.mealSupplement.agencyCommissionAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.Commission')}
											text2={`(${data.mealSupplement.agencyCommissionAmount?.toString()})`}
										/>
									)}
								{data.mealSupplement.taxAmount !== undefined &&
									data.mealSupplement.taxAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.Tax')}
											text2={data.mealSupplement.taxAmount?.toString()}
										/>
									)}
								{data.mealSupplement.serviceChargeAmount !== undefined &&
									data.mealSupplement.serviceChargeAmount !== null && (
										<SpaceDisplayText
											text1={t('bk.ServiceCharge')}
											text2={data.mealSupplement.serviceChargeAmount?.toString()}
										/>
									)}
							</Box>
						</>
					)}
					<Divider sx={{ marginBottom: '15px' }} />
					<AppSpaceSubtitleLayout
						label={`${t('bk.BaseRateTotal')}`}
						content={data.total.toString()}
						color={'#4EA4D3'}
					/>
				</StyledBreakdownCard>
			)}
		</>
	);
}

const StyledBreakdownCard = styled(Box)({
	backgroundColor: '#F5F5F5',
	padding: '20px ',
	paddingBottom: '0',
	borderRadius: '10px',
	marginBottom: '20px',
});

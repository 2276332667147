import { List, styled } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListCard from '../../../../common/components/card/ListCard';
import ListItemLinkV2 from '../../../../common/components/list/ListItemLinkV2';
import RoomList from './RoomList';

export default function LoggingListCard() {
	const { pathname } = useLocation();

	const { propertyId } = useParams();
	const { t } = useTranslation();

	return (
		<ListCard
			headerTitle={t('logging.Logging')}
			content={
				<StyledList>
					<ListItemLinkV2
						primarytext={t('logging.Overview')}
						to={`/property/${propertyId}/logging/overview`}
						selected={pathname.includes('/logging/overview')}
					/>
					<RoomList />

					{/* 2.0 admin portal do not have Add-on Feature */}
					{/* <ListItemLinkV2
						primarytext={t('logging.AddOn')}
						to={`/property/${propertyId}/logging/add-on`}
						selected={pathname.includes('/logging/add-on')}
					/> */}

					{/* wait api */}
					{/* <ListItemLinkV2
						primarytext={t('logging.RateAndInventory')}
						to={`/property/${propertyId}/logging/rate-and-inventory`}
						selected={pathname.includes('/logging/rate-and-inventory')}
					/> */}
				</StyledList>
			}
		/>
	);
}

const StyledList = styled(List)({
	marginTop: '20px',
	padding: 0,
});

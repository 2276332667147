import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import PageHeader from '../../common/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../api/userApiSlice';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../assets/icon/add.svg';
import { ReactComponent as ImportIcon } from '../../assets/icon/import.svg';
import { ReactComponent as ViewDetailIcon } from '../../assets/icon/view-detail.svg';
import { ReactComponent as SuppplierMyfamigoIcon } from '../../assets/icon/supplier-myfamigo.svg';
import { ReactComponent as SuppplierMeituanIcon } from '../../assets/icon/supplier-meituan.svg';
import { ReactComponent as SuppplierRakutenIcon } from '../../assets/icon/supplier-rakuten.svg';
import defineAbilityFor from '../../casl/defineAbility';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import {
	useGetFilteredPropertiesQuery,
	useUpdatePropertyStatusMutation,
} from '../../api/accommodationApiSlice';
import { useCallback, useEffect, useState } from 'react';
import { PropertySummaryItem } from '../../common/interface/propertySummaryItem.interface';
import { getPropertyStatus } from '../../utils/propertyHelper';
import CountryFlag from '../../common/components/form-data-display/CountryFlag';
import SmallerAppStatusButton from '../../common/components/SmallerAppStatusButton';
import AppDataGridHeaderWithSorting from '../../common/components/dataGrid/AppDataGridHeaderWithSorting';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { PropertyFilter } from '../../api/DTO/property.interface';
import PropertySummaryFilterSection from './components/PropertySummaryFilterSection';
import AppDataGrid, {
	initialPagination,
} from '../../common/components/dataGrid/AppDataGrid';
import qs from 'qs';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../utils/qsHelper';
import { DISPLAY_DATE_FORMAT } from '../../utils/dateHelper';
import dayjs from 'dayjs';
import { PropertyStatus } from '../../api/enum/propertyStatus.enum';
import { propertyCategoryOptions } from '../../api/enum/propertyCategories.enum';

export interface PropertyDataGrid {
	id: string;
	name: string;
	nameEn: string;
	status: { propertyId: string; status: PropertyStatus };
	majorArea: { name: string; countryCode: string };
	area: string;
	ownerShip: string;
	category: string;
	suppliersType: { directContract: boolean; apiSuppliers: number[] };
	createdAt: string;
}

export default function PropertySummaryPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const defaultPropertyFilter: PropertyFilter = {
		pagination: initialPagination,
		sortings: [],
		searchKey: '',
		statuses: undefined,
		countryId: undefined,
		majorAreaId: undefined,
		areaId: undefined,
		categories: undefined,
		hasOwner: undefined,
		ownerId: undefined,
	};

	const defaultValues: DefaultValues<PropertyFilter> = defaultPropertyFilter;

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const methods = useForm<PropertyFilter>({
		defaultValues,
	});

	const transformedSearchParams: PropertyFilter = {
		...defaultPropertyFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'searchKey',
			'statuses',
			'countryId',
			'majorAreaId',
			'areaId',
			'categories',
			'hasOwner',
			'ownerId',
		]),
	};

	const { data: propertySummary, isSuccess: isGetPropertySummarySuccess } =
		useGetFilteredPropertiesQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
		});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const propertyFilterFormResult = value as PropertyFilter;

			// append to url to reload page
			setSearchParams(qs.stringify(propertyFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: PropertySummaryItem[]) => {
		if (response == null) return;
		const newResponse: PropertyDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.id,
				name: item.name,
				nameEn: item.nameEn,
				status: { propertyId: item.id, status: item.status },
				majorArea: { name: item.majorArea, countryCode: item.countryCode },
				area: item.area,
				ownerShip: item.owner ?? t('property.Individual'),
				category:
					propertyCategoryOptions.find((option) => option.id == item.category)
						?.name ?? '',
				suppliersType: {
					directContract: item.directContract,
					apiSuppliers: item.apiSuppliers,
				},
				createdAt: dayjs(item.createdAt).format(DISPLAY_DATE_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const DataGridHeader = (params: GridColumnHeaderParams) => {
		return (
			<AppDataGridHeaderWithSorting
				headerName={params.colDef.headerName}
				sortingsPropertyName={params.colDef.field}
			/>
		);
	};

	const [updatePropertyStatusMutation] = useUpdatePropertyStatusMutation();

	const handleStatusChange = (propertyId: string, status: PropertyStatus) => {
		updatePropertyStatusMutation({
			propertyId: propertyId,
			body: {
				status:
					status === PropertyStatus.ACTIVE
						? PropertyStatus.INACTIVE
						: PropertyStatus.ACTIVE,
			},
		});
	};

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: t('property.Id'),
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			renderHeader: DataGridHeader,
		},
		{
			field: 'name',
			headerName: t('property.Name'),
			flex: 1,
			minWidth: 200,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
							fontSize: '0.75rem',
						}}
						variant='regular'
					>
						{params.row.nameEn}
					</Typography>
					<Typography
						sx={{
							display: 'block',
							fontSize: '0.625rem',
						}}
						variant='regular'
					>
						{params.row.name}
					</Typography>
				</Box>
			),
		},
		{
			field: 'status',
			headerName: t('property.Status'),
			width: 80, // 10(cell padding left) + 60(status fixed width) + 10(cell padding right)
			renderCell: (params) => (
				<SmallerAppStatusButton
					initialStatusI18nLabel={
						getPropertyStatus(params.row.status.status).i18nLabel
					}
					initialStatusValue={
						getPropertyStatus(params.row.status.status).isOnline
					}
					enableI18nLabel={t('property.Online')}
					disableI18nLabel={t('property.Offline')}
					statusOnChange={(event, activated) =>
						handleStatusChange(
							params.row.status.propertyId,
							params.row.status.status
						)
					}
				/>
			),
		},
		{
			field: 'majorArea',
			headerName: t('property.MajorArea'),
			flex: 1,
			minWidth: 95,
			renderCell: (params) => (
				<Stack direction={'row'} alignItems={'center'}>
					<CountryFlag
						iso2={params.row.majorArea.countryCode as string}
						size='medium'
						style={{ marginRight: '8px' }}
					/>
					<Typography
						variant='regular'
						sx={{
							fontSize: '12px',
							wordWrap: 'anywhere',
							whiteSpace: 'normal',
						}}
					>
						{params.row.majorArea.name}
					</Typography>
				</Stack>
			),
		},
		{
			field: 'area',
			headerName: t('property.Area'),
			flex: 1,
		},
		{
			field: 'ownerShip',
			headerName: t('property.OwnerShip'),
			flex: 1,
		},
		{
			field: 'category',
			headerName: t('property.Category'),
			flex: 1,
		},
		{
			field: 'suppliersType',
			headerName: t('property.Suppliers'),
			width: 112,
			renderCell: (params) => (
				<Stack direction={'row'} gap={'0.25rem'}>
					{params.row.suppliersType.directContract && <SuppplierMyfamigoIcon />}
					{(params.row.suppliersType.apiSuppliers as number[]).includes(0) && (
						<SuppplierRakutenIcon />
					)}
					{(params.row.suppliersType.apiSuppliers as number[]).includes(1) && (
						<SuppplierMeituanIcon />
					)}
				</Stack>
			),
		},
		{
			field: 'createdAt',
			headerName: t('property.DateCreated'),
			width: 140, // 10(cell padding left) + 120(header fixed width) + 10(cell padding right)
			renderHeader: DataGridHeader,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68, // 10(cell padding left) + 34(icon button width) + 24(cell padding right)
			renderCell: (params) => (
				<Link
					to={`/property/${params.id}`}
					state={{
						propertySummarySearchParams: qs.stringify(transformedSearchParams),
					}}
				>
					<IconButton color='primary'>
						<ViewDetailIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<PageHeader
				title={t('property.Property')}
				actionJsx={
					<Stack direction={'row'} alignItems={'center'} spacing='8px'>
						{ability.can('create', 'Property') && (
							<Button
								variant='contained'
								startIcon={<ImportIcon />}
								onClick={() =>
									navigate(`/property/import`, {
										state: {
											propertySummarySearchParams: qs.stringify(
												transformedSearchParams
											),
										},
									})
								}
							>
								{t('button.Import')}
							</Button>
						)}
						<Button
							variant='contained'
							startIcon={<AddIcon />}
							onClick={() =>
								navigate(`/property/add`, {
									state: {
										propertySummarySearchParams: qs.stringify(
											transformedSearchParams
										),
									},
								})
							}
						>
							{t('button.CreateProperty')}
						</Button>
					</Stack>
				}
			/>
			<FormProvider {...methods}>
				<PropertySummaryFilterSection
					isInitializedForm={isInitializedForm}
					transformedSearchParams={transformedSearchParams}
				/>
				<Box marginTop={'28px'}>
					<AppDataGrid
						loading={!isGetPropertySummarySuccess}
						rows={transformResponse(propertySummary?.data?.items) ?? []}
						columns={columns}
						rowCount={propertySummary?.data?.pagination.totalItems ?? 0}
						getRowId={(row) => row.id}
					/>
				</Box>
			</FormProvider>
		</>
	);
}

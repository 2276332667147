import Option from '../../common/types/option.type';

export enum Reviewer {
	TABELOG = 0,
	RURUBU = 1,
	TRIPADVISOR = 2,
	MICHELIN = 3,
}

export const ReviewerOption: Option[] = [
	{
		id: Reviewer.TABELOG,
		name: 'Tabelog',
	},
	{
		id: Reviewer.RURUBU,
		name: 'Rurubu',
	},
	{
		id: Reviewer.TRIPADVISOR,
		name: 'Tripadvisor',
	},
	{
		id: Reviewer.MICHELIN,
		name: 'Michelin',
	},
];

import { Box, Popper, PopperProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	DailyRoomConfigMap,
	RatePlanCalendar,
} from '../../../../api/DTO/propertyCalendar.interface';
import { useState } from 'react';
import React from 'react';
import dayjs from 'dayjs';
import { RateModel } from '../../../../api/enum/rateModel.enum';
import {
	RatePlanRow,
	getRatePlanRows,
	transformApiRatePlanDataToGridRowsProp,
} from './RatePlanRow.enum';
import { RatePlanStatusEnum } from '../../../../api/enum/ratePlan.enum';
import NonEditableTextCell from './NonEditableTextCell';
import RatePlanConditionCell from './RatePlanConditionCell';
import EditRatePlanStatusPopUp from './edit-pop-up/EditRatePlanStatusPopUp';
import EditRatePlanSellingRatePopUp from './edit-pop-up/EditRatePlanSellingRatePopUp';
import EditRatePlanAllotmentPopUp from './edit-pop-up/EditRatePlanAllotmentPopUp';
import EditRatePlanOnRequestAllotmentPopUp from './edit-pop-up/EditRatePlanOnRequestAllotmentPopUp';
import EditRatePlanSupplementRatePopUp from './edit-pop-up/EditRatePlanSupplementRatePopUp';
import EditRatePlanMarkupPercentagePopUp from './edit-pop-up/EditRatePlanMarkupPercentagePopUp';
import { MarkupType } from '../../../../api/enum/markupType.enum';
import { PriceMode } from '../../../../api/enum/priceMode.enum';
import { PerPersonRateType } from './PerPersonRateToggle';
import EditRatePlanAdultRatePopUp from './edit-pop-up/EditRatePlanAdultRatePopUp';
import { RatePlanStatus } from '../../../../api/enum/ratePlanStatus';
import DisabledCell from './DisabledCell';
import { CellType } from '../CellType.enum';
import { RatePlanChannel } from '../../../../api/enum/ratePlanChannel.enum';
import { Grid, GridCellProps, ScrollParams } from 'react-virtualized';

interface RatePlanGridProps {
	gridRef: React.LegacyRef<Grid> | undefined;
	width: number;
	height: number;
	onScroll?: ((params: ScrollParams) => any) | undefined;
	rateModel: RateModel;
	ratePlan: RatePlanCalendar;
	priceMode: PriceMode;
	sellAtOfficialRate?: boolean;
	perPersonRateType: PerPersonRateType;
	maxAdultCapacity: number;
	dailyRoomConfigs: DailyRoomConfigMap;
}

interface CellRange {
	field?: string;
	start?: string;
	end?: string;
}

export default function RatePlanGrid({
	gridRef,
	width,
	height,
	onScroll,
	rateModel,
	ratePlan,
	priceMode,
	sellAtOfficialRate,
	perPersonRateType,
	maxAdultCapacity,
	dailyRoomConfigs,
}: RatePlanGridProps) {
	const { t } = useTranslation();

	const [currentSelectedCellRange, setCurrentSelectedRange] =
		useState<CellRange>({ field: undefined, start: undefined, end: undefined });

	const [selectedCellRange, setSelectedRange] = useState<CellRange>({
		field: undefined,
		start: undefined,
		end: undefined,
	});

	function RatePlanStatusCell({
		ratePlanId,
		field,
		date,
		ratePlanStatus,
	}: {
		ratePlanId: string;
		field: string;
		date: string;
		ratePlanStatus: RatePlanStatusEnum;
	}) {
		const handleCellMouseDown = (field: string, date: string) => {
			setSelectedRange({ field: undefined, start: undefined, end: undefined });
			setCurrentSelectedRange({
				field: field,
				start: date,
				end: date,
			});
		};

		const handleCellMouseEnter = (field: string, date: string) => {
			if (
				currentSelectedCellRange.start !== undefined &&
				currentSelectedCellRange.end !== date
			) {
				setCurrentSelectedRange((prevRange) => ({
					field: prevRange.field,
					start: prevRange.start,
					end: date,
				}));
			}
		};

		const handleCellMouseUp = (event: React.MouseEvent<HTMLElement>) => {
			setSelectedRange({
				field: currentSelectedCellRange.field,
				start: cMinDate,
				end: cMaxDate,
			});
			setCurrentSelectedRange({
				field: undefined,
				start: undefined,
				end: undefined,
			});

			const getBoundingClientRect = () => {
				const element = document.getElementById(
					`${ratePlanId}_${date}_${field}`
				);
				return element
					? element.getBoundingClientRect()
					: new DOMRect(0, 0, 0, 0);
			};

			setAnchorEl({ getBoundingClientRect });
		};

		const cStart = dayjs(currentSelectedCellRange.start);

		const cEnd = dayjs(currentSelectedCellRange.end);

		const sStart = dayjs(selectedCellRange.start);

		const sEnd = dayjs(selectedCellRange.end);

		const dateInDayjs = dayjs(date);

		const cMinDate = cStart.isBefore(cEnd)
			? currentSelectedCellRange.start
			: currentSelectedCellRange.end;
		const sMinDate = sStart.isBefore(sEnd)
			? selectedCellRange.start
			: selectedCellRange.end;
		const cMaxDate = cStart.isBefore(cEnd)
			? currentSelectedCellRange.end
			: currentSelectedCellRange.start;
		const sMaxDate = sStart.isBefore(sEnd)
			? selectedCellRange.end
			: selectedCellRange.start;

		const isEndCell =
			(currentSelectedCellRange.field === field && cMaxDate === date) ||
			(selectedCellRange.field === field && sMaxDate === date);

		const isBetweenCell =
			(currentSelectedCellRange.field === field &&
				dateInDayjs.isBefore(cMaxDate) &&
				dateInDayjs.isAfter(cMinDate)) ||
			(selectedCellRange.field === field &&
				dateInDayjs.isBefore(sMaxDate) &&
				dateInDayjs.isAfter(sMinDate));

		const isStartCell =
			(currentSelectedCellRange.field === field && cMinDate === date) ||
			(selectedCellRange.field === field && sMinDate === date);

		return (
			<Box
				id={`${ratePlanId}_${date}_${field}`}
				sx={{
					':hover': !(isStartCell || isBetweenCell || isEndCell)
						? {
								backgroundColor: '#F2FAFF',
								borderTop: '1px solid #4EA4D3',
								borderRight: '1px solid #4EA4D3',
								borderBottom: '1px solid #4EA4D3',
								borderLeft: '1px solid #4EA4D3',
						  }
						: {},
					backgroundColor:
						isStartCell || isBetweenCell || isEndCell
							? '#F2FAFF'
							: ratePlan.displayStatus === RatePlanStatus.INACTIVE
							? '#EFEFEF'
							: ratePlanStatus === RatePlanStatusEnum.ACTIVE
							? '#D5F4CB'
							: '#EFEFEF',
					borderTop:
						isStartCell || isBetweenCell || isEndCell
							? '1px solid #4EA4D3'
							: 'none',
					borderRight: isEndCell ? '1px solid #4EA4D3' : 'none',
					borderBottom:
						isStartCell || isBetweenCell || isEndCell
							? '1px solid #4EA4D3'
							: 'none',
					borderLeft: isStartCell ? '1px solid #4EA4D3' : 'none',
					cursor: 'default',
				}}
				width={'100%'}
				height={'100%'}
				justifyContent={'center'}
			>
				<Box
					width={'100%'}
					height={'100%'}
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
					onMouseDown={() => {
						handleCellMouseDown(field, date);
					}}
					onMouseEnter={() => {
						handleCellMouseEnter(field, date);
					}}
					onMouseUp={(e) => {
						handleCellMouseUp(e);
					}}
				>
					<Typography
						className={'noselect'}
						sx={{
							display: 'block',
							fontSize: '12px',
							textAlign: 'center',
							lineHeight: '36px',
							color:
								ratePlan.displayStatus === RatePlanStatus.INACTIVE
									? '#808080'
									: ratePlanStatus === RatePlanStatusEnum.ACTIVE
									? '#79B765'
									: '#808080',
						}}
						variant='regular'
					>
						{ratePlanStatus === RatePlanStatusEnum.ACTIVE
							? 'Online'
							: 'Offline'}
					</Typography>
				</Box>
			</Box>
		);
	}

	function RangeSelectionTextCell({
		ratePlanId,
		field,
		date,
		value,
		isEditable,
	}: CellProps) {
		const handleCellMouseDown = (field: string, date: string) => {
			setSelectedRange({ field: undefined, start: undefined, end: undefined });
			setCurrentSelectedRange({
				field: field,
				start: date,
				end: date,
			});
		};

		const handleCellMouseEnter = (field: string, date: string) => {
			if (
				currentSelectedCellRange.start !== undefined &&
				currentSelectedCellRange.end !== date
			) {
				setCurrentSelectedRange((prevRange) => ({
					field: prevRange.field,
					start: prevRange.start,
					end: date,
				}));
			}
		};

		const handleCellMouseUp = () => {
			setSelectedRange({
				field: currentSelectedCellRange.field,
				start: cMinDate,
				end: cMaxDate,
			});
			setCurrentSelectedRange({
				field: undefined,
				start: undefined,
				end: undefined,
			});

			const getBoundingClientRect = () => {
				const element = document.getElementById(
					`${ratePlanId}_${date}_${field}`
				);
				return element
					? element.getBoundingClientRect()
					: new DOMRect(0, 0, 0, 0);
			};

			setAnchorEl({ getBoundingClientRect });
		};

		const cStart = dayjs(currentSelectedCellRange.start);

		const cEnd = dayjs(currentSelectedCellRange.end);

		const sStart = dayjs(selectedCellRange.start);

		const sEnd = dayjs(selectedCellRange.end);

		const dateInDayjs = dayjs(date);

		const cMinDate = cStart.isBefore(cEnd)
			? currentSelectedCellRange.start
			: currentSelectedCellRange.end;
		const sMinDate = sStart.isBefore(sEnd)
			? selectedCellRange.start
			: selectedCellRange.end;
		const cMaxDate = cStart.isBefore(cEnd)
			? currentSelectedCellRange.end
			: currentSelectedCellRange.start;
		const sMaxDate = sStart.isBefore(sEnd)
			? selectedCellRange.end
			: selectedCellRange.start;

		const isEndCell =
			(currentSelectedCellRange.field === field && cMaxDate === date) ||
			(selectedCellRange.field === field && sMaxDate === date);

		const isBetweenCell =
			(currentSelectedCellRange.field === field &&
				dateInDayjs.isBefore(cMaxDate) &&
				dateInDayjs.isAfter(cMinDate)) ||
			(selectedCellRange.field === field &&
				dateInDayjs.isBefore(sMaxDate) &&
				dateInDayjs.isAfter(sMinDate));

		const isStartCell =
			(currentSelectedCellRange.field === field && cMinDate === date) ||
			(selectedCellRange.field === field && sMinDate === date);

		return (
			<Box
				id={`${ratePlanId}_${date}_${field}`}
				sx={{
					':hover': !(isStartCell || isBetweenCell || isEndCell)
						? {
								backgroundColor: '#F2FAFF',
								borderTop: '1px solid #4EA4D3',
								borderRight: '1px solid #4EA4D3',
								borderBottom: '1px solid #4EA4D3',
								borderLeft: '1px solid #4EA4D3',
						  }
						: {},
					backgroundColor:
						isStartCell || isBetweenCell || isEndCell ? '#F2FAFF' : 'white',
					borderTop:
						isStartCell || isBetweenCell || isEndCell
							? '1px solid #4EA4D3'
							: 'none',
					borderRight: isEndCell ? '1px solid #4EA4D3' : 'none',
					borderBottom:
						isStartCell || isBetweenCell || isEndCell
							? '1px solid #4EA4D3'
							: 'none',
					borderLeft: isStartCell ? '1px solid #4EA4D3' : 'none',
					cursor: 'default',
				}}
				width={'100%'}
				height={'100%'}
				justifyContent={'center'}
			>
				<Box
					width={'100%'}
					height={'100%'}
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
					onMouseDown={() => {
						handleCellMouseDown(field, date);
					}}
					onMouseEnter={() => {
						handleCellMouseEnter(field, date);
					}}
					onMouseUp={() => {
						handleCellMouseUp();
					}}
				>
					<Typography
						className={'noselect'}
						sx={{
							display: 'block',
							fontSize: '12px',
							textAlign: 'center',
							lineHeight: '36px',
							color: isEditable ? '#2C2C2C' : '#808080',
						}}
						variant='regular'
					>
						{value}
					</Typography>
				</Box>
			</Box>
		);
	}

	const Cell = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
		const rows = transformApiRatePlanDataToGridRowsProp(
			rateModel,
			ratePlan,
			priceMode,
			perPersonRateType,
			maxAdultCapacity,
			sellAtOfficialRate
		);

		const id = rows[rowIndex].id;
		const cellType = rows[rowIndex].cellType;
		const date = Object.keys(ratePlan.dailyRatePlanConfigs)[columnIndex];
		const value = rows[rowIndex][date];

		return (
			<div
				key={key}
				style={{
					...style,
					borderLeft: columnIndex == 0 ? '1px solid #e0e0e0' : 'none',
					borderRight: '1px solid #e0e0e0',
					borderTop: rowIndex == 0 ? '1px solid #FFFFFF' : '1px solid #e0e0e0',
				}}
			>
				<>
					{cellType === CellType.RATE_PLAN_STATUS && (
						<RatePlanStatusCell
							ratePlanId={ratePlan.id}
							field={id}
							date={date}
							ratePlanStatus={value}
						/>
					)}

					{cellType === CellType.NON_EDITABLE_TEXT_CELL && (
						<NonEditableTextCell field={id} date={date} value={value} />
					)}
					{cellType === CellType.RANGE_SELECTION_TEXT_CELL &&
						(ratePlan.channel === RatePlanChannel.DIRECT &&
						((rateModel === RateModel.AGENCY &&
							id === RatePlanRow.SELLING_RATE) ||
							id === RatePlanRow.MARKUP_AMOUNT) ? (
							<>
								{dailyRoomConfigs[date].rate ? (
									<RangeSelectionTextCell
										ratePlanId={ratePlan.id}
										field={id}
										date={date}
										value={value}
										isEditable={true}
									/>
								) : (
									<DisabledCell
										field={id}
										date={date}
										value={value}
										tooltip={t('toolTip.PleaseFillInBaseRateFirst')}
									/>
								)}
							</>
						) : (
							<RangeSelectionTextCell
								ratePlanId={ratePlan.id}
								field={id}
								date={date}
								value={value}
								isEditable={true}
							/>
						))}

					{cellType === CellType.RATE_PLAN_CONDITION && (
						<RatePlanConditionCell field={id} date={date} condition={value} />
					)}
				</>
			</div>
		);
	};

	const [anchorEl, setAnchorEl] = React.useState<PopperProps['anchorEl']>(null);

	return (
		<>
			<div>
				<Popper
					open={selectedCellRange.field !== undefined}
					anchorEl={anchorEl}
				>
					{selectedCellRange.field === RatePlanRow.STATUS &&
						selectedCellRange.start &&
						selectedCellRange.end && (
							<EditRatePlanStatusPopUp
								ratePlanId={ratePlan.id}
								from={selectedCellRange.start}
								to={selectedCellRange.end}
								onCloseCallBack={() => {
									setSelectedRange({
										field: undefined,
										start: undefined,
										end: undefined,
									});
								}}
							/>
						)}
					{selectedCellRange.field === RatePlanRow.RATE_PLAN_SUPPLEMENT &&
						selectedCellRange.start &&
						selectedCellRange.end && (
							<EditRatePlanSupplementRatePopUp
								ratePlanId={ratePlan.id}
								from={selectedCellRange.start}
								to={selectedCellRange.end}
								onCloseCallBack={() => {
									setSelectedRange({
										field: undefined,
										start: undefined,
										end: undefined,
									});
								}}
							/>
						)}

					{selectedCellRange.field === RatePlanRow.SELLING_RATE &&
						selectedCellRange.start &&
						selectedCellRange.end && (
							<EditRatePlanSellingRatePopUp
								ratePlanId={ratePlan.id}
								from={selectedCellRange.start}
								to={selectedCellRange.end}
								onCloseCallBack={() => {
									setSelectedRange({
										field: undefined,
										start: undefined,
										end: undefined,
									});
								}}
							/>
						)}

					{selectedCellRange.field === RatePlanRow.MARKUP_PRECENTAGE &&
						selectedCellRange.start &&
						selectedCellRange.end && (
							<EditRatePlanMarkupPercentagePopUp
								ratePlanId={ratePlan.id}
								from={selectedCellRange.start}
								to={selectedCellRange.end}
								onCloseCallBack={() => {
									setSelectedRange({
										field: undefined,
										start: undefined,
										end: undefined,
									});
								}}
								markupType={MarkupType.PERCENTAGE}
							/>
						)}

					{selectedCellRange.field === RatePlanRow.MARKUP_AMOUNT &&
						selectedCellRange.start &&
						selectedCellRange.end && (
							<EditRatePlanMarkupPercentagePopUp
								ratePlanId={ratePlan.id}
								from={selectedCellRange.start}
								to={selectedCellRange.end}
								onCloseCallBack={() => {
									setSelectedRange({
										field: undefined,
										start: undefined,
										end: undefined,
									});
								}}
								markupType={MarkupType.FIXED}
							/>
						)}

					{selectedCellRange.field === RatePlanRow.ALLOTMENT &&
						selectedCellRange.start &&
						selectedCellRange.end && (
							<EditRatePlanAllotmentPopUp
								ratePlanId={ratePlan.id}
								from={selectedCellRange.start}
								to={selectedCellRange.end}
								onCloseCallBack={() => {
									setSelectedRange({
										field: undefined,
										start: undefined,
										end: undefined,
									});
								}}
							/>
						)}

					{selectedCellRange.field === RatePlanRow.ON_REQUEST_ALLOTMENT &&
						selectedCellRange.start &&
						selectedCellRange.end && (
							<EditRatePlanOnRequestAllotmentPopUp
								ratePlanId={ratePlan.id}
								from={selectedCellRange.start}
								to={selectedCellRange.end}
								onCloseCallBack={() => {
									setSelectedRange({
										field: undefined,
										start: undefined,
										end: undefined,
									});
								}}
							/>
						)}

					{selectedCellRange.field?.includes(RatePlanRow.PER_PERSON_RATE) &&
						selectedCellRange.start &&
						selectedCellRange.end && (
							<EditRatePlanAdultRatePopUp
								ratePlanId={ratePlan.id}
								from={selectedCellRange.start}
								to={selectedCellRange.end}
								onCloseCallBack={() => {
									setSelectedRange({
										field: undefined,
										start: undefined,
										end: undefined,
									});
								}}
								numOfAdults={Number(selectedCellRange.field?.substring(16))}
							/>
						)}
				</Popper>
			</div>
			<Grid
				ref={gridRef}
				containerStyle={{}}
				cellRenderer={Cell}
				columnCount={Object.keys(ratePlan.dailyRatePlanConfigs).length}
				columnWidth={74}
				height={height}
				autoHeight
				overscanColumnCount={1}
				overscanRowCount={1}
				rowCount={
					getRatePlanRows(
						ratePlan.channel,
						rateModel,
						ratePlan.enableSupplement,
						priceMode,
						perPersonRateType,
						maxAdultCapacity,
						sellAtOfficialRate
					).length
				}
				rowHeight={37}
				width={width}
				onScroll={onScroll}
			/>
		</>
	);
}

interface CellProps {
	ratePlanId: string;
	field: string;
	date: string;
	value: string;
	isEditable: boolean;
}

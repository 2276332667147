import { Box, Divider, Grid, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';

import { useContext } from 'react';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';

import { useGetAbkQuery } from '../../../../api/abkApiSlice';
import { AbkGenderOptions } from '../../../../api/enum/abkGender.enum';
import { Bed, bedOption } from '../../../../api/enum/bed.enum';
import {
	RateModel,
	rateModelOptions,
} from '../../../../api/enum/rateModel.enum';
import EditorDisplay from '../../../../common/components/editor/EditorDisplay';
import FlagLabelDisplay from '../../../../common/components/form-data-display/FlagLabelDisplay';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';
import { AbkVersionContext } from '../../AbkContainer';
import BreakdownCard from '../../components/BreakdownCard';
import GrandTotalBreakdownCard from '../../components/GrandTotalBreakdownCard';
import ProfitBreakdownCard from '../../components/ProfitBreakdownCard';
import ProfitOtherServiceBreakdownCard from '../../components/ProfitOtherServiceBreakdownCard';
import ProfitTotalBreakdownCard from '../../components/ProfitTotalBreakdownCard';
import { AbkIdContext } from '../ViewAbkPage';

export default function CustomerPaymentInfoView() {
	const { t } = useTranslation();
	const abkId = useContext(AbkIdContext);
	const [AbkVersion, setAbkVersion] = useContext(AbkVersionContext);

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getAbkResponse } = useGetAbkQuery({
		abk_id: abkId ?? '',
		version: AbkVersion,
	});

	return (
		<>
			<Stack position={'relative'}>
				<Grid container alignItems='stretch' spacing={''}>
					<Grid item xs={12} paddingX={'10px'}>
						<StyledRateTitle>{t('abk.ForStaffOnly')}</StyledRateTitle>
					</Grid>
					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						paddingX={'10px'}
						paddingY={'3px'}
						marginY={'-3px'}
					>
						<AppSubtitleLayout label={t('abk.PropertyInfo')} />
						<AppInputLayout label={t('abk.CountryOrRegion')}>
							{getAbkResponse?.data.rate.property.country ? (
								<FlagLabelDisplay
									iso2={getAbkResponse?.data.rate.property.country.iso2}
									label={getAbkResponse?.data.rate.property.country.name}
								/>
							) : (
								<NewDisplayText text={'-'} />
							)}
						</AppInputLayout>
						<AppInputLayout label={t('abk.MajorArea')}>
							<NewDisplayText
								text={getAbkResponse?.data.rate.property.majorArea?.name}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('abk.Area')}>
							<NewDisplayText
								text={getAbkResponse?.data.rate.property.area?.name}
							/>
						</AppInputLayout>

						<AppInputLayout label={t('abk.PropertyPhoneNumber')}>
							<NewDisplayText
								text={`${getAbkResponse?.data.rate.property.phoneNum ?? '-'}`}
							/>
						</AppInputLayout>
						{getAbkResponse?.data.rate.rateModel === RateModel.MERCHANT && (
							<Divider sx={{ marginBottom: '15px' }} />
						)}
					</Grid>
					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						paddingX={'10px'}
					>
						<AppSubtitleLayout label={t('abk.RateDetails')} />
						<AppInputLayout label={t('abk.NumberOfNights')}>
							<NewDisplayText
								text={getAbkResponse?.data.rate.numOfNights?.toString()}
							/>
						</AppInputLayout>
						<AppInputLayout label={t('abk.RateModel')}>
							<NewDisplayText
								text={
									rateModelOptions.find(
										(i) => i.id === getAbkResponse?.data.rate.rateModel
									)?.name
								}
							/>
						</AppInputLayout>
						{getAbkResponse?.data.rate.rateModel === RateModel.MERCHANT && (
							<Box
								sx={{
									paddingLeft: '20px',
									borderLeft: '5px solid #4EA4D3',
									marginBottom: '15px',
								}}
							>
								<AppInputLayout label={t('abk.CommissionRate')}>
									<NewDisplayText
										text={`${
											getAbkResponse?.data.rate.commissionLevel?.toString() ??
											'-'
										} %`}
									/>
								</AppInputLayout>
								<AppInputLayout label={t('abk.TaxRate')}>
									<NewDisplayText
										text={`${
											getAbkResponse?.data.rate.taxRate?.toString() ?? '-'
										} %`}
									/>
								</AppInputLayout>
								<AppInputLayout label={t('abk.ServiceChargeRate')}>
									<NewDisplayText
										text={`${
											getAbkResponse?.data.rate.serviceCharge?.toString() ?? '-'
										} %`}
									/>
								</AppInputLayout>
							</Box>
						)}
						{getAbkResponse?.data.rate.rateModel === RateModel.MERCHANT && (
							<>
								<Divider sx={{ marginBottom: '15px' }} />
								<AppSubtitleLayout label={t('abk.RoomRateDetails')} />
								<AppInputLayout label={t('abk.CommissionIncludedTax')}>
									<YesNoDisplay
										value={getAbkResponse?.data.rate.commissionTaxIncluded}
									/>
								</AppInputLayout>
								<AppInputLayout
									label={t('abk.CommissionIncludedServiceCharge')}
								>
									<YesNoDisplay
										value={
											getAbkResponse?.data.rate.commissionServiceChargeIncluded
										}
									/>
								</AppInputLayout>
							</>
						)}
					</Grid>
				</Grid>
				<Divider sx={{ marginBottom: '15px' }} />
				<Grid container alignItems='stretch' spacing={''} marginBottom={'15px'}>
					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						paddingX={'10px'}
						paddingY={'3px'}
						marginY={'-3px'}
						borderRight={'1px solid #E4E8EA'}
					>
						<Box>
							<StyledRateTitle>{t('abk.ForSuppliers')}</StyledRateTitle>

							<AppInputLayout label={t('abk.PropertyName')}>
								<NewDisplayText
									text={getAbkResponse?.data.rate.property.supName}
								/>
							</AppInputLayout>
							<AppInputLayout label={t('abk.AddressInDetails')}>
								<NewDisplayText
									text={getAbkResponse?.data.rate.property.supAddress}
								/>
							</AppInputLayout>
							<AppInputLayout
								label={t('abk.PropertyUniquePolicy')}
								labelAlignItemsFlexStart
							>
								<EditorDisplay
									editorStateString={
										getAbkResponse?.data.rate.property.supUniquePolicy
									}
								/>
							</AppInputLayout>
							<AppInputLayout
								label={t('abk.CancellationPolicy')}
								labelAlignItemsFlexStart
							>
								<EditorDisplay
									editorStateString={
										getAbkResponse?.data.rate.supCancellationPolicy
									}
								/>
							</AppInputLayout>
						</Box>
						<Divider sx={{ marginBottom: '15px' }} />
					</Grid>
					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						paddingX={'10px'}
						paddingY={'3px'}
						marginY={'-3px'}
						borderRight={'1px solid #E4E8EA'}
					>
						<Box>
							<StyledRateTitle>{t('abk.ForCustomers')}</StyledRateTitle>

							<AppInputLayout label={t('abk.PropertyName')}>
								<NewDisplayText
									text={getAbkResponse?.data.rate.property.cusName}
								/>
							</AppInputLayout>
							<AppInputLayout label={t('abk.AddressInDetails')}>
								<NewDisplayText
									text={getAbkResponse?.data.rate.property.cusAddress}
								/>
							</AppInputLayout>
							<AppInputLayout
								label={t('abk.PropertyUniquePolicy')}
								labelAlignItemsFlexStart
							>
								<EditorDisplay
									editorStateString={
										getAbkResponse?.data.rate.property.cusUniquePolicy
									}
								/>
							</AppInputLayout>
							<AppInputLayout
								label={t('abk.CancellationPolicy')}
								labelAlignItemsFlexStart
							>
								<EditorDisplay
									editorStateString={
										getAbkResponse?.data.rate.cusCancellationPolicy
									}
								/>
							</AppInputLayout>
						</Box>
						<Divider sx={{ marginBottom: '15px' }} />
					</Grid>

					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						paddingX={'10px'}
						paddingY={'3px'}
						marginY={'-3px'}
						borderRight={'1px solid #E4E8EA'}
					>
						{getAbkResponse?.data.rate.rooms.map((item, index) => {
							return (
								<Box key={item.id} flex={'auto'}>
									<AppSubtitleLayout
										label={t('abk.Room') + ` (${index + 1}) ` + t('abk.Info')}
									/>

									<AppInputLayout label={t('abk.RoomType')}>
										<NewDisplayText
											text={getAbkResponse?.data.rate.rooms[index].supName}
										/>
									</AppInputLayout>
									<AppInputLayout label={t('abk.BreakfastIncluded')}>
										<YesNoDisplay
											value={
												getAbkResponse?.data.rate.rooms[index].withBreakfast
											}
										/>
									</AppInputLayout>
									<AppInputLayout label={t('abk.RatePlanName')}>
										<NewDisplayText
											text={
												getAbkResponse?.data.rate.rooms[index].ratePlanSupName
											}
										/>
									</AppInputLayout>
									<AppInputLayout
										label={t('abk.RatePlanInclusion')}
										labelAlignItemsFlexStart
									>
										<EditorDisplay
											editorStateString={
												getAbkResponse?.data.rate.rooms[index]
													.ratePlanSupInclusion
											}
										/>
									</AppInputLayout>
									<AppInputLayout
										label={t('abk.SpecialOffer')}
										labelAlignItemsFlexStart
									>
										<EditorDisplay
											editorStateString={
												getAbkResponse?.data.rate.rooms[index].supSpecialOffer
											}
										/>
									</AppInputLayout>
									<AppInputLayout label={t('abk.NoOfGuests')}>
										<NewDisplayText
											text={getAbkResponse?.data.rate.rooms[
												index
											].guests.length.toString()}
										/>
									</AppInputLayout>
									<AppInputLayout
										label={t('abk.ListOfGuests')}
										labelAlignItemsFlexStart
									>
										{item.guests.length <= 0 && <NewDisplayText text={'-'} />}
										{item.guests.map((guestsItem, guestsIndex) => {
											return (
												<Box key={guestsItem.id} display={'flex'}>
													<Box width={'160px'}>
														<NewDisplayText
															text={
																`${guestsItem.firstName} ${guestsItem.lastName}` ??
																'-'
															}
														/>
													</Box>
													<Box width={'100px'}>
														<NewDisplayText
															text={
																`${
																	AbkGenderOptions.find(
																		(option) =>
																			option.id == Number(guestsItem.gender)
																	)?.name
																}` ?? '-'
															}
														/>
													</Box>
													<Box width={'160px'}>
														<NewDisplayText
															text={`${
																guestsItem.adult
																	? 'Adult'
																	: `Child : ${
																			guestsItem.age?.toString() ?? ''
																	  } years old`
															}`}
														/>
													</Box>
												</Box>
											);
										})}
									</AppInputLayout>

									<AppInputLayout
										label={t('abk.BedType')}
										labelAlignItemsFlexStart
									>
										{Object.keys(
											getAbkResponse?.data.rate.rooms[index]?.beddings
										).map((bedType, bedIndex) => {
											return (
												<NewDisplayText
													key={bedIndex}
													text={`${
														getAbkResponse?.data.rate.rooms[index]?.beddings[
															Number(bedType) as Bed
														]
													} ${
														bedOption.find(
															(option) => option.id == Number(bedType)
														)?.shortName
													}
													`}
												/>
											);
										})}
									</AppInputLayout>
									<AppInputLayout label={t('abk.SpecialRequest')}>
										<NewDisplayText
											text={
												getAbkResponse?.data.rate.rooms[index].supSpecialRequest
											}
										/>
									</AppInputLayout>
									{item.otherMeals.length > 0 && (
										<>
											<Typography
												fontSize={12}
												paddingBottom={'15px'}
												fontWeight={700}
												sx={{ textDecoration: 'underline' }}
											>
												{t('abk.OtherMeal')}
											</Typography>

											{item.otherMeals.map(
												(otherMealsItem, otherMealsIndex) => {
													return (
														<Box key={otherMealsItem.id}>
															<AppSubtitleLayout
																label={
																	t('abk.MealItem') +
																	` (${otherMealsIndex + 1}) `
																}
															/>
															<AppInputLayout label={t('abk.AdultsOrChildren')}>
																<NewDisplayText
																	text={
																		otherMealsItem.forAdult
																			? 'For Adults'
																			: 'For Children'
																	}
																/>
															</AppInputLayout>
															<AppInputLayout label={t('abk.Description')}>
																<NewDisplayText text={otherMealsItem.supName} />
															</AppInputLayout>
														</Box>
													);
												}
											)}
										</>
									)}
									{item.otherSupplements.length > 0 && (
										<>
											<Typography
												fontSize={12}
												fontWeight={700}
												paddingBottom={'15px'}
												sx={{ textDecoration: 'underline' }}
											>
												{t('abk.OtherSupplement')}
											</Typography>

											{item.otherSupplements.map(
												(otherSupplementsItem, otherSupplementsIndex) => {
													return (
														<Box key={otherSupplementsItem.id}>
															<AppSubtitleLayout
																label={
																	t('abk.SupplementItem') +
																	` (${otherSupplementsIndex + 1}) `
																}
															/>
															<AppInputLayout label={t('abk.Description')}>
																<NewDisplayText
																	text={otherSupplementsItem.supName ?? '-'}
																/>
															</AppInputLayout>
														</Box>
													);
												}
											)}
										</>
									)}
								</Box>
							);
						})}
						<Divider sx={{ marginBottom: '15px' }} />
					</Grid>
					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						paddingX={'10px'}
						paddingY={'3px'}
						marginY={'-3px'}
						borderRight={'1px solid #E4E8EA'}
					>
						{getAbkResponse?.data.rate.rooms.map((item, index) => {
							return (
								<Box key={item.id} flex={'auto'}>
									<AppSubtitleLayout
										label={t('abk.Room') + ` (${index + 1}) ` + t('abk.Info')}
									/>
									<AppInputLayout label={t('abk.RoomType')}>
										<NewDisplayText
											text={getAbkResponse?.data.rate.rooms[index].cusName}
										/>
									</AppInputLayout>
									<AppInputLayout label={t('abk.BreakfastIncluded')}>
										<YesNoDisplay
											value={
												getAbkResponse?.data.rate.rooms[index].withBreakfast
											}
										/>
									</AppInputLayout>
									<AppInputLayout label={t('abk.RatePlanName')}>
										<NewDisplayText
											text={
												getAbkResponse?.data.rate.rooms[index].ratePlanCusName
											}
										/>
									</AppInputLayout>
									<AppInputLayout
										label={t('abk.RatePlanInclusion')}
										labelAlignItemsFlexStart
									>
										<EditorDisplay
											editorStateString={
												getAbkResponse?.data.rate.rooms[index]
													.ratePlanCusInclusion
											}
										/>
									</AppInputLayout>
									<AppInputLayout
										label={t('abk.SpecialOffer')}
										labelAlignItemsFlexStart
									>
										<EditorDisplay
											editorStateString={
												getAbkResponse?.data.rate.rooms[index].cusSpecialOffer
											}
										/>
									</AppInputLayout>
									<AppInputLayout label={t('abk.NoOfGuests')}>
										<NewDisplayText
											text={getAbkResponse?.data.rate.rooms[
												index
											].guests.length.toString()}
										/>
									</AppInputLayout>
									<AppInputLayout
										label={t('abk.ListOfGuests')}
										labelAlignItemsFlexStart
									>
										{item.guests.length <= 0 && <NewDisplayText text={'-'} />}

										{item.guests.map((guestsItem, guestsIndex) => {
											return (
												<Box key={guestsItem.id} display={'flex'}>
													<Box width={'160px'}>
														<NewDisplayText
															text={
																`${guestsItem.firstName} ${guestsItem.lastName}` ??
																'-'
															}
														/>
													</Box>
													<Box width={'100px'}>
														<NewDisplayText
															text={
																`${
																	AbkGenderOptions.find(
																		(option) =>
																			option.id == Number(guestsItem.gender)
																	)?.name
																}` ?? '-'
															}
														/>
													</Box>
													<Box width={'160px'}>
														<NewDisplayText
															text={`${
																guestsItem.adult
																	? 'Adult'
																	: `Child : ${
																			guestsItem.age?.toString() ?? ''
																	  } years old`
															}`}
														/>
													</Box>
												</Box>
											);
										})}
									</AppInputLayout>
									<AppInputLayout
										label={t('abk.BedType')}
										labelAlignItemsFlexStart
									>
										{Object.keys(
											getAbkResponse?.data.rate.rooms[index]?.beddings
										).map((bedType, bedIndex) => {
											return (
												<NewDisplayText
													key={bedIndex}
													text={`${
														getAbkResponse?.data.rate.rooms[index]?.beddings[
															Number(bedType) as Bed
														]
													} ${
														bedOption.find(
															(option) => option.id == Number(bedType)
														)?.shortName
													}
													`}
												/>
											);
										})}
									</AppInputLayout>
									<AppInputLayout label={t('abk.SpecialRequest')}>
										<NewDisplayText
											text={
												getAbkResponse?.data.rate.rooms[index].cusSpecialRequest
											}
										/>
									</AppInputLayout>
									{item.otherMeals.length > 0 && (
										<>
											<Typography
												fontSize={12}
												fontWeight={700}
												paddingBottom={'15px'}
												sx={{ textDecoration: 'underline' }}
											>
												{t('abk.OtherMeal')}
											</Typography>

											{item.otherMeals.map(
												(otherMealsItem, otherMealsIndex) => {
													return (
														<Box key={otherMealsItem.id}>
															<AppSubtitleLayout
																label={
																	t('abk.MealItem') +
																	` (${otherMealsIndex + 1}) `
																}
															/>
															<AppInputLayout label={t('abk.AdultsOrChildren')}>
																<NewDisplayText
																	text={
																		otherMealsItem.forAdult
																			? 'For Adults'
																			: 'For Children'
																	}
																/>
															</AppInputLayout>
															<AppInputLayout label={t('abk.Description')}>
																<NewDisplayText text={otherMealsItem.cusName} />
															</AppInputLayout>
														</Box>
													);
												}
											)}
										</>
									)}
									{item.otherSupplements.length > 0 && (
										<>
											<Typography
												fontSize={12}
												paddingBottom={'15px'}
												fontWeight={700}
												sx={{ textDecoration: 'underline' }}
											>
												{t('abk.OtherSupplement')}
											</Typography>
											{item.otherSupplements.map(
												(otherSupplementsItem, otherSupplementsIndex) => {
													return (
														<Box key={otherSupplementsItem.id}>
															<AppSubtitleLayout
																label={
																	t('abk.SupplementItem') +
																	` (${otherSupplementsIndex + 1}) `
																}
															/>
															<AppInputLayout label={t('abk.Description')}>
																<NewDisplayText
																	text={otherSupplementsItem.cusName ?? '-'}
																/>
															</AppInputLayout>
														</Box>
													);
												}
											)}
										</>
									)}
								</Box>
							);
						})}
						<Divider sx={{ marginBottom: '15px' }} />
					</Grid>

					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						paddingX={'10px'}
						paddingY={'3px'}
						marginY={'-3px'}
						borderRight={'1px solid #E4E8EA'}
					>
						{getAbkResponse?.data.rate.otherServices.length !== 0 && (
							<>
								<AppSubtitleLayout label={t('abk.OtherService')} />
								{getAbkResponse?.data.rate.otherServices.map(
									(otherServiceItem) => {
										return (
											<AppInputLayout
												key={otherServiceItem.id}
												label={t('abk.Description')}
											>
												<NewDisplayText text={otherServiceItem.supName} />
											</AppInputLayout>
										);
									}
								)}
								<Divider sx={{ marginBottom: '15px' }} />
							</>
						)}
					</Grid>
					<Grid
						item
						xs={6}
						display='flex'
						flexDirection={'column'}
						paddingX={'10px'}
						paddingY={'3px'}
						marginY={'-3px'}
						borderRight={'1px solid #E4E8EA'}
					>
						{getAbkResponse?.data.rate.otherServices.length !== 0 && (
							<>
								<AppSubtitleLayout label={t('abk.OtherService')} />
								{getAbkResponse?.data.rate.otherServices.map(
									(otherServiceItem) => {
										return (
											<AppInputLayout
												key={otherServiceItem.id}
												label={t('abk.Description')}
											>
												<NewDisplayText text={otherServiceItem.cusName} />
											</AppInputLayout>
										);
									}
								)}
								<Divider sx={{ marginBottom: '15px' }} />
							</>
						)}
					</Grid>

					{getAbkResponse?.data.breakdown && (
						<>
							<Grid
								item
								xs={6}
								display='flex'
								flexDirection={'column'}
								paddingX={'10px'}
								paddingY={'3px'}
								marginY={'-3px'}
								borderRight={'1px solid #E4E8EA'}
							>
								<AppSubtitleLayout label={t('abk.Breakdown')} />
								{getAbkResponse?.data.breakdown?.supplier?.rooms.map(
									(breakdownRoomItem: any, breakdownRoomIndex) => {
										return (
											<BreakdownCard
												key={breakdownRoomIndex}
												data={breakdownRoomItem}
												label={`${t('abk.Room')} (${breakdownRoomIndex + 1})`}
												currency={
													getAbkResponse?.data.breakdown?.supplier?.currency ??
													'-'
												}
											/>
										);
									}
								)}
								{getAbkResponse?.data.breakdown?.supplier?.otherService.items
									?.length !== 0 && (
									<BreakdownCard
										data={
											getAbkResponse?.data.breakdown?.supplier?.otherService
										}
										label={`${t('abk.OtherService')} `}
										currency={
											getAbkResponse?.data.breakdown?.supplier?.currency ?? '-'
										}
									/>
								)}
								<GrandTotalBreakdownCard
									data={getAbkResponse?.data.breakdown?.supplier?.total}
									label={`${t('abk.Total')}`}
									currency={
										getAbkResponse?.data.breakdown?.supplier?.currency ?? '-'
									}
								/>
							</Grid>

							<Grid
								item
								xs={6}
								display='flex'
								flexDirection={'column'}
								paddingX={'10px'}
								paddingY={'3px'}
								marginY={'-3px'}
								borderRight={'1px solid #E4E8EA'}
							>
								<AppSubtitleLayout label={t('abk.Breakdown')} />
								{getAbkResponse?.data.breakdown?.customer?.rooms.map(
									(breakdownRoomItem: any, breakdownRoomIndex) => {
										return (
											<BreakdownCard
												key={breakdownRoomIndex}
												data={breakdownRoomItem}
												label={`${t('abk.Room')} (${breakdownRoomIndex + 1})`}
												currency={
													getAbkResponse?.data.breakdown?.customer?.currency ??
													'-'
												}
											/>
										);
									}
								)}
								{getAbkResponse?.data.breakdown?.customer?.otherService.items
									?.length !== 0 && (
									<BreakdownCard
										data={
											getAbkResponse?.data.breakdown?.customer?.otherService
										}
										label={`${t('abk.OtherService')} `}
										currency={
											getAbkResponse?.data.breakdown?.customer?.currency ?? '-'
										}
									/>
								)}
								<GrandTotalBreakdownCard
									data={getAbkResponse?.data.breakdown?.customer?.total}
									label={`${t('abk.Total')} `}
									currency={
										getAbkResponse?.data.breakdown?.customer?.currency ?? '-'
									}
								/>
							</Grid>
						</>
					)}
				</Grid>

				{getAbkResponse?.data.breakdown && (
					<>
						<Divider sx={{ marginBottom: '15px' }} />

						<Grid container alignItems='stretch' spacing={''}>
							<Grid
								item
								xs={6}
								display='flex'
								flexDirection={'column'}
								paddingX={'10px'}
								paddingY={'3px'}
								marginY={'-3px'}
							>
								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<StyledRateTitle>{t('abk.ProfitEstimation')}</StyledRateTitle>
									<Box sx={{ display: 'flex', gap: '5px' }}>
										<Label
											sx={{
												display: 'flex',
												alignItems: 'center',
												marginBottom: '20px',
											}}
										>
											{`${t('abk.ExchangeRate')}: `}
										</Label>
										<Typography
											sx={{
												display: 'flex',
												alignItems: 'center',
												marginBottom: '20px',
											}}
											fontSize='12px'
											color='#2C2C2C'
											whiteSpace={'break-spaces'}
										>
											{`${getAbkResponse?.data.breakdown?.supplier?.currency} to HKD - ${getAbkResponse?.data.breakdown?.supplier?.exchangeRate} | ${getAbkResponse?.data.breakdown?.customer?.currency} to HKD - ${getAbkResponse?.data.breakdown?.customer?.exchangeRate}`}
										</Typography>
									</Box>
								</Box>

								{getAbkResponse?.data.breakdown?.profit?.rooms.map(
									(breakdownRoomItem: any, breakdownRoomIndex) => {
										return (
											<ProfitBreakdownCard
												key={breakdownRoomIndex}
												data={breakdownRoomItem}
												label={`${t('abk.Room')} (${breakdownRoomIndex + 1})`}
												currency={
													getAbkResponse?.data.breakdown?.profit?.currency ??
													'-'
												}
											/>
										);
									}
								)}
								{(getAbkResponse?.data.breakdown?.profit?.otherService
									.supOtherServices.length !== 0 ||
									getAbkResponse?.data.breakdown?.profit?.otherService
										.selfOtherServices.length !== 0) && (
									<ProfitOtherServiceBreakdownCard
										data={getAbkResponse?.data.breakdown?.profit?.otherService}
										label={`${t('abk.OtherService')}`}
										currency={
											getAbkResponse?.data.breakdown?.profit?.currency ?? '-'
										}
									/>
								)}

								<ProfitTotalBreakdownCard
									data={getAbkResponse?.data.breakdown?.profit?.total}
									label={`${t('abk.ProfitTotal')} `}
									currency={
										getAbkResponse?.data.breakdown?.profit?.currency ?? '-'
									}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</Stack>
		</>
	);
}

const StyledRateTitle = styled(Typography)({
	color: '#4EA4D3',
	fontWeight: 700,
	fontSize: '16px',
	marginBottom: '20px',
});

const Label = styled(Typography)({
	fontSize: '12px',
	color: '#808080',
	whiteSpace: 'pre-line',
});

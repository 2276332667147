import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface YearsRangeDisplayProps {
	from?: string;
	to?: string;
}

export function YearsRangeDisplay({ from, to }: YearsRangeDisplayProps) {
	const { t } = useTranslation();
	return (
		<Stack direction={'row'}>
			<Typography fontSize='12px'>{t('common.From')}</Typography>
			<Typography fontSize='12px' marginLeft={'14px'} minWidth={'15px'}>
				{from}
			</Typography>
			<Typography fontSize='12px' marginLeft={'18px'}>
				{t('common.YearsOld')}
			</Typography>
			<Typography fontSize='12px' marginLeft={'27px'}>
				{t('common.To')}
			</Typography>
			<Typography fontSize='12px' marginLeft={'14px'} minWidth={'15px'}>
				{to}
			</Typography>
			<Typography fontSize='12px' marginLeft={'18px'}>
				{t('common.YearsOld')}
			</Typography>
		</Stack>
	);
}

import { useParams } from 'react-router-dom';
import FullScreenLoader from '../../../common/components/FullScreenLoader';
import { createContext } from 'react';
import { Stack, Grid } from '@mui/material';
import { FormCardType } from '../../../common/components/card/FormCard';
import BlogPostCard from './blogPost/blogPostCard';
import ProductMappingCard from './productMapping/productMappingCard';
import { useGetBlogPostQuery } from '../../../api/contentApiSlice';

export const BlogIdContext = createContext('');

export default function ViewBlogPostPage() {
	const { blogId } = useParams();

	const {
		isLoading: isGetBlogPostLoading,
		isSuccess: isGetBlogPostSuccess,
		isError: isGetBlogPostError,
	} = useGetBlogPostQuery(blogId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	let pageContent;
	if (isGetBlogPostLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetBlogPostSuccess) {
		pageContent = (
			<>
				<BlogIdContext.Provider value={blogId ?? ''}>
					<Stack spacing='16px'>
						<Grid container alignItems='stretch' spacing={'10px'}>
							<Grid item xs zeroMinWidth>
								<BlogPostCard initialType={FormCardType.view} />
							</Grid>
							<Grid item style={{ width: '383px' }}>
								<ProductMappingCard initialType={FormCardType.view} />
							</Grid>
						</Grid>
					</Stack>
				</BlogIdContext.Provider>
			</>
		);
	} else if (isGetBlogPostError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}

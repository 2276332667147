import { Box, Link } from '@mui/material';
import { ReactComponent as LinkIcon } from '../../../assets/icon/link.svg';

interface LinkDisplayTextProps {
	link?: string;
}

export default function LinkDisplayText({ link }: LinkDisplayTextProps) {
	let url = '';
	if (link) {
		if (!/^https?:\/\//i.test(link)) {
			url = 'http://' + link;
		} else url = link;
	} else '';

	return (
		<Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
			{link && <LinkIcon style={{ flex: '0 0 12px' }} color='#32A5F1' />}
			<Link
				href={url}
				rel='noreferrer'
				target='_blank'
				underline='none'
				fontSize='12px'
				color='#32A5F1'
				sx={{ wordBreak: 'break-word' }}
			>
				{link}
			</Link>
		</Box>
	);
}

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation, useParams, Outlet } from 'react-router-dom';
import PageHeader from '../../common/components/PageHeader';
import FullScreenLoader from '../../common/components/FullScreenLoader';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import AppStatusToggle from '../../common/components/AppStatusToggle';
import { useAppDispatch } from '../../redux-hooks';
import { showModal } from '../../common/components/modal/modalSlice';
import { useGetBlogPostQuery } from '../../api/contentApiSlice';
import { getBlogPostStatus } from '../../utils/contentHelper';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '../../utils/dateHelper';

export default function BlogPostContainer() {
	const { t } = useTranslation();
	const { blogId } = useParams();
	const { state } = useLocation();
	const dispatch = useAppDispatch();

	const [blogPostSummarySearchParams, setblogPostSummarySearchParams] =
		useState<string | undefined>(undefined);

	useEffect(() => {
		if (state?.blogPostSummarySearchParams) {
			setblogPostSummarySearchParams(state?.blogPostSummarySearchParams);
		}
	}, []);

	const {
		data: getBlogPostResponse,
		isLoading: isGetBlogPostLoading,
		isSuccess: itGetBlogPostSuccess,
		isError: isGetBlogPostError,
	} = useGetBlogPostQuery(blogId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	const handleStatusChange = (
		event: React.MouseEvent<HTMLElement>,
		activated: boolean | null
	) => {
		if (activated == null) return;
		if (activated) {
			dispatch(
				showModal({
					modalType: 'ACTIVATE_BLOG',
					modalProps: {
						blogId: blogId,
						title: getBlogPostResponse?.data.title,
					},
				})
			);
		} else {
			dispatch(
				showModal({
					modalType: 'DEACTIVATE_BLOG',
					modalProps: {
						blogId: blogId,
						title: getBlogPostResponse?.data.title,
					},
				})
			);
		}
	};

	let pageContent;
	if (isGetBlogPostLoading) {
		pageContent = <FullScreenLoader />;
	} else if (itGetBlogPostSuccess) {
		pageContent = (
			<Box sx={{ width: '100%' }}>
				<PageHeader
					showBackButton={true}
					backPath={
						blogPostSummarySearchParams
							? `/blog?${blogPostSummarySearchParams}`
							: '/blog'
					}
					title={getBlogPostResponse.data.title}
					subtitleJsx={
						<Typography fontSize='12px' color='#808080'>{`${t(
							'common.Lastupdate'
						)}: ${
							getBlogPostResponse?.data.updatedAt
								? dayjs(getBlogPostResponse?.data.updatedAt).format(
										DISPLAY_DATE_TIME_FORMAT
								  )
								: '-'
						}`}</Typography>
					}
					actionJsx={
						<AppStatusToggle
							enableI18nLabel={t('blogPost.Online')}
							disableI18nLabel={t('blogPost.Offline')}
							initialValue={
								getBlogPostStatus(getBlogPostResponse?.data.status).isOnline
							}
							statusOnChange={handleStatusChange}
							label={t('blogPost.BlogPostStatus')}
						/>
					}
				/>
				<Outlet />
			</Box>
		);
	} else if (isGetBlogPostError) {
		pageContent = <div>API Error</div>;
	}
	return <div>{pageContent}</div>;
}

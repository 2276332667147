import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Chip, Typography, styled } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { SectionTag, TagPosition } from '../../../api/DTO/page.interface';
import {
	useGetSectionTagPositionQuery,
	useUpdateSectionTagPositionMutation,
} from '../../../api/pageApiSlice';
import { ReactComponent as TagOrderIcon } from '../../../assets/icon/tag-order.svg';
import CustomDialog from '../../../common/components/CustomDialog';

export const defaultValues: DefaultValues<SectionTag> = {
	id: '',
	title: '',
	tags: [],
};

export interface OrderSectionTagDialogProps {
	sectionId: string;
}

export default function OrderSectionTagDialog({
	sectionId,
	...props
}: OrderSectionTagDialogProps) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const { data: getSectionTagPositionResponse } = useGetSectionTagPositionQuery(
		sectionId ?? ''
	);

	const [
		UpdateSectionTagPosition,
		{
			data: UpdateSectionTagPositionResponse,
			isSuccess: isUpdateSectionTagPositionSuccess,
		},
	] = useUpdateSectionTagPositionMutation();

	// form

	const formSchema = Yup.object().shape({
		tags: Yup.array().min(
			getSectionTagPositionResponse?.data.tags.length ?? 0,
			t('errorMessage.selectAllTagOrder')
		),
	});

	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<SectionTag>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const errorMessage = errors.tags?.message;

	const [selectedTags, setSelectedTags] = useState<TagPosition[]>(
		getSectionTagPositionResponse?.data.tags ?? []
	);

	const [selectedTagsId, setSelectedTagsId] = useState<string[]>([]);

	useEffect(() => {
		if (getSectionTagPositionResponse?.data) {
			setSelectedTags(getSectionTagPositionResponse?.data.tags);
			setSelectedTagsId(
				getSectionTagPositionResponse?.data.tags.map((tag) => tag.id)
			);
		}
	}, [getSectionTagPositionResponse?.data]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleClick = (tag: TagPosition) => {
		const arr: TagPosition[] = selectedTags ?? [];
		const found = arr.some((el) => el.id === tag.id);
		if (!found) {
			arr.push(tag);
			setSelectedTags(arr);
			setSelectedTagsId(arr.map((tag) => tag.id));
		} else {
			setSelectedTags(arr.filter((item) => item.id !== tag.id));
			setSelectedTagsId(
				arr.filter((item) => item.id !== tag.id).map((tag) => tag.id)
			);
		}
	};

	// Set position following the order of selectedTags array
	const result = selectedTags.map((tag, index) => ({
		...tag,
		position: index + 1,
	}));

	useEffect(() => {
		setValue('tags', result);
	}, [result]);

	useEffect(() => {
		if (isUpdateSectionTagPositionSuccess) handleClose();
	}, [isUpdateSectionTagPositionSuccess]);

	const onSubmit: SubmitHandler<SectionTag> = (data) => {
		data.id = getSectionTagPositionResponse?.data.id ?? '';
		data.title = getSectionTagPositionResponse?.data.title ?? '';
		UpdateSectionTagPosition({
			sectionId: getSectionTagPositionResponse?.data.id ?? '',
			body: data,
		});
	};

	return (
		<>
			<Button
				variant='contained'
				startIcon={<TagOrderIcon />}
				onClick={() => setOpen(true)}
			>
				{t('button.TagOrder')}
			</Button>
			<CustomDialog
				title={getSectionTagPositionResponse?.data?.title ?? ''}
				onClose={handleClose}
				open={open}
				content={
					<>
						<form onSubmit={handleSubmit(onSubmit)}>
							<StyledModelSubTitle>
								{t('page.OrderTagsModalMsg')}
							</StyledModelSubTitle>

							<Box
								sx={{
									display: 'flex',
									flexWrap: 'wrap',
									gap: '5px 2px',
								}}
							>
								{getSectionTagPositionResponse?.data.tags.map(
									(option: TagPosition) => (
										<Box key={option.id} position={'relative'}>
											<Chip
												label={option.name}
												onClick={() => handleClick(option)}
												variant={
													selectedTagsId.includes(option.id)
														? 'filled'
														: 'outlined'
												}
												color='primary'
												sx={{
													height: '28px',
													fontSize: '12px',
													marginRight: '6px',
													marginBottom: '6px',
												}}
												clickable
											/>
											{selectedTagsId.findIndex(
												(tagId) => tagId === option.id
											) !== -1 && (
												<Box
													sx={{
														position: 'absolute',
														top: '-5px',
														right: '0',
														fontSize: '10px',
														color: '#32A5F1',
														border: '1px solid #32A5F1',
														background: 'white',
														borderRadius: '50%',
														width: '15px',
														height: '15px',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													{selectedTagsId.findIndex(
														(tagId) => tagId === option.id
													) + 1}
												</Box>
											)}
										</Box>
									)
								)}
							</Box>
							<Error>{errorMessage ?? '\n'}</Error>

							<Stack direction='row-reverse' spacing='12px'>
								<Button
									type='submit'
									variant='contained'
									color='success'
									sx={{
										width: '100%',
									}}
								>
									{t('button.Confirm')}
								</Button>
							</Stack>
						</form>
					</>
				}
			/>
		</>
	);
}

const StyledModelSubTitle = styled(Typography)({
	color: '#808080',
	fontSize: '14px',
	fontWeight: 700,
	marginBottom: '15px',
});

const Error = styled(Typography)(({ theme }) => ({
	minHeight: '15px',
	paddingTop: '8px',
	fontSize: '12px',
	lineHeight: '15px',
	color: ' #DA5150',
	whiteSpace: 'pre-line',
	paddingBottom: '10px',
}));

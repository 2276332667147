import { Box, styled, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';
import { ReactComponent as CircleInfoIcon } from '../../../assets/icon/circleInfo.svg';

interface AppSpaceSubtitleLayoutProps {
	label?: string;
	infoPopUp?: ReactNode;
	content?: string;
	color?: string;
	link?: ReactNode;
}

export const Label = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'color',
})<{
	color?: string;
}>(({ color }) => ({
	fontSize: '12px',
	fontWeight: 700,
	color: color ? color : '#2C2C2C',
	whiteSpace: 'pre-line',
}));

export default function AppSpaceSubtitleLayout({
	label,
	infoPopUp,
	content,
	color,
	link,
	...props
}: React.PropsWithChildren<AppSpaceSubtitleLayoutProps>) {
	return (
		<Box>
			{label && (
				<Box
					sx={{ display: 'flex', gap: '20px', justifyContent: 'space-between' }}
				>
					<Stack
						width={'160px'}
						direction={'row'}
						alignItems={'center'}
						spacing='5px'
						flexWrap={'wrap'}
						marginBottom='15px'
					>
						<Label color={color}>{label}</Label>
						{!!infoPopUp && (
							<Box component={'span'} sx={{ marginLeft: '5px' }}>
								<Tooltip title={infoPopUp} placement='bottom-start'>
									<CircleInfoIcon />
								</Tooltip>
							</Box>
						)}
					</Stack>
					<Label color={color}>{content}</Label>
					{link}
				</Box>
			)}
			<Stack sx={{ width: '100%' }}>{props.children}</Stack>
		</Box>
	);
}

import App from './App';
import './i18n';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
} from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import AddBooking from './pages/booking/add-booking.component';
import SystemUserDetailPage from './pages/system-user/SystemUserDetailPage';
import BookingSummaryPage from './pages/booking/BookingSummaryPage';
import TestForm from './pages/test/TestForm';
import TestFullCalendar from './pages/test/TestFullCalendar';
import CreateUserPage from './pages/system-user/CreateUserPage';
import LoginPage from './pages/auth/LoginPage';
import NavigationDrawer from './common/components/NavigationDrawer';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import EmailVerifyPage from './pages/auth/EmailVerifyPage';
import RequireAuth from './pages/auth/RequireAuth';
import { lazy, Suspense } from 'react';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import CreatePropertyPage from './pages/property/CreatePropertyPage';
import PropertyContainer from './pages/property/PropertyContainer';
import CreateSupplierPage from './pages/supplier/CreateSupplierPage';
import SupplierContainer from './pages/supplier/SupplierContainer';
import ViewPropertyPage from './pages/property/overview/ViewPropertyPage';
import RoomContainer from './pages/property/room/RoomContainer';
import ViewRoomPage from './pages/property/room/ViewRoomPage';
import CreateRoomPage from './pages/property/room/CreateRoomPage';
import RatePlanSummaryPage from './pages/property/room/ratePlan/RatePlanSummary';
import RatePlanDetailPage from './pages/property/room/ratePlan/RatePlanDetailPage';
import CreateRatePlanPage from './pages/property/room/ratePlan/CreateRatePlanPage';
import ViewSupplierPage from './pages/supplier/overview/ViewSupplierPage';
import ViewFaqPage from './pages/property/faq/ViewFaqPage';
import PropertySummaryPage from './pages/property/PropertySummaryPage';
import NavigateWithState from './common/components/NavigateWithState';
import ViewFacilityPage from './pages/property/facility/ViewFacilityPage';
import CreatePlacePage from './pages/content/CreatePlacePage';
import ViewPlacePage from './pages/content/overview/ViewPlacePage';
import PlaceContainer from './pages/content/PlaceContainer';
import SelectRoomClassPage from './pages/property/room/SelectRoomClassPage';
import TranslationContainer from './pages/property/translation/TranslationContainer';
import MealSupplementContainer from './pages/property/meal-supplement/MealSupplementContainer';
import BlogPostSummaryPage from './pages/blog/BlogPostSummaryPage';
import CreateBlogPostPage from './pages/blog/CreateBlogPostPage';
import ViewBlogPostPage from './pages/blog/overview/ViewBlogPostPage';
import BlogPostContainer from './pages/blog/BlogPostContainer';
import ViewGalleryPage from './pages/property/gallery/ViewGalleryPage';
import ViewLogggingPage from './pages/property/logging/ViewLoggingPage';
import CreateAbkPage from './pages/abk/CreateAbkPage';
import ViewAbkPage from './pages/abk/overview/ViewAbkPage';
import AbkContainer from './pages/abk/AbkContainer';
import ViewRateAndInventoryPage from './pages/property/rate-and-inventory/ViewRateAndInventoryPage';
import ViewOtherInfoPage from './pages/abk/otherInfo/ViewOtherInfoPage';
import OverviewTranslationCard from './pages/property/translation/OverviewTranslationCard';
import RoomTranslationCard from './pages/property/translation/RoomTranslationCard';
import RatePlanTranslationCard from './pages/property/translation/RatePlanTranslationCard';
import FAQTranslationCard from './pages/property/translation/FAQTranslationCard';
import OverviewLoggingCard from './pages/property/logging/OverviewLoggingCard';
import RoomLoggingCard from './pages/property/logging/RoomLoggingCard';
import RatePlanLoggingCard from './pages/property/logging/RatePlanLoggingCard';
import AddOnLoggingCard from './pages/property/logging/AddOnLoggingCard';
import RateAndInventoryLoggingCard from './pages/property/logging/RateAndInventoryLoggingCard';
import TransactionsSummaryPage from './pages/abk/transactions/TransactionsSummaryPage';
import CreateTransactionPage from './pages/abk/transactions/CreateTransactionPage';
import EditTransactionPage from './pages/abk/transactions/EditTransactionPage ';
import AbkTransactionLoggingCard from './pages/abk/logging/AbkTransactionLoggingCard';
import AbkViewLoggingPage from './pages/abk/logging/AbkViewLoggingPage';
import AbkOtherInfoLoggingCard from './pages/abk/logging/AbkOtherInfoLoggingCard';
import ViewKidsSupplementPage from './pages/property/kids-supplement/ViewKidsSupplementPage';
import ViewMealSupplementPage from './pages/property/meal-supplement/ViewMealSupplementPage';
import CreatePromotionPage from './pages/promotion/CreatePromotionPage';
import ViewPromotionPage from './pages/promotion/overview/ViewPromotionPage';
import PromotionContainer from './pages/promotion/PromotionContainer';
import PropertyFacilityCard from './pages/property/facility/propertyFacility/PropertyFacilityCard';
import { FormCardType } from './common/components/card/FormCard';
import RoomFacilityCard from './pages/property/facility/roomFacility/RoomFacilityCard';
import ViewTransportationPage from './pages/property/transportation/ViewTransportationPage';
import ImportPropertyPage from './pages/property/ImportPropertyPage';
import ImportPropertyContainer from './pages/property/ImportPropertyContainer';
import ViewLoggingPage from './pages/property/property-import/logging/ViewLoggingPage';
import PageContainer from './pages/page/PageContainer';
import ViewHomePage from './pages/page/home/ViewHomePage';
import CreateHomeSectionPage from './pages/page/home/section/CreateHomeSectionPage';
import EditHomeSectionPage from './pages/page/home/section/EditHomeSectionPage';
import BkSummaryPage from './pages/bk/BkSummaryPage';
import BkContainer from './pages/bk/BkContainer';
import ViewBkPage from './pages/bk/overview/ViewBkPage';

const SystemUserSummary = lazy(
	() => import('./pages/system-user/SystemUserSummaryPage')
);

const SupplierSummaryPage = lazy(
	() => import('./pages/supplier/SupplierSummaryPage')
);

const PlaceSummaryPage = lazy(() => import('./pages/content/PlaceSummaryPage'));

const AbkSummaryPage = lazy(() => import('./pages/abk/AbkSummaryPage'));

const PromotionSummaryPage = lazy(
	() => import('./pages/promotion/PromotionSummaryPage')
);

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path='' element={<App />}>
				<Route path='login' element={<Outlet />}>
					<Route index element={<LoginPage />} />
				</Route>
				<Route path='forgot-password' element={<Outlet />}>
					<Route index element={<ForgotPasswordPage />} />
					<Route path='verify' element={<EmailVerifyPage />} />
					<Route path='reset' element={<ResetPasswordPage />} />
				</Route>
				<Route element={<RequireAuth />}>
					<Route path='' element={<NavigationDrawer />}>
						<Route path='booking' element={<Outlet />}>
							<Route index element={<BookingSummaryPage />} />
							<Route path='add' element={<AddBooking />} />
						</Route>
						<Route path='sys-user'>
							<Route
								index
								element={
									<Suspense fallback={<>...</>}>
										<SystemUserSummary />
									</Suspense>
								}
							/>
							<Route path='detail/:userId' element={<SystemUserDetailPage />} />
							<Route path='add' element={<CreateUserPage />} />
						</Route>
						<Route path='property'>
							<Route index element={<PropertySummaryPage />} />
							<Route path='add' element={<CreatePropertyPage />} />
							<Route path='import' element={<NavigateWithState to='add' />} />
							<Route path='import' element={<ImportPropertyContainer />}>
								<Route path='add' element={<ImportPropertyPage />} />
								<Route path='logging' element={<ViewLoggingPage />} />
							</Route>
							<Route
								path=':propertyId'
								element={<NavigateWithState to='overview' />}
							/>
							<Route path=':propertyId/*' element={<PropertyContainer />}>
								<Route path='overview' element={<ViewPropertyPage />} />
								<Route path='room/*' element={<RoomContainer />}>
									<Route path='' element={<ViewRoomPage />} />
									<Route path='c' element={<CreateRoomPage />} />
									<Route path='src' element={<SelectRoomClassPage />} />
									<Route path=':roomId' element={<ViewRoomPage />} />
									<Route
										path=':roomId/rate-plan'
										element={<RatePlanSummaryPage />}
									/>
									<Route
										path=':roomId/rate-plan/:ratePlanId'
										element={<RatePlanDetailPage />}
									/>{' '}
									{/** TO make into rateplan details */}
									<Route
										path=':roomId/rate-plan/create'
										element={<CreateRatePlanPage />}
									/>
								</Route>
								<Route path='faq' element={<ViewFaqPage />} />
								<Route path='facility/' element={<ViewFacilityPage />}>
									<Route
										path='property/:pfcategoryId'
										element={
											<PropertyFacilityCard initialType={FormCardType.view} />
										}
									/>
									<Route
										path='room/:rfcategoryId'
										element={
											<RoomFacilityCard initialType={FormCardType.view} />
										}
									/>
								</Route>
								<Route
									path='translation/'
									element={<NavigateWithState to='overview' />}
								/>
								<Route path='translation/*' element={<TranslationContainer />}>
									<Route
										path='overview'
										element={<OverviewTranslationCard />}
									/>
									<Route
										path='room/:roomId'
										element={<RoomTranslationCard />}
									/>
									<Route
										path='rm/:roomId/rp/:ratePlanId'
										element={<RatePlanTranslationCard />}
									/>
									<Route path='faq' element={<FAQTranslationCard />} />
								</Route>
								<Route
									path='kids-supplement'
									element={<ViewKidsSupplementPage />}
								/>
								<Route
									path='meal-supplement/*'
									element={<MealSupplementContainer />}
								>
									<Route
										path='meal-type/:mealTypeId'
										element={<ViewMealSupplementPage />}
									/>
								</Route>
								<Route
									path='transportation'
									element={<ViewTransportationPage />}
								/>
								<Route path='gallery' element={<ViewGalleryPage />} />
								<Route
									path='logging/'
									element={<NavigateWithState to='overview' />}
								/>
								<Route path='logging/*' element={<ViewLogggingPage />}>
									<Route path='overview' element={<OverviewLoggingCard />} />
									<Route path='room/:roomId' element={<RoomLoggingCard />} />
									<Route
										path='rm/:roomId/rp/:ratePlanId'
										element={<RatePlanLoggingCard />}
									/>
									<Route path='add-on' element={<AddOnLoggingCard />} />
									<Route
										path='rate-and-inventory'
										element={<RateAndInventoryLoggingCard />}
									/>
								</Route>
								<Route
									path='rate-and-inventory'
									element={<ViewRateAndInventoryPage />}
								/>
							</Route>
						</Route>
						<Route path='supplier'>
							<Route
								index
								element={
									<Suspense fallback={<>...</>}>
										<SupplierSummaryPage />
									</Suspense>
								}
							/>
							<Route path='add' element={<CreateSupplierPage />} />
							<Route path=':supplierId/*' element={<SupplierContainer />}>
								<Route path='overview' element={<ViewSupplierPage />} />
								{/* <Route path='room/*' element={<RoomContainer />} /> */}
							</Route>
							<Route
								path=':supplierId'
								element={<NavigateWithState to='overview' />}
							/>
						</Route>
						<Route path='places'>
							<Route
								index
								element={
									<Suspense fallback={<>...</>}>
										<PlaceSummaryPage />
									</Suspense>
								}
							/>
							<Route path='add' element={<CreatePlacePage />} />
							<Route path=':placeId/*' element={<PlaceContainer />}>
								<Route path='' element={<ViewPlacePage />} />
							</Route>
							<Route path=':placeId' element={<NavigateWithState to='' />} />
						</Route>
						<Route path='blog'>
							<Route
								index
								element={
									<Suspense fallback={<>...</>}>
										<BlogPostSummaryPage />
									</Suspense>
								}
							/>
							<Route path='add' element={<CreateBlogPostPage />} />
							<Route path=':blogId/*' element={<BlogPostContainer />}>
								<Route path='' element={<ViewBlogPostPage />} />
							</Route>
							<Route path=':blogId' element={<NavigateWithState to='' />} />
						</Route>
						<Route path='abks'>
							<Route
								index
								element={
									<Suspense fallback={<>...</>}>
										<AbkSummaryPage />
									</Suspense>
								}
							/>
							<Route path='add' element={<CreateAbkPage />} />
							<Route path=':abkId/*' element={<AbkContainer />}>
								<Route path='overview' element={<ViewAbkPage />} />
							</Route>
							<Route
								path=':abkId'
								element={<NavigateWithState to='overview' />}
							/>
						</Route>
						<Route path='promotion'>
							<Route
								index
								element={
									<Suspense fallback={<>...</>}>
										<PromotionSummaryPage />
									</Suspense>
								}
							/>
							<Route path='add' element={<CreatePromotionPage />} />
							<Route path=':promotionId/*' element={<PromotionContainer />}>
								<Route path='overview' element={<ViewPromotionPage />} />
							</Route>
							<Route
								path=':promotionId'
								element={<NavigateWithState to='overview' />}
							/>
						</Route>
						<Route path='page' element={<PageContainer />}>
							<Route index element={<NavigateWithState to='home' />} />
							<Route path='home'>
								<Route path='' element={<ViewHomePage />} />
							</Route>
						</Route>
						<Route path='page/home/section'>
							<Route path='add' element={<CreateHomeSectionPage />} />
							<Route path=':sectionId/*' element={<EditHomeSectionPage />} />
						</Route>
						<Route path='bks'>
							<Route
								index
								element={
									<Suspense fallback={<>...</>}>
										<BkSummaryPage />
									</Suspense>
								}
							/>
							<Route path=':bkId/*' element={<BkContainer />}>
								<Route path='overview' element={<ViewBkPage />} />
							</Route>
							<Route
								path=':bkId'
								element={<NavigateWithState to='overview' />}
							/>
						</Route>

						<Route path='test-form' element={<TestForm />} />
						<Route
							path='test-fullcalendar'
							element={<TestFullCalendar />}
						></Route>
					</Route>
				</Route>
			</Route>
		</>
	)
);

export default router;

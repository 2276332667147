import Option from '../common/types/option.type';
import { apiSlice } from './apiSlice';
import { PropertySummaryResponse } from './response/propertySummary.response';
import { SuccessResponse } from './response/shared/success.response';
import { Owner } from './DTO/owner.interface';
import {
	LocalizedProperty,
	Property,
	PropertyBaseInfo,
	PropertyFilter,
	PropertyLocation,
	PropertyPolicy,
	PropertySupplier,
	PropertyLogs,
	PropertyPerPersonConfig,
} from './DTO/property.interface';
import {
	AddTag,
	LocalizedRoom,
	Room,
	RoomInfo,
	RoomRateConfigList,
	RoomSummary,
	RoomSupplement,
	RoomSupplier,
	Tag,
} from './DTO/room.interface';
import {
	LocalizedRatePlan,
	CheckValidMealSupplement,
	ValidMealSupplementMessage,
	RatePlan,
	RatePlanBaseInfo,
	RatePlanInclusion,
	RatePlanName,
	RatePlanPaymentAndPolicy,
	RatePlanSummary,
	RatePlanSupplier,
	RatePlanListItem,
} from './DTO/ratePlan.interface';
import { PropertyFaq } from './DTO/faq.interface';
import {
	PFCategory,
	PFSubcategoryWithPFacilities,
	PFacilityMapping,
	RFCategory,
	RFSubcategoryWithFacilities,
	RFacilityMapping,
} from './DTO/facility.interface';
import { RateModel } from './enum/rateModel.enum';
import { Locale } from './enum/locale.enum';
import {
	PropertyMealSupplement,
	PropertyMealSupplementList,
} from './DTO/propertyMealSupplement.interface';
import {
	Gallery,
	Image,
	Reposition,
	UploadImages,
	RoomImages,
	PropertyVideo,
	UploadVideo,
} from './DTO/gallery.interface';
import {
	PropertyCalendar,
	UpdateRatePlanAllotment,
	UpdateRatePlanMarkup,
	UpdateRatePlanOnRequestAllotment,
	UpdateRatePlanPerPersonRate,
	UpdateRatePlanRates,
	UpdateRatePlanStatus,
	UpdateRatePlanSupplement,
	UpdateRoomAllotment,
	UpdateRoomRate,
} from './DTO/propertyCalendar.interface';
import { RoomClass } from './enum/roomClass.enum';
import { RoomStatus } from './enum/roomStatus.enum';
import { RatePlanStatus } from './enum/ratePlanStatus';
import { PropertyStatus } from './enum/propertyStatus.enum';
import { RatePlanChannel } from './enum/ratePlanChannel.enum';
import { Transport } from './DTO/transportation.interface';

const SERVICE_PATH = '/accommodation-service';

export const AcommodationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// Owner

		getOwners: builder.query<SuccessResponse<Option[]>, void>({
			query: () => `${SERVICE_PATH}/admin/owners`,
			providesTags: ['Owners'],
		}),

		addOwner: builder.mutation<SuccessResponse<Option>, Owner>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/owners`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Owners'],
		}),

		// Property

		addProperty: builder.mutation<SuccessResponse<Property>, Property>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/properties`,
				method: 'POST',
				body,
			}),
		}),

		getProperty: builder.query<SuccessResponse<Property>, string>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}`,
			}),
			providesTags: ['Property'],
		}),

		getFilteredProperties: builder.query<
			PropertySummaryResponse,
			PropertyFilter
		>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/properties/filter`,
				method: 'POST',
				body,
			}),
			providesTags: ['PropertySummary'],
		}),

		updatePropertyStatus: builder.mutation<
			SuccessResponse<{
				status: PropertyStatus;
			}>,
			{
				propertyId: string;
				body: {
					status: PropertyStatus;
				};
			}
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/status`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertySummary', 'Property', 'PropertyCalendar'],
		}),

		updatePropertyBaseInfo: builder.mutation<
			SuccessResponse<PropertyBaseInfo>,
			{ propertyId: string; body: PropertyBaseInfo }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/base-info`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Property'],
		}),

		updatePropertySupplier: builder.mutation<
			SuccessResponse<PropertySupplier>,
			{ propertyId: string; body: PropertySupplier }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/supplier`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Property', 'Room'],
		}),

		updatePropertyLocation: builder.mutation<
			SuccessResponse<PropertyLocation>,
			{ propertyId: string; body: PropertyLocation }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/location`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Property'],
		}),

		updatePropertyPolicy: builder.mutation<
			SuccessResponse<PropertyPolicy>,
			{ propertyId: string; body: PropertyPolicy }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/policy`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Property'],
		}),

		getPropertyLocalization: builder.query<
			SuccessResponse<LocalizedProperty>,
			{ propertyId: string; locale: Locale }
		>({
			query: ({ propertyId, locale }) =>
				`${SERVICE_PATH}/admin/properties/${propertyId}/localizations/${locale}`,
		}),

		updatePropertyLocalization: builder.mutation<
			SuccessResponse<PropertyPolicy>,
			{ propertyId: string; locale: Locale; body: LocalizedProperty }
		>({
			query: ({ propertyId, locale, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/localizations/${locale}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Property'],
		}),

		getPropertyLogging: builder.query<
			SuccessResponse<PropertyLogs[]>,
			{ propertyId: string; logFrom: string; logTo: string }
		>({
			query: ({ propertyId, logFrom, logTo }) =>
				`${SERVICE_PATH}/admin/properties/${propertyId}/logs?from=${logFrom}&to=${logTo}`,
		}),
		// Room

		addRoom: builder.mutation<
			SuccessResponse<Room>,
			{ propertyId: string; body: Room }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/rooms`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['RoomSummary'],
		}),

		getRoomSummary: builder.query<
			SuccessResponse<RoomSummary[]>,
			{ propertyId: string; rateModel?: RateModel; roomClass?: RoomClass }
		>({
			query: ({ propertyId, rateModel, roomClass }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/rooms`,
				params: { rateModel, roomClass },
			}),
			providesTags: ['RoomSummary'],
		}),

		getRoom: builder.query<SuccessResponse<Room>, string>({
			query: (roomId) => `${SERVICE_PATH}/admin/rooms/${roomId}`,
			providesTags: ['Room'],
		}),

		updateRoomInfo: builder.mutation<
			SuccessResponse<RoomInfo>,
			{ roomId: string; body: RoomInfo }
		>({
			query: ({ roomId, body }) => ({
				url: `${SERVICE_PATH}/admin/rooms/${roomId}/info`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Room', 'RoomSummary'],
		}),

		updateRoomSupplier: builder.mutation<
			SuccessResponse<RoomSupplier>,
			{ roomId: string; body: RoomSupplier }
		>({
			query: ({ roomId, body }) => ({
				url: `${SERVICE_PATH}/admin/rooms/${roomId}/supplier`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Room', 'PropertyCalendar'],
		}),

		getRoomLocalization: builder.query<
			SuccessResponse<LocalizedRoom>,
			{ roomId: string; locale: Locale }
		>({
			query: ({ roomId, locale }) =>
				`${SERVICE_PATH}/admin/rooms/${roomId}/localizations/${locale}`,
		}),

		updateRoomLocalization: builder.mutation<
			SuccessResponse<LocalizedRoom>,
			{ roomId: string; locale: Locale; body: LocalizedRoom }
		>({
			query: ({ roomId, locale, body }) => ({
				url: `${SERVICE_PATH}/admin/rooms/${roomId}/localizations/${locale}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['RoomSummary', 'Room'],
		}),

		getRoomSupplements: builder.query<
			SuccessResponse<RoomSupplement[]>,
			{
				roomId: string;
				rateModel: RateModel;
			}
		>({
			query: ({ roomId, rateModel }) => ({
				url: `${SERVICE_PATH}/admin/rooms/${roomId}/supplements?rateModel=${rateModel}`,
			}),
		}),

		updateRoomSupplements: builder.mutation<
			SuccessResponse<RoomSupplement[]>,
			{
				roomId: string;
				rateModel: RateModel;
				body: RoomSupplement[];
			}
		>({
			query: ({ roomId, rateModel, body }) => ({
				url: `${SERVICE_PATH}/admin/rooms/${roomId}/supplements?rateModel=${rateModel}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		updateRoomStatus: builder.mutation<
			SuccessResponse<{
				status: RoomStatus;
			}>,
			{
				roomId: string;
				body: {
					status: RoomStatus;
				};
			}
		>({
			query: ({ roomId, body }) => ({
				url: `${SERVICE_PATH}/admin/rooms/${roomId}/status`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Room', 'PropertyCalendar'],
		}),

		// Tag (Room)

		getRoomTags: builder.query<SuccessResponse<Tag[]>, void>({
			query: () => `${SERVICE_PATH}/admin/tags`,
			providesTags: ['RoomTags'],
		}),

		addRoomTag: builder.mutation<SuccessResponse<Tag>, AddTag>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/tags`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['RoomTags'],
		}),

		getRoomLogging: builder.query<
			SuccessResponse<PropertyLogs[]>,
			{ roomId: string; logFrom: string; logTo: string }
		>({
			query: ({ roomId, logFrom, logTo }) =>
				`${SERVICE_PATH}/admin/rooms/${roomId}/logs?from=${logFrom}&to=${logTo}`,
		}),

		// Room Rate Config

		getRoomRateConfigList: builder.query<
			SuccessResponse<RoomRateConfigList>,
			{ propertyId: string; rateModel: RateModel }
		>({
			query: ({ propertyId, rateModel }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/room-rate-configs`,
				params: { rateModel },
			}),
		}),

		updateRoomRateConfigList: builder.mutation<
			SuccessResponse<RoomRateConfigList>,
			{ propertyId: string; rateModel: RateModel; body: RoomRateConfigList }
		>({
			query: ({ propertyId, rateModel, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/room-rate-configs`,
				method: 'PUT',
				params: { rateModel },
				body,
			}),
			invalidatesTags: ['Room'],
		}),

		// Rate Plan

		getRatePlanSummary: builder.query<
			SuccessResponse<RatePlanSummary[]>,
			string
		>({
			query: (roomId) => `${SERVICE_PATH}/admin/rooms/${roomId}/rate-plans`,
		}),

		getRatePlanListItems: builder.query<
			SuccessResponse<RatePlanListItem[]>,
			{
				propertyId: string;
				rateModel: RateModel;
				channel?: RatePlanChannel;
				enableSupplement?: boolean;
			}
		>({
			query: ({ propertyId, rateModel, channel, enableSupplement }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/rate-plans/list-items`,
				params: {
					rateModel: rateModel,
					channel: channel,
					enableSupplement: enableSupplement,
				},
			}),
		}),

		updateRatePlanStatus: builder.mutation<
			SuccessResponse<{
				status: RatePlanStatus;
			}>,
			{
				ratePlanId: string;
				body: {
					status: RatePlanStatus;
				};
			}
		>({
			query: ({ ratePlanId, body }) => ({
				url: `${SERVICE_PATH}/admin/rate-plans/${ratePlanId}/status`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		getRatePlan: builder.query<SuccessResponse<RatePlan>, string>({
			query: (ratePlanId) => ({
				url: `${SERVICE_PATH}/admin/rate-plans/${ratePlanId}`,
			}),
			providesTags: ['RatePlan'],
		}),

		getRatePlanNameByMealType: builder.query<
			SuccessResponse<RatePlanName>,
			void
		>({
			query: (mealType) => ({
				url: `${SERVICE_PATH}/admin/rate-plans/generate-name?mealType=${mealType}`,
			}),
		}),

		getRatePlanLocalization: builder.query<
			SuccessResponse<LocalizedRatePlan>,
			{ ratePlanId: string; locale: Locale }
		>({
			query: ({ ratePlanId, locale }) =>
				`${SERVICE_PATH}/admin/rate-plans/${ratePlanId}/localizations/${locale}`,
		}),

		updateRatePlanLocalization: builder.mutation<
			SuccessResponse<LocalizedRatePlan>,
			{ ratePlanId: string; locale: Locale; body: LocalizedRatePlan }
		>({
			query: ({ ratePlanId, locale, body }) => ({
				url: `${SERVICE_PATH}/admin/rate-plans/${ratePlanId}/localizations/${locale}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['RatePlan'],
		}),

		getRatePlanLogging: builder.query<
			SuccessResponse<PropertyLogs[]>,
			{ ratePlanId: string; logFrom: string; logTo: string }
		>({
			query: ({ ratePlanId, logFrom, logTo }) =>
				`${SERVICE_PATH}/admin/rate-plans/${ratePlanId}/logs?from=${logFrom}&to=${logTo}`,
		}),

		// FAQ

		getAutoFaqSummary: builder.query<SuccessResponse<PropertyFaq[]>, string>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/faq/autos`,
			}),
		}),

		getCustomFaqSummary: builder.query<SuccessResponse<PropertyFaq[]>, string>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/faq/customs`,
			}),
			providesTags: ['Faq'],
		}),

		updateCustomFaq: builder.mutation<
			SuccessResponse<PropertyFaq[]>,
			{ propertyId: string; body: PropertyFaq[] }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/faq/customs`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Faq'],
		}),

		getCustomFaqLocalization: builder.query<
			SuccessResponse<PropertyFaq[]>,
			{ propertyId: string; locale: Locale }
		>({
			query: ({ propertyId, locale }) =>
				`${SERVICE_PATH}/admin/properties/${propertyId}/faq/customs/localizations/${locale}`,
		}),

		updateCustomFaqLocalization: builder.mutation<
			SuccessResponse<PropertyFaq[]>,
			{ propertyId: string; locale: Locale; body: PropertyFaq[] }
		>({
			query: ({ propertyId, locale, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/faq/customs/localizations/${locale}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Faq'],
		}),

		//Property Facility
		getPfcategories: builder.query<SuccessResponse<PFCategory[]>, string>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/pfcategories?propertyId=${propertyId}`,
			}),
		}),

		getPfsubcategories: builder.query<
			SuccessResponse<PFSubcategoryWithPFacilities[]>,
			string
		>({
			query: (pfcategoryId) => ({
				url: `${SERVICE_PATH}/admin/pfsubcategories?pfcategoryId=${pfcategoryId}`,
			}),
		}),

		getPropertyFacilities: builder.query<
			SuccessResponse<PFacilityMapping>,
			{ propertyId: string; pfcategoryId: string }
		>({
			query: ({ propertyId, pfcategoryId }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/facilities?pfcategoryId=${pfcategoryId}`,
			}),
			providesTags: ['PFacility'],
		}),

		updatePropertyFacilities: builder.mutation<
			SuccessResponse<PFacilityMapping>,
			{ propertyId: string; pfcategoryId: string; body: PFacilityMapping }
		>({
			query: ({ propertyId, pfcategoryId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/facilities?pfcategoryId=${pfcategoryId}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PFacility'],
		}),

		//RoomFacility
		getRfcategories: builder.query<SuccessResponse<RFCategory[]>, string>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/rfcategories?propertyId=${propertyId}`,
			}),
		}),

		getRfsubcategories: builder.query<
			SuccessResponse<RFSubcategoryWithFacilities[]>,
			string
		>({
			query: (rfcategoryId) => ({
				url: `${SERVICE_PATH}/admin/rfsubcategories?rfcategoryId=${rfcategoryId}`,
			}),
		}),

		getRoomFacilities: builder.query<
			SuccessResponse<RFacilityMapping>,
			{ propertyId: string; rfcategoryId: string }
		>({
			query: ({ propertyId, rfcategoryId }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/rooms/facilities?rfcategoryId=${rfcategoryId}`,
			}),
			providesTags: ['RFacility'],
		}),

		updateRoomFacilities: builder.mutation<
			SuccessResponse<RFacilityMapping>,
			{ propertyId: string; rfcategoryId: string; body: RFacilityMapping }
		>({
			query: ({ propertyId, rfcategoryId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/rooms/facilities?rfcategoryId=${rfcategoryId}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['RFacility'],
		}),

		addRatePlan: builder.mutation<
			SuccessResponse<RatePlan>,
			{ roomId: string; body: RatePlan }
		>({
			query: ({ roomId, body }) => ({
				url: `${SERVICE_PATH}/admin/rooms/${roomId}/rate-plans`,
				method: 'POST',
				body,
			}),
		}),

		checkValidMealPlanSupplement: builder.query<
			SuccessResponse<ValidMealSupplementMessage>,
			{
				body: CheckValidMealSupplement;
				propertyId: string;
			}
		>({
			query: ({ body, propertyId }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/meal-supplements/check`,
				method: 'POST',
				body,
			}),
		}),

		updateRatePlanBaseInfo: builder.mutation<
			SuccessResponse<RatePlanBaseInfo>,
			{ ratePlanId: string; body: RatePlanBaseInfo }
		>({
			query: ({ ratePlanId, body }) => ({
				url: `${SERVICE_PATH}/admin/rate-plans/${ratePlanId}/base-info`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['RatePlan'],
		}),

		updateRatePlanPaymentAndPolicy: builder.mutation<
			SuccessResponse<RatePlanPaymentAndPolicy>,
			{ ratePlanId: string; body: RatePlanPaymentAndPolicy }
		>({
			query: ({ ratePlanId, body }) => ({
				url: `${SERVICE_PATH}/admin/rate-plans/${ratePlanId}/payment-and-policy`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['RatePlan'],
		}),

		updateRatePlanInclusion: builder.mutation<
			SuccessResponse<RatePlanInclusion>,
			{ ratePlanId: string; body: RatePlanInclusion }
		>({
			query: ({ ratePlanId, body }) => ({
				url: `${SERVICE_PATH}/admin/rate-plans/${ratePlanId}/inclusion`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['RatePlan'],
		}),

		updateRatePlanSupplier: builder.mutation<
			SuccessResponse<RatePlanSupplier>,
			{ ratePlanId: string; body: RatePlanSupplier }
		>({
			query: ({ ratePlanId, body }) => ({
				url: `${SERVICE_PATH}/admin/rate-plans/${ratePlanId}/supplier`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['RatePlan'],
		}),
		getPropertyPerPersonConfig: builder.query<
			SuccessResponse<PropertyPerPersonConfig>,
			{ propertyId: string; rateModel: RateModel }
		>({
			query: ({ propertyId, rateModel }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/per-person-config`,
				params: {
					rateModel: rateModel,
				},
			}),
			providesTags: ['PropertyPerPersonConfig'],
		}),

		updatePropertyPerPersonConfig: builder.mutation<
			SuccessResponse<PropertyPerPersonConfig>,
			{
				propertyId: string;
				rateModel: RateModel;
				body: PropertyPerPersonConfig;
			}
		>({
			query: ({ propertyId, rateModel, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/per-person-config`,
				method: 'PUT',
				params: {
					rateModel: rateModel,
				},
				body,
			}),
			invalidatesTags: ['PropertyPerPersonConfig'],
		}),
		getMealPlanSupplement: builder.query<
			SuccessResponse<PropertyMealSupplementList>,
			{
				propertyId: string;
				mealTypeId: string;
				rateModelId: string;
			}
		>({
			query: ({ propertyId, mealTypeId, rateModelId }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/meal-supplements?rateModel=${rateModelId}&mealType=${mealTypeId}`,
			}),
		}),

		updateMealPlanSupplement: builder.mutation<
			SuccessResponse<PropertyMealSupplementList>,
			{
				propertyId: string;
				mealTypeId: string;
				rateModelId: string;
				body: any;
			}
		>({
			query: ({ propertyId, mealTypeId, rateModelId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/meal-supplements?rateModel=${rateModelId}&mealType=${mealTypeId}`,
				method: 'PUT',
				body,
			}),
		}),

		//Gallery
		getPropertyImages: builder.query<SuccessResponse<Gallery[]>, string>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/images`,
			}),
			providesTags: ['Gallery'],
		}),

		uploadPropertyImages: builder.mutation<
			SuccessResponse<Gallery>,
			{ propertyId: string; body: UploadImages }
		>({
			query: ({ propertyId, body }) => {
				const bodyFormData = new FormData();
				bodyFormData.append('files', body.files);
				bodyFormData.append('section', body.section);
				if (body.categoryId) {
					bodyFormData.append('categoryId', body.categoryId);
				}

				return {
					url: `${SERVICE_PATH}/admin/properties/${propertyId}/images`,
					method: 'POST',
					body: bodyFormData,
					formData: true,
				};
			},
			invalidatesTags: ['Gallery'],
		}),

		repositionPropertyImages: builder.mutation<
			SuccessResponse<Image>,
			{ property_image_id: string; body: Reposition }
		>({
			query: ({ property_image_id, body }) => ({
				url: `${SERVICE_PATH}/admin/property-images/${property_image_id}/reposition`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Gallery'],
		}),

		deletePropertyImage: builder.mutation<SuccessResponse<null>, string>({
			query: (property_image_id) => ({
				url: `${SERVICE_PATH}/admin/property-images/${property_image_id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Gallery'],
		}),

		selectPropertyImage: builder.mutation<SuccessResponse<Image>, string>({
			query: (property_image_id) => ({
				url: `${SERVICE_PATH}/admin/property-images/${property_image_id}/select`,
				method: 'PUT',
			}),
			invalidatesTags: ['Gallery'],
		}),

		getPropertyVideo: builder.query<SuccessResponse<PropertyVideo>, string>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/videos`,
			}),
			providesTags: ['Gallery'],
		}),

		uploadPropertyVideo: builder.mutation<
			SuccessResponse<PropertyVideo>,
			{ propertyId: string; body: UploadVideo }
		>({
			query: ({ propertyId, body }) => {
				const bodyFormData = new FormData();
				bodyFormData.append('file', body.file);

				return {
					url: `${SERVICE_PATH}/admin/properties/${propertyId}/videos`,
					method: 'POST',
					body: bodyFormData,
					formData: true,
				};
			},
			invalidatesTags: ['Gallery'],
		}),

		deletePropertyVideo: builder.mutation<SuccessResponse<null>, string>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/videos`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Gallery'],
		}),

		getRoomImages: builder.query<SuccessResponse<RoomImages[]>, string>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/rooms/images`,
			}),
			providesTags: ['Gallery'],
		}),

		uploadRoomImages: builder.mutation<
			SuccessResponse<Image[]>,
			{ roomId: string; file: File }
		>({
			query: ({ roomId, file }) => {
				const bodyFormData = new FormData();
				bodyFormData.append('files', file);
				return {
					url: `${SERVICE_PATH}/admin/rooms/${roomId}/images`,
					method: 'POST',
					body: bodyFormData,
					formData: true,
				};
			},
			invalidatesTags: ['Gallery'],
		}),

		repositionRoomImages: builder.mutation<
			SuccessResponse<Image>,
			{ room_image_id: string; body: Reposition }
		>({
			query: ({ room_image_id, body }) => ({
				url: `${SERVICE_PATH}/admin/room-images/${room_image_id}/reposition`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Gallery'],
		}),

		deleteRoomImage: builder.mutation<SuccessResponse<null>, string>({
			query: (room_image_id) => ({
				url: `${SERVICE_PATH}/admin/room-images/${room_image_id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Gallery'],
		}),

		selectRoomImage: builder.mutation<SuccessResponse<Image>, string>({
			query: (room_image_id) => ({
				url: `${SERVICE_PATH}/admin/room-images/${room_image_id}/select`,
				method: 'PUT',
			}),
			invalidatesTags: ['Gallery'],
		}),

		// Property Calendar
		getPropertyCalendarRateModels: builder.query<
			SuccessResponse<RateModel[]>,
			string
		>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar/rate-models`,
			}),
		}),

		getPropertyCalendar: builder.query<
			SuccessResponse<PropertyCalendar>,
			{
				propertyId: string;
				rateModel: RateModel;
				from: string;
				to: string;
				displayCurrency: string;
			}
		>({
			query: ({ propertyId, rateModel, from, to, displayCurrency }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar`,
				params: { rateModel, from, to, displayCurrency },
			}),
			providesTags: ['PropertyCalendar'],
		}),

		bulkUpdateRoomAllotments: builder.mutation<
			SuccessResponse<UpdateRoomAllotment>,
			{ propertyId: string; body: UpdateRoomAllotment }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar/rooms/allotments`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		bulkUpdateRoomRates: builder.mutation<
			SuccessResponse<UpdateRoomRate>,
			{ propertyId: string; body: UpdateRoomRate }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar/rooms/rates`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		bulkUpdateRatePlanAllotments: builder.mutation<
			SuccessResponse<UpdateRatePlanAllotment>,
			{ propertyId: string; body: UpdateRatePlanAllotment }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar/rate-plans/allotments`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		bulkUpdateRatePlanMarkups: builder.mutation<
			SuccessResponse<UpdateRatePlanMarkup>,
			{ propertyId: string; body: UpdateRatePlanMarkup }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar/rate-plans/markups`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		bulkUpdateRatePlanOnRequestAllotments: builder.mutation<
			SuccessResponse<UpdateRatePlanOnRequestAllotment>,
			{ propertyId: string; body: UpdateRatePlanOnRequestAllotment }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar/rate-plans/on-request-allotments`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		bulkUpdateRatePlanRates: builder.mutation<
			SuccessResponse<UpdateRatePlanRates>,
			{ propertyId: string; body: UpdateRatePlanRates }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar/rate-plans/rates`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		bulkUpdateRatePlanStatuses: builder.mutation<
			SuccessResponse<UpdateRatePlanStatus>,
			{ propertyId: string; body: UpdateRatePlanStatus }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar/rate-plans/statuses`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		bulkUpdateRatePlanSupplements: builder.mutation<
			SuccessResponse<UpdateRatePlanSupplement>,
			{ propertyId: string; body: UpdateRatePlanSupplement }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar/rate-plans/supplements`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		bulkUpdateRatePlanPerPersonRates: builder.mutation<
			SuccessResponse<UpdateRatePlanPerPersonRate>,
			{ propertyId: string; body: UpdateRatePlanPerPersonRate }
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/calendar/rate-plans/per-person-rates`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['PropertyCalendar'],
		}),

		getTransportation: builder.query<SuccessResponse<Transport[]>, string>({
			query: (propertyId) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/transports`,
			}),
			providesTags: ['Transportation'],
		}),

		updateTransportation: builder.mutation<
			SuccessResponse<Transport[]>,
			{
				propertyId: string;
				body: any;
			}
		>({
			query: ({ propertyId, body }) => ({
				url: `${SERVICE_PATH}/admin/properties/${propertyId}/transports`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Transportation'],
		}),
	}),
});

export const {
	useGetOwnersQuery,
	useAddOwnerMutation,
	useLazyGetOwnersQuery,
	useAddPropertyMutation,
	useGetPropertyQuery,
	useGetFilteredPropertiesQuery,
	useUpdatePropertyStatusMutation,
	useUpdatePropertyBaseInfoMutation,
	useUpdatePropertySupplierMutation,
	useUpdatePropertyLocationMutation,
	useUpdatePropertyPolicyMutation,
	useLazyGetPropertyLocalizationQuery,
	useUpdatePropertyLocalizationMutation,
	useGetPropertyLoggingQuery,
	useAddRoomMutation,
	useGetRoomSummaryQuery,
	useGetRoomQuery,
	useUpdateRoomSupplierMutation,
	useUpdateRoomInfoMutation,
	useLazyGetRoomLocalizationQuery,
	useUpdateRoomLocalizationMutation,
	useGetRoomSupplementsQuery,
	useUpdateRoomSupplementsMutation,
	useUpdateRoomStatusMutation,
	useGetRoomTagsQuery,
	useGetRoomLoggingQuery,
	useAddRoomTagMutation,
	useLazyGetRoomRateConfigListQuery,
	useUpdateRoomRateConfigListMutation,
	useGetRatePlanSummaryQuery,
	useLazyGetRatePlanSummaryQuery,
	useUpdateRatePlanStatusMutation,
	useLazyGetRatePlanQuery,
	useGetRatePlanQuery,
	useLazyGetRatePlanNameByMealTypeQuery,
	useLazyGetRatePlanLocalizationQuery,
	useUpdateRatePlanLocalizationMutation,
	useGetRatePlanLoggingQuery,
	useGetAutoFaqSummaryQuery,
	useGetCustomFaqSummaryQuery,
	useUpdateCustomFaqMutation,
	useLazyGetCustomFaqLocalizationQuery,
	useUpdateCustomFaqLocalizationMutation,
	useGetPfcategoriesQuery,
	useGetPropertyFacilitiesQuery,
	useLazyGetPropertyFacilitiesQuery,
	useGetPfsubcategoriesQuery,
	useUpdatePropertyFacilitiesMutation,
	useGetRfcategoriesQuery,
	useGetRoomFacilitiesQuery,
	useLazyGetRoomFacilitiesQuery,
	useGetRfsubcategoriesQuery,
	useUpdateRoomFacilitiesMutation,
	useAddRatePlanMutation,
	useLazyCheckValidMealPlanSupplementQuery,
	useUpdateRatePlanBaseInfoMutation,
	useUpdateRatePlanPaymentAndPolicyMutation,
	useUpdateRatePlanInclusionMutation,
	useUpdateRatePlanSupplierMutation,
	useGetPropertyPerPersonConfigQuery,
	useLazyGetPropertyPerPersonConfigQuery,
	useUpdatePropertyPerPersonConfigMutation,
	useGetMealPlanSupplementQuery,
	useLazyGetMealPlanSupplementQuery,
	useUpdateMealPlanSupplementMutation,
	useGetPropertyImagesQuery,
	useUploadPropertyImagesMutation,
	useRepositionPropertyImagesMutation,
	useDeletePropertyImageMutation,
	useSelectPropertyImageMutation,
	useGetPropertyVideoQuery,
	useUploadPropertyVideoMutation,
	useDeletePropertyVideoMutation,
	useGetRoomImagesQuery,
	useUploadRoomImagesMutation,
	useRepositionRoomImagesMutation,
	useDeleteRoomImageMutation,
	useSelectRoomImageMutation,
	useGetPropertyCalendarRateModelsQuery,
	useGetPropertyCalendarQuery,
	useLazyGetPropertyCalendarQuery,
	useBulkUpdateRoomAllotmentsMutation,
	useBulkUpdateRoomRatesMutation,
	useBulkUpdateRatePlanAllotmentsMutation,
	useBulkUpdateRatePlanMarkupsMutation,
	useBulkUpdateRatePlanOnRequestAllotmentsMutation,
	useBulkUpdateRatePlanRatesMutation,
	useBulkUpdateRatePlanStatusesMutation,
	useBulkUpdateRatePlanSupplementsMutation,
	useBulkUpdateRatePlanPerPersonRatesMutation,
	useGetRatePlanListItemsQuery,
	useGetTransportationQuery,
	useUpdateTransportationMutation,
} = AcommodationApiSlice;

import { useState } from 'react';
import FormCard, {
	FormCardType,
} from '../../../../../common/components/card/FormCard';
import { useTranslation } from 'react-i18next';
import RatePlanPaymentAndPolicyView from './RatePlanPaymentAndPolicyView';
import RatePlanPaymentAndPolicyForm from './RatePlanPaymentAndPolicyForm';

interface RatePlanPaymentAndPolicyCardProps {
	initialType: FormCardType;
}

export default function RatePlanPaymentAndPolicyCard({
	initialType,
}: RatePlanPaymentAndPolicyCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<>
			<FormCard type={type} title={t('room.ratePlan.PaymentAndPolicy')}>
				{(() => {
					switch (type) {
						case FormCardType.view:
							return <RatePlanPaymentAndPolicyView setType={setType} />;
						case FormCardType.create:
							return (
								<RatePlanPaymentAndPolicyForm type={type} setType={setType} />
							);
						case FormCardType.edit:
							return (
								<RatePlanPaymentAndPolicyForm type={type} setType={setType} />
							);
					}
				})()}
			</FormCard>
		</>
	);
}

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	InputAdornment,
	TextField,
	TextFieldProps,
	styled,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { ReactComponent as ClockIcon } from '../../../assets/icon/clock.svg';
import AppInputLayout from '../form-layout/AppInputLayout';
import dayjs from 'dayjs';
import {
	API_TIME_FORMAT,
	DISPLAY_TIME_FORMAT,
} from '../../../utils/dateHelper';

const StyledTextField = styled(TextField)(({ theme }) => ({
	'label + &': {
		marginTop: '28px',
	},

	'& .MuiOutlinedInput-root': {
		padding: '9px 10px',

		'& fieldset': {
			borderColor: '#BCBCBC',
			borderRadius: '5px',
		},
		'&:hover fieldset': {
			borderWidth: '1px',
			borderColor: '#BCBCBC',
		},
		'&.Mui-focused fieldset': {
			borderWidth: '1px',
			borderColor: theme.palette.primary.main,
		},
		'&.Mui-error fieldset': {
			borderWidth: '1px',
			borderColor: theme.palette.error.main,
		},
		'&.Mui-disabled fieldset': {
			borderColor: '#F5F5F5',
		},
		'&.Mui-disabled': {
			background: '#F5F5F5',
		},
		'.Mui-disabled': {
			WebkitTextFillColor: '#808080',
		},
	},
	'& .MuiInputBase-input': {
		fontSize: 12,
		height: '12px',
		padding: '0px',

		'&::placeholder': {
			color: '#808080',
			opacity: 1,
		},
	},

	'& .MuiInputAdornment-root': {
		'& .MuiTypography-root': {
			fontSize: 12,
			color: '#808080',
		},
	},
}));

interface AppTimePickerProps {
	label?: string;
	prefix?: string;
	placeholder?: string;
	required?: boolean;
}

export default function AppTimePicker<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	prefix,
	placeholder,
	required,
	...props
}: AppTimePickerProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<AppInputLayout
			label={label}
			required={required}
			errorMessage={errorMessage}
		>
			<TimePicker
				value={
					field.value ? dayjs(field.value, API_TIME_FORMAT).format() : null
				}
				onChange={(value) => {
					value === null
						? field.onChange(value)
						: field.onChange(dayjs(value).format(API_TIME_FORMAT));
				}}
				renderInput={(params) => (
					<StyledTextField
						{...params}
						error={!!errorMessage}
						InputProps={{
							startAdornment: prefix ? (
								<InputAdornment position='start'>{prefix}</InputAdornment>
							) : (
								<></>
							),
							endAdornment: (
								<InputAdornment position='end'>
									<ClockIcon />
								</InputAdornment>
							),
						}}
						inputProps={{
							...params.inputProps,
							placeholder: placeholder ?? '',
						}}
					/>
				)}
				inputFormat={DISPLAY_TIME_FORMAT}
			/>
		</AppInputLayout>
	);
}

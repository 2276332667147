import {
	setCredentials,
	setResetSessionId,
	setResetPasswordToken,
} from '../pages/auth/authSlice';
import { apiSlice } from './apiSlice';
import { ConfirmPasswordRequest } from './request/confirmPassword.request';
import { LoginRequest } from './request/login.request';
import { VerifyCodeRequest } from './request/verifyCode.request';
import { LoginResponse } from './response/login.response';
import { RefreshResponse } from './response/refresh.response';
import { ResetPasswordInitResponse } from './response/resetPasswordInit.response';
import { SuccessResponse } from './response/shared/success.response';
import { VerifyCodeResponse } from './response/verifyCode.response';

const SERVICE_PATH = '/auth-service';

export const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<LoginResponse, LoginRequest>({
			query: (credentials) => ({
				url: `${SERVICE_PATH}/admin/auth/login`,
				method: 'POST',
				body: { ...credentials },
				credentials: 'include',
			}),
		}),
		refresh: builder.mutation<RefreshResponse, void>({
			query: () => ({
				url: `${SERVICE_PATH}/admin/auth/refresh`,
				method: 'GET',
				credentials: 'include',
			}),
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setCredentials({ accessToken: data.data.accessToken }));
				} catch (err) {
					console.log(err);
				}
			},
		}),
		resetPasswordInit: builder.mutation<ResetPasswordInitResponse, string>({
			query: (email) => ({
				url: `${SERVICE_PATH}/admin/reset-password/init`,
				method: 'POST',
				params: { email },
			}),
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setResetSessionId(data.data.id));
				} catch (err) {
					console.log(err);
				}
			},
		}),
		resendEmail: builder.mutation<SuccessResponse<null>, string>({
			query: (id) => ({
				url: `${SERVICE_PATH}/admin/reset-password/resend`,
				method: 'POST',
				params: { id },
			}),
		}),
		verifyCode: builder.mutation<
			VerifyCodeResponse,
			{ id: string; body: VerifyCodeRequest }
		>({
			query: ({ id, body }) => ({
				url: `${SERVICE_PATH}/admin/reset-password/verify-code`,
				method: 'POST',
				params: { id },
				body: body,
			}),
			extraOptions: { showSnackbarError: true },
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setResetPasswordToken(data.data.token));
				} catch (err) {
					console.log(err);
				}
			},
		}),
		confirmPassword: builder.mutation<
			SuccessResponse<null>,
			{ id: string; body: ConfirmPasswordRequest }
		>({
			query: ({ id, body }) => ({
				url: `${SERVICE_PATH}/admin/reset-password/confirm-password`,
				method: 'POST',
				params: { id },
				body: body,
			}),
		}),
	}),
});

export const {
	useLoginMutation,
	useRefreshMutation,
	useResetPasswordInitMutation,
	useResendEmailMutation,
	useVerifyCodeMutation,
	useConfirmPasswordMutation,
} = authApiSlice;

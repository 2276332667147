export function getUserRole(role: number): string {
	switch (role) {
		case 0:
			return 'System Admin';
		case 1:
			return 'Admin';
		case 2:
			return 'Customer Service';
		case 3:
			return 'Data Entry';
		case 4:
			return 'Accountant';
		default:
			return '';
	}
}

interface UserStatus {
	isActivated: boolean;
	i18nLabel: string;
}

export function getUserStatus(status: number): UserStatus {
	switch (status) {
		case 0:
			return {
				isActivated: true,
				i18nLabel: 'systemUser.Activated',
			};
		case 1:
			return {
				isActivated: false,
				i18nLabel: 'systemUser.NonActivated',
			};
		default:
			return {
				isActivated: false,
				i18nLabel: 'common.Error',
			};
	}
}

import AppAddPhotoButton from '../../../../common/components/form-inputs/AppAddPhotoButton';
import { Box, Typography } from '@mui/material';
import { ReactComponent as AddIcon } from '../../../../assets/icon/button-add.svg';
import { useTranslation } from 'react-i18next';
import { Image } from '../../../../api/DTO/gallery.interface';
import SortableItem from './SortableItem';

interface ImageListProps {
	imageArray: Image[];
	handleDeleteImage: (id: string, index: number) => void;
	handleSelectImage: (id: string) => void;
	onUploadFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ImageList({
	imageArray,
	handleDeleteImage,
	handleSelectImage,
	onUploadFileChange,
}: ImageListProps) {
	const { t } = useTranslation();

	return (
		<Box
			style={{
				display: 'grid',
				gridTemplateColumns: `repeat(auto-fit, 240px)`,
				gridGap: 16,
				maxWidth: '100%',
			}}
		>
			{imageArray.map((item: Image, index: number) => (
				<SortableItem
					key={item.id}
					item={item}
					index={index}
					DeleteCallBack={handleDeleteImage}
					SelectCallBack={handleSelectImage}
				/>
			))}
			<Box
				sx={{
					width: '100%',
					height: '100%',
					minHeight: '135px',
				}}
			>
				<AppAddPhotoButton component='label'>
					<input
						type='file'
						name='doc'
						hidden
						onChange={onUploadFileChange}
						accept='image/jpeg, image/png, image/gif, image/webp'
					/>
					<Typography fontSize={'14px'} fontWeight={'700'}>
						<AddIcon />
						{t('button.AddPhoto')}
					</Typography>
					<Typography fontSize={'10px'}>
						{t('place.SuggestedFormat')}
						<b>{t('place.FileType')}</b>
					</Typography>
					<Typography fontSize={'10px'}>
						{t('place.SuggestedDimension')}
						<b>{t('place.Dimension')}</b>
					</Typography>
				</AppAddPhotoButton>
			</Box>
		</Box>
	);
}

import Option from '../common/types/option.type';
import { apiSlice } from './apiSlice';
import { SuccessResponse } from './response/shared/success.response';
import {
	Supplier,
	SupplierBaseInfo,
	SupplierBankDetail,
	SupplierContactPoint,
	SupplierDocument,
	SupplierSummaryResponse,
	SupplierFilter,
} from './DTO/supplier.interface';

const SERVICE_PATH = '/supplier-service';

export const SupplierApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getDirectContractSuppliers: builder.query<SuccessResponse<Option[]>, void>({
			query: () => `${SERVICE_PATH}/admin/suppliers?direct=true`,
		}),

		// Supplier

		addSupplier: builder.mutation<SuccessResponse<Supplier>, Supplier>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/suppliers`,
				method: 'POST',
				body,
			}),
		}),

		getSupplier: builder.query<SuccessResponse<Supplier>, string>({
			query: (supplierId) => ({
				url: `${SERVICE_PATH}/admin/suppliers/${supplierId}`,
			}),
			providesTags: ['Supplier'],
		}),

		getFilteredSuppliers: builder.query<
			SupplierSummaryResponse,
			SupplierFilter
		>({
			query: (body) => ({
				url: `${SERVICE_PATH}/admin/suppliers/filter`,
				method: 'POST',
				body,
			}),
		}),

		updateSupplierBaseInfo: builder.mutation<
			SuccessResponse<SupplierBaseInfo>,
			{ supplierId: string; body: SupplierBaseInfo }
		>({
			query: ({ supplierId, body }) => ({
				url: `${SERVICE_PATH}/admin/suppliers/${supplierId}/base-info`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Supplier'],
		}),

		updateSupplierBankDetail: builder.mutation<
			SuccessResponse<SupplierBankDetail>,
			{ supplierId: string; body: SupplierBankDetail }
		>({
			query: ({ supplierId, body }) => ({
				url: `${SERVICE_PATH}/admin/suppliers/${supplierId}/bank-detail`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Supplier'],
		}),

		updateSupplierContactPoint: builder.mutation<
			SuccessResponse<SupplierContactPoint>,
			{ supplierId: string; body: SupplierContactPoint }
		>({
			query: ({ supplierId, body }) => ({
				url: `${SERVICE_PATH}/admin/suppliers/${supplierId}/contact-points`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Supplier'],
		}),

		updateSupplierDocUpload: builder.mutation<
			SuccessResponse<SupplierDocument>,
			{ supplierId: string; body: SupplierDocument }
		>({
			query: ({ supplierId, body }) => ({
				url: `${SERVICE_PATH}/admin/suppliers/${supplierId}/documents`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Supplier'],
		}),
	}),
});

export const {
	useGetDirectContractSuppliersQuery,
	useAddSupplierMutation,
	useGetSupplierQuery,
	useGetFilteredSuppliersQuery,
	useUpdateSupplierBaseInfoMutation,
	useUpdateSupplierBankDetailMutation,
	useUpdateSupplierContactPointMutation,
	useUpdateSupplierDocUploadMutation,
} = SupplierApiSlice;

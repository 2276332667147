import { Alert, AlertProps, styled } from '@mui/material';
import { ReactComponent as CircleInfoIcon } from '../../assets/icon/circleInfo.svg';

interface AppAlertProps {
	alertColor?: string;
	alertBgColor?: string;
	alertBar?: boolean;
}

export default function AppAlert({
	alertColor,
	alertBgColor,
	alertBar,
	children,
}: AppAlertProps & React.PropsWithChildren<AlertProps>) {
	return (
		<StyledAlert
			alertColor={alertColor}
			alertBgColor={alertBgColor}
			alertBar={alertBar}
			severity='info'
			iconMapping={{ info: <CircleInfoIcon color={alertColor} /> }}
		>
			{children}
		</StyledAlert>
	);
}

const StyledAlert = styled(Alert, {
	shouldForwardProp: (prop) =>
		prop !== 'alertColor' && prop !== 'alertBgColor' && prop !== 'alertBar',
})<{
	alertColor?: string;
	alertBgColor?: string;
	alertBar?: boolean;
}>(({ alertColor, alertBgColor, alertBar }) => ({
	fontSize: '12px',
	padding: alertBar ? '5px 10px' : 0,
	color: alertColor ? alertColor : '#32A5F1',
	backgroundColor: alertBgColor ? alertBgColor : '#ffffff',
	borderRadius: 5,
	whiteSpace: 'pre-wrap',
	'& .MuiAlert-icon': {
		alignItems: 'top',
		padding: 2,
		marginRight: 8,
	},

	'& .MuiAlert-message': {
		padding: 0,
		display: 'flex',
		alignItems: 'center',
		paddingRight: alertBar ? '10px' : 0,
	},
}));

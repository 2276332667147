import { Box, Card, Divider, Stack, Typography, styled } from '@mui/material';
import { ReactNode } from 'react';

interface ListCardProps {
	headerTitle: string;
	headerActionButtonNodes?: ReactNode[];
	content: ReactNode;
}

export default function ListCard(props: ListCardProps) {
	const { headerTitle, headerActionButtonNodes, content } = props;

	return (
		<StyledCard>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant='bold' fontSize={'20px'}>
					{headerTitle}
				</Typography>
				<Stack direction={'row'} spacing={'10px'} marginTop={'17px'}>
					{headerActionButtonNodes &&
						headerActionButtonNodes.map((buttonNode) => buttonNode)}
				</Stack>
			</Box>
			<Divider sx={{ marginTop: headerActionButtonNodes ? '10px' : '20px' }} />
			{content}
		</StyledCard>
	);
}

const StyledCard = styled(Card)(({ theme }) => ({
	width: '100%',
	background: theme.palette.background.infoCard,
	boxShadow: '0px 4px 3px 0px rgba(0, 0, 0, 0.05)',
	borderRadius: 5,
	padding: 20,
}));

import { Box, Tooltip, Typography } from '@mui/material';

interface DisabledCellProps {
	field: string;
	date: string;
	value: string;
	tooltip: string;
}

export default function DisabledCell({
	field,
	date,
	value,
	tooltip,
}: DisabledCellProps) {
	return (
		<Box
			id={`${date}_${field}`}
			sx={{
				backgroundColor: 'white',
				cursor: 'not-allowed',
			}}
			width={'100%'}
			height={'100%'}
			justifyContent={'center'}
		>
			<Tooltip title={tooltip} placement='bottom-start'>
				<Box
					width={'100%'}
					height={'100%'}
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					<Typography
						className={'noselect'}
						sx={{
							display: 'block',
							fontSize: '12px',
							textAlign: 'center',
							lineHeight: '36px',
							color: '#808080',
						}}
						variant='regular'
					>
						{value}
					</Typography>
				</Box>
			</Tooltip>
		</Box>
	);
}

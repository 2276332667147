import Dialog from '@mui/material/Dialog';
import { Box, Divider, styled, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icon/close.svg';

const StyledDivider = styled(Divider)({
	border: '1.5px solid #E4E8EA',
	marginTop: '10px',
	marginBottom: '24px',
});

export interface CustomDialogProps {
	open: boolean;
	onClose: () => void;
	title: string;
	content: JSX.Element;
	width?: string;
}

export default function CustomDialog(props: CustomDialogProps) {
	const { onClose, open, width } = props;

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				sx: { width: width ?? '430px', maxWidth: width ?? '430px' },
			}}
			fullWidth
		>
			<Box sx={{ display: 'flex' }}>
				<Typography fontSize='14px' variant='bold' sx={{ flexGrow: 1 }}>
					{props.title}
				</Typography>
				<CloseIcon onClick={handleClose} cursor='pointer' />
			</Box>
			<StyledDivider />
			{props.content}
		</Dialog>
	);
}

import * as yup from 'yup';
import { BedConfig } from '../api/DTO/room.interface';
import { Bed, bedOption } from '../api/enum/bed.enum';
import { PerPersonRateObject } from '../pages/property/rate-and-inventory/components/bulk-edit-rate-plan/AdultRate';
import { ValidationError } from 'yup';

yup.addMethod<yup.ObjectSchema<any>>(
	yup.object,
	'validateAgeRange',
	function (message) {
		return this.test('validateAgeRange', message, function (value) {
			const min = value?.min != undefined;
			const max = value?.max != undefined;

			return (!min && !max) || (min && max);
		});
	}
);

yup.addMethod<yup.ObjectSchema<any>>(
	yup.object,
	'requireAgeRange',
	function (message) {
		return this.test('requireAgeRange', message, function (value) {
			const min = value?.min != undefined;
			const max = value?.max != undefined;

			return min && max;
		});
	}
);

yup.addMethod<yup.ObjectSchema<any>>(
	yup.object,
	'validateRoomSize',
	function (message) {
		return this.test('validateRoomSize', message, function (value) {
			return value?.size !== undefined;
		});
	}
);

yup.addMethod<yup.ArraySchema<any, any>>(
	yup.array,
	'validateBedConfigs',
	function (message) {
		return this.test('validateBedConfigs', message, function (value) {
			let isAllBedValue = true;

			value?.forEach((bedConfig: BedConfig) => {
				let isValid = false;
				bedOption.forEach((option) => {
					const count = bedConfig.beddings[option.id as Bed];
					if (count && !isNaN(Number(count)) && Number(count) > 0) {
						isValid = true;
					}
				});

				if (!isValid) isAllBedValue = false;
			});

			return isAllBedValue;
		});
	}
);

yup.addMethod<yup.ArraySchema<any, any>>(
	yup.array,
	'requireBedConfigs',
	function (message) {
		return this.test('requireBedConfigs', message, function (value) {
			let isValid = false;
			value?.forEach((bedConfig: BedConfig) => {
				bedOption.forEach((option) => {
					const count = bedConfig.beddings[option.id as Bed];
					if (count && !isNaN(Number(count)) && Number(count) > 0) {
						isValid = true;
						return;
					}
				});
			});

			return isValid;
		});
	}
);

yup.addMethod<yup.ObjectSchema<any>>(
	yup.object,
	'requireBeddings',
	function (message) {
		return this.test('requireBeddings', message, function (value) {
			let isValid = false;
			bedOption.forEach((option) => {
				const count = value[option.id as Bed];
				if (count && !isNaN(Number(count)) && Number(count) > 0) {
					return (isValid = true);
				}
			});

			return isValid;
		});
	}
);

yup.addMethod<yup.ArraySchema<any, any>>(
	yup.array,
	'perPersonRateUnique',
	function (field, message) {
		return this.test('perPersonRateUnique', message, function (array) {
			const errors: ValidationError[] = [];

			array.reduce(
				(
					agg: PerPersonRateObject[],
					curr: PerPersonRateObject,
					index: number
				) => {
					const found = agg.findIndex(
						(x) => x.numOfAdults === curr.numOfAdults
					);
					if (found != -1) {
						const indexOfFirst = array.findIndex(
							(x: PerPersonRateObject) => x.numOfAdults === curr.numOfAdults
						);

						errors.push(
							new ValidationError(
								message,
								false,
								`${this.path}.${indexOfFirst}.${field}`
							)
						);

						errors.push(
							new ValidationError(
								message,
								false,
								`${this.path}.${index}.${field}`
							)
						);
					} else {
						agg.push({
							numOfAdults: curr.numOfAdults,
							rate: curr.rate,
						});
					}
					return agg;
				},
				[]
			);

			if (errors.length > 0)
				return this.createError({
					message: () => errors,
				});
			else return true;
		});
	}
);

declare module 'yup' {
	interface ObjectSchema<TIn> {
		validateAgeRange(message: string): ObjectSchema<TIn>;
		requireAgeRange(message: string): ObjectSchema<TIn>;
		validateRoomSize(message: string): ObjectSchema<TIn>;
		requireBeddings(message: string): this;
	}

	interface ArraySchema<TIn, TContext, TDefault, TFlags> {
		requireBedConfigs(message: string): this;
		validateBedConfigs(message: string): this;
		perPersonRateUnique(field: string, message: string): this;
	}
}

export const yupMapRules = (map: any, rule: any) =>
	map
		? Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {})
		: {};

export default yup;

import Option from '../../common/types/option.type';

export enum UserRole {
	SYSTEM_ADMIN = 0,
	ADMIN = 1,
	CS = 2,
	DE = 3,
	ACC = 4,
}

export const userRoleOptions: Option[] = [
	{
		id: UserRole.SYSTEM_ADMIN,
		name: 'System Admin',
	},
	{
		id: UserRole.ADMIN,
		name: 'Admin',
	},
	{
		id: UserRole.CS,
		name: 'Customer Service Officer',
	},
	{
		id: UserRole.DE,
		name: 'Data Entry Officer',
	},
	{
		id: UserRole.ACC,
		name: 'Accountant',
	},
];

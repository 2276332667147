import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/delete.svg';
import { SupplierDocument } from '../../../api/DTO/supplier.interface';
import { StyledTextField } from '../../../common/components/styled/StyledTextField';

interface UploadedFileWithDescriptionProps {
	index: number;
	value: SupplierDocument;
	onChange: (value: SupplierDocument) => void;
	deleteOnClick: (index: number) => void;
}

export default function UploadedFileWithDescription({
	index,
	value,
	onChange,
	deleteOnClick,
}: UploadedFileWithDescriptionProps) {
	const handleDescriptionChange = (newDescription: string) => {
		const onChangeValue = value;
		onChangeValue.description = newDescription;
		onChange(onChangeValue);
	};

	return (
		<>
			<Box sx={{ display: 'flex', gap: '20px' }}>
				<Box>
					<Typography width={'160px'} fontSize={'12px'} color='#808080'>
						{value.name}
					</Typography>
				</Box>

				<Grid container columnSpacing={'20px'}>
					<Grid item xs>
						<StyledTextField
							value={value.description ?? ''}
							onChange={(e) => {
								handleDescriptionChange(e.target.value);
							}}
						/>
					</Grid>

					<Grid item xs={'auto'}>
						<Box
							sx={{
								position: 'relative',
								width: '100%',
							}}
						>
							<DeleteIcon
								color='#F6403F'
								cursor={'pointer'}
								onClick={() => deleteOnClick(index)}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

import { Box, Typography } from '@mui/material';
import { ApiSupplierType } from '../../../api/enum/ApiSupplier.enum';
import { ReactComponent as RakutenIcon } from '../../../assets/icon/Rakuten.svg';
import { ReactComponent as MeituanIcon } from '../../../assets/icon/Meituan.svg';

interface ApiSupplierLabelDisplayProps {
	apiSupplier: ApiSupplierType;
	label?: string;
}

export default function ApiSupplierLabelDisplay({
	apiSupplier,
	label,
}: ApiSupplierLabelDisplayProps) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<>
				{apiSupplier === ApiSupplierType.RAKUTEN && (
					<RakutenIcon style={{ marginRight: '10px' }} />
				)}
				{apiSupplier === ApiSupplierType.MEITUAN && (
					<MeituanIcon style={{ marginRight: '10px' }} />
				)}
				<Typography fontSize='12px'>{label}</Typography>
			</>
		</Box>
	);
}

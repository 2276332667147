import { Button, Typography } from '@mui/material';
import AppModal from './AppModal';

interface DeleteUserModalProps {
	id: string;
	index: number;
	handleDeleteClick: (id: string, index: number) => void;
}

const DeleteGalleryImageModal = ({
	id,
	index,
	handleDeleteClick,
}: DeleteUserModalProps) => {
	const deleteUserButtonOnClick = () => {
		handleDeleteClick(id, index);
	};

	return (
		<AppModal
			title={
				<Typography variant='bold' color='#DA5150'>
					Delete Photo
				</Typography>
			}
			content={<>Are you sure you want to delete this photo?</>}
			actionButtonEl={
				<Button
					variant='contained'
					color='error'
					onClick={deleteUserButtonOnClick}
				>
					Delete
				</Button>
			}
		/>
	);
};

export default DeleteGalleryImageModal;

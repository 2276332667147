import Option from '../../common/types/option.type';

export enum Department {
	RESERVATION = 0,
	SALES = 1,
	FINANCE = 2,
	OTHER = 3,
	AMENDMENT = 4,
}

export const departmentOption: Option[] = [
	{
		id: Department.RESERVATION,
		name: 'Reservation',
	},
	{
		id: Department.SALES,
		name: 'Sales',
	},
	{
		id: Department.FINANCE,
		name: 'Finance',
	},
	{
		id: Department.OTHER,
		name: 'Other',
	},
	{
		id: Department.AMENDMENT,
		name: 'Amendment',
	},
];

export function getAbkDepartmentDisplay(department: number) {
	switch (department) {
		case Department.RESERVATION:
			return 'Reservation';
		case Department.SALES:
			return 'Sales';
		case Department.FINANCE:
			return 'Finance';
		case Department.OTHER:
			return 'Other';
		case Department.AMENDMENT:
			return 'Amendment';
		default:
			return 'Error';
	}
}

/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Box,
	FormControl,
	Stack,
	TextFieldProps,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import {
	StyledMenuItem,
	StyledSelect,
} from '../../../common/components/styled/StyledSelect';
import Option from '../../../common/types/option.type';
import { DISPLAY_DATE_FORMAT } from '../../../utils/dateHelper';

interface DocListDropDownMenuProps {
	passObjectValue?: boolean;
	label?: string;
	options: any[];
	infoPopUp?: ReactNode;
	removeErrorPadding?: boolean;
}

export default function DocListDropDownMenu<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	passObjectValue,
	label,
	options,
	infoPopUp,
	removeErrorPadding,
	disabled,
	placeholder,
	required,
	...props
}: DocListDropDownMenuProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<Box sx={{ padding: '16px' }}>
			<Typography
				color='#808080'
				fontSize={'14px'}
				fontWeight={700}
				paddingBottom={'15px'}
			>
				{label}
			</Typography>
			<FormControl error={!!fieldState.error?.message} sx={{ width: '100%' }}>
				<StyledSelect
					variant='outlined'
					{...field}
					sx={{
						// placeholder for Select
						'& .MuiSelect-select .notranslate::after': placeholder
							? {
									content: `"${placeholder}"`,
									color: '#808080',
									opacity: 1,
							  }
							: {},
					}}
					onChange={(e) => {
						field.onChange(
							passObjectValue
								? options.find((i) => i.version === e.target.value)
								: e.target.value
						);
					}}
					value={
						field.value == null
							? ''
							: passObjectValue
							? (field.value as Option).version
							: field.value
					} // to fix `value` prop on `input` should not be null
					disabled={disabled}
					MenuProps={{
						PaperProps: {
							sx: {
								borderRadius: '5px',
								boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
								'& .MuiMenuItem-root': {
									padding: '12px',
								},
								maxHeight: '250px',
							},
						},
					}}
				>
					{options.map((option) => {
						return (
							<StyledMenuItem key={option.version} value={option.version}>
								<Stack direction={'row'} alignItems={'center'}>
									<Typography variant='regular' sx={{ fontSize: '0.75rem' }}>
										{option.name} (
										{dayjs(option.createdAt).format(DISPLAY_DATE_FORMAT)})
									</Typography>
								</Stack>
							</StyledMenuItem>
						);
					})}
				</StyledSelect>
			</FormControl>
		</Box>
	);
}

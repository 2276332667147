import Option from '../../common/types/option.type';

export enum RoomSizeUnit {
	SQFT = 0,
	SQM = 1,
}

export const roomSizeUnitOption: Option[] = [
	{
		id: RoomSizeUnit.SQFT,
		name: 'sq. ft',
	},
	{
		id: RoomSizeUnit.SQM,
		name: 'sq. m',
	},
];

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SW_INIT, SW_UPDATE } from './types';
import { RootState } from './store';

export interface IServiceWorker {
	swType: string | null;
	serviceWorkerRegistration: ServiceWorkerRegistration | null;
}

type ServiceWorkerState = {
	serviceWorkerInitialized: boolean;
	serviceWorkerUpdated: boolean;
	serviceWorkerRegistration: ServiceWorkerRegistration | null;
};

const serviceWorkerSlice = createSlice({
	name: 'servieWorker',
	initialState: {
		serviceWorkerInitialized: false,
		serviceWorkerUpdated: false,
		serviceWorkerRegistration: null,
	} as ServiceWorkerState,
	reducers: {
		setSWState: (state, action: PayloadAction<IServiceWorker>) => {
			const { swType } = action.payload;

			if (swType === SW_INIT) {
				state.serviceWorkerInitialized = !state.serviceWorkerInitialized;
			} else if (swType === SW_UPDATE) {
				const { serviceWorkerRegistration } = action.payload;
				state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
				state.serviceWorkerRegistration = serviceWorkerRegistration;
			}
		},
	},
});

export const { setSWState } = serviceWorkerSlice.actions;

export default serviceWorkerSlice.reducer;

export const selectServiceWorkerInitialized = (state: RootState) =>
	state.servieWorker.serviceWorkerInitialized;

export const selectServiceWorkerUpdated = (state: RootState) =>
	state.servieWorker.serviceWorkerUpdated;

export const selectServiceWorkerRegistration = (state: RootState) =>
	state.servieWorker.serviceWorkerRegistration;

import { ReactComponent as NoIcon } from '../../../assets/icon/no.svg';
import { ReactComponent as FreeIcon } from '../../../assets/icon/free.svg';
import { ReactComponent as BYOIcon } from '../../../assets/icon/BYO.svg';
import { ReactComponent as EnquiryIcon } from '../../../assets/icon/enquiry.svg';
import { ReactComponent as PaidIcon } from '../../../assets/icon/paid.svg';

interface FacilityIconProps {
	icon: string;
	height: string;
	width: string;
}

export default function FacilityIcon({
	icon,
	height,
	width,
}: FacilityIconProps) {
	switch (icon) {
		case 'NoIcon':
			return <NoIcon height={height} width={width} />;
		case 'FreeIcon':
			return <FreeIcon height={height} width={width} />;
		case 'BYOIcon':
			return <BYOIcon height={height} width={width} />;
		case 'EnquiryIcon':
			return <EnquiryIcon height={height} width={width} />;
		case 'PaidIcon':
			return <PaidIcon height={height} width={width} />;
		default:
			return <></>;
	}
}

import {
	Box,
	ListItemButton,
	ListItemButtonProps,
	ListItemText,
	Typography,
} from '@mui/material';
import { RoomSummary } from '../../../api/DTO/room.interface';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as ArrowForwardIcon } from '../../../assets/icon/arrow-forward.svg';

interface ListItemLinkProps extends ListItemButtonProps {
	room: RoomSummary;
	to: string;
	selected: boolean;
}

export default function ListItemLink(props: ListItemLinkProps) {
	const { room, to, selected, ...other } = props;

	return (
		<li>
			<ListItemButton
				component={RouterLink as any}
				to={to}
				{...other}
				selected={selected}
				sx={{
					padding: '4px 0px 4px 10px',
					borderRadius: '5px',
					'&.Mui-selected': {
						color: '#32A5F1',
						backgroundColor: '#E8F5FD',
						border: '1px solid #E8F5FD',
					},
				}}
			>
				<ListItemText
					primary={
						<Box>
							<Typography
								fontSize='12px'
								color={selected ? '#32A5F1' : 'text.grey'}
							>
								{room.nameEn}
							</Typography>
							<Typography
								fontSize='12px'
								color={selected ? '#32A5F1' : 'text.grey'}
							>
								{room.name}
							</Typography>
						</Box>
					}
				/>
				{!selected && <ArrowForwardIcon />}
			</ListItemButton>
		</li>
	);
}

import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import { useGetPropertyQuery } from '../../../../api/accommodationApiSlice';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import LinkDisplayText from '../../../../common/components/form-data-display/LinkDisplayText';
import { Dispatch, SetStateAction, useContext } from 'react';
import { PropertyIdContext } from '../ViewPropertyPage';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';
import FlagLabelDisplay from '../../../../common/components/form-data-display/FlagLabelDisplay';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';

export default function LocationView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const propertyId = useContext(PropertyIdContext);

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppInputLayout label={t('property.CountryOrRegion')}>
					<FlagLabelDisplay
						iso2={getPropertyResponse?.data.location.country.iso2}
						label={getPropertyResponse?.data.location.country.name}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('property.MajorArea')}>
					<NewDisplayText
						text={getPropertyResponse?.data.location.majorArea.name}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('property.Area')}>
					<NewDisplayText text={getPropertyResponse?.data.location.area.name} />
				</AppInputLayout>
				<Divider sx={{ marginBottom: '15px' }} />
				<AppInputLayout label={t('property.AddressInDetail')}>
					<NewDisplayText text={getPropertyResponse?.data.location.address} />
				</AppInputLayout>
				<AppInputLayout label={t('property.GoogleMapLink')}>
					<LinkDisplayText link={getPropertyResponse?.data.location.mapLink} />
				</AppInputLayout>
				<AppInputLayout label={t('property.LocationCoordinates')}>
					<NewDisplayText
						text={`${getPropertyResponse?.data.location.coordinates?.latitude.toString()}, ${getPropertyResponse?.data.location.coordinates?.longitude.toString()}`}
					/>
				</AppInputLayout>
				<Divider sx={{ marginBottom: '15px' }} />
				<AppInputLayout label={t('property.AirportNearby')} infoPopUp=' '>
					{/* <NewDisplayText
						text={getPropertyResponse?.data.location.nearbyAirports}
					/> */}
				</AppInputLayout>
				<AppInputLayout label={t('property.WithinAirportArea')}>
					<YesNoDisplay
						value={getPropertyResponse?.data.location.withinAirportArea}
					/>
				</AppInputLayout>
				<AppInputLayout
					label={t('property.RailwayStationNearby')}
					infoPopUp=' '
				></AppInputLayout>
			</Stack>
		</>
	);
}

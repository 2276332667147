/* eslint-disable no-mixed-spaces-and-tabs */
import { ReactNode } from 'react';
import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	FormControl,
	MenuItem,
	Select,
	Stack,
	styled,
	TextFieldProps,
	Typography,
} from '@mui/material';
import Option from '../../types/option.type';
import AppInputLayout from '../form-layout/AppInputLayout';
import CountryFlag from '../form-data-display/CountryFlag';

interface AppDropDownMenuV2Props {
	passObjectValue?: boolean;
	label: string;
	options: Option[];
	infoPopUp?: ReactNode;
}

const StyledSelect = styled(Select)(({ theme }) => ({
	display: 'flex',
	fontSize: 12,
	height: '30px',

	'label + &': {
		marginTop: '28px',
	},

	'& fieldset': {
		borderColor: '#BCBCBC !important',
		borderWidth: '1px',
		borderRadius: '5px',
	},

	':hover ': {
		borderWidth: '1px',
		borderColor: '#BCBCBC !important',
	},

	'&.Mui-focused fieldset': {
		borderWidth: '1px !important',
		borderColor: '#4EA4D3 !important',
	},

	'&.Mui-error fieldset': {
		borderWidth: '1px !important',
		borderColor: '#DA5150 !important',
	},
	'&.Mui-disabled fieldset': {
		borderColor: '#F5F5F5 !important',
	},
	'&.Mui-disabled': {
		background: '#F5F5F5',
	},
	'.Mui-disabled': {
		WebkitTextFillColor: '#808080',
	},
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	fontSize: '12px',
	padding: '12px',
}));

export default function AppPhoneCodeDropDownMenuV2<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	passObjectValue,
	label,
	options,
	infoPopUp,
	disabled,
	placeholder,
	required,
	...props
}: AppDropDownMenuV2Props &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<AppInputLayout
			label={label}
			required={required}
			infoPopUp={infoPopUp}
			errorMessage={errorMessage}
		>
			<FormControl error={!!fieldState.error?.message}>
				<StyledSelect
					variant='outlined'
					{...field}
					sx={{
						// placeholder for Select
						'& .MuiSelect-select .notranslate::after': placeholder
							? {
									content: `"${placeholder}"`,
									color: '#808080',
									opacity: 1,
							  }
							: {},
					}}
					onChange={(e) => {
						field.onChange(
							passObjectValue
								? options.find((i) => i.countryCode === e.target.value)
								: e.target.value
						);
					}}
					value={
						field.value == null
							? ''
							: passObjectValue
							? (field.value as Option).countryCode
							: field.value
					} // to fix `value` prop on `input` should not be null
					disabled={disabled}
					MenuProps={{
						PaperProps: {
							sx: {
								borderRadius: '5px',
								boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
								'& .MuiMenuItem-root': {
									padding: '12px',
								},
								maxHeight: '250px',
							},
						},
					}}
				>
					{options.map((option) => (
						<StyledMenuItem key={option.countryCode} value={option.countryCode}>
							<Stack direction={'row'} alignItems={'center'}>
								{option.countryCode && (
									<CountryFlag
										iso2={option.countryCode}
										size='small'
										style={{ marginRight: '12px' }}
									/>
								)}
								<Typography variant='regular' sx={{ fontSize: '0.75rem' }}>
									+{option.phoneCode}
								</Typography>
							</Stack>
						</StyledMenuItem>
					))}
				</StyledSelect>
			</FormControl>
		</AppInputLayout>
	);
}

import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import defineAbilityFor from '../../../../casl/defineAbility';
import { FormCardType } from '../../../../common/components/card/FormCard';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';

import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import { getShortNoticeStatus } from '../../../../api/enum/shortNotice.enum';
import { useGetShortNoticeQuery } from '../../../../api/pageApiSlice';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../../utils/dateHelper';

export default function HomeSectionView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getShortNoticeResponse } = useGetShortNoticeQuery();

	return (
		<>
			<Stack position={'relative'}>
				{ability.can('create', 'Section') && (
					<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
						<UnderlinedIconButton
							icon={<EditIcon color='#32A5F1' />}
							onClick={() => {
								setType(FormCardType.edit);
							}}
						>
							{t('button.Edit')}
						</UnderlinedIconButton>
					</Box>
				)}

				<AppInputLayout label={t('page.LastUpdate')}>
					<NonEditableDisplayText
						text={dayjs(getShortNoticeResponse?.data.updatedAt).format(
							DISPLAY_DATE_TIME_FORMAT
						)}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('page.Status')}>
					<NonEditableDisplayText
						text={
							getShortNoticeStatus(getShortNoticeResponse?.data.status) ?? '-'
						}
					/>
				</AppInputLayout>
				<AppInputLayout label={t('page.Content')}>
					<NonEditableDisplayText
						text={getShortNoticeResponse?.data.content ?? '-'}
					/>
				</AppInputLayout>
			</Stack>
		</>
	);
}

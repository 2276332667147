import { GridRowsProp } from '@mui/x-data-grid';
import { RoomCalendar } from '../../../../api/DTO/propertyCalendar.interface';
import { RoomClass } from '../../../../api/enum/roomClass.enum';
import { PriceMode } from '../../../../api/enum/priceMode.enum';
import { CellType } from '../CellType.enum';

export enum RoomRow {
	MIN_PRICE = 'MIN_PRICE',
	SUPPLEMENT_RATE = 'SUPPLEMENT_RATE',
	ROOM_RATE = 'ROOM_RATE',
	ROOM_BASE_RATE = 'ROOM_BASE_RATE',
	ALLOTMENT = 'ALLOTMENT',
}

interface RowConfig {
	id: RoomRow;
	name: string;
	hint?: string;
	cellType: CellType;
}

export function getRoomRows(
	roomClass: RoomClass,
	priceMode?: PriceMode
): RowConfig[] {
	if (priceMode === PriceMode.PER_PERSON) {
		return perPersonRoomRows;
	} else {
		switch (roomClass) {
			case RoomClass.INDEPENDENT:
				return independentRoomRows;
			case RoomClass.SUPPLEMENT:
				return supplementRoomRows;
			default:
				return [];
		}
	}
}

const perPersonRoomRows: RowConfig[] = [
	{
		id: RoomRow.MIN_PRICE,
		name: 'Minimum Selling Rate',
		hint: 'To protect the rate error when changing the base rate incorrectly.',
		cellType: CellType.BLUE_NON_EDITABLE_TEXT_CELL,
	},
	{
		id: RoomRow.ALLOTMENT,
		name: 'Sold / Max. Allotment',
		hint: 'Direct Contract, Share Pool',
		cellType: CellType.RANGE_SELECTION_TEXT_CELL,
	},
];

const independentRoomRows: RowConfig[] = [
	{
		id: RoomRow.MIN_PRICE,
		name: 'Minimum Selling Rate',
		hint: 'To protect the rate error when changing the base rate incorrectly.',
		cellType: CellType.BLUE_NON_EDITABLE_TEXT_CELL,
	},
	{
		id: RoomRow.ROOM_RATE,
		name: 'Edit Base Rate',
		cellType: CellType.RANGE_SELECTION_TEXT_CELL,
	},
	{
		id: RoomRow.ALLOTMENT,
		name: 'Sold / Max. Allotment',
		hint: 'Direct Contract, Share Pool',
		cellType: CellType.RANGE_SELECTION_TEXT_CELL,
	},
];

const supplementRoomRows: RowConfig[] = [
	{
		id: RoomRow.MIN_PRICE,
		name: 'Minimum Selling Rate',
		hint: 'To protect the rate error when changing the base rate incorrectly.',
		cellType: CellType.BLUE_NON_EDITABLE_TEXT_CELL,
	},
	{
		id: RoomRow.SUPPLEMENT_RATE,
		name: 'Room Supplement',
		cellType: CellType.BLUE_NON_EDITABLE_TEXT_CELL,
	},
	{
		id: RoomRow.ROOM_BASE_RATE,
		name: 'Base Rate',
		cellType: CellType.NON_EDITABLE_TEXT_CELL,
	},
	{
		id: RoomRow.ALLOTMENT,
		name: 'Sold / Max. Allotment',
		hint: 'Direct Contract, Share Pool',
		cellType: CellType.RANGE_SELECTION_TEXT_CELL,
	},
];

export function transformApiDataToGridRowsProp(
	room: RoomCalendar,
	priceMode?: PriceMode
): GridRowsProp {
	return getRoomRows(room.roomClass, priceMode).map((row) => {
		const rowObject: { [key: string]: string | undefined } = {
			id: row.id,
			hint: row.hint,
			cellType: row.cellType,
		};

		Object.keys(room.dailyRoomConfigs).map((item) => {
			switch (row.id) {
				case RoomRow.MIN_PRICE: {
					rowObject[item] = `${room.minPrice ?? ''}`;
					break;
				}

				case RoomRow.SUPPLEMENT_RATE: {
					rowObject[item] = `${room.dailyRoomConfigs[item].supplement ?? '-'}`;
					break;
				}

				case RoomRow.ROOM_RATE: {
					rowObject[item] = `${room.dailyRoomConfigs[item].rate ?? ''}`;
					break;
				}

				case RoomRow.ROOM_BASE_RATE: {
					rowObject[item] = `${room.dailyRoomConfigs[item].basicRate ?? ''}`;
					break;
				}

				case RoomRow.ALLOTMENT: {
					rowObject[item] = `${room.dailyRoomConfigs[item].booked ?? ''}/${
						room.dailyRoomConfigs[item].allotment ?? ''
					}`;
					break;
				}

				default:
					break;
			}
		});

		return rowObject;
	});
}

import { Box, Link } from '@mui/material';
import { ReactComponent as LinkIcon } from '../../../assets/icon/link.svg';
import { ReactComponent as EmailArrow } from '../../../assets/icon/emailArrow.svg';
import { ReactComponent as Email } from '../../../assets/icon/email.svg';


interface LinkDisplayTextProps {
	email?: string;
}

export default function EmailDisplayText({ email }: LinkDisplayTextProps) {

	return (
		<Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
			<EmailArrow style={{ flex: '0 0 12px' }} color='#32A5F1' />
			<Link
				href={'mailto:' + email}
				rel='noreferrer'
				target='_blank'
				underline='none'
				fontSize='12px'
				color='#32A5F1'
				sx={{ wordBreak: 'break-word' }}
			>
				{email}
			</Link>
		</Box>
	);
}

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	MenuItem,
	Stack,
	styled,
	TextFieldProps,
	Typography,
} from '@mui/material';
import Option from '../../../common/types/option.type';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import { ReactNode, useEffect } from 'react';
import { StyledTextField } from '../../../common/components/styled/StyledTextField';
import { StyledAutocomplete } from '../../../common/components/styled/StyledAutocomplete';
import CountryFlag from '../form-data-display/CountryFlag';

interface AppAutocompleteProps {
	label: string;
	options: Option[];
	infoPopUp?: ReactNode;
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	fontSize: '12px',
	padding: '12px',
}));

export default function AppAutocomplete<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	options,
	infoPopUp,
	required,
	...props
}: AppAutocompleteProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	const toNullIfNullOption = (value?: unknown): Option | null | unknown => {
		const option = value as Option | null | undefined;

		if (option) {
			if (option.id === null) return null;
		}
		return options.find((i) => i.id === option?.id);
	};

	return (
		<>
			<AppInputLayout
				label={label}
				required={required}
				infoPopUp={infoPopUp}
				errorMessage={errorMessage}
			>
				<StyledAutocomplete
					{...field}
					disablePortal
					options={options}
					value={
						field.value
							? options.find((i) => i.id === (field.value as Option).id) ?? null
							: null
					}
					onChange={(_, newValue) =>
						field.onChange(toNullIfNullOption(newValue))
					}
					getOptionLabel={(option: any) => option.name || ''}
					isOptionEqualToValue={(option: any, value: any) =>
						option.id === value.id
					}
					renderInput={(params) => (
						<StyledTextField {...params} error={!!errorMessage} />
					)}
					ListboxProps={{
						style: {
							maxHeight: '250px',
						},
					}}
					renderOption={(
						props: React.HTMLAttributes<HTMLLIElement>,
						option: any
					) => (
						<StyledMenuItem {...props}>
							<Stack direction={'row'} alignItems={'center'}>
								{option.iso2 && (
									<CountryFlag
										iso2={option.iso2}
										size='small'
										style={{ marginRight: '12px' }}
									/>
								)}
								<Typography variant='regular' sx={{ fontSize: '0.75rem' }}>
									{option.name}
								</Typography>
							</Stack>
						</StyledMenuItem>
					)}
					disableClearable
				></StyledAutocomplete>
			</AppInputLayout>
		</>
	);
}

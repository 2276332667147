import styled from '@emotion/styled';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { currencies } from '../../../../common/constants/currencies';
import CurrencyDropDownMenu from './CurrencyDropDownMenu';

interface DefaultCurrencyDisplayProps {
	propertySupplierCurrency?: string;
	currency: string;
	exchangeRate?: string;
	taxPercentage?: string;
	serviceChargePercentage?: string;
	onChangeCallback: (optionId: any) => void;
}

export default function DefaultCurrencyDisplay({
	propertySupplierCurrency,
	currency,
	exchangeRate,
	taxPercentage,
	serviceChargePercentage,
	onChangeCallback,
}: DefaultCurrencyDisplayProps) {
	const { t } = useTranslation();

	const iso2 = currencies.find((option) => option.id === currency)?.iso2;

	return (
		<DefaultCurrentContainer>
			<Stack width={'100%'}>
				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Typography fontSize={'14px'}>
						{t('rateAndInventory.CurrencyViewAs')}:{' '}
					</Typography>

					<CurrencyDropDownMenu
						value={currency}
						onChangeCallback={onChangeCallback}
					/>
				</Stack>
				{(exchangeRate || taxPercentage || serviceChargePercentage) && (
					<Box display={'flex'}>
						<Typography fontSize={'10px'} color={'#808080'}>
							{exchangeRate &&
								`${propertySupplierCurrency} to ${currency}: ${exchangeRate}`}
							{exchangeRate && taxPercentage && `, `}
							{taxPercentage && `Tax: ${taxPercentage}%`}
							{taxPercentage && serviceChargePercentage && `, `}
							{serviceChargePercentage && `S.C.: ${serviceChargePercentage}%`}
						</Typography>
					</Box>
				)}
			</Stack>
		</DefaultCurrentContainer>
	);
}

const DefaultCurrentContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '250px',
	width: '250px',
	height: '52px',
	padding: '11px 0px 14px 20px',
	backgroundColor: '#FFF',
	borderRadius: '5px',
	boxShadow: '0px 4px 3px 0px rgba(0, 0, 0, 0.05)',
});

import { Box, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import {
	useGetPropertyQuery,
	useGetRoomQuery,
	useUpdateRoomStatusMutation,
} from '../../../../api/accommodationApiSlice';
import { Dispatch, SetStateAction } from 'react';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { useParams } from 'react-router-dom';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import { roomClassOption } from '../../../../api/enum/roomClass.enum';
import { RateModel } from '../../../../api/enum/rateModel.enum';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import { ApiSupplierType } from '../../../../api/enum/ApiSupplier.enum';
import { ApiRoom } from '../../../../api/DTO/apiRoom.interface';
import ApiRoomLabelDisplay from '../../../../common/components/form-data-display/ApiRoomLabelDisplay';
import AppStatusToggle from '../../../../common/components/AppStatusToggle';
import { RoomStatus } from '../../../../api/enum/roomStatus.enum';

export default function RoomSupplierView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const { propertyId, roomId } = useParams();

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');
	const { data: getRoomResponse } = useGetRoomQuery(roomId ?? '');
	const [updateRoomStatus] = useUpdateRoomStatusMutation();

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppInputLayout label={t('room.RoomStatus')} />
				<Box sx={{ marginBottom: '15px' }}>
					<AppStatusToggle
						enableI18nLabel={t('common.Online')}
						disableI18nLabel={t('common.Offline')}
						initialValue={
							getRoomResponse?.data.info.status === RoomStatus.ACTIVE
						}
						statusOnChange={() => {
							updateRoomStatus({
								roomId: roomId ?? '',
								body: {
									status:
										getRoomResponse?.data.info.status === RoomStatus.ACTIVE
											? RoomStatus.INACTIVE
											: RoomStatus.ACTIVE,
								},
							});
						}}
					/>
				</Box>
				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('common.DirectContract')} />

				<AppInputLayout
					label={t('room.MinimumSellingRate')}
					infoPopUp={t('hint.MinimumSellingRate')}
				>
					<Box textAlign={'end'}>
						<NewDisplayText
							text={`${
								getRoomResponse?.data.supplier.minPrice
									? getPropertyResponse?.data.supplier.currency
									: ''
							}${getRoomResponse?.data.supplier.minPrice?.toString() ?? '-'}`}
						/>
					</Box>
				</AppInputLayout>

				{getRoomResponse?.data.supplier.roomClasses?.[RateModel.AGENCY] !=
					undefined && (
					<AppInputLayout label={t('room.RoomClassAgencyModel')}>
						<Box textAlign={'end'}>
							<NonEditableDisplayText
								text={
									roomClassOption.find(
										(i) =>
											i.id ===
											getRoomResponse?.data.supplier.roomClasses?.[
												RateModel.AGENCY
											]
									)?.name
								}
							/>
						</Box>
					</AppInputLayout>
				)}

				{getRoomResponse?.data.supplier.roomClasses?.[RateModel.MERCHANT] !=
					undefined && (
					<AppInputLayout label={t('room.RoomClassMerchantModel')}>
						<Box textAlign={'end'}>
							<NonEditableDisplayText
								text={
									roomClassOption.find(
										(i) =>
											i.id ===
											getRoomResponse?.data.supplier.roomClasses?.[
												RateModel.MERCHANT
											]
									)?.name
								}
							/>
						</Box>
					</AppInputLayout>
				)}

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('common.Mapping')} />

				<AppInputLayout label={t('room.RakutenIDAndName')} infoPopUp={' '}>
					{getRoomResponse?.data.supplier.mappings[ApiSupplierType.RAKUTEN]
						.length === 0 && (
						<Box textAlign={'end'}>
							<Typography fontSize={'12px'} color='#BCBCBC'>
								/
							</Typography>
						</Box>
					)}
				</AppInputLayout>

				{getRoomResponse?.data.supplier.mappings[ApiSupplierType.RAKUTEN] &&
					getRoomResponse?.data.supplier.mappings[ApiSupplierType.RAKUTEN].map(
						(apiRoom: ApiRoom) => (
							<Box key={apiRoom.id} marginBottom={'15px'}>
								<ApiRoomLabelDisplay
									apiSupplier={ApiSupplierType.RAKUTEN}
									label={`${apiRoom.id} ${apiRoom.name}`}
									apiRoomId={apiRoom.id}
								/>
							</Box>
						)
					)}

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout label={t('room.MeituanIDAndName')} infoPopUp={' '}>
					{getRoomResponse?.data.supplier.mappings[ApiSupplierType.MEITUAN]
						.length === 0 && (
						<Box textAlign={'end'}>
							<Typography fontSize={'12px'} color='#BCBCBC'>
								/
							</Typography>
						</Box>
					)}
				</AppInputLayout>

				{getRoomResponse?.data.supplier.mappings[ApiSupplierType.MEITUAN] &&
					getRoomResponse?.data.supplier.mappings[ApiSupplierType.MEITUAN].map(
						(apiRoom: ApiRoom) => (
							<Box key={apiRoom.id} marginBottom={'15px'}>
								<ApiRoomLabelDisplay
									apiSupplier={ApiSupplierType.MEITUAN}
									label={`${apiRoom.id} ${apiRoom.name}`}
									apiRoomId={apiRoom.id}
								/>
							</Box>
						)
					)}
			</Stack>
		</>
	);
}

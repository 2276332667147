export enum AbkSupplierEmail {
	ENQUIRY = 0,
	RESERVATION_ORDER = 1,
	AMENDMENT = 2,
	ARRIVAL_DETAILS = 3,
	PAYMENT_SLIP = 4,
	REQUEST_FOR_REFUND = 5,
	CANCELLATION = 6,
	REFUND_RECEIPT = 7,
}

export function getAbkSupplierEmailDisplay(status: number) {
	switch (status) {
		case AbkSupplierEmail.ENQUIRY:
			return {
				name: 'Enquiry',
			};
		case AbkSupplierEmail.RESERVATION_ORDER:
			return {
				name: 'Reservation Order',
			};
		case AbkSupplierEmail.AMENDMENT:
			return {
				name: 'Amendment',
			};
		case AbkSupplierEmail.ARRIVAL_DETAILS:
			return {
				name: 'Arrival Details',
			};
		case AbkSupplierEmail.PAYMENT_SLIP:
			return {
				name: 'Payment Slip',
			};
		case AbkSupplierEmail.REQUEST_FOR_REFUND:
			return {
				name: 'Request for Refund',
			};
		case AbkSupplierEmail.CANCELLATION:
			return {
				name: 'Cancellation',
			};
		case AbkSupplierEmail.REFUND_RECEIPT:
			return {
				name: 'Refund Receipt',
			};
		default:
			return {
				name: '-',
			};
	}
}

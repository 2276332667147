import { CSSProperties } from 'react';

interface CountryFlagProps {
	iso2: string;
	size: 'medium' | 'small';
	style?: CSSProperties | undefined;
}

export default function CountryFlag({ iso2, size, style }: CountryFlagProps) {
	return (
		<>
			{iso2 && (
				<img
					src={`https://flagcdn.com/w20/${iso2.toLowerCase()}.png`}
					width={
						{
							small: '18px',
							medium: '24px',
						}[size]
					}
					height={
						{
							small: '12px',
							medium: '16px',
						}[size]
					}
					style={style}
					alt={`${iso2}-flag`}
				/>
			)}
		</>
	);
}

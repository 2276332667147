import { Box, Typography } from '@mui/material';
import {
	DailyRatePlanCondition,
	getRatePlanConditionTextColor,
	getRatePlanConditionText,
} from '../../../../api/enum/dailyRatePlanCondition';

interface RatePlanConditionCellProps {
	field: string;
	date: string;
	condition: DailyRatePlanCondition;
}

export default function RatePlanConditionCell({
	field,
	date,
	condition,
}: RatePlanConditionCellProps) {
	return (
		<Box
			id={`${date}_${field}`}
			width={'100%'}
			height={'100%'}
			justifyContent={'center'}
		>
			<Box
				width={'100%'}
				height={'100%'}
				style={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				<Typography
					className={'noselect'}
					sx={{
						display: 'block',
						fontSize: '12px',
						textAlign: 'center',
						lineHeight: '36px',
						color: getRatePlanConditionTextColor(condition),
					}}
					variant='regular'
				>
					{getRatePlanConditionText(condition)}
				</Typography>
			</Box>
		</Box>
	);
}

export enum PaymentType {
	FULL = 0,
	HOLD_LIMIT = 1,
}

export function getRatePlanPaymentType(paymentType: number): string {
	switch (paymentType) {
		case PaymentType.FULL:
			return 'Full Payment';
		case PaymentType.HOLD_LIMIT:
			return 'Hold Limit';
		default:
			return '';
	}
}

export const ratePlanPaymentTypeOptions = [
	{
		id: PaymentType.FULL,
		name: 'Full Payment',
	},
	{
		id: PaymentType.HOLD_LIMIT,
		name: 'Hold Limit',
	},
];

import { Box, Button, InputAdornment, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
	useBulkUpdateRatePlanSupplementsMutation,
	useGetPropertyQuery,
} from '../../../../../api/accommodationApiSlice';
import EditPopUpContainer from './EditPopUpContainer';
import { arrayOfWholeWeek } from '../../../../../api/enum/daysOfWeek';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';

interface EditRatePlanSupplementRatePopUpProps {
	ratePlanId: string;
	from: string;
	to: string;
	onCloseCallBack: () => void;
}

export default function EditRatePlanSupplementRatePopUp({
	ratePlanId,
	from,
	to,
	onCloseCallBack,
}: EditRatePlanSupplementRatePopUpProps) {
	const { t } = useTranslation();
	const { propertyId } = useParams();
	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');

	const defaultValues: DefaultValues<FieldValues> = {
		ratePlanIds: [],
		supplement: '',
	};

	const formSchema = Yup.object().shape({
		supplement: Yup.string().required(t('errorMessage.required')),
	});

	const { control, handleSubmit } = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [bulkUpdateRatePlanSupplementsMutation] =
		useBulkUpdateRatePlanSupplementsMutation();

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		bulkUpdateRatePlanSupplementsMutation({
			propertyId: propertyId ?? '',
			body: {
				from: from,
				to: to,
				days: arrayOfWholeWeek,
				ratePlanIds: [ratePlanId],
				supplement: data.supplement,
			},
		})
			.unwrap()
			.then((response) => {
				if (response.success) {
					onCloseCallBack();
				}
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<EditPopUpContainer
				title={t('rateAndInventory.RatePlanSupplement')}
				from={from}
				to={to}
				onCloseCallBack={onCloseCallBack}
			>
				<Box display='flex' justifyContent={'space-between'}>
					<Label>{t('rateAndInventory.RevisedRate')}</Label>
					<Box width='148px'>
						<AppTextField
							control={control}
							name={`supplement`}
							inputType={InputType.amount}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										{getPropertyResponse?.data?.supplier.currency}
									</InputAdornment>
								),
							}}
							autoFocus
						/>
					</Box>
				</Box>
				<Button
					variant={'contained'}
					onClick={handleSubmit(onSubmit)}
					fullWidth={true}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{t('common.Confirm')}
					</Typography>
				</Button>
			</EditPopUpContainer>{' '}
		</form>
	);
}

const Label = styled(Typography)({
	fontSize: '12px',
});

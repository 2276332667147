import { Box, Collapse, List, ListItemText, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ReactComponent as ExpandMoreIcon } from '../../../../assets/icon/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from '../../../../assets/icon/expand-less.svg';
import { useGetPfcategoriesQuery } from '../../../../api/accommodationApiSlice';
import ListItemLinkV2 from '../../../../common/components/list/ListItemLinkV2';
import { StyledListItemButton } from '../../../../common/components/styled/StyledListItemButton';
import { useTranslation } from 'react-i18next';
import { PFCategory } from '../../../../api/DTO/facility.interface';

export default function PropertyList() {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { propertyId } = useParams();

	const { data: getPfcategoriesResponse } = useGetPfcategoriesQuery(
		propertyId ?? ''
	);

	const [open, setOpen] = useState(propertyId ? true : false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<>
			<StyledListItemButton onClick={handleClick} selected={false}>
				<ListItemText
					primary={
						<Box>
							<Typography fontSize='12px' color={'text.grey'}>
								{t('facility.Property')}
							</Typography>
						</Box>
					}
				/>
				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</StyledListItemButton>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding sx={{ paddingLeft: '10px' }}>
					{getPfcategoriesResponse?.data?.map((pfcategory: PFCategory) => (
						<div key={pfcategory.id}>
							<ListItemLinkV2
								primarytext={pfcategory.nameEn}
								secondaryText={pfcategory.name}
								to={`/property/${propertyId}/facility/property/${pfcategory.id}`}
								selected={pathname.includes(
									`/facility/property/${pfcategory.id}`
								)}
							/>
						</div>
					))}
				</List>
			</Collapse>
		</>
	);
}

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	List,
	ListItem,
	Stack,
	Tooltip,
	Typography,
	styled,
} from '@mui/material';
import {
	RatePlanCalendar,
	RoomCalendar,
} from '../../../../api/DTO/propertyCalendar.interface';
import { ReactComponent as InvertedTriangle } from '../../../../assets/icon/inverted-triangle.svg';
import { getRatePlanRows } from './RatePlanRow.enum';
import { RateModel } from '../../../../api/enum/rateModel.enum';
import { RefObject, useState } from 'react';
import PerPersonRateToggle, { PerPersonRateType } from './PerPersonRateToggle';
import { PriceMode } from '../../../../api/enum/priceMode.enum';
import { ReactComponent as ViewDetailIcon } from '../../../../assets/icon/view-detail.svg';
import { ReactComponent as CircleInfoIcon } from '../../../../assets/icon/circleInfo.svg';
import { ReactComponent as RakutenIcon } from '../../../../assets/icon/Rakuten.svg';
import { ReactComponent as MeituanIcon } from '../../../../assets/icon/Meituan.svg';
import { Link } from 'react-router-dom';
import OnlineSwitchButton from './OnlineSwitchButton';
import { RatePlanStatus } from '../../../../api/enum/ratePlanStatus';
import { useUpdateRatePlanStatusMutation } from '../../../../api/accommodationApiSlice';
import { ApiSupplierType } from '../../../../api/enum/ApiSupplier.enum';
import { AutoSizer, Grid } from 'react-virtualized';
import RatePlanGrid from './RatePlanGrid';

interface RatePlanAccordionProps {
	roomIndex: number;
	room: RoomCalendar;
	ratePlanIndex: number;
	ratePlan: RatePlanCalendar;
	rateModel: RateModel;
	priceMode: PriceMode;
	sellAtOfficialRate?: boolean;
	maxAdultCapacity: number;
	dateCalendarRef: RefObject<HTMLDivElement>;
	roomGrid: (Grid | null)[];
	ratePlanGrid: (Grid | null)[][];
	propertyId: string;
	roomId: string;
}

export default function RatePlanAccordion({
	roomIndex,
	room,
	ratePlanIndex,
	ratePlan,
	rateModel,
	priceMode,
	sellAtOfficialRate,
	maxAdultCapacity,
	dateCalendarRef,
	roomGrid,
	ratePlanGrid,
	propertyId,
	roomId,
}: RatePlanAccordionProps) {
	const [perPersonRateType, setPerPersonRateType] = useState(
		PerPersonRateType.EDIT_RATE
	);

	const [updateRatePlanStatus] = useUpdateRatePlanStatusMutation();

	const ratePlanStatusColor =
		ratePlan.displayStatus === RatePlanStatus.ACTIVE
			? roomIndex % 2
				? '#2B769F'
				: '#32A5F1'
			: roomIndex % 2
			? '#808080'
			: '#BCBCBC';

	return (
		<StyledRatePlanAccordion
			disableGutters
			defaultExpanded
			sx={{
				borderLeft: `20px solid ${ratePlanStatusColor}`,
			}}
		>
			<RatePlanAccordionSummary
				expandIcon={<InvertedTriangle />}
				sx={{
					backgroundColor: 'white',
					borderBottom: '1px solid #EAEAEA',
				}}
			>
				<Box display={'flex'} width={'100%'}>
					<Stack
						sx={{ marginRight: '1rem', flexShrink: 0 }}
						direction={'row'}
						alignItems={'center'}
						maxWidth={'65%'}
					>
						{ratePlan.apiSupplier === ApiSupplierType.RAKUTEN && (
							<RakutenIcon width={'28px'} height={'28px'} />
						)}
						{ratePlan.apiSupplier === ApiSupplierType.MEITUAN && (
							<MeituanIcon width={'28px'} height={'28px'} />
						)}

						<Box marginLeft={'8px'}>
							<RatePlanName>
								{ratePlan.name}

								<Link
									to={`/property/${propertyId}/room/${roomId}/rate-plan/${ratePlan.id}`}
									target='_blank'
									style={{
										color: '#32A5F1',
										display: 'inline-block',
										paddingLeft: '5px',
									}}
								>
									<ViewDetailIcon
										onClick={(event) => {
											event.stopPropagation();
										}}
									/>
								</Link>
							</RatePlanName>

							<RatePlanID>ID: {ratePlan.id}</RatePlanID>
						</Box>
					</Stack>
					<Box sx={{ width: '100%' }}></Box>
					{priceMode === PriceMode.PER_PERSON && (
						<Box marginRight={'15px'} display={'flex'} alignItems={'center'}>
							<PerPersonRateToggle
								value={perPersonRateType}
								handleChange={(_, newPerPersonRateType) => {
									if (
										newPerPersonRateType !== null &&
										newPerPersonRateType !== undefined
									)
										setPerPersonRateType(newPerPersonRateType);
								}}
								selectedColor={
									ratePlan.displayStatus === RatePlanStatus.ACTIVE
										? undefined
										: '#BCBCBC'
								}
								showSupplierCost={rateModel == RateModel.AGENCY}
							/>
						</Box>
					)}
					<Box marginRight={'15px'} display={'flex'} alignItems={'center'}>
						<OnlineSwitchButton
							checked={ratePlan.status === RatePlanStatus.ACTIVE}
							onClickAction={() => {
								updateRatePlanStatus({
									ratePlanId: ratePlan.id,
									body: {
										status:
											ratePlan.status === RatePlanStatus.ACTIVE
												? RatePlanStatus.INACTIVE
												: RatePlanStatus.ACTIVE,
									},
								});
							}}
						/>
					</Box>
				</Box>
			</RatePlanAccordionSummary>
			<RatePlanAccordionDetails>
				<Box display={'flex'}>
					<Box minWidth={'242px'} width={'242px'} marginTop={'1px'}>
						<StyledList>
							{getRatePlanRows(
								ratePlan.channel,
								rateModel,
								ratePlan.enableSupplement,
								priceMode,
								perPersonRateType,
								maxAdultCapacity,
								sellAtOfficialRate
							).map((row, index) => (
								<div
									key={`${ratePlan.id}_${row.id}${
										row.numOfAdult ? `_${row.numOfAdult}` : ''
									}`}
								>
									{index > 0 && <Divider />}
									<StyledListItem>
										<Typography fontSize={'14px'}>{row.name}</Typography>
										{row.hint && (
											<Tooltip
												title={row.hint}
												placement='bottom-start'
												style={{
													marginLeft: '5px',
													color: '#808080',
												}}
											>
												<CircleInfoIcon />
											</Tooltip>
										)}
									</StyledListItem>
								</div>
							))}
						</StyledList>
					</Box>
					<Box width='100%' height={'auto'} minWidth={0}>
						<AutoSizer>
							{({ height, width }) => (
								<>
									<RatePlanGrid
										gridRef={(ref) => {
											ratePlanGrid[roomIndex] = ratePlanGrid[roomIndex] || [];
											ratePlanGrid[roomIndex][ratePlanIndex] = ref;
										}}
										width={width}
										height={height}
										onScroll={(params) => {
											if (dateCalendarRef.current)
												dateCalendarRef.current.scrollLeft = params.scrollLeft;

											ratePlanGrid.forEach((array) => {
												array.forEach((grid, i) => {
													if (i !== ratePlanIndex) {
														grid?.scrollToPosition({
															scrollLeft: params.scrollLeft,
															scrollTop: grid.state.scrollTop,
														});
													}
												});
											});

											roomGrid.forEach((grid, i) => {
												grid?.scrollToPosition({
													scrollLeft: params.scrollLeft,
													scrollTop: grid.state.scrollTop,
												});
											});
										}}
										rateModel={rateModel}
										ratePlan={ratePlan}
										priceMode={priceMode}
										sellAtOfficialRate={sellAtOfficialRate}
										perPersonRateType={perPersonRateType}
										maxAdultCapacity={maxAdultCapacity}
										dailyRoomConfigs={room.dailyRoomConfigs}
									/>
								</>
							)}
						</AutoSizer>
					</Box>
				</Box>
			</RatePlanAccordionDetails>
		</StyledRatePlanAccordion>
	);
}

const StyledRatePlanAccordion = styled(Accordion)({
	borderRadius: '5px',
	boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.10)',
});

const RatePlanAccordionSummary = styled(AccordionSummary)({
	padding: '6px 12px',
	borderRadius: '5px 5px 5px 5px',
	'.MuiAccordionSummary-content': {
		margin: '0px',
	},
});

const RatePlanName = styled(Typography)({
	fontSize: '16px',
	fontWeight: 700,
	color: '#32A5F1',
});

const RatePlanID = styled(Typography)({
	fontSize: '10px',
	color: '#808080',
});

const RatePlanAccordionDetails = styled(AccordionDetails)({
	padding: '0px',
});

const StyledList = styled(List)({
	padding: '0px',
});

const StyledListItem = styled(ListItem)({
	height: '36px',
});

import AbkMoreActionOption from '../../common/types/option.type';
import { ReactComponent as EditIcon } from '../../assets/icon/edit.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icon/arrow.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icon/delete.svg';
import { ReactComponent as CircleTickIcon } from '../../assets/icon/circleTick.svg';

export enum AbkMoreAction {
	EDIT = 0,
	PROCEED = 1,
	REMOVE = 2,
	CONFIRM = 3,
	CANCEL = 4,
}

export const abkEnquiryMoreActionOptions: AbkMoreActionOption[] = [
	{
		id: AbkMoreAction.EDIT,
		name: 'Edit Enquiry',
		startIcon: <EditIcon />,
		color: '#000000',
	},
	{
		id: AbkMoreAction.PROCEED,
		name: 'Proceed as Reservation',
		startIcon: <ArrowIcon />,
		color: '#4EA4D3',
	},
	{
		id: AbkMoreAction.REMOVE,
		name: 'Remove Enquiry',
		startIcon: <DeleteIcon />,
		color: '#DA5150',
		backgroundColor: '#FFDDDC',
	},
];

export const abkNewMoreActionOptions: AbkMoreActionOption[] = [
	{
		id: AbkMoreAction.EDIT,
		name: 'Edit Booking',
		startIcon: <EditIcon />,
		color: '#000000',
	},
	{
		id: AbkMoreAction.CONFIRM,
		name: 'Confirm Booking',
		startIcon: <CircleTickIcon />,
		color: '#79B765',
	},
	{
		id: AbkMoreAction.CANCEL,
		name: 'Cancel Booking',
		startIcon: <DeleteIcon />,
		color: '#DA5150',
		backgroundColor: '#FFDDDC',
	},
];

export const abkConfirmedMoreActionOptions: AbkMoreActionOption[] = [
	{
		id: AbkMoreAction.EDIT,
		name: 'Edit Booking',
		startIcon: <EditIcon />,
		color: '#000000',
	},
	{
		id: AbkMoreAction.CANCEL,
		name: 'Cancel Booking',
		startIcon: <DeleteIcon />,
		color: '#DA5150',
		backgroundColor: '#FFDDDC',
	},
];

import Option from '../../common/types/option.type';

export enum PriceMode {
	PER_ROOM = 0,
	PER_PERSON = 1,
}

export const priceModeOption: Option[] = [
	{
		id: PriceMode.PER_ROOM,
		name: 'Per Room',
	},
	{
		id: PriceMode.PER_PERSON,
		name: 'Per Person',
	},
];

import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppToggleButtonGroup from '../../../../common/components/form-inputs/AppToggleButtonGroup';

import { useParams } from 'react-router-dom';
import {
	useGetPfsubcategoriesQuery,
	useLazyGetPropertyFacilitiesQuery,
	useUpdatePropertyFacilitiesMutation,
} from '../../../../api/accommodationApiSlice';
import { facilityOption } from '../../../../api/enum/facility.enum';
import { PFacilityMapping } from '../../../../api/DTO/facility.interface';
import AppAlert from '../../../../common/components/AppAlert';

interface PropertyFacilityFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function PropertyFacilityForm({
	type,
	setType,
}: PropertyFacilityFormProps) {
	const { propertyId, pfcategoryId } = useParams();

	const { t } = useTranslation();

	const [getPropertyFacilities] = useLazyGetPropertyFacilitiesQuery();

	const { data: getPfsubcategoriesResponse } = useGetPfsubcategoriesQuery(
		pfcategoryId ?? '',
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const { control, handleSubmit, reset } = useForm<PFacilityMapping>({
		mode: 'onChange',
	});

	const [
		updatePropertyFacilities,
		{ isSuccess: isUpdatePropertyFacilitiesSuccess },
	] = useUpdatePropertyFacilitiesMutation();

	useEffect(() => {
		const fetchTargetLocaleData = async () => {
			const targetLocaleResponse = await getPropertyFacilities({
				propertyId: propertyId ?? '',
				pfcategoryId: pfcategoryId ?? '',
			});
			reset(targetLocaleResponse.data?.data);
		};
		fetchTargetLocaleData();
	}, [propertyId, pfcategoryId]);

	useEffect(() => {
		if (isUpdatePropertyFacilitiesSuccess) setType(FormCardType.view);
	}, [isUpdatePropertyFacilitiesSuccess]);

	const onSubmit: SubmitHandler<PFacilityMapping> = (data) => {
		Object.keys(data).forEach((key) => {
			if (data[key] === '' || data[key] == null) {
				delete data[key];
			}
		});

		updatePropertyFacilities({
			propertyId: propertyId ?? '',
			pfcategoryId: pfcategoryId ?? '',
			body: data,
		});
	};

	const formContent = (
		<>
			<Grid
				container
				columnSpacing='40px'
				rowSpacing='20px'
				marginTop='20px'
				marginBottom='20px'
				padding='0px'
				position='relative'
			>
				<Box
					position={'absolute'}
					sx={{
						top: -20,
						left: 35,
					}}
				>
					<AppAlert alertColor='#32A5F1' alertBar={false}>
						{t('facility.TagStyleMessage')}
					</AppAlert>
				</Box>
				{getPfsubcategoriesResponse?.data.map(
					(subCategory, subcategoryIndex) => {
						return (
							<Grid item xs={12} key={subCategory.id}>
								<Box alignItems={'center'}>
									<Box minWidth={'17rem'}>
										<Typography fontSize={14} fontWeight={700}>
											{subCategory.nameEn}
										</Typography>
									</Box>
									{subCategory.facilities?.map((facility) => {
										return (
											<Box key={facility.id} marginBottom={'10px'}>
												<AppToggleButtonGroup
													name={facility.id ?? ''}
													options={facilityOption}
													control={control}
													formLabel={facility.nameEn}
													color={facility.highlighted ? '#32A5F1' : '#808080'}
												/>
											</Box>
										);
									})}
								</Box>
								{subcategoryIndex <
									getPfsubcategoriesResponse?.data.length - 1 && (
									<Divider sx={{ marginTop: '15px' }} />
								)}
							</Grid>
						);
					}
				)}
			</Grid>
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</>
	);

	const form = <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;

	return <>{form}</>;
}

import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PlaceIcon from '@mui/icons-material/Place';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DiscountIcon from '@mui/icons-material/Discount';
import AddHomeIcon from '@mui/icons-material/AddHome';
import DateRangeIcon from '@mui/icons-material/DateRange';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventIcon from '@mui/icons-material/Event';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { ReactComponent as MyFamigoSimpleLogo } from '../../assets/icon/myfamigoSimpleLogo.svg';
import { ReactComponent as NotificationIcon } from '../../assets/icon/notification.svg';
import { ReactComponent as currentUserIcon } from '../../assets/icon/currentUser.svg';
import BusinessIcon from '@mui/icons-material/Business';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	backgroundColor: theme.palette.background.navigationDrawer,
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	backgroundColor: theme.palette.background.navigationDrawer,
	width: 88,
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingTop: 30,
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: 'auto',
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	zIndex: 5,
	borderRight: 0,
	'& .MuiPaper-root': {
		borderWidth: 0,
		boxShadow: '4px 0px 3px rgba(0, 0, 0, 0.05)',
	},
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const FloatingActionButtonWrapper = styled('div')({
	position: 'fixed',
	top: 23,
	right: 40,
});

const StyledUserIcon = styled(currentUserIcon)({
	marginLeft: 11,
});

const menuItems = [
	{
		label: 'System User',
		icon: <PersonIcon />,
		path: '/sys-user',
	},
	{
		label: 'Supplier',
		icon: <PeopleIcon />,
		path: '/supplier',
	},
	{
		label: 'Property',
		icon: <BusinessIcon />,
		path: '/property',
	},
	{
		label: 'Place',
		icon: <PlaceIcon />,
		path: '/places',
	},
	{
		label: 'blog',
		icon: <PostAddIcon />,
		path: '/blog',
	},
	{
		label: 'abks',
		icon: <EventIcon />,
		path: '/abks',
	},
	{
		label: 'promotion',
		icon: <DiscountIcon />,
		path: '/promotion',
	},
	{
		label: 'page',
		icon: <AddHomeIcon />,
		path: '/page',
	},
	{
		label: 'bks',
		icon: <DateRangeIcon />,
		path: '/bks',
	},
	// {
	// 	label: 'Hotel Booking',
	// 	icon: <InboxIcon />,
	// 	path: '/booking',
	// },
	// {
	// 	label: 'Test Form',
	// 	icon: <DynamicFormIcon />,
	// 	path: '/test-form',
	// },
	// {
	// 	label: 'Test Fullcalendar',
	// 	icon: <CalendarMonthIcon />,
	// 	path: '/test-fullcalendar',
	// },
];

export default function NavigationDrawer(props: any) {
	const { pathname } = useLocation();
	const [open, setOpen] = React.useState(false);

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer variant='permanent' open={open}>
				<DrawerHeader>
					<MyFamigoSimpleLogo />
				</DrawerHeader>
				<List>
					{process.env.REACT_APP_IS_TESTING_ENV && (
						<Box
							sx={{
								marginTop: '15px',
								padding: '5px',
								backgroundColor: '#ff545f',
								textAlign: 'center',
								fontSize: '16px',
								whiteSpace: 'pre-line',
							}}
						>
							TESTING {process.env.REACT_APP_VERSION}
						</Box>
					)}

					{process.env.REACT_APP_IS_PRE_PRODUCTION && (
						<Box
							sx={{
								marginTop: '15px',
								padding: '5px 0px',
								backgroundColor: '#fbd03d',
								textAlign: 'center',
								fontSize: '16px',
								whiteSpace: 'pre-line',
							}}
						>
							PRE-PROD {process.env.REACT_APP_VERSION}
						</Box>
					)}

					{menuItems.map((item) => (
						<ListItem
							component={Link}
							to={item.path}
							key={item.label}
							disablePadding
							sx={{
								display: 'block',
								pointerEvents: pathname == item.path ? 'none' : 'auto',
							}}
						>
							<ListItemButton
								sx={{
									minHeight: 80,
									justifyContent: 'center',
									// px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										// mr: open ? 3 : "auto",
										justifyContent: 'center',
										color: pathname.includes(item.path) ? '#4EA4D3' : '#BCBCBC',
									}}
								>
									{item.icon}
								</ListItemIcon>
								{/* <ListItemText primary={item.label} sx={{ display: open ? "block" : "none" }} /> */}
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>
			<FloatingActionButtonWrapper>
				<NotificationIcon />
				<StyledUserIcon />
			</FloatingActionButtonWrapper>
			<Box
				component='main'
				sx={{ minWidth: 1280 - 88, flexGrow: 1, p: '28px 40px' }}
			>
				<Outlet />
			</Box>
		</Box>
	);
}

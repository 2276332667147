import AppPasswordTextField from '../../common/components/form-inputs/AppPasswordTextField';
import { useTranslation } from 'react-i18next';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PASSWORD_PATTERN } from '../../common/constants/pattern';
import { Stack } from '@mui/system';
import CustomDialog from '../../common/components/CustomDialog';
import { Button } from '@mui/material';
import { useUpdateUserPasswordMutation } from '../../api/userApiSlice';
import { useEffect } from 'react';

export type FormValues = {
	newPassword: string;
	confirmPassword: string;
};

export const defaultValues: DefaultValues<FormValues> = {
	newPassword: '',
	confirmPassword: '',
};

export interface ChangePasswordDialogProps {
	userId: string;
	open: boolean;
	onClose: () => void;
}

export default function ChangePasswordDialog(props: ChangePasswordDialogProps) {
	const { t } = useTranslation();

	const { onClose, open, userId } = props;

	const handleClose = () => {
		onClose();
	};

	// form

	const formSchema = Yup.object().shape({
		newPassword: Yup.string()
			.required(t('errorMessage.required')!)
			.matches(PASSWORD_PATTERN, t('errorMessage.password')!),
		confirmPassword: Yup.string()
			.required(t('errorMessage.required')!)
			.oneOf([Yup.ref('newPassword')], t('errorMessage.password')!),
	});

	const { handleSubmit, control } = useForm<FormValues>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [updateUserPassword, { isSuccess }] = useUpdateUserPasswordMutation();

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		updateUserPassword({
			userId: userId,
			body: {
				newPassword: data.newPassword,
			},
		});
	};

	useEffect(() => {
		if (isSuccess) {
			handleClose();
		}
	}, [isSuccess]);

	return (
		<CustomDialog
			title={t('systemUser.ChangePassword')}
			onClose={handleClose}
			open={open}
			content={
				<form onSubmit={handleSubmit(onSubmit)}>
					<Stack spacing='24px'>
						<AppPasswordTextField
							name='newPassword'
							control={control}
							label={t('systemUser.NewPassword')}
							placeholder={t('common.TypeHere')!}
							helperText={t('systemUser.NewPasswordHelperText')}
						/>

						<AppPasswordTextField
							name='confirmPassword'
							control={control}
							label={t('systemUser.ConfirmPassword')}
							placeholder={t('common.TypeHere')!}
							showCopyButton
						/>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.SaveChanges')}
							</Button>
							<Button onClick={handleClose} variant='outlined' color='info'>
								{t('button.Cancel')}
							</Button>
						</Stack>
					</Stack>
				</form>
			}
		></CustomDialog>
	);
}

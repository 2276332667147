import { Box, Button, Stack, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PageHeader from '../../common/components/PageHeader';
import { ReactComponent as AddIcon } from '../../assets/icon/add.svg';
import defineAbilityFor from '../../casl/defineAbility';
import { useGetMeQuery } from '../../api/userApiSlice';
import AppDataGridHeaderWithSorting from '../../common/components/dataGrid/AppDataGridHeaderWithSorting';
import {
	GridColDef,
	GridColumnHeaderParams,
	GridRenderCellParams,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import CountryFlag from '../../common/components/form-data-display/CountryFlag';
import { ReactComponent as ViewDetailIcon } from '../../assets/icon/view-detail.svg';
import BlogPostSummaryFilterSection from './components/BlogPostSummaryFilterSection';
import {
	BlogPostFilter,
	BlogPostSummaryItem,
} from '../../api/DTO/blogPost.interface';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../utils/qsHelper';
import qs from 'qs';
import AppDataGrid, {
	initialPagination,
} from '../../common/components/dataGrid/AppDataGrid';
import { useGetFilteredBlogPostQuery } from '../../api/contentApiSlice';
import SmallerAppStatusButton from '../../common/components/SmallerAppStatusButton';
import { getBlogPostStatus } from '../../utils/contentHelper';
import { useAppDispatch } from '../../redux-hooks';
import { showModal } from '../../common/components/modal/modalSlice';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT, INVALID_DATE } from '../../utils/dateHelper';

interface BlogPostDataGrid {
	id: string;
	title: string;
	categoryCountryCode: string;
	categoryName: string;
	createdAt: string;
	createdBy: string;
	description: string;
	status: number;
}

export default function BlogPostSummaryPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const defaultBlogPostFilter: BlogPostFilter = {
		pagination: initialPagination,
		sortings: [],
		searchKey: undefined,
		categoryIds: undefined,
		userIds: undefined,
		createdAtFrom: undefined,
		createdAtTo: undefined,
		statuses: undefined,
	};

	const defaultValues: DefaultValues<BlogPostFilter> = defaultBlogPostFilter;

	// watch serach params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const transformedSearchParams: BlogPostFilter = {
		...defaultBlogPostFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'searchKey',
			'categoryIds',
			'userIds',
			'createdAtFrom',
			'createdAtTo',
			'statuses',
		]),
	};

	const { data: blogPostSummary, isSuccess: isGetBlogPostSummarySuccess } =
		useGetFilteredBlogPostQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
		});

	// watch search params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const methods = useForm<BlogPostFilter>({
		defaultValues,
	});

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const blogPostFilterFormResult = value as BlogPostFilter;

			if (
				blogPostFilterFormResult.createdAtFrom === INVALID_DATE ||
				blogPostFilterFormResult.createdAtTo === INVALID_DATE
			) {
				return;
			}

			// append to url to reload page
			setSearchParams(qs.stringify(blogPostFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: BlogPostSummaryItem[]) => {
		if (response == null) return;
		const newResponse: BlogPostDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.id,
				title: item.title,
				categoryCountryCode: item.categoryCountryCode,
				categoryName: item.categoryName,
				createdBy: item.createdBy,
				description: item.description,
				status: item.status,
				createdAt: dayjs(item.createdAt).format(DISPLAY_DATE_TIME_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const DataGridHeader = (params: GridColumnHeaderParams) => {
		return (
			<AppDataGridHeaderWithSorting
				headerName={params.colDef.headerName}
				sortingsPropertyName={params.colDef.field}
			/>
		);
	};

	const handleStatusChange = (
		event: React.MouseEvent<HTMLElement>,
		activated: boolean | null,
		params: GridRenderCellParams
	) => {
		if (activated == null) return;
		if (activated) {
			dispatch(
				showModal({
					modalType: 'ACTIVATE_BLOG',
					modalProps: {
						blogId: params.row.id,
						title: params.row.title,
					},
				})
			);
		} else {
			dispatch(
				showModal({
					modalType: 'DEACTIVATE_BLOG',
					modalProps: {
						blogId: params.row.id,
						title: params.row.title,
					},
				})
			);
		}
	};

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: t('common.Id'),
			width: 90, // 24(cell padding left) + 56(text fixed width) + 10(cell padding right)
			renderHeader: DataGridHeader,
		},
		{
			field: 'nameOg',
			headerName: t('blogPost.Title'),
			flex: 1,
			minWidth: 200,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
							fontSize: '12px',
						}}
						variant='regular'
					>
						{params.row.title}
					</Typography>
				</Box>
			),
		},
		{
			field: 'category',
			headerName: t('blogPost.Category'),
			width: 180, // 10(cell padding left) + 42(icon wdith) + 8(gap) + 110(text fixed width) + 10(cell padding right)
			renderCell: (params) => (
				<Stack direction={'row'} alignItems={'center'}>
					<CountryFlag
						iso2={params.row.categoryCountryCode}
						size='small'
						style={{ marginRight: '12px' }}
					/>
					<Typography
						variant='regular'
						sx={{
							fontSize: '12px',
							wordWrap: 'break-word',
							whiteSpace: 'normal',
						}}
					>
						{params.row.categoryName}
					</Typography>
				</Stack>
			),
		},
		{
			field: 'createdAt',
			headerName: t('blogPost.DateCreated'),
			width: 140, // 10(cell padding left) + 120(header width) + 10(cell padding right)
			renderHeader: DataGridHeader,
		},
		{
			field: 'createdBy',
			headerName: t('blogPost.Author'),
			flex: 1,
			minWidth: 120,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
							fontSize: '12px',
						}}
						variant='regular'
					>
						{params.row.createdBy}
					</Typography>
				</Box>
			),
		},
		{
			field: 'description',
			headerName: t('blogPost.ShortDescription'),
			flex: 1,
			minWidth: 170,
			renderCell: (params) => (
				<Typography
					variant='regular'
					sx={{
						fontSize: '12px',
						wordWrap: 'break-word',
						whiteSpace: 'normal',
					}}
				>
					{params.row.description ?? '-'}
				</Typography>
			),
		},
		{
			field: 'status',
			headerName: t('blogPost.BlogPostStatus'),
			width: 110, // 10(cell padding left) + 90(header width) + 10(cell padding right)
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<SmallerAppStatusButton
					initialStatusI18nLabel={
						getBlogPostStatus(params.row.status).i18nLabel
					}
					initialStatusValue={getBlogPostStatus(params.row.status).isOnline}
					enableI18nLabel={t('room.ratePlanSummary.Online')}
					disableI18nLabel={t('room.ratePlanSummary.Offline')}
					statusOnChange={(event, status) => {
						handleStatusChange(event, status, params);
					}}
				/>
			),
		},

		{
			field: 'edit',
			headerName: '',
			width: 68, // 10(cell padding left) + 34(icon button width) + 24(cell padding right)
			renderCell: (params) => (
				<Link
					to={`/blog/${params.id}`}
					state={{
						blogPostSummarySearchParams: qs.stringify(transformedSearchParams),
					}}
				>
					<IconButton color='primary'>
						<ViewDetailIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<PageHeader
				title={t('blogPost.BlogPostSummary')}
				actionJsx={
					<>
						{ability.can('create', 'Blog') && (
							<Button
								variant='contained'
								startIcon={<AddIcon />}
								onClick={() =>
									navigate(`/blog/add`, {
										state: {
											blogPostSummarySearchParams: qs.stringify(
												transformedSearchParams
											),
										},
									})
								}
							>
								{t('button.CreateBlogPost')}
							</Button>
						)}
					</>
				}
			/>
			<FormProvider {...methods}>
				<BlogPostSummaryFilterSection
					isInitializedForm={isInitializedForm}
					transformedSearchParams={transformedSearchParams}
				/>
				<Box marginTop={'28px'}>
					<AppDataGrid
						loading={!isGetBlogPostSummarySuccess}
						rows={transformResponse(blogPostSummary?.data.items) ?? []}
						columns={columns}
						rowCount={blogPostSummary?.data.pagination.totalItems ?? 0}
						getRowId={(row) => row.id}
					/>
				</Box>
			</FormProvider>
		</>
	);
}

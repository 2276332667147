import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

interface FullScreenLoaderState {
	display: boolean;
}

const initialState: FullScreenLoaderState = {
	display: false,
};

export const fullScreenLoaderSlice = createSlice({
	name: 'fullScreenLoader',
	initialState,
	reducers: {
		showFullScreenLoader: (state) => {
			state.display = true;
			return state;
		},
		hideFullScreenLoader: () => {
			return initialState;
		},
	},
});

export const { showFullScreenLoader, hideFullScreenLoader } =
	fullScreenLoaderSlice.actions;

export const selectFullScreenLoaderState = (state: RootState) =>
	state.fullScreenLoader;

export default fullScreenLoaderSlice;

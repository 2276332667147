import Option from '../../common/types/option.type';

export enum Facility {
	FREE = 0,
	PAID = 1,
	ENQUIRY = 2,
	BYO = 3,
	NO = '',
}

export const facilityOption: Option[] = [
	{
		id: Facility.NO,
		name: 'No',
		selectedbackgroundcolor: '#FFDDED',
		selectedcolor: 'red',
		icon: 'NoIcon',
	},
	{
		id: Facility.FREE,
		name: 'Free',
		selectedbackgroundcolor: '#CDEAC3',
		selectedcolor: '#79B765',
		icon: 'FreeIcon',
	},
	{
		id: Facility.BYO,
		name: 'BYO',
		selectedbackgroundcolor: '#FFDDED',
		selectedcolor: '#FF82BE',
		icon: 'BYOIcon',
	},
	{
		id: Facility.ENQUIRY,
		name: 'Enquiry',
		selectedbackgroundcolor: '#F2FAFF',
		selectedcolor: '#4EA4D3',
		icon: 'EnquiryIcon',
	},
	{
		id: Facility.PAID,
		name: 'Paid',
		selectedbackgroundcolor: '#F9ECC2',
		selectedcolor: '#E9BD2A',
		icon: 'PaidIcon',
	},
];

import { Breadcrumbs, BreadcrumbsProps, Typography } from '@mui/material';

export default function AppBreadcrumbs(
	props: React.PropsWithChildren<BreadcrumbsProps>
) {
	const { children } = props;

	return (
		<Breadcrumbs
			separator={<Typography color='#C5C5C5'>{'>'}</Typography>}
			sx={{ paddingX: '20px', paddingBottom: '15px' }}
			aria-label='breadcrumb'
		>
			{children}
		</Breadcrumbs>
	);
}

import { Grid, Stack } from '@mui/material';
import KidsSupplementCard from './KidsSupplementCard';
import { useState } from 'react';
import { FormCardType } from '../../../common/components/card/FormCard';

export default function ViewKidsSupplementPage() {
	const [type, setType] = useState<FormCardType>(FormCardType.view);

	return (
		<Stack spacing='16px'>
			<Grid container alignItems='stretch' spacing={'10px'}>
				<Grid item xs={12} display='flex'>
					<KidsSupplementCard type={type} setType={setType} />
				</Grid>
			</Grid>
		</Stack>
	);
}

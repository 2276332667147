import { Box, styled, Divider } from '@mui/material';
import AppSpaceSubtitleLayout from '../../../common/components/form-layout/AppSpaceSubtitleLayout';
import { useTranslation } from 'react-i18next';
import SpaceDisplayText from '../../../common/components/form-data-display/SpaceDisplayText';

interface ProfitTotalBreakdownCardProps {
	data?: any;
	currency: string;
	label: string;
}

export default function ProfitTotalBreakdownCard({
	data,
	currency,
	label,
}: ProfitTotalBreakdownCardProps) {
	const { t } = useTranslation();
	return (
		<>
			{data !== undefined && (
				<StyledBreakdownCard>
					<AppSpaceSubtitleLayout
						label={label}
						content={`${currency} ${data.total}`}
					/>
					<Box
						sx={{
							display: 'flex',
							gap: '5px',
							flexDirection: 'column',
							marginBottom: '15px',
						}}
					>
						{data.items.map((dataItem: any, dataIndex: any) => {
							return (
								<SpaceDisplayText
									key={dataIndex}
									text1={dataItem.name}
									text2={`(${currency} ${dataItem.rate.toString()})`}
								/>
							);
						})}
					</Box>
					<Divider sx={{ marginBottom: '15px' }} />

					<AppSpaceSubtitleLayout
						label={`${t('abk.NetProfit')}`}
						content={`${currency} ${data.netProfit?.toString()}`}
						color={'#4EA4D3'}
					/>
					<AppSpaceSubtitleLayout
						label={`${t('abk.Profit')} %`}
						content={
							data.profitPercentage?.toString()
								? `${data.profitPercentage?.toString()}%`
								: '-'
						}
						color={'#4EA4D3'}
					/>
				</StyledBreakdownCard>
			)}
		</>
	);
}

const StyledBreakdownCard = styled(Box)({
	backgroundColor: '#F2FAFF',
	padding: '20px ',
	paddingBottom: '0',
	borderRadius: '10px',
	marginBottom: '20px',
});

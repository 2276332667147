import Option from '../../common/types/option.type';

export enum TransactionType {
	CASH_ON_ARRIVAL = 0,
	CASH_ON_DELIVERY = 1,
}

export const transactionTypeOption: Option[] = [
	{
		id: TransactionType.CASH_ON_ARRIVAL,
		name: 'Cash On Arrival',
	},
	{
		id: TransactionType.CASH_ON_DELIVERY,
		name: 'Cash On Delivery',
	},
];

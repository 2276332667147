import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Box, CheckboxProps } from '@mui/material';
import { ReactNode } from 'react';
import AppInputLayout from '../form-layout/AppInputLayout';
import AppCheckbox from './AppCheckbox';

interface AppAppSingleCheckboxProps {
	label: string;
	infoPopUp?: ReactNode;
	disabled?: boolean;
}

export default function AppSingleCheckbox<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	infoPopUp,
	required,
	disabled,
	...props
}: AppAppSingleCheckboxProps &
	UseControllerProps<TFieldValues, TName> &
	CheckboxProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<AppInputLayout
			label={label}
			required={required}
			infoPopUp={infoPopUp}
			errorMessage={errorMessage}
		>
			<Box>
				<AppCheckbox
					{...field}
					value={field.value ?? ''}
					checked={field.value ?? false}
					sx={{ padding: 0 }}
					disabled={disabled}
					ref={null}
				/>
			</Box>
		</AppInputLayout>
	);
}

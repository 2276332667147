import Option from '../../common/types/option.type';

export enum BillingCycle {
	BY_BOOKING = 0,
	BY_DEPOSIT = 1,
	MONTHLY = 2,
	BIWEEKLY = 3,
	WEEKLY = 4,

}

export const billingCycleOption: Option[] = [
	{
		id: BillingCycle.BY_BOOKING,
		name: 'By Booking',
	},
	{
		id: BillingCycle.BY_DEPOSIT,
		name: 'By Deposit',
	},
    {
		id: BillingCycle.MONTHLY,
		name: 'Mothly Billing',
	},
    {
		id: BillingCycle.BIWEEKLY,
		name: 'Bi-weekly Billing',
	},
    {
		id: BillingCycle.WEEKLY,
		name: 'Weekly Billing',
	},
];

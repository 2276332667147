import { Box, CircularProgress, Typography } from '@mui/material';
import { ApiSupplierType } from '../../../api/enum/ApiSupplier.enum';
import { ReactComponent as RakutenIcon } from '../../../assets/icon/Rakuten.svg';
import { ReactComponent as MeituanIcon } from '../../../assets/icon/Meituan.svg';
import { useGetApiRoomRatePercentageQuery } from '../../../api/apiServiceApiSlice';
import { useTranslation } from 'react-i18next';

interface ApiRoomLabelDisplayProps {
	apiSupplier: ApiSupplierType;
	label?: string;
	apiRoomId: string;
}

export default function ApiRoomLabelDisplay({
	apiSupplier,
	label,
	apiRoomId,
}: ApiRoomLabelDisplayProps) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<>
				{apiSupplier === ApiSupplierType.RAKUTEN && (
					<RakutenIcon style={{ minWidth: '20px', marginRight: '10px' }} />
				)}
				{apiSupplier === ApiSupplierType.MEITUAN && (
					<MeituanIcon style={{ minWidth: '20px', marginRight: '10px' }} />
				)}
				<Box>
					<Typography fontSize='12px'>{label}</Typography>
					<ApiRoomReturnRate apiSupplier={apiSupplier} apiRoomId={apiRoomId} />
				</Box>
			</>
		</Box>
	);
}

export function ApiRoomReturnRate({
	apiSupplier,
	apiRoomId,
}: {
	apiSupplier: ApiSupplierType;
	apiRoomId: string;
}) {
	const { t } = useTranslation();

	const { data: getApiRoomRatePercentageResponse, isLoading } =
		useGetApiRoomRatePercentageQuery({
			apiSupplier: apiSupplier,
			body: { rooms: [apiRoomId] },
		});
	return (
		<Typography fontSize='12px' color='#808080'>
			{t('common.ReturnRate')}
			{': '}
			{isLoading ? (
				<CircularProgress size='12px' sx={{ marginLeft: '5px' }} />
			) : (
				<>{`${
					getApiRoomRatePercentageResponse?.data.find(
						(room) => room.roomCode == apiRoomId
					)?.returnRate
				}%`}</>
			)}
		</Typography>
	);
}

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Stack } from '@mui/material';
import { useEffect } from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
	TangleFilter,
	TanglePeek,
} from '../../../../api/DTO/propertyImport.interface';
import { useAddTanglePeekMutation } from '../../../../api/scraperApiSlice';
import AppTextField, {
	InputType,
} from '../../../../common/components/form-inputs/AppTextField';
import { COORDINATES_PATTERN } from '../../../../common/constants/pattern';

interface SearchPropertyFormProps {
	setSearchResult: (result: TanglePeek | undefined) => void;
	searchResult?: TanglePeek;
}

export default function SearchPropertyForm({
	setSearchResult,
	searchResult,
}: SearchPropertyFormProps) {
	const { t } = useTranslation();

	const defaultValues: DefaultValues<TangleFilter> = {};

	const formSchema = Yup.object().shape({
		coordinates: Yup.lazy((value) =>
			typeof value === 'object'
				? Yup.array().of(Yup.number().required(t('errorMessage.required')))
				: Yup.string()
						.required(t('errorMessage.required'))
						.matches(
							COORDINATES_PATTERN,
							t('errorMessage.incorrectCoordinatesFormat')
						)
		),
	});

	const { handleSubmit, control, reset } = useForm<TangleFilter>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const [
		addTanglePeek,
		{ data: addTanglePeekResponse, isSuccess: isAddTanglePeekSuccess },
	] = useAddTanglePeekMutation();

	useEffect(() => {
		if (isAddTanglePeekSuccess) {
			setSearchResult(addTanglePeekResponse?.data);
		}
	}, [isAddTanglePeekSuccess]);

	const onSubmit: SubmitHandler<TangleFilter> = (data: any) => {
		if (typeof data.coordinates === 'string') {
			const coordinatesInString = data.coordinates.toString();
			if (coordinatesInString) {
				data.coordinates = [
					parseFloat(coordinatesInString.split(/,(.*)/s)[0]),
					parseFloat(coordinatesInString.split(/,(.*)/s)[1]),
				];
			}
		}
		if (data.propertyName === '') {
			data.propertyName = undefined;
		}
		if (data.radius === '') {
			data.radius = undefined;
		}

		addTanglePeek(data);
	};
	const formContent = (
		<Stack>
			<AppTextField
				control={control}
				name='coordinates'
				label={t('property.LocationCoordinates')}
				placeholder='0.000000000000000, 0.00000000000000'
				required
			/>

			<AppTextField
				control={control}
				name='propertyName'
				inputType={InputType.english}
				label={t('property.PropertyName')}
				placeholder={t('placeholder.pleaseEnterEnglish')}
			/>

			<AppTextField
				control={control}
				name='radius'
				inputType={InputType.number}
				label={t('property.propertyImport.Radius(meter)')}
			/>

			<Stack spacing='16px'>
				<Grid item xs={12}>
					<Stack direction='row-reverse' spacing='12px'>
						<Button
							variant='contained'
							color='success'
							onClick={handleSubmit(onSubmit)}
						>
							{t('button.Search')}
						</Button>
						{searchResult && (
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									reset();
									setSearchResult(undefined);
								}}
							>
								{t('button.Discard')}
							</Button>
						)}
					</Stack>
				</Grid>
			</Stack>
		</Stack>
	);

	const form = <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;

	return <>{form}</>;
}

export enum DailyRatePlanCondition {
	AVAILABLE = 0,
	CLOSED_OUT = 1,
	ON_REQUEST = 2,
}

export function getRatePlanConditionTextColor(
	condition: DailyRatePlanCondition
): string {
	switch (condition) {
		case DailyRatePlanCondition.AVAILABLE:
			return '#4B9831';
		case DailyRatePlanCondition.CLOSED_OUT:
			return '#DA5150';
		case DailyRatePlanCondition.ON_REQUEST:
			return '#BA8B5D';
		default:
			return '';
	}
}

export function getRatePlanConditionText(
	condition: DailyRatePlanCondition
): string {
	switch (condition) {
		case DailyRatePlanCondition.AVAILABLE:
			return 'Available';
		case DailyRatePlanCondition.CLOSED_OUT:
			return 'Closed Out';
		case DailyRatePlanCondition.ON_REQUEST:
			return 'On Request';
		default:
			return '';
	}
}

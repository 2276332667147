import { Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { DefaultValues, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppOTPField from '../../common/components/form-inputs/AppOTPField';
import React, { useEffect } from 'react';
import { VERIFICATION_CODE_PATTERN } from '../../common/constants/pattern';
import AuthContainer from './components/AuthContainer';
import {
	useResendEmailMutation,
	useVerifyCodeMutation,
} from '../../api/authApiSlice';
import { useSelector } from 'react-redux';
import { selectResetSessionId } from './authSlice';
import { VerifyCodeRequest } from '../../api/request/verifyCode.request';
import { useAppDispatch } from '../../redux-hooks';
import {
	SnackbarType,
	showSnackbar,
} from '../../common/components/snackbar/snackbarSlice';

export const defaultValues: DefaultValues<VerifyCodeRequest> = {
	code: '',
};

export default function EmailVerifyPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const formSchema = Yup.object().shape({
		code: Yup.string()
			.required(t('errorMessage.required'))
			.matches(VERIFICATION_CODE_PATTERN),
	});

	const {
		control,
		watch,
		formState,
		formState: { isValidating },
	} = useForm<VerifyCodeRequest>({
		defaultValues,
		mode: 'onChange',
		resolver: yupResolver(formSchema),
	});

	// verify code

	const [verifyCode, { isSuccess: isVerifyCodeSuccess }] =
		useVerifyCodeMutation();

	const data = watch();
	const [tempCode, setTempCode] = React.useState('');

	React.useEffect(() => {
		if (formState.isValid && !isValidating && tempCode != data.code) {
			setTempCode(data.code);
			if (resetSessionId) verifyCode({ id: resetSessionId, body: data });
			else console.log('missing reset sessionId');
		}

		if (data.code.length < 6) setTempCode('');
	}, [formState, data, isValidating]);

	useEffect(() => {
		if (isVerifyCodeSuccess) {
			navigate('/forgot-password/reset');
		}
	}, [isVerifyCodeSuccess]);

	// resend count down
	const [counter, setCounter] = React.useState(30);

	React.useEffect(() => {
		counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
	}, [counter]);

	const [resendEmail, { isSuccess: isResendEmailSuccess }] =
		useResendEmailMutation();

	const resetSessionId = useSelector(selectResetSessionId);

	function resendCode() {
		if (resetSessionId) resendEmail(resetSessionId);
		else console.log('missing reset sessionId');
	}

	useEffect(() => {
		if (isResendEmailSuccess) {
			setCounter(30); // reset count down

			dispatch(
				showSnackbar({
					snackbarType: SnackbarType.MESSAGE,
					snackbarProps: {
						anchorOrigin: { vertical: 'top', horizontal: 'center' },
						message: t('forgotPassword.ResendEmailSuccessMessage'),
					},
				})
			);
		}
	}, [isResendEmailSuccess]);

	return (
		<AuthContainer
			title={t('forgotPassword.VerifyTitle')}
			message={t('forgotPassword.VerifyMesaage')}
		>
			<form>
				<Stack
					width='382px'
					alignItems='center'
					spacing='24px'
					marginTop='40px'
				>
					<AppOTPField name='code' control={control} valueLength={6} />
				</Stack>
			</form>

			<Typography fontSize='14px' color='#808080' marginTop='40px'>
				{t('forgotPassword.CantReceiveTheCode')}

				{counter === 0 && (
					<Typography
						component='span'
						onClick={resendCode}
						sx={{ textDecoration: 'underline', cursor: 'pointer' }}
						fontSize='14px'
						variant='bold'
						color='#4EA4D3'
					>
						{t('button.SendAgain')}
					</Typography>
				)}

				{counter > 0 && (
					<Typography fontSize='14px' variant='bold' color='#BCBCBC'>
						Send again ({counter} sec)
					</Typography>
				)}
			</Typography>

			<Typography
				component={Link}
				to='/login'
				sx={{ textDecoration: 'underline', cursor: 'pointer' }}
				fontSize='14px'
				variant='bold'
				color='#808080'
				marginTop='169px'
			>
				{t('button.BackToSignIn')}
			</Typography>
		</AuthContainer>
	);
}

import { Grid, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import FullScreenLoader from '../../../common/components/FullScreenLoader';

import { createContext } from 'react';
import { useGetPromotionQuery } from '../../../api/promotionApiSlice';
import { FormCardType } from '../../../common/components/card/FormCard';
import PromotionCard from './promotion/PromotionCard';

export const PromotionIdContext = createContext('');

export default function ViewPromotionPage() {
	const { promotionId } = useParams();

	const {
		data: getPromotionResponse,
		isLoading: isGetPromotionLoading,
		isSuccess: isGetPromotionSuccess,
		isError: isGetPromotionError,
	} = useGetPromotionQuery(promotionId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	let pageContent;
	if (isGetPromotionLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetPromotionSuccess) {
		pageContent = (
			<>
				<PromotionIdContext.Provider value={promotionId ?? ''}>
					<Stack spacing='16px'>
						<Grid container alignItems='stretch' spacing={'10px'}>
							<Grid item xs={12} display='flex'>
								<PromotionCard initialType={FormCardType.view} />
							</Grid>
						</Grid>
					</Stack>
				</PromotionIdContext.Provider>
			</>
		);
	} else if (isGetPromotionError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}

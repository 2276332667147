import Option from '../../common/types/option.type';

export enum AbkCusAndSupType {
	CUSTOMER = 0,
	SUPPLIER = 1,
}

export const CusAndSupTypeOptions: Option[] = [
	{
		id: AbkCusAndSupType.CUSTOMER,
		name: 'Customer',
	},
	{
		id: AbkCusAndSupType.SUPPLIER,
		name: 'Supplier',
	},
];

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormCardType } from '../../../common/components/card/FormCard';
import { useTranslation } from 'react-i18next';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
	useGetPropertyQuery,
	useLazyGetPropertyPerPersonConfigQuery,
	useUpdatePropertyPerPersonConfigMutation,
} from '../../../api/accommodationApiSlice';
import AppDropDownMenu from '../../../common/components/form-inputs/AppDropDownMenu';
import { useParams } from 'react-router-dom';

import { Box, Button, Grid, InputAdornment, Stack } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PriceMode } from '../../../api/enum/priceMode.enum';
import AppSubtitleLayout from '../../../common/components/form-layout/AppSubtitleLayout';
import {
	ChildPriceType,
	childPriceTypeOptions,
} from '../../../api/enum/childPriceType.enum';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import AppTextField, {
	InputType,
} from '../../../common/components/form-inputs/AppTextField';
import { PropertyPerPersonConfig } from '../../../api/DTO/property.interface';
import { RateModel, rateModelList } from '../../../api/enum/rateModel.enum';
import RateModelToggleButton from '../../../common/components/RateModelToggleButton';
import {
	MarkupType,
	markupTypeOptions,
} from '../../../api/enum/markupType.enum';

interface KidsSupplementFormProps {
	type: FormCardType;
	setType: Dispatch<SetStateAction<FormCardType>>;
}

export default function KidsSupplementForm({
	type,
	setType,
}: KidsSupplementFormProps) {
	const { t } = useTranslation();
	const { propertyId } = useParams();

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	const [rateModel, setRateModel] = useState<RateModel>(
		getPropertyResponse?.data?.supplier.rateModels?.includes(RateModel.AGENCY)
			? RateModel.AGENCY
			: getPropertyResponse?.data?.supplier.rateModels?.[0] ?? RateModel.AGENCY
	);

	const [propertyPerPersonConfig, setPropertyPerPersonConfig] = useState<
		PropertyPerPersonConfig | undefined
	>(undefined);

	const [getPropertyPerPersonConfig] = useLazyGetPropertyPerPersonConfigQuery();

	useEffect(() => {
		const fetchData = async () => {
			const response = await getPropertyPerPersonConfig({
				propertyId: propertyId ?? '',
				rateModel: rateModel,
			});
			setPropertyPerPersonConfig(response.data?.data);
			reset(response.data?.data);
		};
		fetchData();
	}, [rateModel]);

	const [
		updatePropertyPerPersonConfig,
		{ isSuccess: isUpdatePropertyPerPersonConfigSuccess },
	] = useUpdatePropertyPerPersonConfigMutation();

	const formSchema = Yup.object().shape({
		infantPriceConfig: Yup.object()
			.nullable()
			.when([], {
				is: () => getPropertyResponse?.data.policy.allowInfant,
				then: (schema) =>
					schema.shape({
						priceType: Yup.number().required(t('errorMessage.pleaseSelect')),
						amount: Yup.string()
							.nullable()
							.when('priceType', {
								is: ChildPriceType.PERCENTAGE,
								then: (schema) => schema.required(t('errorMessage.required')),
							})
							.when('priceType', {
								is: ChildPriceType.FIXED,
								then: (schema) => schema.required(t('errorMessage.required')),
							}),
						markup: Yup.object()
							.nullable()
							.when(['priceType'], {
								is: (priceType: ChildPriceType) =>
									rateModel == RateModel.AGENCY &&
									priceType == ChildPriceType.FIXED &&
									!propertyPerPersonConfig?.sellAtOfficialRate,
								then: (schema) =>
									schema.shape({
										amount: Yup.string().required(t('errorMessage.required')),
										type: Yup.number()
											.nullable()
											.required(t('errorMessage.required')),
									}),
							}),
					}),
			}),
		toddlerPriceConfig: Yup.object()
			.nullable()
			.when([], {
				is: () => getPropertyResponse?.data.policy.allowToddler,
				then: (schema) =>
					schema.shape({
						priceType: Yup.number().required(t('errorMessage.pleaseSelect')),
						amount: Yup.string()
							.nullable()
							.when('priceType', {
								is: ChildPriceType.PERCENTAGE,
								then: (schema) => schema.required(t('errorMessage.required')),
							})
							.when('priceType', {
								is: ChildPriceType.FIXED,
								then: (schema) => schema.required(t('errorMessage.required')),
							}),
						markup: Yup.object()
							.nullable()
							.when(['priceType'], {
								is: (priceType: ChildPriceType) =>
									rateModel == RateModel.AGENCY &&
									priceType == ChildPriceType.FIXED &&
									!propertyPerPersonConfig?.sellAtOfficialRate,
								then: (schema) =>
									schema.shape({
										amount: Yup.string().required(t('errorMessage.required')),
										type: Yup.number()
											.nullable()
											.required(t('errorMessage.required')),
									}),
							}),
					}),
			}),
		childPriceConfig: Yup.object()
			.nullable()
			.when([], {
				is: () => getPropertyResponse?.data.policy.allowChildren,
				then: (schema) =>
					schema.shape({
						priceType: Yup.number().required(t('errorMessage.pleaseSelect')),
						amount: Yup.string()
							.nullable()
							.when('priceType', {
								is: ChildPriceType.PERCENTAGE,
								then: (schema) => schema.required(t('errorMessage.required')),
							})
							.when('priceType', {
								is: ChildPriceType.FIXED,
								then: (schema) => schema.required(t('errorMessage.required')),
							}),
						markup: Yup.object()
							.nullable()
							.when(['priceType'], {
								is: (priceType: ChildPriceType) =>
									rateModel == RateModel.AGENCY &&
									priceType == ChildPriceType.FIXED &&
									!propertyPerPersonConfig?.sellAtOfficialRate,
								then: (schema) =>
									schema.shape({
										amount: Yup.string().required(t('errorMessage.required')),
										type: Yup.number()
											.nullable()
											.required(t('errorMessage.required')),
									}),
							}),
					}),
			}),
	});

	const { handleSubmit, control, watch, setValue, reset } =
		useForm<PropertyPerPersonConfig>({
			resolver: yupResolver(formSchema),
		});

	useEffect(() => {
		if (isUpdatePropertyPerPersonConfigSuccess) setType(FormCardType.view);
	}, [isUpdatePropertyPerPersonConfigSuccess]);

	// per person child config logic

	const watchInfantPriceType = watch('infantPriceConfig.priceType');
	const watchToddlerPriceType = watch('toddlerPriceConfig.priceType');
	const watchChildrPriceType = watch('childPriceConfig.priceType');

	// clear amount if price type does not require amount

	useEffect(() => {
		if (
			getPropertyResponse?.data.supplier.priceMode === PriceMode.PER_PERSON &&
			getPropertyResponse?.data.policy.allowInfant &&
			watchInfantPriceType !== ChildPriceType.PERCENTAGE &&
			watchInfantPriceType !== ChildPriceType.FIXED
		) {
			setValue('infantPriceConfig.amount', undefined, {
				shouldValidate: true,
			});
		}
	}, [watchInfantPriceType]);

	useEffect(() => {
		if (
			getPropertyResponse?.data.supplier.priceMode === PriceMode.PER_PERSON &&
			getPropertyResponse?.data.policy.allowToddler &&
			watchToddlerPriceType !== ChildPriceType.PERCENTAGE &&
			watchToddlerPriceType !== ChildPriceType.FIXED
		) {
			setValue('toddlerPriceConfig.amount', undefined, {
				shouldValidate: true,
			});
		}
	}, [watchToddlerPriceType]);

	useEffect(() => {
		if (
			getPropertyResponse?.data.supplier.priceMode === PriceMode.PER_PERSON &&
			getPropertyResponse?.data.policy.allowChildren &&
			watchChildrPriceType !== ChildPriceType.PERCENTAGE &&
			watchChildrPriceType !== ChildPriceType.FIXED
		) {
			setValue('childPriceConfig.amount', undefined, {
				shouldValidate: true,
			});
		}
	}, [watchChildrPriceType]);

	// markup logic

	useEffect(() => {
		if (
			!(
				rateModel == RateModel.AGENCY &&
				watch('infantPriceConfig.priceType') == ChildPriceType.FIXED &&
				!propertyPerPersonConfig?.sellAtOfficialRate
			)
		) {
			setValue('infantPriceConfig.markup', null);
		}
	}, [
		rateModel,
		watch('infantPriceConfig.priceType'),
		propertyPerPersonConfig?.sellAtOfficialRate,
	]);

	// submit

	const onSubmit: SubmitHandler<FieldValues> = (data) => {
		updatePropertyPerPersonConfig({
			propertyId: propertyId ?? '',
			rateModel: rateModel,
			body: data,
		});
	};

	const availableConfigs: {
		name: 'infantPriceConfig' | 'toddlerPriceConfig' | 'childPriceConfig';
		label: string;
		isAllowed?: boolean;
	}[] = [
		{
			name: 'infantPriceConfig',
			label: t('common.Infants'),
			isAllowed: getPropertyResponse?.data.policy.allowInfant,
		},
		{
			name: 'toddlerPriceConfig',
			label: t('common.Toddlers'),
			isAllowed: getPropertyResponse?.data.policy.allowToddler,
		},
		{
			name: 'childPriceConfig',
			label: t('common.Children'),
			isAllowed: getPropertyResponse?.data.policy.allowChildren,
		},
	];

	const formContent = (
		<>
			{getPropertyResponse?.data.supplier.priceMode ===
				PriceMode.PER_PERSON && (
				<>
					<Box display={'flex'} justifyContent={'end'}>
						<RateModelToggleButton
							value={rateModel}
							handleChange={(_, newRateModel) => {
								if (newRateModel != null) setRateModel(newRateModel);
							}}
							disableRateModel={rateModelList.filter(
								(rateModel) =>
									!getPropertyResponse?.data.supplier.rateModels?.includes(
										rateModel
									)
							)}
						/>
					</Box>

					{availableConfigs.map(({ name, label, isAllowed }) => (
						<div key={name}>
							{isAllowed && (
								<>
									<AppSubtitleLayout label={label} />

									<AppInputLayout
										label={t('room.ratePlan.PriceType')}
										removeErrorPadding
										required
									>
										<Grid container columnSpacing={2}>
											<Grid item xs={8}>
												<AppTextField
													control={control}
													label={''}
													name={`${name}.amount`}
													inputType={InputType.amount}
													disabled={
														watch(`${name}.priceType`) !==
															ChildPriceType.PERCENTAGE &&
														watch(`${name}.priceType`) !== ChildPriceType.FIXED
													}
													InputProps={
														watch(`${name}.priceType`) === ChildPriceType.FIXED
															? {
																	startAdornment: (
																		<InputAdornment position='start'>
																			{propertyPerPersonConfig?.currency}
																		</InputAdornment>
																	),
															  }
															: {}
													}
												/>
											</Grid>
											<Grid item xs={4}>
												<AppDropDownMenu
													control={control}
													label={''}
													name={`${name}.priceType`}
													options={childPriceTypeOptions}
													required
												/>
											</Grid>
										</Grid>
									</AppInputLayout>

									{rateModel == RateModel.AGENCY &&
										watch(`${name}.priceType`) == ChildPriceType.FIXED &&
										!propertyPerPersonConfig?.sellAtOfficialRate && (
											<AppInputLayout
												label={t('common.Markup')}
												required
												removeErrorPadding
											>
												<Grid container columnSpacing={2}>
													<Grid item xs={8}>
														<AppTextField
															control={control}
															label={''}
															name={`${name}.markup.amount`}
															inputType={InputType.amount}
															InputProps={
																watch(`${name}.markup.type`) ===
																MarkupType.FIXED
																	? {
																			startAdornment: (
																				<InputAdornment position='start'>
																					{propertyPerPersonConfig?.currency}
																				</InputAdornment>
																			),
																	  }
																	: {}
															}
														/>
													</Grid>
													<Grid item xs={4}>
														<AppDropDownMenu
															control={control}
															label={''}
															name={`${name}.markup.type`}
															options={markupTypeOptions}
															required
														/>
													</Grid>
												</Grid>
											</AppInputLayout>
										)}
								</>
							)}
						</div>
					))}
				</>
			)}
			{type === FormCardType.edit && (
				<Stack spacing='16px'>
					<Grid item xs={12}>
						<Stack direction='row-reverse' spacing='12px'>
							<Button type='submit' variant='contained' color='success'>
								{t('button.Save')}
							</Button>
							<Button
								variant='outlined'
								color='info'
								onClick={() => {
									setType(FormCardType.view);
								}}
							>
								{t('button.Discard')}
							</Button>
						</Stack>
					</Grid>
				</Stack>
			)}
		</>
	);

	const form =
		type === FormCardType.edit
			? (() => {
					return <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;
			  })()
			: formContent;

	return <>{form}</>;
}

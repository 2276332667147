import { Grid, Stack } from '@mui/material';
import AutoFaqCard from './autoFaq/AutoFaqCard';
import CustomFaqCard from './customFaq/CustomFaqCard';
import { FormCardType } from '../../../common/components/card/FormCard';

export default function ViewFaqPage() {
	return (
		<Grid container spacing={'20px'}>
			<Grid item style={{ width: '100%' }}>
				<Stack width={'100%'} spacing={'15px'}>
					<AutoFaqCard />
					<CustomFaqCard initialType={FormCardType.view} />
				</Stack>
			</Grid>
		</Grid>
	);
}

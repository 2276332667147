export enum AbkCustomerEmail {
	INVOICE = 0,
	ACKNOWLEDGEMENT_AMENDMENT = 1,
	AMENDED_INVOICE = 2,
	CONFIRMATION = 3,
	RECEIPT = 4,
	ACKNOWLEDGEMENT_REFUND = 5,
	CANCELLATION = 6,
	REFUND_RECEIPT = 7,
}

export function getAbkCustomerEmailDisplay(status: number) {
	switch (status) {
		case AbkCustomerEmail.INVOICE:
			return {
				name: 'Invoice',
			};
		case AbkCustomerEmail.ACKNOWLEDGEMENT_AMENDMENT:
			return {
				name: 'Amendment Acknowledgement',
			};
		case AbkCustomerEmail.AMENDED_INVOICE:
			return {
				name: 'Amended Invoice',
			};
		case AbkCustomerEmail.CONFIRMATION:
			return {
				name: 'Confirmation',
			};
		case AbkCustomerEmail.RECEIPT:
			return {
				name: 'Receipt',
			};
		case AbkCustomerEmail.ACKNOWLEDGEMENT_REFUND:
			return {
				name: 'Refund Acknowledgement',
			};
		case AbkCustomerEmail.CANCELLATION:
			return {
				name: 'Cancellation',
			};
		case AbkCustomerEmail.REFUND_RECEIPT:
			return {
				name: 'Refund Receipt',
			};
		default:
			return {
				name: '-',
			};
	}
}

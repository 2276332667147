import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	List,
	ListItem,
	Stack,
	Tooltip,
	Typography,
	styled,
} from '@mui/material';
import { RoomCalendar } from '../../../../api/DTO/propertyCalendar.interface';
import { ReactComponent as InvertedTriangleWhite } from '../../../../assets/icon/inverted-triangle-white.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import { ReactComponent as CircleInfoIcon } from '../../../../assets/icon/circleInfo.svg';
import { RateModel } from '../../../../api/enum/rateModel.enum';
import { RefObject } from 'react';
import { PriceMode } from '../../../../api/enum/priceMode.enum';
import { ReactComponent as ViewDetailIcon } from '../../../../assets/icon/view-detail.svg';
import { Link } from 'react-router-dom';
import { RoomClass } from '../../../../api/enum/roomClass.enum';
import RoomTypeLabel from './RoomTypeLabel';
import OnlineSwitchButton from './OnlineSwitchButton';
import { RoomRow, getRoomRows } from './RoomRow.enum';
import { RoomStatus } from '../../../../api/enum/roomStatus.enum';
import { useUpdateRoomStatusMutation } from '../../../../api/accommodationApiSlice';
import { AutoSizer, Grid } from 'react-virtualized';
import RoomGrid from './RoomGrid';

interface RoomAccordionProps {
	room: RoomCalendar;
	roomIndex: number;
	basicRoomId?: string;
	propertyId: string;
	priceMode: PriceMode;
	minPriceOnClick: () => void;
	roomSupplementRateOnClick: () => void;
	dateCalendarRef: RefObject<HTMLDivElement>;
	roomGrid: (Grid | null)[];
	ratePlanGrid: (Grid | null)[][];
	rateModel: RateModel;
}

export default function RoomAccordion({
	room,
	roomIndex,
	basicRoomId,
	propertyId,
	priceMode,
	minPriceOnClick,
	roomSupplementRateOnClick,
	dateCalendarRef,
	roomGrid,
	ratePlanGrid,
	rateModel,
}: RoomAccordionProps) {
	const [updateRoomStatus] = useUpdateRoomStatusMutation();

	const roomStatusColor =
		room.displayStatus === RoomStatus.ACTIVE
			? roomIndex % 2
				? '#2B769F'
				: '#32A5F1'
			: roomIndex % 2
			? '#808080'
			: '#BCBCBC';

	return (
		<StyledRoomAccordion disableGutters defaultExpanded>
			<RoomAccordionSummary
				expandIcon={<InvertedTriangleWhite />}
				sx={{
					backgroundColor: roomStatusColor,
				}}
			>
				<Box display={'flex'} width={'100%'}>
					<Stack
						sx={{ marginRight: '1rem', flexShrink: 0 }}
						direction={'row'}
						alignItems={'center'}
						maxWidth={'90%'}
					>
						{room.roomClass == RoomClass.INDEPENDENT &&
							room.id == basicRoomId && (
								<RoomTypeLabel label='Basic' textColor={roomStatusColor} />
							)}
						{room.roomClass == RoomClass.INDEPENDENT &&
							room.id != basicRoomId && (
								<RoomTypeLabel
									label='Independent'
									textColor={roomStatusColor}
								/>
							)}
						{room.roomClass == RoomClass.SUPPLEMENT && (
							<RoomTypeLabel label='Supplement' textColor={roomStatusColor} />
						)}
						<Box marginLeft={'8px'}>
							<RoomName>
								{room.name}

								<Link
									to={`/property/${propertyId}/room/${room.id}`}
									target='_blank'
									style={{
										color: 'white',
										display: 'inline-block',
										paddingLeft: '5px',
									}}
								>
									<ViewDetailIcon
										onClick={(event) => {
											event.stopPropagation();
										}}
									/>
								</Link>
							</RoomName>

							<RoomID>ID: {room.id}</RoomID>
						</Box>
					</Stack>
					<Box sx={{ width: '100%' }}></Box>
					<Box marginRight={'15px'} display={'flex'} alignItems={'center'}>
						<OnlineSwitchButton
							checked={room.status === RoomStatus.ACTIVE}
							onClickAction={() => {
								updateRoomStatus({
									roomId: room.id,
									body: {
										status:
											room.status === RoomStatus.ACTIVE
												? RoomStatus.INACTIVE
												: RoomStatus.ACTIVE,
									},
								});
							}}
						/>
					</Box>
				</Box>
			</RoomAccordionSummary>
			<RoomAccordionDetails>
				<Box display={'flex'}>
					<Box minWidth={'262px'} width={'262px'} marginTop={'1px'}>
						<StyledList>
							{getRoomRows(room.roomClass, priceMode).map((row, index) => (
								<div key={`${room.id}_${row.id}`}>
									{index > 0 && <Divider />}
									{row.id === RoomRow.MIN_PRICE ? (
										<StyledListItem>
											<ClickableListItemText
												onClick={minPriceOnClick}
												display={'flex'}
												alignItems={'center'}
												gap={'5px'}
												color={'#32A5F1'}
											>
												{row.name}
												{row.hint && (
													<Tooltip
														title={row.hint}
														placement='bottom-start'
														style={{
															marginLeft: '5px',
															marginRight: '5px',
															color: '#32A5F1',
														}}
													>
														<CircleInfoIcon />
													</Tooltip>
												)}
												<EditIcon />
											</ClickableListItemText>
										</StyledListItem>
									) : row.id === RoomRow.SUPPLEMENT_RATE ? (
										<StyledListItem>
											<ClickableListItemText
												onClick={roomSupplementRateOnClick}
												display={'flex'}
												alignItems={'center'}
												gap={'5px'}
												color={'#32A5F1'}
											>
												{row.name}
												{row.hint && (
													<Tooltip
														title={row.hint}
														placement='bottom-start'
														style={{
															marginLeft: '5px',
															marginRight: '5px',
															color: '#32A5F1',
														}}
													>
														<CircleInfoIcon />
													</Tooltip>
												)}
												<EditIcon />
											</ClickableListItemText>
										</StyledListItem>
									) : (
										<StyledListItem>
											<Typography fontSize={'14px'}>{row.name}</Typography>
											{row.hint && (
												<Tooltip
													title={row.hint}
													placement='bottom-start'
													style={{ marginLeft: '5px', color: '#808080' }}
												>
													<CircleInfoIcon />
												</Tooltip>
											)}
										</StyledListItem>
									)}
								</div>
							))}
						</StyledList>
					</Box>
					<Box width='100%' height={'auto'} minWidth={0}>
						<AutoSizer>
							{({ height, width }) => (
								<>
									<RoomGrid
										gridRef={(ref) => {
											roomGrid[roomIndex] = ref;
										}}
										width={width}
										height={height}
										onScroll={(params) => {
											if (dateCalendarRef.current)
												dateCalendarRef.current.scrollLeft = params.scrollLeft;

											roomGrid.forEach((grid, i) => {
												if (i !== roomIndex) {
													grid?.scrollToPosition({
														scrollLeft: params.scrollLeft,
														scrollTop: grid.state.scrollTop,
													});
												}
											});

											ratePlanGrid.forEach((array) => {
												array.forEach((grid) => {
													grid?.scrollToPosition({
														scrollLeft: params.scrollLeft,
														scrollTop: grid.state.scrollTop,
													});
												});
											});
										}}
										rateModel={rateModel}
										priceMode={priceMode}
										room={room}
									/>
								</>
							)}
						</AutoSizer>
					</Box>
				</Box>
			</RoomAccordionDetails>
		</StyledRoomAccordion>
	);
}

const StyledRoomAccordion = styled(Accordion)({
	borderRadius: '5px',
	boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.10)',
});

const RoomAccordionSummary = styled(AccordionSummary)({
	padding: '6px 12px',
	borderRadius: '5px 5px 5px 5px',
	'.MuiAccordionSummary-content': {
		margin: '0px',
	},
});

const RoomAccordionDetails = styled(AccordionDetails)({
	padding: '0px',
});

const RoomName = styled(Typography)({
	fontSize: '16px',
	fontWeight: 700,
	color: '#FFF',
});

const RoomID = styled(Typography)({
	fontSize: '10px',
	color: '#FFF',
});

const StyledList = styled(List)({
	padding: '0px',
});

const StyledListItem = styled(ListItem)({
	height: '36px',
});

const ClickableListItemText = styled(Typography)({
	fontSize: '14px',
	fontWeight: 700,
	color: '#32A5F1',
	cursor: 'pointer',
});

import { Snackbar, SnackbarProps } from '@mui/material';
import { useAppDispatch } from '../../../redux-hooks';
import { hideSnackbar } from './snackbarSlice';

const AppSnackbar = ({ sx, anchorOrigin, message }: SnackbarProps) => {
	const dispatch = useAppDispatch();

	const handleClose = () => {
		dispatch(hideSnackbar());
	};

	return (
		<Snackbar
			sx={sx}
			anchorOrigin={anchorOrigin}
			open={message != null}
			autoHideDuration={6000}
			message={message}
			onClose={handleClose}
		/>
	);
};

export default AppSnackbar;

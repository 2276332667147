import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { FormControl, styled, TextField, TextFieldProps } from '@mui/material';
import AppInputLabel from './AppInputLabel';

const StyledTextField = styled(TextField)(({ theme }) => ({
	'label + &': {
		marginTop: '28px',
	},

	'& .MuiOutlinedInput-root': {
		padding: '12px',

		'& fieldset': {
			borderColor: '#BCBCBC',
			borderRadius: '5px',
		},
		'&:hover fieldset': {
			borderWidth: '1px',
			borderColor: '#BCBCBC',
		},
		'&.Mui-focused fieldset': {
			borderWidth: '1px',
			borderColor: theme.palette.primary.main,
		},
		'&.Mui-error fieldset': {
			borderWidth: '1px',
			borderColor: theme.palette.error.main,
		},
		'&.Mui-disabled fieldset': {
			borderColor: '#F5F5F5',
		},
		'&.Mui-disabled': {
			background: '#F5F5F5',
		},
		'.Mui-disabled': {
			WebkitTextFillColor: '#808080',
		},
	},
	'& .MuiInputBase-input': {
		fontSize: 12,
		height: '12px',
		padding: '0px',

		'&::placeholder': {
			color: '#808080',
			opacity: 1,
		},
	},

	'& .MuiInputAdornment-root': {
		'& .MuiTypography-root': {
			fontSize: 12,
			color: '#808080',
		},
	},
}));

interface AppTextFieldProps {
	label: string;
}

export default function AppTextField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	disabled,
	placeholder,
	InputProps,
	required,
	...props
}: AppTextFieldProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<FormControl fullWidth={true} error={!!errorMessage}>
			<AppInputLabel
				label={label}
				errorMessage={errorMessage}
				required={required}
			/>
			<StyledTextField
				{...field}
				disabled={disabled}
				placeholder={placeholder}
				error={!!errorMessage}
				InputProps={InputProps}
			/>
		</FormControl>
	);
}

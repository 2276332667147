import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useEffect, useState } from 'react';
import RoomInfoView from './RoomInfoView';
import RoomInfoForm from './RoomInfoForm';
import { useParams } from 'react-router-dom';

interface RoomCardProps {
	initialType: FormCardType;
}

export default function RoomInfoCard({ initialType }: RoomCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	const { roomId } = useParams();

	useEffect(() => {
		if (type === FormCardType.edit) {
			setType(FormCardType.view);
		}
	}, [roomId]);

	return (
		<FormCard type={type} title={t('room.RoomInfo')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <RoomInfoView setType={setType} />;
					case FormCardType.create:
						return <RoomInfoForm type={type} setType={setType} />;
					case FormCardType.edit:
						return <RoomInfoForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

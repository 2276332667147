import { Button, Stack, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import {
	FieldPath,
	FieldValues,
	UseControllerProps,
	useController,
} from 'react-hook-form';
import { SectionItem } from '../../../api/DTO/page.interface';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import SelectPropertiesModal from '../../../common/components/modal/SelectPropertiesModal';

interface SectionSelectPropertiesProps {
	label?: string;
	infoPopUp?: ReactNode;
	removeErrorPadding?: boolean;
	required?: boolean;
	onClick?: () => void;
	defaultItems: any[];
}

export default function SectionSelectProperties<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	infoPopUp,
	required,
	removeErrorPadding,
	onClick,
	defaultItems,
	...props
}: SectionSelectPropertiesProps & UseControllerProps<TFieldValues, TName>) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	const defaultSelectedSectionProperties: SectionItem[] = field.value ?? [];

	const [selectedProperties, setSelectedProperties] = useState<any[]>([]);

	useEffect(() => {
		const newSelectedProperties: any[] = [];
		defaultSelectedSectionProperties.forEach((item) => {
			newSelectedProperties.push({
				itemId: item.id,
				id: item.propertyId,
				name: item.propertyName,
				status: item.status,
				category: item.category,
				tags: item.tags,
			});
		});
		setSelectedProperties(newSelectedProperties);
	}, [defaultSelectedSectionProperties]);

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		onClick && onClick();
		setOpen(true);
	};

	const handleClose = (updatedSelectedProperties?: any[]) => {
		setOpen(false);
		const newSelectedSectionProperties: SectionItem[] = [];

		if (updatedSelectedProperties) {
			updatedSelectedProperties.forEach((item, index) => {
				if (defaultItems.find((p) => p.propertyId === item.id)) {
					newSelectedSectionProperties.push({
						id: defaultItems.find((p) => p.propertyId === item.id)?.id ?? '',
						category: item.category,
						propertyId: item.id,
						propertyName: item.name,
						status: item.status,
						tags: defaultItems.find((p) => p.propertyId === item.id).tags ?? [],
					});
				} else {
					newSelectedSectionProperties.push({
						id: item.itemId,
						category: item.category,
						propertyId: item.id,
						propertyName: item.name,
						status: item.status,
						tags: [],
					});
				}
			});
			setSelectedProperties(newSelectedSectionProperties);
			field.onChange(newSelectedSectionProperties);
		}
	};

	return (
		<Stack justifyContent='flex-start' width={'100%'}>
			<AppInputLayout
				label={label}
				infoPopUp={infoPopUp}
				required={required}
				errorMessage={errorMessage}
				removeErrorPadding={removeErrorPadding}
			>
				<Button
					sx={{ width: '150px' }}
					variant={'contained'}
					onClick={handleClickOpen}
				>
					<Typography fontSize={'12px'} fontWeight={'bolder'}>
						Select Properties
					</Typography>
				</Button>
			</AppInputLayout>

			{open && (
				<SelectPropertiesModal
					open={open}
					onClose={handleClose}
					selectedProperties={selectedProperties ?? []}
				/>
			)}
		</Stack>
	);
}

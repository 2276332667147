export enum DiscountType {
	PERCENTAGE = 0,
	FIXED = 1,
}

export const discountTypeOptions = [
	{
		id: DiscountType.PERCENTAGE,
		name: '%',
	},
	{
		id: DiscountType.FIXED,
		name: 'Amount',
	},
];

import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import { useGetPropertyQuery } from '../../../../api/accommodationApiSlice';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import { Dispatch, SetStateAction, useContext } from 'react';
import { PropertyIdContext } from '../ViewPropertyPage';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';
import FlagLabelDisplay from '../../../../common/components/form-data-display/FlagLabelDisplay';
import { currencies } from '../../../../common/constants/currencies';
import { ApiSupplierType } from '../../../../api/enum/ApiSupplier.enum';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import {
	RateModel,
	rateModelOptions,
} from '../../../../api/enum/rateModel.enum';
import TextWithUnit from '../../../../common/components/form-data-display/TextWithUnit';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import TickOrCrossWithLabelDisplay from '../../../../common/components/form-data-display/TickOrCrossWithLabelDisplay';
import { priceModeOption } from '../../../../api/enum/priceMode.enum';
import ApiPropertyLabelDisplay from '../../../../common/components/form-data-display/ApiPropertyLabelDisplay';

export default function SupplierView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const propertyId = useContext(PropertyIdContext);

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppInputLayout label={t('property.DirectContract')}>
					<YesNoDisplay
						value={getPropertyResponse?.data.supplier.directContract}
					/>
				</AppInputLayout>

				{getPropertyResponse?.data.supplier.directContract && (
					<AppInputLayout label={t('property.DirectContractSupplier')}>
						<NewDisplayText
							text={getPropertyResponse?.data.supplier.supplier?.name}
						/>
					</AppInputLayout>
				)}

				<AppInputLayout label={t('property.SupplierCurrency')}>
					<FlagLabelDisplay
						iso2={
							currencies.find(
								(i) => i.id === getPropertyResponse?.data.supplier.currency
							)?.iso2
						}
						label={
							currencies.find(
								(i) => i.id === getPropertyResponse?.data.supplier.currency
							)?.name
						}
					/>
				</AppInputLayout>

				{getPropertyResponse?.data.supplier.directContract && (
					<>
						<AppInputLayout label={t('property.PriceMode')}>
							<NewDisplayText
								text={
									priceModeOption.find(
										(i) => i.id === getPropertyResponse?.data.supplier.priceMode
									)?.name
								}
							/>
						</AppInputLayout>

						<AppInputLayout label={t('common.RateModel')}>
							<Stack direction={'row'} spacing={'18px'} gap={'10px'}>
								<TickOrCrossWithLabelDisplay
									value={getPropertyResponse?.data.supplier.rateModels.includes(
										RateModel.AGENCY
									)}
									label={
										rateModelOptions.find((i) => i.id === RateModel.AGENCY)
											?.name ?? ''
									}
								/>

								<TickOrCrossWithLabelDisplay
									value={getPropertyResponse?.data.supplier.rateModels.includes(
										RateModel.MERCHANT
									)}
									label={
										rateModelOptions.find((i) => i.id === RateModel.MERCHANT)
											?.name ?? ''
									}
								/>
							</Stack>
						</AppInputLayout>
					</>
				)}

				{getPropertyResponse?.data.supplier.directContract &&
					getPropertyResponse?.data.supplier.rateModels.includes(
						RateModel.AGENCY
					) && (
						<>
							<Divider sx={{ marginBottom: '15px' }} />
							<AppSubtitleLayout label={t('common.AgencyModel')} />

							<AppInputLayout label={t('property.SellAtOfficialRate')}>
								<YesNoDisplay
									value={
										getPropertyResponse?.data.supplier.agencyConfig
											?.sellAtOfficialRate
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('property.CommissionLevel')}>
								<TextWithUnit
									text={`${
										getPropertyResponse?.data.supplier.agencyConfig
											?.commissionLevel ?? '-'
									}`}
									unit={`${
										getPropertyResponse?.data.supplier.agencyConfig
											?.commissionLevel
											? '%'
											: ''
									}`}
								/>
							</AppInputLayout>
						</>
					)}

				{getPropertyResponse?.data.supplier.directContract &&
					getPropertyResponse?.data.supplier.rateModels.includes(
						RateModel.MERCHANT
					) && (
						<>
							<Divider sx={{ marginBottom: '15px' }} />
							<AppSubtitleLayout label={t('common.MerchantModel')} />

							<AppInputLayout label={t('property.ServiceChargeIncluded')}>
								<YesNoDisplay
									value={
										getPropertyResponse?.data.supplier.merchantConfig
											?.serviceChargeIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('property.TaxIncluded')}>
								<YesNoDisplay
									value={
										getPropertyResponse?.data.supplier.merchantConfig
											?.taxIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('property.CommissionLevel')}>
								<TextWithUnit
									text={`${getPropertyResponse?.data.supplier.merchantConfig?.commissionLevel}`}
									unit='%'
								/>
							</AppInputLayout>

							<AppInputLayout
								label={t('property.CommissionIncludedServiceCharge')}
							>
								<YesNoDisplay
									value={
										getPropertyResponse?.data.supplier.merchantConfig
											?.commissionServiceChargeIncluded
									}
								/>
							</AppInputLayout>

							<AppInputLayout label={t('property.CommissionIncludedTax')}>
								<YesNoDisplay
									value={
										getPropertyResponse?.data.supplier.merchantConfig
											?.commissionTaxIncluded
									}
								/>
							</AppInputLayout>
						</>
					)}

				{getPropertyResponse?.data.supplier.mappings[
					ApiSupplierType.RAKUTEN
				] && (
					<>
						<Divider sx={{ marginBottom: '15px' }} />

						<AppInputLayout label={t('property.RakutenIDAndName')}>
							<ApiPropertyLabelDisplay
								apiPropertyId={
									getPropertyResponse?.data.supplier.mappings[
										ApiSupplierType.RAKUTEN
									]?.id
								}
								apiSupplier={ApiSupplierType.RAKUTEN}
								label={`${
									getPropertyResponse?.data.supplier.mappings[
										ApiSupplierType.RAKUTEN
									]?.id
								} ${
									getPropertyResponse?.data.supplier.mappings[
										ApiSupplierType.RAKUTEN
									]?.name
								}`}
							/>
						</AppInputLayout>
					</>
				)}

				{getPropertyResponse?.data.supplier.mappings[
					ApiSupplierType.MEITUAN
				] && (
					<>
						<Divider sx={{ marginBottom: '15px' }} />

						<AppInputLayout label={t('property.MeituanIDAndName')}>
							<ApiPropertyLabelDisplay
								apiPropertyId={
									getPropertyResponse?.data.supplier.mappings[
										ApiSupplierType.MEITUAN
									]?.id
								}
								apiSupplier={ApiSupplierType.MEITUAN}
								label={`${
									getPropertyResponse?.data.supplier.mappings[
										ApiSupplierType.MEITUAN
									]?.id
								} ${
									getPropertyResponse?.data.supplier.mappings[
										ApiSupplierType.MEITUAN
									]?.name
								}`}
							/>
						</AppInputLayout>
					</>
				)}
			</Stack>
		</>
	);
}

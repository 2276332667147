import { Box, Button, InputAdornment, Stack, Typography } from '@mui/material';
import { ReactComponent as ExpandMoreIcon } from '../../../../../assets/icon/expand-more.svg';
import {
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
} from '../../../../../common/components/AppAccordian';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
	useBulkUpdateRatePlanRatesMutation,
	useGetPropertyQuery,
	useGetRatePlanListItemsQuery,
} from '../../../../../api/accommodationApiSlice';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppGridMultipleCheckbox from '../../../../../common/components/form-inputs/AppGridMultipleCheckbox';
import AppTextField, {
	InputType,
} from '../../../../../common/components/form-inputs/AppTextField';
import { RatePlanChannel } from '../../../../../api/enum/ratePlanChannel.enum';

export default function SellingRate() {
	const { t } = useTranslation();
	const method = useFormContext();
	const { propertyId } = useParams();

	const [submitCount, setSubmitCount] = useState(0);
	const [submitted, setSubmitted] = useState(false);

	const { data: getRatePlanListItemsResponse } = useGetRatePlanListItemsQuery({
		propertyId: propertyId ?? '',
		rateModel: method.getValues('rateModel'),
		channel: RatePlanChannel.DIRECT,
	});

	const { data: getPropertyResponse } = useGetPropertyQuery(propertyId ?? '');
	const [bulkUpdateRatePlanRatesMutation] =
		useBulkUpdateRatePlanRatesMutation();

	const defaultValues: DefaultValues<FieldValues> = {
		ratePlanIds: [],
		sellingRate: '',
	};

	const formSchema = Yup.object().shape({
		ratePlanIds: Yup.array().min(1, t('errorMessage.atLeastOneCheckbox')),
		sellingRate: Yup.string().required(t('errorMessage.required')),
	});

	const sellingRateMethod = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const watchParentFrom = method.watch('from');
	const watchParentTo = method.watch('to');
	const watchParentDays = method.watch('days');
	const watchRatePlanIds = sellingRateMethod.watch('ratePlanIds');
	const watchSellingRate = sellingRateMethod.watch('sellingRate');

	useEffect(() => {
		if (submitCount === 0) return;
		method.trigger().then((r) => sellingRateMethod.trigger().then());
	}, [
		watchParentFrom,
		watchParentTo,
		watchParentDays,
		watchSellingRate,
		watchRatePlanIds,
		submitCount,
	]);

	useEffect(() => {
		if (submitted) {
			setTimeout(() => {
				setSubmitted(false);
			}, 3000);
		}
	}, [submitted]);

	const onSubmit: SubmitHandler<FieldValues> = () => {
		setSubmitCount((prev) => prev + 1);
		method.trigger().then((methodValidated) => {
			sellingRateMethod.trigger().then((sellingRateMethodValidated) => {
				if (methodValidated && sellingRateMethodValidated) {
					sellingRateMethod.setValue('days', method.getValues('days'));
					sellingRateMethod.setValue('from', method.getValues('from'));
					sellingRateMethod.setValue('to', method.getValues('to'));
					sellingRateMethod.setValue(
						'rateModel',
						method.getValues('rateModel')
					);

					bulkUpdateRatePlanRatesMutation({
						propertyId: propertyId ?? '',
						body: {
							from: sellingRateMethod.getValues('from'),
							to: sellingRateMethod.getValues('to'),
							days: sellingRateMethod.getValues('days'),
							ratePlanIds: sellingRateMethod.getValues('ratePlanIds'),
							sellingRate: sellingRateMethod.getValues('sellingRate'),
						},
					})
						.unwrap()
						.then((response) => {
							if (response.success) {
								setSubmitCount(0);
								setSubmitted(true);
							}
						});
				}
			});
		});
	};

	return (
		<StyledAccordion>
			<StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography
					color={'gray'}
					sx={{ flexGrow: 1 }}
					fontWeight={'bold'}
					fontSize={'14px'}
				>
					{t('rateAndInventory.SellingRate')}
				</Typography>
			</StyledAccordionSummary>
			<StyledAccordionDetails>
				<AppTextField
					control={sellingRateMethod.control}
					name={`sellingRate`}
					inputType={InputType.amount}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								{getPropertyResponse?.data?.supplier.currency}
							</InputAdornment>
						),
					}}
				/>

				<Box>
					<Typography
						color={'gray'}
						sx={{ flexGrow: 1 }}
						fontWeight={'bold'}
						fontSize={'14px'}
					>
						{t('rateAndInventory.ApplyToFollowingRatePlans')}
					</Typography>
					<Stack
						display={'flex'}
						direction={'column'}
						alignItems={'flex-start'}
					>
						<AppGridMultipleCheckbox
							control={sellingRateMethod.control}
							label={''}
							options={getRatePlanListItemsResponse?.data ?? []}
							name={'ratePlanIds'}
							columns={1}
						/>
					</Stack>
				</Box>

				<Button
					variant={'contained'}
					onClick={sellingRateMethod.handleSubmit(onSubmit)}
					fullWidth={true}
					disabled={submitted}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{submitted ? 'Applied' : 'Confirm'}
					</Typography>
				</Button>
			</StyledAccordionDetails>
		</StyledAccordion>
	);
}

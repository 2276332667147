import { useSelector } from 'react-redux';
import {
	selectServiceWorkerRegistration,
	selectServiceWorkerUpdated,
} from '../../../serviceWorkerSlice';
import { Button, Snackbar } from '@mui/material';
import { useEffect } from 'react';

const VersionUpdateAlertSnackbarRoot = () => {
	const isServiceWorkerUpdated = useSelector(selectServiceWorkerUpdated);
	const serviceWorkerRegistration = useSelector(
		selectServiceWorkerRegistration
	);

	const updateServiceWorker = () => {
		if (serviceWorkerRegistration) {
			const registrationWaiting = serviceWorkerRegistration.waiting;

			if (registrationWaiting) {
				registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

				window.location.reload();
			} else {
				window.location.reload();
			}
		} else {
			window.location.reload();
		}
	};

	useEffect(() => {
		if (isServiceWorkerUpdated) {
			if (serviceWorkerRegistration) {
				const registrationWaiting = serviceWorkerRegistration.waiting;

				if (registrationWaiting) {
					registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
				}
			}
		}
	}, [isServiceWorkerUpdated]);

	return (
		<>
			{isServiceWorkerUpdated && (
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={true}
					message={'A new version of the application is available.'}
					action={<Button onClick={updateServiceWorker}>REFRESH</Button>}
				/>
			)}
		</>
	);
};

export default VersionUpdateAlertSnackbarRoot;

import { IconButton, InputAdornment, InputBase, styled } from '@mui/material';
import { ReactComponent as IncreaseIcon } from '../../../assets/icon/increase.svg';
import { ReactComponent as DecreaseIcon } from '../../../assets/icon/decrease.svg';

interface BedTypeCounterProps {
	value?: number;
	onChange: (value: number) => void;
	errorBorder?: boolean;
	noSelectedColor?: boolean;
}

const StyledInputBase = styled(InputBase, {
	shouldForwardProp: (prop) =>
		!(
			prop === 'errorBorder' ||
			prop === 'isSelected' ||
			prop === 'noSelectedColor'
		),
})<{
	errorBorder?: boolean;
	isSelected?: boolean;
	noSelectedColor?: boolean;
}>(({ errorBorder, isSelected, noSelectedColor }) => ({
	width: '100%',
	padding: '3px 5px',
	borderRadius: '5px',
	border: noSelectedColor
		? '1px solid #BCBCBC'
		: isSelected
		? '1px solid #E8F5FD'
		: errorBorder
		? '1px solid #F6403F'
		: '1px solid #BCBCBC',
	backgroundColor: noSelectedColor ? 'white' : isSelected ? '#E8F5FD' : 'white',
}));

export default function BedTypeCounter({
	value,
	onChange,
	errorBorder,
	noSelectedColor,
}: BedTypeCounterProps) {
	const regex = /^[0-9\b]+$/;

	function increment() {
		onChange((value ? value : 0) + 1);
	}

	function decrement() {
		if ((value ?? 0) > 0) {
			onChange((value ?? 0) - 1);
		} else {
			onChange(0);
		}
	}

	return (
		<StyledInputBase
			errorBorder={errorBorder}
			isSelected={value ? true : false}
			noSelectedColor={noSelectedColor}
			inputProps={{
				style: {
					textAlign: 'center',
					fontSize: '12px',
					padding: '0px',
					minWidth: '10px',
				},
			}}
			value={value ?? 0}
			onChange={(e) => {
				if (e.target.value === '' || regex.test(e.target.value)) {
					if (e.target.value === '') onChange(0);
					else onChange(Number(e.target.value));
				}
			}}
			startAdornment={
				<InputAdornment position='start' onClick={decrement}>
					<IconButton disableRipple>
						<DecreaseIcon />
					</IconButton>
				</InputAdornment>
			}
			endAdornment={
				<InputAdornment position='end' onClick={increment}>
					<IconButton disableRipple>
						<IncreaseIcon />
					</IconButton>
				</InputAdornment>
			}
		/>
	);
}

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	Box,
	InputAdornment,
	MenuItem,
	Stack,
	styled,
	TextFieldProps,
	Typography,
} from '@mui/material';
import Option from '../../../common/types/option.type';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import { ReactNode, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icon/dropdownmenu-search.svg';
import { ReactComponent as RakutenIcon } from '../../../assets/icon/Rakuten.svg';
import { ReactComponent as MeituanIcon } from '../../../assets/icon/Meituan.svg';
import SelectedApiMapping from '../../../common/components/SelectedApiMapping';
import { useLazyGetFilteredApiPropertiesQuery } from '../../../api/apiServiceApiSlice';
import { ApiSupplierType } from '../../../api/enum/ApiSupplier.enum';
import { StyledTextField } from '../../../common/components/styled/StyledTextField';
import { StyledAutocomplete } from '../../../common/components/styled/StyledAutocomplete';
import { ApiPropertyFilter } from '../../../api/DTO/apiProperty.interface';
import { ApiPropertyReturnRate } from '../../../common/components/form-data-display/ApiPropertyLabelDisplay';

interface ApiPropertyMappingSearchProps {
	apiSupplier: ApiSupplierType;
	label: string;
	infoPopUp?: ReactNode;
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	fontSize: '12px',
	padding: '12px',
}));

export default function ApiPropertyMappingSearch<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	apiSupplier,
	label,
	infoPopUp,
	required,
	...props
}: ApiPropertyMappingSearchProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const value = field.value;
	const onChange = field.onChange;
	const errorMessage = fieldState.error?.message;

	const [inputValue, setInputValue] = useState('');

	const [getFilteredApiProperties, { data: ApiProperties }] =
		useLazyGetFilteredApiPropertiesQuery();

	const selectOnChange = (selectedOption: Option) => {
		// const arr: Option[] = value ?? [];
		// const found = arr.some((el) => el.id === selectedOption.id);
		// if (!found) arr.push(selectedOption);
		// onChange(arr);

		onChange(value ?? selectedOption);
	};

	const removeItem = (option: Option) => {
		const arr: Option[] = value ?? [];
		onChange(arr.filter((item) => item.id !== option.id));
	};

	return (
		<AppInputLayout
			label={label}
			required={required}
			infoPopUp={infoPopUp}
			errorMessage={errorMessage}
			labelAlignItemsFlexStart
		>
			<Stack>
				{/* {value?.map((option: Option) => (
					<SelectedApiMapping
						key={option.id}
						apiSupplier={apiSupplier}
						option={option}
						onDeleteClick={() => {
							removeItem(option);
						}}
					/>
				))} */}
				{value && (
					<SelectedApiMapping
						key={value.id}
						apiSupplier={apiSupplier}
						option={value}
						onDeleteClick={() => {
							onChange(null);
						}}
						returnRateNode={
							<ApiPropertyReturnRate
								apiPropertyId={value.id}
								apiSupplier={apiSupplier}
							/>
						}
					/>
				)}
				<StyledAutocomplete
					disablePortal
					options={ApiProperties?.data ?? []}
					onChange={(_, newValue) => console.log(newValue)}
					getOptionLabel={(option: any) => option.name}
					isOptionEqualToValue={(option: any, value: any) =>
						option.id === value.id
					}
					filterSelectedOptions
					inputValue={inputValue}
					onInputChange={(_, newInputValue) => {
						setInputValue(newInputValue);
					}}
					clearOnBlur={false}
					renderInput={(params) => (
						<StyledTextField
							{...params}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<InputAdornment sx={{ marginRight: '14px' }} position='end'>
										<SearchIcon
											cursor={'pointer'}
											onClick={() => {
												getFilteredApiProperties({
													apiSupplier: apiSupplier,
													body: {
														searchKey: inputValue,
													} as ApiPropertyFilter,
												});
											}}
										/>
										{/* {params.InputProps.endAdornment} */}
									</InputAdornment>
								),
							}}
						/>
					)}
					ListboxProps={{
						style: {
							maxHeight: '250px',
						},
					}}
					renderOption={(props: object, option: any) => (
						// <StyledMenuItem {...props}>{option.name}</StyledMenuItem>
						<StyledMenuItem
							key={option.id}
							value={option.id}
							onClick={() => {
								selectOnChange(option);
							}}
						>
							<Stack direction={'row'} alignItems={'center'} spacing={'10px'}>
								{apiSupplier === ApiSupplierType.RAKUTEN && <RakutenIcon />}
								{apiSupplier === ApiSupplierType.MEITUAN && <MeituanIcon />}
								<Box>
									<Typography variant='regular' sx={{ fontSize: '0.75rem' }}>
										{option.id} {option.name}
									</Typography>
									<ApiPropertyReturnRate
										apiPropertyId={option.id}
										apiSupplier={apiSupplier}
									/>
								</Box>
							</Stack>
						</StyledMenuItem>
					)}
				></StyledAutocomplete>
			</Stack>
		</AppInputLayout>
	);
}

export enum ChildPriceType {
	NA = 0,
	FULL_RATE = 1,
	PERCENTAGE = 2,
	FIXED = 3,
	FREE = 4,
}

export const childPriceTypeOptions = [
	{
		id: ChildPriceType.NA,
		name: 'NA',
	},
	{
		id: ChildPriceType.FULL_RATE,
		name: 'Full Rate',
	},
	{
		id: ChildPriceType.PERCENTAGE,
		name: '%',
	},
	{
		id: ChildPriceType.FIXED,
		name: 'Amount',
	},
	{
		id: ChildPriceType.FREE,
		name: 'Free',
	},
];

import { Select, Stack, Typography, styled } from '@mui/material';
import { StyledMenuItem } from '../../../../common/components/styled/StyledSelect';
import {
	Locale,
	translationLocaleOptions,
} from '../../../../api/enum/locale.enum';

interface LocaleDropDownMenuProps {
	value: Locale;
	onChangeCallback: (optionId: any) => void;
}

export default function LocaleDropDownMenu({
	value,
	onChangeCallback,
}: LocaleDropDownMenuProps) {
	return (
		<LocaleSelect
			autoWidth
			variant='outlined'
			onChange={(e) => {
				onChangeCallback(e.target.value);
			}}
			value={value}
			MenuProps={{
				PaperProps: {
					sx: {
						borderRadius: '5px',
						boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
						'& .MuiMenuItem-root': {
							padding: '12px',
						},
						maxHeight: '250px',
					},
				},
			}}
		>
			{translationLocaleOptions.map((option) => (
				<StyledMenuItem key={option.id} value={option.id}>
					<Stack direction={'row'} alignItems={'center'}>
						<Typography variant='regular' sx={{ fontSize: '16px' }}>
							{option.name}
						</Typography>
					</Stack>
				</StyledMenuItem>
			))}
		</LocaleSelect>
	);
}

const LocaleSelect = styled(Select)(({ theme }) => ({
	display: 'flex',
	fontSize: '16px',
	height: '30px',
	color: '#32A5F1',

	'label + &': {
		marginTop: '28px',
	},

	'& fieldset': {
		borderWidth: '0px',
	},

	':hover ': {
		borderWidth: '0px',
	},

	'&.Mui-focused fieldset': {
		borderWidth: '0px !important',
	},

	'&.Mui-error fieldset': {
		borderWidth: '0px !important',
	},
	'&.Mui-disabled': {
		background: '#F5F5F5',
	},
	'.Mui-disabled': {
		WebkitTextFillColor: '#808080',
	},

	'.MuiSelect-icon': {
		color: '#32A5F1',
	},
}));

import { Box, Button, Stack, ToggleButton, Typography } from '@mui/material';
import { ReactComponent as ExpandMoreIcon } from '../../../../../assets/icon/expand-more.svg';
import {
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
} from '../../../../../common/components/AppAccordian';
import AppGridMultipleCheckbox from '../../../../../common/components/form-inputs/AppGridMultipleCheckbox';
import { useTranslation } from 'react-i18next';
import {
	DefaultValues,
	FieldValues,
	SubmitHandler,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
	useBulkUpdateRatePlanStatusesMutation,
	useGetRatePlanListItemsQuery,
} from '../../../../../api/accommodationApiSlice';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RatePlanStatusEnum } from '../../../../../api/enum/ratePlan.enum';
import { StyledToggleButtonGroup } from '../../../../../common/components/RateModelToggleButton';

export default function RatePlanStatus() {
	const { t } = useTranslation();
	const method = useFormContext();
	const { propertyId } = useParams();

	const [submitCount, setSubmitCount] = useState(0);
	const [ratePlanStatusEnum, setRatePlanStatusEnum] = useState(
		RatePlanStatusEnum.ACTIVE
	);
	const [submitted, setSubmitted] = useState(false);

	const { data: getRatePlanListItemsResponse } = useGetRatePlanListItemsQuery({
		propertyId: propertyId ?? '',
		rateModel: method.getValues('rateModel'),
	});

	const [bulkUpdateRatePlanStatusesMutation] =
		useBulkUpdateRatePlanStatusesMutation();

	const defaultValues: DefaultValues<FieldValues> = {
		ratePlanIds: [],
		status: RatePlanStatusEnum.ACTIVE,
	};

	const formSchema = Yup.object().shape({
		ratePlanIds: Yup.array().min(1, t('errorMessage.atLeastOneCheckbox')),
	});

	const ratePlanStatusMethod = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	// watch
	const watchParentFrom = method.watch('from');
	const watchParentTo = method.watch('to');
	const watchParentDays = method.watch('days');
	const watchRatePlanIds = ratePlanStatusMethod.watch('ratePlanIds');

	// onsubmit
	useEffect(() => {
		if (submitCount === 0) return;
		method.trigger().then((r) => ratePlanStatusMethod.trigger().then());
	}, [
		watchParentFrom,
		watchParentTo,
		watchParentDays,
		watchRatePlanIds,
		submitCount,
		ratePlanStatusEnum,
	]);

	useEffect(() => {
		if (submitted) {
			setTimeout(() => {
				setSubmitted(false);
			}, 3000);
		}
	}, [submitted]);

	const onSubmit: SubmitHandler<FieldValues> = () => {
		setSubmitCount((prev) => prev + 1);
		method.trigger().then((methodValidated) => {
			ratePlanStatusMethod.trigger().then((ratePlanStatusMethodValidated) => {
				if (methodValidated && ratePlanStatusMethodValidated) {
					ratePlanStatusMethod.setValue('days', method.getValues('days'));
					ratePlanStatusMethod.setValue('from', method.getValues('from'));
					ratePlanStatusMethod.setValue('to', method.getValues('to'));
					ratePlanStatusMethod.setValue(
						'rateModel',
						method.getValues('rateModel')
					);

					bulkUpdateRatePlanStatusesMutation({
						propertyId: propertyId ?? '',
						body: {
							from: ratePlanStatusMethod.getValues('from'),
							to: ratePlanStatusMethod.getValues('to'),
							days: ratePlanStatusMethod.getValues('days'),
							ratePlanIds: ratePlanStatusMethod.getValues('ratePlanIds'),
							status: ratePlanStatusEnum,
						},
					})
						.unwrap()
						.then((response) => {
							if (response.success) {
								setSubmitCount(0);
								setSubmitted(true);
							}
						});
				}
			});
		});
	};

	return (
		<StyledAccordion>
			<StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography
					color={'gray'}
					sx={{ flexGrow: 1 }}
					fontWeight={'bold'}
					fontSize={'14px'}
				>
					{t('rateAndInventory.RatePlanStatus')}
				</Typography>
			</StyledAccordionSummary>
			<StyledAccordionDetails>
				<Box paddingBottom={'10px'}>
					<StyledToggleButtonGroup
						fullWidth={true}
						color='primary'
						value={ratePlanStatusEnum}
						exclusive
						onChange={(_, value) => {
							if (value != null) setRatePlanStatusEnum(value);
						}}
						aria-label='Model-Selection'
					>
						<ToggleButton value={RatePlanStatusEnum.ACTIVE}>
							{t('common.Online')}
						</ToggleButton>
						<ToggleButton value={RatePlanStatusEnum.INACTIVE}>
							{t('common.Offline')}
						</ToggleButton>
					</StyledToggleButtonGroup>
				</Box>

				<Box>
					<Typography
						color={'gray'}
						sx={{ flexGrow: 1 }}
						fontWeight={'bold'}
						fontSize={'14px'}
					>
						{t('rateAndInventory.ApplyToFollowingRatePlans')}
					</Typography>
					<Stack
						display={'flex'}
						direction={'column'}
						alignItems={'flex-start'}
					>
						<AppGridMultipleCheckbox
							control={ratePlanStatusMethod.control}
							label={''}
							options={getRatePlanListItemsResponse?.data ?? []}
							name={'ratePlanIds'}
							columns={1}
						/>
					</Stack>
				</Box>

				<Button
					variant={'contained'}
					onClick={ratePlanStatusMethod.handleSubmit(onSubmit)}
					fullWidth={true}
					disabled={submitted}
				>
					<Typography fontSize={'10px'} fontWeight={'bolder'}>
						{submitted ? 'Applied' : 'Confirm'}
					</Typography>
				</Button>
			</StyledAccordionDetails>
		</StyledAccordion>
	);
}

import { IconButton, InputAdornment, InputBase, styled } from '@mui/material';
import { ReactComponent as IncreaseIcon } from '../../../assets/icon/increase.svg';
import { ReactComponent as DecreaseIcon } from '../../../assets/icon/decrease.svg';
import { useState } from 'react';

interface AppFieldArrayCounterProps {
	value?: number;
	onChange: (value: number) => void;
	append: () => void;
	remove: (value: number) => void;
	errorBorder?: boolean;
	noSelectedColor?: boolean;
	greaterZero?: boolean;
}

const StyledInputBase = styled(InputBase, {
	shouldForwardProp: (prop) =>
		!(
			prop === 'errorBorder' ||
			prop === 'isSelected' ||
			prop === 'noSelectedColor'
		),
})<{
	errorBorder?: boolean;
	isSelected?: boolean;
	noSelectedColor?: boolean;
}>(({ errorBorder, isSelected, noSelectedColor }) => ({
	width: '100%',
	padding: '3px 5px',
	borderRadius: '5px',
	border: noSelectedColor
		? '1px solid #BCBCBC'
		: isSelected
		? '1px solid #E8F5FD'
		: errorBorder
		? '1px solid #F6403F'
		: '1px solid #BCBCBC',
	backgroundColor: noSelectedColor ? 'white' : isSelected ? '#E8F5FD' : 'white',
}));

export default function AppFieldArrayCounter({
	value,
	onChange,
	append,
	remove,
	noSelectedColor,
	errorBorder,
	greaterZero,
}: AppFieldArrayCounterProps) {
	const regex = /^[0-9\b]+$/;
	const [number, setNumber] = useState<number>(value ?? 0);

	function increment() {
		onChange((number ? number : 0) + 1);
		setNumber((number ? number : 0) + 1);
		append();
	}

	function decrement() {
		if (greaterZero) {
			if ((number ?? 1) > 1) {
				onChange((number ?? 0) - 1);
				setNumber((number ?? 0) - 1);
				remove((number ?? 0) - 1);
			} else {
				onChange(1);
				setNumber(1);
				remove(1);
			}
		} else {
			if ((number ?? 0) > 0) {
				onChange((number ?? 0) - 1);
				setNumber((number ?? 0) - 1);
				remove((number ?? 0) - 1);
			} else {
				onChange(0);
				setNumber(0);
				remove(0);
			}
		}
	}

	return (
		<StyledInputBase
			errorBorder={errorBorder}
			isSelected={number ? true : false}
			noSelectedColor={noSelectedColor}
			inputProps={{
				readOnly: true,
				style: {
					textAlign: 'center',
					fontSize: '12px',
					padding: '0px',
					minWidth: '10px',
				},
			}}
			value={number ?? 0}
			onChange={(e) => {
				// console.log(e.target.value);
				if (e.target.value === '' || regex.test(e.target.value)) {
					if (e.target.value === '') {
						setNumber(0);
						onChange(0);
					} else {
						setNumber(Number(e.target.value));
						onChange(Number(e.target.value));
					}
				}
			}}
			startAdornment={
				<InputAdornment position='start' onClick={decrement}>
					<IconButton disableRipple>
						<DecreaseIcon />
					</IconButton>
				</InputAdornment>
			}
			endAdornment={
				<InputAdornment position='end' onClick={increment}>
					<IconButton disableRipple>
						<IncreaseIcon />
					</IconButton>
				</InputAdornment>
			}
		/>
	);
}

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { SubmitHandler, DefaultValues, useForm } from 'react-hook-form';
import AppEmailTextField from '../../common/components/form-inputs/AppEmailTextField';
import AppPasswordTextField from '../../common/components/form-inputs/AppPasswordTextField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthContainer from './components/AuthContainer';
import { useLoginMutation } from '../../api/authApiSlice';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import { LoginRequest } from '../../api/request/login.request';
import SingleCheckbox from '../../common/components/form-inputs/SingleCheckbox';

export const defaultValues: DefaultValues<LoginRequest> = {
	email: '',
	password: '',
	extended: false,
};

export default function LoginPage() {
	const { t } = useTranslation();

	const formSchema = Yup.object().shape({
		email: Yup.string()
			.required(t('errorMessage.required'))
			.email(t('errorMessage.email')),
		password: Yup.string().required(t('errorMessage.required')),
	});

	const { handleSubmit, control } = useForm<LoginRequest>({
		defaultValues,
		resolver: yupResolver(formSchema),
	});

	const navigate = useNavigate();
	const location = useLocation();
	const from = ((location.state as any)?.from.pathname as string) || '/';
	const fromSearchParams =
		((location.state as any)?.from.search as string) || '';
	const [login] = useLoginMutation();
	const dispatch = useDispatch();

	const onSubmit: SubmitHandler<LoginRequest> = async (formResult) => {
		try {
			const loginResponse = await login(formResult).unwrap();
			dispatch(
				setCredentials({
					accessToken: loginResponse.data.accessToken,
				})
			);
			navigate(from + fromSearchParams);
		} catch (err) {
			console.log('error');
		}
	};

	return (
		<AuthContainer
			title={t('login.welcomeTitle')}
			message={t('login.welcomeMessage')}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Stack
					width='382px'
					alignItems='center'
					spacing='24px'
					marginTop='40px'
				>
					<AppEmailTextField
						control={control}
						name='email'
						label={t('login.YourEmail')}
						placeholder={t('common.TypeHere')}
					/>

					<AppPasswordTextField
						control={control}
						name='password'
						label={t('login.YourPassword')}
						placeholder={t('common.TypeHere')}
					/>

					<SingleCheckbox
						control={control}
						name='extended'
						label={t('login.KeepMeSignedIn')}
					/>
				</Stack>

				<Button
					fullWidth
					type='submit'
					variant='contained'
					color='success'
					sx={{
						marginTop: '40px',
					}}
				>
					{t('button.SignIn')}
				</Button>
			</form>

			<Typography
				component={Link}
				to='/forgot-password'
				sx={{ textDecoration: 'underline', cursor: 'pointer' }}
				fontSize='14px'
				variant='bold'
				color='#808080'
				marginTop='24px'
			>
				{t('button.ForgotPassword')}
			</Typography>
		</AuthContainer>
	);
}

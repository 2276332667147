import { Box, InputAdornment } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AbkMealItemOptions } from '../../../api/enum/abkMealItem.enum';
import { ReactComponent as AddIcon } from '../../../assets/icon/button-add.svg';
import { ReactComponent as CopyIcon } from '../../../assets/icon/copy.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/delete.svg';
import AppAddButton from '../../../common/components/form-inputs/AppAddButton';
import AppRadioGroup from '../../../common/components/form-inputs/AppRadioGroup';
import AppTextField, {
	InputType,
} from '../../../common/components/form-inputs/AppTextField';
import AppInputLayout from '../../../common/components/form-layout/AppInputLayout';
import AppSplitInputLayout from '../../../common/components/form-layout/AppSplitInputLayout';
import AppSplitSubtitleLabel from '../../../common/components/form-layout/AppSplitSubtitleLabel';
import AppSplitSubtitleLayout from '../../../common/components/form-layout/AppSplitSubtitleLayout';
import { StyledBackgroundCard } from '../overview/rateInfo/RateInfoForm';
import { AbkStatus } from '../../../api/enum/abkStatus.enum';

interface AbkMealItemFieldProps {
	index: number;
	control: any;
	cusCurrency?: string;
	supCurrency?: string;
	status: AbkStatus;
	handleCopyButton: (from: string, to: string) => void;
}

export default function AbkMealItemField({
	index,
	control,
	cusCurrency,
	supCurrency,
	status,
	handleCopyButton,
}: AbkMealItemFieldProps) {
	const { t } = useTranslation();

	const {
		fields: mealItemFields,
		append: mealItemAppend,
		remove: mealItemRemove,
	} = useFieldArray({
		control,
		name: `rate.rooms.${index}.otherMeals`,
	});

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					gap: '5px',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<Box width={'100%'}>
					{mealItemFields.map((item, mealItemIndex) => {
						return (
							<StyledBackgroundCard key={item.id} position={'relative'}>
								<Box
									sx={{
										position: 'absolute',
										top: '15px',
										right: '15px',
									}}
								>
									<DeleteIcon
										color='#F6403F'
										cursor={'pointer'}
										onClick={() => {
											mealItemRemove(mealItemIndex);
										}}
									/>
								</Box>
								<Box width={'100%'} display={'flex'} alignItems={'center'}>
									<AppSplitSubtitleLayout
										subTitle={`${t('abk.MealItem')} (${mealItemIndex + 1})`}
										children1={
											<AppSplitSubtitleLabel
												label={t('abk.SupplierRate')}
												color={'#4EA4D3'}
											/>
										}
										children2={
											<AppSplitSubtitleLabel
												label={t('abk.CustomerRate')}
												required={status !== AbkStatus.ENQUIRY}
											/>
										}
									/>
								</Box>
								<AppInputLayout
									label={t('abk.Type')}
									required
									removeErrorPadding
								>
									<AppRadioGroup
										control={control}
										name={`rate.rooms.${index}.otherMeals.${mealItemIndex}.forAdult`}
										label={''}
										options={AbkMealItemOptions}
									/>
								</AppInputLayout>
								<AppSplitInputLayout
									label={t('abk.Description')}
									children1={
										<AppTextField
											control={control}
											name={`rate.rooms.${index}.otherMeals.${mealItemIndex}.supName`}
											placeholder={t('abk.SupDescriptionPlaceholder')}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<CopyIcon
															color='#4EA4D3'
															onClick={() => {
																handleCopyButton(
																	`rate.rooms.${index}.otherMeals.${mealItemIndex}.supName`,
																	`rate.rooms.${index}.otherMeals.${mealItemIndex}.cusName`
																);
															}}
														/>
													</InputAdornment>
												),
											}}
										/>
									}
									children2={
										<AppTextField
											control={control}
											name={`rate.rooms.${index}.otherMeals.${mealItemIndex}.cusName`}
											placeholder={t('abk.CusDescriptionPlaceholder')}
											// InputProps={{
											// 	endAdornment: (
											// 		<InputAdornment position='end'>
											// 			<CopyIcon
											// 				color='#4EA4D3'
											// 				onClick={() => {
											// 					handleCopyButton(
											// 						`rate.rooms.${index}.otherMeals.${mealItemIndex}.cusName`,
											// 						`rate.rooms.${index}.otherMeals.${mealItemIndex}.supName`
											// 					);
											// 				}}
											// 			/>
											// 		</InputAdornment>
											// 	),
											// }}
										/>
									}
									required
								/>
								<AppSplitInputLayout
									label={t('abk.MealRate')}
									children1={
										<AppTextField
											control={control}
											name={`rate.rooms.${index}.otherMeals.${mealItemIndex}.supRate`}
											inputType={InputType.amount}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														{supCurrency}
													</InputAdornment>
												),
												endAdornment: (
													<>
														<InputAdornment position='end'>
															{t('abk.PerMeal')}
														</InputAdornment>
														<InputAdornment position='end'>
															<CopyIcon
																color='#4EA4D3'
																onClick={() => {
																	handleCopyButton(
																		`rate.rooms.${index}.otherMeals.${mealItemIndex}.supRate`,
																		`rate.rooms.${index}.otherMeals.${mealItemIndex}.cusRate`
																	);
																}}
															/>
														</InputAdornment>
													</>
												),
											}}
										/>
									}
									children2={
										<AppTextField
											control={control}
											name={`rate.rooms.${index}.otherMeals.${mealItemIndex}.cusRate`}
											inputType={InputType.amount}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														{cusCurrency}
													</InputAdornment>
												),
												endAdornment: (
													<>
														<InputAdornment position='end'>
															{t('abk.PerMeal')}
														</InputAdornment>
														{/* <InputAdornment position='end'>
															<CopyIcon
																color='#4EA4D3'
																onClick={() => {
																	handleCopyButton(
																		`rate.rooms.${index}.otherMeals.${mealItemIndex}.cusRate`,
																		`rate.rooms.${index}.otherMeals.${mealItemIndex}.supRate`
																	);
																}}
															/>
														</InputAdornment> */}
													</>
												),
											}}
										/>
									}
									required
								/>
							</StyledBackgroundCard>
						);
					})}
				</Box>
			</Box>
			<AppAddButton
				onClick={() =>
					mealItemAppend({
						forAdult: true,
						supName: undefined,
						cusName: undefined,
						supRate: undefined,
						cusRate: undefined,
					})
				}
			>
				<AddIcon />
				{t('abk.AddOtherMeal')}
			</AppAddButton>
		</>
	);
}

import Option from '../../common/types/option.type';

interface AbkStatusProps {
	name: string;
	color: string;
	backgroundColor: string;
}

export enum AbkStatus {
	ENQUIRY = 0,
	NEW = 1,
	CONFIRMED = 2,
	CANCELLED = 3,
}

export const AbkEditOptions: Option[] = [
	{
		id: AbkStatus.ENQUIRY,
		name: 'Enquiry',
	},
	{
		id: AbkStatus.NEW,
		name: 'Reservation',
	},
];

export const AbkStatusOptions: Option[] = [
	{
		id: AbkStatus.ENQUIRY,
		name: 'Enquiry',
		color: '#4EA4D3',
	},
	{
		id: AbkStatus.NEW,
		name: 'New',
		color: '#D0AB2A',
	},
	{
		id: AbkStatus.CONFIRMED,
		name: 'Confirmed',
		color: '#4B9831',
	},
	{
		id: AbkStatus.CANCELLED,
		name: 'Cancelled',
		color: '#DA5150',
	},
];

export function getAbkStatusDisplay(status: number): AbkStatusProps {
	switch (status) {
		case AbkStatus.ENQUIRY:
			return {
				name: 'Enquiry',
				color: '#4EA4D3',
				backgroundColor: '#D1EAFA',
			};
		case AbkStatus.NEW:
			return {
				name: 'New',
				color: '#D0AB2A',
				backgroundColor: '#FFEEB6',
			};
		case AbkStatus.CONFIRMED:
			return {
				name: 'Confirmed',
				color: '#4B9831',
				backgroundColor: '#E4F0DB',
			};
		case AbkStatus.CANCELLED:
			return {
				name: 'Cancelled',
				color: '#DA5150',
				backgroundColor: '#FFDDDC',
			};
		default:
			return {
				name: '-',
				color: 'black',
				backgroundColor: 'transparent',
			};
	}
}

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Abk, AbkPhoneCode } from '../../../api/DTO/abk.interface';
import { useGetAbkQuery, useUpdateAbkMutation } from '../../../api/abkApiSlice';
import { AbkEditOptions, AbkStatus } from '../../../api/enum/abkStatus.enum';
import { PaymentMethod } from '../../../api/enum/paymentMethod.enum';
import { RateModel } from '../../../api/enum/rateModel.enum';
import FullScreenLoader from '../../../common/components/FullScreenLoader';
import { FormCardType } from '../../../common/components/card/FormCard';
import AppToggleButton from '../../../common/components/form-inputs/AppToggleButton';
import { NONE_INVALID_DATE_PATTERN } from '../../../common/constants/pattern';
import { yupMapRules } from '../../../utils/yup-extended';
import { AbkTypeContext, AbkVersionContext } from '../AbkContainer';
import BaseInfoCard from './baseInfo/BaseInfoCard';
import CustomerPaymentInfoCard from './customerPaymentInfo/CustomerPaymentInfoCard';
import RateInfoCard from './rateInfo/RateInfoCard';
import SupplierInfoCard from './supplierInfo/SupplierInfoCard';
import dayjs from 'dayjs';

export const AbkIdContext = createContext('');

export default function ViewAbkPage() {
	const { t } = useTranslation();
	const { abkId } = useParams();

	const [type, setType] = useContext(AbkTypeContext);
	const [AbkVersion, setAbkVersion] = useContext(AbkVersionContext);

	const [abkSubmitted, setAbkSubmitted] = useState<boolean>(false);

	const {
		data: getAbkResponse,
		isLoading: isGetAbkLoading,
		isSuccess: isGetAbkSuccess,
		isError: isGetAbkError,
	} = useGetAbkQuery(
		{
			abk_id: abkId ?? '',
			version: undefined,
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const [
		updateAbk,
		{ data: UpdateAbkResponse, isSuccess: isUpdateAbkSuccess },
	] = useUpdateAbkMutation();

	useEffect(() => {
		if (isUpdateAbkSuccess) {
			setType(FormCardType.view);
			setAbkVersion(undefined);
		}
	}, [isUpdateAbkSuccess]);

	interface ValidateOptionsExtended {
		options: {
			index: number;
		};
	}

	const formSchema = Yup.object().shape({
		baseInfo: Yup.object().shape({
			creationDate: Yup.string()
				.required(t('errorMessage.pleaseSelect'))
				.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
			stayPeriod: Yup.object().shape({
				start: Yup.string()
					.required(t('errorMessage.pleaseSelect'))
					.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime')),
				end: Yup.string()
					.required(t('errorMessage.pleaseSelect'))
					.matches(NONE_INVALID_DATE_PATTERN, t('errorMessage.incorrectTime'))
					.when('start', (start: any, schema) => {
						return schema.test(
							'shouldGreaterThanStart',
							() => t('errorMessage.incorrectPeriodTime'),
							(value) => !!start && dayjs(value).isAfter(dayjs(start))
						);
					}),
			}),
			contactPerson: Yup.object().shape(
				{
					locale: Yup.string().required(t('errorMessage.pleaseSelect')),
					salutation: Yup.number().required(t('errorMessage.pleaseSelect')),
					firstName: Yup.string().required(t('errorMessage.required')),
					lastName: Yup.string().required(t('errorMessage.required')),

					phoneNum: Yup.string()
						.nullable()
						.when(['phoneCode', 'email'], {
							is: (phoneCode: AbkPhoneCode, email: string) =>
								phoneCode || !email,
							then: (schema) => schema.required(t('errorMessage.emailOrPhone')),
						}),
					phoneCode: Yup.object()
						.nullable()
						.when(['phoneNum', 'email'], {
							is: (phoneNum: string, email: string) => phoneNum || !email,
							then: (schema) => schema.required(t('errorMessage.emailOrPhone')),
						}),

					email: Yup.string()
						.nullable()
						.when(['phoneCode', 'phoneNum'], {
							is: (phoneCode: AbkPhoneCode, phoneNum: string) =>
								!phoneCode || !phoneNum,
							then: (schema) =>
								schema
									.required(t('errorMessage.emailOrPhone'))
									.email(t('errorMessage.email')),
						}),
				},
				[
					['phoneNum', 'phoneCode'],
					['phoneNum', 'email'],
					['phoneCode', 'email'],
				]
			),
		}),
		supplier: Yup.object().shape({
			name: Yup.string().required(t('errorMessage.required')),
			locale: Yup.string().required(t('errorMessage.pleaseSelect')),
			currency: Yup.string().required(t('errorMessage.pleaseSelect')),
			contactPoints: Yup.array().of(
				Yup.object().shape({
					department: Yup.string().required(t('errorMessage.pleaseSelect')),
					name: Yup.string().required(t('errorMessage.required')),
				})
			),
		}),
		customerPayment: Yup.object()
			.nullable()
			.when(['baseInfo.status'], {
				is: (status: number) =>
					status == AbkStatus.NEW &&
					getAbkResponse?.data.baseInfo.status === AbkStatus.ENQUIRY,
				then: (schema) =>
					schema.shape({
						payDate: Yup.string()
							.required(t('errorMessage.pleaseSelect'))
							.matches(
								NONE_INVALID_DATE_PATTERN,
								t('errorMessage.incorrectTime')
							),
						paymentLink: Yup.string()
							.nullable()
							.when('paymentMethod', {
								is: (paymentMethod: PaymentMethod) =>
									paymentMethod == PaymentMethod.CREDIT_CARD,
								then: (schema) => schema.required(t('errorMessage.required')),
							}),
					}),
			})
			.shape({
				payDate: Yup.string()
					.nullable()
					.when('furtherPayment', {
						is: true,
						then: (schema) =>
							schema
								.required(t('errorMessage.pleaseSelect'))
								.matches(
									NONE_INVALID_DATE_PATTERN,
									t('errorMessage.incorrectTime')
								),
					}),

				paymentLink: Yup.string()
					.nullable()
					.when(['paymentMethod', 'furtherPayment'], {
						is: (paymentMethod: PaymentMethod, furtherPayment: boolean) =>
							furtherPayment == true &&
							paymentMethod == PaymentMethod.CREDIT_CARD,
						then: (schema) => schema.required(t('errorMessage.required')),
					}),
			}),
		rate: Yup.object()
			.nullable()
			.when('baseInfo.status', {
				is: AbkStatus.NEW,
				then: (schema) =>
					schema.shape({
						property: Yup.object().shape({
							country: Yup.object().required(t('errorMessage.pleaseSelect')),
							majorArea: Yup.object().required(t('errorMessage.pleaseSelect')),
							area: Yup.object().required(t('errorMessage.pleaseSelect')),
							supAddress: Yup.string().required(t('errorMessage.pleaseSelect')),
							cusAddress: Yup.string().required(t('errorMessage.pleaseSelect')),
							cusName: Yup.string().required(t('errorMessage.required')),
						}),
					}),
			})
			.when('baseInfo.status', {
				is: AbkStatus.ENQUIRY,
				then: (schema) =>
					schema.shape({
						property: Yup.object().shape({
							supName: Yup.string().required(t('errorMessage.required')),
						}),
						rooms: Yup.array().of(
							Yup.object().shape({
								supName: Yup.string().required(t('errorMessage.required')),
								ratePlanSupName: Yup.string().required(
									t('errorMessage.required')
								),
								beddings: Yup.object().requireBeddings(
									t('errorMessage.atLeastOneBedType')
								),
								dailyRates: Yup.lazy((map) => {
									return Yup.object(
										yupMapRules(
											map,
											Yup.object().shape({
												supRate: Yup.string().required(
													t('errorMessage.required')
												),
											})
										)
									);
								}),
								otherMeals: Yup.array().of(
									Yup.object().shape({
										supName: Yup.string().required(t('errorMessage.required')),
										supRate: Yup.string().required(t('errorMessage.required')),
									})
								),
								otherSupplements: Yup.array().of(
									Yup.object().shape({
										supName: Yup.string().required(t('errorMessage.required')),
										supRate: Yup.string().required(t('errorMessage.required')),
									})
								),
								guests: Yup.array().of(
									Yup.object().shape({
										firstName: Yup.string().nullable(),
										lastName: Yup.string().nullable(),
										age: Yup.string()
											.nullable()
											.when('adult', {
												is: false,
												then: (schema) =>
													schema.required(t('errorMessage.required')),
											}),
									})
								),
							})
						),
						otherServices: Yup.array().of(
							Yup.object().shape({
								providedBySupplier: Yup.boolean(),
								supName: Yup.string().required(t('errorMessage.required')),
								supRate: Yup.string().required(t('errorMessage.required')),
							})
						),
					}),
			})
			.shape({
				property: Yup.object().shape({
					supName: Yup.string().required(t('errorMessage.required')),
					cusName: Yup.string().required(t('errorMessage.required')),
				}),
				rateModel: Yup.number().required(t('errorMessage.pleaseSelect')),
				commissionLevel: Yup.string()
					.nullable()
					.when('rateModel', {
						is: RateModel.MERCHANT,
						then: (schema) => schema.required(t('errorMessage.required')),
					}),
				taxRate: Yup.string()
					.nullable()
					.when('rateModel', {
						is: RateModel.MERCHANT,
						then: (schema) => schema.required(t('errorMessage.required')),
					}),
				serviceCharge: Yup.string()
					.nullable()
					.when('rateModel', {
						is: RateModel.MERCHANT,
						then: (schema) => schema.required(t('errorMessage.required')),
					}),

				rooms: Yup.array().of(
					Yup.object().shape({
						supName: Yup.string().required(t('errorMessage.required')),
						cusName: Yup.string().required(t('errorMessage.required')),
						ratePlanSupName: Yup.string().required(t('errorMessage.required')),
						ratePlanCusName: Yup.string().required(t('errorMessage.required')),
						beddings: Yup.object().requireBeddings(
							t('errorMessage.atLeastOneBedType')
						),
						dailyRates: Yup.lazy((map) => {
							return Yup.object(
								yupMapRules(
									map,
									Yup.object().shape({
										supRate: Yup.string().required(t('errorMessage.required')),
										cusRate: Yup.string().required(t('errorMessage.required')),
									})
								)
							);
						}),
						otherMeals: Yup.array().of(
							Yup.object().shape({
								supName: Yup.string().required(t('errorMessage.required')),
								cusName: Yup.string().required(t('errorMessage.required')),
								supRate: Yup.string().required(t('errorMessage.required')),
								cusRate: Yup.string().required(t('errorMessage.required')),
							})
						),
						otherSupplements: Yup.array().of(
							Yup.object().shape({
								supName: Yup.string().required(t('errorMessage.required')),
								cusName: Yup.string().required(t('errorMessage.required')),
								supRate: Yup.string().required(t('errorMessage.required')),
								cusRate: Yup.string().required(t('errorMessage.required')),
							})
						),
						guests: Yup.array().of(
							Yup.object().shape({
								firstName: Yup.string()
									.trim()
									.nullable()
									.test({
										name: 'first',
										message: t('errorMessage.required'),
										test: function (item) {
											const { options } = this as Yup.TestContext &
												ValidateOptionsExtended;
											// if not index 0 then exit we are not checking others here
											if (options.index > 0) {
												return true;
											}
											if (options.index === 0) {
												return !!item;
											}
										},
									}),
								lastName: Yup.string()
									.trim()
									.nullable()
									.test({
										name: 'first',
										message: t('errorMessage.required'),
										test: function (item) {
											const { options } = this as Yup.TestContext &
												ValidateOptionsExtended;
											// if not index 0 then exit we are not checking others here
											if (options.index > 0) {
												return true;
											}
											if (options.index === 0) {
												return !!item;
											}
										},
									}),
								age: Yup.string()
									.nullable()
									.when('adult', {
										is: false,
										then: (schema) =>
											schema.required(t('errorMessage.required')),
									}),
							})
						),
					})
				),
				otherServices: Yup.array().of(
					Yup.object().shape({
						providedBySupplier: Yup.boolean(),
						supName: Yup.string().required(t('errorMessage.required')),
						cusName: Yup.string().required(t('errorMessage.required')),
						supRate: Yup.string().required(t('errorMessage.required')),
						cusRate: Yup.string().required(t('errorMessage.required')),
					})
				),
			}),
	});

	const methods = useForm<Abk>({
		defaultValues: getAbkResponse?.data,
		resolver: yupResolver(formSchema),
		mode: 'onChange',
	});

	const onSubmit: SubmitHandler<Abk> = (data) => {
		// if (data.rate?.rooms && data.baseInfo.status === AbkStatus.ENQUIRY) {
		// 	data.rate?.rooms.forEach((room, index) => {
		// 		const keys = Object.keys(room.dailyRates);

		// 		keys.forEach((key) => {
		// 			if (
		// 				data.rate?.rooms[index].dailyRates[key].cusRate == undefined ||
		// 				Number(data.rate?.rooms[index].dailyRates[key].cusRate) === 0
		// 			) {
		// 				data.rate.rooms[index].dailyRates[key].cusRate = 0;
		// 			}
		// 		});

		// 		data.rate?.rooms[index].otherMeals.forEach((otherMeal, index) => {
		// 			if (
		// 				data.rate.rooms[index].otherMeals[index].cusRate == undefined ||
		// 				Number(data.rate.rooms[index].otherMeals[index].cusRate) === 0
		// 			) {
		// 				data.rate.rooms[index].otherMeals[index].cusRate = 0;
		// 			}
		// 		});

		// 		data.rate?.rooms[index].otherSupplements.forEach(
		// 			(otherSupplement, index) => {
		// 				if (
		// 					data.rate.rooms[index].otherSupplements[index].cusRate ==
		// 						undefined ||
		// 					Number(data.rate.rooms[index].otherSupplements[index].cusRate) ===
		// 						0
		// 				) {
		// 					data.rate.rooms[index].otherSupplements[index].cusRate = 0;
		// 				}
		// 			}
		// 		);
		// 	});
		// }

		// if (
		// 	data.rate?.otherServices &&
		// 	data.baseInfo.status === AbkStatus.ENQUIRY
		// ) {
		// 	data.rate?.otherServices.forEach((otherService, index) => {
		// 		if (
		// 			data.rate.otherServices[index].cusRate == undefined ||
		// 			Number(data.rate.otherServices[index].cusRate) === 0
		// 		) {
		// 			data.rate.otherServices[index].cusRate = 0;
		// 		}
		// 	});
		// }

		if (
			data.customerPayment &&
			data.customerPayment.furtherPayment === null &&
			(data.baseInfo.status === AbkStatus.NEW ||
				data.baseInfo.status === AbkStatus.CONFIRMED)
		) {
			data.customerPayment.furtherPayment = false;
		}

		if (data.supplier?.contactPoints) {
			data.supplier?.contactPoints
				.filter((contactPoint) => contactPoint.email === '')
				.map((item: any, index: number) => {
					item.email = undefined;
				});
		}

		if (data.customerPayment && data.baseInfo.status === AbkStatus.ENQUIRY) {
			data.customerPayment.furtherPayment = undefined;
			data.customerPayment.paymentMethod = undefined;
			data.customerPayment.paymentLink = undefined;
			data.customerPayment.payDate = undefined;
		}

		if (data.rate?.rateModel === RateModel.MERCHANT) {
			if (data.rate?.commissionTaxIncluded === null) {
				data.rate.commissionTaxIncluded = false;
			}
			if (data.rate?.commissionServiceChargeIncluded === null) {
				data.rate.commissionServiceChargeIncluded = false;
			}
		}

		if (data.rate?.rateModel === RateModel.AGENCY && data.rate?.rooms) {
			data.rate?.rooms.map((room: any) => {
				room.otherMealMerchantConfig = null;
				room.otherSupplementMerchantConfig = null;
			});
		}

		if (data.rate?.rateModel === RateModel.MERCHANT && data.rate?.rooms) {
			data.rate?.rooms.map((room: any) => {
				if (
					room.otherMealMerchantConfig.commissionApply === (undefined || null)
				) {
					room.otherMealMerchantConfig.commissionApply = false;
				}
				if (room.otherMealMerchantConfig.commissionTaxIncluded === undefined) {
					room.otherMealMerchantConfig.commissionTaxIncluded = false;
				}
				if (
					room.otherMealMerchantConfig.commissionServiceChargeIncluded ===
					undefined
				) {
					room.otherMealMerchantConfig.commissionServiceChargeIncluded = false;
				}

				if (
					room.otherSupplementMerchantConfig.commissionApply ===
					(undefined || null)
				) {
					room.otherSupplementMerchantConfig.commissionApply = false;
				}
				if (
					room.otherSupplementMerchantConfig.commissionTaxIncluded === undefined
				) {
					room.otherSupplementMerchantConfig.commissionTaxIncluded = false;
				}
				if (
					room.otherSupplementMerchantConfig.commissionServiceChargeIncluded ===
					undefined
				) {
					room.otherSupplementMerchantConfig.commissionServiceChargeIncluded =
						false;
				}
			});
		}

		if (
			data.rate?.rateModel === RateModel.AGENCY &&
			data.rate?.otherServices.length > 0
		) {
			data.rate?.otherServices.map((otherService: any) => {
				otherService.merchantConfig = null;
			});
		}

		if (
			data.rate?.rateModel === RateModel.MERCHANT &&
			data.rate?.otherServices.length > 0
		) {
			data.rate?.otherServices.map((otherService: any) => {
				if (
					otherService.merchantConfig.commissionApply === (undefined || null)
				) {
					otherService.merchantConfig.commissionApply = false;
				}
				if (otherService.merchantConfig.commissionTaxIncluded === undefined) {
					otherService.merchantConfig.commissionTaxIncluded = false;
				}
				if (
					otherService.merchantConfig.commissionServiceChargeIncluded ===
					undefined
				) {
					otherService.merchantConfig.commissionServiceChargeIncluded = false;
				}
			});
		}

		updateAbk({ abkId: abkId ?? '', body: data });
	};
	const watchCusCurrency = methods.watch('baseInfo.contactPerson.currency');
	const watchSupCurrency = methods.watch('supplier.currency');
	const watchStatus = methods.watch('baseInfo.status');

	const isAmendment =
		getAbkResponse?.data.baseInfo.status === AbkStatus.NEW ||
		getAbkResponse?.data.baseInfo.status === AbkStatus.CONFIRMED;

	useEffect(() => {
		if (type !== FormCardType.specialEdit && isGetAbkSuccess) {
			methods.setValue('baseInfo.status', getAbkResponse?.data.baseInfo.status);
		} else if (type === FormCardType.specialEdit) {
			methods.setValue('baseInfo.status', AbkStatus.NEW);
		}
	}, [type, isGetAbkSuccess]);

	useEffect(() => {
		if (isGetAbkSuccess && type === FormCardType.specialEdit) {
			methods.trigger();
		}
		if (abkSubmitted) {
			methods.trigger();
		}
	}, [isGetAbkSuccess, type, watchStatus]);

	let pageContent;
	if (isGetAbkLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetAbkSuccess) {
		pageContent = (
			<>
				<AbkIdContext.Provider value={abkId ?? ''}>
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							{(type === FormCardType.edit ||
								type === FormCardType.specialEdit) &&
								getAbkResponse?.data.baseInfo.status === AbkStatus.ENQUIRY && (
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											marginBottom: '10px',
										}}
									>
										<Typography
											fontSize={'14px'}
											fontWeight={700}
											paddingRight={'10px'}
										>
											{t('abk.SelectEditingMode')}
										</Typography>
										<AppToggleButton
											name='baseInfo.status'
											options={AbkEditOptions}
											control={methods.control}
											formLabel={''}
										/>
									</Box>
								)}
							<Stack spacing='16px'>
								<Grid container alignItems='stretch' spacing={'10px'}>
									<Grid item xs={6} display='flex'>
										<BaseInfoCard initialType={type} />
									</Grid>
									<Grid
										item
										xs={6}
										display='flex'
										flexDirection={'column'}
										gap={'16px'}
									>
										<SupplierInfoCard initialType={type} />
										<CustomerPaymentInfoCard
											initialType={type}
											isAmendment={isAmendment}
										/>
									</Grid>
									<Grid item xs={12} display='flex'>
										<RateInfoCard
											initialType={type}
											cusCurrency={watchCusCurrency}
											supCurrency={watchSupCurrency}
										/>
									</Grid>
								</Grid>
								{(type === FormCardType.edit ||
									type === FormCardType.specialEdit) && (
									<Stack spacing='16px'>
										<Grid item xs={12}>
											<Stack direction='row-reverse' spacing='12px'>
												<Button
													type='submit'
													variant='contained'
													color='success'
													onClick={() => setAbkSubmitted(true)}
												>
													{t('button.Save')}
												</Button>
												<Button
													variant='outlined'
													color='info'
													onClick={() => {
														methods.reset();
														methods.setValue('rate', getAbkResponse?.data.rate);
														setType(FormCardType.view);
													}}
												>
													{t('button.Discard')}
												</Button>
											</Stack>
										</Grid>
									</Stack>
								)}
							</Stack>
						</form>
					</FormProvider>
				</AbkIdContext.Provider>
			</>
		);
	} else if (isGetAbkError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}

import { Box, Typography } from '@mui/material';
import CountryFlag from './CountryFlag';
import NewDisplayText from './NewDisplayText';

interface PhoneNumberDisplayProps {
    iso2: string;
    phoneCode: string;
    phoneNum: string;
}

export default function PhoneNumberDisplay(props: PhoneNumberDisplayProps) {
    const phoneNumber =  '+' + props.phoneCode + ' ' + props.phoneNum

    return(
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            {props.phoneCode && props.phoneNum  && <CountryFlag
                iso2={props.iso2}
                size='small'
            />}

            <Typography fontSize='12px' color='#2C2C2C'>
                {props.phoneCode && props.phoneNum ? phoneNumber : '-'}
            </Typography>

        </Box>
    )
}

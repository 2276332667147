import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';
import { StyledTextField } from '../../../../common/components/styled/StyledTextField';
import { Transport } from '../../../../api/DTO/transportation.interface';
import { useTranslation } from 'react-i18next';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';

interface UploadedImageWithTitleAndDesProps {
	index: number;
	value: Transport;
	onChange: (value: Transport) => void;
	deleteOnClick: (index: number) => void;
	fieldState?: any;
}

export default function UploadedImageWithTitleAndDes({
	index,
	value,
	onChange,
	deleteOnClick,
	fieldState,
}: UploadedImageWithTitleAndDesProps) {
	const { t } = useTranslation();

	const handleTitleChange = (newTitle: string) => {
		const onChangeValue = value;
		onChangeValue.title = newTitle;
		onChange(onChangeValue);
	};
	const handleDescriptionChange = (newDescription: string) => {
		const onChangeValue = value;
		onChangeValue.description = newDescription;
		onChange(onChangeValue);
	};

	return (
		<>
			<Box
				position={'relative'}
				marginBottom='20px'
				padding='20px'
				borderRadius={'10px'}
				sx={{ background: '#f2faff' }}
			>
				<AppSubtitleLayout label={t('place.LocalPhoto') + ` (${index + 1})`} />
				<Box
					sx={{
						display: 'flex',
						gap: '20px',
						marginTop: '10px',
						marginBottom: '10px',
					}}
				>
					<Box>
						<Typography width={'160px'} fontSize={'12px'} color='#808080'>
							{t('place.Media')}
						</Typography>
					</Box>

					<Grid container columnSpacing={'20px'}>
						<Grid item xs sx={{ maxWidth: '100%' }}>
							<img
								style={{
									width: '100%',
									maxWidth: '400px',
									borderRadius: '10px',
								}}
								src={value.url}
							/>
						</Grid>
					</Grid>
				</Box>
				<AppInputLayout
					label={t('transportation.TitleInChi')}
					required={true}
					errorMessage={fieldState.error?.[index]?.title.message}
				>
					<StyledTextField
						value={value.title ?? ''}
						placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
						error={!!fieldState.error?.[index]?.title.message}
						onChange={(e) => {
							handleTitleChange(e.target.value);
						}}
					/>
				</AppInputLayout>
				<AppInputLayout
					label={t('transportation.DescriptionInChi')}
					errorMessage={fieldState.error?.[index]?.description?.message}
				>
					<StyledTextField
						value={value.description ?? ''}
						error={!!fieldState.error?.[index]?.description?.message}
						onChange={(e) => {
							handleDescriptionChange(e.target.value);
						}}
						placeholder={t('placeholder.pleaseEnterTraditionalChinese')}
					/>
				</AppInputLayout>
				<Box
					sx={{
						position: 'absolute',
						top: 10,
						right: 20,
					}}
				>
					<DeleteIcon
						color='#F6403F'
						cursor={'pointer'}
						onClick={() => deleteOnClick(index)}
					/>
				</Box>
			</Box>
		</>
	);
}

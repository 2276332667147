import {
	useUploadRoomImagesMutation,
	useDeleteRoomImageMutation,
	useRepositionRoomImagesMutation,
	useSelectRoomImageMutation,
} from '../../../../api/accommodationApiSlice';
import { Reposition, Image } from '../../../../api/DTO/gallery.interface';
import { useEffect, useState } from 'react';

import { arrayMove } from '@dnd-kit/sortable';
import DndImageListSection from './DndImageListSection';
import {
	showModal,
	hideModal,
} from '../../../../common/components/modal/modalSlice';
import { useAppDispatch } from '../../../../redux-hooks';

interface RoomImageListSectionProps {
	image: Image[];
	roomId: string;
}

export default function RoomImageListSection({
	image,
	roomId,
}: RoomImageListSectionProps) {
	const [
		uploadRoomImagesMutation,
		{ data: uploadRoomImagesMutationResponse, isSuccess: isUploadSuccess },
	] = useUploadRoomImagesMutation();

	const [deleteRoomImageMutation] = useDeleteRoomImageMutation();

	const [repositionRoomImagesMutation] = useRepositionRoomImagesMutation();

	const [
		selectRoomImageMutation,
		{ data: selectRoomImageMutationResponse, isSuccess: isSelectSuccess },
	] = useSelectRoomImageMutation();

	const [imageArray, setImageArray] = useState<Image[]>(image);

	useEffect(() => {
		if (isUploadSuccess) {
			setImageArray(uploadRoomImagesMutationResponse?.data ?? image);
		}
	}, [isUploadSuccess]);

	useEffect(() => {
		if (isSelectSuccess) {
			const updatedArray = imageArray.map((image) => {
				if (image.id === selectRoomImageMutationResponse?.data.id) {
					return selectRoomImageMutationResponse?.data;
				}
				return image;
			});
			setImageArray(updatedArray);
		}
	}, [isSelectSuccess]);

	const dispatch = useAppDispatch();

	function handleDeleteClick(id: string, index: number) {
		deleteRoomImageMutation(id).then((res: any) => {
			if (res.data.success == true) {
				const list = [...imageArray];
				list.splice(index, 1);
				setImageArray(list);
				dispatch(hideModal());
			}
		});
	}

	function handleDeleteImage(id: string, index: number) {
		dispatch(
			showModal({
				modalType: 'DELETE_GALLERY_IMAGE',
				modalProps: {
					id,
					index,
					handleDeleteClick,
				},
			})
		);
	}

	function handleSelectImage(id: string) {
		selectRoomImageMutation(id);
	}

	function onUploadFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		const target = event.target;
		const file = target.files?.[0];

		if (file) {
			uploadRoomImagesMutation({
				roomId: roomId ?? '',
				file: file,
			});
		}
	}

	const handleRepositionImage = (
		activeIndex: number,
		overIndex: number,
		activeItem: Image
	) => {
		const position: Reposition = {
			newPosition: overIndex,
		};
		repositionRoomImagesMutation({
			room_image_id: activeItem.id,
			body: position,
		}).then((res: any) => {
			if (res.data.success == true) {
				setImageArray((prev) => arrayMove<Image>(prev, activeIndex, overIndex));
			}
		});
	};

	return (
		<>
			<DndImageListSection
				imageArray={imageArray}
				handleDeleteImage={handleDeleteImage}
				onUploadFileChange={onUploadFileChange}
				handleRepositionImage={handleRepositionImage}
				handleSelectImage={handleSelectImage}
			/>
		</>
	);
}

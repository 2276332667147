import {
	Box,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { getAbkSupplierEmailDisplay } from '../../../api/enum/abkSupplierEmail';
import { ReactComponent as ExpandMoreIcon } from '../../../assets/icon/arrow-right.svg';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../utils/dateHelper';

interface SupplierEmailListDisplayProps {
	data: any;
	onClick: (email: number) => void;
}

export default function SupplierEmailListDisplay({
	data,
	onClick,
}: SupplierEmailListDisplayProps) {
	return (
		<List component='nav' aria-label='mailbox folders' sx={{ paddingTop: 0 }}>
			{data.map((item: any, index: number) => {
				return (
					<ListItemButton
						divider
						key={index}
						disabled={!item.sendable}
						onClick={() => onClick(item.email)}
					>
						<ListItemText
							primary={
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Typography
										color={'#2C2C2C'}
										fontSize={'14px'}
										fontWeight={700}
									>
										{getAbkSupplierEmailDisplay(item.email).name}
									</Typography>
									<Typography
										color={'#4EA4D3'}
										fontSize={'12px'}
										fontWeight={450}
									>
										{`Last sent: ${
											item.lastSent
												? dayjs(item.lastSent).format(DISPLAY_DATE_TIME_FORMAT)
												: '-'
										}`}
									</Typography>
								</Box>
							}
						/>

						<ExpandMoreIcon />
					</ListItemButton>
				);
			})}
		</List>
	);
}

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Option from '../common/types/option.type';
import { apiSlice } from './apiSlice';
import { SuccessResponse } from './response/shared/success.response';

const SERVICE_PATH = '/location-service';

export const locationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCountries: builder.query<SuccessResponse<Option[]>, void>({
			query: () => `${SERVICE_PATH}/admin/countries`,
		}),

		getMajorAreas: builder.query<SuccessResponse<Option[]>, string>({
			query: (countryId) => ({
				url: `${SERVICE_PATH}/admin/countries/${countryId}/major-areas`,
			}),
		}),

		getAreas: builder.query<SuccessResponse<Option[]>, string>({
			query: (majorAreaId) => ({
				url: `${SERVICE_PATH}/admin/major-areas/${majorAreaId}/areas`,
			}),
		}),

		getMultiLangCountries: builder.query<SuccessResponse<Option[]>, void>({
			async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
				const targetUrl = `${SERVICE_PATH}/admin/countries`;

				const engResponse = await fetchWithBQ({
					url: targetUrl,
					headers: {
						'Accept-Language': 'en-US',
					},
				});
				if (engResponse.error) throw engResponse.error;
				const chiResponse = await fetchWithBQ({
					url: targetUrl,
					headers: {
						'Accept-Language': 'zh-HK',
					},
				});

				const combineMutliLangResponse = combineMultiLangResponse(
					engResponse.data as SuccessResponse<Option[]>,
					chiResponse.data as SuccessResponse<Option[]>
				);

				return combineMutliLangResponse
					? { data: combineMutliLangResponse }
					: { error: chiResponse.error as FetchBaseQueryError };
			},
		}),

		getMultiLangMajorAreas: builder.query<SuccessResponse<Option[]>, string>({
			async queryFn(countryId, _queryApi, _extraOptions, fetchWithBQ) {
				const targetUrl = `${SERVICE_PATH}/admin/countries/${countryId}/major-areas`;

				const engResponse = await fetchWithBQ({
					url: targetUrl,
					headers: {
						'Accept-Language': 'en-US',
					},
				});
				if (engResponse.error) throw engResponse.error;
				const chiResponse = await fetchWithBQ({
					url: targetUrl,
					headers: {
						'Accept-Language': 'zh-HK',
					},
				});

				const combineMutliLangResponse = combineMultiLangResponse(
					engResponse.data as SuccessResponse<Option[]>,
					chiResponse.data as SuccessResponse<Option[]>
				);

				return combineMutliLangResponse
					? { data: combineMutliLangResponse }
					: { error: chiResponse.error as FetchBaseQueryError };
			},
		}),

		getMultiLangArea: builder.query<SuccessResponse<Option[]>, string>({
			async queryFn(majorAreaId, _queryApi, _extraOptions, fetchWithBQ) {
				const targetUrl = `${SERVICE_PATH}/admin/major-areas/${majorAreaId}/areas`;

				const engResponse = await fetchWithBQ({
					url: targetUrl,
					headers: {
						'Accept-Language': 'en-US',
					},
				});
				if (engResponse.error) throw engResponse.error;
				const chiResponse = await fetchWithBQ({
					url: targetUrl,
					headers: {
						'Accept-Language': 'zh-HK',
					},
				});

				const combineMutliLangResponse = combineMultiLangResponse(
					engResponse.data as SuccessResponse<Option[]>,
					chiResponse.data as SuccessResponse<Option[]>
				);

				return combineMutliLangResponse
					? { data: combineMutliLangResponse }
					: { error: chiResponse.error as FetchBaseQueryError };
			},
		}),

		getPhoneCodes: builder.query<SuccessResponse<Option[]>, void>({
			query: () => `${SERVICE_PATH}/admin/countries/phone-codes`,
		}),
	}),
});

function combineMultiLangResponse(
	engResponse: SuccessResponse<Option[]>,
	chiResponse: SuccessResponse<Option[]>
): SuccessResponse<Option[]> {
	const engOptions = (engResponse as SuccessResponse<Option[]>).data;
	const chiOptions = (chiResponse as SuccessResponse<Option[]>).data;

	const chiMap = chiOptions.reduce(
		(map, item) => map.set(item.id, item.name),
		new Map()
	);

	const combinedOptions = engOptions.map((item) =>
		Object.assign(item, {
			name: item.name + ' ' + chiMap.get(item.id),
		})
	);

	const newResponse = engResponse;
	newResponse.data = combinedOptions;

	return newResponse;
}

export const {
	useGetCountriesQuery,
	useLazyGetMajorAreasQuery,
	useLazyGetAreasQuery,
	useGetMultiLangCountriesQuery,
	useLazyGetMultiLangMajorAreasQuery,
	useLazyGetMultiLangAreaQuery,
	useGetPhoneCodesQuery,
} = locationApiSlice;

import { GridRowsProp } from '@mui/x-data-grid';
import { RatePlanCalendar } from '../../../../api/DTO/propertyCalendar.interface';
import { RateModel } from '../../../../api/enum/rateModel.enum';
import { PriceMode } from '../../../../api/enum/priceMode.enum';
import { PerPersonRateType } from './PerPersonRateToggle';
import { RatePlanChannel } from '../../../../api/enum/ratePlanChannel.enum';
import { CellType } from '../CellType.enum';

export enum RatePlanRow {
	STATUS = 'STATUS',
	ROOM_RATE = 'ROOM_RATE',
	AGENCY_COMMISSION_LEVEL = 'AGENCY_COMMISSION_LEVEL',
	RATE_PLAN_SUPPLEMENT = 'RATE_PLAN_SUPPLEMENT',
	SUPPLIER_RATE = 'SUPPLIER_RATE',
	MARKUP_PRECENTAGE = 'MARKUP_PRECENTAGE',
	MARKUP_AMOUNT = 'MARKUP_AMOUNT',
	SELLING_RATE = 'SELLING_RATE',
	PER_PERSON_RATE = 'PER_PERSON_RATE', // for Per Person Edit Rate
	PER_PERSON_NETT = 'PER_PERSON_NETT', // for Per Person NETT
	PER_PERSON_SELLING_RATE = 'PER_PERSON_SELLING_RATE', // for Per Person Selling Rate
	ALLOTMENT = 'ALLOTMENT',
	REMAINING_ALLOTMENT = 'REMAINING_ALLOTMENT',
	ON_REQUEST_ALLOTMENT = 'ON_REQUEST_ALLOTMENT',
	CONDITION = 'CONDITION',
}

interface RowConfig {
	id: RatePlanRow;
	name: string;
	hint?: string;
	numOfAdult?: number;
	cellType?: CellType;
}

function getDirectPerRoomAgencyRows(sellAtOfficialRate?: boolean): RowConfig[] {
	let rows: RowConfig[] = [];

	rows = rows.concat([
		{
			id: RatePlanRow.STATUS,
			name: 'Status',
			cellType: CellType.RATE_PLAN_STATUS,
		},
		{
			id: RatePlanRow.ROOM_RATE,
			name: 'Base Rate', // called "Base Rate" on purpose
			cellType: CellType.NON_EDITABLE_TEXT_CELL,
			hint: 'Edit or update under Room Type level [Edit Base Rate] showing above the rate plans. For NETT Model, Base Rate is equal to NETT.',
		},
		{
			id: RatePlanRow.AGENCY_COMMISSION_LEVEL,
			name: 'Commission Level',
			cellType: CellType.NON_EDITABLE_TEXT_CELL,
			hint: 'Applicable to Base Rate & Rate Plan Supplement',
		},
		{
			id: RatePlanRow.RATE_PLAN_SUPPLEMENT,
			name: 'Rate Plan Supplement',
			cellType: CellType.RANGE_SELECTION_TEXT_CELL,
			hint: 'Editable.',
		},
		{
			id: RatePlanRow.SUPPLIER_RATE,
			name: 'NETT',
			cellType: CellType.NON_EDITABLE_TEXT_CELL,
			hint: 'Payment to Supplier: Base Rate (NETT or after commission) + Rate Plan Supplement + Room Supplement (*If any).',
		},
	]);

	if (sellAtOfficialRate) {
		// If sell at official rate, markup are 0. Then no need to display
		rows = rows.concat([
			{
				id: RatePlanRow.SELLING_RATE,
				name: 'Official Selling Rate',
				cellType: CellType.NON_EDITABLE_TEXT_CELL,
				hint: 'This property sells at official rate.',
			},
		]);
	} else {
		rows = rows.concat([
			{
				id: RatePlanRow.MARKUP_PRECENTAGE,
				name: 'Markup %',
				cellType: CellType.RANGE_SELECTION_TEXT_CELL,
			},
			{
				id: RatePlanRow.MARKUP_AMOUNT,
				name: 'Markup Amount',
				cellType: CellType.RANGE_SELECTION_TEXT_CELL,
			},
			{
				id: RatePlanRow.SELLING_RATE,
				name: 'Selling Rate',
				cellType: CellType.RANGE_SELECTION_TEXT_CELL,
				hint: '= Base Rate + Rate Plan Supplement (*If any) + Room Supplement (*If any) + Markup Amount',
			},
		]);
	}

	rows = rows.concat([
		{
			id: RatePlanRow.ALLOTMENT,
			name: 'Sold / Allotment',
			cellType: CellType.RANGE_SELECTION_TEXT_CELL,
			hint: 'Enter an increment to the current allotment. If enter 5, the allotment will be increased by 5. If enter -5, the allotment will be decreased by 5.',
		},
		{
			id: RatePlanRow.REMAINING_ALLOTMENT,
			name: 'Remaining Allotment',
			cellType: CellType.NON_EDITABLE_TEXT_CELL,
			hint: 'Minimum of Room Allotment & Rate plan Allotment. i.e. If Remaining Allotment is 5, this rate plan can sell up to 5 rooms. If If Remaining Allotment is 0, rate plan can not be sold.',
		},
		{
			id: RatePlanRow.ON_REQUEST_ALLOTMENT,
			name: 'On Request Allotment',
			cellType: CellType.RANGE_SELECTION_TEXT_CELL,
			hint: 'Enter an increment to the current on request allotment. If the on request allotment is larger than 0, the rate plan is still sellable, but the conditions will change to “On Request” when the remaining allotment sold out.',
		},
		{
			id: RatePlanRow.CONDITION,
			name: 'Condition',
			cellType: CellType.RATE_PLAN_CONDITION,
		},
	]);

	return rows;
}

const directPerRoomCommissionableRows: RowConfig[] = [
	{
		id: RatePlanRow.STATUS,
		name: 'Status',
		cellType: CellType.RATE_PLAN_STATUS,
	},
	{
		id: RatePlanRow.SELLING_RATE,
		name: 'Selling Rate',
		cellType: CellType.NON_EDITABLE_TEXT_CELL,
		hint: '= Base Rate + Rate Plan Supplement (*If any) + Room Supplement (*If any) + Tax (*Apply to relative regions and if Tax Included in Overview is enabled) + Service Charge (if Service Charge Included in Overview is enabled)',
	},
	{
		id: RatePlanRow.ROOM_RATE,
		name: 'Base Rate', // called "Base Rate" on purpose
		cellType: CellType.NON_EDITABLE_TEXT_CELL,
		hint: 'Edit or update under Room Type level [Edit Base Rate] showing above the rate plans. For Commissionable Model, Base Rate is equal to Publish Rate before tax and service charge.',
	},
	{
		id: RatePlanRow.RATE_PLAN_SUPPLEMENT,
		name: 'Rate Plan Supplement',
		cellType: CellType.RANGE_SELECTION_TEXT_CELL,
		hint: 'Editable.',
	},
	{
		id: RatePlanRow.ALLOTMENT,
		name: 'Sold / Allotment',
		cellType: CellType.RANGE_SELECTION_TEXT_CELL,
		hint: 'Enter an increment to the current allotment. If enter 5, the allotment will be increased by 5. If enter -5, the allotment will be decreased by 5.',
	},
	{
		id: RatePlanRow.REMAINING_ALLOTMENT,
		name: 'Remaining Allotment',
		cellType: CellType.NON_EDITABLE_TEXT_CELL,
		hint: 'Minimum of Room Allotment & Rate plan Allotment. i.e. If Remaining Allotment is 5, this rate plan can sell up to 5 rooms. If If Remaining Allotment is 0, rate plan can not be sold.',
	},
	{
		id: RatePlanRow.ON_REQUEST_ALLOTMENT,
		name: 'On Request Allotment',
		cellType: CellType.RANGE_SELECTION_TEXT_CELL,
		hint: 'Enter an increment to the current on request allotment. If the on request allotment is larger than 0, the rate plan is still sellable, but the conditions will change to “On Request” when the remaining allotment sold out.',
	},
	{
		id: RatePlanRow.CONDITION,
		name: 'Condition',
		cellType: CellType.RATE_PLAN_CONDITION,
	},
];

function getDirectPerPersonAgencyRows(
	perPersonRateType: PerPersonRateType,
	maxAdultCapacity: number,
	sellAtOfficialRate?: boolean
): RowConfig[] {
	let rows: RowConfig[] = [];

	rows.push({
		id: RatePlanRow.STATUS,
		name: 'Status',
		cellType: CellType.RATE_PLAN_STATUS,
	});

	rows = rows.concat(getPerPersonRateRows(perPersonRateType, maxAdultCapacity));

	rows = rows.concat([
		{
			id: RatePlanRow.AGENCY_COMMISSION_LEVEL,
			name: 'Commission Level',
			cellType: CellType.NON_EDITABLE_TEXT_CELL,
			hint: 'Applicable to Base Rate & Rate Plan Supplement',
		},
	]);

	if (sellAtOfficialRate) {
		rows = rows.concat([
			{
				id: RatePlanRow.MARKUP_PRECENTAGE,
				name: 'Markup %',
				cellType: CellType.NON_EDITABLE_TEXT_CELL,
			},
		]);
	} else {
		rows = rows.concat([
			{
				id: RatePlanRow.MARKUP_PRECENTAGE,
				name: 'Markup %',
				cellType: CellType.RANGE_SELECTION_TEXT_CELL,
			},
		]);
	}

	rows = rows.concat([
		{
			id: RatePlanRow.ALLOTMENT,
			name: 'Sold / Allotment',
			cellType: CellType.RANGE_SELECTION_TEXT_CELL,
			hint: 'Enter an increment to the current allotment. If enter 5, the allotment will be increased by 5. If enter -5, the allotment will be decreased by 5.',
		},
		{
			id: RatePlanRow.REMAINING_ALLOTMENT,
			name: 'Remaining Allotment',
			cellType: CellType.NON_EDITABLE_TEXT_CELL,
			hint: 'Minimum of Room Allotment & Rate plan Allotment. i.e. If Remaining Allotment is 5, this rate plan can sell up to 5 rooms. If If Remaining Allotment is 0, rate plan can not be sold.',
		},
		{
			id: RatePlanRow.ON_REQUEST_ALLOTMENT,
			name: 'On Request Allotment',
			cellType: CellType.RANGE_SELECTION_TEXT_CELL,
			hint: 'Enter an increment to the current on request allotment. If the on request allotment is larger than 0, the rate plan is still sellable, but the conditions will change to “On Request” when the remaining allotment sold out.',
		},
		{
			id: RatePlanRow.CONDITION,
			name: 'Condition',
			cellType: CellType.RATE_PLAN_CONDITION,
		},
	]);

	return rows;
}

function getDirectPerPersonMerchantRows(
	perPersonRateType: PerPersonRateType,
	maxAdultCapacity: number
): RowConfig[] {
	let rows: RowConfig[] = [];

	rows.push({
		id: RatePlanRow.STATUS,
		name: 'Status',
		cellType: CellType.RATE_PLAN_STATUS,
	});

	rows = rows.concat(getPerPersonRateRows(perPersonRateType, maxAdultCapacity));

	rows = rows.concat([
		{
			id: RatePlanRow.ALLOTMENT,
			name: 'Sold / Allotment',
			cellType: CellType.RANGE_SELECTION_TEXT_CELL,
			hint: 'Enter an increment to the current allotment. If enter 5, the allotment will be increased by 5. If enter -5, the allotment will be decreased by 5.',
		},
		{
			id: RatePlanRow.REMAINING_ALLOTMENT,
			name: 'Remaining Allotment',
			cellType: CellType.NON_EDITABLE_TEXT_CELL,
			hint: 'Minimum of Room Allotment & Rate plan Allotment. i.e. If Remaining Allotment is 5, this rate plan can sell up to 5 rooms. If If Remaining Allotment is 0, rate plan can not be sold.',
		},
		{
			id: RatePlanRow.ON_REQUEST_ALLOTMENT,
			name: 'On Request Allotment',
			cellType: CellType.RANGE_SELECTION_TEXT_CELL,
			hint: 'Enter an increment to the current on request allotment. If the on request allotment is larger than 0, the rate plan is still sellable, but the conditions will change to “On Request” when the remaining allotment sold out.',
		},
		{
			id: RatePlanRow.CONDITION,
			name: 'Condition',
			cellType: CellType.RATE_PLAN_CONDITION,
		},
	]);

	return rows;
}

function getPerPersonRateRows(
	perPersonRateType: PerPersonRateType,
	maxAdultCapacity: number
): RowConfig[] {
	switch (perPersonRateType) {
		case PerPersonRateType.EDIT_RATE: {
			const rows: RowConfig[] = [];
			for (let i = 1; i <= maxAdultCapacity; i++) {
				const row = {
					id: RatePlanRow.PER_PERSON_RATE,
					name: `Official Rate per Adult: ${i} Adult${i > 1 ? 's' : ''}`,
					cellType: CellType.RANGE_SELECTION_TEXT_CELL,
					numOfAdult: i,
				};
				rows.push(row);
			}
			return rows;
		}
		case PerPersonRateType.NETT: {
			const rows: RowConfig[] = [];
			for (let i = 1; i <= maxAdultCapacity; i++) {
				const row = {
					id: RatePlanRow.PER_PERSON_NETT,
					name: `NETT per ${i} Adult${i > 1 ? 's' : ''}`,
					cellType: CellType.NON_EDITABLE_TEXT_CELL,
					numOfAdult: i,
				};
				rows.push(row);
			}
			return rows;
		}
		case PerPersonRateType.SELLING_RATE: {
			const rows: RowConfig[] = [];
			for (let i = 1; i <= maxAdultCapacity; i++) {
				const row = {
					id: RatePlanRow.PER_PERSON_SELLING_RATE,
					name: `Selling Rate per ${i} Adult${i > 1 ? 's' : ''}`,
					cellType: CellType.NON_EDITABLE_TEXT_CELL,
					numOfAdult: i,
				};
				rows.push(row);
			}
			return rows;
		}
	}
}

const apiSupplierPerRoomAgencyRows: RowConfig[] = [
	{
		id: RatePlanRow.STATUS,
		name: 'Status',
		cellType: CellType.RATE_PLAN_STATUS,
	},
	{
		id: RatePlanRow.ROOM_RATE,
		name: 'Base Rate', // called "Base Rate" on purpose
		cellType: CellType.NON_EDITABLE_TEXT_CELL,
		hint: 'Rate returns from API.',
	},
	{
		id: RatePlanRow.MARKUP_PRECENTAGE,
		name: 'Markup %',
		cellType: CellType.RANGE_SELECTION_TEXT_CELL,
	},
	{
		id: RatePlanRow.MARKUP_AMOUNT,
		name: 'Markup Amount',
		cellType: CellType.NON_EDITABLE_TEXT_CELL,
	},
	{
		id: RatePlanRow.SELLING_RATE,
		name: 'Selling Rate',
		cellType: CellType.NON_EDITABLE_TEXT_CELL,
		hint: '= Base Rate + Markup Amount',
	},
	{
		id: RatePlanRow.CONDITION,
		name: 'Condition',
		cellType: CellType.RATE_PLAN_CONDITION,
	},
];

const apiSupplierPerRoomMerchantRows: RowConfig[] = [
	{
		id: RatePlanRow.STATUS,
		name: 'Status',
		cellType: CellType.RATE_PLAN_STATUS,
	},
	{
		id: RatePlanRow.SELLING_RATE,
		name: 'Selling Rate',
		cellType: CellType.NON_EDITABLE_TEXT_CELL,
		hint: '= Base Rate',
	},
	{
		id: RatePlanRow.ROOM_RATE,
		name: 'Base Rate', // called "Base Rate" on purpose
		cellType: CellType.NON_EDITABLE_TEXT_CELL,
		hint: 'Rate returns from API.',
	},
	{
		id: RatePlanRow.CONDITION,
		name: 'Condition',
		cellType: CellType.RATE_PLAN_CONDITION,
	},
];

export function getRatePlanRows(
	channel: RatePlanChannel,
	rateModel: RateModel,
	enableSupplement: boolean,
	priceMode: PriceMode,
	perPersonRateType: PerPersonRateType,
	maxAdultCapacity: number,
	sellAtOfficialRate?: boolean
): RowConfig[] {
	let rows: RowConfig[] = [];

	if (
		priceMode === PriceMode.PER_ROOM &&
		rateModel === RateModel.AGENCY &&
		channel === RatePlanChannel.DIRECT
	)
		rows = getDirectPerRoomAgencyRows(sellAtOfficialRate);
	if (
		priceMode === PriceMode.PER_ROOM &&
		rateModel === RateModel.MERCHANT &&
		channel === RatePlanChannel.DIRECT
	)
		rows = directPerRoomCommissionableRows;
	if (
		priceMode === PriceMode.PER_PERSON &&
		rateModel === RateModel.AGENCY &&
		channel === RatePlanChannel.DIRECT
	) {
		rows = getDirectPerPersonAgencyRows(
			perPersonRateType,
			maxAdultCapacity,
			sellAtOfficialRate
		);
	}

	if (
		priceMode === PriceMode.PER_PERSON &&
		rateModel === RateModel.MERCHANT &&
		channel === RatePlanChannel.DIRECT
	) {
		rows = getDirectPerPersonMerchantRows(perPersonRateType, maxAdultCapacity);
	}
	if (
		priceMode === PriceMode.PER_ROOM &&
		rateModel === RateModel.AGENCY &&
		channel === RatePlanChannel.APISUPPLIER
	) {
		rows = apiSupplierPerRoomAgencyRows;
	}
	if (
		priceMode === PriceMode.PER_ROOM &&
		rateModel === RateModel.MERCHANT &&
		channel === RatePlanChannel.APISUPPLIER
	) {
		rows = apiSupplierPerRoomMerchantRows;
	}

	if (!enableSupplement) {
		rows = rows.filter((item) => item.id !== RatePlanRow.RATE_PLAN_SUPPLEMENT);
	}

	return rows;
}

export function transformApiRatePlanDataToGridRowsProp(
	rateModel: RateModel,
	ratePlan: RatePlanCalendar,
	priceMode: PriceMode,
	perPersonRateType: PerPersonRateType,
	maxAdultCapacity: number,
	sellAtOfficialRate?: boolean
): GridRowsProp {
	return getRatePlanRows(
		ratePlan.channel,
		rateModel,
		ratePlan.enableSupplement,
		priceMode,
		perPersonRateType,
		maxAdultCapacity,
		sellAtOfficialRate
	).map((row) => {
		const rowObject: { [key: string]: any } = {
			id: `${row.id}${row.numOfAdult ? `_${row.numOfAdult}` : ''}`,
			numOfAdult: row.numOfAdult,
			cellType: row.cellType,
		};

		Object.keys(ratePlan.dailyRatePlanConfigs).map((item) => {
			switch (row.id) {
				case RatePlanRow.STATUS: {
					rowObject[item] = ratePlan.dailyRatePlanConfigs[item].status;
					break;
				}

				case RatePlanRow.ROOM_RATE: {
					rowObject[item] = ratePlan.dailyRatePlanConfigs[item].roomRate;
					break;
				}

				// per person rate

				case RatePlanRow.PER_PERSON_RATE: {
					const perPersonRates =
						ratePlan.dailyRatePlanConfigs[item].perPersonRates;

					if (perPersonRates && row.numOfAdult) {
						const rate = perPersonRates[row.numOfAdult];
						rowObject[item] = `${rate ?? ''}`;
					}
					break;
				}

				case RatePlanRow.PER_PERSON_NETT: {
					const perPersonSupplierRates =
						ratePlan.dailyRatePlanConfigs[item].perPersonSupplierRates;

					if (perPersonSupplierRates && row.numOfAdult) {
						const rate = perPersonSupplierRates[row.numOfAdult];
						rowObject[item] = `${rate ?? '-'}`;
					}
					break;
				}

				case RatePlanRow.PER_PERSON_SELLING_RATE: {
					const perPersonSellingRates =
						ratePlan.dailyRatePlanConfigs[item].perPersonSellingRates;

					if (perPersonSellingRates && row.numOfAdult) {
						const rate = perPersonSellingRates[row.numOfAdult];
						rowObject[item] = `${rate ?? '-'}`;
					}
					break;
				}

				//

				case RatePlanRow.RATE_PLAN_SUPPLEMENT: {
					rowObject[item] = `${
						ratePlan.dailyRatePlanConfigs[item].supplement ?? ''
					}`;
					break;
				}

				case RatePlanRow.AGENCY_COMMISSION_LEVEL: {
					rowObject[item] = `${
						ratePlan.dailyRatePlanConfigs[item]
							.agencyCommissionLevelPercentage ?? '-'
					} ${
						ratePlan.dailyRatePlanConfigs[item].agencyCommissionLevelPercentage
							? '%'
							: ''
					}`;
					break;
				}

				case RatePlanRow.SUPPLIER_RATE: {
					rowObject[item] = `${
						ratePlan.dailyRatePlanConfigs[item].supplierRate ?? '-'
					}`;
					break;
				}

				case RatePlanRow.MARKUP_PRECENTAGE: {
					rowObject[item] = `${
						ratePlan.dailyRatePlanConfigs[item].markupPercentage ?? ''
					} ${ratePlan.dailyRatePlanConfigs[item].markupPercentage ? '%' : ''}`;
					break;
				}

				case RatePlanRow.MARKUP_AMOUNT: {
					rowObject[item] = `${
						ratePlan.dailyRatePlanConfigs[item].markupAmount ?? ''
					}`;
					break;
				}

				case RatePlanRow.SELLING_RATE: {
					rowObject[item] = `${
						ratePlan.dailyRatePlanConfigs[item].sellingRate ?? ''
					}`;
					break;
				}

				case RatePlanRow.ALLOTMENT: {
					rowObject[item] = `${
						ratePlan.dailyRatePlanConfigs[item].booked ?? ''
					}/${ratePlan.dailyRatePlanConfigs[item].allotment ?? ''}`;
					break;
				}

				case RatePlanRow.REMAINING_ALLOTMENT: {
					rowObject[item] = `${
						ratePlan.dailyRatePlanConfigs[item].remainingAllotment ?? '-'
					}`;
					break;
				}

				case RatePlanRow.ON_REQUEST_ALLOTMENT: {
					rowObject[item] = `${
						ratePlan.dailyRatePlanConfigs[item].onRequestAllotment ?? ''
					}`;
					break;
				}

				case RatePlanRow.CONDITION: {
					rowObject[item] = ratePlan.dailyRatePlanConfigs[item].condition;
					break;
				}

				default:
					break;
			}
		});

		return rowObject;
	});
}

import { useState } from 'react';
import { ButtonBase, Stack, styled, Typography } from '@mui/material';
import { DataGridSortingDirection } from '../../interface/dataGridHeader.interface';
import { ReactComponent as InvertedTriangle } from '../../../assets/icon/inverted-triangle.svg';
import { ReactComponent as UnsortedIcon } from '../../../assets/icon/unsorted.svg';

interface CustomDataGridHeaderProps {
	headerName?: string;
	fieldName: string;
	addSortCallback?: (
		direction: DataGridSortingDirection,
		property: string
	) => void;
	removeSortCallback?: (property: string) => void;
	enableSorting?: boolean;
}

/**
 * @deprecated This component should not be used anymore. Please use AppDataGridHeaderWithSorting instead.
 */
const CustomDataGridHeader = ({
	headerName,
	fieldName,
	addSortCallback,
	removeSortCallback,
	enableSorting,
}: CustomDataGridHeaderProps) => {
	const [sortingDirection, setSortingDirection] =
		useState<DataGridSortingDirection>('none');

	function sortingButtonOnClick() {
		if (sortingDirection === 'ASC') {
			setSortingDirection('DESC');
			addSortCallback ? addSortCallback('DESC', fieldName) : () => {};
			return;
		}
		if (sortingDirection === 'DESC') {
			setSortingDirection('none');
			removeSortCallback ? removeSortCallback(fieldName) : () => {};

			return;
		}
		if (sortingDirection === 'none') {
			setSortingDirection('ASC');
			addSortCallback ? addSortCallback('ASC', fieldName) : () => {};
		}
	}

	return (
		<HeaderContainer
			direction={'row'}
			justifyContent={'space-between'}
			onClick={sortingButtonOnClick}
		>
			<HeaderName variant='bold'>{headerName ?? ''}</HeaderName>

			{(enableSorting == null || enableSorting) && (
				<ButtonBase sx={{ padding: '0.5rem' }}>
					{SORTING_ICON[sortingDirection]}
				</ButtonBase>
			)}
		</HeaderContainer>
	);
};

const HeaderContainer = styled(Stack)({
	width: '100%',
});

const Triangle = styled(InvertedTriangle)({
	width: '8px',
	height: '12px',
	transform: 'rotate(180deg)',
});

const StyledInvertedTriangle = styled(InvertedTriangle)({
	width: '8px',
	height: '12px',
});

const StyledUnsortedIcon = styled(UnsortedIcon)({
	width: '8px',
	height: '12px',
});

const HeaderName = styled(Typography)({
	fontSize: '0.875rem',
	paddingTop: '0.5rem',
	paddingBottom: '0.5rem',
	paddingRight: '0.1rem',
	whiteSpace: 'break-spaces',
	lineHeight: '1.25rem',
});

const SORTING_ICON = {
	ASC: <StyledInvertedTriangle />,
	DESC: <Triangle />,
	none: <StyledUnsortedIcon />,
};

export default CustomDataGridHeader;

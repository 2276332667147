import { useParams } from 'react-router-dom';
import { useGetPropertyImagesQuery } from '../../../../api/accommodationApiSlice';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { Box, Divider, Stack } from '@mui/material';
import PropertyImageListSection from '../component/PropertyImageListSection';
import FullScreenLoader from '../../../../common/components/FullScreenLoader';

export default function PropertyDynamicForm() {
	const { propertyId } = useParams();

	const {
		data: getPropertyImagesResponse,
		isLoading: isGetPropertyImagesLoading,
		isSuccess: isGetPropertyImagesSuccess,
		isError: isGetPropertyImagesError,
	} = useGetPropertyImagesQuery(propertyId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	let pageContent;

	if (isGetPropertyImagesLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetPropertyImagesSuccess) {
		pageContent = (
			<>
				<Stack>
					{getPropertyImagesResponse?.data
						.slice(2)
						.map((propertyImage, index) => {
							return (
								<Box
									key={propertyImage.category.id}
									sx={{ paddingTop: '20px' }}
								>
									<AppSubtitleLayout label={propertyImage.category.name} />
									<PropertyImageListSection
										image={propertyImage.images}
										categoryId={propertyImage.category.id}
										section={propertyImage.section}
									/>
									{index + 1 <
										getPropertyImagesResponse?.data.slice(2).length && (
										<Divider sx={{ marginY: '15px' }} />
									)}
								</Box>
							);
						})}
				</Stack>
			</>
		);
	} else if (isGetPropertyImagesError) {
		pageContent = <div>API ERROR</div>;
	}

	return <div>{pageContent}</div>;
}

import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { useGetSupplierQuery } from '../../../../api/supplierApiSlice';
import { Dispatch, SetStateAction, useContext } from 'react';
import { SupplierIdContext } from '../ViewSupplierPage';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { SupplierDocumentType } from '../../../../api/enum/supplierDocumentType.enum';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import DocumentDisplay from '../../../../common/components/form-data-display/DocumentDisplay';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../../utils/dateHelper';

export default function DocumentView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const supplierId = useContext(SupplierIdContext);

	const { data: getSupplierResponse } = useGetSupplierQuery(supplierId);

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppSubtitleLayout label={t('supplier.Agreement')} />

				<Grid container columnSpacing='20px' marginBottom='15px'>
					<Grid item style={{ width: '180px' }}>
						<Typography fontSize={12} color='#808080'>
							{t('supplier.FileName')}
						</Typography>
					</Grid>
					<Grid item xs>
						<Typography fontSize={12} color='#808080'>
							{t('supplier.Description')}
						</Typography>
					</Grid>
					<Grid item style={{ width: '160px' }}>
						<Typography fontSize={12} color='#808080'>
							{t('supplier.UploadDate')}
						</Typography>
					</Grid>
				</Grid>

				{getSupplierResponse?.data.documents[
					SupplierDocumentType.AGREEMENT
				].map((item) => {
					return (
						<Grid
							key={item.id}
							container
							columnSpacing='20px'
							marginBottom='15px'
						>
							<Grid item style={{ width: '180px' }}>
								<DocumentDisplay fileId={item.id} fileName={item.name} />
							</Grid>
							<Grid item xs>
								<NewDisplayText text={item.description} />
							</Grid>
							<Grid item style={{ width: '160px' }}>
								<Typography fontSize={12} color='#808080'>
									{item.createdAt
										? dayjs(item.createdAt).format(DISPLAY_DATE_TIME_FORMAT)
										: '-'}
								</Typography>
							</Grid>
						</Grid>
					);
				})}

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('supplier.Other')} />

				<Grid container columnSpacing='20px' marginBottom='15px'>
					<Grid item style={{ width: '180px' }}>
						<Typography fontSize={12} color='#808080'>
							{t('supplier.FileName')}
						</Typography>
					</Grid>
					<Grid item xs>
						<Typography fontSize={12} color='#808080'>
							{t('supplier.Description')}
						</Typography>
					</Grid>
					<Grid item style={{ width: '160px' }}>
						<Typography fontSize={12} color='#808080'>
							{t('supplier.UploadDate')}
						</Typography>
					</Grid>
				</Grid>

				{getSupplierResponse?.data.documents[SupplierDocumentType.OTHER].map(
					(item) => {
						return (
							<Grid
								key={item.id}
								container
								columnSpacing='20px'
								marginBottom='15px'
							>
								<Grid item style={{ width: '180px' }}>
									<DocumentDisplay fileId={item.id} fileName={item.name} />
								</Grid>
								<Grid item xs>
									<NewDisplayText text={item.description} />
								</Grid>
								<Grid item style={{ width: '160px' }}>
									<Typography fontSize={12} color='#808080'>
										{item.createdAt
											? dayjs(item.createdAt).format(DISPLAY_DATE_TIME_FORMAT)
											: '-'}
									</Typography>
								</Grid>
							</Grid>
						);
					}
				)}
			</Stack>
		</>
	);
}

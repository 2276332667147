import { ReactNode } from 'react';
import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { FormControl, Stack, TextFieldProps, Typography } from '@mui/material';
import Option from '../../../common/types/option.type';
import {
	StyledMenuItem,
	StyledSelect,
} from '../../../common/components/styled/StyledSelect';

interface SetPlaceTypeDropDownMenuProps {
	passObjectValue?: boolean;
	label: string;
	options: Option[];
	infoPopUp?: ReactNode;
	onChangeCallback: (optionIds: any) => void;
}

export default function SetPlaceTypeDropDownMenu<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	passObjectValue,
	label,
	options,
	infoPopUp,
	disabled,
	placeholder,
	required,
	onChangeCallback,
	...props
}: SetPlaceTypeDropDownMenuProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<FormControl
			error={!!fieldState.error?.message}
			sx={{ flexDirection: 'row', gap: '10px', marginBottom: '20px' }}
		>
			<Typography
				sx={{
					fontSize: '14px',
					fontWeight: '700',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{label}
			</Typography>
			<StyledSelect
				variant='outlined'
				{...field}
				sx={{
					backgroundColor: '#FFFFFF',
					minWidth: '150px',
					// placeholder for Select
					'& .MuiSelect-select .notranslate::after': placeholder
						? {
								content: `"${placeholder}"`,
								color: '#808080',
								opacity: 1,
						  }
						: {},
				}}
				onChange={(e) => {
					field.onChange(
						passObjectValue
							? options.find((i) => i.id === e.target.value)
							: e.target.value
					);
					// console.log(e.target.value);
					onChangeCallback(e.target.value);
				}}
				value={
					field.value == null
						? ''
						: passObjectValue
						? (field.value as Option).id
						: field.value
				} // to fix `value` prop on `input` should not be null
				disabled={disabled}
				MenuProps={{
					PaperProps: {
						sx: {
							borderRadius: '5px',
							boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
							'& .MuiMenuItem-root': {
								padding: '12px',
							},
							maxHeight: '250px',
						},
					},
				}}
			>
				{options.map((option) => (
					<StyledMenuItem key={option.id} value={option.id}>
						<Stack direction={'row'} alignItems={'center'}>
							<Typography variant='regular' sx={{ fontSize: '0.75rem' }}>
								{option.name}
							</Typography>
						</Stack>
					</StyledMenuItem>
				))}
			</StyledSelect>
		</FormControl>
	);
}

import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import {
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	OutlinedInput,
	styled,
	TextFieldProps,
} from '@mui/material';
import React, { MouseEvent } from 'react';
import AppInputLabel from './archive/AppInputLabel';
import { ReactComponent as VisibilityIcon } from '../../../assets/icon/visibility.svg';
import { ReactComponent as VisibilityOffIcon } from '../../../assets/icon/visibilityOff.svg';
import { ReactComponent as CopyIcon } from '../../../assets/icon/copy.svg';
import { useAppDispatch } from '../../../redux-hooks';
import { SnackbarType, showSnackbar } from '../snackbar/snackbarSlice';
import { useTranslation } from 'react-i18next';

interface AppPasswordTextFieldProps {
	label: string;
	showCopyButton?: boolean;
}

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
	'label + &': {
		marginTop: '28px',
	},

	padding: '12px',

	'& fieldset': {
		borderColor: '#BCBCBC !important',
		borderWidth: '1px',
		borderRadius: '5px',
	},

	':hover ': {
		borderWidth: '1px',
		borderColor: '#BCBCBC !important',
	},

	'&.Mui-focused fieldset': {
		borderWidth: '1px !important',
		borderColor: '#4EA4D3 !important',
	},

	'&.Mui-error fieldset': {
		borderWidth: '1px !important',
		borderColor: '#DA5150 !important',
	},

	'& .MuiInputBase-input': {
		fontSize: 12,
		height: '12px',
		padding: '0px',

		'&::placeholder': {
			color: '#808080',
			opacity: 1,
		},
	},
}));

export default function AppPasswordTextField<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	showCopyButton,
	placeholder,
	helperText,
	required,
	...props
}: AppPasswordTextFieldProps &
	UseControllerProps<TFieldValues, TName> &
	TextFieldProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event: MouseEvent) => {
		event.preventDefault();
	};

	const handleClickCopyPassword = () => {
		dispatch(
			showSnackbar({
				snackbarType: SnackbarType.MESSAGE,
				snackbarProps: {
					anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
					message: t('common.copyPasswordSuccessMessage'),
				},
			})
		);
		navigator.clipboard.writeText(field.value);
	};

	return (
		<FormControl fullWidth={true} variant='standard' error={!!errorMessage}>
			<AppInputLabel
				label={label}
				errorMessage={errorMessage}
				required={required}
			/>
			<StyledOutlinedInput
				id='outlined-adornment-password'
				type={showPassword ? 'text' : 'password'}
				endAdornment={
					<InputAdornment position='end' sx={{ marginRight: '4px' }}>
						<IconButton
							aria-label='toggle password visibility'
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge='end'
						>
							{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
						</IconButton>
						{showCopyButton && (
							<IconButton
								onClick={handleClickCopyPassword}
								edge='end'
								sx={{ marginLeft: '5px' }}
							>
								<CopyIcon />
							</IconButton>
						)}
					</InputAdornment>
				}
				placeholder={placeholder}
				{...field}
			/>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

import { Box, styled, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { ReactComponent as CircleInfoIcon } from '../../../assets/icon/circleInfo.svg';

interface AppSplitSubtitleLabelProps {
	required?: boolean;
	label?: string;
	infoPopUp?: ReactNode;
	color?: string;
}

export default function AppSplitSubtitleLabel({
	required,
	label,
	infoPopUp,
	color,
}: React.PropsWithChildren<AppSplitSubtitleLabelProps>) {
	return (
		<SubtitleLabel color={color ?? '#000000'}>
			{label}
			{required ? '*' : ''}
			{!!infoPopUp && (
				<Box component={'span'} sx={{ marginLeft: '5px' }}>
					<Tooltip title={infoPopUp} placement='bottom-start'>
						<CircleInfoIcon />
					</Tooltip>
				</Box>
			)}
		</SubtitleLabel>
	);
}

const SubtitleLabel = styled(Typography, {
	shouldForwardProp: (prop) => !(prop === 'color'),
})<{
	color: string;
}>(({ color }) => ({
	fontSize: '12px',
	fontWeight: '700',
	textAlign: 'center',
	color: color,
}));

import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormCardType } from '../../../../common/components/card/FormCard';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import { Dispatch, SetStateAction } from 'react';
import ToggleButtonDisplay from '../../../../common/components/form-data-display/ToggleButtonDisplay';
import { facilityOption } from '../../../../api/enum/facility.enum';
import defineAbilityFor from '../../../../casl/defineAbility';
import { useGetMeQuery } from '../../../../api/userApiSlice';
import {
	useGetPfsubcategoriesQuery,
	useGetPropertyFacilitiesQuery,
} from '../../../../api/accommodationApiSlice';
import {
	PFacility,
	RFSubcategoryWithFacilities,
} from '../../../../api/DTO/facility.interface';
import AppAlert from '../../../../common/components/AppAlert';

export default function PropertyFacilityView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { propertyId, pfcategoryId } = useParams();
	const { t } = useTranslation();

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);

	const { data: getPfsubcategoriesResponse } = useGetPfsubcategoriesQuery(
		pfcategoryId ?? ''
	);

	const { data: getPropertyFacilitiesResponse } = useGetPropertyFacilitiesQuery(
		{ propertyId: propertyId ?? '', pfcategoryId: pfcategoryId ?? '' }
	);

	return (
		<>
			<Stack position={'relative'}>
				{ability.can('create', 'Property') && (
					<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
						<UnderlinedIconButton
							icon={<EditIcon color='#32A5F1' />}
							onClick={() => {
								setType(FormCardType.edit);
							}}
						>
							{t('button.Edit')}
						</UnderlinedIconButton>
					</Box>
				)}
				<Box
					position={'absolute'}
					sx={{
						top: 0,
						left: 0,
					}}
				>
					<AppAlert alertColor='#32A5F1' alertBar={false}>
						{t('facility.TagStyleMessage')}
					</AppAlert>
				</Box>

				<Grid
					container
					columnSpacing='40px'
					rowSpacing='20px'
					marginTop='20px'
					marginBottom='20px'
					padding='0px'
					position='relative'
					sx={{ overflowX: 'auto' }}
				>
					{getPfsubcategoriesResponse?.data &&
						getPfsubcategoriesResponse?.data.map(
							(
								subcategory: RFSubcategoryWithFacilities,
								subcategoryIndex: number
							) => {
								return (
									<Grid item xs={12} key={subcategory.id}>
										<Box alignItems={'center'}>
											<Box minWidth={'17rem'}>
												<Typography fontSize={14} fontWeight={700}>
													{subcategory.nameEn}
												</Typography>
											</Box>
											{subcategory.facilities?.map((facility: PFacility) => {
												return (
													<Box
														key={facility.id}
														display={'flex'}
														alignItems={'center'}
													>
														<Box minWidth={'17rem'} maxWidth={'270px'}>
															<Typography
																fontSize={14}
																color={
																	facility.highlighted ? '#32A5F1' : '#808080'
																}
															>
																{facility.nameEn}
															</Typography>
														</Box>
														<Box
															minWidth={'17rem'}
															marginLeft={'1rem'}
															marginRight={'1rem'}
														>
															<ToggleButtonDisplay
																name={facility.id ?? ''}
																options={facilityOption}
																defaultValue={
																	getPropertyFacilitiesResponse?.data[
																		facility.id
																	] ?? ''
																}
																disabled
															/>
														</Box>
													</Box>
												);
											})}
										</Box>
										{subcategoryIndex <
											getPfsubcategoriesResponse?.data.length - 1 && (
											<Divider sx={{ marginTop: '15px' }} />
										)}
									</Grid>
								);
							}
						)}
				</Grid>
			</Stack>
		</>
	);
}

import Option from '../../common/types/option.type';

export enum MealType {
	NO_MEAL = 0,
	BREAKFAST = 1,
	LUNCH = 2,
	DINNER = 3,
	HALF_BOARD = 4,
	FULL_BOARD = 5,
}

export const otherSupplementMealTypeOptions = getMealTypeOptions().filter(
	(item) => item.id !== MealType.NO_MEAL
);

export function getMealTypeOptions(): Option[] {
	return [
		{
			id: MealType.NO_MEAL,
			name: 'No Meal',
			nameEn: 'No Meal',
		},
		{
			id: MealType.BREAKFAST,
			name: '早餐',
			nameEn: 'Breakfast',
		},
		{
			id: MealType.LUNCH,
			name: '午餐',
			nameEn: 'Lunch',
		},
		{
			id: MealType.DINNER,
			name: '晚餐',
			nameEn: 'Dinner',
		},
		{
			id: MealType.HALF_BOARD,
			name: '早晚餐',
			nameEn: 'Half Board',
		},
		{
			id: MealType.FULL_BOARD,
			name: '一日三餐',
			nameEn: 'Full Board',
		},
	];
}

export function getMealTypeNameByMealType(id?: number): string {
	if (id === MealType.NO_MEAL) {
		return 'NoMeal';
	} else if (id === MealType.BREAKFAST) {
		return 'Breakfast';
	} else if (id === MealType.LUNCH) {
		return 'Lunch';
	} else if (id === MealType.DINNER) {
		return 'Dinner';
	} else if (id === MealType.HALF_BOARD) {
		return 'HalfBoard';
	} else if (id === MealType.FULL_BOARD) {
		return 'FullBoard';
	}

	return '';
}

export function getMealNameByMealType(id?: number): string {
	if (id === MealType.NO_MEAL) {
		return 'No Meal';
	} else if (id === MealType.BREAKFAST) {
		return 'Breakfast';
	} else if (id === MealType.LUNCH) {
		return 'Lunch';
	} else if (id === MealType.DINNER) {
		return 'Dinner';
	} else if (id === MealType.HALF_BOARD) {
		return 'Half Board';
	} else if (id === MealType.FULL_BOARD) {
		return 'Full Board';
	}

	return '';
}

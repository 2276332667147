import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useState } from 'react';
import TransportationView from './transportationView';
import TransportationForm from './transportationForm';

interface TransportationCardProps {
	initialType: FormCardType;
}

export default function TransportationCard({
	initialType,
}: TransportationCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<FormCard type={type} title={t('transportation.Transportation')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <TransportationView setType={setType} />;
					case FormCardType.edit:
						return <TransportationForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

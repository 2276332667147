import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useState } from 'react';
import BaseInfoView from './BaseInfoView';
import BaseInfoForm from './BaseInfoForm';

interface BaseInfoCardProps {
	initialType: FormCardType;
	placeType: number;
}

export default function BaseInfoCard({ initialType, placeType }: BaseInfoCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<FormCard type={type} title={t('place.BasicInformation')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <BaseInfoView setType={setType} />;
					case FormCardType.create:
						return <BaseInfoForm type={type} setType={setType} placeType={placeType} />;
					case FormCardType.edit:
						return <BaseInfoForm type={type} setType={setType} placeType={placeType} />;
				}
			})()}
		</FormCard>
	);
}

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as ViewDetailIcon } from '../../assets/icon/view-detail.svg';
import PageHeader from '../../common/components/PageHeader';
import AppDataGridHeaderWithSorting from '../../common/components/dataGrid/AppDataGridHeaderWithSorting';

import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import qs from 'qs';
import { BkFilter, BkSummaryItem } from '../../api/DTO/bk.interface';
import { useGetFilteredBkQuery } from '../../api/bkApiSlice';
import { BkStatus, getBkStatusDisplay } from '../../api/enum/bkStatus.enum';
import { useGetMeQuery } from '../../api/userApiSlice';
import defineAbilityFor from '../../casl/defineAbility';
import AppNightsDisplay from '../../common/components/AppNightsDisplay';
import AppStatusDisplay from '../../common/components/AppStatusDisplay';
import AppDataGrid, {
	initialPagination,
} from '../../common/components/dataGrid/AppDataGrid';
import {
	API_DATE_FORMAT,
	DISPLAY_DATE_FORMAT,
	INVALID_DATE,
} from '../../utils/dateHelper';
import {
	filterObjectByKeys,
	parseSummarySearchParams,
} from '../../utils/qsHelper';
import BkSummaryFilterSection from './components/BkSummaryFilterSection';

interface BkDataGrid {
	id?: string;
	contactPersonName: string;
	contactPersonPhoneNum: string;
	supPropertyName: string;
	cusPropertyName: string;
	majorArea: string;
	area: string;
	stayPeriodStart: string;
	stayPeriodEnd: string;
	numOfNights: number;
	numOfAdults: number;
	numOfChildren: number;
	status: BkStatus;
	createdAt: string;
}

export default function BkSummaryPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data: getMeResponse } = useGetMeQuery();
	const ability = defineAbilityFor(getMeResponse?.data.role ?? -1);
	const [searchParams, setSearchParams] = useSearchParams({
		'sortings[0][direction]': 'DESC',
		'sortings[0][property]': 'createdAt',
	});
	const [isInitializedForm, setIsInitializedForm] = useState<boolean>(false);

	const defaultBkFilter: BkFilter = {
		pagination: initialPagination,
		sortings: [],
		searchKey: undefined,
		countryId: undefined,
		majorAreaId: undefined,
		areaId: undefined,
		stayPeriodStart: undefined,
		stayPeriodEnd: undefined,
		createdAtFrom: undefined,
		createdAtTo: undefined,
	};

	const defaultValues: DefaultValues<BkFilter> = defaultBkFilter;

	const transformedSearchParams: BkFilter = {
		...defaultBkFilter,
		...filterObjectByKeys(parseSummarySearchParams(searchParams), [
			'pagination',
			'sortings',
			'searchKey',
			'countryId',
			'majorAreaId',
			'areaId',
			'stayPeriodStart',
			'stayPeriodEnd',
			'createdAtFrom',
			'createdAtTo',
		]),
	};

	const { data: bkSummary, isSuccess: isGetBkSummarySuccess } =
		useGetFilteredBkQuery(transformedSearchParams, {
			refetchOnMountOrArgChange: true,
		});

	// watch search params
	useEffect(() => {
		if (!isInitializedForm) {
			methods.reset(transformedSearchParams, {
				keepDefaultValues: true,
			});
			setIsInitializedForm(true);
		}
	}, [searchParams]);

	const methods = useForm<BkFilter>({ defaultValues });

	// watch fields change
	useEffect(() => {
		const subscription = methods.watch((value) => {
			const bkFilterFormResult = value as BkFilter;

			if (
				bkFilterFormResult.stayPeriodStart === INVALID_DATE ||
				bkFilterFormResult.stayPeriodEnd === INVALID_DATE ||
				bkFilterFormResult.createdAtFrom === INVALID_DATE ||
				bkFilterFormResult.createdAtTo === INVALID_DATE
			) {
				return;
			}

			// append to url to reload page
			setSearchParams(qs.stringify(bkFilterFormResult));
		});
		return () => subscription.unsubscribe();
	}, [methods.watch]);

	const transformResponse = useCallback((response?: BkSummaryItem[]) => {
		if (response == null) return;
		const newResponse: BkDataGrid[] = [];
		response.forEach((item) => {
			newResponse.push({
				id: item.id,
				contactPersonName: item.contactPersonName,
				contactPersonPhoneNum: item.contactPersonPhoneNum,
				supPropertyName: item.supPropertyName,
				cusPropertyName: item.cusPropertyName,
				majorArea: item.majorArea,
				area: item.area,
				numOfNights: item.numOfNights,
				stayPeriodStart: item.stayPeriod.start,
				stayPeriodEnd: item.stayPeriod.end,
				numOfAdults: item.numOfAdults,
				numOfChildren: item.numOfChildren,
				status: item.status,
				createdAt: dayjs(item.createdAt).format(DISPLAY_DATE_FORMAT),
			});
		});
		return newResponse;
	}, []);

	const DataGridHeader = (params: GridColumnHeaderParams) => {
		return (
			<AppDataGridHeaderWithSorting
				headerName={params.colDef.headerName}
				sortingsPropertyName={params.colDef.field}
			/>
		);
	};

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: t('bk.Id'),
			width: 109, // 24(cell padding left) + 75(text fixed width) + 10(cell padding right)
			renderHeader: DataGridHeader,
		},
		{
			field: 'contactPerson',
			headerName: t('bk.ContactPerson'),
			width: 120,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
							fontSize: '12px',
						}}
						variant='regular'
					>
						{params.row.contactPersonName}
					</Typography>
					<Typography
						sx={{
							display: 'block',
							fontSize: '10px',
						}}
						variant='regular'
					>
						{params.row.contactPersonPhoneNum
							? `+${params.row.contactPersonPhoneNum}`
							: '-'}
					</Typography>
				</Box>
			),
		},
		{
			field: 'propertyName',
			headerName: t('bk.PropertyName'),
			flex: 1,
			minWidth: 90,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
							fontSize: '12px',
						}}
						variant='regular'
					>
						{params.row.supPropertyName}
					</Typography>
					<Typography
						sx={{
							display: 'block',
							fontSize: '10px',
							color: '#808080',
						}}
						variant='regular'
					>
						{params.row.cusPropertyName}
					</Typography>
				</Box>
			),
		},
		{
			field: 'majorArea',
			headerName: t('bk.Location'),
			flex: 1,
			minWidth: 100,
			renderCell: (params) => (
				<Box>
					<Typography
						sx={{
							display: 'block',
							fontSize: '10px',
							wordBreak: 'break-word',
						}}
						variant='regular'
					>
						{params.row.majorArea} / {params.row.area}
					</Typography>
				</Box>
			),
		},
		{
			field: 'stayPeriod',
			headerName: t('bk.StayPeriod'),
			width: 130,
			renderHeader: DataGridHeader,
			renderCell: (params) => (
				<Stack direction={'row'} gap={'0.5rem'} alignItems={'center'}>
					<AppNightsDisplay nights={params.row.numOfNights} />
					<Typography
						variant='regular'
						sx={{
							fontSize: '0.75rem',
							wordWrap: 'break-word',
							whiteSpace: 'normal',
						}}
					>
						{dayjs(params.row.stayPeriodStart, API_DATE_FORMAT).format(
							DISPLAY_DATE_FORMAT
						)}{' '}
						-{' '}
						{dayjs(params.row.stayPeriodEnd, API_DATE_FORMAT).format(
							DISPLAY_DATE_FORMAT
						)}
					</Typography>
				</Stack>
			),
		},
		{
			field: 'details',
			headerName: t('bk.GuestInfo'),
			width: 90,
			renderCell: (params) => (
				<Box display={'flex'} flexDirection={'column'}>
					<Typography
						variant='regular'
						sx={{
							fontSize: '12px',
							wordWrap: 'break-word',
							whiteSpace: 'normal',
						}}
					>
						{params.row.numOfAdults} Adults <br />
						{params.row.numOfChildren > 0 &&
							`${params.row.numOfChildren} Children`}
					</Typography>
				</Box>
			),
		},
		{
			field: 'bookingStatus',
			headerName: t('bk.BookingStatus'),
			width: 100,
			renderCell: (params) => (
				<AppStatusDisplay
					name={getBkStatusDisplay(params.row.status).name}
					color={getBkStatusDisplay(params.row.status).color}
					bgcolor={getBkStatusDisplay(params.row.status).backgroundColor}
				/>
			),
		},
		{
			field: 'customerPayment',
			headerName: t('bk.CustomerPayment'),
			width: 110,
			renderCell: (params) => <>-</>,
		},
		{
			field: 'payToSupplier',
			headerName: t('bk.PayToSupplier'),
			width: 110,
			renderCell: (params) => <>-</>,
		},
		{
			field: 'createdAt',
			headerName: t('bk.DateCreated'),
			width: 100,
			renderHeader: DataGridHeader,
		},
		{
			field: 'edit',
			headerName: '',
			width: 68, // 10(cell padding left) + 34(icon button width) + 24(cell padding right)
			renderCell: (params) => (
				<Link
					to={`/bks/${params.id}`}
					state={{
						bkSummarySearchParams: qs.stringify(transformedSearchParams),
					}}
				>
					<IconButton color='primary'>
						<ViewDetailIcon />
					</IconButton>
				</Link>
			),
		},
	];

	return (
		<>
			<PageHeader title={t('bk.Booking')} />
			<FormProvider {...methods}>
				<BkSummaryFilterSection
					isInitializedForm={isInitializedForm}
					transformedSearchParams={transformedSearchParams}
				/>

				<Box marginTop={'28px'}>
					<AppDataGrid
						loading={!isGetBkSummarySuccess}
						rows={transformResponse(bkSummary?.data?.items) ?? []}
						columns={columns}
						rowCount={bkSummary?.data?.pagination.totalItems ?? 0}
						getRowId={(row) => row.id}
					/>
				</Box>
			</FormProvider>
		</>
	);
}

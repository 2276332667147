import Option from '../../common/types/option.type';

export enum RestaurantSizeType {
	SMALL_SCALE = 0,
	LARGE_SCALE = 1,
	CHAIN = 2,
}

export const RestaurantSizeTypeOption: Option[] = [
	{
		id: RestaurantSizeType.SMALL_SCALE,
		name: 'Small Scale',
	},
	{
		id: RestaurantSizeType.LARGE_SCALE,
		name: 'Large Scale',
	},
	{
		id: RestaurantSizeType.CHAIN,
		name: 'Chain',
	},
];

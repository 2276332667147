import { useTranslation } from 'react-i18next';
import { TanglePeek } from '../../../../api/DTO/propertyImport.interface';
import { useGetTangleVersionQuery } from '../../../../api/scraperApiSlice';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import SearchPropertyForm from './SearchPropertyForm';

interface SearchPropertyCardProps {
	initialType: FormCardType;
	setSearchResult: (result: TanglePeek | undefined) => void;
	searchResult?: TanglePeek;
}

export default function SearchPropertyCard({
	initialType,
	setSearchResult,
	searchResult,
}: SearchPropertyCardProps) {
	const { t } = useTranslation();

	const { data: getTangleVersionResponse } = useGetTangleVersionQuery();

	return (
		<FormCard
			type={initialType}
			title={
				t('property.propertyImport.SearchProperty') +
				` (Version ${getTangleVersionResponse?.data.engineVersion})`
			}
		>
			<SearchPropertyForm
				setSearchResult={setSearchResult}
				searchResult={searchResult}
			/>
		</FormCard>
	);
}

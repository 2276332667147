import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import SupplierInfoForm from './SupplierInfoForm';
import SupplierInfoView from './SupplierInfoView';

interface SupplierInfoCardProps {
	initialType: FormCardType;
}

export default function SupplierInfoCard({
	initialType,
}: SupplierInfoCardProps) {
	const { t } = useTranslation();

	return (
		<FormCard type={initialType} title={t('abk.SupplierInfo')}>
			{(() => {
				switch (initialType) {
					case FormCardType.view:
						return <SupplierInfoView />;
					case FormCardType.create:
						return <SupplierInfoForm type={initialType} />;
					case FormCardType.edit:
						return <SupplierInfoForm type={initialType} />;
					case FormCardType.specialEdit:
						return <SupplierInfoForm type={initialType} />;
				}
			})()}
		</FormCard>
	);
}

import { Box, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';
import AppFormLabel from './AppFormLabel';

interface AppInputLayoutProps {
	required?: boolean;
	label?: string;
	infoPopUp?: ReactNode;
	removeErrorPadding?: boolean;
	errorMessage?: string;
	labelAlignItemsFlexStart?: boolean;
}

const Error = styled(Typography)(({ theme }) => ({
	minHeight: '15px',
	paddingTop: '2px',
	fontSize: '10px',
	lineHeight: '10px',
	color: theme.palette.error.main,
	whiteSpace: 'pre-line',
}));

export default function AppInputLayout({
	required,
	label,
	infoPopUp,
	removeErrorPadding,
	errorMessage,
	labelAlignItemsFlexStart,
	...props
}: React.PropsWithChildren<AppInputLayoutProps>) {
	const alignItems = labelAlignItemsFlexStart ? 'flex-start' : 'center';

	return (
		<Box
			sx={{
				display: 'flex',
				gap: '20px',
				alignItems: { alignItems },
			}}
		>
			{label && (
				<AppFormLabel required={required} label={label} infoPopUp={infoPopUp} />
			)}
			<Stack sx={{ width: '100%', minWidth: 0 }}>
				{props.children}
				{!removeErrorPadding && <Error>{errorMessage ?? '\n'}</Error>}
			</Stack>
		</Box>
	);
}

import { useState } from 'react';
import { FormCardType } from '../../../../common/components/card/FormCard';
import TitleCard from '../../../../common/components/card/TitleCard';
import { useTranslation } from 'react-i18next';
import CustomFaqForm from './CustomFaqForm';
import CustomFaqView from './CustomFaqView';

interface CustomFaqCardProps {
	initialType: FormCardType;
}

export default function CustomFaqCard({ initialType }: CustomFaqCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<TitleCard type={type} title={t('faq.CustomQuestions')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <CustomFaqView setType={setType} />;
					case FormCardType.create:
						return <CustomFaqForm type={type} setType={setType} />;
					case FormCardType.edit:
						return <CustomFaqForm type={type} setType={setType} />;
				}
			})()}
		</TitleCard>
	);
}

import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useEffect, useState } from 'react';
import RoomSupplierView from './RoomSupplierView';
import RoomSupplierForm from './RoomSupplierForm';
import { useParams } from 'react-router-dom';

interface RoomSupplierCardProps {
	initialType: FormCardType;
}

export default function RoomSupplierCard({
	initialType,
}: RoomSupplierCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	const { roomId } = useParams();

	useEffect(() => {
		if (type === FormCardType.edit) {
			setType(FormCardType.view);
		}
	}, [roomId]);

	return (
		<FormCard type={type} title={t('room.SupplierMapping')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <RoomSupplierView setType={setType} />;
					case FormCardType.create:
						return <RoomSupplierForm type={type} setType={setType} />;
					case FormCardType.edit:
						return <RoomSupplierForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}

import { useAppSelector } from '../../../redux-hooks';
import { SnackbarType, selectSnackbarState } from './snackbarSlice';
import AppSnackbar from './AppSnackbar';
import SaveSuccessSnackbar from './SaveSuccessSnackbar';

const SnackbarRoot = () => {
	const { snackbarType, snackbarProps } = useAppSelector(selectSnackbarState);

	if (snackbarType === SnackbarType.NULL) {
		return null;
	}

	if (snackbarType === SnackbarType.SAVE_SUCCESS)
		return <SaveSuccessSnackbar {...snackbarProps} />;

	return <AppSnackbar {...snackbarProps} />;
};

export default SnackbarRoot;

import { Button, Dialog, Stack, styled, Typography } from '@mui/material';
import { useEffect } from 'react';
import {
	useGetPreviewSupplierEmailQuery,
	useGetSendSupplierEmailMutation,
} from '../../../api/abkApiSlice';
import {
	AbkSupplierEmail,
	getAbkSupplierEmailDisplay,
} from '../../../api/enum/abkSupplierEmail';
import { ReactComponent as CloseIcon } from '../../../assets/icon/close.svg';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal } from './modalSlice';

interface SendAbkSupplierEmailModalProps {
	abkId: string;
	email: AbkSupplierEmail;
	contactPointId: string;
}

const SendAbkSupplierEmailModal = ({
	abkId,
	email,
	contactPointId,
}: SendAbkSupplierEmailModalProps) => {
	const dispatch = useAppDispatch();

	const { data: getPreviewSupplierEmailResponse } =
		useGetPreviewSupplierEmailQuery(
			{
				abk_id: abkId ?? '',
				email: email,
				contactPointId: contactPointId ?? '',
			},
			{
				refetchOnMountOrArgChange: true,
			}
		);

	const [getSendSupplierEmail, { isSuccess: isGetSendSupplierEmailSuccess }] =
		useGetSendSupplierEmailMutation();

	const deleteAbkBookingButtonOnClick = () => {
		getSendSupplierEmail({
			abk_id: abkId ?? '',
			email: email,
			contactPointId: contactPointId ?? '',
		});
	};

	const handleClose = () => {
		dispatch(hideModal());
	};

	useEffect(() => {
		if (isGetSendSupplierEmailSuccess) {
			dispatch(hideModal());
		}
	}, [isGetSendSupplierEmailSuccess]);

	return (
		<Dialog
			sx={{
				width: '100%',
				'& .MuiDialog-paper': {
					width: '100%',
					minHeight: '500px',
					padding: '1rem 1.75rem 1.5rem 1.75rem',
				},
				overflow: 'hidden',
			}}
			fullWidth={true}
			maxWidth={'lg'}
			open={abkId != null}
		>
			<Stack direction={'row'} alignItems={'center'}>
				<DialogTitle variant='bold' sx={{ flexGrow: 1 }}>
					Preview of {getAbkSupplierEmailDisplay(email).name} to Supplier
				</DialogTitle>
				<CloseIcon onClick={handleClose} cursor='pointer' />
			</Stack>
			<DialogContent
				variant='regular'
				sx={{
					border: '2px solid #E8EBED',
					borderRadius: '10px',
					padding: '15px',
					marginTop: '15px',
					overflow: 'auto',
				}}
			>
				<div
					dangerouslySetInnerHTML={{
						__html: getPreviewSupplierEmailResponse?.data ?? '',
					}}
				/>
			</DialogContent>
			<Stack
				direction={'row'}
				gap={'0.75rem'}
				sx={{ marginTop: '1.5rem', marginLeft: 'auto' }}
			>
				<Button variant='outlined' color='info' onClick={handleClose}>
					Cancel
				</Button>
				<Button
					variant='contained'
					color='success'
					onClick={deleteAbkBookingButtonOnClick}
				>
					Confirm and Send
				</Button>
			</Stack>
		</Dialog>
	);
};

const DialogTitle = styled(Typography)({
	fontSize: '1rem',
});

const DialogContent = styled(Typography)({
	fontSize: '0.875rem',
});

export default SendAbkSupplierEmailModal;

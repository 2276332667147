import { Box, Typography } from '@mui/material';
import { ReactComponent as CircleTickIcon } from '../../../assets/icon/circleTick.svg';
import { ReactComponent as CircleCrossIcon } from '../../../assets/icon/circleCross.svg';
import { useTranslation } from 'react-i18next';

interface YesNoDisplayProps {
	value?: boolean;
}

export default function YesNoDisplay({ value }: YesNoDisplayProps) {
	const { t } = useTranslation();

	return (
		<>
			{value !== undefined && (
				<Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
					{value && (
						<>
							<CircleTickIcon color='#79B765' />
							<Typography fontSize='12px' color='#79B765'>
								{t('common.Yes')}
							</Typography>
						</>
					)}
					{!value && (
						<>
							<CircleCrossIcon color='#F6403F' />
							<Typography fontSize='12px' color='#F6403F'>
								{t('common.No')}
							</Typography>
						</>
					)}
				</Box>
			)}
		</>
	);
}

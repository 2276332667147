import { Grid, Stack } from '@mui/material';
import PropertyFixedCard from './propertyFixedCard/propertyFixedCard';
import PropertyDynamicCard from './propertyDynamicCard/propertyDynamicCard';
import RoomImageCard from './roomImageCard/roomImageCard';

export default function ViewGalleryPage() {
	return (
		<Grid container spacing={'20px'}>
			<Grid item style={{ width: '100%' }}>
				<Stack width={'100%'} spacing={'15px'}>
					<PropertyFixedCard />
					<PropertyDynamicCard />
					<RoomImageCard />
				</Stack>
			</Grid>
		</Grid>
	);
}

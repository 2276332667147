import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import { useGetRoomQuery } from '../../../../api/accommodationApiSlice';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import { Dispatch, SetStateAction } from 'react';
import AppSubtitleLayout from '../../../../common/components/form-layout/AppSubtitleLayout';
import { useNavigate, useParams } from 'react-router-dom';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';
import TextWithUnit from '../../../../common/components/form-data-display/TextWithUnit';
import { roomViewOption } from '../../../../api/enum/roomView.enum';
import TickOrCrossWithLabelDisplay from '../../../../common/components/form-data-display/TickOrCrossWithLabelDisplay';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { ReactComponent as ViewDetailIcon } from '../../../../assets/icon/view-detail.svg';
import TagsDisplay from '../../../../common/components/form-data-display/TagsDisplay';
import { roomSizeUnitOption } from '../../../../api/enum/roomSizeUnit.enum';
import BedConfigsDisplay from '../components/BedConfigsDisplay';
import EditorDisplay from '../../../../common/components/editor/EditorDisplay';

export default function RoomInfoView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const { roomId } = useParams();

	const { data: getRoomResponse } = useGetRoomQuery(roomId ?? '');

	const navigate = useNavigate();

	return (
		<>
			<Stack position={'relative'}>
				<Box
					sx={{
						position: 'absolute',
						top: -10,
						right: 10,
					}}
				>
					<UnderlinedIconButton
						icon={<ViewDetailIcon color='#32A5F1' />}
						onClick={() => navigate(`${location.pathname}/rate-plan`)}
					>
						{t('button.RatePlans')}
					</UnderlinedIconButton>
					&nbsp;&nbsp;
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>

				<AppInputLayout label={t('room.RoomID')}>
					<NonEditableDisplayText text={getRoomResponse?.data.info.id} />
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppSubtitleLayout label={t('room.RoomType')} />

				<AppInputLayout label={t('common.English')}>
					<NewDisplayText text={getRoomResponse?.data.info.nameEn} />
				</AppInputLayout>

				<AppInputLayout label={t('common.TraditionalChinese')}>
					<NewDisplayText text={getRoomResponse?.data.info.name} />
				</AppInputLayout>

				<AppInputLayout label={t('common.Original')}>
					<NewDisplayText text={getRoomResponse?.data.info.originalName} />
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<Box display='grid' gap='84px' gridTemplateColumns='repeat(2, 1fr)'>
					<AppInputLayout label={t('room.FamilyFriendly')}>
						<YesNoDisplay value={getRoomResponse?.data.info.familyFriendly} />
					</AppInputLayout>

					<AppInputLayout label={t('room.MaxRoomCapacity')}>
						<TextWithUnit
							text={`${getRoomResponse?.data.info.maxCapacity}`}
							unit={t('unit.PersonsInTotal')}
						/>
					</AppInputLayout>
				</Box>

				<Box display='grid' gap='84px' gridTemplateColumns='repeat(2, 1fr)'>
					<AppInputLayout label={t('room.MaxAdultCapacity')}>
						<TextWithUnit
							text={`${getRoomResponse?.data.info.maxAdultCapacity}`}
							unit={t('unit.Adults')}
						/>
					</AppInputLayout>

					<AppInputLayout label={t('room.MaxChildCapacity')}>
						<TextWithUnit
							text={`${getRoomResponse?.data.info.maxChildCapacity}`}
							unit={t('unit.Children')}
						/>
					</AppInputLayout>
				</Box>

				<Divider sx={{ marginBottom: '15px' }} />

				<Box display='grid' gap='84px' gridTemplateColumns='repeat(2, 1fr)'>
					<AppInputLayout label={t('room.RoomView')}>
						<NewDisplayText
							text={
								roomViewOption.find(
									(i) => i.id === getRoomResponse?.data.info.view
								)?.name
							}
						/>
					</AppInputLayout>

					<AppInputLayout label={t('room.RoomSize')}>
						<TextWithUnit
							text={`${getRoomResponse?.data.info.size?.size ?? '-'}`}
							unit={
								roomSizeUnitOption.find(
									(i) => i.id === getRoomResponse?.data.info.size?.unit
								)?.name
							}
						/>
					</AppInputLayout>
				</Box>

				<AppInputLayout label={t('room.SmokingRoom')}>
					<YesNoDisplay value={getRoomResponse?.data.info.allowSmoking} />
				</AppInputLayout>

				<AppInputLayout
					labelAlignItemsFlexStart
					label={t('room.RoomDescription')}
				>
					<NewDisplayText text={getRoomResponse?.data.info.description} />
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout
					label={t('room.UniquePolicy')}
					labelAlignItemsFlexStart={true}
				>
					<EditorDisplay
						editorStateString={getRoomResponse?.data.info.policy}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout
					labelAlignItemsFlexStart
					label={t('room.BedType')}
					infoPopUp={' '}
				>
					<BedConfigsDisplay
						bedConfigs={getRoomResponse?.data.info.bedConfigs ?? []}
					/>
				</AppInputLayout>

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout label={t('room.EnableShareBed')}>
					<YesNoDisplay value={getRoomResponse?.data.info.allowSharedBed} />
				</AppInputLayout>

				{getRoomResponse?.data.info.allowSharedBed && (
					<>
						<AppInputLayout
							label={t('room.FitForAdultsShareWith')}
							infoPopUp=' '
						>
							<Stack direction={'row'} spacing={'18px'} gap={'10px'}>
								<TickOrCrossWithLabelDisplay
									value={getRoomResponse?.data.info.infantSharedBed}
									label={t('common.Infants')}
								/>
								<TickOrCrossWithLabelDisplay
									value={getRoomResponse?.data.info.toddlerSharedBed}
									label={t('common.Toddlers')}
								/>
								<TickOrCrossWithLabelDisplay
									value={getRoomResponse?.data.info.childSharedBed}
									label={t('common.Children')}
								/>
								<TickOrCrossWithLabelDisplay
									value={getRoomResponse?.data.info.adultSharedBed}
									label={t('common.Adults')}
								/>
							</Stack>
						</AppInputLayout>

						<AppInputLayout label={t('room.ExtraCostIsRequired')}>
							<YesNoDisplay
								value={getRoomResponse?.data.info.sharedBedExtraCost}
							/>
						</AppInputLayout>
					</>
				)}

				<Divider sx={{ marginBottom: '15px' }} />

				<AppInputLayout
					labelAlignItemsFlexStart
					label={t('room.CustomTags')}
					infoPopUp={' '}
				>
					<TagsDisplay tags={getRoomResponse?.data.info.tags} />
				</AppInputLayout>
			</Stack>
		</>
	);
}

import { Button, Dialog, Stack, styled, Typography } from '@mui/material';
import { useEffect } from 'react';
import {
	useGetPreviewCustomerEmailQuery,
	useGetSendCustomerEmailMutation,
} from '../../../api/abkApiSlice';
import {
	AbkCustomerEmail,
	getAbkCustomerEmailDisplay,
} from '../../../api/enum/abkCustomerEmail';
import { ReactComponent as CloseIcon } from '../../../assets/icon/close.svg';
import { useAppDispatch } from '../../../redux-hooks';
import { hideModal } from './modalSlice';

interface SendAbkCustomerEmailModalProps {
	abkId: string;
	email: AbkCustomerEmail;
}

const SendAbkCustomerEmailModal = ({
	abkId,
	email,
}: SendAbkCustomerEmailModalProps) => {
	const dispatch = useAppDispatch();

	const { data: getPreviewCustomerEmailResponse } =
		useGetPreviewCustomerEmailQuery(
			{ abk_id: abkId ?? '', email: email },
			{
				refetchOnMountOrArgChange: true,
			}
		);

	const [getSendCustomerEmail, { isSuccess: isGetSendCustomerEmailSuccess }] =
		useGetSendCustomerEmailMutation();

	const sendCustomerEmailButtonOnClick = () => {
		getSendCustomerEmail({
			abk_id: abkId ?? '',
			email: email,
		});
	};

	const handleClose = () => {
		dispatch(hideModal());
	};

	useEffect(() => {
		if (isGetSendCustomerEmailSuccess) {
			dispatch(hideModal());
		}
	}, [isGetSendCustomerEmailSuccess]);

	return (
		<Dialog
			sx={{
				width: '100%',
				'& .MuiDialog-paper': {
					width: '100%',
					minHeight: '500px',
					padding: '1rem 1.75rem 1.5rem 1.75rem',
				},
				overflow: 'hidden',
			}}
			fullWidth={true}
			maxWidth={'lg'}
			open={abkId != null}
		>
			<Stack direction={'row'} alignItems={'center'}>
				<DialogTitle variant='bold' sx={{ flexGrow: 1 }}>
					Preview of {getAbkCustomerEmailDisplay(email).name} to Customer
				</DialogTitle>
				<CloseIcon onClick={handleClose} cursor='pointer' />
			</Stack>
			<DialogContent
				variant='regular'
				sx={{
					border: '2px solid #E8EBED',
					borderRadius: '10px',
					padding: '15px',
					marginTop: '15px',
					overflow: 'auto',
				}}
			>
				<div
					dangerouslySetInnerHTML={{
						__html: getPreviewCustomerEmailResponse?.data ?? '',
					}}
				/>
			</DialogContent>
			<Stack
				direction={'row'}
				gap={'0.75rem'}
				sx={{ marginTop: '1.5rem', marginLeft: 'auto' }}
			>
				<Button variant='outlined' color='info' onClick={handleClose}>
					Cancel
				</Button>
				<Button
					variant='contained'
					color='success'
					onClick={sendCustomerEmailButtonOnClick}
				>
					Confirm and Send
				</Button>
			</Stack>
		</Dialog>
	);
};

const DialogTitle = styled(Typography)({
	fontSize: '1rem',
});

const DialogContent = styled(Typography)({
	fontSize: '0.875rem',
});

export default SendAbkCustomerEmailModal;

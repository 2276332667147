import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormCardType } from '../../../../common/components/card/FormCard';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';
import NewDisplayText from '../../../../common/components/form-data-display/NewDisplayText';
import { Dispatch, SetStateAction, useContext } from 'react';
import AppSubtitleLayout, {
	Label,
} from '../../../../common/components/form-layout/AppSubtitleLayout';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import UnderlinedIconButton from '../../../../common/components/buttons/UnderlinedIconButton';
import { PlaceIdContext } from '../ViewPlacePage';
import { useGetPlaceQuery } from '../../../../api/contentApiSlice';
import { RestaurantSizeTypeOption } from '../../../../api/enum/restaurantSize.enum';
import YesNoDisplay from '../../../../common/components/form-data-display/YesNoDisplay';
import TagsDisplay from '../../../../common/components/form-data-display/TagsDisplay';

export default function CatAndFacView({
	setType,
}: {
	setType: Dispatch<SetStateAction<FormCardType>>;
}) {
	const { t } = useTranslation();
	const placeId = useContext(PlaceIdContext);

	const { data: getPlaceResponse } = useGetPlaceQuery(placeId);

	const placeType = getPlaceResponse?.data.type;

	return (
		<>
			<Stack position={'relative'}>
				<Box sx={{ position: 'absolute', top: -10, right: 10 }}>
					<UnderlinedIconButton
						icon={<EditIcon color='#32A5F1' />}
						onClick={() => {
							setType(FormCardType.edit);
						}}
					>
						{t('button.Edit')}
					</UnderlinedIconButton>
				</Box>
				{placeType == 0 ? (
					<>
						<AppInputLayout label={t('place.Category')}>
							<NewDisplayText
								text={getPlaceResponse?.data.catAndFac.category?.name}
							/>
						</AppInputLayout>

						<AppInputLayout label={t('place.HiddenGem')}>
							<YesNoDisplay
								value={getPlaceResponse?.data.catAndFac.hiddenGem}
							/>
						</AppInputLayout>

						<Divider sx={{ marginBottom: '15px' }} />
					</>
				) : (
					<>
						<AppSubtitleLayout label={t('place.Category')} />
						<AppInputLayout label={t('place.Type')}>
							<NewDisplayText
								text={getPlaceResponse?.data.catAndFac.category?.name}
							/>
						</AppInputLayout>

						<AppInputLayout label={t('place.RestaurantSize')}>
							<NewDisplayText
								text={
									RestaurantSizeTypeOption.find(
										(i) =>
											i.id === getPlaceResponse?.data.catAndFac.restaurantSize
									)?.name
								}
							/>
						</AppInputLayout>

						<AppInputLayout label={t('place.FamilyFriendly')}>
							<YesNoDisplay
								value={getPlaceResponse?.data.catAndFac.familyFriendly}
							/>
						</AppInputLayout>

						<AppInputLayout label={t('place.HiddenGem')}>
							<YesNoDisplay
								value={getPlaceResponse?.data.catAndFac.hiddenGem}
							/>
						</AppInputLayout>

						<AppInputLayout label={t('place.KolRecommended')}>
							<YesNoDisplay
								value={getPlaceResponse?.data.catAndFac.kolRecommended}
							/>
						</AppInputLayout>
						<Divider sx={{ marginBottom: '15px' }} />
					</>
				)}

				{getPlaceResponse?.data.catAndFac.tagCategories.map((tags, index) => {
					return (
						<Box key={tags.id}>
							<Box paddingBottom={'15px'}>
								<Label
									marginBottom={'15px'}
								>{`${tags.nameEn} / ${tags.name}`}</Label>
								<TagsDisplay tags={tags.tags ?? []} />
							</Box>
							{index + 1 <
								getPlaceResponse?.data.catAndFac.tagCategories.length && (
								<Divider sx={{ marginBottom: '15px' }} />
							)}
						</Box>
					);
				})}
			</Stack>
		</>
	);
}

import CloseIcon from '@mui/icons-material/Close';
import {
	AppBar,
	Box,
	Divider,
	Drawer,
	IconButton,
	Stack,
	Tab,
	Tabs,
	Toolbar,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { SyntheticEvent, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
	useGetExportCustomerPDFMutation,
	useGetExportSupplierPDFMutation,
	useLazyGetAmendmentsQuery,
	useLazyGetContactPointsQuery,
	useLazyGetCustomerEmailsQuery,
	useLazyGetCustomerPDFsQuery,
	useLazyGetSupplierEmailsQuery,
	useLazyGetSupplierPDFsQuery,
} from '../../../api/abkApiSlice';
import {
	AbkCusAndSupType,
	CusAndSupTypeOptions,
} from '../../../api/enum/abkCusAndSupType';
import { getAbkStatusDisplay } from '../../../api/enum/abkStatus.enum';
import { ReactComponent as SendEmailIcon } from '../../../assets/icon/send-email-bold.svg';
import AppStatusDisplay from '../../../common/components/AppStatusDisplay';
import AppToggleButton from '../../../common/components/form-inputs/AppToggleButton';
import { DISPLAY_DATE_FORMAT } from '../../../utils/dateHelper';
import CustomerEmailListDisplay from './CustomerEmailListDisplay';
import CustomerPDFListDisplay from './CustomerPDFListDisplay';
import SupplierEmailListDisplay from './SupplierEmailListDisplay';
import SupplierPDFListDisplay from './SupplierPDFListDisplay';

import { showModal } from '../../../common/components/modal/modalSlice';
import { useAppDispatch } from '../../../redux-hooks';
import ContactPointsListDropDownMenu from './ContactPointsListDropDownMenu';
import DocListDropDownMenu from './DocListDropDownMenu';

enum TabType {
	EMAIL,
	PDF,
}

export interface SendDocDrawerProps {
	status: number;
	AmVersion?: number;
	date?: string | undefined;
	open: boolean;
	onClose: () => void;
}

export default function SendDocDrawer({
	status,
	AmVersion,
	date,
	open,
	onClose,
}: SendDocDrawerProps) {
	const { t } = useTranslation();
	const { abkId } = useParams();
	const dispatch = useAppDispatch();

	const [tabValue, setTabValue] = useState<TabType>(TabType.EMAIL);
	const [CusAndSupType, setCusAndSupType] = useState<AbkCusAndSupType>(
		AbkCusAndSupType.CUSTOMER
	);

	const handleChange = (_event: SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	const [getCustomerEmails, { data: getCustomerEmailsResponse }] =
		useLazyGetCustomerEmailsQuery();

	const [getSupplierEmails, { data: getSupplierEmailsResponse }] =
		useLazyGetSupplierEmailsQuery();

	const [getExportCustomerPDF] = useGetExportCustomerPDFMutation();

	const [getExportSupplierPDF] = useGetExportSupplierPDFMutation();

	const [
		getAmendments,
		{ data: getAmendmentsResponse, isSuccess: getAmendmentsSuccess },
	] = useLazyGetAmendmentsQuery();

	const [
		getContactPoints,
		{ data: getContactPointsResponse, isSuccess: getContactPointsSuccess },
	] = useLazyGetContactPointsQuery();

	const defaultValues: DefaultValues<any> = {
		forCustomer: CusAndSupType,
		version: null,
		contactPoint: null,
	};

	const method = useForm({
		defaultValues: defaultValues,
		reValidateMode: 'onChange',
		shouldUnregister: false,
	});

	const watchVersion = method.watch('version');
	const watchContactPoint = method.watch('contactPoint');

	const [getCustomerPDFs, { data: getCustomerPDFResponse }] =
		useLazyGetCustomerPDFsQuery();

	const [getSupplierPDFs, { data: getSupplierPDFResponse }] =
		useLazyGetSupplierPDFsQuery();

	useEffect(() => {
		if (getAmendmentsResponse?.data && getContactPointsResponse?.data) {
			method.reset({
				forCustomer: CusAndSupType,
				version: getAmendmentsResponse?.data.length - 1,
				contactPoint: getContactPointsResponse?.data[0].id,
			});
		}
	}, [getAmendmentsSuccess, getContactPointsSuccess]);

	useEffect(() => {
		if (open == true) {
			getAmendments(abkId ?? '');
			getContactPoints(abkId ?? '');
			getCustomerEmails(abkId ?? '');
			getSupplierEmails(abkId ?? '');
			getCustomerPDFs({
				abk_id: abkId ?? '',
				version: watchVersion ?? '',
			});
			getSupplierPDFs({
				abk_id: abkId ?? '',
				version: watchVersion ?? '',
			});
		}
	}, [open, watchVersion, watchContactPoint]);

	const buttonOnChange = (value: any) => {
		setCusAndSupType(value);
	};

	const handleCustomerEmailClick = (email: number) => {
		dispatch(
			showModal({
				modalType: 'SEND_ABK_CUSTOMER_EMAIL',
				modalProps: {
					abkId: abkId,
					email: email,
				},
			})
		);
	};

	const handleSupplierEmailClick = (email: number) => {
		dispatch(
			showModal({
				modalType: 'SEND_ABK_SUPPLIER_EMAIL',
				modalProps: {
					abkId: abkId,
					email: email,
					contactPointId: watchContactPoint ?? '',
				},
			})
		);
	};

	const handleCustomerPDFClick = (email: number) => {
		getExportCustomerPDF({
			abk_id: abkId ?? '',
			version: watchVersion,
			email: email,
		});
	};
	const handleSupplierPDFClick = (email: number) => {
		getExportSupplierPDF({
			abk_id: abkId ?? '',
			version: watchVersion,
			email: email,
			contactPointId: watchContactPoint ?? '',
		});
	};

	return (
		<Drawer anchor={'right'} open={open}>
			<FormProvider {...method}>
				<Box sx={{ width: 400, flexGrow: 1 }} role='presentation'>
					<AppBar
						sx={{ backgroundColor: '#E8EBED', width: 400 }}
						position='sticky'
						elevation={0}
					>
						<Toolbar>
							<Typography
								color={'black'}
								sx={{
									flexGrow: 1,
									display: 'flex',
									alignItems: 'center',
									gap: '5px',
								}}
								fontWeight={'bold'}
								fontSize={'16px'}
							>
								<SendEmailIcon />
								{t('abk.SendDocs')}
							</Typography>
							<IconButton aria-label='close' onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<Stack paddingX={'28px'} direction={'column'}>
						<Stack
							paddingTop={'20px'}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: '15px',
								alignItems: 'center',
								marginBottom: '15px',
							}}
						>
							<AppStatusDisplay
								name={getAbkStatusDisplay(status).name}
								color={getAbkStatusDisplay(status).color}
								bgcolor={getAbkStatusDisplay(status).backgroundColor}
							/>
							<Box>
								<Typography
									color={'gray'}
									sx={{ flexGrow: 1 }}
									fontWeight={'bold'}
									fontSize={'14px'}
								>
									{t('abk.LatestVersion')}
								</Typography>
								<Typography
									color={'#2C2C2C'}
									sx={{ flexGrow: 1 }}
									fontWeight={450}
									fontSize={'12px'}
								>
									{AmVersion === 0 || AmVersion === -1
										? `Original`
										: `Amendment ${AmVersion}`}{' '}
									({dayjs(date).format(DISPLAY_DATE_FORMAT)})
								</Typography>
							</Box>
						</Stack>
						<Stack paddingTop={'5px'} marginBottom={'15px'}>
							<AppToggleButton
								name='forCustomer'
								options={CusAndSupTypeOptions}
								buttonOnChange={(value) => buttonOnChange(value)}
								formLabel={''}
							/>
						</Stack>
					</Stack>
					<Box
						sx={{ borderBottom: 1, borderColor: 'divider' }}
						fontSize={'14px'}
					>
						<Tabs value={tabValue} onChange={handleChange}>
							<Tab
								label={t('abk.SendEmail')}
								sx={{ width: '50%', textTransform: 'none' }}
							/>
							<Tab
								label={t('abk.ExportPDF')}
								sx={{ width: '50%', textTransform: 'none' }}
							/>
						</Tabs>
					</Box>

					<Box hidden={tabValue !== TabType.EMAIL}>
						{getContactPointsResponse?.data &&
							CusAndSupType === AbkCusAndSupType.SUPPLIER && (
								<ContactPointsListDropDownMenu
									name='contactPoint'
									label={t('abk.ContactPerson')}
									options={getContactPointsResponse?.data}
								/>
							)}
						<Divider />
						{CusAndSupType === AbkCusAndSupType.CUSTOMER &&
							getCustomerEmailsResponse?.data && (
								<CustomerEmailListDisplay
									data={getCustomerEmailsResponse?.data}
									onClick={(email) => handleCustomerEmailClick(email)}
								/>
							)}

						{CusAndSupType === AbkCusAndSupType.SUPPLIER &&
							getSupplierEmailsResponse?.data && (
								<SupplierEmailListDisplay
									data={getSupplierEmailsResponse?.data}
									onClick={(email) => handleSupplierEmailClick(email)}
								/>
							)}
					</Box>

					<Box hidden={tabValue !== TabType.PDF}>
						{getContactPointsResponse?.data &&
							CusAndSupType === AbkCusAndSupType.SUPPLIER && (
								<ContactPointsListDropDownMenu
									name='contactPoint'
									label={t('abk.ContactPerson')}
									options={getContactPointsResponse?.data}
								/>
							)}
						{getAmendmentsResponse?.data && (
							<DocListDropDownMenu
								name='version'
								label={t('abk.RecordsVersion')}
								options={getAmendmentsResponse?.data}
							/>
						)}
						<Divider />
						{CusAndSupType === AbkCusAndSupType.CUSTOMER &&
							getCustomerPDFResponse?.data && (
								<CustomerPDFListDisplay
									data={getCustomerPDFResponse?.data}
									onClick={(email) => handleCustomerPDFClick(email)}
								/>
							)}

						{CusAndSupType === AbkCusAndSupType.SUPPLIER &&
							getSupplierPDFResponse?.data && (
								<SupplierPDFListDisplay
									data={getSupplierPDFResponse?.data}
									onClick={(email) => handleSupplierPDFClick(email)}
								/>
							)}
					</Box>
				</Box>
			</FormProvider>
		</Drawer>
	);
}

import Option from '../../common/types/option.type';

export enum RateModel {
	AGENCY = 0,
	MERCHANT = 1,
}

export const rateModelList = [RateModel.AGENCY, RateModel.MERCHANT];

export const rateModelOptions: Option[] = [
	{
		id: RateModel.AGENCY,
		name: 'NETT Model',
	},
	{
		id: RateModel.MERCHANT,
		name: 'Commissionable Model',
	},
];

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	Outlet,
	PathMatch,
	matchPath,
	useLocation,
	useParams,
} from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../../assets/icon/breadcrumb-home.svg';
import AppBreadcrumbs from '../../../common/components/breadcrumbs/AppBreadcrumbs';
import StyledBreadcrumb from '../../../common/components/breadcrumbs/StyledBreadcrumb';
import {
	useGetRatePlanQuery,
	useGetRoomQuery,
} from '../../../api/accommodationApiSlice';
import FullScreenLoader from '../../../common/components/FullScreenLoader';

export default function RoomContainer() {
	const { propertyId, roomId, ratePlanId } = useParams();
	const { t } = useTranslation();
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((x) => x);
	pathnames.splice(0, 3); // remove pathname outside room and rate plan

	const {
		data: getRoomResponse,
		isLoading: isGetRoomLoading,
		isSuccess: isGetRoomSuccess,
		isError: isGetRoomError,
	} = useGetRoomQuery(roomId ?? '', {
		skip: roomId === undefined,
	});

	const { data: getRatePlanResponse } = useGetRatePlanQuery(ratePlanId ?? '', {
		skip: ratePlanId === undefined,
	});

	function useRouteMatch(pathname: string, patterns: readonly string[]) {
		for (let i = 0; i < patterns.length; i += 1) {
			const pattern = patterns[i];
			const possibleMatch = matchPath(pattern, pathname);
			if (possibleMatch !== null) {
				return possibleMatch;
			}
		}

		return null;
	}

	function getBreadcrumbNameByPathMatch(
		pathMatch?: PathMatch<string> | null
	): string {
		switch (pathMatch?.pattern?.path) {
			case '/:roomId/rate-plan/create': {
				return t('room.ratePlan.CreateRatePlan');
			}

			case '/:roomId/rate-plan/:ratePlanId': {
				return `${getRatePlanResponse?.data.baseInfo.id} ${getRatePlanResponse?.data.baseInfo.nameEn}`;
			}

			case '/:roomId/rate-plan': {
				return t('room.ratePlan.RatePlanSummary');
			}

			case '/src': {
				return t('room.RoomRelationship');
			}
			case '/c': {
				return t('room.CreateRoom');
			}
			case '/:roomId': {
				return `${getRoomResponse?.data.info.id} ${getRoomResponse?.data.info.nameEn}`;
			}
			default: {
				return '';
			}
		}
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<AppBreadcrumbs>
				{pathnames.length > 0 ? (
					<StyledBreadcrumb
						icon={<HomeIcon />}
						label={t('room.RoomList')}
						to={`/property/${propertyId}/room`}
					/>
				) : (
					<StyledBreadcrumb
						icon={<HomeIcon />}
						label={t('room.RoomList')}
						active
					/>
				)}

				{pathnames.map((pathname, index) => {
					const last = index === pathnames.length - 1;
					const to = `/${pathnames.slice(0, index + 1).join('/')}`;

					const pathMatch = useRouteMatch(to, [
						'/:roomId/rate-plan/create',
						'/:roomId/rate-plan/:ratePlanId',
						'/:roomId/rate-plan',
						'/src',
						'/c',
						'/:roomId',
					]);

					const label = getBreadcrumbNameByPathMatch(pathMatch);

					return last ? (
						<StyledBreadcrumb key={to} label={label} active />
					) : (
						<StyledBreadcrumb
							key={to}
							label={label}
							to={`/property/${propertyId}/room${to}`}
						/>
					);
				})}
			</AppBreadcrumbs>
			<Outlet />
		</Box>
	);
}

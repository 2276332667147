import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useState } from 'react';
import CatAndFacView from './CatAndFacView';
import CatAndFacForm from './CatAndFacForm';

interface CatAndFacCardProps {
	initialType: FormCardType;
	placeType: number;
}

export default function CatAndFacCard({ initialType, placeType }: CatAndFacCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<FormCard type={type} title={t('place.CategoryAndFacilities')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <CatAndFacView setType={setType} />;
					case FormCardType.create:
						return <CatAndFacForm type={type} setType={setType} placeType={placeType}/>;
					case FormCardType.edit:
						return <CatAndFacForm type={type} setType={setType} placeType={placeType}/>;
				}
			})()}
		</FormCard>
	);
}

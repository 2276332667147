import { Box, Button, Grid, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	TangleFilter,
	TanglePeek,
	TanglePeekSummary,
} from '../../../../api/DTO/propertyImport.interface';
import { useAddTangleWriteMutation } from '../../../../api/scraperApiSlice';
import LocalDataGrid from '../../../../common/components/dataGrid/LocalDataGrid';
import NonEditableDisplayText from '../../../../common/components/form-data-display/NonEditableDisplayText';
import AppInputLayout from '../../../../common/components/form-layout/AppInputLayout';

interface SearchResultFormProps {
	searchResult: TanglePeek;
}

export default function SearchResultForm({
	searchResult,
}: SearchResultFormProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [addTangleWrite, { isSuccess: isAddTangleWriteSuccess }] =
		useAddTangleWriteMutation();

	const { handleSubmit } = useForm<TangleFilter>({
		defaultValues: searchResult?.filter,
	});

	useEffect(() => {
		if (isAddTangleWriteSuccess) {
			navigate(`/property/import/logging`);
		}
	}, [isAddTangleWriteSuccess]);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('property.PropertyName'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'description',
			headerName: t('property.Description'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'category',
			headerName: t('property.Category'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
		{
			field: 'numOfRooms',
			headerName: t('property.propertyImport.NumOfRooms'),
			flex: 1,
			width: 86, // 24(cell padding left) + 52(text fixed width) + 10(cell padding right)
			sortable: false,
		},
	];

	const transformResponse = useCallback((response?: TanglePeekSummary[]) => {
		if (response == null) return;
		if (response.length == 0)
			return [
				{
					id: '-',
					name: '-',
					description: '-',
					category: '-',
					numOfRooms: '-',
				},
			];

		const newResponse: any[] = [];
		response.forEach((item, index) => {
			newResponse.push({
				id: index,
				name: item.name ?? '-',
				description: item.description ?? '-',
				category: item.category ?? '-',
				numOfRooms: item.numOfRooms ?? '-',
			});
		});
		return newResponse;
	}, []);

	const onSubmit: SubmitHandler<TangleFilter> = (data: TangleFilter) => {
		addTangleWrite(data);
	};
	const formContent = (
		<Stack>
			<AppInputLayout label={t('property.LocationCoordinates')}>
				<NonEditableDisplayText
					text={searchResult.filter?.coordinates.join(', ').toString()}
				/>
			</AppInputLayout>

			<AppInputLayout label={t('property.PropertyName')}>
				<NonEditableDisplayText
					text={
						searchResult.filter?.propertyName === ''
							? '-'
							: searchResult.filter?.propertyName
					}
				/>
			</AppInputLayout>

			<AppInputLayout label={t('property.propertyImport.Radius(meter)')}>
				<NonEditableDisplayText
					text={searchResult.filter?.radius?.toString() ?? '-'}
				/>
			</AppInputLayout>
			<Box width={'100%'} marginBottom={'15px'}>
				<LocalDataGrid
					rows={transformResponse(searchResult.data) ?? []}
					columns={columns}
					rowCount={0}
					getRowId={(row) => row.id}
					pagination={false}
				/>
			</Box>

			<Stack spacing='16px'>
				<Grid item xs={12}>
					<Stack direction='row-reverse' spacing='12px'>
						<Button type='submit' variant='contained' color='success'>
							{t('button.Import')}
						</Button>
					</Stack>
				</Grid>
			</Stack>
		</Stack>
	);

	const form = <form onSubmit={handleSubmit(onSubmit)}>{formContent}</form>;

	return <>{form}</>;
}

import { Box, Button, Tab, Tabs } from '@mui/material';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Link,
	Outlet,
	matchPath,
	useLocation,
	useParams,
} from 'react-router-dom';
import {
	useGetAbkQuery,
	useGetAmendmentsQuery,
	useUpdateAbkStatusMutation,
} from '../../api/abkApiSlice';
import {
	AbkMoreAction,
	abkConfirmedMoreActionOptions,
	abkEnquiryMoreActionOptions,
	abkNewMoreActionOptions,
} from '../../api/enum/abkMoreAction.enum';
import { AbkStatus, getAbkStatusDisplay } from '../../api/enum/abkStatus.enum';
import { ReactComponent as SendEmailIcon } from '../../assets/icon/send-email.svg';
import AppDropDownButton from '../../common/components/AppDropDownButton';
import AppStatusDisplay from '../../common/components/AppStatusDisplay';
import FullScreenLoader from '../../common/components/FullScreenLoader';
import PageHeader from '../../common/components/PageHeader';
import { FormCardType } from '../../common/components/card/FormCard';
import { showModal } from '../../common/components/modal/modalSlice';
import { useAppDispatch } from '../../redux-hooks';
import AbkViewRecordButton from './components/AbkViewRecordButton';
import SendDocDrawer from './components/SendDocDrawer';

function useRouteMatch(patterns: readonly string[]) {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}

	return null;
}
export const AbkVersionContext = createContext<any>(undefined);
export const AbkTypeContext = createContext<any>(undefined);

function AbkTabs({ currentTab }: { currentTab?: string }) {
	const { t } = useTranslation();
	const { abkId } = useParams();

	return (
		<Box
			sx={{
				backgroundColor: '#FFFFFF',
				borderRadius: '5px',
				boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.05)',
				padding: '0px 20px',
				marginBottom: '15px',
			}}
		>
			{currentTab && (
				<Tabs value={currentTab}>
					<Tab
						label={t('tabs.Overview')}
						value='/abks/:abkId/overview'
						to={`/abks/${abkId}/overview`}
						component={Link}
					/>
					<Tab
						label={t('tabs.OtherInfo')}
						value='/abks/:abkId/other-info'
						to={`/abks/${abkId}/other-info`}
						component={Link}
					/>
					<Tab
						label={t('tabs.Transactions')}
						value='/abks/:abkId/transactions/*'
						to={`/abks/${abkId}/transactions`}
						component={Link}
					/>
					<Tab
						label={t('tabs.Logging')}
						value='/abks/:abkId/logging/*'
						to={`/abks/${abkId}/logging`}
						component={Link}
					/>
				</Tabs>
			)}
		</Box>
	);
}

export default function AbkContainer() {
	const { t } = useTranslation();
	const { abkId } = useParams();
	const { state } = useLocation();
	const dispatch = useAppDispatch();

	const [abkSummarySearchParams, setAbkSummarySearchParams] = useState<
		string | undefined
	>(undefined);

	// You need to provide the routes in descendant order.
	// This means that if you have nested routes like:
	// users, users/new, users/edit.
	// Then the order should be ['users/add', 'users/edit', 'users'].
	const routeMatch = useRouteMatch([
		'/abks/:abkId/overview',
		'/abks/:abkId/other-info',
		'/abks/:abkId/transactions/*',
		'/abks/:abkId/logging/*',
	]);

	const currentTab = routeMatch?.pattern?.path;

	const [abkVersion, setAbkVersion] = useState<number | undefined>(undefined);
	const [type, setType] = useState<FormCardType>(FormCardType.view);

	const [updateAbkStatus, { isSuccess: isUpdateAbkStatusSuccess }] =
		useUpdateAbkStatusMutation();

	const [sendDocOpen, setSendDocOpen] = useState<boolean>(false);

	const toggleDrawer = (open: boolean) => () => {
		setSendDocOpen(open);
	};

	useEffect(() => {
		if (state?.abkSummarySearchParams) {
			setAbkSummarySearchParams(state?.abkSummarySearchParams);
		}
	}, []);

	useEffect(() => {
		if (currentTab) {
			setType(FormCardType.view);
		}
	}, [currentTab]);

	const handleAbkEnquiry = (abkId: string) => {
		dispatch(
			showModal({
				modalType: 'DELETE_ABK_ENQUIRY',
				modalProps: {
					id: abkId,
				},
			})
		);
	};

	const handleCancelAbkBooking = (abkId: string, status: AbkStatus) => {
		dispatch(
			showModal({
				modalType: 'DELETE_ABK_BOOKING',
				modalProps: {
					id: abkId,
					status: status,
				},
			})
		);
	};

	const handleVersionChange = (version: number) => {
		setAbkVersion(version);
	};

	const backPath = abkSummarySearchParams
		? `/abks?${abkSummarySearchParams}`
		: '/abks';

	const {
		data: getAbkResponse,
		isLoading: isGetAbkLoading,
		isSuccess: isGetAbkSuccess,
		isError: isGetAbkError,
	} = useGetAbkQuery(
		{
			abk_id: abkId ?? '',
			version: undefined,
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const { data: getAbkWithVersionResponse } = useGetAbkQuery(
		{
			abk_id: abkId ?? '',
			version: abkVersion,
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const { data: getAmendmentsResponse } = useGetAmendmentsQuery(abkId ?? '', {
		refetchOnMountOrArgChange: true,
	});

	const handleChange = (value: number) => {
		switch (value) {
			case AbkMoreAction.EDIT:
				return setType(FormCardType.edit);
			case AbkMoreAction.PROCEED:
				return setType(FormCardType.specialEdit);

			// return updateAbkStatus({
			// 	abkId: abkId ?? '',
			// 	body: { status: AbkStatus.NEW },
			// });
			case AbkMoreAction.REMOVE:
				return handleAbkEnquiry(abkId ?? '');
			case AbkMoreAction.CONFIRM:
				return updateAbkStatus({
					abkId: abkId ?? '',
					body: { status: AbkStatus.CONFIRMED },
				});
			case AbkMoreAction.CANCEL:
				return handleCancelAbkBooking(abkId ?? '', AbkStatus.CANCELLED);
			default:
				return undefined;
		}
	};

	const getActionButtonOption = (value: number) => {
		switch (value) {
			case AbkStatus.ENQUIRY:
				return abkEnquiryMoreActionOptions;
			case AbkStatus.NEW:
				return abkNewMoreActionOptions;
			case AbkStatus.CONFIRMED:
				return abkConfirmedMoreActionOptions;
			default:
				return undefined;
		}
	};

	const getVersionDate = (value?: number) => {
		let date = undefined;

		getAmendmentsResponse?.data.map((option, index) => {
			if (option.version === value) {
				date = option.createdAt;
			}
		});

		return date;
	};

	let pageContent;
	if (isGetAbkLoading) {
		pageContent = <FullScreenLoader />;
	} else if (isGetAbkSuccess) {
		pageContent = (
			<>
				<AbkVersionContext.Provider value={[abkVersion, setAbkVersion]}>
					<AbkTypeContext.Provider value={[type, setType]}>
						<Box sx={{ width: '100%' }}>
							<PageHeader
								showBackButton={currentTab == '/abks/:abkId/overview'}
								backPath={backPath}
								title={getAbkResponse?.data.baseInfo.id ?? '-'}
								statusJsx={
									<>
										<AppStatusDisplay
											name={
												getAbkStatusDisplay(
													getAbkResponse?.data.baseInfo.status ?? 0
												).name
											}
											color={
												getAbkStatusDisplay(
													getAbkResponse?.data.baseInfo.status ?? 0
												).color
											}
											bgcolor={
												getAbkStatusDisplay(
													getAbkResponse?.data.baseInfo.status ?? 0
												).backgroundColor
											}
										/>
									</>
								}
								actionJsx={
									<>
										{currentTab === '/abks/:abkId/overview' && (
											<>
												{type === FormCardType.view && (
													<Box gap={'10px'} display={'flex'}>
														{abkVersion === undefined ||
														abkVersion + 1 ===
															getAmendmentsResponse?.data.length ? (
															<>
																{getAbkResponse?.data.baseInfo.status !==
																AbkStatus.CANCELLED ? (
																	<AppDropDownButton
																		label={t('button.MoreActions')}
																		options={getActionButtonOption(
																			getAbkResponse?.data.baseInfo.status
																		)}
																		onChange={(value: number) =>
																			handleChange(value)
																		}
																		disabledOption={AbkMoreAction.EDIT}
																	/>
																) : (
																	<></>
																)}
																<Button
																	variant='outlined'
																	startIcon={<SendEmailIcon />}
																	sx={{
																		backgroundColor: '#FFF',
																		borderColor: '#FFF',
																		boxShadow:
																			'0px 4px 3px rgba(0, 0, 0, 0.05)',
																	}}
																	onClick={toggleDrawer(true)}
																>
																	{t('button.SendDoc')}
																</Button>
																<SendDocDrawer
																	status={getAbkResponse?.data.baseInfo.status}
																	AmVersion={
																		getAbkResponse?.data.baseInfo.version
																	}
																	date={getVersionDate(
																		getAbkResponse?.data.baseInfo.version
																	)}
																	open={sendDocOpen}
																	onClose={toggleDrawer(false)}
																/>
															</>
														) : (
															<></>
														)}
														{getAbkResponse?.data.baseInfo.status !==
														AbkStatus.ENQUIRY ? (
															getAmendmentsResponse?.data &&
															getAmendmentsResponse?.data.length > 1 ? (
																<AbkViewRecordButton
																	label={t('button.ViewRecords')}
																	data={getAmendmentsResponse?.data ?? []}
																	AmVersion={
																		getAbkWithVersionResponse?.data.baseInfo
																			.version
																	}
																	onChange={(value: number) =>
																		handleVersionChange(value)
																	}
																/>
															) : (
																<></>
															)
														) : (
															<></>
														)}
													</Box>
												)}
											</>
										)}
									</>
								}
							/>
							<AbkTabs currentTab={currentTab} />
							<Outlet />
						</Box>
					</AbkTypeContext.Provider>
				</AbkVersionContext.Provider>
			</>
		);
	} else if (isGetAbkError) {
		pageContent = <div>API Error</div>;
	}

	return <div>{pageContent}</div>;
}

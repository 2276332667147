import { Box, Typography } from '@mui/material';

interface SpaceDisplayTextProps {
	text1?: string;
	text2?: string;
}

export default function SpaceDisplayText({
	text1,
	text2,
}: SpaceDisplayTextProps) {
	return (
		<Box sx={{ display: 'flex', gap: '20px', justifyContent: 'space-between' }}>
			<Typography fontSize='12px' color='#2C2C2C' whiteSpace={'break-spaces'}>
				{text1?.length ? text1 : '-'}
			</Typography>
			<Typography fontSize='12px' color='#2C2C2C' whiteSpace={'break-spaces'}>
				{text2?.length ? text2 : '-'}
			</Typography>
		</Box>
	);
}

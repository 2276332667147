import AbkMoreActionOption from '../../common/types/option.type';
import { ReactComponent as DeleteIcon } from '../../assets/icon/delete.svg';
import { ReactComponent as CircleTickIcon } from '../../assets/icon/circleTick.svg';

export enum BkMoreAction {
	CONFIRM = 0,
	CANCEL = 1,
}

export const bkNewMoreActionOptions: AbkMoreActionOption[] = [
	{
		id: BkMoreAction.CONFIRM,
		name: 'Confirm Booking',
		startIcon: <CircleTickIcon />,
		color: '#79B765',
	},
	{
		id: BkMoreAction.CANCEL,
		name: 'Cancel Booking',
		startIcon: <DeleteIcon />,
		color: '#DA5150',
		backgroundColor: '#FFDDDC',
	},
];

export const bkConfirmedMoreActionOptions: AbkMoreActionOption[] = [
	{
		id: BkMoreAction.CANCEL,
		name: 'Cancel Booking',
		startIcon: <DeleteIcon />,
		color: '#DA5150',
		backgroundColor: '#FFDDDC',
	},
];

import { ReactNode } from 'react';
import {
	FieldValues,
	FieldPath,
	useController,
	UseControllerProps,
} from 'react-hook-form';
import { Checkbox, CheckboxProps, styled } from '@mui/material';
import { ReactComponent as CheckedIcon } from '../../../assets/icon/checkbox-checked.svg';
import { ReactComponent as UnCheckedIcon } from '../../../assets/icon/checkbox-unchecked.svg';
import AppInputLayout from '../form-layout/AppInputLayout';

interface AppInputLayoutSingleCheckboxProps {
	label: string;
	required?: boolean;
	infoPopUp?: ReactNode;
}

export default function AppInputLayoutSingleCheckbox<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
	label,
	required,
	infoPopUp,
	...props
}: AppInputLayoutSingleCheckboxProps &
	UseControllerProps<TFieldValues, TName> &
	CheckboxProps) {
	const { field, fieldState } = useController(props);
	const errorMessage = fieldState.error?.message;

	return (
		<AppInputLayout
			label={label}
			required={required}
			infoPopUp={infoPopUp}
			errorMessage={errorMessage}
		>
			<div>
				<StyledCheckbox
					{...field}
					checked={field.value}
					icon={<UnCheckedIcon />}
					checkedIcon={<CheckedIcon />}
				/>
			</div>
		</AppInputLayout>
	);
}

const StyledCheckbox = styled(Checkbox)({
	padding: '0px',
	color: '#4EA4D3 !important',
	'&.Mui-disabled': {
		color: '#BCBCBC !important',
	},
});

import Option from '../../common/types/option.type';

export const mealRateCalculationOptions: Option[] = [
	{
		id: true,
		name: 'Use Meal Supplement',
	},
	{
		id: false,
		name: 'included in Rate Plan Supplement',
	},
];

import FullCalendar from '@fullcalendar/react';
import { EventContentArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { ReactComponent as FilterIcon } from '../../assets/icon/filter.svg';

export default function TestFullCalendar() {
	// const handleDateClick = (arg: any) => { // bind with an arrow function
	//     alert(arg.dateStr)
	//   }

	return (
		<FullCalendar
			plugins={[dayGridPlugin]}
			initialView='dayGridMonth'
			eventBackgroundColor='#fffffff'
			eventContent={renderEventContent}
			events={[
				{ title: 'event 1', date: '2023-03-01', count: '1adasdasd' },
				{ title: 'event 2', date: '2023-03-02' },
			]}
		/>
	);
}

function renderEventContent(eventInfo: EventContentArg) {
	return (
		<div>
			<b>{eventInfo.timeText}</b>
			<i>{eventInfo.event.title}</i>
			<FilterIcon />
			<button>123</button>
		</div>
	);
}

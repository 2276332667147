import { useTranslation } from 'react-i18next';
import FormCard, {
	FormCardType,
} from '../../../../common/components/card/FormCard';
import { useState } from 'react';

import DocumentView from './DocumentView';
import DocumentForm from './DocumentForm';

interface DocumentCardProps {
	initialType: FormCardType;
}

export default function DocumentCard({ initialType }: DocumentCardProps) {
	const { t } = useTranslation();
	const [type, setType] = useState<FormCardType>(initialType);

	return (
		<FormCard type={type} title={t('supplier.Document')}>
			{(() => {
				switch (type) {
					case FormCardType.view:
						return <DocumentView setType={setType} />;
					case FormCardType.create:
						return <DocumentForm type={type} setType={setType} />;
					case FormCardType.edit:
						return <DocumentForm type={type} setType={setType} />;
				}
			})()}
		</FormCard>
	);
}
